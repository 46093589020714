<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';

import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';

import ReturnInventoryToWarehouse from './ReturnInventoryToWarehouse.vue';

/**
 * EditWarehouseModal
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'EditWarehouseModal',
  props: {
    creditNote: {
      type: Object,
      required: true,
    },
  },
  components: {
    EzFormModal,
    EzButton,
    ReturnInventoryToWarehouse,
  },
  data() {
    return {
      warehouse: null,
      supplierCy,
    };
  },
  computed: {
    warehouseDataCy() {
      const { LABEL__RETURN_TO_INVENTORY, BUTTON__WAREHOUSE } = supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_WAREHOUSE_MODAL;
      return { LABEL__RETURN_TO_INVENTORY, BUTTON__WAREHOUSE };
    },
  },
  methods: {
    open() {
      const { warehouse, venue } = this.creditNote;
      this.warehouse = warehouse?.id || venue?.connection?.primaryWarehouseId;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onCancel() {
      this.warehouse = null;
      this.close();
      this.$emit('cancel');
    },
    onSave() {
      this.close();
      this.$emit('save', this.warehouse);
    },
    onWarehouseChange(warehouse) {
      this.warehouse = warehouse;
    },
  },
};
</script>

<template>
  <ez-form-modal ref="modal">
    <template #title>Edit Warehouse</template>
    <template #content>
      <return-inventory-to-warehouse
        :selected="warehouse"
        @change="onWarehouseChange"
        :data-cy="warehouseDataCy"
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="onCancel"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_WAREHOUSE_MODAL.BUTTON__CANCEL
        "
      >
        Cancel
      </ez-button>
      <ez-button
        type="primary"
        @click="onSave"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_WAREHOUSE_MODAL.BUTTON__SAVE
        "
      >
        Save Changes
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped></style>
