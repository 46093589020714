<script>
import { mapGetters, mapMutations } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardEmitterMixin } from '@/mixins/wizard';

import EzButton from '@/components/ui/Button';
import EzWizard from '@/components/layout/Wizard.vue';
import steps, {
  NEW_CREDIT_NOTE_STEP_1,
  NEW_CREDIT_NOTE_STEP_2,
  NEW_CREDIT_NOTE_STEP_3,
} from './steps';

/**
 * Index
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  mixins: [wizardEmitterMixin],
  props: {
    orderId: {
      type: Number,
      default: null,
    },
  },
  components: {
    EzButton,
    EzWizard,
  },
  data() {
    return {
      steps,
      NEW_CREDIT_NOTE_STEP_1,
      NEW_CREDIT_NOTE_STEP_2,
      NEW_CREDIT_NOTE_STEP_3,
      currentStep: 0,
      nextDisabled: true,
      secondActionDisabled: true,
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('creditNotes', {
      getDraftVenue: 'getDraftVenue',
      fromOrder: 'getIsFromOrder',
      isTotalCreditAmountExceeded: 'getTotalCreditNoteAmountInvalid',
      remainingCreditNote: 'getRemainingCreditAmount',
      nonStockCreditNote: 'getNonStockCredit',
    }),
    selectedVenue() {
      return this.getDraftVenue;
    },
    hasSelectedVenue() {
      return !!this.selectedVenue && Object.keys(this.selectedVenue || {}).length > 0;
    },
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      /**
       * Special case for the overview step.
       * Buttons swap places depending on the permission.
       */
      if (this.isLastStep && !this.$permission.has('approveCreditNote')) {
        return steps[this.currentStep].secondCta;
      }

      return steps[this.currentStep].nextCta;
    },
    secondCta() {
      /**
       * Special case for the overview step.
       * Buttons swap places depending on the permission.
       */
      if (this.isLastStep && !this.$permission.has('approveCreditNote')) {
        return undefined;
      }

      return steps[this.currentStep].secondCta;
    },
    isFirstStep() {
      return this.currentStep === NEW_CREDIT_NOTE_STEP_1;
    },
    isSecondStep() {
      return this.currentStep === NEW_CREDIT_NOTE_STEP_2;
    },
    isLastStep() {
      return this.currentStep === NEW_CREDIT_NOTE_STEP_3;
    },
    isCreditNoteExceeded() {
      return this.fromOrder && this.isTotalCreditAmountExceeded;
    },
    isNonCreditNoteExceeded() {
      return this.nonStockCreditNote > this.remainingCreditNote;
    },
  },
  methods: {
    ...mapMutations('creditNotes', ['CLEAR_DRAFT']),
    onWizardExit() {
      this.CLEAR_DRAFT();
      return this.$router.push({ name: 'distributor-credit-notes' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= NEW_CREDIT_NOTE_STEP_1 && step <= NEW_CREDIT_NOTE_STEP_3) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= NEW_CREDIT_NOTE_STEP_1) return;

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= NEW_CREDIT_NOTE_STEP_3) {
        return;
      }

      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
  watch: {
    hasSelectedVenue: {
      immediate: true,
      handler(val) {
        this.secondActionDisabled = !val;
      },
    },
  },
};
</script>

<template>
  <ez-wizard
    :extraWide="[NEW_CREDIT_NOTE_STEP_2].includes(currentStep)"
    :mediumWide="[NEW_CREDIT_NOTE_STEP_3].includes(currentStep)"
    @exit="onWizardExit"
    @back="goToPreviousStep"
  >
    <template #title>Add New Credit Note</template>
    <template #nav>
      <ul>
        <li
          :class="{
            'ez-step-active': currentStep === NEW_CREDIT_NOTE_STEP_1,
            'ez-step-completed': currentStep > NEW_CREDIT_NOTE_STEP_1,
          }"
        >
          1. Credit Info
        </li>
        <li
          :class="{
            'ez-step-active': currentStep === NEW_CREDIT_NOTE_STEP_2,
            'ez-step-completed': currentStep > NEW_CREDIT_NOTE_STEP_2,
          }"
        >
          2. Stock Credit
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button
        v-if="secondCta"
        type="secondary"
        class="mr-8 secondary-action"
        @click="goToNextStep"
        :disabled="secondActionDisabled || isNonCreditNoteExceeded"
        :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.GENERAL.BUTTON__PRIMARY_ACTION"
      >
        {{ secondCta }}
      </ez-button>
      <ez-button
        :disabled="nextDisabled || isCreditNoteExceeded"
        @click="goToStep({ step: NEW_CREDIT_NOTE_STEP_3 })"
        :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.GENERAL.BUTTON__SECONDARY_ACTION"
      >
        {{ nextCta }}
      </ez-button>
    </template>
    <template #prevStep>
      <ez-button
        v-if="isSecondStep"
        type="link"
        formType="button"
        @click="goToPreviousStep"
        :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.GENERAL.BUTTON__PREV_STEP"
      >
        <font-awesome-icon icon="arrow-left" />
        <span>Back</span>
      </ez-button>
    </template>
    <template #pageTitle>{{ currentStepPageTitle }}</template>
    <template #pageInfo>
      <div v-html="currentStepPageInfo"></div>
    </template>
    <component
      :is="currentStepComponent"
      :orderId="orderId"
      @stepCompleted="onStepCompleted"
      @stepBack="onStepBack"
    />
  </ez-wizard>
</template>

<style lang="scss" scoped>
.secondary-action:disabled {
  background-color: $input-disabled-bg-color;
  color: rgba($color-gray-6C, 0.48);
}
</style>
