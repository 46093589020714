export const STOCKTAKE_STEP_1 = 0;
export const STOCKTAKE_STEP_2 = 1;
export const STOCKTAKE_STEP_3 = 2;

const steps = {
  [STOCKTAKE_STEP_1]: {
    component: () =>
      import(
        /* webpackChunkName: "conduct-stocktake" */ '@/views/platform/venue/stock/conduct-stocktake/Location.vue'
      ),
    title: 'Location',
    pageTitle: 'Please choose location',
    pageInfo: 'Please, select the location for which you would like to conduct the count.',
    nextCta: 'Next Step',
  },
  [STOCKTAKE_STEP_2]: {
    component: () =>
      import(
        /* webpackChunkName: "conduct-stocktake" */ '@/views/platform/venue/stock/conduct-stocktake/Products.vue'
      ),
    title: 'Stocktaking',
    pageTitle: 'Count Inventory Items',
    pageInfo:
      'Make sure your records are accurate and correct in order to finish the count process for the selected products.',
    nextCta: 'Go To Overview',
  },
  [STOCKTAKE_STEP_3]: {
    component: () =>
      import(
        /* webpackChunkName: "conduct-stocktake" */ '@/views/platform/venue/stock/conduct-stocktake/Overview.vue'
      ),
    title: 'Overview',
    pageTitle: 'Overview',
    pageInfo:
      'Before completing the count for the selected products, check the information below to confirm you have entered everything correctly.',
    nextCta: 'Finish',
  },
};

export default steps;
