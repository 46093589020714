<template>
  <div>
    <paper>
      <template #actions>
        <ez-button type="link" formType="button" @click="editGroup">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <ez-entity-info imgWidth="4rem" imgHeight="4rem" imgBorderRadius="50%" :imgUrl="venue.logo">{{
        venue.name
      }}</ez-entity-info>
    </paper>
    <paper>
      <template #title>Main info</template>
      <template #actions>
        <ez-button type="link" formType="button" @click="editGroup">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="overview-item">
        <div class="info info--vertical">
          <small>{{ $t('global.venue') }} Name</small>
          <span>{{ venue.name }}</span>
        </div>
        <div v-if="venue.legalName" class="info info--vertical">
          <small>Legal Name</small>
          <span>{{ venue.legalName }}</span>
        </div>
        <div v-if="venue.companyRegistrationNumber" class="info info--vertical">
          <small>Company Registration Number / Tax ID</small>
          <span>{{ venue.companyRegistrationNumber }}</span>
        </div>
        <div v-if="venue.customInfo" class="info info--vertical">
          <small>Additional Info</small>
          <span>{{ venue.customInfo }}</span>
        </div>
      </div>

      <div class="overview-item" v-if="hasShippingAddress || hasBillingAddress">
        <h3>Addresses</h3>
        <div v-if="hasShippingAddress" class="ez-info-panel__contact">
          <div class="info info--vertical">
            <small>Delivery Address</small>
            <span class="formatted-address">{{ shippingAddress }} </span>
          </div>
        </div>
        <div v-if="hasBillingAddress" class="ez-info-panel__contact">
          <div class="info info--vertical">
            <small>Billing Address</small>
            <span class="formatted-address">{{ billingAddress }} </span>
          </div>
        </div>
      </div>
      <div v-if="workingHours" class="overview-item">
        <h3>Cut off time:</h3>
        <div class="info info--vertical">
          <small>Hours</small>
          <div>
            <span>{{ workingHours.from }}</span> - <span>{{ workingHours.to }}</span>
          </div>
        </div>
        <div class="info info--vertical">
          <small>Time Zone</small>
          <span>{{ venue.timezone }}</span>
        </div>
      </div>
      <div class="overview-item" v-if="minimumOrderAmount">
        <div v-if="minimumOrderAmount" class="info info--vertical">
          <small>Minimum order amount:</small>
          <div>{{ minimumOrderAmount | price }}</div>
        </div>
      </div>
      <div v-if="hasNotifications" class="info info--vertical">
        <h3>Receive Notifications Via:</h3>
        <template v-if="venue.email">
          <small>Email</small>
          <span>{{ venue.email }}</span>
        </template>
        <template v-if="venue.smsNumber">
          <small>SMS</small>
          <span>{{ venue.smsNumber }}</span>
        </template>
        <template v-if="venue.whatsAppNumber">
          <small>WhatsApp</small>
          <span>{{ venue.whatsAppNumber }}</span>
        </template>
      </div>
    </paper>

    <paper>
      <template #title>Settings</template>
      <template #actions>
        <ez-button type="link" formType="button" @click="editSettings">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="overview-item">
        <div v-if="settingsData.customerCategory?.name" class="info info--vertical">
          <small>{{ $t('global.venue') }} Category</small>
          <span>{{ settingsData.customerCategory?.name }}</span>
        </div>
        <div v-if="settingsData.distributorAccountOwner?.name" class="info info--vertical">
          <small>Account Representative</small>
          <span>{{ settingsData.distributorAccountOwner?.name }}</span>
        </div>
        <div v-if="settingsData.paymentTermsData?.name" class="info info--vertical">
          <small>Payment Terms</small>
          <span>{{ settingsData.paymentTermsData?.name }}</span>
        </div>
        <div v-if="settingsData.primaryWarehouse?.name" class="info info--vertical">
          <small>Default Warehouse</small>
          <span>{{ settingsData.primaryWarehouse?.name }}</span>
        </div>
        <div v-if="settingsData.defaultDriver?.name" class="info info--vertical">
          <small>Default Driver</small>
          <span>{{ settingsData.defaultDriver?.name }}</span>
        </div>
        <div class="overview-item mt-16">
          <h3>Tax Rate Applicable</h3>
          <div class="info info--vertical">
            <small>Tax Calculation</small>
            <div>
              <span v-if="taxCalculationLabel">{{ taxCalculationLabel }}</span>
              <span v-else>Default</span>
            </div>
          </div>
          <div v-if="tax" class="info info--vertical">
            <small>Tax Rate</small>
            <div>
              <span>{{ tax }}%</span>
            </div>
          </div>
        </div>
        <div v-if="venue.tags.length" class="info info--vertical">
          <small class="mb-16">Tags</small>
          <div class="all-tags">
            <v-tag
              v-for="tag in tagsToDisplay"
              :key="tag.id"
              :color="tag.color.colors[0]"
              :backgroundColor="tag.color.colors[1]"
            >
              {{ tag.name }}
            </v-tag>
            <span
              class="tags-number-indicator"
              v-if="venue.tags.length > numberOfTagsToDisplay && !tagsListExpanded"
            >
              +{{ venue.tags.length - numberOfTagsToDisplay }}
            </span>
          </div>
          <ez-button
            v-if="venue.tags.length > numberOfTagsToDisplay"
            class="u-flex-h-center mt-16"
            @click="toggleListExpanded"
            isFullWidth
            type="link"
            formType="button"
          >
            <span>{{ expandButtonCopy }}</span>
            <font-awesome-icon class="ml-8" :icon="expandButtonIcon" />
          </ez-button>
        </div>
      </div>
    </paper>

    <paper>
      <template #title>Users</template>
      <template #actions>
        <ez-button type="link" formType="button" @click="editUsers">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="contact-item">
        <div class="contact-item__name">{{ draftAdmin.owner.name }}</div>
        <div class="contact-item__type">Admin</div>
      </div>
      <div class="contact-item">
        <div class="contact-item__name">{{ draftAdmin.finance.name }}</div>
        <div class="contact-item__type">Finance User</div>
      </div>
      <div class="contact-item" v-if="hasOrderingContact">
        <div class="contact-item__name">{{ draftAdmin.ordering.name }}</div>
        <div class="contact-item__type">Ordering User</div>
      </div>
      <div v-for="user in users" :key="user.id" class="info info--horizontal">
        <span>{{ user.name }}</span>
        <small>{{ user.role ? user.role.name : '' }}</small>
      </div>
    </paper>

    <paper class="products-paper">
      <template #title>Products ({{ meta.totalCount }})</template>
      <template #actions>
        <ez-button @click="editProducts" type="link" formType="button">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <table class="product-table">
        <tbody>
          <tr v-for="product in products" :key="product.id">
            <td class="item">
              <v-product-entity-info class="entity-info" :product="product" />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="isLoadingMore" class="u-text-center">
        <ez-spinner />
      </div>
      <ez-load-more v-if="!isLoadingMore && canLoadOnScroll && meta.nextId" @loadMore="loadMore" />

      <template #footer>
        <div class="footer-paper mt-16">
          <ez-button v-if="showExpandBtn" @click="expandList" type="link" formType="button">
            <span>{{ expandBtnCopy }}</span>
            <font-awesome-icon class="ml-8" :icon="`angle-${iconDirection}`" />
          </ez-button>
        </div>
      </template>
    </paper>
    <hr class="dashed-line" />
    <paper>
      <div class="onboarding-option">
        <div class="help-block mb-20">
          <h4 class="help-block__title">Send Terms & Conditions</h4>
          <p class="help-block__text">
            Include the Terms & Conditions in the account<br />
            opening form. The {{ $t('global.venue') | lowercase }} will have to accept the<br />
            T&C in order to complete the form.
          </p>
        </div>
        <ez-on-off
          form-key="onboardingOption"
          :checked="isSendOnboarding"
          @change="() => (isSendOnboarding = !isSendOnboarding)"
          name="inventoryTracking"
        />
      </div>
      <OnboardingOptionsForm
        @success="onSuccessOnboarding"
        :additionalData="additionalData"
        ref="onboardingForm"
        v-if="isSendOnboarding"
      />
    </paper>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<script>
/**
 * NewVenueOverview
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import Paper from '@/components/layout/WizardOverviewPaper.vue';
import EzButton from '@/components/ui/Button';
import EzEntityInfo from '@/components/ui/EntityInfo';
import User from '@/models/User';
import EzOnOff from '@/components/ui/OnOff/EzOnOff';
import OnboardingOptionsForm from '@/views/platform/distributor/outlets/OnboardingOptionsForm';
import {
  LOADING_KEY,
  NUMBER_OF_TAGS_TO_DISPLAY,
  TAGS_DISPLAY_TRESHOLD,
  TAX_CALCULATION_OPTIONS,
} from '@/util/constants';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzLoader from '@/components/ui/Loader/EzLoader';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import VTag from '@/components/v3/elements/VTag';
import { VENUE_STEP_1, VENUE_STEP_2, VENUE_STEP_3, VENUE_STEP_4 } from './steps';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzButton,
    EzEntityInfo,
    Paper,
    EzOnOff,
    OnboardingOptionsForm,
    EzLoadMore,
    EzSpinner,
    EzLoader,
    VProductEntityInfo,
    VTag,
  },
  data() {
    return {
      isSendOnboarding: false,
      products: [],
      firstPageProducts: [],
      meta: {},
      firstPageMetaCopy: {},
      limit: 10,
      expandBtnCopy: 'Expand List',
      iconDirection: 'down',
      tagsListExpanded: false,
    };
  },
  computed: {
    ...mapState('entities/venues', [
      'draft',
      'draftAdmin',
      'settingsStep',
      'newCustomerGroupLinkData',
    ]),
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    settingsData() {
      return this.settingsStep;
    },
    canExpand() {
      return this.products.length < this.limit * 2;
    },
    canLoadOnScroll() {
      return this.products.length >= this.limit * 2;
    },
    showExpandBtn() {
      return this.products?.length >= this.limit;
    },
    additionalData() {
      return {
        venueId: this.venue.id,
        name: this.venue.name,
      };
    },
    venue() {
      return this.draft;
    },
    users() {
      return User.query().where('groupId', this.draft.id).all();
    },
    hasSecondaryContact() {
      return this.venue.secondaryContactName || this.venue.secondaryContactPhone;
    },
    hasNotifications() {
      const { venue } = this;
      return venue.email || venue.smsNumber || venue.whatsAppNumber;
    },
    workingHours() {
      return this.venue.workingHours || {};
    },
    tax() {
      return this.settingsData.tax;
    },
    minimumOrderAmount() {
      return this.venue.minimumOrderAmount;
    },
    hasShippingAddress() {
      return !!this.venue.shippingAddress && Object.keys(this.venue.shippingAddress).length;
    },
    hasBillingAddress() {
      return !!this.venue.billingAddress && Object.keys(this.venue.billingAddress).length;
    },
    shippingAddress() {
      if (!this.hasShippingAddress) return '';
      return this.venue.shippingAddress.formattedAddress;
    },
    billingAddress() {
      if (!this.hasBillingAddress) return '';
      return this.venue.billingAddress.formattedAddress;
    },
    hasOrderingContact() {
      return Object.keys(this.draftAdmin.ordering).length;
    },
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS_LOAD_MORE);
    },
    numberOfTagsToDisplay() {
      if (this.venue.tags.length - NUMBER_OF_TAGS_TO_DISPLAY > TAGS_DISPLAY_TRESHOLD)
        return NUMBER_OF_TAGS_TO_DISPLAY;
      return this.venue.tags.length;
    },
    tagsToDisplay() {
      if (!this.tagsListExpanded) {
        return this.venue.tags.slice(0, this.numberOfTagsToDisplay);
      }
      return this.venue.tags;
    },
    expandButtonCopy() {
      return this.tagsListExpanded ? 'Collapse List' : 'Show more tags';
    },
    expandButtonIcon() {
      return this.tagsListExpanded ? 'angle-up' : 'angle-down';
    },
    taxCalculationLabel() {
      return TAX_CALCULATION_OPTIONS.find(
        option => option.value === this.settingsData.taxCalculation,
      )?.name;
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'distributorFetchVenueVisibleProducts',
      'connectDistributorToVenue',
    ]),
    ...mapMutations('entities/venues', ['CLEAR_DRAFT', 'CLEAR_SETTINGS_STEP']),
    async onNextStep() {
      if (this.isSendOnboarding) {
        this.$refs.onboardingForm.submitOnboardingForm();
        return;
      }
      await this.connectDistributorToVenue({ id: this.draft.id, data: { keepOptions: true } });
      this.CLEAR_DRAFT();
      this.CLEAR_SETTINGS_STEP();
      await this.$router.push({
        name: this.newCustomerGroupLinkData.route,
        params: {
          flash: {
            title: `${this.$t('global.venue')} created!`,
            type: 'success',
          },
          ...(this.newCustomerGroupLinkData.params ? this.newCustomerGroupLinkData.params : {}),
        },
      });
    },
    onSuccessOnboarding() {
      this.$router.push({
        name: 'distributor-outlets',
        params: {
          flash: {
            title: 'Onboarding link successfully sent!',
            message: `Good job! Onboarding link has been sent to ${this.venue.name}.`,
            type: 'success',
          },
        },
      });
      this.CLEAR_DRAFT();
    },
    editGroup() {
      this.$emit('stepBack', VENUE_STEP_1);
    },
    editSettings() {
      this.$emit('stepBack', VENUE_STEP_2);
    },
    editUsers() {
      this.$emit('stepBack', VENUE_STEP_3);
    },
    fetchVisibleProducts(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS) {
      const queryParams = {
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
        sortBy: 'name',
        limit: this.limit,
        hidden: 'false',
      };
      return this.distributorFetchVenueVisibleProducts({
        venueId: this.venue.id,
        query: queryParams,
        loadingKey,
      });
    },
    async refresh() {
      this.meta = {};
      const {
        data: { data, meta },
      } = await this.fetchVisibleProducts();
      this.products = data;
      this.meta = meta;

      this.firstPageProducts = this.products;
      this.firstPageMetaCopy = this.meta;
    },
    async loadMore() {
      const loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS_LOAD_MORE;
      const {
        data: { data, meta },
      } = await this.fetchVisibleProducts(loadingKey);
      this.products = [...this.products, ...data];
      this.meta = meta;
    },
    async expandList() {
      if (!this.canExpand) {
        await this.resetFilters();
        return;
      }
      await this.loadMore();
      this.expandBtnCopy = 'Collapse List';
      this.iconDirection = 'up';
    },
    async resetFilters() {
      this.products = this.firstPageProducts;
      this.meta = this.firstPageMetaCopy;
      this.resetExpandBtn();
    },
    resetExpandBtn() {
      this.expandBtnCopy = 'Expand List';
      this.iconDirection = 'down';
    },
    editProducts() {
      this.$emit('stepBack', VENUE_STEP_4);
    },
    toggleListExpanded() {
      this.tagsListExpanded = !this.tagsListExpanded;
    },
  },
  mounted() {
    this.refresh();
    this.enableNextStep();
  },
};
</script>

<style scoped lang="scss">
.formatted-address {
  white-space: pre-line;
}

.ez-info-panel__contact + .ez-info-panel__contact {
  margin-top: 1rem;
}

:deep() .entity-info {
  flex-direction: column;
  align-items: center;
  &__text {
    margin: 0.75rem 0 0;
    justify-content: center;
  }
}

.address {
  @include separator('both');
}
.overview-item:not(:last-child) {
  @include separator('bottom');
}

h3 {
  @include font-size(12px, 14px);
  margin: 0 0 16px;
  padding: 0;
  color: #6c7995;
  font-weight: 500;
  text-transform: uppercase;
}
.onboarding-option {
  display: flex;
  justify-content: space-between;
}
.dashed-line {
  max-width: 400px;
  margin: 20px auto;
}
.contact-item {
  @extend %flex-center;
  justify-content: space-between;
  @include font-size(14px, 16px);
  padding: 10px 0;
  &__name {
    color: $color-gray-25;
  }
  &__type {
    color: $color-gray-6C;
  }
}
.product-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  .item {
    width: 100%;
  }
  .entity-info {
    flex-direction: initial;
    align-items: center;
    :deep() .entity-info__text {
      margin-top: 0;
    }
  }
}
.footer-paper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.products-paper {
  max-height: 900px;
  :deep() .overview-paper__content {
    overflow-y: auto;
    max-height: 720px;
  }
}
.all-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}
.tags-number-indicator {
  @include font-size(14px, 16px);
  color: $color-gray-6C;
}
:deep() .info .all-tags .v-tag span {
  @include font-size(11px, 14px);
}
</style>
