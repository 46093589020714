<script>
import {
  distributorGetXeroAccountTypes,
  distributorGetXeroTaxes,
  distributorPostAccountSetup,
  distributorGetXeroAccountSetup,
} from '@/api/endpoints/xero';
import EzButton from '@/components/ui/Button/EzButton';
import xeroGuard from '@/views/platform/distributor/xero/settings/xeroGuard';
import flash from '@/components/ui/FlashMessage';
import { mapActions } from 'vuex';
import EzLoader from '@/components/ui/Loader';
import VXeroBillTypeInput from '@/components/v3/patterns/VXeroBillTypeInput';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

/**
 * Xero Account Info
 * @version 1.0.0
 * @since
 */

export default {
  mixins: [xeroGuard],
  components: {
    VXeroBillTypeInput,
    EzButton,
    VSelectSearch,
    EzLoader,
  },
  data() {
    return {
      loading: false,
      buttonDisabled: true,
      accountsCOGS: [],
      accountsRevenue: [],
      creditNotes: [],
      accountsExpenses: [],
      accountsInventory: [],
      taxes: [],
      taxesExpenses: [],
      expensesTypes: [],
      expensesSelected: {
        check_in: '',
      },
      orderDiscounts: [],
      allAccounts: [],
      values: {
        accountCode: '',
        cogsAccountCode: '',
        inventoryAccountCode: '',
        deliveryFeeAccountCode: '',
        taxType: '',
        expensesTaxType: '',
        combinedExpenseBill: '',
        expenseBillCreateDay: null,
        creditNoteCode: '',
        orderDiscountAccountCode: '',
      },
    };
  },
  methods: {
    ...mapActions('reports', ['fetchExpenseTypes']),
    onChangeUpdate(updateValName, value) {
      this.buttonDisabled = false;
      this.values[updateValName] = value;
    },
    changeBill(type, item) {
      this.buttonDisabled = false;
      this.expensesSelected[type] = item.code;
    },
    async saveChanges() {
      const accountSettings = {
        accountCode: this.values.accountCode,
        cogsAccountCode: this.values.cogsAccountCode,
        inventoryAccountCode: this.values.inventoryAccountCode,
        deliveryFeeAccountCode: this.values.deliveryFeeAccountCode,
        taxType: this.values.taxType,
        ...(this.values.expensesTaxType && { expensesTaxType: this.values.expensesTaxType }),
        expensesAccountCode: {
          ...Object.entries(this.expensesSelected)
            // eslint-disable-next-line no-return-assign
            .reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {}),
        },
        combinedExpenseBill: this.values.combinedExpenseBill,
        expenseBillCreateDay: this.values.expenseBillCreateDay,
        nonStockCreditAccountCode: this.values.creditNoteCode,
        orderDiscountAccountCode: this.values.orderDiscountAccountCode,
      };
      try {
        await distributorPostAccountSetup(accountSettings);
        flash.success({
          title: this.$t('xero.settings.account.flashMessages.success.title'),
        });
        this.buttonDisabled = true;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({
          title: this.$t('xero.settings.account.flashMessages.error.title'),
        });
      }
    },
  },
  async created() {
    try {
      this.loading = true;

      const [
        { data: accounts },
        { data: taxes },
        { data: taxesExpenses },
        { data: expensesTypes },
      ] = await Promise.all([
        distributorGetXeroAccountTypes(),
        distributorGetXeroTaxes(),
        distributorGetXeroTaxes({ type: 'expense' }),
        this.fetchExpenseTypes(),
      ]);

      this.accountsCOGS = accounts.data.cogs;
      this.accountsRevenue = accounts.data.revenue;
      this.accountsExpenses = accounts.data.expense;
      this.accountsInventory = accounts.data.inventory;
      this.creditNotes = accounts.data.creditNotes;
      this.orderDiscounts = accounts.data.orderDiscount;
      this.allAccounts = [
        ...accounts.data.revenue,
        ...accounts.data.expense,
        ...accounts.data.inventory,
      ];

      this.taxes = taxes.data;
      this.taxesExpenses = taxesExpenses.data;
      this.expensesTypes = expensesTypes.data;
      this.expensesTypes.forEach((item) => {
        this.expensesSelected[item.key] = '';
      });

      const {
        data: { data: setupData },
      } = await distributorGetXeroAccountSetup();
      this.values = {
        accountCode: setupData.accountCode,
        cogsAccountCode: setupData.cogsAccountCode,
        inventoryAccountCode: setupData.inventoryAccountCode,
        deliveryFeeAccountCode: setupData.deliveryFeeAccountCode,
        taxType: setupData.taxType,
        expensesTaxType: setupData.expensesTaxType,
        combinedExpenseBill: setupData.combinedExpenseBill?.toString(),
        expenseBillCreateDay: setupData.expenseBillCreateDay,
        creditNoteCode: setupData.nonStockCreditAccountCode,
        orderDiscountAccountCode: setupData.orderDiscountAccountCode,
      };

      const expensesAccountCode = setupData.expensesAccountCode || [];
      Object.keys(this.expensesSelected).forEach((key) => {
        this.expensesSelected[key] = expensesAccountCode[key] || '';
      });
      this.loading = false;
    } catch (e) {
      this.loading = false;
      // eslint-disable-next-line no-console
      console.error(e);
      flash.error({
        title: 'Something went wrong',
      });
    }
  },
};
</script>
<template>
  <div class="container--small">
    <div class="help-block mb-16 distributor-info">
      <h4 class="distributor-info__title">Invoices</h4>
    </div>
    <v-select-search
      class="account-dropdown width-100"
      align-left
      is-full-width
      name="accountCode"
      label="Invoices Account"
      :data="accountsRevenue"
      value-property="code"
      :placeholder="`Select ${$t('xero.name')} Account`"
      :searchPlaceholder="`Search ${$t('xero.name')} Account`"
      :selected="values.accountCode"
      :has-clear="false"
      @selected="onChangeUpdate('accountCode', $event.code)"
    >
      <template #result="{result}">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="mt-12"
      align-left
      is-full-width
      name="taxType"
      label="Tax Rate"
      :data="taxes"
      value-property="taxType"
      placeholder="Select Tax Rate"
      searchPlaceholder="Search Tax Rate"
      :selected="values.taxType"
      :has-clear="false"
      @selected="onChangeUpdate('taxType', $event.taxType)"
    >
      <template #result="{result}">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="mt-12"
      align-left
      is-full-width
      name="deliveryFeeAccountCode"
      label="Delivery Fee Account"
      :data="accountsRevenue"
      value-property="code"
      placeholder="Select Delivery Fee Account"
      searchPlaceholder="Search Delivery Fee Account"
      :selected="values.deliveryFeeAccountCode"
      :has-clear="false"
      @selected="onChangeUpdate('deliveryFeeAccountCode', $event.code)"
    >
      <template #result="{result}">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="mt-12"
      align-left
      is-full-width
      name="orderDiscountAccountCode"
      label="Order Discount Account"
      :data="orderDiscounts"
      value-property="code"
      placeholder="Select Order Discount Account"
      searchPlaceholder="Search Order Discount Account"
      :selected="values.orderDiscountAccountCode"
      :has-clear="false"
      @selected="onChangeUpdate('orderDiscountAccountCode', $event.code)"
    >
      <template #result="{result}">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <hr />
    <div class="help-block mb-16 distributor-info">
      <h4 class="distributor-info__title">Credit Notes</h4>
    </div>
    <v-select-search
      align-left
      is-full-width
      name="nonStockCreditAccountCode"
      label="Non-Stock Credit Account"
      :data="creditNotes"
      value-property="code"
      placeholder="Select Non-Stock Credit Account"
      searchPlaceholder="Search Non-Stock Credit Account"
      :selected="values.creditNoteCode"
      :has-clear="false"
      @selected="onChangeUpdate('creditNoteCode', $event.code)"
    >
      <template #result="{result}">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <hr />
    <div class="help-block mb-16 distributor-info">
      <h4 class="distributor-info__title">Inventory Accounting</h4>
    </div>
    <v-select-search
      align-left
      is-full-width
      name="inventoryAccountCode"
      label="Inventory Account"
      :data="accountsInventory"
      value-property="code"
      :placeholder="`Select ${$t('xero.name')} Account`"
      :searchPlaceholder="`Search ${$t('xero.name')} Account`"
      :selected="values.inventoryAccountCode"
      @selected="onChangeUpdate('inventoryAccountCode', $event.code ? $event.code : null)"
    >
      <template #result="{result}">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      align-left
      is-full-width
      name="cogsAccountCode"
      label="COGS Account"
      class="mt-12"
      :data="accountsCOGS"
      value-property="code"
      :placeholder="`Select ${$t('xero.name')} Account`"
      :searchPlaceholder="`Search ${$t('xero.name')} Account`"
      :selected="values.cogsAccountCode"
      @selected="onChangeUpdate('cogsAccountCode', $event.code ? $event.code : null)"
    >
      <template #result="{result}">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <hr />
    <div class="help-block mb-16 distributor-info">
      <h4 class="distributor-info__title">Bills</h4>
    </div>
    <v-select-search
      align-left
      is-full-width
      name="AccountCheckInExpenses"
      label="Team Activity Expenses"
      :data="accountsExpenses"
      value-property="code"
      :placeholder="`Select ${$t('xero.name')} Account`"
      :searchPlaceholder="`Search ${$t('xero.name')} Account`"
      :selected="expensesSelected.check_in"
      :has-clear="false"
      @selected="changeBill('check_in', $event)"
    >
      <template #result="{result}">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      v-for="type in expensesTypes"
      align-left
      class="mt-12"
      :key="type.key"
      is-full-width
      :name="`${type.key}Expenses`"
      :label="`${type.name} Expenses`"
      :data="accountsExpenses"
      value-property="code"
      :placeholder="`Select ${$t('xero.name')} Account`"
      :searchPlaceholder="`Search ${$t('xero.name')} Account`"
      :selected="expensesSelected[type.key]"
      :has-clear="false"
      @selected="changeBill(type.key, $event)"
    >
      <template #result="{result}">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      align-left
      class="mt-12"
      is-full-width
      name="expensesTaxType"
      label="Tax Rate"
      :data="taxesExpenses"
      value-property="taxType"
      placeholder="Select Tax Rate"
      searchPlaceholder="Search Tax Rate"
      :selected="values.expensesTaxType"
      :has-clear="false"
      @selected="onChangeUpdate('expensesTaxType', $event.taxType)"
    >
      <template #result="{result}">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <hr />

    <v-xero-bill-type-input
      form-key=""
      :selected-type="values.combinedExpenseBill"
      :selected-day="values.expenseBillCreateDay"
      @changeType="onChangeUpdate('combinedExpenseBill', $event)"
      @changeDay="onChangeUpdate('expenseBillCreateDay', $event.value)"
    />

    <ez-button @click="saveChanges" :disabled="buttonDisabled" class="mt-24">
      {{ $t('xero.settings.account.buttonText') }}
    </ez-button>
    <ez-loader :show="loading">Loading...</ez-loader>
  </div>
</template>
<style lang="scss" scoped>
:deep() .select-search__trigger {
  span {
    color: $color-gray-6C;
  }
  svg {
    color: $color-gray-6C;
  }
  span.select-search__value {
    color: $color-gray-25;
  }
}

:deep() .select-search--disabled .select-search__trigger {
  span, svg {
    color: $input-disabled-color;
  }
}

:deep() .select-search__list li.select-search__item {
  padding: 0.625rem 1rem;
}
:deep() .select-search__list li.select-search__item.clear {
  padding: 0rem 1rem;
}
</style>
