<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';

import { wizardListenerMixin } from '@/mixins/wizard';
import {
  LOADING_KEY,
  CREDIT_NOTE_STATUS_DRAFT,
  CREDIT_NOTE_STATUS_APPROVED,
} from '@/util/constants';
import { clone } from '@/util/utils';

import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo.vue';
import EzTextarea from '@/components/ui/Textarea/EzTextarea.vue';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table/EzTable.vue';
import EzOnOff from '@/components/ui/OnOff/EzOnOff.vue';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import EzLiteBadge from '@/components/ui/LiteBadge';
import flash from '@/components/ui/FlashMessage';

import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import VEntityInfo from '@/components/v3/elements/VEntityInfo/index';
import VPrice from '@/components/v3/elements/VPrice';
import Paper from '@/components/layout/WizardOverviewPaper.vue';

import { NEW_CREDIT_NOTE_STEP_1, NEW_CREDIT_NOTE_STEP_2 } from './steps';

/**
 * Overview
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Overview',
  components: {
    EzEntityInfo,
    EzTextarea,
    EzLoader,
    EzButton,
    EzTable,
    EzOnOff,
    EzPremiumBadge,
    EzLiteBadge,
    VSelectSearch,
    VEntityInfo,
    VPrice,
    Paper,
  },
  data() {
    return {
      productsListExpanded: false,
      returnToWarehouse: false,
      warehouses: [],
      warehouse: {},
      note: '',
      nonStockCredit: null,
      totals: {},
      CREDIT_NOTE_STATUS_DRAFT,
      CREDIT_NOTE_STATUS_APPROVED,
      supplierCy,
    };
  },
  computed: {
    ...mapState('creditNotes', ['draft']),
    ...mapGetters('creditNotes', {
      venue: 'getDraftVenue',
      selectedProducts: 'getDraftProducts',
      fulfilmentWarehouse: 'getFulfilmentWarehouse',
      isFromOrder: 'getIsFromOrder',
      orderId: 'getCreditNoteOrderId',
    }),
    ...mapGetters('loading', ['getLoading']),
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_CREATE_CREDIT_NOTE);
    },
    expandButtonCopy() {
      return this.productsListExpanded ? 'Collapse List' : 'Expand List';
    },
    expandButtonIcon() {
      return this.productsListExpanded ? 'angle-up' : 'angle-down';
    },
    products() {
      if (!this.productsListExpanded) return this.selectedProducts.slice(0, 6);
      return this.selectedProducts;
    },
    allProductsUntracked() {
      return this.selectedProducts.every(p => !p.inventoryTracked);
    },
    hasApproveCreditNotePermission() {
      return this.$permission.has('approveCreditNote');
    },
    appendWarehouse() {
      return this.returnToWarehouse && Object.keys(this.warehouse).length > 0;
    },
    toggleTooltip() {
      if (!this.allProductsUntracked) return undefined;
      return {
        content: 'No products are tracked in inventory',
        classes: ['tooltip--reset-margin', 'my-8'],
      };
    },
    hasProducts() {
      return !!this.selectedProducts?.length;
    },
    showExpandButton() {
      return this.selectedProducts?.length > 6;
    },
    content() {
      const { TEXT__TOTAL_TOOLTIP } = supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW;

      return `
      <div class="tax" data-cy="${TEXT__TOTAL_TOOLTIP}">
        <div class="tax__item">
          <strong class="tax__label">Stock Credit:</strong>
          <span class="tax__number" data-cy="${TEXT__TOTAL_TOOLTIP}-stockCredit">
            ${this.$helpers.formatPrice(this.totals.stockCredit)}
          </span>
        </div>
        <div class="tax__item">
          <strong class="tax__label">Non-Stock Credit:</strong>
          <span class="tax__number" data-cy="${TEXT__TOTAL_TOOLTIP}-nonStockCredit">
            ${this.$helpers.formatPrice(this.totals.nonStockCredit)}
          </span>
        </div>
        <div class="tax__item">
          <strong class="tax__label" data-cy="${TEXT__TOTAL_TOOLTIP}-tax">
            Tax (${this.totals.tax}%):
          </strong>
          <span class="tax__number" data-cy="${TEXT__TOTAL_TOOLTIP}-taxAmount">
            ${this.$helpers.formatPrice(this.totals.taxAmount)}
          </span>
        </div>
        <div class="tax__item tax__item--total">
          <strong class="tax__label">Total:</strong>
          <span class="tax__number" data-cy="${TEXT__TOTAL_TOOLTIP}-amount">
            ${this.$helpers.formatPrice(this.totals.amount)}
          </span>
        </div>
      </div>`;
    },
    tooltip() {
      return {
        html: true,
        placement: 'top-end',
        classes: ['tooltip--reset-margin', 'tooltip--lift-up'],
        content: this.content,
      };
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    ...mapMutations('creditNotes', ['UPDATE_DRAFT', 'CLEAR_DRAFT']),
    ...mapActions('creditNotes', [
      'distributorCreateCreditNote',
      'distributorFetchCreditNoteAmounts',
    ]),
    onPreviousStep() {
      this.$emit('stepBack');
    },
    editVenue() {
      this.$emit('stepBack', NEW_CREDIT_NOTE_STEP_1);
    },
    editProducts() {
      this.$emit('stepBack', NEW_CREDIT_NOTE_STEP_2);
    },
    toggleListExpanded() {
      this.productsListExpanded = !this.productsListExpanded;
    },
    generateCreditNoteData(isApproved = false) {
      return {
        venueId: this.venue.id,
        status: isApproved ? CREDIT_NOTE_STATUS_APPROVED : CREDIT_NOTE_STATUS_DRAFT,
        nonStockCredit: this.nonStockCredit,
        note: this.note,
        ...(this.hasProducts
          ? {
            items: this.selectedProducts?.map(pr => ({
              productId: pr.id,
              quantity: pr.quantity,
              batchCode: pr.batchCode,
              expiryDate: pr.expiryDate,
              price: pr.price,
            })),
          }
          : {}),
        ...(this.appendWarehouse ? { warehouseId: this.warehouse.id } : {}),
        ...(this.isFromOrder ? { orderId: this.orderId } : {}),
      };
    },
    async submitData(isApproved = false) {
      const generatedData = this.generateCreditNoteData(isApproved);
      try {
        await this.distributorCreateCreditNote({ data: generatedData });
        flash.success({ title: 'Credit Note added successfully.' });
      } catch (err) {
        if (err?.response?.data?.error?.type === 'XeroAPIException') {
          flash.fail({
            title: 'Something went wrong!',
            message: err.response.data.error.message,
            delay: 6000,
          });
        }
      }

      this.CLEAR_DRAFT();
      this.$router.push({ name: 'distributor-credit-notes' });
    },
    async initTotals() {
      const { data } = await this.distributorFetchCreditNoteAmounts({
        data: this.generateCreditNoteData(),
      });
      this.totals = {
        stockCredit: data.data.stockCredit,
        nonStockCredit: data.data.nonStockCredit,
        taxAmount: data.data.taxAmount,
        amount: data.data.amount,
        tax: data.data.tax,
      };
    },
    async onNextStep() {
      await this.submitData();
    },
    async onGoToStep() {
      await this.submitData(this.hasApproveCreditNotePermission);
    },
  },
  async created() {
    const { data } = await this.distributorFetchWarehouses();
    this.warehouses = clone(data.data);

    const draftWarehouse = Object.keys(this.draft.warehouse).length ? this.draft.warehouse : null;
    const venueWarehouse = this.warehouses.find(
      w => w.id === this.venue?.connection?.primaryWarehouseId,
    );
    const primaryWarehouse = this.warehouses.find(w => w.primary);

    this.returnToWarehouse = this.draft.returnToWarehouse || false;
    this.warehouse = this.isFromOrder
      ? this.fulfilmentWarehouse
      : clone(draftWarehouse || venueWarehouse || primaryWarehouse || {});
    this.note = this.draft.note || '';
    this.nonStockCredit = this.draft.nonStockCredit || null;

    await this.initTotals();
  },
  watch: {
    returnToWarehouse(val) {
      this.UPDATE_DRAFT({ returnToWarehouse: val });
    },
    warehouse(val) {
      this.UPDATE_DRAFT({ warehouse: val });
    },
    note(val) {
      this.UPDATE_DRAFT({ note: val });
    },
  },
};
</script>

<template>
  <div>
    <paper fullWidth class="venue-section">
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editVenue"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.BUTTON__EDIT_CUSTOMER"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <template>
        <v-entity-info
          class="venue"
          imageSize="64px"
          :imageUrl="venue.logo"
          imageBorderRadius="50%"
          imageHasBorder
        >
          <template>
            <span
              :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.TEXT__CUSTOMER_NAME"
            >
              {{ venue.name }}
            </span>
          </template>
          <template #suffix>
            <ez-premium-badge v-if="$helpers.isPremium(venue.accountType)" />
            <ez-lite-badge v-if="$helpers.isLite(venue.accountType)" />
          </template>
        </v-entity-info>
      </template>
      <template #footer v-if="draft.nonStockCredit">
        <div class="non-stock-credit">
          <hr class="mt-16 mb-16" />
          <p class="non-stock-credit__label m-0">NON-STOCK CREDIT</p>
          <p
            class="non-stock-credit__value m-0"
            :data-cy="
              supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.TEXT__NON_STOCK_CREDIT
            "
          >
            {{ draft.nonStockCredit | price }}
          </p>
        </div>
      </template>
    </paper>

    <paper fullWidth class="products-section">
      <template #title>
        <span
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.TEXT__PRODUCTS_HEADER"
        >
          PRODUCTS ({{ selectedProducts.length }})</span
        >
      </template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editProducts"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.BUTTON__EDIT_PRODUCTS"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div>
        <ez-table
          v-if="hasProducts"
          :data="products"
          :columns="['name', 'quantity', 'price']"
          :headers="{
            name: () => 'Product',
            quantity: () => 'Qty',
          }"
          :columnProps="{
            name: { class: 'name-cell' },
            quantity: { class: 'u-text-right medium-cell' },
            price: { class: 'price-cell' },
          }"
          disableHover
          class="products-table"
        >
          <template #cell-name="{ row, row: { name, sku, image, orderingUnit } }">
            <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="image">
              <div class="product-info" :title="name">
                <span
                  class="product-info__name"
                  v-tooltip="{ content: name }"
                  :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.TEXT__PRODUCT_NAME}-${row.id}`"
                >
                  {{ name }}
                </span>
                <div class="product-info__secondary">
                  <span
                    v-if="orderingUnit"
                    class="product-info__unit"
                    :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.TEXT__PRODUCT_CATEGORY}-${row.id}`"
                  >
                    {{ orderingUnit.label }}
                  </span>
                  <span v-if="orderingUnit && sku" class="product-info__bull ml-2 mr-2">
                    &bull;
                  </span>
                  <span
                    v-if="sku"
                    class="product-info__sku"
                    :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.TEXT__PRODUCT_SKU}-${row.id}`"
                  >
                    {{ sku }}
                  </span>
                </div>
              </div>
            </ez-entity-info>
          </template>
          <template #cell-quantity="{ row, row: { quantity } }">
            <span
              :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.TEXT__PRODUCT_QUANTITY}-${row.id}`"
            >
              {{ quantity }}
            </span>
          </template>
          <template #cell-price="{ row, row: { price, currency } }">
            <v-price
              :price="price || 0"
              :currency="currency"
              :show-market-price-info="false"
              :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.TEXT__PRODUCT_PRICE}-${row.id}`"
            />
          </template>
        </ez-table>
      </div>
      <template #footer>
        <div v-if="showExpandButton" class="u-flex-h-center mt-16">
          <ez-button
            @click="toggleListExpanded"
            type="link"
            formType="button"
            :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.BUTTON__TOGGLE_LIST"
          >
            <span>{{ expandButtonCopy }}</span>
            <font-awesome-icon class="ml-8" :icon="expandButtonIcon" />
          </ez-button>
        </div>

        <hr v-if="showExpandButton || !hasProducts" class="solid-separator" />

        <span class="totals">
          <span v-tooltip="tooltip" class="totals__label">
            TOTAL (INCL. TAXES) <font-awesome-icon icon="info-circle" class="ml-8" />
          </span>
          <span
            class="totals__total"
            :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.TEXT__TOTAL_AMOUNT"
          >
            {{ $helpers.formatPrice(totals.amount) }}
          </span>
        </span>
      </template>
    </paper>

    <paper v-if="hasProducts" fullWidth class="warehouse-section">
      <template>
        <div class="warehouse-section__toggle">
          <div class="help-block">
            <h4 class="help-block__title">Return inventory to the warehouse</h4>
            <p class="help-block__text">
              If the toggle is on, select the warehouse to which products should be returned. Only
              tracked products can be returned to inventory.
            </p>
          </div>
          <ez-on-off
            formKey=""
            name="returnToWarehouse"
            :value="returnToWarehouse.toString()"
            :checked="returnToWarehouse"
            :disabled="allProductsUntracked"
            @change="returnToWarehouse = $event"
            v-tooltip="toggleTooltip"
            :data-cy="
              supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.LABEL__RETURN_TO_INVENTORY
            "
          />
        </div>
        <v-select-search
          v-if="returnToWarehouse"
          name="warehouse"
          label="Warehouse"
          placeholder="Choose Warehouse"
          :hasClear="false"
          :data="warehouses"
          :selected="warehouse"
          @selected="warehouse = $event"
          isFullWidth
          class="warehouse-section__dropdown mt-12"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.BUTTON__WAREHOUSE"
        />
      </template>
    </paper>

    <paper fullWidth>
      <template #title>NOTE</template>
      <template>
        <ez-textarea
          formKey=""
          label=""
          name="note"
          placeholder="Why was this credit note created?"
          :value="note"
          @onChange="note = $event"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.TEXTAREA__NOTE"
        />
      </template>
    </paper>

    <ez-loader :show="loading" />
  </div>
</template>

<style lang="scss" scoped>
.venue-section :deep() {
  .overview-paper__content {
    display: flex;
    justify-content: center;
  }

  .entity-info {
    margin-top: -36px;
    flex-direction: column;
    align-items: center;

    &__info {
      justify-content: center;
      margin-left: 0;
      margin-top: 8px;
      @include font-size(16px, 22px);
      font-weight: 600;
      color: $color-gray-25;
    }
  }

  .non-stock-credit {
    &__label {
      @include font-size(12px, 18px);
      color: $color-gray-6C;
    }

    &__value {
      @include font-size(14px, 20px);
      color: $color-gray-25;
    }
  }
}

.products-section :deep() {
  .overview-paper__title {
    margin-bottom: 0;
  }

  .products-table {
    margin-top: 18px;

    .name-cell {
      .product-info {
        display: flex;
        flex-direction: column;

        &__secondary {
          display: flex;
          align-items: center;
        }

        &__name,
        &__unit {
          font-weight: 500;
          color: $color-gray-25;
        }

        &__bull,
        &__sku {
          color: $color-gray-6C;
        }

        span {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .solid-separator {
    margin: $spacing-16 0;
    border-top: 1px solid $color-gray-E1;
  }

  .totals {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;

    &__label {
      display: flex;
      align-items: center;
      @include font-size(12px, 16px);
      color: $color-gray-6C;
      font-weight: 600;

      svg {
        width: $spacing-16;
        height: $spacing-16;
      }
    }

    &__total {
      @include font-size(16px, 22px);
      color: $color-gray-25;
      font-weight: 600;
    }
  }
}

.warehouse-section {
  &__toggle {
    display: flex;
    align-items: flex-start;

    .help-block {
      margin-right: 16px;
      margin-bottom: 0;

      &__text {
        @include font-size(14px);
      }
    }
  }

  &__dropdown {
    :deep() .select-search {
      &__trigger span {
        @include font-size(14px, 20px);

        &:not(.select-search__value) {
          color: $color-gray-6C;
        }
      }

      &__search-wrapper {
        display: none;
      }

      &__item {
        @include font-size(14px, 20px);
      }
    }
  }
}
</style>
