<script>
/**
 * Integrations
 * @version 1.0.0
 * @since 2.4.0
 */

import VStatusBadge from '@/components/v3/elements/VStatusBadge';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import VSwitchTabs from '@/components/v3/patterns/VSwitchTabs';
import { EzTab } from '@/components/ui/Tabs';
import logoXero from '@/assets/logo-xero.png';
import logoProvino from '@/assets/logo-provino.png';
import logoHubDoc from '@/assets/logo-hubdoc.svg';
import logoNetsuite from '@/assets/logo-netsuite.png';
import logoQB from '@/assets/logo-qb.png';
import { INTEGRATION_STATUSES, INTEGRATIONS_TYPES, LOADING_KEY } from '@/util/constants';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';

export default {
  components: {
    VStatusBadge,
    EzEntityInfo,
    VSwitchTabs,
    EzTab,
    EzLoader,
    EzLoadMore,
  },
  data() {
    return {
      connectedData: [],
      connectedMeta: {},
      availableData: [],
      availableMeta: {},
      INTEGRATION_STATUSES,
    };
  },
  computed: {
    ...mapGetters('entities/users', [
      'getDistributor',
      'isDistributorXeroConnected',
      'isDistributorQuickBooksConnected',
      'isDistributorProvinoConnected',
      'isDistributorHubDocConnected',
      'isDistributorNetSuiteConnected',
    ]),
    ...mapGetters('entities/distributors', [
      'isSelectedEcommerceConnected',
      'isSelectedEcommerceNeedToUpdate',
    ]),
    /**
     * If not connected navigate to not connected route.
     * If connected navigate to account info route.
     */
    xeroIntegrationRoute() {
      return this.isDistributorXeroConnected
        ? 'distributor-xero-account'
        : 'distributor-xero-not-connected';
    },
    quickBooksIntegrationRoute() {
      return this.isDistributorQuickBooksConnected
        ? 'distributor-quick-books-account'
        : 'distributor-quick-books-not-connected';
    },
    eCommerceIntegrationRoute() {
      return this.isSelectedEcommerceConnected || this.isSelectedEcommerceNeedToUpdate
        ? 'distributor-e-commerce-products'
        : 'distributor-e-commerce-not-connected';
    },
    netsuiteIntegrationRoute() {
      return this.isDistributorNetSuiteConnected
        ? 'distributor-netsuite-account-info'
        : 'distributor-netsuite-not-connected';
    },
    integrations() {
      return [
        {
          icon: logoXero,
          title: this.isDistributorXeroConnected
            ? this.getDistributor.xero?.tenantName
            : this.$t('xero.name'),
          status: this.status(this.isDistributorXeroConnected),
          route: this.xeroIntegrationRoute,
        },
        {
          icon: logoProvino,
          title: this.$t('provino.name'),
          status: this.status(this.isDistributorProvinoConnected),
          route: 'distributor-provino-account',
        },
        {
          icon: logoHubDoc,
          title: this.$t('hubDoc.name'),
          status: this.status(this.isDistributorHubDocConnected),
          route: 'distributor-hub-doc-account',
        },
        {
          icon: logoNetsuite,
          title: this.$t('netsuite.name'),
          status: this.status(this.isDistributorNetSuiteConnected),
          route: this.netsuiteIntegrationRoute,
        },
        {
          icon: logoQB,
          title: this.isDistributorQuickBooksConnected
            ? this.getDistributor?.quickBooks?.name
            : this.$t('quickBooks.name'),
          status: this.status(this.isDistributorQuickBooksConnected),
          route: this.quickBooksIntegrationRoute,
        },
      ];
    },
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.ME, LOADING_KEY.DISTRIBUTOR_FETCH_INTEGRATIONS]);
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributorLoggedUser']),
    ...mapActions('entities/distributors', [
      'distributorFetchAvailableIntegrations',
      'distributorFetchConnectedIntegrations',
    ]),
    ...mapMutations('entities/distributors', [
      'SET_SELECTED_INTEGRATION',
      'CLEAR_SELECTED_INTEGRATION',
    ]),
    status(data) {
      return data ? INTEGRATION_STATUSES.connected : INTEGRATION_STATUSES.notConnected;
    },
    isConnected(data) {
      return data === INTEGRATION_STATUSES.connected;
    },
    isNotEnabled(data) {
      return data === INTEGRATION_STATUSES.notEnabled;
    },
    isUpdateAvailable(data) {
      return data === INTEGRATION_STATUSES.updateNeeded;
    },
    isECommerce(type) {
      return type === INTEGRATIONS_TYPES.eCommerce;
    },
    isTray(type) {
      return type === INTEGRATIONS_TYPES.default;
    },
    hasWarning(integration) {
      return !!integration.warning;
    },
    async fetchAvailableIntegrations() {
      const { data } = await this.distributorFetchAvailableIntegrations({
        query: this.availableMeta?.hasNextPage ? this.availableMeta?.endCursor : '',
      });
      this.availableData = [
        ...this.availableData,
        ...this.integrations.filter(int => !this.isConnected(int.status)),
        ...data.data,
      ];
      this.availableMeta = data?.meta;
    },
    async fetchConnectedIntegrations() {
      const { data: connected } = await this.distributorFetchConnectedIntegrations({
        query: this.connectedMeta?.hasNextPage ? this.connectedMeta?.endCursor : '',
      });
      this.connectedData = [
        ...this.connectedData,
        ...this.integrations.filter(int => this.isConnected(int.status)),
        ...connected.data,
      ];
      this.connectedMeta = connected?.meta;
    },
    whichRoute(item) {
      if (item.route) {
        return { name: item.route };
      }
      if (this.isECommerce(item.type)) {
        return { name: this.eCommerceIntegrationRoute };
      }
      if (this.isTray(item.type)) {
        return { name: 'distributor-e-commerce-not-connected' };
      }
      return '';
    },
    async navigate(integration) {
      await this.SET_SELECTED_INTEGRATION(integration);
      const route = this.whichRoute(integration);
      await this.$router.push(route);
    },
  },
  async created() {
    await this.initDistributorLoggedUser();
    this.availableData = this.integrations.filter(int => !this.isConnected(int.status));
    this.connectedData = this.integrations.filter(int => this.isConnected(int.status));
  },
};
</script>
<template>
  <div class="container--small">
    <div class="help-block mb-16">
      <h4 class="help-block__title">Connect to Other Platforms</h4>
      <p class="help-block__text">
        Connect to other platforms to keep track of and manage your inventory or invoices.
      </p>
    </div>
    <v-switch-tabs :defaultTabIndex="0">
      <ez-tab title="Connected" hash="tab-1">
        <div class="integrations-list">
          <div
            v-for="(connected, ind) in connectedData"
            :key="`${connected.title}${ind}`"
            class="integrations-list__item"
            @click="navigate(connected)"
          >
            <ez-entity-info :img-url="connected.icon" img-border-radius="50%">
              <div class="info-wrapper">
                <span class="ml-4 name">{{ connected.title }}</span>
              </div>
            </ez-entity-info>
            <div class="integrations-list__actions">
              <span v-if="hasWarning(connected)" class="status-icon--warning">
                <font-awesome-icon icon="exclamation-circle" />
              </span>
              <v-status-badge type="green" v-if="isConnected(connected.status)">
                {{ $t('integrations.actions.allSet') }}
              </v-status-badge>
              <v-status-badge type="orange" v-if="isNotEnabled(connected.status)">
                {{ $t('integrations.actions.actionNeeded') }}
              </v-status-badge>
              <v-status-badge type="blue" v-if="isUpdateAvailable(connected.status)">
                {{ $t('integrations.actions.actionUpdate') }}
              </v-status-badge>
            </div>
          </div>
          <ez-load-more
            v-if="connectedMeta && connectedMeta.hasNextPage && !isLoading"
            @loadMore="fetchConnectedIntegrations"
          />
        </div>
      </ez-tab>
      <ez-tab title="Available" hash="tab-2">
        <div class="integrations-list">
          <div
            v-for="(available, ind) in availableData"
            :key="`${available.title}${ind}`"
            class="integrations-list__item"
            @click="navigate(available)"
          >
            <ez-entity-info :img-url="available.icon" img-border-radius="50%">
              <div class="info-wrapper">
                <span class="ml-4 name name--available">{{ available.title }}</span>
              </div>
            </ez-entity-info>
          </div>
          <ez-load-more
            v-if="availableMeta && availableMeta.hasNextPage && !isLoading"
            @loadMore="fetchAvailableIntegrations"
          />
        </div>
      </ez-tab>
    </v-switch-tabs>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>
<style lang="scss" scoped>
.integrations-list {
  margin-top: 36px;
  .label {
    @include font-size(12px, 16px);
    font-weight: bold;
    color: $color-gray-6C;
    margin: 24px 0 16px 0;
  }

  &__actions {
    @extend %flex-center;

    .status-icon {
      &--warning {
        margin-right: 12px;
        @include font-size(16px);
        color: $color-primary-orange;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #e9ebf2;
    text-decoration: none;
    cursor: pointer;
    .info-wrapper {
      display: flex;
      flex-direction: column;
      color: $color-gray-25;
      width: 100%;
      .name {
        @include font-size(14px, 22px);
        font-weight: bold;
        max-width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        &--available {
          max-width: 100%;
        }
      }
    }
  }

  &__text {
    @extend %flex-center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    img {
      border: 1px solid #e1e5ed;
      border-radius: 50%;
    }
  }
}

.xero-description p + p {
  margin-top: 32px;
}
</style>
