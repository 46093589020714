<script>
import { mapGetters } from 'vuex/dist/vuex.common';
import { wizardEmitterMixin } from '@/mixins/wizard';
import { LOADING_KEY } from '@/util/constants';

import EzButton from '@/components/ui/Button';
import EzWizard from '@/components/layout/Wizard.vue';
import EzLoader from '@/components/ui/Loader/EzLoader';
import steps, { STOCKTAKE_STEP_1, STOCKTAKE_STEP_2, STOCKTAKE_STEP_3 } from './steps';

/**
 * Conduct Stocktake Flow
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardEmitterMixin],
  props: {
    stocktakeId: {
      type: Number,
      required: true,
    },
  },
  components: { EzButton, EzWizard, EzLoader },
  data() {
    return {
      STOCKTAKE_STEP_1,
      STOCKTAKE_STEP_2,
      STOCKTAKE_STEP_3,
      currentStep: 0,
      loadingMsg: 'Loading...',
      nextDisabled: true,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.FETCH_VENUE_LOCATIONS]);
    },
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === STOCKTAKE_STEP_1;
    },
    isLastStep() {
      return this.currentStep === STOCKTAKE_STEP_3;
    },
  },
  methods: {
    onWizardExit() {
      return this.$router.push({ name: 'venue-stock-stocktake' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= STOCKTAKE_STEP_1 && step <= STOCKTAKE_STEP_3) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= STOCKTAKE_STEP_1) return;

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= STOCKTAKE_STEP_3) {
        return;
      }

      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
  created() {
    this.currentStep = STOCKTAKE_STEP_1;
  },
};
</script>

<template>
  <ez-wizard
    :extraWide="currentStep === STOCKTAKE_STEP_2 || currentStep === STOCKTAKE_STEP_3"
    @exit="onWizardExit"
    @back="goToPreviousStep"
  >
    <template #title>Conduct Count</template>
    <template #nav>
      <ul>
        <li
          :class="{
            'ez-step-active': currentStep === STOCKTAKE_STEP_1,
            'ez-step-completed': currentStep > STOCKTAKE_STEP_1,
          }"
        >
          1. Location
        </li>
        <li
          :class="{
            'ez-step-active': currentStep === STOCKTAKE_STEP_2,
            'ez-step-completed': currentStep > STOCKTAKE_STEP_2,
          }"
        >
          2. Counting
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button :disabled="nextDisabled" @click="goToNextStep">
        {{ nextCta }}
      </ez-button>
    </template>
    <template #prevStep>
      <ez-button
        v-if="[STOCKTAKE_STEP_2, STOCKTAKE_STEP_3].includes(currentStep)"
        type="link"
        formType="button"
        @click="goToPreviousStep"
      >
        <font-awesome-icon icon="arrow-left" />
        <span>Back</span>
      </ez-button>
    </template>
    <template #pageTitle>{{ currentStepPageTitle }}</template>
    <template #pageInfo>
      <div v-html="currentStepPageInfo"></div>
    </template>
    <component
      :is="currentStepComponent"
      :stocktakeId="stocktakeId"
      @stepCompleted="onStepCompleted"
      @stepBack="onStepBack"
    />
    <ez-loader :show="isLoading">
      {{ loadingMsg }}
    </ez-loader>
  </ez-wizard>
</template>
