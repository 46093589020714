<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';

import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal.vue';
import EzButton from '@/components/ui/Button';
import AdvancedInventoryManagement from './AdvancedInventoryManagement.vue';

/**
 * EditInventoryManagement
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'EditInventoryManagement',
  props: {
    order: {
      type: Object,
      required: true,
    },
    warehouseId: {
      type: Number,
    },
  },
  components: {
    EzConfirmationModal,
    EzButton,
    AdvancedInventoryManagement,
  },
  data() {
    return {
      disabled: true,
      initialProducts: [],
      supplierCy,
    };
  },
  methods: {
    open() {
      this.$refs.advancedInventoryManagement.updateOrderQuotas(this.order);
      this.$refs.advancedInventoryManagement.refresh();
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onInvalid(val) {
      this.disabled = val;
    },
    onChange(val) {
      this.$emit('onChange', val);
    },
    onConfirm() {
      this.$emit('onConfirm');
    },
  },
  watch: {
    order: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.initialProducts.length || !val?.orderedProducts?.length) return;
        this.initialProducts = val.orderedProducts.map(p => p.id);
      },
    },
  },
};
</script>

<template>
  <ez-confirmation-modal ref="modal" :icon="null" class="edit-inventory-management">
    <template #title>Inventory Management</template>
    <template #content>
      <p>Select products for delivery, and insert their order quantity.</p>
      <advanced-inventory-management
        v-if="order.orderedProducts && warehouseId"
        ref="advancedInventoryManagement"
        show
        :show-header="false"
        :order="order"
        :warehouseId="warehouseId"
        @invalid="onInvalid"
        @onChange="onChange"
      />
    </template>
    <template #footer>
      <ez-button type="link" @click="close">Cancel</ez-button>
      <ez-button
        type="primary"
        @click="onConfirm"
        :disabled="disabled"
        :data-cy="supplierCy.ORDERS.GENERAL.BUTTON__EDIT_INVENTORY_MANAGEMENT_CONFIRM"
      >
        Confirm
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped></style>
