<template>
  <product-form
    ref="form"
    :distributorId="distributorId"
    @submitSuccess="onSubmitSuccess"
    @formValid="isValid"
    :show-cogs="false"
    :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.PRODUCT_INFO"
  />
</template>

<script>
import { mapMutations } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { Form as ProductForm } from '@/views/common/products';
import Product from '@/models/Product';

export default {
  mixins: [wizardListenerMixin],
  components: {
    ProductForm,
  },
  props: {
    distributorId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      supplierCy,
    };
  },
  methods: {
    onNextStep() {
      this.$refs.form.submit();
    },
    onSubmitSuccess({ data }) {
      Product.insertOrUpdate({ data: data.data });

      this.UPDATE_DRAFT(data.data);

      this.$emit('stepCompleted');
    },
    isValid(val) {
      if (!val) this.disableNextStep();
      else this.enableNextStep();
    },
    ...mapMutations('entities/products', [
      'UPDATE_DRAFT',
    ]),
  },
};
</script>

<style scoped>

</style>
