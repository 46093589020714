<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { DATE_INPUT_FORMAT, DEFAULT_FRACTION_DIGITS } from '@/util/constants';
import { getTaxValue } from '@/util/utilsFinCalculator';
import { formatPrice, isNullish } from '@/util/utils';

import EzMultipleImageUpload from '@/components/ui/ImageUpload/EzMultipleImageUpload';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput.vue';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown.vue';

import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import VDatePicker from '@/components/v3/patterns/VDatePicker';
import Paper from '@/components/layout/WizardOverviewPaper.vue';

import {
  DISCOUNT_TYPE,
  ORDER_TYPE_QUOTE,
  ORDER_TYPE_SALES_ORDER,
  ORDER_TYPES,
  getDiscountValue,
  getDiscountedPrice,
  getPrice,
} from './utils';

/**
 * OverviewSidebar
 * @version 1.0.0
 * @since 3.30.2
 */
export default {
  name: 'OverviewSidebar',
  mixins: [wizardListenerMixin],
  props: {
    discountsInvalid: {
      type: Object,
      required: true,
    },
  },
  components: {
    EzMultipleImageUpload,
    EzMaskInput,
    EzDropdown,
    VSelectSearch,
    VDatePicker,
    Paper,
  },
  data() {
    return {
      drivers: [],
      today: dayjs(new Date()),
      values: {
        distributorType: '',
        deliveryDate: null,
        driverId: null,
        productDiscount: 0,
        orderDiscountAmount: 0,
      },
      editableDeliveryFee: 0,
      supplierCy,
      ORDER_TYPES,
      DISCOUNT_TYPE,
      DATE_INPUT_FORMAT,
    };
  },
  computed: {
    ...mapState('entities/orders', ['draft']),
    ...mapGetters('entities/users', ['getTax']),
    ...mapGetters('entities/users', ['getDistributor']),
    isTaxUniform() {
      let uniformTax = true;
      this.products.forEach(item => {
        if (item.tax !== this.tax) uniformTax = false;
      });
      return uniformTax;
    },
    venue() {
      return this.draft.venue;
    },
    isTargetAccount() {
      return !this.venue.connection;
    },
    precision() {
      return this.getDistributor.currency?.fractionDigits ?? DEFAULT_FRACTION_DIGITS;
    },
    defaultDeliveryDate() {
      if (this.isTargetAccount) return null;
      return dayjs(this.getDistributor.defaultDeliveryDate || this.today);
    },
    isQuote() {
      return this.values.distributorType === ORDER_TYPE_QUOTE;
    },
    products() {
      return this.draft.products;
    },
    draftDeliveryFee() {
      return this.draft?.deliveryFee ?? null;
    },
    deliveryFee() {
      return this.editableDeliveryFee || this.draftDeliveryFee || 0;
    },
    invoices() {
      return this.draft?.invoices || [];
    },
    isSomeProductsTBD() {
      return this.products.some(p => this.isToBeDetermined(p));
    },
    filteredProducts() {
      return this.products.filter(p => !this.isToBeDetermined(p));
    },
    subtotal() {
      if (!this.filteredProducts.length) return 0;
      const productsTotal = this.filteredProducts.reduce(
        (sum, cur) => sum + getPrice(cur) * cur.quantity,
        0,
      );
      const productsTax = this.products.reduce((acc, cur) => {
        const productTax = getTaxValue({
          price: getPrice(cur) * cur.quantity,
          tax: cur.tax,
          taxCalculation: this.taxCalculation,
        });
        return acc + productTax;
      }, 0);
      if (this.taxCalculation === 'inclusive') return productsTotal - productsTax;
      return productsTotal;
    },
    subtotalFormatted() {
      if (this.isSomeProductsTBD) return 'TBD';
      return formatPrice(this.subtotal);
    },
    subtotalAfterDiscounts() {
      if (!this.filteredProducts.length) return 0;
      const subtotalNew = this.filteredProducts.reduce(
        (sum, cur) => sum + getDiscountedPrice(cur) * cur.quantity,
        0,
      );
      return Math.max(subtotalNew, 0);
    },
    discounts() {
      const productsDiscounts = this.filteredProducts.reduce(
        (sum, cur) => sum + getDiscountValue(cur) * cur.quantity,
        0,
      );
      const discountTax = getTaxValue({
        price: productsDiscounts,
        tax: this.tax,
        taxCalculation: this.taxCalculation,
      });
      const orderDiscountWithTax = getTaxValue({
        price: this.values.orderDiscountAmount,
        tax: this.tax,
        taxCalculation: this.taxCalculation,
      });
      if (this.taxCalculation === 'inclusive') {
        return (
          this.values.orderDiscountAmount - orderDiscountWithTax + productsDiscounts - discountTax
        );
      }
      const totalDiscounts = this.values.orderDiscountAmount + productsDiscounts;
      return totalDiscounts;
    },
    discountsFormatted() {
      const formattedValue = formatPrice(Math.abs(this.discounts), this.getDistributor.currency);
      const prefix = this.discounts > 0 ? '-' : '+';
      return `${this.discounts === 0 ? '' : prefix}${formattedValue}`;
    },
    tax() {
      if (!isNullish(this.venue.connection?.tax)) return this.venue.connection?.tax;
      if (!isNullish(this.getTax)) return this.getTax;
      return 0;
    },
    deliveryFeeTaxable() {
      return this.getDistributor.deliveryFeeWithoutTax;
    },
    taxCalculation() {
      return this.venue.connection?.taxCalculation;
    },
    taxAmount() {
      if (!this.tax) return 0;

      const deliveryFeeWithTax = this.deliveryFeeTaxable
        ? getTaxValue({ price: this.deliveryFee, tax: this.tax })
        : 0;
      const productsWithTax = this.products.reduce((acc, cur) => {
        const productTax = getTaxValue({
          price: getDiscountedPrice(cur) * cur.quantity,
          tax: cur.tax,
          taxCalculation: this.taxCalculation,
        });
        return acc + productTax;
      }, 0);
      const orderDiscountWithTax = getTaxValue({
        price: this.values.orderDiscountAmount,
        tax: this.tax,
        taxCalculation: this.taxCalculation,
      });

      return productsWithTax - orderDiscountWithTax + deliveryFeeWithTax;
    },
    taxAmountFormatted() {
      if (this.isSomeProductsTBD) return 'TBD';
      return formatPrice(this.taxAmount);
    },
    total() {
      return this.subtotal - this.discounts + this.deliveryFee + this.taxAmount;
    },
    totalFormatted() {
      if (this.isSomeProductsTBD) return 'TBD';
      return formatPrice(this.subtotal - this.discounts + this.deliveryFee + this.taxAmount);
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorFetchDrivers']),
    ...mapMutations('entities/orders', [
      'SET_DRAFT_DELIVERY',
      'UPDATE_DRAFT',
      'CALCULATE_NEW_DRAFT_ORDER_AMOUNT',
      'UPDATE_DRAFT_PRODUCT',
      'UPDATE_DELIVERY_FEE_TO_NEW_ORDER',
    ]),
    updateValue(field, val) {
      let value;

      if (field === 'distributorType') {
        value = val.id;
        if (val.id === ORDER_TYPE_QUOTE) this.updateValue('deliveryDate', null);
      } else if (field === 'driverId') value = val.reset ? null : val.id;
      else value = val;

      this.values = { ...this.values, [field]: value };
    },
    editDeliveryFee(val) {
      this.editableDeliveryFee = val;
      this.SET_DRAFT_DELIVERY(this.editableDeliveryFee);
    },
    onFileUpload(data) {
      this.UPDATE_DRAFT({ invoices: [...data.values()] });
    },
    isToBeDetermined(product) {
      const { priceUnit, marketPrice, price } = product || {};
      return (!!priceUnit && price > 0) || marketPrice;
    },
    handleDeliveryFee() {
      this.UPDATE_DRAFT({ oldAmount: this.draft.amount });
      this.CALCULATE_NEW_DRAFT_ORDER_AMOUNT();
      this.UPDATE_DELIVERY_FEE_TO_NEW_ORDER({ distributor: this.getDistributor });
    },
  },
  async created() {
    const { data } = await this.distributorFetchDrivers();
    this.drivers = data.data;

    const { distributorType, deliveryDate, driverId, productDiscount, orderDiscountAmount } =
      this.draft;
    const venueDriver = this.venue.connection?.defaultDriverId;
    const distributorDriver = this.getDistributor.defaultDriverId;
    const defaultOrderType = this.isTargetAccount ? ORDER_TYPE_QUOTE : ORDER_TYPE_SALES_ORDER;

    this.values = {
      ...this.values,
      driverId: driverId || venueDriver || distributorDriver,
      distributorType: distributorType || defaultOrderType,
      deliveryDate: this.defaultDeliveryDate,
      ...(deliveryDate && { deliveryDate }),
      ...(productDiscount && { productDiscount }),
      ...(orderDiscountAmount && { orderDiscountAmount }),
    };
  },
  watch: {
    values: {
      async handler(val, oldVal) {
        if (val.productDiscount !== oldVal.productDiscount) {
          this.products.forEach(p =>
            this.UPDATE_DRAFT_PRODUCT({
              ...p,
              ...(!this.isToBeDetermined(p) && {
                discountType: DISCOUNT_TYPE.percent,
                discountValue: val.productDiscount,
                price: getDiscountedPrice({ ...p, discountValue: val.productDiscount }),
              }),
            }),
          );
        }

        this.UPDATE_DRAFT({ ...val, deliveryDate: val.deliveryDate?.format('YYYY-MM-DD') });
        this.handleDeliveryFee();
      },
    },
    draftDeliveryFee(val) {
      if (val === this.getDistributor.deliveryFee) this.editableDeliveryFee = 0;
    },
  },
};
</script>

<template>
  <div class="create-order__sidebar">
    <paper>
      <ez-dropdown
        :data="ORDER_TYPES"
        :selected="values.distributorType"
        :disabled="isTargetAccount"
        :tooltip="
          isTargetAccount ? 'You can create only quotes for target and pending accounts.' : ''
        "
        name="distributorType"
        label="Type of Order"
        placeholder="Select Type of Order"
        @change="updateValue('distributorType', $event)"
        isFullWidth
      />
    </paper>

    <paper class="delivery-details">
      <template #title>{{ isQuote ? 'Quote' : 'Delivery' }} Details</template>
      <template>
        <v-date-picker
          name="deliveryDate"
          :label="isQuote ? 'Quote Deadline' : 'Delivery Date'"
          v-model="values.deliveryDate"
          @dateChange="updateValue('deliveryDate', $event)"
          isFullWidth
          class="mb-16"
        />
        <v-select-search
          v-if="!isQuote"
          :data="drivers"
          :selected="values.driverId"
          name="driverId"
          label="Driver"
          placeholder="No Driver"
          @selected="updateValue('driverId', $event)"
          class="driver-dropdown mb-16"
          isFullWidth
        />
        <ez-mask-input
          :value="deliveryFee"
          :precision="precision"
          type="input"
          label="Delivery Fee"
          @input="editDeliveryFee"
          class="delivery-fee"
        />
      </template>
    </paper>

    <paper class="custom-documents">
      <template #title>Custom Documents</template>
      <template>
        <ez-multiple-image-upload
          class="invoice-upload"
          name="invoice"
          ref="imageUpload"
          form-key="upload-invoice"
          accept="image/jpeg,image/png,application/pdf"
          :number-of-files="5"
          :value="invoices"
          card-type
          @change="onFileUpload"
          :data-cy="supplierCy.ORDERS.NEW_ORDER.OVERVIEW.BUTTON__UPLOAD_FILES"
        >
          <template #label></template>
          <template #cta>Upload Files</template>
        </ez-multiple-image-upload>
      </template>
    </paper>

    <paper class="discount">
      <template #title>Product Discount</template>
      <template>
        <p class="m-0 u-flex-space">
          <span class="discount__label">Discount All Products</span>
          <ez-mask-input
            customPrefix="%"
            :value="values.productDiscount"
            :minValue="0"
            :maxValue="100"
            :isInvalid="discountsInvalid.product"
            :disabled="!filteredProducts.length"
            @input="updateValue('productDiscount', $event)"
            @invalid="$emit('invalid', { product: $event })"
            class="discount__input"
          />
        </p>
      </template>
    </paper>

    <paper class="discount">
      <template #title>
        Order Discount
        <font-awesome-icon
          icon="info-circle"
          v-tooltip="{
            content: 'Discount is in addition to product discounts',
            classes: ['tooltip--reset-margin', 'tooltip--lift-up'],
          }"
          class="info-icon ml-8"
        />
      </template>
      <template>
        <p class="u-flex-space m-0">
          <span class="discount__label">Additional Order Discount</span>
          <ez-mask-input
            :value="values.orderDiscountAmount"
            :minValue="0"
            :maxValue="subtotalAfterDiscounts"
            :precision="precision"
            :isInvalid="discountsInvalid.order"
            @input="updateValue('orderDiscountAmount', $event)"
            @invalid="$emit('invalid', { order: $event })"
            class="discount__input"
          />
        </p>
      </template>
    </paper>

    <paper class="order-totals">
      <div class="u-flex-space m-0">
        <span class="order-totals__label">Subtotal</span>
        <span class="order-totals__amount">{{ subtotalFormatted }}</span>
      </div>

      <div class="u-flex-space m-0">
        <span class="order-totals__label">Discounts</span>
        <span class="order-totals__amount">{{ discountsFormatted }}</span>
      </div>

      <div class="u-flex-space m-0">
        <span class="order-totals__label">Delivery Fee</span>
        <span class="order-totals__amount">{{ deliveryFee | price }}</span>
      </div>

      <div class="u-flex-space m-0">
        <span class="order-totals__label">Tax{{ isTaxUniform ? ` (${tax}%)` : '' }}</span>
        <span class="order-totals__amount">{{ taxAmountFormatted }}</span>
      </div>

      <div class="u-flex-space mb-0 mt-16">
        <span class="order-totals__label order-totals__label--total">Total</span>
        <span class="order-totals__amount">{{ totalFormatted }}</span>
      </div>
    </paper>
  </div>
</template>

<style lang="scss" scoped>
.create-order {
  &__sidebar {
    flex: 1 0 315px;
    margin-left: $spacing-16;

    .delivery-details {
      :deep() .date-picker {
        width: 100%;
      }
      .driver-dropdown {
        :deep() .select-search {
          &__trigger span {
            @include font-size(14px, 20px);

            &:not(.select-search__value) {
              color: $color-gray-6C;
            }
          }

          &__search-wrapper {
            display: none;
          }

          &__item {
            @include font-size(14px, 20px);
            padding: 10px 16px;
            color: $color-gray-25;
          }
        }
      }

      .delivery-fee :deep() {
        .label {
          font-weight: 500;
        }

        input {
          font-weight: 400;
        }

        .mark-input__error {
          display: none;
        }
      }
    }

    .custom-documents {
      .invoice-upload {
        :deep() .preview__images {
          flex-direction: column;
          .preview__image {
            & + .preview__image {
              margin-left: 0;
            }
            .image-name {
              font-size: 14px;
              margin-right: 12px;
            }
          }
        }
      }
    }

    .discount {
      &__label {
        display: block;
        color: $color-gray-6C;
        @include font-size(14px, 22px);
      }

      &__input :deep() {
        width: auto;

        .mask-input {
          &__input {
            @include font-size(16px, 20px);
          }

          &__prefix {
            color: $color-gray-6C;
            font-weight: bold;
          }
        }

        .mark-input__error {
          display: none;
        }
      }
    }

    .order-totals {
      &__label {
        @include font-size(14px, 20px);
        color: $color-gray-6C;

        &--total {
          @include font-size(16px, 22px);
          font-weight: bold;
        }
      }

      &__amount {
        @include font-size(16px, 22px);
        color: $color-gray-25;
        font-weight: bold;
      }
    }
  }
}
</style>
