var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ez-wizard',{attrs:{"extraWide":[_vm.NEW_CREDIT_NOTE_STEP_2].includes(_vm.currentStep),"mediumWide":[_vm.NEW_CREDIT_NOTE_STEP_3].includes(_vm.currentStep)},on:{"exit":_vm.onWizardExit,"back":_vm.goToPreviousStep},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Add New Credit Note")]},proxy:true},{key:"nav",fn:function(){return [_c('ul',[_c('li',{class:{
          'ez-step-active': _vm.currentStep === _vm.NEW_CREDIT_NOTE_STEP_1,
          'ez-step-completed': _vm.currentStep > _vm.NEW_CREDIT_NOTE_STEP_1,
        }},[_vm._v(" 1. Credit Info ")]),_c('li',{class:{
          'ez-step-active': _vm.currentStep === _vm.NEW_CREDIT_NOTE_STEP_2,
          'ez-step-completed': _vm.currentStep > _vm.NEW_CREDIT_NOTE_STEP_2,
        }},[_vm._v(" 2. Stock Credit ")])])]},proxy:true},{key:"actions",fn:function(){return [(_vm.secondCta)?_c('ez-button',{staticClass:"mr-8 secondary-action",attrs:{"type":"secondary","disabled":_vm.secondActionDisabled || _vm.isNonCreditNoteExceeded,"data-cy":_vm.supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.GENERAL.BUTTON__PRIMARY_ACTION},on:{"click":_vm.goToNextStep}},[_vm._v(" "+_vm._s(_vm.secondCta)+" ")]):_vm._e(),_c('ez-button',{attrs:{"disabled":_vm.nextDisabled || _vm.isCreditNoteExceeded,"data-cy":_vm.supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.GENERAL.BUTTON__SECONDARY_ACTION},on:{"click":function($event){return _vm.goToStep({ step: _vm.NEW_CREDIT_NOTE_STEP_3 })}}},[_vm._v(" "+_vm._s(_vm.nextCta)+" ")])]},proxy:true},{key:"prevStep",fn:function(){return [(_vm.isSecondStep)?_c('ez-button',{attrs:{"type":"link","formType":"button","data-cy":_vm.supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.GENERAL.BUTTON__PREV_STEP},on:{"click":_vm.goToPreviousStep}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),_c('span',[_vm._v("Back")])],1):_vm._e()]},proxy:true},{key:"pageTitle",fn:function(){return [_vm._v(_vm._s(_vm.currentStepPageTitle))]},proxy:true},{key:"pageInfo",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.currentStepPageInfo)}})]},proxy:true}])},[_c(_vm.currentStepComponent,{tag:"component",attrs:{"orderId":_vm.orderId},on:{"stepCompleted":_vm.onStepCompleted,"stepBack":_vm.onStepBack}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }