<script>
/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */

import {
  HISTORY_LOG_ACTION_ADD,
  HISTORY_LOG_ACTION_REMOVE,
  HISTORY_LOG_ACTION_EDIT,
  HISTORY_LOG_ACTION_STOCKTAKE,
} from '@/util/constants';

export default {
  props: {
    type: {
      required: true,
      type: String,
      validator: value => [
        HISTORY_LOG_ACTION_ADD,
        HISTORY_LOG_ACTION_REMOVE,
        HISTORY_LOG_ACTION_EDIT,
        HISTORY_LOG_ACTION_STOCKTAKE,
      ].includes(value),
    },
  },
  computed: {
    types() {
      return {
        [HISTORY_LOG_ACTION_ADD]: 'plus',
        [HISTORY_LOG_ACTION_REMOVE]: 'minus',
        [HISTORY_LOG_ACTION_EDIT]: 'pen',
        [HISTORY_LOG_ACTION_STOCKTAKE]: 'tasks',
      };
    },
  },
};
</script>
<template>
  <div :class="['type-badge', `type-badge--${types[type]}`]">
    <font-awesome-layers v-if="types[type] === 'plus' || types[type] === 'minus'">
      <font-awesome-icon icon="circle" />
      <font-awesome-icon :icon="types[type]" transform="shrink-6" :style="{ color: 'white' }" />
    </font-awesome-layers>
    <font-awesome-icon v-else :icon="types[type]" />
  </div>
</template>
<style lang="scss" scoped>
.type-badge {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  @extend %flex-center;
  justify-content: center;
  &--plus {
    background-color: #daf1e9;
    color: #1ba975;
  }
  &--minus {
    background-color: #fedfde;
    color: #f73731;
  }
  &--exchange-alt,
  &--tasks,
  &--pen {
    background-color: #e2eaff;
    color: #4d7cfe;
  }
  &--file-csv {
    background-color: #f5f6fa;
    border: 1px solid #e9ebf2;
    color: #6c7995;
  }
}
</style>
