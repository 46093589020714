<script>
import { mapActions } from 'vuex';
import EzButton from '@/components/ui/Button/EzButton';
import { EzConfirmationModal } from '@/components/ui/Modal';

/**
 * DeleteCustomerCategoryModal
 * @version 1.0.0
 * @since 3.26.0
 */

export default {
  name: 'DeleteCustomerCategoryModal',
  components: {
    EzConfirmationModal,
    EzButton,
  },
  props: {
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      category: {},
    };
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorDeleteCustomerCategory']),
    open(category) {
      this.category = category;
      this.$refs.deleteModal.open();
    },
    close() {
      this.$refs.deleteModal.close();
      this.$emit('onClose');
      this.category = {};
    },
    async onRemove() {
      await this.distributorDeleteCustomerCategory({ categoryId: this.category.id });
      this.$emit('success', this.category.id);
      this.close();
    },
  },
};
</script>

<template>
  <ez-confirmation-modal class="remove-category-modal" ref="deleteModal" type="red">
    <template #title>Remove {{ category.name }} Category?</template>
    <template #content>
      <p>
        You are about to remove {{ category.name }} from your list. {{ $t('global.venues') }} that
        were assigned to this category will be left unassigned.
      </p>
    </template>
    <template #footer>
      <ez-button @click="close" type="link" :data-cy="dataCy.BUTTON__CANCEL">Cancel</ez-button>
      <ez-button @click="onRemove" type="red" :data-cy="dataCy.BUTTON__REMOVE">
        Remove Category
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped>
.remove-category-modal {
  @include z-index('modal', 10);
  :deep() {
    .modal {
      @include z-index('modal', 20);
    }
  }
}
</style>
