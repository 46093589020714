<script>
/**
 * NetSuite Not Connected Screen
 * @version 1.0.0
 * @since 3.24.0
 */

import { mapActions, mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';

import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import EzButton from '@/components/ui/Button/EzButton';
import EzLoader from '@/components/ui/Loader';

import EmptyState from '@/views/common/empty-state';

export default {
  components: {
    EzConfirmationModal,
    EzButton,
    EzLoader,
    EmptyState,
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    ...mapGetters('entities/users', ['isDistributorNetSuiteConnected']),
    isAuthorizing() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_NETSUITE_AUTHORIZE);
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('netsuite', ['distributorNetsuiteConnect']),
    ...mapActions('entities/users', ['initDistributorLoggedUser']),
    confirmNetSuiteConnection() {
      this.distributorNetsuiteConnect({ route: 'distributor-netsuite-account-info' });
    },
  },
  watch: {
    isDistributorNetSuiteConnected: {
      immediate: true,
      handler(val) {
        if (!val) return;

        this.$router.replace({
          name: 'distributor-netsuite-account-info',
          params: {
            flash: {
              title: window.location.search
                ? this.$t('netsuite.integrations.successfulConnection')
                : this.$t('netsuite.integrations.alreadyConnected'),
              type: 'success',
            },
          },
        });
      },
    },
  },
};
</script>
<template>
  <div class="not-connected">
    <empty-state>
      <template #badge>
        <img src="@/assets/netsuite-empty-state.svg" alt="NetSuite" width="160" height="144" />
      </template>
      <template #title>{{ $t('netsuite.integrations.notConnected.title') }}</template>
      <template #info>{{ $t('netsuite.integrations.notConnected.info') }}</template>

      <div class="mt-16">
        <ez-button key="connectNetSuite" v-open="'netsuiteConnectModal'">
          <font-awesome-icon icon="link" />
          <span>{{ $t('global.actions.connect') }}</span>
        </ez-button>
      </div>
    </empty-state>

    <ez-loader :show="isAuthorizing">Loading...</ez-loader>

    <ez-confirmation-modal ref="netsuiteConnectModal" icon="question">
      <template #title>{{ $t('netsuite.integrations.confirmationModal.title') }}</template>
      <template #content>
        <div
          class="netsuite-description"
          v-html="$t('netsuite.integrations.confirmationModal.content', { platform: platformName })"
        />
      </template>
      <template #footer>
        <ez-button type="link" v-close="'netsuiteConnectModal'">
          {{ $t('global.actions.cancel') }}
        </ez-button>
        <ez-button class="ml-12" @click="confirmNetSuiteConnection">
          {{ $t('global.actions.confirm') }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<style lang="scss" scoped>
.not-connected {
  padding-top: 80px;
}
</style>
