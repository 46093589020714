<template>
  <div>
    <ez-loader :show="isLoading">
      {{loadingMsg}}
    </ez-loader>
    <ez-wizard
      :fullWidth="currentStep === VENUE_STEP_3 || currentStep === VENUE_STEP_4"
      @exit="onWizardExit"
      @back="goToPreviousStep">
      <template #title>Add New {{ $t('global.venue') }} Group</template>
      <template #nav>
        <ul>
          <li :class="{
            'ez-step-active': currentStep === VENUE_STEP_1,
            'ez-step-completed': currentStep > VENUE_STEP_1,
          }">
            1. {{ $t('global.venue') }} Info
          </li>
          <li :class="{
            'ez-step-active': currentStep === VENUE_STEP_2,
            'ez-step-completed': currentStep > VENUE_STEP_2,
          }">
            2. {{ $t('global.venue') }} Settings
          </li>
          <li :class="{
            'ez-step-active': currentStep === VENUE_STEP_3,
            'ez-step-completed': currentStep > VENUE_STEP_3,
          }">
            3. Contacts
          </li>
          <li :class="{
            'ez-step-active': currentStep === VENUE_STEP_4,
            'ez-step-completed': currentStep > VENUE_STEP_4,
          }">
            4. Products
          </li>
        </ul>
      </template>
      <template #actions>
        <ez-button
          :disabled="nextDisabled"
          @click="goToNextStep">{{ nextCta }}</ez-button>
      </template>
      <template #prevStep>
        <ez-button
          v-if="currentStep > VENUE_STEP_1 && currentStep < VENUE_STEP_5"
          type="link"
          formType="button"
          @click="goToPreviousStep">
          <font-awesome-icon icon="arrow-left"/>
          <span>Back</span>
        </ez-button>
      </template>
      <template #pageTitle>{{ currentStepPageTitle }}</template>
      <template #pageInfo>{{ currentStepPageInfo }}</template>
      <component
        :prefilledName="prefilledName"
        :venueData="venueData"
        :is="currentStepComponent"
        @stepCompleted="onStepCompleted"
        @stepBack="onStepBack"></component>
    </ez-wizard>
    <OnboardingLinkModal
      @success="onSuccessOnboarding"
      ref="onboardingModal" />
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { wizardEmitterMixin } from '@/mixins/wizard';
import EzWizard from '@/components/layout/Wizard.vue';
import EzButton from '@/components/ui/Button';
import EzAlert from '@/components/ui/Alert/EzAlert';
import OnboardingLinkModal from '@/views/platform/distributor/outlets/OnboardingLinkModal';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { LOADING_KEY } from '@/util/constants';
import steps, {
  VENUE_STEP_1,
  VENUE_STEP_2,
  VENUE_STEP_3,
  VENUE_STEP_4,
  VENUE_STEP_5,
} from './steps';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzLoader,
    EzButton,
    EzWizard,
    EzAlert,
    OnboardingLinkModal,
  },
  props: {
    prefilledName: {
      type: String,
    },
    venueData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      currentStep: 0,
      VENUE_STEP_1,
      VENUE_STEP_2,
      VENUE_STEP_3,
      VENUE_STEP_4,
      VENUE_STEP_5,
      nextDisabled: true,
      loadingMsg: 'Loading...',
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_TIME_ZONES,
        LOADING_KEY.FETCH_DISTRIBUTOR_VENUE,
        LOADING_KEY.GET_USERS,
        LOADING_KEY.CONNECT_DISTRIBUTOR_TO_VENUE,
      ]);
    },
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === VENUE_STEP_1;
    },
    isLastStep() {
      return this.currentStep === VENUE_STEP_5;
    },
    disableNext() {
      return false;
    },
    ...mapState('entities/groups', [
      'draft',
    ]),
    ...mapState('entities/venues', [
      'newCustomerGroupLinkData',
    ]),
  },
  methods: {
    ...mapMutations('entities/venues', [
      'CLEAR_NEW_CUSTOMER_GROUP_LINK_DATA',
    ]),
    onWizardExit() {
      this.CLEAR_DRAFT();
      this.$router.push({
        name: this.newCustomerGroupLinkData.route,
        ...(this.newCustomerGroupLinkData.params ? { params: this.newCustomerGroupLinkData.params } : {}),
      });
      this.CLEAR_NEW_CUSTOMER_GROUP_LINK_DATA();
    },
    onStepBack(step) {
      if (step !== undefined && step >= VENUE_STEP_1 && step <= VENUE_STEP_5) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= VENUE_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= VENUE_STEP_5) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
    openOnboardingModal() {
      this.$refs.onboardingModal.open();
    },
    onSuccessOnboarding() {
      this.$refs.onboardingModal.close();
      this.$router.push({ name: 'distributor-outlets' });
      flash.success({
        title: 'Onboarding link sent!',
        message: 'Good job! The onboarding link has been sent.',
      });
    },
    ...mapMutations('entities/venues', [
      'CLEAR_DRAFT',
    ]),
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
:deep() .ez-wizard {
  header {
    nav.ez-wizard__nav {
      ul {
        li {
          width: 136px;
          &::before {
            width: 144px !important;
          }
        }
      }
    }
  }
}
.banner {
  padding-bottom: 0;
  .banner__text {
    color: $color-gray-6C;
    font-weight: 400;
    margin: 0;
    .banner-title {
      color: $color-gray-25;
      font-weight: 500;
    }
  }
  .banner__btn {
    padding: 0;
  }
}
</style>
