<script>
import { mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import Header from '@/views/platform/venue/orders/Header.vue';
import EzNav from '@/components/ui/Nav';

/**
 * OrdersHome
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'OrdersHome',
  components: {
    EzNav,
    Header,
  },
  data() {
    return {
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/orders', {
      ordersMeta: 'meta',
      ordersFilters: 'filters',
    }),
    ...mapState('creditNotes', {
      creditNotesMeta: 'meta',
      creditNotesFilters: 'filters',
    }),
    isCreditNotesPage() {
      return this.$route.name === 'distributor-credit-notes';
    },
    meta() {
      return this.isCreditNotesPage ? this.creditNotesMeta : this.ordersMeta;
    },
    filters() {
      return this.isCreditNotesPage ? this.creditNotesFilters : this.ordersFilters;
    },
    totalLabel() {
      if (this.isCreditNotesPage) return 'Total for credit notes';

      const appliedFilters = Object.entries(this.filters).filter(([key, val]) => {
        /**
         * Checks if the current values for date filters are the same as the default ones.
         * If not equal, that means the filter has changed and should be accounted for.
         */
        if (['scheduledFrom', 'scheduledTo', 'from', 'to'].includes(key)) {
          const filter = key.startsWith('scheduled') ? 'scheduled' : 'month';
          const property = key.split('scheduled').filter(Boolean)[0]; // 'from' | 'to'
          return val && val !== this.defaultDateFilters?.[filter]?.[property];
        }

        if (Array.isArray(val)) return !!val.length;
        return !!val;
      });

      return appliedFilters.length
        ? 'Total for selected filters'
        : 'Total for this month’s deliveries';
    },
    totalIcon() {
      return this.isCreditNotesPage ? 'file-invoice-dollar' : 'money-bill-wave';
    },
    totalIconVariant() {
      return this.isCreditNotesPage ? 'yellow' : 'green';
    },
    amountDataCy() {
      if (!this.isCreditNotesPage) return '';
      return supplierCy.ORDERS.CREDIT_NOTES.TEXT__TOTAL_AMOUNT;
    },
  },
};
</script>

<template>
  <div class="distributor-orders">
    <Header
      :count="meta?.totalCount"
      :amount="meta?.totalAmount"
      :filtersApplied="filters"
      :showLabel="!!totalLabel"
      :totalIcon="totalIcon"
      :totalIconVariant="totalIconVariant"
      :totalIconTooltip="{}"
      :biggerTotalIcon="isCreditNotesPage"
      :amountDataCy="amountDataCy"
    >
      <template v-if="totalLabel" #total>{{ totalLabel }}</template>
    </Header>

    <ez-nav class="mt-16">
      <template #nav>
        <ul>
          <li>
            <router-link
              :to="{ name: 'distributor-orders-all' }"
              :data-cy="supplierCy.ORDERS.GENERAL.NAVIGATION.BUTTON__ORDERS"
            >
              Orders
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'distributor-credit-notes' }"
              :data-cy="supplierCy.ORDERS.GENERAL.NAVIGATION.BUTTON__CREDIT_NOTES"
            >
              Credit Notes
            </router-link>
          </li>
        </ul>
      </template>
      <router-view />
    </ez-nav>
  </div>
</template>

<style lang="scss" scoped>
.distributor-orders :deep() .order-header {
  .order-header__stat {
    flex-direction: column-reverse;

    small {
      text-transform: initial;
    }
  }
}
</style>
