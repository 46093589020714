<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import EzInput from '@/components/ui/Input';
import EzMultipleImageUpload from '@/components/ui/ImageUpload/EzMultipleImageUpload';
import { mapMutations, mapState } from 'vuex';
import { TAX_OPTIONS } from '@/util/constants';
import { getCurrency } from '@/util/utils';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';

export default {
  name: 'Expense',
  mixins: [wizardListenerMixin],
  components: {
    EzInput,
    EzMultipleImageUpload,
    EzDropdown,
  },
  data() {
    return {
      taxOptions: TAX_OPTIONS,
      tax: null,
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/distributors', ['checkInInfo']),
    taxRateId() {
      return this.checkInInfo.taxRate?.id;
    },
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return symbol;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', [
      'SET_CHECK_IN_INFO_TAX',
      'SET_CHECK_IN_INFO_SPENT',
      'SET_CHECK_IN_INFO_RECEIPTS',
      'CLEAR_CHECK_IN_INFO_SPENT',
      'CLEAR_CHECK_IN_INFO_TAX',
      'CLEAR_CHECK_IN_INFO_RECEIPTS',
    ]),
    onNextStep() {
      this.SET_CHECK_IN_INFO_SPENT(Number(this.checkInInfo.moneySpent).toFixed(2));
      this.$emit('stepCompleted');
    },
    clearExpenseTab() {
      this.CLEAR_CHECK_IN_INFO_SPENT();
      this.CLEAR_CHECK_IN_INFO_TAX();
      this.CLEAR_CHECK_IN_INFO_RECEIPTS();
    },
    onGoToStep({ step }) {
      this.clearExpenseTab();
      this.$emit('stepBack', step);
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    allSelected() {
      return this.checkInInfo.moneySpent
           && this.checkInInfo.receipts.length
           && this.tax
           && this.tax.value !== null;
    },
    noneSelected() {
      return !this.checkInInfo.moneySpent
          && !this.checkInInfo.receipts.length
          && (!this.tax || this.tax.value === null);
    },
    /**
     * Next step should be enabled if all fields have set values or
     * if none of them have set values.
     */
    checkIfEnabled() {
      if (this.allSelected() || this.noneSelected()) {
        this.enableNextStep();
      } else {
        this.disableNextStep();
      }
    },
    editTax(tax) {
      this.tax = tax;
      this.SET_CHECK_IN_INFO_TAX(tax);
      this.checkIfEnabled();
    },
    editMoneySpent(value) {
      this.SET_CHECK_IN_INFO_SPENT(value);
      this.checkIfEnabled();
    },
    editReceipts(data) {
      this.SET_CHECK_IN_INFO_RECEIPTS([...data.values()]);
      this.checkIfEnabled();
    },
  },
  mounted() {
    this.tax = this.checkInInfo.taxRate;
    this.checkIfEnabled();
  },
};
</script>
<template>
  <div>
    <h5>Expense Details</h5>
    <ez-input
      form-key="money"
      name="money-spent"
      type="number"
      :min="0"
      label="Money Spent"
      @onChange="editMoneySpent"
      :value="checkInInfo.moneySpent"
      :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.EXPENSE.INPUT__MONEY_SPENT"
    >
      <template #prefix>
        {{ currencySymbol }}
      </template>
    </ez-input>
    <ez-dropdown
      ref="select"
      class="tax-select mt-12"
      label="Tax Rate"
      :data="taxOptions"
      name="taxRates"
      placeholder="Select Tax"
      is-full-width
      @change="editTax"
      :selected="taxRateId"
      :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.EXPENSE.BUTTON__TAX_RATE"
    />
    <hr>
    <h5>Receipts</h5>
    <ez-multiple-image-upload
      ref="imageUpload"
      accept="image/jpeg,image/png"
      formKey="receipt"
      card-type
      name="invoice"
      :value="checkInInfo.receipts"
      class="upload-component"
      @change="editReceipts"
      :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.EXPENSE.BUTTON__UPLOAD_RECEIPT"
    >
      <template #icon><font-awesome-icon icon="plus" class="preview__button-icon"/></template>
      <template #cta>Upload Receipt</template>
    </ez-multiple-image-upload>
  </div>
</template>
<style lang="scss" scoped>
.title {
  font-weight: 600;
  color: #000;
  font-size: 14px;
}

.upload-component {
  :deep() .preview {
    &__images {
      display: flex;
      flex-direction: column;
    }
  }
  :deep() .preview__image + .preview__image {
    margin-left: 0;
  }
}

.tax-select {
  :deep() .ez-dropdown {
    &__label {
      color: $color-gray-6C;
    }
  }
}
</style>
