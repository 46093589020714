<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import Paper from '@/components/layout/WizardOverviewPaper';
import EzMultipleImageUpload from '@/components/ui/ImageUpload/EzMultipleImageUpload';
import EzButton from '@/components/ui/Button';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo/index';
import { mapState, mapActions, mapGetters } from 'vuex';
import { CHECK_IN_STEP_1, CHECK_IN_STEP_2 } from '@/views/platform/distributor/crm/account-check-in/new-check-in/steps';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader';

export default {
  name: 'Overview',
  mixins: [wizardListenerMixin],
  components: {
    Paper,
    EzButton,
    VVenueEntityInfo,
    EzMultipleImageUpload,
    EzLoader,
  },
  data() {
    return {
      loading: false,
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/distributors', ['checkInInfo']),
    ...mapGetters('entities/distributors', ['getCheckIn']),
    taxRateId() {
      return this.checkInInfo.taxRate?.id;
    },
    hasVenue() {
      return !!Object.keys(this.checkInInfo.venue || {}).length;
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['createCheckIn']),
    editInfo() {
      this.$emit('stepBack', CHECK_IN_STEP_1);
    },
    editExpense() {
      this.$emit('stepBack', CHECK_IN_STEP_2);
    },
    async onNextStep() {
      this.loading = true;
      try {
        const { data } = await this.createCheckIn({
          data: this.getCheckIn,
        });
        const { id } = data.data;
        const { href } = this.$router.resolve({ name: 'distributor-account-check-in-single', params: { id } });
        await this.$nextTick();
        this.loading = false;
        this.$router.push({
          name: 'distributor-account-check-in',
        });
        flash.success({
          title: 'Activity successfully added!',
          message: 'You can see the overview of your activity in Activity Details. ',
          ctas: `<a href="${href}">View Activity Details</a>`,
        });
      } catch (e) {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.enableNextStep();
  },
};
</script>
<template>
  <div>
    <paper class="check-in-info">
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editInfo"
          :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.OVERVIEW.BUTTON__EDIT_OUTLET"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <v-venue-entity-info
        v-if="hasVenue"
        :venue="checkInInfo.venue"
        :is-big="true"
        :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.OVERVIEW.TEXT__OUTLET"
      />
      <hr v-if="hasVenue" />
      <div class="info info--vertical">
        <small>Activity Type</small>
        <span :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.OVERVIEW.TEXT__TYPE">
          {{ checkInInfo.type.name }}
        </span>
      </div>
      <div class="info info--vertical">
        <small>Date & Time</small>
        <span :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.OVERVIEW.TEXT__DATE_TIME">
          {{ checkInInfo.datetime | dateTime }}
        </span>
      </div>
      <div class="info info--vertical">
        <small>Note</small>
        <span :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.OVERVIEW.TEXT__NOTE">
          {{ checkInInfo.note }}
        </span>
      </div>
    </paper>
    <paper>
      <template #title>Expense Details</template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editExpense"
          :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.OVERVIEW.BUTTON__EDIT_EXPENSE"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="info info--vertical">
        <small>Money Spent</small>
        <span :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.OVERVIEW.TEXT__MONEY_SPENT">
          {{ checkInInfo.moneySpent | price }}
        </span>
      </div>
      <div class="info info--vertical">
        <small>Tax</small>
        <span
          v-if="taxRateId === 1"
          :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.OVERVIEW.TEXT__TAX"
        >
          GST Included
        </span>
        <span
          v-else
          :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.OVERVIEW.TEXT__TAX"
        >
          No Tax
        </span>
      </div>
    </paper>
    <paper v-if="checkInInfo.receipts.length">
      <template #title>Receipts ({{ checkInInfo.receipts.length }})</template>
      <ez-multiple-image-upload
        ref="imageUpload"
        accept="image/jpeg,image/png"
        formKey="receipt"
        :card-type="true"
        :editable="false"
        :value="checkInInfo.receipts"
        name="invoice"
        class="upload-component"
        :note="false"
        :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.OVERVIEW.IMG__RECEIPT"
      />
    </paper>

    <ez-loader :show="loading">Loading...</ez-loader>

  </div>
</template>
<style lang="scss" scoped>
:deep() .loader {
  @extend %whole-screen;
}

.check-in-info :deep() .entity-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .entity-info__info {
    justify-content: center;
    font-size: 16px;
  }
}

.upload-component {
  :deep() .preview__images {
    display: flex;
    flex-direction: column;

    .preview__image {
      border: none;
      box-shadow: none
    }
  }
  :deep() .preview__image + .preview__image {
    margin-left: 0;
  }
}
</style>
