<script>
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { UNIT_TYPE_FRACTIONAL } from '@/util/constants';
import { clone, getDateFormat } from '@/util/utils';

import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput.vue';
import EzDateInput from '@/components/ui/DateInput/EzDateInput.vue';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzTextarea from '@/components/ui/Textarea/EzTextarea.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';

/**
 * EditProductModal
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'EditProductModal',
  components: {
    EzMaskInputSimple,
    EzMaskInput,
    EzDateInput,
    EzFormModal,
    EzTextarea,
    EzButton,
    EzInput,
  },
  data() {
    return {
      item: {},
      note: '',
      getDateFormat,
      isInvalidQuantity: false,
      supplierCy,
    };
  },
  computed: {
    isFractional() {
      return this.item.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    increment() {
      return this.isFractional ? 0.01 : 1;
    },
  },
  methods: {
    open(item) {
      this.item = { ...clone(item), expiryDate: item.expiryDate ? dayjs(item.expiryDate) : null };
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onCancel() {
      this.item = {};
      this.note = '';

      this.close();
      this.$emit('cancel');
    },
    onSave() {
      this.close();
      this.$emit('save', { ...this.item, note: this.note });
    },
    onChange(key, value) {
      this.item[key] = value;
    },
  },
};
</script>

<template>
  <ez-form-modal ref="modal">
    <template #title>Edit Product</template>
    <template #content>
      <ez-mask-input-simple
        formKey=""
        name="quantity"
        label="Quantity"
        :value="item.quantity"
        :minValue="0"
        :precision="isFractional ? 2 : 0"
        :isInvalid="isInvalidQuantity"
        @input="onChange('quantity', $event)"
        :has-currency="false"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_PRODUCT_MODAL.INPUT__QUANTITY
        "
      />
      <ez-mask-input
        formKey=""
        name="price"
        label="Price per Unit"
        :value="item.price || 0"
        :allowNegativeValue="false"
        @input="onChange('price', $event)"
        type="input"
        class="price-input mt-12"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_PRODUCT_MODAL.INPUT__PRICE_PER_UNIT
        "
      >
        <template #suffix>/ {{ item.orderingUnit?.name }}</template>
      </ez-mask-input>
      <ez-input
        formKey=""
        name="batchCode"
        label="Batch ID"
        placeholder="Enter Batch ID"
        :value="item.batchCode"
        :disabled="!item.product?.inventoryTracked"
        @onChange="onChange('batchCode', $event)"
        class="batchCode-input mt-12"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_PRODUCT_MODAL.INPUT__BATCH_ID
        "
      />
      <ez-date-input
        formKey=""
        name="expiryDate"
        label="Expiry Date"
        :value="item.expiryDate"
        :placeholder="getDateFormat()"
        :dateFormat="getDateFormat()"
        :disabled="!item.product?.inventoryTracked"
        @onChange="onChange('expiryDate', $event)"
        class="mt-12"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_PRODUCT_MODAL.INPUT__EXPIRY_DATE
        "
      />
      <ez-textarea
        formKey=""
        name="note"
        label="Note"
        :value="note"
        class="mt-12"
        placeholder="Leave a Note"
        @onChange="note = $event"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_PRODUCT_MODAL.TEXTAREA__NOTE
        "
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="onCancel"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_PRODUCT_MODAL.BUTTON__CANCEL
        "
      >
        Cancel
      </ez-button>
      <ez-button
        type="primary"
        @click="onSave"
        :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_PRODUCT_MODAL.BUTTON__SAVE"
      >
        Save Changes
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.batchCode-input {
  :deep() .input-group__input input:disabled::placeholder {
    color: $color-gray-6C;
  }
}

.price-input {
  :deep() {
    .label {
      font-weight: 500;
    }

    input.mask-input__input {
      font-weight: 400;
    }
  }
}

:deep() .mask-input-wrapper {
  .mask-input.mark-input--inline {
    width: 100%;
  }

  .mask-input__input {
    text-align: center;
    font-weight: normal;
    color: $color-gray-25;
  }
}
</style>
