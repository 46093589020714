<script>
/**
 * Venues
 * @version 1.0.0
 * @since
 */
import VDatePicker from '@/components/v3/patterns/VDatePicker';
import BarChartV2 from '@/util/BarChart/BarChartV2';
import dayjs from 'dayjs';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzDrawer from '@/components/ui/Drawer';
import VStatsBox from '@/components/v3/elements/VStatsBox';
import VStatsBoxes from '@/components/v3/elements/VStatsBoxes';
import VLabel from '@/components/v3/elements/VLabel';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import { formatK, formatPrice, getCurrency } from '@/util/utils';
import EmptyState from '@/views/common/empty-state';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch/index';
import EzTable from '@/components/ui/Table/EzTable';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { LOADING_KEY } from '@/util/constants';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';

export default {
  components: {
    EzSpinner,
    EzLoader,
    EzLoadMore,
    EzTable,
    VLabel,
    EzDrawer,
    VDatePicker,
    EzEntityInfo,
    VStatsBox,
    VStatsBoxes,
    VProductEntityInfo,
    VVenueEntityInfo,
    EmptyState,
    VSelectSearch,
  },
  data() {
    return {
      range: {
        start: null,
        end: null,
      },
      rangeName: '',
      tmp: 1,
      chart: null,
      data: [],
      selectedProduct: null,
      selectedVenue: {},
      selectedVenueStats: {},
      mostOrderedItems: [],
      emptyState: true,
      products: [],
      loadingMsg: 'Loading...',
      loadMoreMostOrderItemsKey: 'load-more-most-order-items-key',
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    ...mapState('reports', ['meta', 'filtersOutlets']),
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return symbol;
    },
    ...mapState('reports', ['meta', 'filtersOutlets']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_VENUE_REPORT,
        LOADING_KEY.INIT_DISTRIBUTOR,
        LOADING_KEY.FETCH_REPORT_PRODUCTS_SEARCH,
        LOADING_KEY.FETCH_SINGLE_VENUE_REPORT,
        LOADING_KEY.FETCH_MOST_ORDERED_ITEMS,
        LOADING_KEY.JOKER,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(this.loadMoreMostOrderItemsKey);
    },
  },
  created() {
    this.range = { ...this.filtersOutlets.range };
    this.rangeName = this.filtersOutlets.rangeName;
    this.selectedProduct = { ...this.filtersOutlets.product };

    this.updateChart();
  },
  async mounted() {
    const { data } = await this.fetchReportProductsSearch();
    this.products = data.data;
  },
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    ...mapActions('reports', [
      'fetchVenueReport',
      'fetchSingleVenueReport',
      'fetchReportProductsSearch',
      'fetchMostOrderedItems',
    ]),
    ...mapMutations('reports', [
      'SET_META_DATA',
      'UPDATE_FILTERS_OUTLETS',
    ]),
    ...mapMutations('loading', ['SET_LOADING', 'CLEAR_LOADING']),
    onRangeNameChange(rangeName) {
      this.rangeName = rangeName;
      this.UPDATE_FILTERS_OUTLETS({ rangeName });
    },
    onDateChange(range) {
      this.range = range;
      this.UPDATE_FILTERS_OUTLETS({ range });
      this.updateChart();
    },
    async createChart() {
      const chart = document.getElementById('chart');
      this.chart = new BarChartV2(chart, {
        data: this.data,
        options: {
          on: {
            click: this.chartClick,
          },
          yAxis: {
            format: a => `${this.currencySymbol}${formatK(a)}`,
          },
          tooltip: {
            format: a => formatPrice(a),
          },
        },
      });
    },
    filterSelectedResults(results) {
      return results.filter(product => (this.selectedProduct || {}).id !== product.id);
    },
    onProductSelect(data) {
      this.selectedProduct = data.reset ? null : data;
      this.UPDATE_FILTERS_OUTLETS({
        product: data.id ? data : null,
      });
      this.refresh();
    },
    async fetchData() {
      const { data } = await this.fetchVenueReport({
        from: dayjs(this.range.start).toISOString(),
        to: dayjs(this.range.end).toISOString(),
        ...(this.selectedProduct && { id: this.selectedProduct.id }),
      });
      this.data = data.data;
      this.emptyState = !this.data.length;

      // Set header data
      this.SET_META_DATA({
        subtitle: this.$tc('reports.general.venueSubtitle', data.meta.count),
        label: this.$t('reports.general.venueLabel'),
        count: this.$helpers.formatPrice(data.meta.amount),
      });
    },
    async refresh() {
      await this.fetchData();

      if (!this.chart) {
        this.emptyState = !this.data.length;
        await this.$nextTick();
        if (this.emptyState) {
          return;
        }
        await this.createChart();
      }
      this.chart.update(this.data);
    },
    updateChart() {
      this.refresh();
    },
    onLoadMoreClick() {
      const { nextId, nextValue } = this.meta;
      this.fetchMostOrderedItemsSigneView({
        nextId,
        nextValue,
        loadingKey: this.loadMoreMostOrderItemsKey,
      });
    },
    async chartClick({ entity }) {
      const { id } = entity;
      if (id) {
        const from = dayjs(this.range.start).toISOString();
        const to = dayjs(this.range.end).toISOString();

        this.SET_LOADING({ [LOADING_KEY.JOKER]: true });

        const { data } = await this.fetchSingleVenueReport({
          id,
          from,
          to,
        });
        this.selectedVenue = entity;
        this.selectedVenueStats = data.data;
        this.mostOrderedItems = [];
        await this.fetchMostOrderedItemsSigneView();

        this.CLEAR_LOADING({ key: LOADING_KEY.JOKER });

        this.$refs.singleItemDrawer.open();
      }
    },
    async fetchMostOrderedItemsSigneView({ nextId = null, nextValue = null, loadingKey } = {}) {
      const { id } = this.selectedVenue;
      const from = dayjs(this.range.start).toISOString();
      const to = dayjs(this.range.end).toISOString();
      const res = await this.fetchMostOrderedItems({
        venueId: id,
        from,
        to,
        nextId,
        nextValue,
        ...(loadingKey ? { loadingKey } : null),
      });
      const { data, meta } = res.data;
      this.SET_META_DATA({
        ...this.meta,
        nextId: meta.nextId,
        nextValue: meta.nextValue,
      });
      this.mostOrderedItems = [...this.mostOrderedItems, ...data];
    },
    destroy() {
      if (this.chart) this.chart.destroy();
    },
  },
};
</script>
<template>
  <div>
    <ez-loader :show="isLoading">
      {{loadingMsg}}
    </ez-loader>
    <div class="u-flex-center mt-24 mb-48">
      <v-select-search
        v-if="products.length"
        class="select-product"
        :data="products"
        value-property="id"
        placeholder="Showing for all Products"
        searchPlaceholder="Select Product"
        :selected="selectedProduct"
        @selected="onProductSelect"
        align-left
      >
        <template #result="{result}">
          <ez-entity-info :imgUrl="result.image" :imgHasBorder="true">
            <span :title="result.name">{{ result.name }}</span>
          </ez-entity-info>
        </template>
      </v-select-search>
      <v-date-picker
        class="ml-12"
        name="range"
        label="Range"
        v-model="range"
        rangeMode
        hideLabel
        withPredefinedRanges
        :numberOfCalendars="2"
        :selectPredefinedRange="rangeName"
        @dateChange="onDateChange"
        @rangeNameChange="onRangeNameChange"
      />
    </div>
    <div id="chart" v-show="!emptyState"></div>
    <div class="pt-48" v-show="emptyState">
      <empty-state>
        <template #badge>
          <img src="@/assets/no-reports.svg" width="160" height="138" alt="Empty state image" />
        </template>
        <template #title>No data for selected filters</template>
        <template #info>Try with different filters.</template>
      </empty-state>
    </div>

    <ez-drawer ref="singleItemDrawer">
      <template #content>
        <div class="item-drawer__container">
          <header class="item-drawer__header mb-24 pb-24">
            <v-venue-entity-info :venue="selectedVenueStats.venue" class="mb-24 drawer-title" />
            <div class="contact-info">
              <div class="contact-info__item">
                <v-label as="small">Primary Contact Name</v-label>
                <div
                  class="contact-info__value drawer-header__text--ellipses"
                  :title="selectedVenueStats.venue.primaryContactName"
                >
                  {{ selectedVenueStats.venue.primaryContactName }}
                </div>
              </div>
              <div class="contact-info__item">
                <v-label as="small">Primary Contact Phone Number</v-label>
                <div
                  class="contact-info__value drawer-header__text--ellipses"
                  :title="selectedVenueStats.venue.primaryContactPhone"
                >
                  {{ selectedVenueStats.venue.primaryContactPhone }}
                </div>
              </div>
            </div>
          </header>

          <v-label as="small" class="mb-12">Stats for {{ rangeName }}</v-label>
          <v-stats-boxes class="stats-boxes--large">
            <v-stats-box>
              <template #label>Average Order</template>
              <template #value>{{ selectedVenueStats.avgAmount | price }}</template>
            </v-stats-box>
            <v-stats-box>
              <template #label>Sales for Period</template>
              <template #value>{{ selectedVenueStats.rangeAmount | price }}</template>
            </v-stats-box>
            <v-stats-box>
              <template #label>Lifetime Sales</template>
              <template #value>{{ selectedVenueStats.totalAmount | price }}</template>
            </v-stats-box>
            <v-stats-box>
              <template #label>Gross Margin</template>
              <template #value v-if="!selectedVenueStats.grossMargin">-</template>
              <template #value v-else>{{ selectedVenueStats.grossMargin }}%</template>
            </v-stats-box>
          </v-stats-boxes>

          <v-label as="h4" class="drawer-subtitle mb-2">Most Ordered Items</v-label>
          <v-label class="mb-16">{{ rangeName }}</v-label>
          <div class="drawer-table-container">
            <ez-table
              :data="mostOrderedItems"
              :headers="{
                name: () => 'Product',
                quantity: () => 'Qty',
                total: () => 'Total Amount',
              }"
              :columns="['name', 'quantity', 'unit', 'total']"
              :column-props="{
                name: { class: 'width-100-cell' },
                quantity: { class: 'qty-cell text-right-cell' },
                unit: { class: 'unit-cell text-right-cell' },
                total: { class: 'price-cell padding-right-price-cell' },
              }"
            >
              <template #cell-name="{ row }">
                <v-product-entity-info :product="row" />
              </template>
              <template #cell-quantity="{ row }">
                {{ row.count }}
              </template>
              <template #cell-unit="{ row }">
                <span :title="row.orderingUnit.label">
                  {{ row.orderingUnit.abbreviation ? row.orderingUnit.abbreviation : row.orderingUnit.label }}
                </span>
              </template>
              <template #cell-total="{ row }">
                {{ row.amount | price }}
              </template>
            </ez-table>
            <div v-if="isLoadingMore" class="u-text-center mt-12">
              <ez-spinner/>
            </div>
            <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="onLoadMoreClick" />
          </div>
        </div>
      </template>
    </ez-drawer>
  </div>
</template>
<style lang="scss" scoped>
:deep() .table {
  .unit-cell {
    width: 95px;
  }
  tbody {
    tr {
      &:hover {
        cursor: default;
        td {
          background-color: inherit;
        }
      }
    }
  }
}

.drawer-title {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 29px;
}

.drawer-table-container {
  height: 630px;
  overflow: auto;

  & > :deep() div {
    padding-bottom: 1px;
  }
}

.padding-right-price-cell {
  padding-right: 5px !important;
}

:deep() .drawer-subtitle {
  @include font-size(16px, 22px);
  font-weight: bold;
  color: inherit;
}

.drawer-header__text--ellipses {
  @extend %text-overflow-ellipsis;
  width: 276px;
}

.contact-info {
  display: flex;
  align-items: center;

  &__item {
    flex: 1;
  }

  &__value {
    margin-top: 6px;
    color: #2b2d3e;
    font-size: 14px;
    letter-spacing: -0.25px;
    line-height: 16px;
  }
}
.select-product {
  :deep() .select-search {
    &__trigger {
      min-width: 240px;
    }
  }
}

.item-drawer {
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 24px 24px;
  }

  &__header {
    border-bottom: 1px dashed #dee1e4;
  }
}
</style>
<style lang="scss">
@import '../../../../scss/chart';
</style>
