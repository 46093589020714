<script>
/**
 * Netsuite Warehouses
 * @version 1.0.0
 * @since 3.24.0
 */

import { mapGetters, mapActions } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import netsuiteGuard from '@/views/platform/distributor/netsuite/netsuiteGuard';
import { debounce } from '@/util/utils';

import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzTable from '@/components/ui/Table/EzTable';
import EzAlert from '@/components/ui/Alert/EzAlert';
import flash from '@/components/ui/FlashMessage';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input/EzInput.vue';

import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

export default {
  mixins: [netsuiteGuard],
  name: 'Warehouses',
  components: {
    EzLoadMore,
    EzSpinner,
    EzLoader,
    EzTable,
    EzAlert,
    VSelectSearch,
    EzFilterList,
    EzInput,
  },
  data() {
    return {
      warehouses: [],
      meta: {},
      distributorWarehouses: [],
      initialMappings: {},
      filters: {
        term: null,
      },
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    ...mapGetters('entities/users', {
      connectionExpired: 'isDistributorNetSuiteConnectionExpired',
    }),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_WAREHOUSES,
        LOADING_KEY.DISTRIBUTOR_NETSUITE_UPDATE_WAREHOUSES,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_MORE_WAREHOUSES);
    },
    isSomeMapped() {
      return this.warehouses.some(wr => wr.warehouse);
    },
    isLastPage() {
      return this.meta.lastPage === this.meta.page || !this.meta.lastPage;
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('netsuite', [
      'distributorNetsuiteFetchWarehouses',
      'distributorNetsuiteUpdateWarehouses',
    ]),
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    async fetchDistributorWarehouses() {
      const { data: distributorWarehouses } = await this.distributorFetchWarehouses();
      this.distributorWarehouses = distributorWarehouses.data;
    },
    async fetchNetsuiteWarehouses(loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_WAREHOUSES) {
      const query = {
        ...(!this.isLastPage ? { page: this.meta.page + 1 } : {}),
        ...this.filters,
      };
      const { data: warehouses } = await this.distributorNetsuiteFetchWarehouses({
        query,
        loadingKey,
      });

      this.warehouses = [...this.warehouses, ...warehouses.data];
      this.setInitialMappings(warehouses.data);
      this.meta = warehouses.meta;
    },
    async fetchMoreNetsuiteWarehouses() {
      await this.fetchNetsuiteWarehouses(LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_MORE_WAREHOUSES);
    },
    async resetFilters() {
      Object.keys(this.filters).forEach((key) => { this.filters[key] = null; });
      this.meta = {};
      this.warehouses = [];
      this.fetchNetsuiteWarehouses();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      }
      this.meta = {};
      this.warehouses = [];
      this.fetchNetsuiteWarehouses();
    }, 300),
    async updateWarehouse(selected, row) {
      const found = {
        ...this.warehouses.find(({ id }) => id === row.id),
        warehouse: selected.reset ? null : selected,
      };
      await this.saveChanges(found);
    },
    async saveChanges(item) {
      const data = [
        {
          externalId: item.id,
          warehouseId: item.warehouse?.id || null,
        },
      ];

      try {
        await this.distributorNetsuiteUpdateWarehouses({ data });

        const updatedIdx = this.warehouses.findIndex(w => w.id === item.id);
        this.warehouses.splice(updatedIdx, 1, item);

        await this.$nextTick();
        this.setInitialMappings(this.warehouses);

        flash.success({ title: this.$t('netsuite.warehouses.flashMessages.success.title') });
      } catch (err) {
        flash.error({
          title: this.$t('netsuite.warehouses.flashMessages.error.title'),
          message: this.$t('netsuite.warehouses.flashMessages.error.message'),
        });
      }
    },
    setInitialMappings(warehouses) {
      this.initialMappings = {
        ...this.initialMappings,
        ...warehouses.reduce((a, c) => ({ ...a, [c.id]: c.warehouse?.id || null }), {}),
      };
    },
  },
  async created() {
    await Promise.all([
      this.fetchDistributorWarehouses(),
      ...(this.connectionExpired ? [] : [this.fetchNetsuiteWarehouses()]),
    ]);
  },
};
</script>

<template>
  <div>
    <div class="header mb-32">
      <ez-alert v-if="!isLoading && !isSomeMapped" variant="disclaimer" custom-class="mt-0">
        <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
        <span>{{ $t('netsuite.warehouses.alert', { platform: platformName }) }}</span>
      </ez-alert>

      <div class="subheader">{{ $t('netsuite.warehouses.subhead', { platform: platformName }) }}</div>
    </div>
    <ez-filter-list
      :filters="filters"
      @filterUpdated="updateFilters"
      @resetFilter="resetFilters"
      class="mb-24"
    >
      <ez-input
        formKey="filters"
        name="search"
        class="search"
        :placeholder="`Search for a Location`"
      >
        <template #prefix>
          <font-awesome-icon icon="search" />
        </template>
      </ez-input>
    </ez-filter-list>
    <ez-table
      v-if="warehouses.length"
      class="sync-table"
      :disable-hover="true"
      :data="warehouses"
      :columns="['location', 'warehouse']"
      :columnProps="{
        location: { class: 'name-cell' },
      }"
      :headers="{
        location: () => `${$t('netsuite.name')} Location`,
      }"
    >
      <template #cell-location="{ row }">
        <span>{{ row.name }}</span>
      </template>
      <template #cell-warehouse="{ row }">
        <v-select-search
          :data="distributorWarehouses"
          placeholder="Select Location"
          :selected="row.warehouse"
          @selected="obj => updateWarehouse(obj, row)"
        />
      </template>
    </ez-table>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more
      v-if="!isLoading && !isLoadingMore && !isLastPage"
      @loadMore="fetchMoreNetsuiteWarehouses"
    />

    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped>
.header {
  .subheader {
    @include font-size(14px, 20px);
    width: 400px;
    font-weight: 500;
    color: $color-gray-6C;
  }
}

.sync-table tbody tr td {
  overflow: visible;
}

.name-cell span {
  @include font-size(14px, 22px);
  font-weight: 600;
  color: $color-gray-25;
}
</style>
