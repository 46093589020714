<script>
import { wizardEmitterMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button/EzButton';
import EzWizard from '@/components/layout/Wizard';
import steps, {
  VENUE_STEP_1,
  VENUE_STEP_2,
} from '@/views/platform/distributor/outlets/accept-send-onboarding-link/steps';
import { mapMutations, mapState } from 'vuex';

/**
 *
 * @version 1.0.0
 * @since 3.8.1
 */
export default {
  name: 'Index',
  mixins: [wizardEmitterMixin],
  components: {
    EzButton,
    EzWizard,
  },
  props: {
    prefilledName: {
      type: String,
    },
  },
  data() {
    return {
      currentStep: 0,
      VENUE_STEP_1,
      VENUE_STEP_2,
      nextDisabled: true,
      loadingMsg: 'Loading...',
    };
  },
  computed: {
    ...mapState('entities/venues', ['acceptAndSendOnboardingLink']),
    venue() {
      return this.acceptAndSendOnboardingLink?.venue;
    },
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === VENUE_STEP_1;
    },
    isLastStep() {
      return this.currentStep === VENUE_STEP_2;
    },
    disableNext() {
      return false;
    },
  },
  methods: {
    ...mapMutations('entities/venues', [
      'CLEAR_ACCEPT_AND_SEND_ONBOARDING_LINK_DATA',
      'CLEAR_DRAFT',
    ]),
    onWizardExit() {
      if (this.venue.id) {
        this.$router.push({
          name: 'distributor-outlets-requests-single',
          params: { id: this.venue.id },
        });
      } else {
        this.$router.push({ name: 'distributor-outlets-requests' });
      }
      this.CLEAR_ACCEPT_AND_SEND_ONBOARDING_LINK_DATA();
      this.CLEAR_DRAFT();
    },
    onStepBack(step) {
      if (step !== undefined && step >= VENUE_STEP_1 && step <= VENUE_STEP_2) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= VENUE_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= VENUE_STEP_2) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
};
</script>

<template>
  <div>
    <ez-wizard
      :fullWidth="currentStep === VENUE_STEP_1"
      @exit="onWizardExit"
      @back="goToPreviousStep">
      <template #title>Accept and Send Onboarding Link</template>
      <template #nav>
        <ul>
          <li :class="{
            'ez-step-active': currentStep === VENUE_STEP_1,
            'ez-step-completed': currentStep > VENUE_STEP_1,
          }">
            1. Products
          </li>
        </ul>
      </template>
      <template #actions>
        <ez-button
          :disabled="nextDisabled"
          @click="goToNextStep">{{ nextCta }}</ez-button>
      </template>
      <template #prevStep>
        <ez-button
          v-if="currentStep > VENUE_STEP_1 && currentStep < VENUE_STEP_2"
          type="link"
          formType="button"
          @click="goToPreviousStep">
          <font-awesome-icon icon="arrow-left"/>
          <span>Back</span>
        </ez-button>
      </template>
      <template #pageTitle>{{ currentStepPageTitle }}</template>
      <template #pageInfo>{{ currentStepPageInfo }}</template>
      <component
        :prefilledName="prefilledName"
        :is="currentStepComponent"
        @stepCompleted="onStepCompleted"
        @stepBack="onStepBack"></component>
    </ez-wizard>
  </div>
</template>

<style lang="scss" scoped></style>
