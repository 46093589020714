<script>
import { mapMutations, mapState } from 'vuex';
import EzInput from '@/components/ui/Input/EzInput';
import { wizardListenerMixin } from '@/mixins/wizard';
import EzForm from '@/components/ui/Form/EzForm';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'Info',
  mixins: [wizardListenerMixin],
  components: {
    EzInput,
    EzForm,
    EzCheckbox,
  },
  data() {
    return {
      formKey: 'sendOnboardingLink',
      namesToDisable: [],
      additionalData: {},
      email: null,
      smsNumber: null,
      whatsAppNumber: null,
      emailEnabled: false,
      smsEnabled: false,
      whatsAppEnabled: false,
      requiredValues: {
        name: null,
        contactName: null,
      },
      values: {},
      formValid: false,
      isActive: false,
    };
  },
  computed: {
    ...mapState('entities/venues', ['sendOnboardingLinkData']),
    onboardingLinkData() {
      return this.sendOnboardingLinkData;
    },
    isEdit() {
      return !!this.onboardingLinkData.name;
    },
    targetVenueId() {
      return this.onboardingLinkData.id;
    },
    action() {
      if (this.isEdit) {
        return `distributor/venues/${this.onboardingLinkData?.id}`;
      }
      return 'distributor/venues/target';
    },
    method() {
      return this.isEdit ? 'patch' : 'post';
    },
    isNotifyActive() {
      return this.emailEnabled || this.smsEnabled || this.whatsAppEnabled;
    },
  },
  watch: {
    isNotifyActive(val) {
      this.isActive = val;
    },
    formValid(val) {
      if (val && this.isActive) {
        this.enableNextStep();
      } else {
        this.disableNextStep();
      }
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERRORS']),
    ...mapMutations('entities/venues', ['SET_ONBOARDING_LINK_DATA']),
    isDisabled(name) {
      return !!this.namesToDisable.find(x => x === name);
    },
    onSuccessOnboarding({ data: { data } }) {
      this.SET_ONBOARDING_LINK_DATA({
        name: this.values.name,
        contactName: this.values.contactName,
        ...(this.smsEnabled ? { smsNumber: this.values.smsNumber } : null),
        ...(this.emailEnabled ? { email: this.values.email } : null),
        ...(this.whatsAppEnabled ? { whatsAppNumber: this.values.whatsAppNumber } : null),
        whatsAppEnabled: this.whatsAppEnabled,
        emailEnabled: this.emailEnabled,
        smsEnabled: this.smsEnabled,
        ...(!this.isEdit && data),
      });
      this.$emit('stepCompleted');
    },
    onNextStep() {
      this.$refs.onboardingForm.onSubmit();
    },
    smsChanged(name, val) {
      this.smsEnabled = val;
      if (!this.smsEnabled) {
        this.deleteFromRequiredValues(name);
        return;
      }
      this.updateFormState(name, false);
    },
    smsUpdate(name, val) {
      this.smsNumber = val;
      this.updateFormState(name, this.smsNumber);
    },
    whatsAppChanged(name, val) {
      this.whatsAppEnabled = val;
      if (!this.whatsAppEnabled) {
        this.deleteFromRequiredValues(name);
        return;
      }
      this.updateFormState(name, false);
    },
    whatsAppUpdate(name, val) {
      this.whatsAppNumber = val;
      this.updateFormState(name, this.whatsAppNumber);
    },
    emailChanged(name, val) {
      this.emailEnabled = val;
      this.email = undefined;
      if (!this.emailEnabled) {
        this.deleteFromRequiredValues(name);
        return;
      }
      this.updateFormState(name, this.email);
    },
    emailUpdate(name, val) {
      this.email = val;
      this.updateFormState(name, this.email);
    },
    async updateFormState(name, value) {
      this.values[name] = value;
      if (!value) {
        this.requiredValues[name] = false;
      } else {
        this.requiredValues[name] = await this.$validator.validate(name, value);
      }
      this.validateForm();
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.requiredValues).forEach((key) => {
        if (!this.requiredValues[key]) this.formValid = false;
      });
    },
    deleteFromRequiredValues(name) {
      delete this.requiredValues[name];
      this.validateForm();
    },
    async initialFormState() {
      await this.$nextTick();
      this.requiredValues = {
        name: this.onboardingLinkData?.name,
        contactName: this.onboardingLinkData?.contactName,
      };
    },
  },
  async created() {
    if (this.isEdit) {
      this.whatsAppEnabled = this.onboardingLinkData.whatsAppEnabled;
      this.emailEnabled = this.onboardingLinkData.emailEnabled;
      this.smsEnabled = this.onboardingLinkData.smsEnabled;
      this.values = {
        contactName: this.onboardingLinkData.contactName,
        name: this.onboardingLinkData.name,
        whatsAppEnabled: this.onboardingLinkData.whatsAppEnabled,
        emailEnabled: this.onboardingLinkData.emailEnabled,
        smsEnabled: this.onboardingLinkData.smsEnabled,
        email: this.onboardingLinkData.email,
        ...(this.smsEnabled && { smsNumber: this.onboardingLinkData.smsNumber }),
        ...(this.emailEnabled && { email: this.onboardingLinkData.email }),
        ...(this.whatsAppEnabled && { whatsAppNumber: this.onboardingLinkData.whatsAppNumber }),
      };
    }
    if (this.targetVenueId) {
      this.namesToDisable.push('name');
      this.additionalData = {
        venueId: this.targetVenueId,
        name: this.onboardingLinkData.name,
      };
    }
    await this.initialFormState();
    this.validateForm();
  },
};
</script>

<template>
  <ez-form
    ref="onboardingForm"
    :action="action"
    :method="method"
    @success="onSuccessOnboarding"
    :form-key="formKey"
    :additional-data="additionalData"
  >
    <ez-input
      class="mt-12"
      :disabled="isDisabled('name')"
      :formKey="formKey"
      validators="required"
      :label="`${$t('global.venue')} Name`"
      :placeholder="`Enter ${$t('global.venue')} Name`"
      name="name"
      @onChange="updateFormState('name', $event)"
      :value="onboardingLinkData.name"
    />
    <ez-input
      class="mt-12"
      :disabled="isDisabled('contactName')"
      :formKey="formKey"
      validators="required"
      label="Ordering Contact"
      placeholder="Enter Ordering Contact Name"
      name="contactName"
      @onChange="updateFormState('contactName', $event)"
      :value="onboardingLinkData.contactName"
    />

    <hr />

    <ez-checkbox
      :formKey="formKey"
      :checked="emailEnabled"
      class="mb-8"
      label="Email"
      @change="emailChanged('email', $event)"
    />
    <ez-input
      :formKey="formKey"
      :readonly="!emailEnabled"
      :validators="`${emailEnabled ? 'required' : ''}|email`"
      class="notification-number-input"
      placeholder="Enter Email Address"
      name="email"
      :value="onboardingLinkData.email"
      @onChange="emailUpdate('email', $event)"
    />
    <ez-checkbox
      :formKey="formKey"
      :checked="smsEnabled"
      class="mt-12 mb-8"
      label="SMS"
      @change="smsChanged('smsNumber', $event)"
    />
    <ez-input
      :formKey="formKey"
      :readonly="!smsEnabled"
      :validators="`${smsEnabled ? 'required' : ''}`"
      class="notification-number-input"
      placeholder="Enter Phone Number"
      name="smsNumber"
      :value="onboardingLinkData.smsNumber"
      @onChange="smsUpdate('smsNumber', $event)"
    />
    <ez-checkbox
      :formKey="formKey"
      :checked="whatsAppEnabled"
      label="WhatsApp"
      class="mt-12 mb-8"
      @change="whatsAppChanged('whatsAppNumber', $event)"
    />
    <ez-input
      :formKey="formKey"
      :readonly="!whatsAppEnabled"
      :validators="`${whatsAppEnabled ? 'required' : ''}`"
      class="notification-number-input"
      placeholder="Enter Phone Number"
      name="whatsAppNumber"
      :value="onboardingLinkData.whatsAppNumber"
      @onChange="whatsAppUpdate('whatsAppNumber', $event)"
    />
  </ez-form>
</template>

<style lang="scss" scoped>

</style>
