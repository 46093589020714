<script>
/**
 * QuickBooks Account Info
 * @version 1.0.0
 * @since
 */

import quickBooksGuard from '@/views/platform/distributor/quick-books/settings/quickBooksGuard';
import {
  distributorGetInfo,
  distributorGetQuickBooksAccounts,
  distributorGetQuickBooksClasses,
  distributorGetQuickBooksTaxesSetup,
  distributorQuickBooksSetupAccount,
} from '@/api/endpoints/quick-books';
import EzButton from '@/components/ui/Button/EzButton';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

export default {
  mixins: [quickBooksGuard],
  components: {
    EzButton,
    EzLoader,
    VSelectSearch,
  },
  props: {
    venueId: {
      type: Number,
    },
  },
  data() {
    return {
      accounts: [],
      classes: [],
      trackingCategories: [],
      departments: [],
      taxes: [],
      selectedAccount: '',
      buttonDisabled: true,
      selectedTax: '',
      selectedDeliveryFeeAccount: '',
      selectedZeroTaxAccount: '',
      selectedOrderDiscountAccount: '',
      selectedInvoiceDiscountAccount: '',
      loading: false,
      formKey: 'accountInfoQuickBooks',
    };
  },
  methods: {
    changeSelectedAccount(item) {
      this.selectedAccount = item.externalId;
      this.buttonDisabled = false;
    },
    changeTax(item) {
      this.buttonDisabled = false;
      this.selectedTax = item.externalId;
    },
    changeDeliveryFee(item) {
      this.buttonDisabled = false;
      this.selectedDeliveryFeeAccount = item.externalId;
    },
    changeZeroTax(item) {
      this.buttonDisabled = false;
      this.selectedZeroTaxAccount = item.externalId;
    },
    changeOrderDiscount(item) {
      this.buttonDisabled = false;
      this.selectedOrderDiscountAccount = item.externalId;
    },
    changeDiscountAccount(item) {
      this.buttonDisabled = false;
      this.selectedInvoiceDiscountAccount = item.externalId;
    },
    async saveChanges() {
      const account = {
        account: this.selectedAccount,
        tax: this.selectedTax,
        deliveryFeeAccount: this.selectedDeliveryFeeAccount,
        orderDiscountAccount: this.selectedOrderDiscountAccount,
        invoiceDiscountAccount: this.selectedInvoiceDiscountAccount,
        zeroTax: this.selectedZeroTaxAccount,
      };
      try {
        await distributorQuickBooksSetupAccount(account, 'accountInfoQuickBooks');
        this.buttonDisabled = true;
        flash.success({
          title: this.$t('quickBooks.integrations.flashMessage.accountSuccess.title'),
        });
      } catch (e) {
        flash.error({
          title: this.$t('quickBooks.integrations.flashMessage.accountSuccess.error.title'),
        });
      }
    },
  },
  async created() {
    this.loading = true;
    try {
      const {
        data: { data: accounts },
      } = await distributorGetQuickBooksAccounts();
      this.accounts = accounts;
      const {
        data: { data: classes },
      } = await distributorGetQuickBooksClasses();
      this.classes = classes;
      const {
        data: { data: taxes },
      } = await distributorGetQuickBooksTaxesSetup();
      this.taxes = taxes;

      const {
        data: {
          data: { config },
        },
      } = await distributorGetInfo();
      if (config) {
        this.selectedAccount = config.account;
        this.selectedTax = config.tax;
        this.selectedDeliveryFeeAccount = config.deliveryFeeAccount;
        this.selectedOrderDiscountAccount = config.orderDiscountAccount;
        this.selectedInvoiceDiscountAccount = config.invoiceDiscountAccount;
        this.selectedZeroTaxAccount = config.zeroTax;
      }
    } finally {
      this.loading = false;
    }
  },
};
</script>

<template>
  <div class="container--small">
    <div class="help-block mb-16 distributor-info">
      <h4 class="distributor-info__title">Invoices</h4>
    </div>
    <v-select-search
      class="account-dropdown width-100"
      align-left
      is-full-width
      :formKey="formKey"
      name="account"
      label="Invoices Class"
      :data="classes"
      value-property="externalId"
      :placeholder="`Select ${$t('quickBooks.name')} Class`"
      :searchPlaceholder="`Search ${$t('quickBooks.name')} Class`"
      :selected="selectedAccount"
      :has-clear="false"
      @selected="changeSelectedAccount"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="taxRates"
      label="Tax Rate"
      :data="taxes"
      value-property="externalId"
      placeholder="Select Tax Rate"
      searchPlaceholder="Search Tax Rate"
      :selected="selectedTax"
      :has-clear="false"
      @selected="changeTax"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="deliveryFeeAccountCode"
      label="Delivery Fee Class"
      :data="classes"
      value-property="externalId"
      placeholder="Select Delivery Fee Class"
      searchPlaceholder="Search Delivery Fee Class"
      :selected="selectedDeliveryFeeAccount"
      :has-clear="false"
      @selected="changeDeliveryFee"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="orderDiscountAccount"
      label="Order Discount Class"
      :data="classes"
      value-property="externalId"
      placeholder="Select Order Discount Class"
      searchPlaceholder="Search Order Discount Class"
      :selected="selectedOrderDiscountAccount"
      :has-clear="false"
      @selected="changeOrderDiscount"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="zeroTax"
      label="Tax Rate for Non-Taxable Items (0%)"
      :data="taxes"
      value-property="externalId"
      placeholder="Select Tax Rate for Non-Taxable Items (0%)"
      searchPlaceholder="Search Tax Rate"
      :selected="selectedZeroTaxAccount"
      @selected="changeZeroTax"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="width-100 mt-16"
      align-left
      is-full-width
      :formKey="formKey"
      name="invoiceDiscountAccount"
      label="Invoice Discount Account"
      :data="accounts"
      value-property="externalId"
      placeholder="Select Discount Account"
      searchPlaceholder="Search Discount Account"
      :selected="selectedInvoiceDiscountAccount"
      @selected="changeDiscountAccount"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <ez-button @click="saveChanges" :disabled="buttonDisabled" class="mt-24">
      Save Changes
    </ez-button>
    <ez-loader :show="loading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped>
:deep() .select-search__trigger {
  span {
    color: $color-gray-6C;
  }
  svg {
    color: $color-gray-6C;
  }
  span.select-search__value {
    color: $color-gray-25;
  }
}

:deep() .select-search__list li.select-search__item {
  padding: 0.625rem 1rem;
}

:deep() .select-search__list li.select-search__item.clear {
  padding: 0rem 1rem;
}
</style>
