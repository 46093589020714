<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import EzTable from '@/components/ui/Table/EzTable';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import VAccountOwnerEntityInfo from '@/components/v3/patterns/VAccountOwnerEntityInfo';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import StatusBadge from '@/views/common/status-badge/StatusBadge';
import {
  ALL_ACCOUNT_REPS,
  CHECK_IN_TEAM_ACTIVITY,
  NO_SPENDINGS,
  ALL_TYPES,
  sendOnboardingAction,
  addActivityAction,
  TARGET_ACCOUNT,
  PENDING_ACCOUNT_OPENING,
  sendConnectionReqAction,
  resendInviteAction,
  LOADING_KEY, addExpenseAction, completeToDoAction, ActivityType,
} from '@/util/constants';
import {
  capitalizeEveryWord, falsy,
  formatContactInfoForVenue,
  formatErrorMessageFromRes,
  formatPrice,
  groupListByDateFormat,
  isLite,
  isPremium,
} from '@/util/utils';
import EzDropDown from '@/components/ui/Dropdown/EzDropdown';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import flash from '@/components/ui/FlashMessage';
import dayjs from 'dayjs';
import EzButton from '@/components/ui/Button/EzButton';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzLoader from '@/components/ui/Loader/EzLoader';
import AccountDrawer from '@/views/platform/distributor/crm/AccountDrawer';
import AccountDrawerData from '@/views/platform/distributor/crm/AccountDrawerData.model';
import EmptyState from '@/views/common/empty-state';
import ActivityTrack from '@/views/platform/distributor/crm/ActivityTrack';
import EzBadge from '@/components/ui/Badge/EzBadge';
import ActivityCard from '@/views/platform/distributor/crm/ActivityCard';
import AddToDoModal from '@/views/platform/distributor/crm/to-dos/AddToDoModal';
import OnboardingLinkModal from '@/views/platform/distributor/outlets/OnboardingLinkModal';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import ConnectionRequestModal from '@/views/platform/distributor/outlets/ConnectionRequestModal';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
import uuid from 'uuid/v4';

const SingleViewTodoConstants = {
  noteTitle: 'Note',
};

export default {
  name: 'AccountCheckIn',
  components: {
    EzSpinner,
    ConnectionRequestModal,
    EzConfirmationModal,
    OnboardingLinkModal,
    AddToDoModal,
    ActivityCard,
    AccountDrawer,
    EzLoader,
    VSelectSearch,
    EzLoadMore,
    EzButton,
    StatusBadge,
    VAccountOwnerEntityInfo,
    EzTable,
    VVenueEntityInfo,
    EzDropDown,
    VDatePicker,
    EmptyState,
    ActivityTrack,
    EzBadge,
    EzTextarea,
  },
  props: {
    singleId: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      routeNameSingleTodo: 'distributor-account-check-in-single-todo',
      loadingMsg: 'Loading...',
      routeNameSingle: 'distributor-account-check-in-single',
      routeName: 'distributor-account-check-in',
      list: [],
      meta: {},
      range: null,
      rangeName: '',
      filterVenue: null,
      venuesOptions: [],
      filtersVals: {
        types: null,
        to: null,
        from: null,
        userId: null,
        venueId: null,
      },
      accountOwnerOptions: [],
      allAccounts: ALL_ACCOUNT_REPS,
      expenseTypes: [],
      allTypes: ALL_TYPES,
      noSpendingsStatus: NO_SPENDINGS,
      singleView: new AccountDrawerData({}),
      todoList: [],
      todoMeta: {},
      todoConstants: SingleViewTodoConstants,
      toDoId: null,
      activityId: null,
      invitationId: null,
      isLoaderShown: false,
      loadingKeyMore: 'fetch-list-more',
      loadingKeyMoreOrders: 'fetch-more-order-history',
      completeNote: null,
      keyId: null,
      supplierCy,
      entityToBeDeleted: '',
      ActivityType,
    };
  },
  computed: {
    ...mapState('reports', ['filtersCheckIn', 'metaOrders', 'metaTarget']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['isSomeLoading']),
    isLoadingMoreOrders() {
      return this.isSomeLoading([
        this.loadingKeyMoreOrders,
      ]);
    },
    showWidget() {
      return !this.$permission.has('createTodos');
    },
    onboardingAction() {
      return `/distributor/invitations/${this.invitationId}/resend`;
    },
    onboardingData() {
      const venue = this.singleView && this.singleView.venue;
      return venue && (venue.onboardingInfo || venue);
    },
    isLoadingTodos() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_TO_DO_LIST,
      ]);
    },
    isLoadingMore() {
      return this.isSomeLoading([
        this.loadingKeyMore,
      ]);
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.INIT_DISTRIBUTOR,
        LOADING_KEY.FETCH_EXPENSE_TYPES_BY_CATEGORY,
        LOADING_KEY.FETCH_EXPENSE_USERS,
        LOADING_KEY.FETCH_REPORT_VENUES_SEARCH,
        LOADING_KEY.FETCH_EXPENSE_LIST,
        LOADING_KEY.FETCH_EXPENSE_SINGLE,
      ]);
    },
    dropdownDisabled() {
      return !this.$permission.has('filterAccountOwners');
    },
    groupedDict() {
      return groupListByDateFormat({
        list: this.list,
        dateKey: 'createdAt',
        dateFormat: 'MMMM YYYY',
      });
    },
    expenseTypesOptions() {
      return [this.allTypes, ...this.expenseTypes];
    },
    selectedExpenseType() {
      const { types } = this.filtersVals;
      const isAllTypes = !types || types.length > 1;
      return isAllTypes ? this.allTypes.type : types[0];
    },
    hasResults() {
      return Object.keys(this.groupedDict).length > 0;
    },
    loggedUserId() {
      return this.loggedUser.id;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', [
      'UPDATE_CHECK_IN_INFO',
      'UPDATE_EXPENSE_INFO',
    ]),
    ...mapActions('reports', [
      'fetchExpenseList',
      'fetchVenuesFormAccountActivity',
      'fetchExpenseSingle',
      'fetchExpenseUsers',
      'fetchExpenseTypesByCategory',
      'fetchToDoSingle',
      'fetchOrderHistory',
      'fetchToDoList',
      'deleteToDo',
      'deleteSingleExpense',
    ]),
    ...mapActions('entities/users', ['initDistributor']),
    ...mapMutations('reports', [
      'UPDATE_FILTERS_CHECK_IN',
      'SET_META_DATA',
      'SET_META_DATA_ORDERS',
    ]),
    ...mapActions('entities/distributors', ['createCheckIn']),
    openConnectionReqModal() {
      this.$refs.connectionReqModal.open();
    },
    getSingeViewActions({ venue, canResend }) {
      const { accountType } = venue;
      if ((isPremium(accountType) || isLite(accountType)) && this.isTargetAccount(venue)) {
        return [addActivityAction, addExpenseAction, completeToDoAction, sendConnectionReqAction];
      }
      if (this.isTargetAccount(venue)) {
        return [addActivityAction, addExpenseAction, completeToDoAction, sendOnboardingAction];
      }
      if (canResend) {
        return [addActivityAction, addExpenseAction, completeToDoAction, resendInviteAction];
      }
      return [addActivityAction, addExpenseAction, completeToDoAction];
    },
    openDeleteModal(entityToBeDeleted, id = null) {
      if (entityToBeDeleted === ActivityType.TODO) {
        if (id) this.toDoId = id;
        this.$refs.toDoConfirmationModal.open();
      } else this.$refs.activityConfirmationModal.open();
    },
    closeToDoDeleteModal() {
      this.$refs.toDoConfirmationModal.close();
    },
    closeActivityDeleteModal() {
      this.$refs.activityConfirmationModal.close();
    },
    async deleteTodoCard() {
      this.closeToDoDeleteModal();
      await this.deleteToDo({ id: this.toDoId });
      this.$refs.accountDrawer.close();

      this.todoList = await this.fetchListTodo();

      flash.success({
        title: 'To-do removed!',
        message: 'You have successfully removed this to-do.',
      });
    },
    async deleteActivity() {
      this.closeActivityDeleteModal();
      try {
        await this.deleteSingleExpense({ id: this.activityId });
        this.$refs.accountDrawer.close();
        this.list = await this.fetchList();

        flash.success({
          title: 'Activity removed!',
          message: 'You have successfully removed this activity.',
        });
      } catch (e) {
        flash.error({
          title: 'Something went wrong',
        });
      }
    },
    openCompleteModal() {
      this.$refs.completeToDo.open();
    },
    closeCompleteModal() {
      this.$refs.completeToDo.close();
    },
    onCloseComplete() {
      this.completeNote = null;
    },
    async completeTodoCard() {
      const data = new FormData();
      const dateTime = new Date();

      data.append('type', 'todo');
      data.append('todoId', this.toDoId);
      data.append('createdAt', dateTime.toISOString());
      if (this.completeNote) {
        data.append('note', this.completeNote);
      }

      await this.createCheckIn({ data });
      this.$refs.accountDrawer.close();

      this.todoList = await this.fetchListTodo();
      await this.initDistributor();
      this.meta = {};
      this.list = await this.fetchList();
      this.closeCompleteModal();

      flash.success({
        title: 'To-do completed!',
        message: 'You have successfully completed this to-do.',
      });
    },
    addExpense() {
      const todoId = this.toDoId;
      this.UPDATE_EXPENSE_INFO({ todoId });
      this.$router.push({ name: 'account-expense-new' });
    },
    setRouteSingleTodo(id) {
      if (this.$route.name === this.routeNameSingleTodo) {
        return;
      }

      this.$router.replace({
        name: this.routeNameSingleTodo,
        params: { id },
      });
    },
    setRouteSingle(id) {
      if (this.$route.name === this.routeNameSingle) {
        return;
      }

      this.$router.replace({
        name: this.routeNameSingle,
        params: { id },
      });
    },
    setRouteList() {
      this.$router.replace({
        name: this.routeName,
      });
    },
    async openSingleView(expense) {
      const { id } = expense;
      this.activityId = id;
      this.entityToBeDeleted = CHECK_IN_TEAM_ACTIVITY;

      const res = await this.fetchExpenseSingle({ id });
      const { data } = res.data;
      const { todo } = data;
      const { venue } = data;
      const hasVenue = !!Object.keys(venue || {}).length;

      this.singleView = new AccountDrawerData({
        ...({ venue } || {}),
        disableRedirectByVenue: true,
        badge: data.type,
        accountOwner: data.user,
        dateLabel: 'Date & Time',
        dateTime: data.createdAt,
        noteTitle: 'Note',
        note: data.note,
        invoices: data.invoices && data.invoices.length ? data.invoices : null,
        secondaryNoteTitle: todo && 'To-Do Note',
        secondaryNote: todo && todo.note,
        icon: { text: 'Activity Info', icon: 'user-friends' },
        totalLabel: 'Total',
        total: data.amount,
        taxIncluded: data.taxIncluded,
        contactInfoCopy: `${this.$t('global.venue')} Info`,
        contactInfoData: (this.isTargetAccount(venue || {}) || !hasVenue) ? null
          : formatContactInfoForVenue(venue || {}),
        deleteEnabled: data.canDelete,
      });

      const { note, invoices } = this.singleView;
      this.singleView.contentTitle = note || invoices ? 'Additional Details' : null;

      this.setRouteSingle(id);
      this.$refs.accountDrawer.openDrawer();
    },
    async openTodoSingleView(todo) {
      const { id } = todo;
      this.toDoId = id;
      this.entityToBeDeleted = ActivityType.TODO;

      this.isLoaderShown = true;

      const res = await this.fetchToDoSingle({ id });
      const { data } = res.data;
      const { venue } = data;
      this.invitationId = venue?.invitationId;
      const hasVenue = !!Object.keys(venue || {}).length;
      let orders;
      if (hasVenue) {
        orders = await this.fetchOrders({ venueId: venue.id });
      }

      const actions = this.getSingeViewActions({ venue: venue || {}, canResend: data.canResend });

      this.singleView = new AccountDrawerData({
        ...({ venue } || {}),
        badge: venue?.accountActivityStatus,
        disableRedirectByVenue: this.isTargetAccount(venue || {})
          || this.isPendingAccount(venue || {}),
        accountOwner: data.accountOwner,
        dateLabel: 'Date Created',
        dateTime: data.createdAt,
        noteTitle: 'Note',
        note: data.note,
        contentTitle: 'Account History',
        orders,
        icon: { text: 'To-Do Info', icon: 'tasks' },
        totalLabel: 'Total',
        total: data.amount,
        taxIncluded: data.taxIncluded,
        contactInfoCopy: `${this.$t('global.venue')} Info`,
        contactInfoData: (this.isTargetAccount(venue || {}) || !hasVenue) ? null
          : formatContactInfoForVenue(venue || {}),
        deleteEnabled: data.canDelete,
        actions,
      });
      const { note, invoices } = this.singleView;
      this.singleView.contentTitle = note || invoices ? 'Additional Details' : null;

      this.isLoaderShown = false;

      this.setRouteSingleTodo(id);
      this.$refs.accountDrawer.openDrawer();
    },
    async fetchOrders({ venueId, loadingKey }) {
      const { nextId, nextValue } = this.metaOrders;

      const { data: { data, meta } } = await this.fetchOrderHistory({
        venueId,
        nextId,
        nextValue,
        ...(loadingKey && { loadingKey }),
      });

      this.SET_META_DATA_ORDERS({ ...meta });
      return data;
    },
    async onLoadMoreOrders(venueId) {
      const currentOrders = this.singleView.orders;
      const loadingKey = this.loadingKeyMoreOrders;
      const orders = await this.fetchOrders({ venueId, loadingKey });
      const orderHistoryList = [...currentOrders, ...orders];

      this.singleView = { ...this.singleView, orders: orderHistoryList };
    },
    isTargetAccount(venue) {
      return TARGET_ACCOUNT === venue.accountActivityStatus;
    },
    isPendingAccount(venue) {
      return PENDING_ACCOUNT_OPENING === venue.accountActivityStatus;
    },
    async fetchListTodo() {
      const {
        data: { data, meta },
      } = await this.fetchToDoList({});

      this.todoMeta = meta;
      return data;
    },
    addToDo() {
      this.$refs.addToDoModal.open();
    },
    async loadingMoreToDos() {
      const { data: { data, meta } } = await this.fetchToDoList({
        nextId: this.todoMeta.nextId,
        nextValue: this.todoMeta.nextValue,
      });
      this.todoList = [
        ...this.todoList,
        ...data,
      ];
      this.todoMeta = meta;
    },
    onCloseAddToDoModal() {
      this.keyId = uuid();
    },
    async onAddToDoSuccess(res) {
      const {
        data: {
          data: { id },
        },
      } = res;

      this.todoList = await this.fetchListTodo();

      const { href } = this.$router.resolve({ name: this.routeNameSingleTodo, params: { id } });

      flash.success({
        title: 'To-do successfully added!',
        ctas: `<a href="${href}">View To-Do Details</a>`,
      });
    },
    onAddToDoError(res) {
      const message = formatErrorMessageFromRes(res);
      flash.error({
        title: 'Unsuccessful action.',
        message,
        delay: 5000,
      });
    },
    addActivity() {
      const todoId = this.toDoId;
      const { venue } = this.singleView;
      const disableVenueChange = true;
      const isVenueAssigned = !falsy(venue);

      this.UPDATE_CHECK_IN_INFO({ disableVenueChange, todoId, venue, isVenueAssigned });
      this.$router.push({ name: 'account-check-in-new' });
    },
    sendOnboardingLink() {
      this.$refs.onboardingModal.open();
    },
    async onSuccessConnectionReq() {
      this.$refs.connectionReqModal.close();
      this.$refs.accountDrawer.close();

      this.todoList = await this.fetchListTodo();

      flash.success({
        title: 'Your action was successful!',
      });
    },
    async onSuccessOnboarding() {
      this.$refs.onboardingModal.close();
      this.$refs.accountDrawer.close();

      this.todoList = await this.fetchListTodo();

      flash.success({
        title: 'Your action was successful!',
      });
    },
    async onLoadMore() {
      const list = await this.fetchList({ loadingKey: this.loadingKeyMore });
      this.list = [...this.list, ...list];
    },
    async onVenueSelect(data) {
      this.filterVenue = data;
      this.UPDATE_FILTERS_CHECK_IN({ venue: data.id ? data : null });
      this.filtersVals = {
        ...this.filtersVals,
        venueId: data ? data.id : null,
      };

      this.meta = {};
      this.list = await this.fetchList();
    },
    async selectAccountOwner(accountOwner) {
      const { id } = accountOwner;
      this.UPDATE_FILTERS_CHECK_IN({ userId: id });
      this.filtersVals = {
        ...this.filtersVals,
        userId: id,
      };
      this.meta = {};
      this.list = await this.fetchList();
    },
    async selectExpenseType(typeObj) {
      const { id } = typeObj;
      const types = id ? [id] : (await this.fetchTypes()).map(type => type.id);
      this.UPDATE_FILTERS_CHECK_IN({ types });
      this.filtersVals = {
        ...this.filtersVals,
        types,
      };
      this.meta = {};
      this.list = await this.fetchList();
    },
    async selectDateRange(range) {
      this.UPDATE_FILTERS_CHECK_IN({ range });
      this.range = range;
      this.filtersVals = {
        ...this.filtersVals,
        from: dayjs(this.range.start).toISOString(),
        to: dayjs(this.range.end).toISOString(),
      };

      this.meta = {};
      this.list = await this.fetchList();
    },
    onRangeNameChange(rangeName) {
      this.rangeName = rangeName;
      this.UPDATE_FILTERS_CHECK_IN({ rangeName });
    },
    async fetchTypes() {
      const { data } = await this.fetchExpenseTypesByCategory({
        category: CHECK_IN_TEAM_ACTIVITY,
      });
      return data.data.map(type => ({
        id: type.key,
        name: capitalizeEveryWord({
          string: type.name,
        }),
      }));
    },
    async fetchVenueOptions() {
      const { data: { data } } = await this.fetchVenuesFormAccountActivity();
      return data;
    },
    async fetchUsers() {
      try {
        const { data: { data } } = await this.fetchExpenseUsers();
        return data;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        flash.error({
          title: `Something went wrong with fetching options for ${this.$t('global.accountReps')}.`,
        });
        return [];
      }
    },
    async fetchList({ loadingKey = null } = {}) {
      const category = CHECK_IN_TEAM_ACTIVITY;
      const { nextId, nextValue } = this.meta;

      const { data: { data, meta } } = await this.fetchExpenseList({
        ...this.filtersVals,
        nextId,
        nextValue,
        category,
        ...(loadingKey ? { loadingKey } : null),
      });

      this.meta = meta;

      this.SET_META_DATA({
        subtitle: `${meta.totalCount} team activities`,
        label: 'TOTAL MONEY SPENT',
        count: Number.isFinite(meta.totalAmount) ? formatPrice(meta.totalAmount) : null,
      });

      return data;
    },
    formatDueDateInfo(data) {
      return {
        isDue: data.isDue,
        isPastDue: data.isPastDue,
        dueDate: data.dueDate,
      };
    },
    updateNote(note) {
      this.completeNote = note;
    },
  },
  async created() {
    const { range, rangeName, userId, venue, types } = this.filtersCheckIn;

    this.range = range;
    this.rangeName = rangeName;
    this.keyId = uuid();

    this.filterVenue = venue;
    const venueId = venue ? venue.id : null;

    try {
      this.isLoaderShown = true;

      [
        this.todoList,
        this.expenseTypes,
        this.accountOwnerOptions,
        this.venuesOptions,
      ] = await Promise.all([
        this.fetchListTodo(),
        this.fetchTypes(),
        this.fetchUsers(),
        this.fetchVenueOptions(),
      ]);

      this.filtersVals = {
        ...this.filtersVals,
        from: dayjs(range.start).toISOString(),
        to: dayjs(range.end).toISOString(),
        userId,
        venueId,
        types: types || this.expenseTypes.map(type => type.id),
      };
      this.accountOwnerOptions = [this.allAccounts, ...this.accountOwnerOptions];

      if (!this.$permission.has('filterAccountOwners')) {
        this.filtersVals.userId = this.accountOwnerOptions[1].id;
      }
      this.list = await this.fetchList();

      const { singleId } = this; // this is passed form params

      if (Number.isFinite(singleId)) {
        if (this.$route.name === this.routeNameSingleTodo) {
          await this.openTodoSingleView({ id: singleId });
        } else {
          await this.openSingleView({ id: singleId });
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      this.isLoaderShown = false;
      flash.error({
        title: 'Something went wrong!',
      });
    }
    this.isLoaderShown = false;
  },
};
</script>

<template>
  <div>
    <div class="account-check-in__actions mt-24">
      <div class="account-check-in__filters">
        <ez-drop-down
          v-if="$permission.has('seeCRMForAll')"
          :disabled="dropdownDisabled"
          :placeholder="allAccounts.name"
          :data="accountOwnerOptions"
          :selected="filtersVals.userId"
          name="accountOwnerId"
          @change="selectAccountOwner"
          :data-cy="supplierCy.CRM.TEAM_ACTIVITY.BUTTON__SELECT_ACCOUNT_REP"
        />

        <ez-drop-down
          :placeholder="allTypes.name"
          :data="expenseTypesOptions"
          :selected="selectedExpenseType"
          name="expenseTypes"
          @change="selectExpenseType"
          :data-cy="supplierCy.CRM.TEAM_ACTIVITY.BUTTON__SELECT_TYPE"
        />

        <v-select-search
          v-if="venuesOptions.length"
          :data="venuesOptions"
          value-property="id"
          :placeholder="`All ${$t('global.venues')}`"
          :searchPlaceholder="`Select ${$t('global.venue')}`"
          :selected="filterVenue"
          @selected="onVenueSelect"
          align-left
          :data-cy="supplierCy.CRM.TEAM_ACTIVITY.BUTTON__SELECT_OUTLET"
        >
          <template #result="{result}">
            <v-venue-entity-info :venue="result" />
          </template>
        </v-select-search>

        <v-date-picker
          name="range"
          label="Range"
          v-model="range"
          rangeMode
          hideLabel
          withPredefinedRanges
          :numberOfCalendars="2"
          :selectPredefinedRange="rangeName"
          @rangeNameChange="onRangeNameChange"
          @dateChange="selectDateRange"
          :data-cy="supplierCy.CRM.TEAM_ACTIVITY.BUTTON__SELECT_DATE"
        />
      </div>
      <ez-button
        @click="$router.push({ name: 'account-check-in-new' })"
        formType="button"
        :data-cy="supplierCy.CRM.TEAM_ACTIVITY.BUTTON__ADD_ACTIVITY"
      >
        Add Activity
      </ez-button>
    </div>
    <div class="team-activity-container">
      <div class="account-check-in__table" v-if="hasResults">
        <div v-for="(group, key) in groupedDict" :key="key">
          <h3 class="account-check-in__group-title mt-24 mb-16">
            {{ group.title }}
          </h3>
          <ez-table
            :data="group.items"
            :columns="['account', 'activity', 'outlet', 'dateTime', 'note']"
            :headers="{
              account: () => $t('global.accountRep'),
              activity: () => 'Activity Type',
              outlet: () => $t('global.venue'),
              dateTime: () => 'Date & Time',
              note: () => 'Note',
            }"
            :column-props="{
              amount: { class: 'price-cell' },
              activity: { class: 'status-small-cell' },
            }"
            @rowClick="openSingleView"
          >
            <template #cell-account="{ row }">
              <v-account-owner-entity-info
                :account-owner="row.user"
                :data-cy="`${supplierCy.CRM.TEAM_ACTIVITY.TEXT__ACCOUNT_REP}-${row.id}`"
              />
            </template>
            <template #cell-activity="{ row }">
              <status-badge
                :status="row.type"
                :data-cy="`${supplierCy.CRM.TEAM_ACTIVITY.TEXT__TYPE}-${row.id}`"
              />
            </template>
            <template #cell-outlet="{ row }">
              <v-venue-entity-info
                v-if="row.venue"
                :venue="row.venue"
                :data-cy="`${supplierCy.CRM.TEAM_ACTIVITY.TEXT__OUTLET}-${row.id}`"
              />
              <span
                v-else
                :data-cy="`${supplierCy.CRM.TEAM_ACTIVITY.TEXT__OUTLET}-${row.id}`"
              >
                No {{$t('global.venue')}} Selected
              </span>
            </template>
            <template #cell-dateTime="{ row }">
              <span
                :data-cy="`${supplierCy.CRM.TEAM_ACTIVITY.TEXT__DATE}-${row.id}`"
              >
                {{ row.createdAt | dateTime }}
              </span>
            </template>
            <template #cell-note="{ row }">
              <span
                :data-cy="`${supplierCy.CRM.TEAM_ACTIVITY.TEXT__NOTE}-${row.id}`"
              >
                {{ row.note }}
              </span>
            </template>
          </ez-table>
        </div>

        <div v-if="isLoadingMore" class="u-text-center mt-12">
          <ez-spinner />
        </div>

        <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="onLoadMore" />

      </div>
      <div class="account-check-in__table--empty width-100" v-else>
        <empty-state>
          <template #badge><img src="@/assets/no-products.svg" alt=""/></template>
          <template #title>No activities for selected filters.</template>
          <template #info>Try with different filtering options.</template>
        </empty-state>
      </div>
      <div v-if="showWidget" class="my-todos">
        <activity-track
          class="todos-track"
          :is-content-empty="!todoList || !todoList.length"
        >
          <template #header>
            <div class="u-flex-space width-100 my-todo-header">
              <div class="my-todo-header__info">
                <span>My To-Do</span>
                <ez-badge
                  v-if="todoList.length"
                  type="grey"
                  :count="todoMeta && todoMeta.totalCount"
                  :show-every-number="true" />
              </div>
              <ez-button
                isFullWidth
                v-if="todoList && todoList.length"
                class="add-new-todos mt-12"
                type="secondary-dark"
                formType="button"
                @click="addToDo">
                Add To-Do
              </ez-button>
            </div>
          </template>
          <template #content>
            <div v-if="isLoadingTodos" class="u-text-center my-12">
              <ez-spinner />
            </div>
            <activity-card
              has-to-do-logo
              v-for="account in todoList"
              :key="account.id"
              :due-date="formatDueDateInfo(account)"
              :venue="account.venue || {}"
              :note="account.note"
              :delete-enabled="account.canDelete"
              :to-hide-account-owner="true"
              @deleteAccount="openDeleteModal(ActivityType.TODO, account.id)"
              @click="openTodoSingleView(account)"
            />
            <ez-load-more
              v-if="!isLoadingTodos && todoMeta.nextId"
              @loadMore="loadingMoreToDos" />
          </template>
          <template #contentEmpty>
            <div class="empty-message" v-if="!(todoList && todoList.length)">
              <span>You don’t have any active to-dos at the moment.</span>
              <ez-button
                isFullWidth
                class="add-new-todos mt-12"
                type="secondary-dark"
                formType="button"
                @click="addToDo">
                Add To-Do
              </ez-button>
            </div>
          </template>
        </activity-track>
      </div>
    </div>

    <account-drawer
      ref="accountDrawer"
      :badge="singleView.badge"
      :venue="singleView.venue"
      :disable-redirect-by-venue="singleView.disableRedirectByVenue"
      :date-label="singleView.dateLabel"
      :date-time="singleView.dateTime"
      :account-owner="singleView.accountOwner"
      :content-title="singleView.contentTitle"
      :note-title="singleView.noteTitle"
      :note="singleView.note"
      :secondary-note-title="singleView.secondaryNoteTitle"
      :secondary-note="singleView.secondaryNote"
      :invoices="singleView.invoices"
      :contact-info-data="singleView.contactInfoData"
      :contact-info-copy="singleView.contactInfoCopy"
      :total="singleView.total"
      :total-label="singleView.totalLabel"
      :tax-included="singleView.taxIncluded"
      :actions="singleView.actions"
      :delete-enabled="singleView.deleteEnabled"
      :orders="singleView.orders"
      :next-id-orders="metaOrders.nextId"
      :is-loading-more-orders="isLoadingMoreOrders"
      :logo-icon="singleView.icon"
      :data-cy="supplierCy.CRM.TEAM_ACTIVITY.SINGLE_ACTIVITY"
      @loadMoreOrders="onLoadMoreOrders"
      @deletePending="openDeleteModal(entityToBeDeleted)"
      @sendOnboardingLink="sendOnboardingLink"
      @resendInvite="sendOnboardingLink"
      @sendConnectionReq="openConnectionReqModal"
      @addActivity="addActivity"
      @onClose="setRouteList"
      @completeToDo="openCompleteModal"
      @addExpense="addExpense"
    />

    <add-to-do-modal
      :key="keyId"
      ref="addToDoModal"
      :prefilled-data="{ accountOwnerId: loggedUserId }"
      :to-hide-account-owner-select="true"
      @submitSuccess="onAddToDoSuccess"
      @submitError="onAddToDoError"
      @onClose="onCloseAddToDoModal"
    />

    <onboarding-link-modal
      ref="onboardingModal"
      :action="onboardingAction"
      method="put"
      :data="onboardingData"
      :names-to-disable="['name']"
      :send-disabled="true"
      @success="onSuccessOnboarding"
    />

    <connection-request-modal
      ref="connectionReqModal"
      :action="onboardingAction"
      method="put"
      :data="onboardingData"
      :names-to-disable="['name']"
      :send-disabled="true"
      @success="onSuccessConnectionReq"
    />

    <ez-confirmation-modal ref="toDoConfirmationModal" type="red">
      <template #title>Remove this To-Do?</template>
      <template #content>
        <p>
          You are about to remove this to-do from your list.
        </p>
      </template>
      <template #footer>
        <ez-button @click="closeToDoDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="deleteTodoCard" type="red">Remove To-Do</ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-confirmation-modal ref="activityConfirmationModal" type="red">
      <template #title>Remove this Activity?</template>
      <template #content>
        <p>
          You are about to remove this activity from your list.
        </p>
      </template>
      <template #footer>
        <ez-button @click="closeActivityDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="deleteActivity" type="red">Remove Activity</ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-confirmation-modal @close="onCloseComplete" ref="completeToDo" icon="question">
      <template #title>Complete To-Do?</template>
      <template #content>
        <p>Before completing this to-do make sure you've logged everything needed.</p>
        <ez-textarea
          :value="completeNote"
          form-key="note"
          @onChange="updateNote"
          placeholder="Leave a Note"
          name="note"
          label="Note"
        />
      </template>
      <template #footer>
        <ez-button type="link" @click="closeCompleteModal">Back</ez-button>
        <ez-button @click="completeTodoCard">
          Yes, Complete To-Do
        </ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-loader :show="isLoaderShown || isLoading">
      {{ loadingMsg }}
    </ez-loader>
  </div>
</template>

<style scoped lang="scss">
:deep() .invoice {
  padding: 0;
}

:deep() .select-search {
  color: $color-gray-6C;

  &__list {
    max-height: 244px;
  }
}

.account-check-in__table--empty {
  margin-top: 64px;
}

.account-check-in__group-title {
  @include font-size(18px, 24px);
}

.account-check-in__actions {
  @extend %flex-center;
  justify-content: space-between;
}

.account-check-in__filters {
  @extend %flex-center;
  & > * {
    margin-right: 16px;
  }
}

.team-activity-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .account-check-in__table {
    flex: 1 1 100%;
  }
  .my-todos {
    flex: 1 0 280px;
    position: sticky;
    top: 24px;
    margin: 64px 0 64px 48px;
    min-height: 572px;
    height: 100%;
    .todos-track {
      min-height: 100%;
      :deep() .track__header {
        height: auto;
      }
      :deep() .track__content {
        height: calc(100% - 92px);
      }
    }
    .my-todo-header {
      flex-direction: column;
      &__info {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .add-new-todo {
        background: #ECEEF5;
      }
    }
  }
  .empty-message {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}
</style>
