<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import EzInput from '@/components/ui/Input';
import EzMultipleImageUpload from '@/components/ui/ImageUpload/EzMultipleImageUpload';
import { mapMutations, mapState } from 'vuex';
import { TAX_OPTIONS } from '@/util/constants';
import { getCurrency } from '@/util/utils';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';

export default {
  name: 'Details',
  mixins: [wizardListenerMixin],
  data() {
    return {
      taxOptions: TAX_OPTIONS,
      tax: null,
      moneySpent: null,
      receipts: [],
      supplierCy,
    };
  },
  components: {
    EzInput,
    EzMultipleImageUpload,
    EzDropdown,
  },
  computed: {
    ...mapState('entities/distributors', ['expense']),
    taxRateId() {
      return this.expense.taxRate?.id;
    },
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return symbol;
    },
    hasSecondStep() {
      return !!(this.tax && this.tax.value !== null && this.moneySpent && this.receipts.length);
    },
    hasSecondStepPrefilled() {
      return this.expense.taxRate
        && this.expense.moneySpent
        && this.expense.receipts.length;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', [
      'SET_EXPENSE_TAX',
      'SET_EXPENSE_SPENT',
      'SET_EXPENSE_RECEIPTS',
    ]),
    onNextStep() {
      this.SET_EXPENSE_SPENT(Number(this.moneySpent).toFixed(2));
      this.SET_EXPENSE_TAX(this.tax);
      this.SET_EXPENSE_RECEIPTS(this.receipts);
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    editTax(tax) {
      this.tax = tax;
    },
    editMoneySpent(value) {
      this.moneySpent = value;
    },
    editReceipts(data) {
      this.receipts = [...data.values()];
    },
  },
  watch: {
    hasSecondStep(val) {
      if (val) {
        this.enableNextStep();
      } else {
        this.disableNextStep();
      }
    },
  },
  created() {
    if (this.hasSecondStepPrefilled) {
      this.tax = this.expense.taxRate;
      this.moneySpent = this.expense.moneySpent;
      this.receipts = this.expense.receipts;
    }
  },
};
</script>
<template>
  <div>
    <h5>Expense Details</h5>
    <ez-input
      form-key="money"
      name="money-spent"
      type="number"
      :min="0"
      label="Money Spent"
      @onChange="editMoneySpent"
      :value="expense.moneySpent"
      :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.DETAILS.INPUT__MONEY_SPENT"
    >
      <template #prefix>
        {{ currencySymbol }}
      </template>
    </ez-input>
    <ez-dropdown
      ref="select"
      class="tax-select mt-12"
      label="Tax Rate"
      :data="taxOptions"
      name="taxRates"
      placeholder="Select Tax"
      is-full-width
      @change="editTax"
      :selected="taxRateId"
      :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.DETAILS.BUTTON__TAX_RATE"
    />
    <hr>
    <h5>Receipts</h5>
    <ez-multiple-image-upload
      ref="imageUpload"
      accept="image/jpeg,image/png,application/pdf"
      formKey="receipt"
      card-type
      name="invoice"
      :value="expense.receipts"
      class="upload-component"
      @change="editReceipts"
      :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.DETAILS.BUTTON__UPLOAD_RECEIPT"
    >
      <template #icon><font-awesome-icon icon="plus" class="preview__button-icon"/></template>
      <template #cta>Upload Receipt</template>
    </ez-multiple-image-upload>
  </div>
</template>
<style lang="scss" scoped>
.title {
  font-weight: 600;
  color: #000;
  font-size: 14px;
}

.upload-component {
  :deep() .preview {
    &__images {
      display: flex;
      flex-direction: column;
    }
  }
  :deep() .preview__image + .preview__image {
    margin-left: 0;
  }
}

.tax-select {
  :deep() .ez-dropdown {
    &__label {
      color: $color-gray-6C;
    }
  }
}
</style>
