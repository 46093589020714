<script>
import { mapActions } from 'vuex';
import flash from '@/components/ui/FlashMessage';
import VCustomerCategory from '@/components/v3/patterns/VCustomerCategory';
import CreateCategoryModal from './CreateCategoryModal';
import DeleteCategoryModal from './DeleteCategoryModal';

/**
 * VCustomerCategoriesSettings
 * @version 1.0.0
 * @since 3.26.0
 */

export default {
  name: 'VCustomerCategoriesSettings',
  props: {
    selectedId: {
      type: Number,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  components: {
    VCustomerCategory,
    CreateCategoryModal,
    DeleteCategoryModal,
  },
  data() {
    return {
      categories: [],
      initialSelectedId: null,
    };
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorFetchCustomerCategories']),
    async fetchCustomerCategories() {
      const { data: categories } = await this.distributorFetchCustomerCategories();
      this.categories = categories.data;
    },
    onSelect(val) {
      this.$emit('onSelect', val);
    },
    onAddNew() {
      this.$refs.createModal.open();
    },
    onEdit(category) {
      this.$refs.createModal.open(category);
    },
    onRemove(category) {
      this.$refs.deleteModal.open(category);
    },
    async onCreateSuccess({ isEdit, id }) {
      flash.success({
        title: `${this.$t('global.venue')} Category successfully ${
          isEdit ? 'updated' : 'created'
        }!`,
      });
      await this.fetchCustomerCategories();
      if (isEdit) return;

      const newCategory = this.categories.find(c => c.id === id);
      this.onSelect(newCategory);
    },
    async onDeleteSuccess(deletedCategoryId) {
      flash.success({ title: `${this.$t('global.venue')} Category successfully deleted!` });
      await this.fetchCustomerCategories();

      if (this.selectedId !== deletedCategoryId) return;
      const initialCategory = this.categories.find(c => c.id === this.initialSelectedId);
      this.onSelect(initialCategory);
    },
  },
  async created() {
    await this.fetchCustomerCategories();
  },
  watch: {
    selectedId(val) {
      if (this.initialSelectedId) return;
      this.initialSelectedId = val;
    },
  },
};
</script>

<template>
  <div class="mb-16 customer-category-settings">
    <div class="help-block">
      <h4 class="help-block__title">Category</h4>
      <p class="help-block__text">
        Create and/or assign a category for the {{ $t('global.venue') | lowercase }}.
      </p>
    </div>

    <v-customer-category
      :data="categories"
      :selected-id="selectedId"
      :name="name"
      :label="`${$t('global.venue')} Category`"
      :placeholder="`${$t('global.venue')} Category`"
      :disabled="!$permission.has('createDeleteProduct')"
      :data-cy="dataCy.BUTTON__CATEGORY"
      has-actions
      is-full-width
      @onSelect="onSelect"
      @onAddNew="onAddNew"
      @onEdit="onEdit"
      @onRemove="onRemove"
    />

    <create-category-modal
      ref="createModal"
      @success="onCreateSuccess"
      :data-cy="dataCy.CREATE_CATEGORY_MODAL"
    />
    <delete-category-modal
      ref="deleteModal"
      @success="onDeleteSuccess"
      :data-cy="dataCy.DELETE_CATEGORY_MODAL"
    />
  </div>
</template>

<style lang="scss" scoped></style>
