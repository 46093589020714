<script>
import { mapActions, mapGetters } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { CREDIT_NOTE_STATUS_APPROVED, LOADING_KEY } from '@/util/constants';

import EzDrawer from '@/components/ui/Drawer/EzDrawer.vue';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';
import flash from '@/components/ui/FlashMessage';
import StatusBadge from '@/views/common/status-badge/StatusBadge.vue';

import CreditNoteHeader from './CreditNoteHeader.vue';
import CreditNoteContent from './CreditNoteContent.vue';
import CreditNoteFooter from './CreditNoteFooter.vue';

import ApproveModal from './modals/ApproveModal.vue';
import DeleteModal from './modals/DeleteModal.vue';

/**
 * CreditNoteDrawer
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'CreditNoteDrawer',
  props: {
    creditNoteId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  components: {
    EzDrawer,
    EzLoader,
    StatusBadge,
    CreditNoteHeader,
    CreditNoteContent,
    CreditNoteFooter,
    ApproveModal,
    DeleteModal,
  },
  data() {
    return {
      creditNote: null,
      originalCreditNote: null,
      updatedCreditNote: {},
      editMode: false,
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_CREDIT_NOTE_AMOUNTS,
        LOADING_KEY.DISTRIBUTOR_UPDATE_CREDIT_NOTE,
      ]);
    },
  },
  methods: {
    ...mapActions('creditNotes', [
      'distributorFetchSingleCreditNote',
      'distributorUpdateCreditNote',
      'distributorDeleteCreditNote',
      'distributorFetchCreditNoteAmounts',
    ]),
    open() {
      this.$refs.creditNoteDrawer.open();
    },
    close() {
      this.$refs.creditNoteDrawer.close();
    },
    async onOpen() {
      this.$emit('open');
      await this.fetchCreditNote();
    },
    onClose() {
      this.$emit('close');
      this.editMode = false;
      this.creditNote = null;
      this.originalCreditNote = null;
    },
    async fetchCreditNote() {
      const { data } = await this.distributorFetchSingleCreditNote({ id: this.creditNoteId });
      this.creditNote = data.data;
      this.originalCreditNote = data.data;
    },
    onEdit() {
      this.editMode = true;
    },
    async onEditSave() {
      await this.updateCreditNote(this.updatedCreditNote, 'Credit Note successfully updated!', 'update');
      this.editMode = false;
    },
    onEditDiscard() {
      this.editMode = false;
      this.creditNote = this.originalCreditNote;
      this.updatedCreditNote = {};
    },
    openApproveModal() {
      this.$refs.approveModal.open();
    },
    async confirmApprove(warehouse) {
      const warehouseUpdated = this.creditNote.warehouse?.id !== warehouse?.id;
      const data = {
        status: CREDIT_NOTE_STATUS_APPROVED,
        ...(warehouseUpdated && { warehouseId: warehouse?.id || null }),
      };

      await this.updateCreditNote(data, 'Credit Note successfully approved!', 'approve');
    },
    openDeleteModal() {
      this.$refs.deleteModal.open();
    },
    async confirmDelete() {
      try {
        await this.distributorDeleteCreditNote({ id: this.creditNoteId });
        this.close();
        flash.success({ title: 'Credit Note successfully deleted!' });
        this.$emit('delete');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    async onUpdate(data) {
      this.updatedCreditNote = { ...this.updatedCreditNote, ...data };
      await this.$nextTick();

      const body = { data: { id: this.creditNoteId, ...this.updatedCreditNote } };
      const { data: res } = await this.distributorFetchCreditNoteAmounts(body);
      this.creditNote = {
        ...this.creditNote,
        stockCredit: res.data.stockCredit,
        nonStockCredit: res.data.nonStockCredit,
        taxAmount: res.data.taxAmount,
        amount: res.data.amount,
      };
    },
    async updateCreditNote(data, message, eventName) {
      try {
        const body = { id: this.creditNoteId, data };
        const { data: res } = await this.distributorUpdateCreditNote(body);
        this.creditNote = res.data;

        flash.success({ title: message });
        this.$emit(eventName);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  },
};
</script>

<template>
  <div>
    <ez-drawer ref="creditNoteDrawer" @onOpen="onOpen" @onClose="onClose" class="credit-note">
      <template #title>
        <span class="credit-note__title">Credit Note</span>
        <status-badge
          v-if="creditNote"
          :status="creditNote.status"
          class="ml-8"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TEXT__STATUS"
        />
      </template>

      <template #header>
        <credit-note-header
          v-if="creditNote"
          :creditNote="creditNote"
          :editMode="editMode"
          @update="onUpdate"
        />
      </template>

      <template #content>
        <credit-note-content
          v-if="creditNote"
          :creditNote="creditNote"
          :editMode="editMode"
          @update="onUpdate"
        />

        <ez-loader :show="isLoading">Loading...</ez-loader>
      </template>

      <template #footer>
        <credit-note-footer
          v-if="creditNote"
          :creditNote="creditNote"
          :updatedCreditNote="updatedCreditNote"
          :editMode="editMode"
          @approve="openApproveModal"
          @edit="onEdit"
          @delete="openDeleteModal"
          @saveCreditEdit="onEditSave"
          @discardCreditEdit="onEditDiscard"
        />
      </template>
    </ez-drawer>

    <approve-modal
      v-if="creditNote"
      ref="approveModal"
      :creditNote="creditNote"
      @approve="confirmApprove"
    />

    <delete-modal ref="deleteModal" @delete="confirmDelete" />
  </div>
</template>

<style lang="scss" scoped>
$footer-height: 56px;

.credit-note {
  :deep() .drawer {
    position: relative;

    &__top {
      padding: $spacing-16 $spacing-24;
    }

    &__content {
      padding: $spacing-16 $spacing-24 0;
    }

    &__footer {
      display: flex;
      align-items: center;
      box-shadow: none;
      height: $footer-height;
      border-top: 1px solid $color-gray-E9;

      .order-total-price {
        @include font-size(20px);
        color: $color-gray-25;
        font-weight: bold;
        margin-left: auto;
      }
    }
  }

  &__title {
    @include font-size(24px, 32px);
    color: $color-gray-25;
  }
}
</style>
