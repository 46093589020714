<template>
  <div class="info-container">
    <div v-if="hasAdditionalFields" class="overview-item mt-16">
      <div v-if="legalName" class="info info--vertical">
        <small>Legal Business Name</small>
        <div>
          <span>{{ legalName }}</span>
        </div>
      </div>
      <div v-if="companyRegistrationNumber" class="info info--vertical">
        <small>Company Registration Number / Tax ID</small>
        <div>
          <span>{{ companyRegistrationNumber }}</span>
        </div>
      </div>
      <div v-if="customInfo" class="info info--vertical">
        <small>Additional Info</small>
        <div>
          <span>{{ customInfo }}</span>
        </div>
      </div>
    </div>
    <div class="overview-item" v-if="hasShippingAddress || hasBillingAddress">
      <h3>Addresses</h3>
      <div v-if="hasShippingAddress" class="ez-info-panel__contact">
        <div class="info info--vertical">
          <small>Delivery Address</small>
          <span class="formatted-address">{{ shippingAddress }} </span>
        </div>
      </div>
      <div v-if="hasBillingAddress" class="ez-info-panel__contact">
        <div class="info info--vertical">
          <small>Billing Address</small>
          <span class="formatted-address">{{ billingAddress }} </span>
        </div>
      </div>
    </div>
    <div v-if="hasWorkingHours" class="overview-item">
      <h3>Cut off time:</h3>
      <div class="info info--vertical">
        <small>Hours</small>
        <div>
          <span>{{ workingHours.from }}</span> - <span>{{ workingHours.to }}</span>
        </div>
      </div>
      <div class="info info--vertical" v-if="venue.timezone">
        <small>Time Zone</small>
        <span>{{ venue.timezone }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    venueId: {
      type: Number,
    },
    venue: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasPrimaryContact() {
      return this.venue.primaryContactName || this.venue.primaryContactPhone;
    },
    hasSecondaryContact() {
      return this.venue.secondaryContactName || this.venue.secondaryContactPhone;
    },
    hasWorkingHours() {
      return Object.keys(this.workingHours).length > 0;
    },
    workingHours() {
      return this.venue.workingHours || {};
    },
    hasShippingAddress() {
      return !!this.venue.shippingAddress && Object.keys(this.venue.shippingAddress).length;
    },
    hasBillingAddress() {
      return !!this.venue.billingAddress && Object.keys(this.venue.billingAddress).length;
    },
    shippingAddress() {
      if (!this.hasShippingAddress) return '';
      return this.venue.shippingAddress.formattedAddress;
    },
    billingAddress() {
      if (!this.hasBillingAddress) return '';
      return this.venue.billingAddress.formattedAddress;
    },
    customInfo() {
      return this.venue.customInfo;
    },
    legalName() {
      return this.venue.legalName;
    },
    companyRegistrationNumber() {
      return this.venue.companyRegistrationNumber;
    },
    hasAdditionalFields() {
      return this.customInfo || this.legalName || this.companyRegistrationNumber;
    },
  },
};
</script>

<style scoped lang="scss">
.formatted-address {
  white-space: pre-line;
}

.ez-info-panel__contact + .ez-info-panel__contact {
  margin-top: 1rem;
}

.address {
  @include separator('both');
}
.overview-item {
  @include separator('bottom');
  h3 {
    margin-top: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 16px;
  }
}
:deep() .entity-info {
  @include separator('bottom');
  flex-direction: column;
  align-items: center;
  &__text {
    margin: 0.75rem 0 0;
    justify-content: center;
  }
}
</style>
