<script>
import i18n from '@/i18n';
import EzDrawer from '@/components/ui/Drawer';
import StatusBadge from '@/views/common/status-badge';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import EzButton from '@/components/ui/Button/EzButton';
import EzInfoModal from '@/components/ui/Modal/EzInfoModal';
import VAccountOwnerEntityInfo from '@/components/v3/patterns/VAccountOwnerEntityInfo';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import Invoice from '@/views/common/orders/Invoice/Invoice';
import EmptyState from '@/views/common/empty-state/EmptyState';
import Timeline from '@/views/common/orders/history-log/Timeline';
import NotOrderingTabs from '@/views/platform/distributor/crm/NotOrderingTabs';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import ButtonSwitch from '@/views/platform/distributor/crm/ButtonSwitch';

const Event = {
  onClose: 'onClose',
  loadMoreOrders: 'loadMoreOrders',
  loadMoreProducts: 'loadMoreProducts',
  setAccountOwner: 'setAccountOwner',
  deletePendingAccount: 'deletePending',
};

export default {
  name: 'AccountDrawer',
  components: {
    EzSpinner,
    Timeline,
    Invoice,
    EzSimpleDropdown,
    EzLoadMore,
    VAccountOwnerEntityInfo,
    EzInfoModal,
    EzButton,
    EzDrawer,
    StatusBadge,
    VVenueEntityInfo,
    EmptyState,
    NotOrderingTabs,
    ButtonSwitch,
  },
  /**
   * If you are updating props, also update AccountDrawerData model.
   */
  props: {
    title: {
      required: false,
      type: String,
      default: null,
    },
    badge: {
      required: false,
      type: String,
      default: null,
    },
    venue: {
      required: false,
      type: Object,
      default: () => null,
    },
    disableRedirectByVenue: {
      required: false,
      type: Boolean,
      default: false,
    },
    products: {
      required: false,
      type: Array,
      default: () => null,
    },
    isLoadingMoreProducts: {
      required: false,
      type: Boolean,
      default: false,
    },
    nextIdProducts: {
      required: false,
      type: Number,
      default: null,
    },
    orders: {
      required: false,
      type: Array,
      default: () => null,
    },
    isLoadingMoreOrders: {
      required: false,
      type: Boolean,
      default: false,
    },
    nextIdOrders: {
      required: false,
      type: Number,
      default: null,
    },
    accountOwner: {
      required: false,
      type: Object,
      default: () => null,
    },
    accountOptions: {
      required: false,
      type: Array,
      default: () => null,
    },
    notification: {
      required: false,
      type: String,
      default: '',
    },
    contentTitle: {
      required: false,
      type: String,
      default: '',
    },
    contentSubtitle: {
      required: false,
      type: String,
      default: '',
    },
    totalLabel: {
      required: false,
      type: String,
      default: '',
    },
    totalLabelInfo: {
      required: false,
      type: String,
      default: '',
    },
    noteTitle: {
      required: false,
      type: String,
      default: '',
    },
    note: {
      required: false,
      type: String,
      default: '',
    },
    secondaryNoteTitle: {
      required: false,
      type: String,
      default: '',
    },
    secondaryNote: {
      required: false,
      type: String,
      default: '',
    },
    invoices: {
      required: false,
      type: Array,
      default: () => null,
    },
    total: {
      required: false,
      type: Number,
      default: null,
    },
    taxIncluded: {
      required: false,
      type: Boolean,
      default: () => null,
    },
    dateLabel: {
      required: false,
      type: String,
      default: 'Data Sent',
    },
    date: {
      required: false,
      type: Number,
      default: null,
    },
    dateTime: {
      required: false,
      type: Number,
      default: null,
    },
    dueDate: {
      required: false,
      type: Object,
      default: () => {},
    },
    /**
     * type: Array<InfoRow>
     */
    contactInfoData: {
      required: false,
      type: Array,
      default: () => [],
    },
    /**
     * Copy for button that opens modal, and modal title.
     */
    contactInfoCopy: {
      required: false,
      type: String,
      default: `${i18n.t('global.venue')} Info`,
    },
    showProductsEmpty: {
      required: false,
      type: Boolean,
      default: false,
    },
    deleteEnabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    moneySpent: {
      required: false,
      type: Number,
      default: null,
    },
    logoIcon: {
      type: Object,
      required: false,
      default: () => {},
    },
    /**
     * type: Array<AccountDrawerAction>
     */
    actions: {
      required: false,
      type: Array,
      default: () => [],
      validator: (values) => {
        const set = new Set(values.map(v => v.text));
        return values.length === set.size;
      },
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      dropdownArrowUp: false,
    };
  },
  computed: {
    taxIncludedMsg() {
      return this.taxIncluded ? 'Tax is included' : 'No Tax';
    },
    venueInfo() {
      if (this.disableRedirectByVenue) {
        return null;
      }

      const { href } = this.$router.resolve({
        name: 'distributor-outlets-single',
        params: { id: this.venue.id },
      });
      return href;
    },
    hasDueDate() {
      return !!this.dueDate?.dueDate;
    },
    hasActions() {
      return !!this.actions.length;
    },
    hasLastResend() {
      return !!this.venue?.lastResend;
    },
    hasValidTotal() {
      return Number.isFinite(this.total);
    },
    shouldDisplayFooter() {
      return this.hasActions || this.hasLastResend || this.hasValidTotal;
    },
  },
  methods: {
    openDrawer() {
      this.$refs.drawer.open();
      this.dropdownArrowUp = false;
    },
    onOptionSelected(accountOwner) {
      this.$emit(Event.setAccountOwner, accountOwner);
    },
    close() {
      this.$refs.drawer.close();
    },
    onClose() {
      this.$emit(Event.onClose);
    },
    openContactInfo() {
      this.$refs.infoModal.open();
    },
    onLoadMoreOrders() {
      if (!this.venue) {
        return;
      }
      this.$emit(Event.loadMoreOrders, this.venue.id);
    },
    onLoadMoreProducts() {
      if (!this.venue) {
        return;
      }
      this.$emit(Event.loadMoreProducts, this.venue.id);
    },
    onDropdownOpen() {
      this.dropdownArrowUp = true;
    },
    onDropdownClose() {
      this.dropdownArrowUp = false;
    },
    deletePendingAccount() {
      this.$emit(Event.deletePendingAccount, this.venue);
    },
  },
};
</script>

<template>
  <ez-drawer ref="drawer" class="account-drawer" @click="openDrawer" @onClose="onClose">
    <template #title>
      <h2 v-if="title" class="account-drawer__title" :data-cy="dataCy.TEXT__TITLE || ''">
        {{ title }}
      </h2>
      <status-badge v-if="badge" :status="badge" :data-cy="dataCy.TEXT__STATUS || ''" />
      <font-awesome-icon
        class="cursor-help ml-8"
        v-if="notification"
        icon="bell"
        v-tooltip="{
          content: `${notification}`,
          classes: ['tooltip-general tooltip-account-drawer'],
          placement: 'right-start',
        }"
        :data-cy="dataCy.ICON__NOTIFICATION || ''"
      />
      <ez-button
        class="account-drawer__delete"
        v-if="deleteEnabled"
        type="link"
        formType="button"
        @click="deletePendingAccount"
        :data-cy="dataCy.BUTTON__DELETE || ''"
      >
        <font-awesome-icon icon="trash" />
      </ez-button>
    </template>
    <template #header>
      <a v-if="venue" class="account-drawer__venue" :href="venueInfo" target="_blank">
        <v-venue-entity-info
          class="mb-16"
          :venue="venue"
          :is-big="true"
          :data-cy="dataCy.TEXT__OUTLET || ''"
        />
      </a>
      <div class="icon-without-venue mb-16" v-if="!venue && logoIcon">
        <div :class="['icon-without-venue__icon', `icon-without-venue__icon--${logoIcon.icon}`]">
          <font-awesome-icon :icon="logoIcon.icon" />
        </div>
        <div class="icon-without-venue__text">{{ logoIcon.text }}</div>
      </div>
      <div v-if="dateLabel || date || dateTime" class="account-drawer__info">
        <div class="info info--vertical">
          <span class="additional-info" :data-cy="dataCy.TEXT__DATE_LABEL || ''">
            {{ dateLabel }}
          </span>
          <span v-if="date" class="account-drawer__date" :data-cy="dataCy.TEXT__DATE || ''">
            {{ date | date }}
          </span>
          <span v-if="dateTime" class="account-drawer__date" :data-cy="dataCy.TEXT__DATETIME || ''">
            {{ dateTime | dateTime }}
          </span>
        </div>
        <div class="info info--vertical u-text-right mt-0">
          <ez-button
            v-if="contactInfoData && contactInfoData.length"
            type="secondary"
            size="medium"
            @click="openContactInfo"
            :data-cy="dataCy.BUTTON__CONTACT_INFO || ''"
          >
            <font-awesome-icon icon="info-circle" />
            <span>
              {{ contactInfoCopy }}
            </span>
          </ez-button>
          <template v-if="hasDueDate">
            <span :class="['additional-info', {
                'mt-12': hasDueDate,
              }]"
            >
              Due Date
            </span>
            <span
              v-if="hasDueDate"
              :class="[
                'account-drawer__date',
                { 'account-drawer__date--blue': dueDate.isDue },
                { 'account-drawer__date--red': dueDate.isPastDue },
              ]"
              :data-cy="dataCy.TEXT__DUE_DATE || ''"
            >
              {{ dueDate.dueDate | date }}
            </span>
          </template>
        </div>
      </div>
      <hr />
      <div v-if="accountOwner || Number.isFinite(moneySpent)">
        <div class="u-flex-space">
          <div v-if="accountOwner" class="account-owner info info--vertical">
            <span class="additional-info mb-8">{{ $t('global.accountRep') }}</span>
            <ez-simple-dropdown
              v-if="accountOptions && accountOptions.length"
              class="account-owner"
              @open="onDropdownOpen"
              @close="onDropdownClose"
              :data-cy="dataCy.BUTTON__ACCOUNT_OPTIONS || ''"
            >
              <template #display>
                <v-account-owner-entity-info
                  class="mr-4"
                  :account-owner="accountOwner"
                  :data-cy="dataCy.TEXT__ACCOUNT_OWNER_DISPLAY || ''"
                />
                <font-awesome-icon v-if="dropdownArrowUp" icon="angle-up" />
                <font-awesome-icon v-else icon="angle-down" />
              </template>
              <template #dropdown>
                <div>
                  <v-account-owner-entity-info
                    class="account-drawer__dropdown-option cursor-pointer"
                    v-for="account in accountOptions"
                    :key="account.id"
                    :account-owner="account"
                    @click.native="onOptionSelected(account)"
                    :data-cy="`${dataCy.TEXT__ACCOUNT_OWNER_OPTION || ''}-${account.id}`"
                  />
                </div>
              </template>
            </ez-simple-dropdown>
            <v-account-owner-entity-info
              v-else
              :account-owner="accountOwner"
              :data-cy="dataCy.TEXT__ACCOUNT_OWNER || ''"
            />
          </div>
          <div v-if="Number.isFinite(moneySpent)" class="info info--vertical mt-0 u-text-right">
            <small class="info--bold mb-8">Money Spent</small>
            <span class="money-spent" :data-cy="dataCy.TEXT__MONEY_SPENT || ''">{{ moneySpent | price }}</span>
          </div>
        </div>
        <hr v-if="!products" />
      </div>
    </template>
    <template #content>
      <div v-if="showProductsEmpty" class="mt-24">
        <not-ordering-tabs
          :orders="orders"
          :next-id-orders="nextIdOrders"
          :is-loading-more-orders="isLoadingMoreOrders"
          :products="products"
          :next-id-products="nextIdProducts"
          :is-loading-more-products="isLoadingMoreProducts"
          :contentSubtitle="contentSubtitle"
          @loadMoreProducts="onLoadMoreProducts"
          @loadMoreOrders="onLoadMoreOrders"
        />
      </div>
      <div class="account-drawer__content">
        <div class="content-products">
          <div v-if="!products && (contentTitle || contentSubtitle)" class="mt-4 mb-16">
            <h4
              v-if="contentTitle"
              class="account-drawer__subtitle"
              :data-cy="dataCy.TEXT__CONTENT_TITLE || ''"
            >
              {{ contentTitle }}
            </h4>
            <span
              v-if="contentSubtitle"
              class="additional-info"
              :data-cy="dataCy.TEXT__CONTENT_SUBTITLE || ''"
            >
              {{ contentSubtitle }}
            </span>
          </div>

          <div v-if="note" class="info info--vertical">
            <small class="info--bold">{{ noteTitle }}</small>
            <span :data-cy="dataCy.TEXT__NOTE || ''">{{ note }}</span>
          </div>

          <div v-if="invoices && invoices.length" class="info info--vertical mt-16">
            <small class="info--bold">Receipts</small>
            <invoice
              v-if="invoices"
              class="mt-12"
              :invoice="invoices"
              action=""
              :data-cy="dataCy.BUTTON__INVOICE || ''"
            />
          </div>

          <div v-if="secondaryNote" class="info info--vertical">
            <small class="info--bold">{{ secondaryNoteTitle }}</small>
            <span :data-cy="dataCy.TEXT__SECONDARY_NOTE || ''">{{ secondaryNote }}</span>
          </div>

          <empty-state
            class="empty-products"
            v-if="
              ((products && !products.length) || (orders && !orders.length)) && !showProductsEmpty
            "
          >
            <template #badge>
              <img
                src="@/assets/no-products.svg"
                width="160"
                height="138"
                alt="Empty state image"
              />
            </template>
            <template #title>
              <span :data-cy="dataCy.TEXT__NO_PRODUCTS_TITLE || ''">
                <template v-if="products && !products.length">No products to show</template>
                <template v-if="orders && !orders.length">No account history</template>
              </span>
            </template>
            <template #info>
              <span :data-cy="dataCy.TEXT__NO_PRODUCTS_INFO || ''">
                <template v-if="products && !products.length">
                  There are no completed orders between you and this
                  {{ $t('global.venue') | lowercase }}.
                </template>
                <template v-if="orders && !orders.length">
                  There’s no account history for this account.
                </template>
              </span>
            </template>
          </empty-state>
          <div v-if="orders && !products" class="account-drawer__orders-container">
            <timeline :history="orders" />
            <div v-show="isLoadingMoreOrders" class="u-text-center mt-12">
              <ez-spinner />
            </div>

            <ez-load-more
              v-if="!isLoadingMoreOrders && nextIdOrders"
              @loadMore="onLoadMoreOrders"
            />
          </div>
        </div>
      </div>

      <ez-info-modal
        :editable="venue && venue.editable"
        :venue="venue || {}"
        ref="infoModal"
        :info-row-list="contactInfoData"
      >
        <template #title>
          <span :data-cy="dataCy.TEXT__CONTACT_INFO || ''">
            {{ contactInfoCopy }}
          </span>
        </template>
      </ez-info-modal>
    </template>
    <template #footer>
      <div class="u-flex-space width-100" v-if="shouldDisplayFooter">
        <span v-if="hasActions">
          <button-switch
            :actions="actions"
            @click="(ev) => $emit(ev)"
            :data-cy="dataCy.BUTTON__ACTION || ''"
          />
        </span>
        <!-- Here so lastResend span will remain right if the user doesn't have any actions. -->
        <span v-if="hasLastResend && !hasActions"></span>
        <span v-if="hasLastResend" class="info info--vertical u-text-right">
          <small class="info--bold">Invite Last Sent</small>
          <span :data-cy="dataCy.TEXT__LAST_INVITE_SENT || ''">
            {{ venue.lastResend.sentAt | date }}
          </span>
        </span>
        <template v-if="hasValidTotal">
          <span class="u-flex-center">
            <span class="account-drawer__total-label" :data-cy="dataCy.TEXT__TOTAL_LABEL || ''">
              {{ totalLabel }}
            </span>
            <font-awesome-icon
              v-if="totalLabelInfo"
              class="account-drawer__info-icon cursor-help ml-4"
              v-tooltip="{
                content: `${totalLabelInfo}`,
                classes: ['tooltip-general'],
                placement: 'start',
              }"
              icon="info-circle"
              :data-cy="dataCy.ICON__TOTAL_LABEL_INFO || ''"
            />
          </span>
          <span class="u-flex-center">
            <font-awesome-icon
              v-if="taxIncluded !== null && taxIncluded !== undefined"
              class="account-drawer__info-icon cursor-help mr-4"
              v-tooltip="{
                content: `${taxIncludedMsg}`,
                classes: ['tooltip-general'],
                placement: 'start',
              }"
              icon="info-circle"
              :data-cy="dataCy.ICON__TAX_INCLUDED_INFO || ''"
            />
            <span class="account-drawer__total-price" :data-cy="dataCy.TEXT__TOTAL || ''">
              {{ total | price }}
            </span>
          </span>
        </template>
      </div>
    </template>
  </ez-drawer>
</template>

<style scoped lang="scss">
$dropdown-margin: 12px;
$dropdown-min-width: 280px;

:deep() .drawer__header {
  padding: 0 24px;
}

:deep() .drawer__close-button {
  line-height: 0;
}

:deep() .drawer__footer {
  @extend %flex-center;
  justify-content: space-between;

  height: 56px;
  border-top: 1px solid #eceef5;
}
.account-owner {
  :deep() .ez-simple-dropdown {
    width: $dropdown-min-width;

    .ez-simple-dropdown__display-container {
      justify-content: flex-start;
      padding: 0;

      background-color: inherit;
    }
    .ez-simple-dropdown__dropdown {
      left: -$dropdown-margin;
      & > div {
        margin-bottom: -$dropdown-margin;
      }
    }
  }
}

.account-drawer__title {
  @include font-size(24px, 32px);
  color: $color-gray-25;
  margin: 0 8px 0 0;
}

.account-drawer__delete {
  margin-left: auto;
  margin-right: 16px;
  padding: 0;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
}

.account-drawer__dropdown-option {
  margin-bottom: $dropdown-margin;
  margin-left: $dropdown-margin;
}

.additional-info {
  @include font-size(12px, 16px);
  font-weight: 600;
  color: $color-gray-6C;
}

.account-drawer__subtitle {
  line-height: 22px;
  margin: 0;
}

.account-drawer__info {
  @extend %flex-center;
  justify-content: space-between;
  align-items: flex-end;
}

.account-drawer__date {
  @include font-size(14px, 22px);
  color: $color-gray-25;
  &--blue {
    color: $color-primary-blue;
  }
  &--red {
    color: $color-primary-red;
  }
}

.account-owner {
  color: $color-gray-25;
}

.money-spent {
  @include font-size(18px, 24px);
  font-weight: bold;
  color: $color-gray-25;
}

.account-drawer__orders-container {
  padding-bottom: 48px;
  height: 100%;
}

.account-drawer__total-container {
  @extend %flex-center;
  justify-content: space-between;
}

.account-drawer__total-label {
  @include font-size(12px, 14px);
  text-transform: uppercase;
  font-weight: bold;
  color: $color-gray-6C;
}

.account-drawer__total-price {
  @include font-size(20px, 28px);
  font-weight: bold;
}

.account-drawer__info-icon {
  color: $color-gray-6C;
}

:deep() .empty-products {
  margin-top: 48px;
  h3 {
    font-weight: 700;
  }
}

:deep() .timeline {
  padding: 0;
}

.account-drawer__venue {
  text-decoration: none;
}

.account-drawer__content {
  padding: 0 24px;
}

.icon-without-venue {
  display: flex;
  align-items: center;
  &__icon {
    @extend %flex-center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    @include font-size(18px);
    &--tasks {
      background-color: $color-pastel-blue;
      color: $color-primary-blue;
    }
    &--user-friends {
      background-color: $color-pastel-yellow;
      color: $color-primary-yellow;
    }
    &--money-bill-wave {
      background-color: $color-pastel-green;
      color: $color-primary-green;
    }
  }
  &__text {
    margin-left: 12px;
    @include font-size(24px, 32px);
    color: $color-gray-25;
    font-weight: 600;
  }
}
</style>
