export default class AccountDrawerData {
  constructor({
    title = null,
    badge = null,
    venue = null,
    dueDate = null,
    disableRedirectByVenue = false,
    products = null,
    isLoadingMoreProducts = false,
    nextIdProducts = null,
    orders = null,
    isLoadingMoreOrders = false,
    nextIdOrders = null,
    accountOwner = null,
    accountOptions = null,
    notification = null,
    contentTitle = null,
    contentSubtitle = null,
    noteTitle = null,
    note = null,
    secondaryNoteTitle = null,
    secondaryNote = null,
    invoices = null,
    totalLabel = null,
    totalLabelInfo = null,
    total = null,
    taxIncluded = null,
    dateLabel = null,
    date = null,
    dateTime = null,
    contactInfoData = [], // Array<InfoRow>
    contactInfoCopy = null,
    showProductsEmpty = false,
    deleteEnabled = false,
    moneySpent = null,
    actions = [], // Array<AccountDrawerAction>
    icon = null,
  }) {
    this.title = title;
    this.badge = badge;
    this.venue = venue;
    this.disableRedirectByVenue = disableRedirectByVenue;
    this.products = products;
    this.isLoadingMoreProducts = isLoadingMoreProducts;
    this.nextIdProducts = nextIdProducts;
    this.orders = orders;
    this.isLoadingMoreOrders = isLoadingMoreOrders;
    this.nextIdOrders = nextIdOrders;
    this.accountOwner = accountOwner;
    this.accountOptions = accountOptions;
    this.notification = notification;
    this.contentTitle = contentTitle;
    this.contentSubtitle = contentSubtitle;
    this.noteTitle = noteTitle;
    this.note = note;
    this.secondaryNoteTitle = secondaryNoteTitle;
    this.secondaryNote = secondaryNote;
    this.invoices = invoices;
    this.totalLabel = totalLabel;
    this.totalLabelInfo = totalLabelInfo;
    this.total = total;
    this.taxIncluded = taxIncluded;
    this.dateLabel = dateLabel;
    this.date = date;
    this.dateTime = dateTime;
    this.contactInfoData = contactInfoData;
    this.contactInfoCopy = contactInfoCopy;
    this.showProductsEmpty = showProductsEmpty;
    this.deleteEnabled = deleteEnabled;
    this.moneySpent = moneySpent;
    this.actions = actions;
    this.dueDate = dueDate;
    this.icon = icon;
  }
}
