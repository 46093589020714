var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ez-wizard',{on:{"exit":_vm.onWizardExit,"back":_vm.goToPreviousStep},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Add Activity")]},proxy:true},{key:"nav",fn:function(){return [_c('ul',[_c('li',{class:{
        'ez-step-active': _vm.currentStep === _vm.CHECK_IN_STEP_1,
        'ez-step-completed': _vm.currentStep > _vm.CHECK_IN_STEP_1,
      }},[_vm._v(" 1. Activity Info ")]),_c('li',{class:{
        'ez-step-active': _vm.currentStep === _vm.CHECK_IN_STEP_2,
        'ez-step-completed': _vm.currentStep > _vm.CHECK_IN_STEP_2,
      }},[_vm._v(" 2. Expenses ")])])]},proxy:true},{key:"actions",fn:function(){return [(_vm.isSecondStep)?_c('ez-button',{staticClass:"mr-8",attrs:{"type":"secondary","data-cy":_vm.supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.BUTTON__SKIP_STEP},on:{"click":function($event){return _vm.goToStep({ step: _vm.CHECK_IN_STEP_3 });}}},[_vm._v(" "+_vm._s(_vm.steps[_vm.CHECK_IN_STEP_2].secondCta)+" ")]):_vm._e(),_c('ez-button',{attrs:{"disabled":_vm.nextDisabled,"data-cy":_vm.isFirstStep
            ? _vm.supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.BUTTON__ADD_EXPENSE
            : _vm.supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.BUTTON__NEXT_STEP},on:{"click":_vm.goToNextStep}},[_vm._v(" "+_vm._s(_vm.nextCta)+" ")]),(_vm.isFirstStep)?_c('ez-button',{staticClass:"ml-8",attrs:{"disabled":_vm.nextDisabled,"data-cy":_vm.supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.BUTTON__NEXT_STEP},on:{"click":function($event){return _vm.goToStep({ step: _vm.CHECK_IN_STEP_3 })}}},[_vm._v(" "+_vm._s(_vm.steps[_vm.CHECK_IN_STEP_2].nextCta)+" ")]):_vm._e()]},proxy:true},{key:"prevStep",fn:function(){return [(_vm.currentStep > _vm.CHECK_IN_STEP_1 && _vm.currentStep < _vm.CHECK_IN_STEP_3)?_c('ez-button',{attrs:{"type":"link","formType":"button","data-cy":_vm.supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.BUTTON__PREV_STEP},on:{"click":_vm.goToPreviousStep}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),_c('span',[_vm._v("Back")])],1):_vm._e()]},proxy:true},{key:"pageTitle",fn:function(){return [_vm._v(_vm._s(_vm.currentStepPageTitle))]},proxy:true},{key:"pageInfo",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.currentStepPageInfo)}})]},proxy:true}])},[_c(_vm.currentStepComponent,{tag:"component",on:{"stepCompleted":_vm.onStepCompleted,"stepBack":_vm.onStepBack}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }