<script>
/**
 *
 * @version 1.0.0
 * @since 3.6.0
 */

import { mapActions, mapState, mapGetters } from 'vuex';
import EzTable from '@/components/ui/Table';
import EzEntityInfo from '@/components/ui/EntityInfo';
import { EzCsvUploadModal } from '@/components/ui/Modal';
import EzButton, { EzButtonGroup } from '@/components/ui/Button';
import ProposePriceModal from '@/views/platform/venue/suppliers/all/ProposePriceModal';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import EzInput from '@/components/ui/Input';
import EzFilterList from '@/components/ui/FilterList';
import flash from '@/components/ui/FlashMessage';
import { falsy } from '@/util/utils';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown';
import downloadAttachment from '@/util/downloadAttachment';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzSpinner from '@/components/ui/Spinner';
import { LOADING_KEY, PRODUCT_VISIBILITY_HIDDEN, PRODUCT_VISIBILITY_VISIBLE } from '@/util/constants';
import ProductModal from '@/views/platform/distributor/outlets/ProductModal';
import VPrice from '@/components/v3/elements/VPrice';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import StatusBadge from '@/views/common/status-badge/StatusBadge';

export default {
  name: 'VenueProducts',
  components: {
    VPrice,
    EzCsvUploadModal,
    EzButtonGroup,
    ProposePriceModal,
    ProductModal,
    EzButton,
    EzTable,
    EzCategoryFilter,
    EzInput,
    EzFilterList,
    EzLoadMore,
    EmptyState,
    EzSimpleDropdown,
    EzButtonDropdown,
    EzEntityInfo,
    EzLoader,
    EzSpinner,
    VDataWithInfo,
    StatusBadge,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: {
        name: () => 'Product',
        defaultPrice: () => 'List Price',
        price: () => 'Price per unit',
        discount: () => 'Discount (%)',
        actions: () => '',
      },
      products: [],
      selectedProduct: {},
      meta: {},
      filters: {
        term: null,
        categoryId: null,
      },
      categories: [],
    };
  },
  computed: {
    ...mapState('entities/distributors', ['selectedSupplier']),
    ...mapState('entities/venues', {
      venueInfo: 'singleDistributorVenue',
    }),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    distributorId() {
      return this.loggedUser.id;
    },
    venue() {
      return this.venueInfo || {};
    },
    isPremium() {
      return this.$helpers.isPremium(this.venue.accountType);
    },
    isEditable() {
      return this.venue.editable;
    },
    addProductAction() {
      return `/distributor/venues/${this.venueId}/products`;
    },
    columns() {
      return [
        'name',
        'priceTier',
        'visibility',
        'defaultPrice',
        'discount',
        'unit',
        'pricePerUnit',
        ...(this.$permission.isPremium && this.canEditPrice ? ['actions'] : []), // If you are premium you can always set/propose a price
      ];
    },
    hasFilters() {
      return !!this.filters.term || !!this.filters.categoryId;
    },
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_HIDE_UNHIDE_PRODUCTS_FOR_VENUE)
        || this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCTS_FOR_VENUE);
    },
    singleHideUnhideLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_HIDE_UNHIDE_VENUE_PRODUCT);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_VENUE_PRODUCTS_MORE);
    },
    exportLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_EXPORT_PRODUCTS_FOR_VENUE);
    },
    setPriceAction() {
      return `/distributor/venues/${this.venueId}/products/${this.selectedProduct.id}/price`;
    },
    isIntegrationOutlet() {
      return !falsy(this.venue.provider);
    },
    canEditPrice() {
      return this.$permission.has('editProductPricing');
    },
    canHideProducts() {
      return this.$permission.has('hideProducts');
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'distributorFetchProductsForVenue',
      'distributorHideVenueProduct',
      'distributorUnhideVenueProduct',
      'distributorHideAllVenueProducts',
      'distributorUnhideAllVenueProducts',
      'distributorUploadVenueProducts',
    ]),
    ...mapActions('entities/categories', [
      'distributorFetchCategories',
    ]),
    ...mapActions('entities/distributors', ['distributorDownloadVenueProductsCSV']),
    proposePrice(product) {
      this.selectedProduct = product;
      this.$refs.modal.open();
    },
    newProduct() {
      this.selectedProduct = {};
      this.openEditModal();
    },
    closeEditModal() {
      this.$refs.productModal.close();
    },
    openEditModal() {
      this.$refs.productModal.open();
    },
    onModalSuccess() {
      flash.success({ title: `Product successfully ${falsy(this.selectedProduct) ? 'added' : 'updated'}.` });
      this.selectedProduct = {};
      this.refresh();
      this.closeEditModal();
    },
    resetFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });
      this.refresh();
    },
    updateFilters(filterName, event) {
      if (filterName === 'search') {
        this.filters = {
          ...this.filters,
          term: event,
        };
      } else {
        this.filters = {
          ...this.filters,
          [filterName]: event.id,
        };
      }
      this.refresh();
    },
    onPriceChange() {
      flash.success({
        title: 'New price successfully set',
        message: `You have set a new price for ${this.selectedProduct.name}`,
      });
      this.refresh();
    },
    fetchNewDistributorProducts(loadingKey) {
      const queryParams = {
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
        ...(this.filters.categoryId ? { categoryId: this.filters.categoryId } : {}),
        ...(this.filters.term ? { term: this.filters.term } : {}),
        sortBy: 'name',
        limit: '20',
      };
      return this.distributorFetchProductsForVenue({
        venueId: this.venueId,
        params: queryParams,
        ...(loadingKey && { loadingKey }),
      });
    },
    async refresh(loadingKey = null) {
      this.meta = {};
      const { data: { data, meta } } = await this.fetchNewDistributorProducts(loadingKey);
      this.products = data;
      this.meta = meta;
    },
    async loadMore() {
      const { data } = await this.fetchNewDistributorProducts(
        LOADING_KEY.DISTRIBUTOR_VENUE_PRODUCTS_MORE,
      );
      this.products = [
        ...this.products,
        ...data.data,
      ];
      this.meta = data.meta;
    },
    onLoadMore() {
      this.loadMore();
    },
    uploadCSVModal() {
      this.$refs.csvUploadModal.open();
    },
    uploadCSVAction(data) {
      return this.distributorUploadVenueProducts({
        venueId: this.venueId,
        data,
      });
    },
    onUploadFinished() {
      this.refresh();
    },
    async exportCSV() {
      const { data, headers } = await this.distributorDownloadVenueProductsCSV({
        venueId: this.venueId,
      });
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'Products.xlsx';
      downloadAttachment(data, name);
    },
    updateProductAvailability(venue) {
      const { id, isHidden } = venue;
      const data = {
        productId: id,
        venueId: this.venueId,
      };
      return isHidden
        ? this.distributorUnhideVenueProduct(data)
        : this.distributorHideVenueProduct(data);
    },
    async updateVisibility(venue) {
      if (!this.canHideProducts) return;
      try {
        await this.updateProductAvailability(venue);
        venue.isHidden = !venue.isHidden;
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    async bulkUpdateProductVisibility(isHidden) {
      try {
        const data = {
          venueId: this.venueId,
          loadingKey: 'no-loader',
        };
        await (isHidden
          ? this.distributorHideAllVenueProducts(data)
          : this.distributorUnhideAllVenueProducts(data));
        await this.refresh();
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    selectAll() {
      this.bulkUpdateProductVisibility(false);
    },
    deselectAll() {
      this.bulkUpdateProductVisibility(true);
    },
    status(isHidden) {
      return isHidden ? PRODUCT_VISIBILITY_HIDDEN : PRODUCT_VISIBILITY_VISIBLE;
    },
  },
  async created() {
    if (this.isIntegrationOutlet) {
      await this.$router.push({ name: 'distributor-outlets-sales-settings' });
      return;
    }
    const [categories] = await Promise.all([
      this.distributorFetchCategories(),
      this.refresh(),
    ]);
    const { data: { data } } = categories;
    this.categories = data;
  },
};
</script>
<template>
  <div>
    <product-modal
      ref="productModal"
      formKey="outlets-product-modal"
      :formAction="addProductAction"
      :distributorId="distributorId"
      :product="selectedProduct"
      :categories="categories"
      @success="onModalSuccess"
    />

    <ez-csv-upload-modal
      ref="csvUploadModal"
      :for-venue="true"
      :uploadCSV="uploadCSVAction"
      :onUploadFinished="onUploadFinished"/>

    <propose-price-modal
      ref="modal"
      :venueId="venueId"
      :distributorId="distributorId"
      :product="selectedProduct"
      :diff-action="setPriceAction"
      @success="onPriceChange"
      can-set-zero-price
    >
      <template #title>
        Set New Pricing
      </template>
      <template #text>
        <p>
          Please type in the price you would like to set
          for {{ selectedProduct.name }}.
        </p>
      </template>
      <template #confirm>
        Set New Price
      </template>
    </propose-price-modal>

    <ez-filter-list
      :filters="filters"
      @resetFilter="resetFilters"
      @filterUpdated="updateFilters"
      class="venue-products__filters mb-24">
      <ez-input
        formKey="filters"
        label="search"
        name="search"
        class="search"
        placeholder="Search for a Product">
        <template #prefix>
          <font-awesome-icon icon="search"/>
        </template>
      </ez-input>
      <ez-category-filter :categories="categories" name="categoryId"/>
      <template #actions>
        <ez-button-group>
          <template v-if="canHideProducts">
            <ez-simple-dropdown placeholder="Set Visibility">
              <template #dropdown>
                <div class="dropdown-actions bulk-actions">
                  <ez-button :disabled="!products.length" type="link" @click="selectAll">
                    <font-awesome-icon icon="eye" class="mr-8"/><span>Show All</span>
                  </ez-button>
                  <ez-button :disabled="!products.length" type="link" @click="deselectAll">
                    <font-awesome-icon icon="eye-slash" class="mr-8"/><span>Hide All</span>
                  </ez-button>
                </div>
              </template>
            </ez-simple-dropdown>
            <span class="ez-button-group__line"></span>
          </template>
          <ez-button-dropdown
            v-if="$permission.has('exportVenueProducts')"
            :showToggleIcon="false"
            :expandOnClick="true"
            buttonType="secondary"
          >
            <template>
              <span>Import/Export CSV</span>
            </template>
            <template #dropdown>
              <ez-button
                type="link"
                formType="button"
                customClass="upload-csv"
                @click="uploadCSVModal">
                Upload CSV
              </ez-button>
              <ez-button
                type="link"
                formType="button"
                customClass="upload-csv"
                @click="exportCSV">
                Export CSV
              </ez-button>
            </template>
          </ez-button-dropdown>
          <ez-button @click="newProduct">
            <span>Add New Product</span>
          </ez-button>
        </ez-button-group>
      </template>
    </ez-filter-list>

    <ez-table
      class="products-table"
      v-if="products.length"
      :data="products"
      :headers="headers"
      :columns="columns"
      :columnProps="{
        priceTier: { class: 'large-cell' },
        visibility: { class: 'medium-cell visibility-cell' },
        unit: { class: 'medium-cell' },
        pricePerUnit: { class: 'price-cell' },
        defaultPrice: { class: 'list-cell' },
        discount: { class: 'price-cell discount-cell' },
        actions: { class: 'actions-cell' },
      }"
      :loading="singleHideUnhideLoading"
    >
      <template #cell-name="{ row }">
        <ez-entity-info
          img-width="2rem"
          img-height="2rem"
          :img-url="row.image"
        >
          <div class="product-info" :title="row.name">
            <span>{{ row.name }}</span>
            <span class="product-info-secondary">
              {{row.sku}} &#8226; {{ row.category | categoryWithParent}}
            </span>
          </div>
        </ez-entity-info>
      </template>
      <template #cell-priceTier="{ row }">{{ row.priceTier }}</template>
      <template #cell-visibility="{ row }">
        <ez-button type="link" @click="updateVisibility(row)">
          <status-badge :status="status(row.isHidden)" />
        </ez-button>
      </template>
      <template #cell-defaultPrice="{ row }">
        <v-price
          :price="row.defaultPrice || 0"
          :is-market-price="row.defaultMarketPrice"
          :show-market-price-info="false"
        />
      </template>
      <template #cell-discount="{ row }">
        {{ (row.discount >= 0 && row.discount !== null) ? row.discount : '-' }}
      </template>
      <template #cell-unit="{ row: { orderingUnit } }">
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="orderingUnit.abbreviation ? `${orderingUnit.name}` : ''"
            :show-underline="!!orderingUnit.abbreviation"
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>
      <template #cell-pricePerUnit="{ row: {price, priceUnit, marketPrice} }">
        <v-price
          :price="price || 0"
          :is-market-price="marketPrice"
          :unit="priceUnit && priceUnit.label"
        />
      </template>
      <template #cell-actions="{ row }">
        <ez-button type="secondary" @click.stop="proposePrice(row)" :stop-propagation="true">
          <template>Set Price</template>
        </ez-button>
      </template>
    </ez-table>
    <template v-else-if="!isLoadingMore">
      <empty-state class="empty-state-center" v-if="hasFilters">
        <template #badge>
          <img src="@/assets/no-products-search-state.svg" alt=""></template>
        <template #title>No products match this search</template>
        <template #info>Try with a different search.</template>
      </empty-state>
      <empty-state class="empty-state-center" v-else>
        <template #badge>
          <img src="@/assets/no-product-empty-state.svg" alt=""></template>
        <template #title>No products listed</template>
      </empty-state>
    </template>
    <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="onLoadMore"/>
    <ez-loader :show="loading || exportLoading">Loading...</ez-loader>
    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep() .actions-cell {
  width: 110px;
}

.bulk-actions {
  .button {
    width: 100%;
  }
}

.list-cell {
  width: 120px;
  text-align: right;
}

:deep() .products-table {

  thead tr th {
    &.list-cell {
      width: 120px;
      text-align: right;
    }
    &.discount-cell {
      width: 100px;
    }
  }

  tbody tr td {
    overflow: visible;

    &.list-cell {
      padding-left: 0;
      color: $color-gray-6C;
      @include font-size(16px);
      font-weight: 700;
    }
    &.discount-cell {
      width: 100px;
      color: $color-gray-6C;
      padding-left: 0;
      @include font-size(16px);
      font-weight: 700;
    }
  }
}

.product-info {
  display: flex;
  flex-direction: column;
}

:deep() .visibility-cell .button {
  padding: 0;
}
</style>
