<script>
import { mapActions, mapGetters } from 'vuex';
import EzInput from '@/components/ui/Input/EzInput';
import EzButton from '@/components/ui/Button/EzButton';
import { EzFormModal } from '@/components/ui/Modal';
import { TAX_CALCULATION_OPTIONS } from '@/util/constants';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown.vue';

/**
 * CreateCustomerCategoryModal
 * @version 1.0.0
 * @since 3.26.0
 */

export default {
  name: 'CreateCustomerCategoryModal',
  components: {
    EzFormModal,
    EzInput,
    EzButton,
    EzDropdown,
  },
  props: {
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      name: '',
      taxCalculation: TAX_CALCULATION_OPTIONS[0].value,
      tax: null,
      category: null,
      formKey: 'create-customer-category-modal',
      taxOptions: TAX_CALCULATION_OPTIONS,
      defaultTaxCalculation: TAX_CALCULATION_OPTIONS[0].value,
      taxRateDisabled: false,
    };
  },
  computed: {
    ...mapGetters('entities/users', ['isDistributorXeroConnected']),
    isEdit() {
      return this.category?.id;
    },
    disabled() {
      return !this.name;
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'distributorCreateCustomerCategory',
      'distributorUpdateCustomerCategory',
    ]),
    open(category) {
      this.category = category;
      this.name = this.category?.name;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.$emit('onClose');
      this.$refs.customerCategoryNameInput.clearErrors();
      this.name = '';
    },
    onNameChange(val) {
      this.name = val;
    },
    async addNewCategory() {
      const body = {
        name: this.name,
        taxCalculation: this.taxCalculation,
        tax: this.tax,
      };
      let newCategoryId = null;

      if (!this.isEdit) {
        const { data: category } = await this.distributorCreateCustomerCategory({ body, formKey: this.formKey });
        newCategoryId = category.data.id;
      } else await this.distributorUpdateCustomerCategory({ categoryId: this.category.id, body, formKey: this.formKey });

      this.$emit('success', { isEdit: this.isEdit, id: newCategoryId });
      this.close();
    },
    onTaxCalculationChange(ev) {
      this.taxCalculation = ev.value;
      if (ev.value === 'exempt') this.taxRateDisabled = true;
      else this.taxRateDisabled = false;
    },
    onTaxChange(ev) {
      this.tax = ev;
    },
    goToAccountingIntegration() {
      const routerData = this.$router.resolve({
        name: 'distributor-xero-customer-categories',
      });
      window.open(routerData.href, '_blank');
    },
  },
};
</script>

<template>
  <ez-form-modal class="create-category-modal" ref="modal" @close="onClose">
    <template #title>{{ isEdit ? 'Edit' : 'Add New' }} Category</template>
    <template #content>
      <ez-input
        :value="name"
        :form-key="formKey"
        ref="customerCategoryNameInput"
        name="name"
        label="Name"
        placeholder="Enter Category Name"
        class="mb-16"
        @onChange="onNameChange"
        :data-cy="dataCy.INPUT__NAME"
      />
      <ez-dropdown
        :data="taxOptions"
        :form-key="formKey"
        is-full-width
        idKey="value"
        name="categoryTaxCal"
        label="Tax Exclusive / Inclusive / Exempt"
        :selected="category?.taxCalculation || defaultTaxCalculation"
        @change="onTaxCalculationChange"
        class="mb-16"
      />
      <div class="tax-wrapper">
        <ez-input
          placeholder="Tax Rate"
          :form-key="formKey"
          type="number"
          name="categoryTax"
          step="any"
          label="Tax Rate"
          :value="taxRateDisabled ? 0 : category?.tax"
          :disabled="isDistributorXeroConnected || taxRateDisabled"
          :disabledTooltip="taxRateDisabled ? 'Tax Exempt set' : 'Update in accounting integration'"
          @onChange="onTaxChange"
          min="0"
        >
        <template #suffix>%</template>
      </ez-input>
      <ez-button
        class="ml-12"
        type="secondary"
        v-if="isDistributorXeroConnected"
        @click="goToAccountingIntegration"
      >
        Settings
      </ez-button>
    </div>
    </template>
    <template #footer>
      <ez-button type="link" form-type="button" @click="close" :data-cy="dataCy.BUTTON__CANCEL">
        Cancel
      </ez-button>
      <ez-button
        :disabled="disabled"
        type="primary"
        @click="addNewCategory"
        :data-cy="dataCy.BUTTON__SAVE"
      >
        {{ isEdit ? 'Save Changes' : 'Add New Category' }}
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.help-block {
  @include font-size(14px);

  &__title {
    margin-bottom: 8px;
  }

  &__text {
    margin-top: 8px;
    color: $color-gray-6C;
  }
}
.create-category-modal {
  @include z-index('modal', 10);
  :deep() {
    .modal {
      @include z-index('modal', 20);
    }
  }
}

.tax-wrapper {
  :deep() .input-group {
    flex: 1;
  }
  display: flex;
  align-items: flex-end;
  width: 100%;
}
</style>
