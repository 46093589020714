<script>
/**
 * Xero Settings Page
 * @version 1.0.0
 * @since
 */
import Page from '@/views/admin/SinglePage.vue';
import VStatusBadge from '@/components/v3/elements/VStatusBadge';
import { mapActions, mapGetters } from 'vuex';
import EzButton from '@/components/ui/Button/EzButton';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import { distributorDisconnectXero } from '@/api/endpoints/xero';

export default {
  components: {
    EzButton,
    Page,
    VStatusBadge,
    EzConfirmationModal,
  },
  computed: {
    ...mapGetters('entities/users', [
      'getDistributor',
      'isDistributorXeroConnected',
      'isDistributorXeroTokenValid',
    ]),
    canReauthorize() {
      return this.isDistributorXeroConnected && !this.isDistributorXeroTokenValid;
    },
    organizationName() {
      return this.getDistributor.xero?.tenantName || 'Xero';
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributorLoggedUser']),
    ...mapActions('xero', ['xeroAuthorize']),
    reauthorizeXero() {
      this.xeroAuthorize({ route: 'distributor-xero-setup' });
    },
    async confirmXeroDisconnect() {
      try {
        await distributorDisconnectXero();
        this.$router.push({
          name: 'distributor-integrations',
          params: {
            flash: {
              title: 'You have successfully disconnected from Xero.',
            },
          },
        });
      } finally {
        this.$refs.xeroDisconnectModal.close();
      }
    },
  },
};
</script>
<template>
  <page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'distributor-integrations' }">
        <font-awesome-icon icon="arrow-left"/>
        Back to Supplier Settings
      </router-link>
    </template>
    <template #title>
      <div class="u-flex-center title">
        <img src="@/assets/logo-xero.png" width="32" height="32" alt="Xero Logo"/>
        <h2 class="m-0 ml-8 xero-title">{{ organizationName }}</h2>
        <v-status-badge type="green" v-if="isDistributorXeroConnected">Connected</v-status-badge>
        <v-status-badge type="gray" v-else>Not Connected</v-status-badge>
      </div>
    </template>
    <template #actions>
      <div class="u-flex-center header-actions">
        <ez-button
          v-if="canReauthorize"
          class="mr-12 header-actions__action"
          @click="reauthorizeXero"
        >
          <font-awesome-icon icon="sync-alt" />
          <span>Reauthorize</span>
        </ez-button>
        <ez-button
          v-if="isDistributorXeroConnected"
          type="link"
          v-open="'xeroDisconnectModal'"
          class="p-0 header-actions__action"
          aria-label="Disconnect from Xero"
        >
          <img
            src="@/assets/xero-disconnect-btn.svg"
            alt="Disconnect from Xero"
            role="presentation"
          />
        </ez-button>
      </div>
    </template>
    <template #navigation>
      <ul>
        <li>
          <component
            class="navigation-link"
            :is="isDistributorXeroConnected ? 'router-link' : 'span'"
            :to="isDistributorXeroConnected ? { name: 'distributor-xero-account' } : null"
          >Account Info</component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isDistributorXeroConnected ? 'router-link' : 'span'"
            :to="isDistributorXeroConnected ? { name: 'distributor-xero-products' } : null"
          >{{ $t('global.products') }}</component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isDistributorXeroConnected ? 'router-link' : 'span'"
            :to="isDistributorXeroConnected ? { name: 'distributor-xero-venues'} : null"
          >{{ $t('global.venues') }}</component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isDistributorXeroConnected ? 'router-link' : 'span'"
            :to="isDistributorXeroConnected ? { name: 'distributor-xero-product-groups'} : null"
          >{{ $t('global.productGroups') }}</component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isDistributorXeroConnected ? 'router-link' : 'span'"
            :to="isDistributorXeroConnected ? { name: 'distributor-xero-customer-categories'} : null"
          >{{ $t('global.customerCategories') }}</component>
        </li>
      </ul>
    </template>


    <ez-confirmation-modal ref="xeroDisconnectModal" type="red">
      <template #title>{{ $t('xero.integrations.disconnectModalTitle') }}</template>
      <template #content>
        <div class="xero-description" v-html="$t('xero.integrations.disconnectModalContent', { platform: platformName })"/>
      </template>
      <template #footer>
        <ez-button v-close="'xeroDisconnectModal'" type="link">
          {{ $t('global.actions.cancel') }}
        </ez-button>
        <ez-button @click="confirmXeroDisconnect" type="red">
          {{ $t('global.actions.disconnect') }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </page>
</template>
<style lang="scss" scoped>
  .xero-title {
    @include font-size(24px);
    font-weight: 500;
  }
  .title {
    img {
      border: 1px solid #E1E5ED;
      border-radius: 50%;
    }
  }
  .header-actions__action {
    height: 43px;
  }
</style>
