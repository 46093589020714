import i18n from '@/i18n';

export const VENUE_STEP_1 = 0;
export const VENUE_STEP_2 = 1;
export const VENUE_STEP_3 = 2;
export const VENUE_STEP_4 = 3;
export const VENUE_STEP_5 = 4;

const steps = {
  [VENUE_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/NewVenueInfo.vue'),
    title: `Create ${i18n.t('global.venue')} - ${i18n.t('global.venue')}  Info`,
    pageTitle: `Please add ${i18n.t('global.venue')} info`,
    pageInfo: `Add ${i18n.t('global.venue')}’s avatar, it’s name, contact info and addresses of the bussiness that will be used throughout the platform.`,
    nextCta: 'Next Step',
  },
  [VENUE_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/NewVenueSettings.vue'),
    title: `Create ${i18n.t('global.venue')} - ${i18n.t('global.venues')}`,
    pageTitle: `Please add ${i18n.t('global.venue')} Settings`,
    pageInfo: `Add ${i18n.t('global.venue')} specific settings related to product pricing and order management.`,
    nextCta: 'Next Step',
  },
  [VENUE_STEP_3]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/NewVenueUsers.vue'),
    title: `Create ${i18n.t('global.venue')} - ${i18n.t('global.venues')}`,
    pageTitle: 'Now add contacts',
    pageInfo: 'Add a user you wish to invite to manage this account first. You can also add additional contacts that will receive notifications about your orders.',
    nextCta: 'Next Step',
  },
  [VENUE_STEP_4]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/NewVenueProducts.vue'),
    title: `Create ${i18n.t('global.venue')} - ${i18n.t('global.venues')}`,
    pageTitle: 'Now add products',
    pageInfo: `Add products that will be in this ${i18n.t('global.venue')}’s inventory`,
    nextCta: 'Go to Overview',
  },
  [VENUE_STEP_5]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/NewVenueOverview.vue'),
    title: `Create ${i18n.t('global.venue')} - Overview`,
    pageTitle: 'Overview',
    pageInfo: `Before you complete adding a new ${i18n.t('global.venue')}, check the information below to make sure you’ve entered everything correctly.`,
    nextCta: 'Finish',
  },
};

export default steps;
