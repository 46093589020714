<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { clone, debounce } from '@/util/utils';
import { LOADING_KEY, UNIT_TYPE_FRACTIONAL, UNIT_TYPE_PACK } from '@/util/constants';

import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzFilterList from '@/components/ui/FilterList';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzButton from '@/components/ui/Button';
import EzBadge from '@/components/ui/Badge';
import EzTable from '@/components/ui/Table';
import EzInput from '@/components/ui/Input';
import EmptyState from '@/views/common/empty-state';
import VWarehouseLocation from '@/components/v3/elements/VWarehouseLocation';
import VTooltipComponent from '@/components/v3/elements/VTooltipComponent';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';

/**
 * Products
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Products',
  components: {
    VDataWithInfo,
    EzMaskInputSimple,
    EzEntityInfo,
    EzFilterList,
    EzLoadMore,
    EzSpinner,
    EzButton,
    EzBadge,
    EzTable,
    EzInput,
    EmptyState,
    VWarehouseLocation,
    // eslint-disable-next-line vue/no-unused-components
    VTooltipComponent,
  },
  props: {
    distributorId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      supplierCy,
      products: [],
      meta: {},
      isInvalidQuantity: {},
      items: [],
      filters: {
        term: null,
      },
      packUnitType: UNIT_TYPE_PACK,
    };
  },
  computed: {
    ...mapState('entities/products', ['inventoryManagement']),
    ...mapGetters('loading', ['getLoading']),
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_OVERVIEW);
    },
    loadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_PRODUCT_INVENTORY_OVERVIEW);
    },
    warehouse() {
      return this.inventoryManagement.warehouse;
    },
    hasFilters() {
      return Object.values(this.filters).some(v => !!v);
    },
    noProductsFound() {
      return !this.loading && !this.loadingMore && !this.products.length;
    },
    nextStepDisabled() {
      return Object.values(this.isInvalidQuantity).some(e => e) || !this.items.length;
    },
  },
  methods: {
    ...mapActions('entities/products', ['distributorFetchProductInventoryOverview']),
    ...mapMutations('entities/products', ['UPDATE_INVENTORY_MANAGEMENT_DRAFT']),
    isFractional(row) {
      return row.product.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    onInputChange(key, row, val) {
      if (key === 'quantity') this.updateSelectedProductQuantity(row, val);
      else this.updateSelectedProduct(row.id, { [key]: val });
    },
    onInvalidQuantity(row, val) {
      this.isInvalidQuantity = {
        ...this.isInvalidQuantity,
        [row.id]: val,
      };

      if (!this.isSelected(row.id)) {
        this.selectProduct(row);
      }
    },
    updateSelectedProductQuantity(row, val) {
      if (!this.isSelected(row.id)) {
        if (val) this.selectProduct(row, val);
        return;
      }

      if (!val) {
        this.unselectProduct(row.id);
        return;
      }

      this.updateSelectedProduct(row.id, { quantity: val });
    },
    isSelected(id) {
      return !!this.findSelected(id);
    },
    findSelected(id, key = '') {
      const item = this.items.find(p => p.id === id);
      if (!key) return item;

      return item?.[key];
    },
    selectProduct(row, quantity = 0) {
      if (this.isSelected(row.id)) return;

      this.items.push({ ...row, quantity });
    },
    unselectProduct(id) {
      const { [id]: removed, ...rest } = this.isInvalidQuantity;
      this.isInvalidQuantity = rest;

      const idx = this.items.findIndex(p => p.id === id);
      this.items.splice(idx, 1);
    },
    updateSelectedProduct(id, payload) {
      const idx = this.items.findIndex(p => p.id === id);
      this.items[idx] = { ...this.items[idx], ...payload };
    },
    async resetFilters() {
      this.filters.term = null;
      this.products = [];
      this.meta = {};
      await this.fetchProducts();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') this.filters.term = event || null;
      this.products = [];
      this.meta = {};
      await this.fetchProducts();
    }, 300),
    onNextStep() {
      this.UPDATE_INVENTORY_MANAGEMENT_DRAFT({ items: clone(this.items) });
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    async fetchProducts(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_OVERVIEW) {
      const query = {
        limit: 10,
        sortBy: 'name',
        warehouseId: this.warehouse.id,
        ...this.filters,
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
      };

      const {
        data: { data, meta },
      } = await this.distributorFetchProductInventoryOverview({ query, loadingKey });

      if (loadingKey === LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_OVERVIEW) {
        this.products = data;
      } else {
        this.products = clone([...this.products, ...data]);
      }
      this.meta = clone(meta);
    },
    async loadMoreProducts() {
      await this.fetchProducts(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_PRODUCT_INVENTORY_OVERVIEW);
    },
    startProductsAdditionFlow() {
      this.$router.push({
        name: 'distributor-add-inventory',
        params: { id: this.distributorId },
      });
    },
    getLocation(location) {
      if (!location) return [];
      return [location.code];
    },
    quantityHeader(h) {
      return h('span', {}, [
        'Removal Quantity',
        h(
          VTooltipComponent,
          {
            props: {
              content:
                'The quantity is being calculated per<br />single unit. Example: Bottle, can, piece...',
              placement: 'top',
              classes: ['tooltip--reset-margin u-text-center'],
            },
          },
          [
            h('font-awesome-icon', {
              class: 'ml-4',
              props: {
                icon: 'info-circle',
              },
            }),
          ],
        ),
      ]);
    },
  },
  watch: {
    nextStepDisabled: {
      immediate: true,
      handler(val) {
        if (val) this.disableNextStep();
        else this.enableNextStep();
      },
    },
  },
  async created() {
    await this.fetchProducts();
    if (this.inventoryManagement.items) {
      this.items = clone(this.inventoryManagement.items);
    }
  },
};
</script>

<template>
  <div class="products-step">
    <div class="subheader mb-24 u-flex-space">
      <span></span>

      <p class="subheader__selected-count">
        Products selected:
        <ez-badge type="blue" :count="items.length || 0" showEveryNumber />
      </p>

      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <ez-input
          formKey="filters"
          label="search"
          name="search"
          class="search"
          placeholder="Search for a Product"
          :data-cy="supplierCy.INVENTORY_FLOWS.REMOVAL.PRODUCTS.INPUT__SEARCH"
        >
          <template #suffix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>
    </div>

    <ez-table
      class="products-table"
      disableHover
      :loading="loading"
      :data="products"
      :columns="[
        'name',
        'location',
        'batchCode',
        'expiryDate',
        'currentInventory',
        'quantity',
        'unit',
        'cogs',
        'action',
      ]"
      :headers="{
        name: () => 'Product',
        batchCode: () => 'Batch ID',
        currentInventory: () => 'Current Stock',
        quantity: quantityHeader,
        cogs: () => 'COGS',
        action: () => '',
      }"
      :columnProps="{
        name: { class: 'name-cell' },
        location: { class: 'medium-cell location-cell' },
        batchCode: { class: 'batchCode-cell' },
        expiryDate: { class: 'medium-cell' },
        currentInventory: { class: 'medium-cell' },
        quantity: { class: 'quantity-cell medium-cell' },
        unit: { class: 'medium-cell unit-cell' },
        cogs: { class: 'small-cell u-text-right' },
        action: { class: 'action-cell' },
      }"
    >
      <template
        #cell-name="{
          row: {
            product: { image, name, sku },
          },
        }"
      >
        <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="image">
          <div class="product-info" :title="name">
            <span>{{ name }}</span>
            <span class="product-info-secondary">{{ sku }}</span>
          </div>
        </ez-entity-info>
      </template>

      <template #cell-location="{ row: { location } }">
        <v-warehouse-location :data="getLocation(location)" />
      </template>

      <template #cell-batchCode="{ row: { batchCode } }">
        <span v-if="batchCode" :title="batchCode">{{ batchCode }}</span>
        <span v-else>-</span>
      </template>

      <template #cell-expiryDate="{ row: { expiryDate } }">
        <span v-if="expiryDate">{{ expiryDate | date }}</span>
        <span v-else>-</span>
      </template>

      <template #cell-currentInventory="{ row: { currentInventory } }">
        <span v-if="currentInventory">{{ currentInventory }}</span>
        <span v-else>-</span>
      </template>

      <template #cell-quantity="{ row }">
        <ez-mask-input-simple
          formKey=""
          name="quantity"
          :precision="isFractional(row) ? 2 : 0"
          :value="findSelected(row.id, 'quantity')"
          :minValue="0"
          :maxValue="row.currentInventory"
          :isInvalid="isInvalidQuantity[row.id]"
          :has-currency="false"
          @input="onInputChange('quantity', row, $event)"
          @invalid="onInvalidQuantity(row, $event)"
          @click.stop
          :data-cy="`${supplierCy.INVENTORY_FLOWS.REMOVAL.PRODUCTS.INPUT__QUANTITY}-${row.id}`"
        />
      </template>

      <template
        #cell-unit="{
          row: {
            product: { orderingUnit },
          },
        }"
      >
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="
              orderingUnit.type === packUnitType
                ? 'The quantity of pack unit products ' + 'is being managed per single unit.'
                : ''
            "
            :show-underline="orderingUnit.type === packUnitType"
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>

      <template #cell-cogs="{ row: { cogs } }">
        {{ (cogs || 0) | price }}
      </template>

      <template #cell-action="{ row }">
        <ez-button
          v-if="isSelected(row.id)"
          type="green"
          @click="unselectProduct(row.id)"
          :data-cy="supplierCy.INVENTORY_FLOWS.REMOVAL.PRODUCTS.BUTTON__SELECTED"
        >
          Selected
        </ez-button>
        <ez-button
          v-else
          type="secondary"
          @click="selectProduct(row, 1)"
          :data-cy="supplierCy.INVENTORY_FLOWS.REMOVAL.PRODUCTS.BUTTON__SELECT"
        >
          Select
        </ez-button>
      </template>
    </ez-table>

    <empty-state v-if="noProductsFound && hasFilters">
      <template #badge><img src="@/assets/no-products-search-state.svg" alt="" /></template>
      <template #title>No products match this search</template>
      <template #info>Try with a different search.</template>
    </empty-state>

    <div v-if="loadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="!loadingMore && meta.nextId" @loadMore="loadMoreProducts" />
  </div>
</template>

<style lang="scss" scoped>
.products-step {
  margin-top: 64px;
}

.subheader {
  border-bottom: 1px solid #eceef5;
  padding: 14px 0;

  & > * {
    flex: 1 0 33.33333%;
  }

  &__selected-count {
    @include font-size(14px, 20px);
    color: $color-gray-6C;
    text-align: center;
    margin: 0;
  }

  :deep() .ez-filter-list__items {
    justify-content: flex-end;
  }
}

.products-table {
  .unit-cell {
    overflow: hidden;
  }
  .name-cell {
    .product-info {
      display: flex;
      flex-direction: column;

      .product-info-secondary {
        display: block;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .location-cell {
    :deep() .dashed-underline {
      border-bottom: 2px dotted rgba($color-gray-6C, 0.64);
      @include font-size(14px);
      font-weight: 400;

      &--out_of_stock {
        border-color: rgba($color-primary-red, 0.64);
      }

      &--without {
        border: none;
      }
    }
  }

  .location-cell,
  .batchCode-cell {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .quantity-cell {
    overflow: visible;
  }

  .batchCode-cell {
    width: 96px;
  }

  .action-cell {
    width: 112px;
  }
}

:deep() .ez-empty-state__image {
  max-width: 256px;

  img {
    width: 256px;
    height: 118px;
  }
}

:deep() .mask-input.mark-input--inline {
  .mask-input__input {
    font-weight: normal;
    color: $color-gray-25;
    text-align: center;
  }
}
</style>
