<template>
  <order-list
    :columns="[
      'orderNumber',
      'statusIcon',
      'venue',
      'expectedAt',
      'orderedAt',
      'createdByUser',
      'status',
      'amount',
    ]"
    :headers="{
      orderNumber: () => (showInvoiceNumber ? 'Order & Invoice No.' : 'Order No.'),
      statusIcon: () => ``,
      venue: () => `${$t('global.venue').toLowerCase()}`,
      expectedAt: () => 'Delivery Date',
      orderedAt: () => 'Order Date',
      createdByUser: () => 'Created By',
    }"
    :filters="filters"
    :is-loading-more="isLoadingMore"
    :show-invoice-number="showInvoiceNumber"
    :default-date-filters="defaultDateFilters"
    :has-stats="false"
    :orders="orders"
    @loadMore="onLoadMoreClick"
    @rowClick="openDrawerAndLoadOrder"
  >
    <template #filtersGroup>
      <div class="filters-grp">
        <ez-filter-list
          :filters="termFilter"
          @filterUpdated="updateTermFilter"
          @resetFilter="resetTermFilter"
          class="mr-16"
        >
          <ez-input
            formKey="filters"
            :name="termFilterKey"
            class="search"
            :placeholder="showInvoiceNumber ? 'Enter Order or Invoice No.' : 'Enter Order Number'"
            ref="searchFilter"
            :value="termFilter[termFilterKey]"
            :data-cy="supplierCy.ORDERS.GENERAL.INPUT__FILTER_SEARCH"
          >
            <template #prefix>
              <font-awesome-icon icon="search" />
            </template>
          </ez-input>
        </ez-filter-list>
        <v-filter-dropdown
          :data-cy="supplierCy.ORDERS.GENERAL.BUTTON__FILTERS"
          :filters="initialStatusFilters"
          :default-date-filters="defaultDateFilters"
          @filterUpdated="updateFilters"
          @resetFilters="resetFilters"
          @filterClosed="filterClosed"
          ref="filtersGroup"
        >
          <template #firstRow>
            <v-select-search
              v-if="venues"
              class="select-outlet"
              formKey="filters"
              name="venueId"
              :label="`${$t('global.venue')}`"
              :data="venues"
              :placeholder="`All ${$t('global.venues')}`"
              :searchPlaceholder="`Select ${$t('global.venue')}`"
              align-left
              :selected="filters.venueId ? filters.venueId : ''"
            >
              <template #result="{ result }">
                <v-venue-entity-info :venue="result" />
              </template>
            </v-select-search>

            <v-date-picker
              ref="calendar"
              class="calendar"
              name="scheduled"
              label="Delivery Date"
              v-model="scheduledRange"
              formKey="filters"
              is-all-time-in-future
              isFullWidth
              range-mode
              with-predefined-ranges
              :number-of-calendars="2"
              :select-predefined-range="defaultRangeNameScheduled"
              :what-date-to-display="today"
              @rangeNameChange="changeScheduledRangeName"
              :data-cy="supplierCy.ORDERS.GENERAL.BUTTON__FILTER_DELIVERY_DATE"
            />

            <v-date-picker
              ref="calendar"
              class="calendar"
              name="month"
              label="Order Date"
              v-model="range"
              formKey="filters"
              range-mode
              with-predefined-ranges
              :number-of-calendars="2"
              :select-predefined-range="defaultRangeName"
              @rangeNameChange="changeRangeName"
              isFullWidth
            />

            <ez-warehouse-filter
              name="fulfillmentWarehouseId"
              label="Warehouse"
              :warehouses="warehousesFilters"
              :selected="filters.fulfillmentWarehouseId"
              :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__WAREHOUSE_FILTER"
              isFullWidth
            />

            <ez-select
              v-if="driversFilters.length"
              ref="select"
              class="input-group filters-dropdown"
              name="driverId"
              isFullWidth
              :value="filters.driverId"
              :selected="filters.driverId"
              label="Driver"
              :options="driversFilters"
            />
          </template>
          <template #secondRowLabel>Status</template>
          <template #secondRow>
            <ez-checkbox
              v-for="status in statusFilter"
              class="status-checkbox"
              :checked="initialStatusFilters[status.id]"
              :key="status.id"
              formKey="filters"
              :name="status.id"
              :label="status.name"
            />
          </template>
        </v-filter-dropdown>
      </div>
    </template>

    <template #actions v-if="$permission.isPremium && $permission.has('manageVenue')">
      <div class="filter-cta">
        <export-orders
          v-if="filters"
          class="mr-16"
          action="distributor/orders"
          :orders="orders"
          :filters="filters"
          :number-of-applied-filters="numberOfAppliedFilters"
          :data-cy="supplierCy.ORDERS.GENERAL.BUTTON__EXPORT_ORDERS"
        />
        <router-link
          :data-cy="supplierCy.ORDERS.GENERAL.BUTTON__ADD_NEW_ORDER"
          :to="{ name: 'distributor-orders-new' }"
        >
          <ez-button>Add New Order</ez-button>
        </router-link>
      </div>
    </template>

    <template #banner>
      <pending-banner
        v-if="
          filters.status !== ORDER_STATUS_PENDING && canSeePendingOrders && !pendingFilterActive
        "
        @viewPending="filterPending"
      />
    </template>

    <ez-drawer
      ref="orderDrawer"
      :class="['order-drawer', { 'order-resolved': selectedOrder.status !== ORDER_STATUS_PENDING }]"
      @onClose="resetDrawerRoute"
    >
      <template #title>
        <h2>{{ isQuoteStatus ? 'Quote' : 'Order' }} #{{ selectedOrder.orderNumber }}</h2>
        <status-badge :status="selectedOrder.status || ORDER_STATUS_PENDING" />
      </template>

      <!--   Info messages // Start   -->

      <template #info-message>
        <template v-if="editingMode">
          <div class="mx-24 mb-16">
            <ez-alert variant="disclaimer" class="m-0" size="big">
              <template #icon>
                <font-awesome-icon icon="exclamation-circle" />
              </template>
              <template #title>
                <span>Edit mode active</span>
              </template>
              <p>
                Edit information or products. Once you are finished, do not forget to save your
                changes.
              </p>
            </ez-alert>
          </div>
        </template>
        <template v-if="checkIsMpOrToBeDetermined && hasMpInOrder && !editingMode">
          <div class="mx-24 mb-16">
            <ez-alert variant="disclaimer" class="m-0" size="big">
              <template #icon>
                <font-awesome-icon icon="exclamation-circle" />
              </template>
              <p v-if="hasMpInOrder && hasTbdInOrder">
                This order contains products with market prices and fractional units which may
                affect the total amount (incl. subtotal and taxes) based on the actual delivered
                quantity.
              </p>
              <p v-if="hasMpInOrder && !hasTbdInOrder">
                Product with market price is included in this order. Market prices affect the total
                amount, subtotal and taxes.
              </p>
            </ez-alert>
          </div>
        </template>
        <template v-if="editingMode && selectedOrder.provinoReference">
          <div class="mx-24 mb-16">
            <ez-alert variant="disclaimer" size="big">
              <template #icon>
                <font-awesome-icon icon="info-circle" />
              </template>
              <template #title>Edits to be applied in {{ oezPlatformName }} only</template>
              <p>
                This order has already been scheduled with {{ $t('provino.name') }}. In case of any
                additional amendments please contact {{ $t('provino.name') }} directly.
              </p>
            </ez-alert>
          </div>
        </template>
        <template
          v-if="
            selectedOrder.status === ORDER_STATUS_PENDING &&
            selectedOrder.afterWorkingHours &&
            !editingMode
          "
        >
          <div class="mx-24 mb-16">
            <ez-alert variant="disclaimer" size="big">
              <template #icon>
                <font-awesome-icon icon="info-circle" />
              </template>
              <p>
                Orders placed after work hours are not expected to be processed on the <br />
                same day. You can process them, but you are not obliged to do it.
              </p>
            </ez-alert>
          </div>
        </template>
        <template
          v-if="
            selectedOrder.canCreateXeroInvoice &&
            selectedOrder.status !== ORDER_STATUS_PENDING &&
            !editingMode &&
            !isQuoteStatus
          "
        >
          <div class="mx-24 mb-16">
            <ez-alert variant="disclaimer" size="big">
              <template #icon>
                <font-awesome-icon icon="info-circle" />
              </template>
              <p>
                The order was not pushed to Xero. You can try creating it now.
                <ez-button
                  :disabled="loading"
                  @click="createInvoice"
                  :class="[
                    'orders__alert-create-invoice',
                    { 'orders__alert-create-invoice--disabled': loading },
                  ]"
                  type="blue-link"
                >
                  Create an Invoice
                </ez-button>
              </p>
            </ez-alert>
          </div>
        </template>

        <template v-if="selectedOrder.canCreateXeroInventoryBill">
          <div class="mx-24 mb-16">
            <ez-alert variant="disclaimer" size="big">
              <template #icon>
                <font-awesome-icon icon="info-circle" />
              </template>
              <p>
                There was a problem creating your inventory accounting bill on Xero. You can try
                creating it again.
                <ez-button
                  :class="[
                    'orders__alert-create-bill',
                    { 'orders__alert-create-bill--disabled': createBillLoading },
                  ]"
                  :disabled="createBillLoading"
                  @click="createBill"
                  type="blue-link"
                >
                  Create Bill
                </ez-button>
              </p>
            </ez-alert>
          </div>
        </template>

        <template v-if="hasIntegrationWarnings">
          <div
            v-for="(warning, idx) in selectedOrder.integrationWarnings"
            :key="idx"
            class="mx-24 mb-16"
          >
            <ez-alert variant="disclaimer" size="big">
              <template #icon>
                <font-awesome-icon icon="info-circle" />
              </template>
              <p>{{ warning.text }}</p>
              <ez-button
                v-if="warning.url"
                type="blue-link"
                class="orders__alert-retry"
                @click="onIntegrationWarningCTA(warning.url)"
              >
                {{ warning.cta }}
              </ez-button>
              <template v-if="warning.ctas">
                <ez-button
                  v-for="cta in warning.ctas"
                  :key="cta.key"
                  type="blue-link"
                  class="orders__alert-retry orders__alert-retry-group"
                  @click="onIntegrationWarningCTAGroup(cta)"
                >
                  {{ cta.cta }}
                </ez-button>
              </template>
            </ez-alert>
          </div>
        </template>
      </template>

      <!--   Info messages // End   -->

      <template #content>
        <single-order
          :order="selectedOrder"
          :enable-edit="canEditProducts"
          :editing-mode="editingMode"
          :has-declined-icon="selectedOrder.status === ORDER_STATUS_DECLINED"
          :can-edit-delivery-on="canEditDeliveryOn"
          @editedProduct="editedProduct"
          @editDeliveryOn="onEditDeliveryOn"
          @editOrderDate="editOrderDate"
          @editDueDate="editDueDate"
          @editVenueInvoiceNumber="editVenueInvoiceNumber"
          @editDriver="editDriver"
          @productRemoved="editedProduct"
          @productsAdded="editedProduct"
          @productExcluded="editedProduct"
          @productDeleted="editedProduct"
          @productUpdated="editedProduct"
          @isSomeUnavailable="e => (someUnavailable = e)"
        >
          <template #venue>
            <div class="u-flex-space" v-if="selectedOrder.venue">
              <ez-entity-info
                imgWidth="2rem"
                imgHeight="2rem"
                imgBorderRadius="50%"
                :imgUrl="selectedOrder.venue.logo"
              >
                <div class="product-info">
                  <span class="product-info__name" :title="selectedOrder.venue.name">
                    <span>{{ selectedOrder.venue.name }}</span>
                    <ez-premium-badge v-if="venueHasPremiumBadge" />
                    <ez-lite-badge v-if="venueHasLiteBadge" />
                  </span>
                  <span v-if="venueHasAddress" class="product-info__address" :title="venueAddress">
                    {{ venueAddress }}
                  </span>
                </div>
              </ez-entity-info>
              <ez-button-group class="ml-12">
                <export-button
                  class="export-btn"
                  direction="down"
                  v-if="exportTypeLen"
                  :order="selectedOrder"
                  :data-cy="supplierCy.ORDERS.SINGLE_ORDER.VIEW.BUTTON__EXPORT"
                />
                <ez-button
                  type="secondary"
                  formType="button"
                  class="venue-info-btn"
                  @click="showVenueInfo"
                  :data-cy="supplierCy.ORDERS.SINGLE_ORDER.VIEW.BUTTON__OUTLET_INFO"
                >
                  <font-awesome-icon icon="info-circle" />
                  <span>Customer Info</span>
                </ez-button>
              </ez-button-group>
            </div>
          </template>
        </single-order>
      </template>

      <template #footer>
        <template v-if="shouldDisplayFooter">
          <button-switch
            :force-disable="forceDisable"
            :order="selectedOrder"
            :editing-mode="editingMode"
            @decline="onDeclineOrderClick"
            @accept="onAcceptOrderClick"
            @schedule="onScheduleOrderClick"
            @complete="confirmDelivery"
            @abandon="onAbandonOrder"
            @reorder="onReorderOrder"
            @resolve="onResolveOrder"
            @createFromDraft="onCreateFromDraft"
            @saveEdit="saveChanges"
            @discardEdit="discardEdit"
            @edit="edit"
            @addCreditNote="addCreditNote"
            @copyToClipboard="copyToClipboard"
            @convertToOrder="onConvertToOrderModal"
          />
          <div v-if="areTaxValuesNumbers" class="order-total-price">
            <v-subtotal-info-static
              v-if="hasProvider"
              :delivery-fee="deliveryFee || 0"
              :tax="tax"
              :tax-amount="selectedOrder.taxAmount || 0"
              :price-before-tax="selectedOrder.amount || 0"
              :price-after-tax="selectedOrder.amountWithTax || 0"
              :has-text="false"
              :is-tbd="selectedOrder.tbd"
              :data-cy="supplierCy.ORDERS.SINGLE_ORDER.VIEW.TEXT__TOTAL_AMOUNT"
            />
            <v-subtotal-info
              v-else
              :item-list="itemList"
              :delivery-fee="deliveryFee"
              :delivery-fee-taxable="deliveryFeeTaxable"
              :amount-adjustment="amountAdjustment"
              :tax-adjustment="taxAdjustment"
              :tax="tax"
              :taxCalculation="taxCalculation"
              :is-tbd="isSomeTbd"
              :discount="orderDiscountAmount"
              :total-discount-amount="selectedOrder?.totalDiscountAmount"
              :data-cy="supplierCy.ORDERS.SINGLE_ORDER.VIEW.TEXT__TOTAL_AMOUNT"
            />
          </div>
        </template>
      </template>
    </ez-drawer>
    <confirm-order
      ref="confirm"
      @success="confirmOrderSuccess"
      :action="`/distributor/orders/${selectedOrder.id}/complete`"
      :order="selectedOrder"
      :isPublicOrder="isPublicOrder"
    />
    <schedule-order
      ref="schedule"
      @success="confirmOrderSchedule"
      :order="selectedOrder"
      :hasInventoryError="someUnavailable"
    />
    <create-from-draft ref="createFromDraft" @success="onSuccessConvert" :order="selectedOrder" />
    <convert-to-order-modal
      ref="convertToOrder"
      @success="onSuccessConvert"
      :order="selectedOrder"
    />
    <ez-info-modal
      ref="infoModal"
      v-if="selectedOrder.customerInfo"
      :data="selectedOrder.customerInfo"
    >
      <template #title>Customer Info</template>
    </ez-info-modal>

    <ez-confirmation-modal ref="orderConfirmation" icon="question" @close="onCloseConfirmModal">
      <template #title>{{ acceptText }}?</template>
      <template #content>
        <p>
          Make sure you went through the whole order in detail and that you can deliver all products
          from the list that are included in the order.
        </p>

        <template v-if="shouldShowWarehouseDropdown">
          <hr />

          <ez-select
            ref="select"
            name="warehouseId"
            label="Fulfill Order From"
            :selected="warehouseId"
            :value="warehouseId"
            :options="warehouses"
            @change="onWarehouseSelectChange"
            isFullWidth
            :data-cy="supplierCy.ORDERS.SINGLE_ORDER.ACCEPT_ORDER_MODAL.BUTTON__WAREHOUSE"
          />
        </template>

        <div class="notes notes-wrapper">
          <span v-if="!!selectedOrder.venueMessage" class="note__label">
            Note from {{ $t('global.venue') | lowercase }}
          </span>
          <ez-notes :order="selectedOrder"></ez-notes>
          <ez-textarea
            name="confirm-message"
            label="Note"
            placeholder="Add a Note. (Optional)"
            @onChange="val => (confirmMessage = val)"
            form-key="orderConfirmation"
            :data-cy="supplierCy.ORDERS.SINGLE_ORDER.ACCEPT_ORDER_MODAL.TEXTAREA__NOTE"
          />
        </div>

        <advanced-inventory-management
          v-if="!(isDistributorProvinoConnected && isProvinoWarehouse) && orderConfirmationVisible"
          :show="advancedOptions"
          :order="selectedOrder"
          :warehouseId="warehouseId"
          @invalid="onInventoryManagementInvalid"
          @onChange="onInventoryItemsChange"
          :data-cy="
            supplierCy.ORDERS.SINGLE_ORDER.ACCEPT_ORDER_MODAL.CONTAINER__ADVANCED_OPTIONS_CONTAINER
          "
          :error-data-cy="
            supplierCy.ORDERS.SINGLE_ORDER.ACCEPT_ORDER_MODAL.TEXT__ADVANCED_OPTIONS_ERROR
          "
        />
      </template>
      <template #footer>
        <ez-button
          v-if="!(isDistributorProvinoConnected && isProvinoWarehouse) && orderConfirmationVisible"
          type="blue-link"
          class="advanced-options-btn"
          @click="showAdvancedOptions"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.ACCEPT_ORDER_MODAL.BUTTON__ADVANCED_OPTIONS"
        >
          Advanced Options
          <font-awesome-icon class="ml-8" v-if="advancedOptions" icon="angle-up" />
          <font-awesome-icon class="ml-8" v-else icon="angle-down" />
        </ez-button>
        <ez-button
          type="link"
          @click="closeConfirmModal"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.ACCEPT_ORDER_MODAL.BUTTON__CANCEL"
        >
          Cancel
        </ez-button>
        <ez-button
          @click="confirmOrderCreation"
          :is-loading="isLoadingAccept"
          :disabled="
            isProvinoWarehouse && isDistributorProvinoConnected
              ? someUnavailable
              : acceptOrderDisabled
          "
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.ACCEPT_ORDER_MODAL.BUTTON__ACCEPT"
        >
          {{ acceptText }}
        </ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-confirmation-modal ref="orderDecline" type="red" icon="question">
      <template #title>Decline Entire Order?</template>
      <template #content>
        <p>Order will be declined completely.</p>
        <p>
          If you wish, you can also provide a reason why it is being declined and
          {{ $t('global.venue') | lowercase }} will be notified.
        </p>
        <div class="notes notes-wrapper">
          <span v-if="!!selectedOrder.venueMessage" class="note__label">
            Note from {{ $t('global.venue') | lowercase }}
          </span>
          <ez-notes :order="selectedOrder"></ez-notes>
          <ez-textarea
            name="decline-note"
            label="Note"
            placeholder="Explain why you are declining this order. (Optional)"
            @onChange="val => (declineMessage = val)"
            form-key="orderDecline"
            :data-cy="supplierCy.ORDERS.SINGLE_ORDER.DECLINE_ORDER_MODAL.TEXTAREA__NOTE"
          />
        </div>
      </template>
      <template #footer>
        <ez-button
          type="link"
          @click="closeDeclineModal"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.DECLINE_ORDER_MODAL.BUTTON__CANCEL"
        >
          Cancel
        </ez-button>
        <ez-button
          type="red"
          @click="confirmDeclineOrder"
          :is-loading="isLoadingDecline"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.DECLINE_ORDER_MODAL.BUTTON__CONFIRM"
        >
          Decline Entire Order
        </ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-confirmation-modal ref="orderAbandoned" icon="question">
      <template #title>Cancel Order?</template>
      <template #content>
        <p>Are you sure you want to cancel this order?</p>
        <ez-textarea
          form-key="cancelOrder"
          placeholder="Leave a Note"
          name="message"
          label="Note"
          class="mt-8"
          @onChange="val => (cancelNote = val)"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.CANCEL_ORDER_MODAL.TEXTAREA__NOTE"
        />
      </template>
      <template #footer>
        <ez-button
          type="link"
          @click="closeAbandonModal"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.CANCEL_ORDER_MODAL.BUTTON__CANCEL"
        >
          Discard
        </ez-button>
        <ez-button
          @click="confirmAbandonOrder"
          :is-loading="isLoadingAbandon"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.CANCEL_ORDER_MODAL.BUTTON__CONFIRM"
        >
          Cancel Order
        </ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-confirmation-modal ref="resolveModal">
      <template #title>
        <template v-if="selectedOrder.notMatching">Resolve Order?</template>
        <template v-else>Resolve Dispute?</template>
      </template>
      <template #content>
        <p v-if="selectedOrder.notMatching">
          Make sure you have settled this order in accordance with the invoice attached.
        </p>
        <p v-else>
          When you resolve the dispute, you will not be able to open it again. Make sure you have
          settled everything about this order with the {{ $t('global.venue') | lowercase }} before
          resolving it.
        </p>
        <hr />
        <ez-textarea
          name="resove-note"
          label="Note"
          placeholder="Enter note (Optional)"
          @onChange="val => (resolveMessage = val)"
          form-key="resolveModal"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.RESOLVE_ORDER_MODAL.TEXTAREA__NOTE"
        />
      </template>
      <template #footer>
        <ez-button
          @click="closeResolveModal"
          type="link"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.RESOLVE_ORDER_MODAL.BUTTON__CANCEL"
        >
          Cancel
        </ez-button>
        <ez-button
          @click="confirmResolve"
          :is-loading="isLoadingResolve"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.RESOLVE_ORDER_MODAL.BUTTON__CONFIRM"
        >
          Resolve
        </ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-confirmation-modal ref="deliveryOnModal">
      <template #title>Edit Delivery Date</template>
      <template #content>
        <div class="mt-16">
          <v-date-picker
            class="calendar mt-8"
            :data-cy="supplierCy.ORDERS.SINGLE_ORDER.EDIT_DELIVERY_DATE_MODAL.INPUT__DELIVERY_ON"
            name="expectedAt"
            label="Delivery Date"
            v-model="deliveryOn"
            form-key="filters"
            @dateChange="updateDeliveryOn"
          />
          <ez-textarea
            class="mt-12"
            :data-cy="supplierCy.ORDERS.SINGLE_ORDER.EDIT_DELIVERY_DATE_MODAL.TEXTAREA__NOTE"
            name="note"
            label="Note"
            placeholder="Leave a Note"
            :form-key="'deliveryOnForm'"
            :value="selectedOrder?.message || ''"
            @onChange="val => (deliveryOnNote = val)"
          />
        </div>
      </template>
      <template #footer>
        <ez-button
          type="link"
          @click="closeDeliveryOnModal"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.EDIT_DELIVERY_DATE_MODAL.BUTTON__CANCEL"
        >
          Cancel
        </ez-button>
        <ez-button
          @click="saveDeliveryOnDate"
          :data-cy="supplierCy.ORDERS.SINGLE_ORDER.EDIT_DELIVERY_DATE_MODAL.BUTTON__SAVE"
        >
          Save Changes
        </ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-confirmation-modal ref="venueInvoiceNumberEditModal">
      <template #title>Edit Reference Number</template>
      <template #content>
        <div class="mt-16">
          <ez-input
            formKey="order-reference-number"
            name="orderReferenceNumber"
            label="Reference Number"
            placeholder="Enter Reference Number"
            :value="venueInvoiceNumber ? venueInvoiceNumber : selectedOrder.venueOrderNumber"
            class="mt-16"
            @onChange="val => (invoiceNumber = val)"
          />
        </div>
      </template>
      <template #footer>
        <ez-button type="link" @click="closeInvoiceNumberModal"> Cancel </ez-button>
        <ez-button @click="invoiceNumberSave"> Save Changes </ez-button>
      </template>
    </ez-confirmation-modal>

    <due-date-edit-modal
      ref="dueDateEditModal"
      :current-due-date="dueDate || today"
      :note="selectedOrder?.message || ''"
      @dueDateSave="dueDateSave"
    />

    <order-date-edit-modal
      ref="orderDateEditModal"
      :current-order-date="orderDate || today"
      :note="selectedOrder?.message || ''"
      @orderDateSave="orderDateSave"
    />

    <drivers-edit-modal
      ref="driversEditModal"
      :drivers="drivers"
      :selected-driver="driver"
      :note="selectedOrder?.message || ''"
      @driverSave="driverSave"
    />

    <edit-inventory-management
      v-if="editingMode"
      ref="editInventoryManagement"
      :order="selectedOrder"
      :warehouseId="warehouseId"
      @onChange="onInventoryItemsChange"
      @onConfirm="saveEdit"
    />

    <ez-loader :show="loading || isSwitchingAccount">Loading...</ez-loader>
  </order-list>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import httpService from '@/api/http';
import { resetState } from '@/store';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import { PendingBanner, SingleOrder, OrderList, ExportButton } from '@/views/common/orders';
import flash from '@/components/ui/FlashMessage';
import EzButton, { EzButtonGroup } from '@/components/ui/Button';
import { EzConfirmationModal, EzInfoModal } from '@/components/ui/Modal';
import EzDrawer from '@/components/ui/Drawer';
import { EzNotes } from '@/views/common/orders/notes';
import StatusBadge from '@/views/common/status-badge';
import {
  ORDER_STATUS_PENDING,
  ORDER_STATUS_NEEDS_APPROVAL,
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_PARTIALLY_ACCEPTED,
  ORDER_STATUS_DECLINED,
  ORDER_STATUS_SHIPPED,
  statusFilterLabelsSupplier,
  PARTIALLY_ACCEPTABLE,
  ORDER_DATE_FILTER_DEFAULT,
  DISTRIBUTOR_ORDER_SCHEDULED_DATE_FILTER_DEFAULT as ORDER_SCHEDULED_DATE_FILTER_DEFAULT,
  LOADING_KEY,
  DATE_INPUT_FORMAT,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
  ORDER_STATUS_QUOTE,
  ORDER_STATUS_BACKORDER,
  INTEGRATION_WARNINGS_CTAS_PATHS,
  CREATE_CUSTOMER_KEY,
  SEND_ONBOARDING_LINK_KEY,
} from '@/util/constants';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import ScheduleOrder from '@/views/platform/distributor/orders/actions/ScheduleOrder.vue';
import ConvertToOrderModal from '@/views/platform/distributor/orders/actions/ConvertToOrderModal.vue';
import ConfirmOrder from '@/views/common/orders/actions/ConfirmOrder.vue';
import ButtonSwitch from '@/views/platform/distributor/orders/ButtonSwitch.vue';
import { distributorCreateInvoice, distributorCreateBill } from '@/api/endpoints/xero';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import ExportOrders from '@/views/common/orders/ExportOrders';
import EzInput from '@/components/ui/Input';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import dayjs from 'dayjs';
import EzLoader from '@/components/ui/Loader/EzLoader';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';
import bus from '@/views/platform/distributor/orders/bus';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzAlert from '@/components/ui/Alert/EzAlert';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
import { isDistributor } from '@/mixins/permissions/utils';
import { isDeclined, isExcluded, isPremium, falsy, debounce } from '@/util/utils';
import { addTotalPriceCustom } from '@/util/utilsFinCalculator';
import VSubtotalInfoStatic from '@/components/v3/patterns/VSubtotalInfoStatic';
import DueDateEditModal from '@/views/platform/distributor/orders/edit-modals/dueDateEditModal.vue';
import OrderDateEditModal from '@/views/platform/distributor/orders/edit-modals/orderDateEditModal.vue';
import DriversEditModal from '@/views/platform/distributor/orders/edit-modals/driversEditModal.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzLiteBadge from '@/components/ui/LiteBadge';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import EzSelect from '@/components/ui/Select';
import AdvancedInventoryManagement from '@/views/platform/distributor/orders/AdvancedInventoryManagement';
import EzFilterList from '@/components/ui/FilterList/EzFilterList.vue';
import EzWarehouseFilter from '@/components/ui/Filter/Warehouse.vue';
import CreateFromDraft from '@/views/platform/distributor/orders/actions/CreateFromDraft.vue';
import EditInventoryManagement from './EditInventoryManagement.vue';

const termFilterKey = 'orderNumber';

export default {
  components: {
    DriversEditModal,
    OrderDateEditModal,
    DueDateEditModal,
    EzFilterList,
    EzTextarea,
    EzAlert,
    VSubtotalInfo,
    VSelectSearch,
    ButtonSwitch,
    EzButtonGroup,
    ConfirmOrder,
    ScheduleOrder,
    ConvertToOrderModal,
    EzButton,
    EzConfirmationModal,
    EzDrawer,
    VVenueEntityInfo,
    PendingBanner,
    SingleOrder,
    OrderList,
    EzNotes,
    ExportButton,
    StatusBadge,
    EzInfoModal,
    VDatePicker,
    ExportOrders,
    EzInput,
    EzLoader,
    VFilterDropdown,
    EzCheckbox,
    VSubtotalInfoStatic,
    EzEntityInfo,
    EzLiteBadge,
    EzPremiumBadge,
    EzSelect,
    AdvancedInventoryManagement,
    EditInventoryManagement,
    EzWarehouseFilter,
    CreateFromDraft,
  },
  props: {
    orderId: {
      type: Number,
      required: false,
      default: null,
    },
    isPublicOrder: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      termFilterKey,
      ORDER_STATUS_PENDING,
      ORDER_STATUS_ACCEPTED,
      ORDER_STATUS_PARTIALLY_ACCEPTED,
      ORDER_STATUS_SHIPPED,
      ORDER_STATUS_DECLINED,
      filters: {
        venueId: null,
        fulfillmentWarehouseId: null,
        driverId: null,
        month: null,
        status: [],
      },
      confirmMessage: '',
      declineMessage: '',
      resolveMessage: '',
      defaultRangeName: ORDER_DATE_FILTER_DEFAULT.name,
      range: {
        start: ORDER_DATE_FILTER_DEFAULT.from,
        end: ORDER_DATE_FILTER_DEFAULT.to,
      },
      defaultRangeNameScheduled: ORDER_SCHEDULED_DATE_FILTER_DEFAULT.name,
      scheduledRange: {
        start: ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from,
        end: ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to,
      },
      loading: false,
      editingMode: false,
      someUnavailable: false,
      initialStatusFilters: {},
      today: new Date(),
      deliveryOn: null,
      deliveryOnNote: '',
      venueInvoiceNumber: null,
      supplierCy,
      cancelNote: '',
      prevDatesLabels: {},
      pendingFilterActive: false,
      inventoryItems: [],
      warehouseId: null,
      isProvinoWarehouse: false,
      warehouses: [],
      acceptOrderDisabled: true,
      advancedOptions: false,
      orderConfirmationModalVisible: false,
      hasEditedProducts: false,
      venues: [],
      warehousesFilters: [],
      driversFilters: [],
      orders: [],
      originalSelectedOrder: {},
      termFilter: { [termFilterKey]: null },
      drivers: [],
      isSwitchingAccount: false,
    };
  },
  computed: {
    ...mapState('entities/orders', ['meta', 'singleOrder']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('entities/users', [
      'isDistributorXeroConnected',
      'isDistributorProvinoConnected',
      'isVenue',
      'isDistributor',
      'getDistributor',
    ]),
    ...mapGetters('loading', ['getLoading']),
    distributor() {
      return this.loggedUser?.distributor;
    },
    showInvoiceNumber() {
      return this.isDistributor && this.distributor.hasInvoiceNumbers;
    },
    hasProvider() {
      return !falsy(this.selectedOrder?.venue?.provider);
    },
    venueOrDistributor() {
      let str = '';

      if (this.isPublicOrder) {
        str = this.type === 'venue' ? this.$t('global.distributor') : this.$t('global.venue');
      } else {
        str = this.isDistributor ? this.$t('global.venue') : this.$t('global.distributor');
      }

      return str.toLocaleLowerCase();
    },
    changeDeliveryDateMsg() {
      return this.canEditDeliveryOn ? ' You can also change the delivery date.' : '';
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_MORE_ORDERS);
    },
    isLoadingDecline() {
      return this.getLoading(LOADING_KEY.DECLINE_ORDER);
    },
    isLoadingResolve() {
      return this.getLoading(LOADING_KEY.RESOLVE_DISPUTE);
    },
    isLoadingAbandon() {
      return this.getLoading(LOADING_KEY.ABANDON_ORDER);
    },
    isDistributorPremium() {
      if (this.isPublicOrder && isDistributor(this.type)) {
        const {
          distributor: { accountType },
        } = this.singleOrder;

        return isPremium(accountType);
      }
      return this.isDistributor && this.$permission.isPremium;
    },
    areTaxValuesNumbers() {
      const { tax, amount, amountWithTax } = this.selectedOrder;
      return Number.isFinite(tax) && Number.isFinite(amount) && Number.isFinite(amountWithTax);
    },
    isLoadingAccept() {
      return this.getLoading(LOADING_KEY.ACCEPT_ORDER);
    },
    acceptText() {
      return this.excludedProducts?.length ? 'Partially Accept Order' : 'Accept Order';
    },
    exportTypeLen() {
      return this.selectedOrder?.exportTypes?.length;
    },
    selectedOrder() {
      return this.singleOrder;
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      return this.selectedOrder.orderedProducts
        .filter(prod => !isExcluded(prod) && !isDeclined(prod))
        .map(item =>
          addTotalPriceCustom({
            item,
            multiplicand: 'priceQuantity',
            checkPriceUnits: false,
          }),
        );
    },
    dueDate() {
      return this.selectedOrder?.dueDate && dayjs(this.selectedOrder?.dueDate);
    },
    orderDate() {
      return this.selectedOrder?.orderedAt && dayjs(this.selectedOrder?.orderedAt);
    },
    driver() {
      return this.selectedOrder?.driver || {};
    },
    deliveryFee() {
      return this.selectedOrder.deliveryFee;
    },
    deliveryFeeTaxable() {
      return this.selectedOrder.deliveryFeeWithoutTax;
    },
    amountAdjustment() {
      return this.selectedOrder.amountAdjustment;
    },
    taxAdjustment() {
      return this.selectedOrder.taxAdjustment;
    },
    tax() {
      return this.selectedOrder.tax;
    },
    taxCalculation() {
      return this.selectedOrder.taxCalculation;
    },
    isSomeTbd() {
      return (this.selectedOrder.orderedProducts || [])
        .filter(pr => !isDeclined(pr))
        .some(pr => {
          if (!pr.quantity) return false;
          return pr.marketPrice || (!pr.priceQuantity && pr.price > 0);
        });
    },
    orderDiscountAmount() {
      return this.selectedOrder?.orderDiscountAmount;
    },
    // Props for VSubtotalInfo - End
    excludedProducts() {
      return (this.selectedOrder.orderedProducts || []).filter(isExcluded);
    },
    declinedProductsIds() {
      return (this.selectedOrder.orderedProducts || []).filter(isDeclined).map(a => a.id);
    },
    userAllowedStatusFilters() {
      return this.$permission.permissions.seeOrders.statuses;
    },
    canSeePendingOrders() {
      return this.statusFilter.find(status => status.id === ORDER_STATUS_PENDING);
    },
    statusFilter() {
      const excludedStatuses = [ORDER_STATUS_PARTIALLY_ACCEPTED, ORDER_STATUS_NEEDS_APPROVAL];
      let statuses = Object.entries(statusFilterLabelsSupplier)
        .filter(([status]) => !excludedStatuses.includes(status))
        .map(([status, label]) => ({ id: status, name: label }));

      if (this.userAllowedStatusFilters) {
        statuses = statuses.filter(status => this.userAllowedStatusFilters.includes(status.id));
      }

      return statuses;
    },
    monthFilter() {
      return [
        {
          id: null,
          from: null,
          to: null,
          name: 'All Time',
        },
        ...this.$helpers.generateMonthsToDate(this.meta.firstOrderedAt),
      ];
    },
    canEditProducts() {
      return PARTIALLY_ACCEPTABLE.includes(this.selectedOrder.status);
    },
    canEditDeliveryOnWhenCompleted() {
      return [ORDER_STATUS_COMPLETED, ORDER_STATUS_COMPLETED_AFTER_DISPUTE].includes(
        this.selectedOrder.status,
      );
    },
    canEditDeliveryOn() {
      const { editConfig } = this.selectedOrder;

      return this.editingMode && editConfig?.deliveryDate;
    },
    forceDisable() {
      return [
        !this.isDistributorProvinoConnected
          ? {
              btnType: 'schedule_delivery',
              value: this.someUnavailable,
            }
          : {},
        ...(this.someUnavailable
          ? [
              !this.isDistributorProvinoConnected
                ? {
                    btnType: 'accept',
                    value: true,
                  }
                : {},
              !this.isDistributorProvinoConnected
                ? {
                    btnType: 'complete',
                    value: true,
                  }
                : {},
            ]
          : []),
        ...(!this.selectedOrder.venue.connection
          ? [{ btnType: 'convert_to_order', value: true }]
          : []),
      ];
    },
    checkIsMpOrToBeDetermined() {
      return this.selectedOrder?.orderedProducts?.some(pr => pr.variableAmount || pr.marketPrice);
    },
    hasMpInOrder() {
      const products = this.selectedOrder?.orderedProducts;
      return products?.some(pr => pr.marketPrice);
    },
    hasTbdInOrder() {
      const products = this.selectedOrder?.orderedProducts;
      return products?.some(pr => pr.variableAmount);
    },
    venueHasAddress() {
      return !!this.selectedOrder.venue.shippingAddress?.formattedAddress;
    },
    venueAddress() {
      return this.selectedOrder.venue.shippingAddress.formattedAddress;
    },
    venueHasPremiumBadge() {
      return this.$helpers.isPremium(this.selectedOrder.venue.accountType);
    },
    venueHasLiteBadge() {
      return this.$helpers.isLite(this.selectedOrder.venue.accountType);
    },
    hasActions() {
      return !!this.selectedOrder?.actions?.length;
    },
    shouldDisplayFooter() {
      return this.hasActions || this.areTaxValuesNumbers;
    },
    trackingInventory() {
      return this.isDistributor && this.selectedOrder.distributor?.inventorySettings?.tracking;
    },
    hasTrackedProducts() {
      return !!this.filterProducts(this.selectedOrder.orderedProducts).length;
    },
    shouldShowWarehouseDropdown() {
      if (!this.trackingInventory || !this.hasTrackedProducts) return false;
      return this.warehouses.length;
    },
    orderConfirmationVisible() {
      if (!this.trackingInventory || !this.hasTrackedProducts) return false;
      return this.selectedOrder.orderedProducts && this.orderConfirmationModalVisible;
    },
    createBillLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_CREATE_XERO_BILL);
    },
    hasIntegrationWarnings() {
      return !!this.selectedOrder.integrationWarnings?.length;
    },
    defaultDateFilters() {
      return {
        month: {
          from: dayjs(ORDER_DATE_FILTER_DEFAULT.from).valueOf(),
          to: dayjs(ORDER_DATE_FILTER_DEFAULT.to).valueOf(),
        },
        scheduled: {
          from: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from).valueOf(),
          to: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to).valueOf(),
        },
      };
    },
    numberOfAppliedFilters() {
      return Object.values(this.initialStatusFilters).filter(x => x === null || x === '' || x)
        .length;
    },
    isQuoteStatus() {
      return this.selectedOrder.status === ORDER_STATUS_QUOTE;
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    me() {
      return this.isDistributor ? this.getDistributor : null;
    },
  },
  methods: {
    ...mapMutations('entities/orders', [
      'SET_SELECTED_SINGLE_ORDER',
      'UPDATE_SELECTED_SINGLE_ORDER',
      'UPDATE_META',
      'UPDATE_FILTERS',
      'SET_DRAFT_VENUE',
      'SET_OUTLET_REFERENCE_NUMBER',
      'SET_DRAFT_PRODUCTS',
      'SET_PRODUCTS',
      'CALCULATE_NEW_ORDER_AMOUNT',
      'UPDATE_DELIVERY_FEE_TO_NEW_ORDER',
      'UPDATE_DRAFT',
      'ADD_SELECTED_PRODUCT',
    ]),
    ...mapMutations('entities/users', ['UPDATE_DISTRIBUTOR_ACTIONS_COUNT']),
    ...mapMutations('creditNotes', ['SET_CREDIT_NOTE_ORDER']),
    ...mapActions('entities/orders', [
      'distributorSaveOrderEdit',
      'distributorFetchOrderInventoryStock',
    ]),
    ...mapActions('reports', ['fetchOrderHistoryTotal']),
    ...mapMutations('entities/venues', [
      'UPDATE_DRAFT',
      'SET_ONBOARDING_LINK_DATA',
      'NEW_CUSTOMER_GROUP_LINK_DATA',
    ]),
    ...mapActions('entities/users', ['ownerCheck']),
    confirmDelivery() {
      this.$refs.confirm.open();
    },
    resetDrawerRoute() {
      this.editingMode = false;
      this.$router.replace({ name: 'distributor-home' });
    },
    changeScheduledRangeName(rangeName) {
      this.defaultRangeNameScheduled = rangeName;
    },
    changeRangeName(rangeName) {
      this.defaultRangeName = rangeName;
    },
    filterClosed() {
      if (!this.filters.from && !this.filters.to) {
        this.defaultRangeName = ORDER_DATE_FILTER_DEFAULT.name;
      }
      this.resetDateLabelsIfNeeded();
      this.resetDateFiltersIfNeeded();
    },
    resetDateLabelsIfNeeded() {
      const { defaultRangeName, defaultRangeNameScheduled } = this.prevDatesLabels;
      const sameOrderLabel = defaultRangeName === this.defaultRangeName;
      const sameDeliveryLabel = defaultRangeNameScheduled === this.defaultRangeNameScheduled;

      if (!sameOrderLabel) this.changeRangeName(defaultRangeName);
      if (!sameDeliveryLabel) this.changeScheduledRangeName(defaultRangeNameScheduled);
    },
    resetDateFiltersIfNeeded() {
      const { month, scheduled } = this.initialStatusFilters;

      if (month) {
        const sameOrderDate = month.start === this.range.start && month.end === this.range.end;
        if (!sameOrderDate) this.range = { ...month };
      }

      if (scheduled) {
        const sameScheduledDate =
          scheduled.start === this.scheduledRange.start &&
          scheduled.end === this.scheduledRange.end;
        if (!sameScheduledDate) this.scheduledRange = { ...scheduled };
      }
    },
    resetFilters() {
      this.initialStatusFilters = {};
      this.pendingFilterActive = false;
      Object.keys(this.filters).forEach(key => {
        if (key !== termFilterKey) {
          this.filters[key] = null;
        }
      });

      const isAllTimeScheduled = ORDER_SCHEDULED_DATE_FILTER_DEFAULT.name !== 'All Time';
      this.filters = {
        ...this.filters,
        scheduledFrom: isAllTimeScheduled ? ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from : null,
        scheduledTo: isAllTimeScheduled ? ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to : null,
      };

      this.range = {
        start: dayjs(ORDER_DATE_FILTER_DEFAULT.from).valueOf(),
        end: dayjs(ORDER_DATE_FILTER_DEFAULT.to).valueOf(),
      };
      this.scheduledRange = {
        start: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from).valueOf(),
        end: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to).valueOf(),
      };
      this.defaultRangeName = ORDER_DATE_FILTER_DEFAULT.name;
      this.defaultRangeNameScheduled = ORDER_SCHEDULED_DATE_FILTER_DEFAULT.name;

      this.$nextTick(() => this.$refs.filtersGroup.syncLocalFilters());

      this.orders = [];
      this.refresh({ filters: this.filters });
    },
    async openDrawerAndLoadOrder({ id }) {
      try {
        const { data } = await this.fetchDistributorOrder({ id });
        this.originalSelectedOrder = data.data;

        this.$router.replace({ name: 'distributor-orders-all', params: { id } });
        this.openOrderDrawer();
      } catch (e) {
        this.resetDrawerRoute();
      }
    },
    async loadDrawerOrder({ id }) {
      const token = this.$route.query?.token;
      if (token) {
        try {
          const { data: accountOwnerData } = await this.ownerCheck({
            data: {
              entity: 'order',
              entityId: id,
              scope: 'distributor',
            },
          });
          if (accountOwnerData.data.id === this.loggedUser.id) {
            const { data } = await this.fetchDistributorOrder({ id });
            this.originalSelectedOrder = data.data;

            this.openOrderDrawer();
          } else {
            this.isSwitchingAccount = true;
            const { data } = await httpService.put(`/accounts/switch/${accountOwnerData.data.id}`);
            const { platform } = data.data;
            if (process.env.VUE_APP_PLATFORM_TITLE.toLowerCase() === platform) {
              await httpService.delete('/manager/oauth');
              localStorage.clear();
              resetState();
            }
            this.isSwitchingAccount = false;
            window.location.href = `${data.data.url}/login?token=${data.data.token}&redirectTo=distributor-orders-all&orderId=${id}`;
          }
        } catch (e) {
          this.resetDrawerRoute();
        }
      } else {
        try {
          const { data } = await this.fetchDistributorOrder({ id });
          this.originalSelectedOrder = data.data;

          this.openOrderDrawer();
        } catch (e) {
          this.resetDrawerRoute();
        }
      }
    },
    onEditDeliveryOn() {
      this.$refs.deliveryOnModal.open();
    },
    editOrderDate() {
      this.$refs.orderDateEditModal.open();
    },
    editDueDate() {
      this.$refs.dueDateEditModal.open();
    },
    editVenueInvoiceNumber() {
      this.$refs.venueInvoiceNumberEditModal.open();
    },
    async editDriver() {
      const { data } = await this.distributorFetchDrivers();
      this.drivers = data.data;
      this.$refs.driversEditModal.open();
    },
    saveDeliveryOnDate() {
      this.UPDATE_SELECTED_SINGLE_ORDER({
        ...this.selectedOrder,
        expectedAt: dayjs(this.deliveryOn.format(DATE_INPUT_FORMAT)).valueOf(),
        isExpectedAtEdited: true,
        message: this.deliveryOnNote,
      });
      this.$refs.deliveryOnModal.close();
    },
    invoiceNumberSave() {
      this.UPDATE_SELECTED_SINGLE_ORDER({
        ...this.selectedOrder,
        venueOrderNumber: this.invoiceNumber,
        isInvoiceNumberEdited: true,
      });
      this.$refs.venueInvoiceNumberEditModal.close();
    },
    dueDateSave(val) {
      this.UPDATE_SELECTED_SINGLE_ORDER({
        ...this.selectedOrder,
        dueDate: dayjs(val.dueDate.format(DATE_INPUT_FORMAT)).valueOf(),
        isDueDateEdited: true,
        message: val.message,
      });
      this.$refs.dueDateEditModal.close();
    },
    orderDateSave(val) {
      this.UPDATE_SELECTED_SINGLE_ORDER({
        ...this.selectedOrder,
        orderedAt: dayjs(val.orderDate.format(DATE_INPUT_FORMAT)).valueOf(),
        isOrderDateEdited: true,
        message: val.message,
      });
      this.$refs.orderDateEditModal.close();
    },
    driverSave(val) {
      this.UPDATE_SELECTED_SINGLE_ORDER({
        ...this.selectedOrder,
        driver: val.driver,
        isDriverEdited: true,
        message: val.message,
      });
      this.$refs.driversEditModal.close();
    },
    updateDeliveryOn(date) {
      this.deliveryOn = date;
    },
    closeDeliveryOnModal() {
      this.$refs.deliveryOnModal.close();
    },
    closeInvoiceNumberModal() {
      this.$refs.venueInvoiceNumberEditModal.close();
      this.invoiceNumber = null;
    },
    async editedProduct() {
      const { orderId } = this;
      return this.loadDrawerOrder({ id: orderId });
    },
    async openOrderDrawer() {
      await this.$nextTick();
      await this.fetchOrderInventoryStock(this.orderId);
      this.someUnavailable = false;
      this.$refs.orderDrawer.open();
    },
    async confirmOrderSuccess() {
      this.closeOrderDrawer();
      flash.success({
        title: 'Order completed.',
        message: 'You have successfully completed the order.',
      });
      this.orders = [];
      await this.refresh({ filters: this.filters });
    },
    async confirmOrderSchedule() {
      this.closeOrderDrawer();
      this.orders = [];
      await this.refresh({ filters: this.filters });
    },
    closeOrderDrawer() {
      this.$refs.orderDrawer.close();
      this.inventoryItems = [];
      this.warehouseId = null;
    },
    combineFilters() {
      this.filters = {
        ...this.filters,
        ...this.termFilter,
      };
    },
    resetTermFilter() {
      this.termFilter[termFilterKey] = null;
      this.combineFilters();

      this.orders = [];
      this.refresh({ filters: this.filters });
    },
    updateTermFilter: debounce(function deb(name, event) {
      if (this.termFilter[termFilterKey] === event) return;

      this.termFilter[termFilterKey] = event;
      this.combineFilters();

      this.orders = [];
      this.refresh({ filters: this.filters });
    }, 300),
    updateFilters(filters, pendingFilter = false) {
      this.initialStatusFilters = filters;
      this.pendingFilterActive = pendingFilter;
      const filtersKeys = Object.keys(statusFilterLabelsSupplier); // Platform status labels
      // looking for selected status filters
      const statusFilters = [];
      Object.keys(filters).forEach(key => {
        if (filtersKeys.includes(key) && filters[key]) {
          statusFilters.push(key);
        }
      });

      const isAllTimeScheduled =
        !!this.defaultRangeNameScheduled && // If default range name is 'All Time'
        this.defaultRangeNameScheduled !== 'All Time';

      this.filters = {
        ...this.filters,
        from: dayjs(this.range?.start).valueOf(),
        to: dayjs(this.range?.end).valueOf(),
        venueId: filters.venueId?.id,
        fulfillmentWarehouseId: filters.fulfillmentWarehouseId?.id,
        driverId: filters.driverId?.id,
        scheduledFrom: isAllTimeScheduled ? dayjs(this.scheduledRange?.start).valueOf() : null,
        scheduledTo: isAllTimeScheduled ? dayjs(this.scheduledRange?.end).valueOf() : null,
        status: [...statusFilters],
      };

      if (this.initialStatusFilters.fulfillmentWarehouseId?.id === null)
        delete this.initialStatusFilters.fulfillmentWarehouseId;
      if (this.initialStatusFilters.driverId?.id === null)
        delete this.initialStatusFilters.driverId;

      this.range = {
        start: dayjs(filters.month?.start || this.range?.start).valueOf(),
        end: dayjs(filters.month?.end || this.range?.end).valueOf(),
      };
      this.scheduledRange = {
        start: dayjs(filters.scheduled?.start || this.scheduledRange?.start).valueOf(),
        end: dayjs(filters.scheduled?.end || this.scheduledRange?.end).valueOf(),
      };

      this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());

      this.orders = [];
      this.refresh({ filters: this.filters });
    },
    setRangeToAllTime() {
      // Change the range to all time.
      this.range = {
        start: dayjs('1970-01-02'),
        end: dayjs().endOf('d'),
      };
    },
    setScheduledRangeToAllTime() {
      this.scheduledRange = {
        start: dayjs('1970-01-02'),
        end: dayjs().endOf('d').add(10, 'year'),
      };
    },
    filterPending() {
      this.setRangeToAllTime();
      this.setScheduledRangeToAllTime();

      this.defaultRangeName = 'All Time';
      this.defaultRangeNameScheduled = 'All Time';

      this.filters = {
        from: dayjs(this.range.start).valueOf(),
        to: dayjs(this.range.end).valueOf(),
        scheduledFrom: dayjs(this.scheduledRange.start).valueOf(),
        scheduledTo: dayjs(this.scheduledRange.end).valueOf(),
      };

      const filters = {
        scheduled: {
          start: this.filters.scheduledFrom,
          end: this.filters.scheduledTo,
        },
        month: {
          start: this.filters.from,
          end: this.filters.to,
        },
        [ORDER_STATUS_PENDING]: true,
      };

      this.updateFilters(filters, true);
    },
    onAbandonOrder() {
      this.$refs.orderAbandoned.open();
    },
    onReorderOrder() {
      this.SET_DRAFT_VENUE({ ...this.selectedOrder.venue });
      this.SET_OUTLET_REFERENCE_NUMBER(this.selectedOrder.venueOrderNumber);
      this.SET_DRAFT_PRODUCTS(this.selectedOrder.orderedProducts);
      this.selectedOrder.orderedProducts.forEach(item =>
        this.ADD_SELECTED_PRODUCT({ currency: this.me.currency, ...item, id: item.productId }),
      );
      this.UPDATE_DRAFT({ oldAmount: this.selectedOrder.amount });
      this.CALCULATE_NEW_ORDER_AMOUNT();
      this.UPDATE_DELIVERY_FEE_TO_NEW_ORDER({
        distributor: this.me,
        taxCalculation: this.selectedOrder.taxCalculation,
      });
      this.$router.push({ name: 'distributor-orders-new' });
    },
    async onAcceptOrderClick() {
      if (!this.orderConfirmationVisible) {
        this.acceptOrderDisabled = false;
      }

      await this.initWarehouses();
      this.orderConfirmationModalVisible = true;
      this.$refs.orderConfirmation.open();
    },
    onResolveOrder() {
      this.$refs.resolveModal.open();
    },
    closeResolveModal() {
      this.$refs.resolveModal.close();
    },
    onCreateFromDraft() {
      this.$refs.createFromDraft.open();
    },
    async edit() {
      await this.initWarehouses();
      await this.$nextTick();
      this.editingMode = true;
    },
    addCreditNote() {
      this.SET_CREDIT_NOTE_ORDER(this.selectedOrder);
      this.$router.push({ name: 'distributor-credit-notes-new' });
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.selectedOrder.copyUrl || '');
      flash.success({
        title: 'Link copied!',
        message: 'The link has been copied successfully.',
      });
    },
    onSuccessConvert() {
      this.closeOrderDrawer();
      this.orders = [];
      this.refresh({ filters: this.filters });
    },
    async openInventoryManagementModal() {
      await this.initWarehouses();
      this.$refs.editInventoryManagement.open();
    },
    async saveChanges() {
      const isNotPending = ![
        ORDER_STATUS_QUOTE,
        ORDER_STATUS_PENDING,
        ORDER_STATUS_BACKORDER,
      ].includes(this.selectedOrder.status);
      const hasTrackedProducts = this.selectedOrder.orderedProducts.some(p => p.inventoryTracked);

      if (this.trackingInventory && isNotPending && hasTrackedProducts && this.hasEditedProducts) {
        this.openInventoryManagementModal();
      } else {
        await this.saveEdit();
      }
    },
    async saveEdit() {
      try {
        this.loading = true;
        const { data } = await this.distributorSaveOrderEdit({
          ...this.singleOrder,
          ...(this.trackingInventory
            ? {
                warehouseId: this.warehouseId,
                inventoryItems: this.inventoryItems,
              }
            : {}),
        });
        this.originalSelectedOrder = data.data;
        this.UPDATE_SELECTED_SINGLE_ORDER({ ...data.data });
        await this.fetchOrdersTotal(this.filters);
        /**
         * This is a simple bus event handler to check inventory products status
         * after successfully order edit
         */
        bus.$emit('savedEditSuccessfully');
        this.editingMode = false;

        const message = `You have successfully edited the order. ${this.$t(
          'global.venue',
        )} will be notified about the change.`;
        flash.success({
          title: 'Order successfully edited.',
          ...(!this.isDistributorPremium ? { message } : {}),
        });
        await this.fetchOrderInventoryStock(this.orderId);
        this.orders = [];
        await this.refresh({ filters: this.filters });
      } finally {
        this.loading = false;
      }
    },
    discardEdit() {
      this.editingMode = false;
      this.SET_SELECTED_SINGLE_ORDER(this.originalSelectedOrder);
    },
    async confirmResolve() {
      const { id } = this.selectedOrder;
      await this.distributorResolveDispute({
        orderId: id,
        declinedProducts: this.declinedProductsIds,
        message: this.resolveMessage,
      });
      this.orders = [];
      this.refresh({ filters: this.filters });
      this.resolveMessage = '';

      this.closeResolveModal();
      this.closeOrderDrawer();

      const { href } = this.$router.resolve({ name: 'distributor-orders-all', params: { id } });

      if (this.selectedOrder.notMatching) {
        flash.success({
          title: 'Order resolved.',
          message: 'You have successfully resolved the completed order.',
          ctas: `<a href="${href}">Go to Order Overview</a>`,
        });
      } else {
        flash.success({
          title: 'Dispute resolved!',
          message: 'Great! You can go to order overview to see detailed info about the order.',
          ctas: `<a href="${href}">Go to Order Overview</a>`,
        });
      }
    },
    async confirmOrderCreation() {
      const declinedProducts = this.selectedOrder.orderedProducts
        .filter(p => p.isDeclined)
        .map(p => p.id);

      await this.acceptOrder({
        id: this.selectedOrder.id,
        declinedProducts,
        message: this.confirmMessage,
        ...(this.trackingInventory
          ? {
              warehouseId: this.warehouseId,
              inventoryItems: this.inventoryItems,
            }
          : {}),
      });
      this.confirmMessage = '';
      await this.initDistributor({ includeVenues: false });

      this.closeConfirmModal();
      this.closeOrderDrawer();

      this.orders = [];
      await this.refresh({ filters: this.filters });

      flash.success({ title: 'Order successfully accepted!' });
    },
    closeConfirmModal() {
      this.$refs.orderConfirmation.close();
    },
    onCloseConfirmModal() {
      this.orderConfirmationModalVisible = false;
      this.advancedOptions = false;
    },
    onDeclineOrderClick() {
      this.$refs.orderDecline.open();
    },
    onScheduleOrderClick() {
      this.$refs.schedule.open();
    },
    onConvertToOrderModal() {
      this.$refs.convertToOrder.open();
    },
    closeDeclineModal() {
      this.$refs.orderDecline.close();
    },
    closeAbandonModal() {
      this.$refs.orderAbandoned.close();
    },
    async confirmDeclineOrder() {
      const { id } = this.selectedOrder;

      await this.declineOrder({ id, message: this.declineMessage });
      this.declineMessage = '';

      this.orders = [];
      await Promise.all([
        this.initDistributor({ includeVenues: false }),
        this.refresh({ filters: this.filters }),
      ]);

      this.closeDeclineModal();
      this.closeOrderDrawer();

      flash.success({
        title: 'Order successfully declined!',
      });
    },
    async confirmAbandonOrder() {
      const { id } = this.selectedOrder;

      await this.distributorAbandonOrder({ orderId: id, cancelNote: this.cancelNote });

      this.orders = [];
      await Promise.all([
        this.initDistributor({ includeVenues: false }),
        this.refresh({ filters: this.filters }),
      ]);

      this.closeAbandonModal();
      this.closeOrderDrawer();

      const { href } = this.$router.resolve({ name: 'distributor-orders-all', params: { id } });
      flash.success({
        title: 'Order successfully cancelled.',
        message: 'You can see the order overview in Order Details.',
        ctas: `<a href="${href}">View Order Details</a>`,
      });
    },
    async onLoadMoreClick() {
      await Promise.all([
        this.refresh(
          {
            filters: this.filters,
            sortBy: 'expectedAt',
            updateMetaTotal: false,
            ...(this.meta?.nextId && { nextId: this.meta.nextId }),
            ...(this.meta?.nextValue && { nextValue: this.meta.nextValue }),
          },
          LOADING_KEY.FETCH_MORE_ORDERS,
        ),
        this.fetchOrdersTotal(this.filters),
      ]);
    },
    showVenueInfo() {
      this.$refs.infoModal.open();
    },
    async createInvoice() {
      this.loading = true;
      try {
        await distributorCreateInvoice(this.orderId);
        await this.loadDrawerOrder({ id: this.orderId });

        this.loading = false;

        flash.success({
          title: 'Created invoice successfully!',
        });
      } catch (e) {
        this.loading = false;
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({
          title: e.response.data.error.message || 'Something went wrong!',
        });
      }
    },
    async createBill() {
      try {
        await distributorCreateBill(this.orderId);
        await this.loadDrawerOrder({ id: this.orderId });

        flash.success({ title: 'Created bill successfully!' });
      } catch (e) {
        console.error(e);
        flash.error({ title: 'Something went wrong!' });
      }
    },
    async onIntegrationWarningCTA(url) {
      this.loading = true;

      try {
        await httpService.put(url);
        await this.loadDrawerOrder({ id: this.orderId });

        this.loading = false;
        flash.success({ title: 'Action completed successfully!' });
      } catch (e) {
        this.loading = false;
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({ title: 'Something went wrong!' });
      }
    },
    onIntegrationWarningCTAGroup(cta) {
      switch (cta.key) {
        case CREATE_CUSTOMER_KEY:
          this.UPDATE_DRAFT({ id: this.selectedOrder.venue?.id });
          this.NEW_CUSTOMER_GROUP_LINK_DATA({
            route: 'distributor-orders-all',
            params: { id: this.selectedOrder.id },
          });
          this.$router.push({
            name: INTEGRATION_WARNINGS_CTAS_PATHS[cta.key],
            params: { venueData: this.selectedOrder?.venue },
          });
          break;
        case SEND_ONBOARDING_LINK_KEY:
          this.SET_ONBOARDING_LINK_DATA({
            route: 'distributor-orders-all',
            params: { id: this.selectedOrder.id },
            id: this.selectedOrder.venue?.id,
            name: this.selectedOrder.venue?.name,
            invitationId: this.selectedOrder.venue?.invitation?.id,
          });
          this.$router.push({ name: INTEGRATION_WARNINGS_CTAS_PATHS[cta.key] });
          break;
        default:
          break;
      }
    },
    ...mapActions('entities/orders', [
      'fetchDistributorOrders',
      'fetchDistributorOrder',
      'acceptOrder',
      'declineOrder',
      'distributorAbandonOrder',
      'distributorResolveDispute',
    ]),
    ...mapActions('entities/users', ['initDistributor']),
    async fetchOrdersTotal(filters = {}) {
      const isAllTimeScheduled = this.defaultRangeNameScheduled === 'All Time';
      const query = {
        ...filters,
        ...(isAllTimeScheduled && !this.numberOfAppliedFilters
          ? {
              scheduledFrom: dayjs().startOf('month').valueOf(),
              scheduledTo: dayjs().endOf('month').valueOf(),
            }
          : {}),
      };
      const { data: total } = await this.fetchOrderHistoryTotal({ query });
      this.UPDATE_META({ meta: { ...this.meta, totalAmount: total.data } });
    },
    async refresh(query = {}, loadingKey = LOADING_KEY.FETCH_ORDERS) {
      this.UPDATE_FILTERS({ filters: query.filters || {} });
      const [{ data }] = await Promise.all([
        this.fetchDistributorOrders({
          updateMetaTotal: false,
          sortBy: 'expectedAt',
          ...query,
          loadingKey,
        }),
        this.fetchOrdersTotal(query.filters),
      ]);
      if (loadingKey === LOADING_KEY.FETCH_ORDERS) {
        this.orders = data;
      } else {
        this.orders = [...this.orders, ...data];
      }
    },
    ...mapActions('entities/distributors', [
      'distributorFetchWarehouses',
      'distributorFetchVenueFilterOptions',
      'distributorFetchDrivers',
    ]),
    async initWarehouses() {
      if (!this.trackingInventory) return;
      if (this.warehouses.length && this.warehouseId) return;

      const { data } = await this.distributorFetchWarehouses();
      this.warehouses = [{ id: null, name: 'Select Warehouse' }, ...data.data];

      const orderWarehouseId = this.selectedOrder.fulfillmentWarehouse?.id || null;
      const primaryWarehouseId = data.data.find(w => w.primary)?.id || null;
      this.warehouseId = orderWarehouseId || primaryWarehouseId;
      this.isProvinoWarehouse =
        this.selectedOrder.fulfillmentWarehouse?.isProvino ||
        data.data.find(w => w.primary)?.isProvino ||
        false;
    },
    showAdvancedOptions() {
      this.advancedOptions = !this.advancedOptions;
    },
    onWarehouseSelectChange(val) {
      if (this.warehouseId === val.id) return;
      this.isProvinoWarehouse = val.isProvino;
      this.warehouseId = val.id;
      this.inventoryItems = [];
    },
    onInventoryItemsChange(items) {
      this.inventoryItems = items.map(p => ({ itemId: p.id, quantity: p.usedInventory }));
    },
    onInventoryManagementInvalid(val) {
      if (this.warehouseId) this.advancedOptions = val;
      this.acceptOrderDisabled = val;
    },
    async fetchOrderInventoryStock(orderId) {
      const { data } = await this.distributorFetchOrderInventoryStock({ orderId });

      const orderedProducts = this.selectedOrder.orderedProducts.map(p => {
        const warehouses = data.data.find(pp => pp.productId === p.productId)?.warehouses || [];
        return { ...p, warehouses };
      });
      this.UPDATE_SELECTED_SINGLE_ORDER({ orderedProducts });
    },
    filterProducts(products) {
      return (
        products?.filter(p => {
          const declined = p.isDeclined ?? false;
          const included = p.isIncluded ?? true;
          return p.inventoryTracked && included && !declined;
        }) || []
      );
    },
    compareOrderedProducts(current, original) {
      const mapProduct = p => ({
        productId: p?.productId,
        quantity: p?.quantity,
        isIncluded: p?.isIncluded,
        isDeclined: p?.isDeclined,
        price: p?.price,
        marketPrice: p?.marketPrice,
      });

      const mappedCurrent = current?.map(mapProduct);
      const mappedOriginal = original?.map(mapProduct);

      return mappedCurrent?.some(p => {
        const orig = mappedOriginal?.find(pr => pr.productId === p.productId);
        return !orig
          ? true
          : orig.quantity !== p.quantity ||
              orig.isIncluded !== p.isIncluded ||
              orig.isDeclined !== p.isDeclined ||
              orig.price !== p.price ||
              orig.marketPrice !== p.marketPrice;
      });
    },
  },
  watch: {
    originalSelectedOrder(newVal) {
      this.SET_SELECTED_SINGLE_ORDER(newVal);
    },
    selectedOrder(order, prevOrder) {
      const isPrevPending = prevOrder.status === ORDER_STATUS_PENDING;
      const isCurrPending = order.status === ORDER_STATUS_PENDING;
      if (isPrevPending && !isCurrPending) {
        const actionsCount = this.meta.actionsCount - 1;
        this.UPDATE_META({ meta: { actionsCount, count: 0 } });
        this.UPDATE_DISTRIBUTOR_ACTIONS_COUNT(actionsCount);
      }

      this.deliveryOn = dayjs(order.expectedAt);

      const hasEditedProducts = this.compareOrderedProducts(
        order.orderedProducts,
        this.originalSelectedOrder?.orderedProducts,
      );
      this.hasEditedProducts = hasEditedProducts;
    },
    filters: {
      immediate: true,
      handler() {
        this.prevDatesLabels = {
          defaultRangeName: this.defaultRangeName,
          defaultRangeNameScheduled: this.defaultRangeNameScheduled,
        };
      },
    },
    inventoryItems(val) {
      if (val.length) return;

      const products = this.filterProducts(this.selectedOrder.orderedProducts);
      if (!products.length) return;

      this.acceptOrderDisabled = true;
    },
  },
  async mounted() {
    // Initial filters that should be applied.
    const [{ data: venuesData }, { data: warehouseData }, { data: driversData }] =
      await Promise.all([
        this.distributorFetchVenueFilterOptions({ query: { for: 'orders' } }),
        this.distributorFetchWarehouses({ withLocations: false }),
        this.distributorFetchDrivers(),
        this.refresh({ filters: this.filters }),
      ]);
    this.venues = venuesData.data;
    this.warehousesFilters = [{ id: null, name: 'All Warehouses' }, ...warehouseData.data];
    this.driversFilters = [{ id: null, name: 'All Drivers' }, ...driversData.data];
    const { orderId } = this;

    if (orderId) {
      this.loadDrawerOrder({ id: orderId });
    }

    if (!this.trackingInventory || !this.hasTrackedProducts) {
      this.acceptOrderDisabled = false;
    }
  },
};
</script>

<style scoped lang="scss">
$text-color: #252631;

.filters-grp {
  display: flex;

  :deep() .ez-select__display-container {
    height: 2.25rem;
  }
}

:deep() .loader {
  @extend %whole-screen;
}

:deep() .select-search {
  color: $color-gray-6C;

  &__list {
    max-height: 244px;
  }
}

:deep() .entity-info {
  .product-info {
    display: flex;
    flex-direction: column;

    &__name {
      display: flex;
      align-items: center;
    }

    &__address {
      @include font-size(12px, 18px);
      color: $color-gray-6C;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.notes-wrapper {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed #dee1e4;
}

.venue-info-btn {
  span {
    margin-left: 0 !important;
  }
}

.modal {
  .notes {
    :deep() .note {
      margin: 0 0 2em;

      &__header {
        font-weight: 600;
      }

      &__label {
        @extend %input-label;
        margin: 3.5em 0 0.5em 0;
      }

      .venue-info {
        display: flex;
        justify-content: center;
        color: $text-color;
      }
    }
  }

  .button-primary,
  .button-red {
    width: 100%;
    max-width: 13em;
  }

  .button-link {
    margin-right: 1em;
  }
}

.ez-pending-banner {
  margin-bottom: 1.5rem;
}

:deep() .drawer {
  @extend %order-drawer;
}

.orders__alert-create-invoice,
.orders__alert-retry {
  padding: 0;
  height: 25px;
}

.orders__alert-retry-group {
  & + & {
    margin-left: 25px;
  }
}

.orders__alert-create-bill {
  padding: 0;
  height: auto;
  line-height: inherit;
}

.orders__alert-create-bill--disabled,
.orders__alert-create-invoice--disabled {
  color: $color-primary-blue;
  cursor: not-allowed;
}

.filter-cta {
  display: flex;
}

.export-btn {
  :deep() .ez-simple-dropdown {
    &__label {
      color: $color-gray-6C;
    }
  }
}

:deep() .calendar {
  .date-picker {
    width: 100%;
    .ez-simple-dropdown__display-container {
      width: 100%;
      .placeholder {
        width: 100%;
        color: $color-gray-6C;
      }
    }
  }
}
.select-outlet {
  :deep() .select-search__trigger {
    width: 100%;
  }
}
.search {
  min-width: 210px;
}
.status-checkbox {
  @include font-size(14px, 20px);
}

.date-picker {
  :deep() .ez-simple-dropdown__display-container {
    width: 100%;
    .placeholder {
      width: 100%;
    }
  }
}

.advanced-options-btn {
  margin-right: auto;
  padding-left: 0;
}
</style>
