<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import EzTable from '@/components/ui/Table/EzTable';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import VAccountOwnerEntityInfo from '@/components/v3/patterns/VAccountOwnerEntityInfo';
import StatusBadge from '@/views/common/status-badge/StatusBadge';
import {
  ALL_ACCOUNT_REPS,
  CHECK_IN_EXPENSE,
  NO_SPENDINGS,
  ALL_TYPES, LOADING_KEY,
} from '@/util/constants';
import { capitalizeEveryWord, formatContactInfoForVenue, formatPrice, groupListByDateFormat } from '@/util/utils';
import EzDropDown from '@/components/ui/Dropdown/EzDropdown';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import flash from '@/components/ui/FlashMessage';
import dayjs from 'dayjs';
import EzButton from '@/components/ui/Button/EzButton';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EzLoader from '@/components/ui/Loader/EzLoader';
import AccountDrawer from '@/views/platform/distributor/crm/AccountDrawer';
import AccountDrawerData from '@/views/platform/distributor/crm/AccountDrawerData.model';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import EmptyState from '@/views/common/empty-state';
import downloadAttachment from '@/util/downloadAttachment';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';

export default {
  name: 'ExpenseTracking',
  components: {
    EzSpinner,
    AccountDrawer,
    EzLoader,
    EzLoadMore,
    EzConfirmationModal,
    EzButton,
    StatusBadge,
    VAccountOwnerEntityInfo,
    EzTable,
    EzDropDown,
    VDatePicker,
    EmptyState,
  },
  props: {
    singleId: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      routeNameSingle: 'distributor-expense-tracking-single',
      routeName: 'distributor-expense-tracking',
      list: [],
      meta: {},
      range: null,
      rangeName: '',
      filtersVals: {
        types: null,
        to: null,
        from: null,
        userId: null,
        venueId: null,
      },
      accountOwnerOptions: [],
      allAccounts: ALL_ACCOUNT_REPS,
      expenseTypes: [],
      allTypes: ALL_TYPES,
      noSpendingsStatus: NO_SPENDINGS,
      singleView: new AccountDrawerData({}),
      isLoaderShown: false,
      loadingKeyMore: 'fetch-list-more',
      supplierCy,
      expenseId: null,
    };
  },
  computed: {
    ...mapState('reports', [
      'filtersExpense',
    ]),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['isSomeLoading']),
    isLoadingMore() {
      return this.isSomeLoading([
        this.loadingKeyMore,
      ]);
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.INIT_DISTRIBUTOR,
        LOADING_KEY.FETCH_EXPENSE_TYPES_BY_CATEGORY,
        LOADING_KEY.FETCH_EXPENSE_USERS,
        LOADING_KEY.FETCH_REPORT_VENUES_SEARCH,
        LOADING_KEY.FETCH_EXPENSE_LIST,
        LOADING_KEY.FETCH_EXPENSE_SINGLE,
      ]);
    },
    dropdownDisabled() {
      return !this.$permission.has('filterAccountOwners');
    },
    groupedDict() {
      return groupListByDateFormat({
        list: this.list,
        dateKey: 'createdAt',
        dateFormat: 'MMMM YYYY',
      });
    },
    expenseTypesOptions() {
      return [this.allTypes, ...this.expenseTypes];
    },
    selectedExpenseType() {
      const { types } = this.filtersVals;
      const isAllTypes = !types || types.length > 1;
      return isAllTypes ? this.allTypes.type : types[0];
    },
    hasResults() {
      return Object.keys(this.groupedDict).length > 0;
    },
  },
  methods: {
    ...mapActions('reports', [
      'fetchExpenseList',
      'fetchReportVenuesSearch',
      'fetchExpenseSingle',
      'fetchExpenseUsers',
      'fetchExpenseTypesByCategory',
      'exportExpenses',
      'deleteSingleExpense',
    ]),
    ...mapMutations('reports', [
      'UPDATE_FILTERS_EXPENSE',
      'SET_META_DATA',
    ]),
    async onExportExpenses() {
      try {
        this.isLoaderShown = true;
        const { data, headers } = await this.exportExpenses({ ...this.filtersVals });
        const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
        const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'Expenses';
        downloadAttachment(data, name);
        this.isLoaderShown = false;
      } catch (err) {
        this.isLoaderShown = false;
        // eslint-disable-next-line no-console
        console.error(err);
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    setRouteSingle(id) {
      if (this.$route.name === this.routeNameSingle) {
        return;
      }

      this.$router.replace({
        name: this.routeNameSingle,
        params: { id },
      });
    },
    setRouteList() {
      this.$router.replace({
        name: this.routeName,
      });
    },
    async openSingleView(expense) {
      const { id } = expense;
      this.expenseId = id;

      const res = await this.fetchExpenseSingle({ id });
      const { data } = res.data;
      const { todo } = data;

      this.singleView = new AccountDrawerData({
        title: 'Expense Info',
        badge: data.type,
        accountOwner: data.user,
        dateLabel: 'Date & Time',
        dateTime: data.createdAt,
        noteTitle: 'Note',
        note: data.note,
        invoices: data.invoices && data.invoices.length ? data.invoices : null,
        secondaryNoteTitle: todo && 'To-Do Note',
        secondaryNote: todo && todo.note,
        icon: { text: 'Expense Info', icon: 'money-bill-wave' },
        totalLabel: 'Total',
        total: data.amount,
        taxIncluded: data.taxIncluded,
        deleteEnabled: data.canDelete,
        contactInfoCopy: `${this.$t('global.venue')} Info`,
        contactInfoData: formatContactInfoForVenue(data.venue),
      });
      const { note, invoices } = this.singleView;
      this.singleView.contentTitle = note || invoices ? 'Additional Details' : null;

      this.setRouteSingle(id);
      this.$refs.accountDrawer.openDrawer();
    },
    openDeleteModal(id) {
      this.expenseId = id;
      this.$refs.confirmationModal.open();
    },
    closeDeleteModal() {
      this.$refs.confirmationModal.close();
    },
    async deleteExpense() {
      this.closeDeleteModal();
      await this.deleteSingleExpense({ id: this.expenseId });
      this.$refs.accountDrawer.close();

      this.list = await this.fetchList();

      flash.success({
        title: 'Expense removed!',
        message: 'You have successfully removed this expense.',
      });
    },
    async onLoadMore() {
      const list = await this.fetchList({ loadingKey: this.loadingKeyMore });
      this.list = [...this.list, ...list];
    },
    async selectAccountOwner(accountOwner) {
      const { id } = accountOwner;
      this.UPDATE_FILTERS_EXPENSE({ userId: id });
      this.filtersVals = {
        ...this.filtersVals,
        userId: id,
      };
      this.meta = {};
      this.list = await this.fetchList();
    },
    async selectExpenseType(typeObj) {
      const { id } = typeObj;
      const types = id ? [id] : (await this.fetchTypes()).map(type => type.id);
      this.UPDATE_FILTERS_EXPENSE({ types });
      this.filtersVals = {
        ...this.filtersVals,
        types,
      };
      this.meta = {};
      this.list = await this.fetchList();
    },
    async selectDateRange(range) {
      this.UPDATE_FILTERS_EXPENSE({ range });
      this.range = range;
      this.filtersVals = {
        ...this.filtersVals,
        from: dayjs(this.range.start).toISOString(),
        to: dayjs(this.range.end).toISOString(),
      };

      this.meta = {};
      this.list = await this.fetchList();
    },
    onRangeNameChange(rangeName) {
      this.rangeName = rangeName;
      this.UPDATE_FILTERS_EXPENSE({ rangeName });
    },
    async fetchTypes() {
      const { data } = await this.fetchExpenseTypesByCategory();
      return data.data.map(type => ({
        id: type.key,
        name: capitalizeEveryWord({
          string: type.name,
        }),
      }));
    },
    async fetchVenueOptions() {
      const { data } = await this.fetchReportVenuesSearch();
      return data.data;
    },
    async fetchUsers() {
      try {
        const res = await this.fetchExpenseUsers();
        return res.data.data;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        flash.error({
          title: `Something went wrong with fetching options for ${this.$t('global.accountReps')}.`,
        });
        return [];
      }
    },
    async fetchList({ loadingKey = null } = {}) {
      const category = CHECK_IN_EXPENSE;
      const {
        nextId,
        nextValue,
      } = this.meta;
      const res = await this.fetchExpenseList({
        ...this.filtersVals,
        nextId,
        nextValue,
        category,
        ...(loadingKey ? { loadingKey } : null),
      });
      const {
        data,
        meta,
      } = res.data;

      this.meta = meta;

      this.SET_META_DATA({
        subtitle: `${meta.totalCount} expenses tracked`,
        label: 'TOTAL MONEY SPENT',
        count: Number.isFinite(meta.totalAmount) ? formatPrice(meta.totalAmount) : null,
      });

      return data;
    },
  },
  async created() {
    const {
      range,
      rangeName,
      userId,
      types,
    } = this.filtersExpense;

    this.range = range;
    this.rangeName = rangeName;

    this.isLoaderShown = true;

    try {
      this.expenseTypes = await this.fetchTypes();
      this.filtersVals = {
        ...this.filtersVals,
        from: dayjs(range.start).toISOString(),
        to: dayjs(range.end).toISOString(),
        userId,
        types: types || this.expenseTypes.map(type => type.id),
      };

      [this.accountOwnerOptions, this.venuesOptions, this.list] = await Promise.all([
        this.fetchUsers(),
        this.fetchVenueOptions(),
        this.fetchList(),
      ]);

      this.accountOwnerOptions = [this.allAccounts, ...this.accountOwnerOptions];
      if (!this.$permission.has('filterAccountOwners')) {
        this.filtersVals.userId = this.accountOwnerOptions[1].id;
      }

      this.isLoaderShown = false;

      const { singleId } = this;
      if (Number.isFinite(singleId)) {
        await this.openSingleView({ id: singleId });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      flash.error({
        title: 'Something went wrong!',
      });
    }
  },
};
</script>

<template>
  <div>
    <div class="expense-tracking__controls mt-24">
      <div class="expense-tracking__filters">
        <ez-drop-down
          v-if="$permission.has('seeCRMForAll')"
          :disabled="dropdownDisabled"
          :placeholder="allAccounts.name"
          :data="accountOwnerOptions"
          :selected="filtersVals.userId"
          name="accountOwnerId"
          @change="selectAccountOwner"
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.BUTTON__SELECT_ACCOUNT_REP"
        />

        <ez-drop-down
          placeholder="All Types"
          :data="expenseTypesOptions"
          :selected="selectedExpenseType"
          name="expenseTypes"
          @change="selectExpenseType"
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.BUTTON__SELECT_TYPE"
        />

        <v-date-picker
          name="range"
          label="Range"
          v-model="range"
          rangeMode
          hideLabel
          withPredefinedRanges
          :numberOfCalendars="2"
          :selectPredefinedRange="rangeName"
          @rangeNameChange="onRangeNameChange"
          @dateChange="selectDateRange"
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.BUTTON__SELECT_DATE"
        />
      </div>

      <div class="expense-tracking__actions">
        <ez-button
          type="secondary"
          formType="button"
          customClass="upload-csv"
          @click="onExportExpenses"
          v-if="$permission.has('exportExpenses')"
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.BUTTON__EXPORT_EXPENSES"
        >
          Export Expenses
        </ez-button>
        <ez-button
          @click="$router.push({ name: 'account-expense-new' })"
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.BUTTON__ADD_EXPENSE"
        >
          Add Expense
        </ez-button>
      </div>
    </div>
    <div class="account-check-in__table" v-if="hasResults">
      <div v-for="(group, key) in groupedDict" :key="key">
        <h3 class="expense-tracking__group-title mt-24 mb-16">
          {{ group.title }}
        </h3>
        <ez-table
          :data="group.items"
          :columns="['account', 'type', 'dateTime', 'amount']"
          :headers="{
            account: () => `${$t('global.accountRep')}`,
            type: () => 'Expense Type',
            dateTime: () => 'Date & Time',
            amount: () => 'Money Spent'
          }"
          :column-props="{
            amount: { class: 'price-cell' }
          }"
          @rowClick="openSingleView"
        >
          <template #cell-account="{row}">
            <v-account-owner-entity-info
              :account-owner="row.user"
              :data-cy="`${supplierCy.CRM.EXPENSE_TRACKING.TEXT__ACCOUNT_REP}-${row.id}`"
            />
          </template>
          <template #cell-type="{row}">
            <status-badge
              :status="row.type"
              :data-cy="`${supplierCy.CRM.EXPENSE_TRACKING.TEXT__TYPE}-${row.id}`"
            />
          </template>
          <template #cell-dateTime="{row}">
            <span :data-cy="`${supplierCy.CRM.EXPENSE_TRACKING.TEXT__DATE}-${row.id}`">
              {{ row.createdAt | dateTime }}
            </span>
          </template>
          <template #cell-amount="{row}">
            <status-badge
              v-if="!row.amount"
              :status="noSpendingsStatus"
              :data-cy="`${supplierCy.CRM.EXPENSE_TRACKING.TEXT__MONEY_SPENT}-${row.id}`"
            />
            <span
              v-else
              :data-cy="`${supplierCy.CRM.EXPENSE_TRACKING.TEXT__MONEY_SPENT}-${row.id}`"
            >
              {{ row.amount | price }}
            </span>
          </template>
        </ez-table>
      </div>

      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner/>
      </div>

      <ez-load-more
        v-if="!isLoadingMore && meta.nextId"
        @loadMore="onLoadMore"
      />
    </div>
    <empty-state class="mt-48" v-else>
      <template #badge><img src="@/assets/no-products.svg" alt=""></template>
      <template #title>No expenses for selected filters.</template>
      <template #info>Try with different filtering options.</template>
    </empty-state>

    <account-drawer
      ref="accountDrawer"
      :badge="singleView.badge"
      :venue="singleView.venue"
      :date-label="singleView.dateLabel"
      :date-time="singleView.dateTime"
      :account-owner="singleView.accountOwner"
      :content-title="singleView.contentTitle"
      :note-title="singleView.noteTitle"
      :note="singleView.note"
      :secondary-note-title="singleView.secondaryNoteTitle"
      :secondary-note="singleView.secondaryNote"
      :invoices="singleView.invoices"
      :contact-info-data="singleView.contactInfoData"
      :contact-info-copy="singleView.contactInfoCopy"
      :total="singleView.total"
      :total-label="singleView.totalLabel"
      :tax-included="singleView.taxIncluded"
      :delete-enabled="singleView.deleteEnabled"
      :logo-icon="singleView.icon"
      :data-cy="supplierCy.CRM.EXPENSE_TRACKING.SINGLE_EXPENSE"
      @onClose="setRouteList"
      @deletePending="openDeleteModal(expenseId)"
    />

    <ez-loader :show="isLoaderShown || isLoading">Loading...</ez-loader>

    <ez-confirmation-modal ref="confirmationModal" type="red">
      <template #title>Remove this Expense?</template>
      <template #content>
        <p>
          You are about to remove this expense from your list.
        </p>
      </template>
      <template #footer>
        <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="deleteExpense" type="red">Remove Expense</ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<style scoped lang="scss">

:deep() .invoice {
  padding: 0;
}

.expense-tracking__group-title {
  @include font-size(18px, 24px);
}

.expense-tracking__controls {
  @extend %flex-center;
  justify-content: space-between;
}

.expense-tracking__filters {
  @extend %flex-center;
  & > * {
    margin-right: 16px;
  }
}

.expense-tracking__actions {
  @extend %flex-center;
  & > * {
    margin-left: 16px;
  }
}
</style>
