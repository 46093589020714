var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-container"},[_c('ez-table',{attrs:{"disableHover":"","data":_vm.products,"headers":{
      name: () => 'Product',
      quantity: () => 'Qty',
      total: () => 'Total Amount',
    },"columns":['name', 'quantity', 'unit', 'total'],"column-props":{
      name: { class: 'width-100-cell' },
      quantity: { class: 'qty-cell text-right-cell' },
      unit: { class: 'unit-cell text-right-cell' },
      total: { class: 'price-cell padding-right-price-cell' },
    }},scopedSlots:_vm._u([{key:"cell-name",fn:function({ row }){return [_c('v-product-entity-info',{attrs:{"product":row}})]}},{key:"cell-quantity",fn:function({ row }){return [_vm._v(" "+_vm._s(row.count)+" ")]}},{key:"cell-unit",fn:function({ row: { orderingUnit } }){return [(orderingUnit)?[_c('v-data-with-info',{attrs:{"info":orderingUnit.abbreviation ? `${orderingUnit.name}` : '',"show-underline":!!orderingUnit.abbreviation}},[_vm._v(" "+_vm._s(orderingUnit.label)+" ")])]:_vm._e()]}},{key:"cell-total",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("price")(row.amount))+" ")]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoadingMore),expression:"isLoadingMore"}],staticClass:"u-text-center mt-12"},[_c('ez-spinner')],1),(!_vm.isLoadingMore && _vm.nextIdProducts)?_c('ez-load-more',{on:{"loadMore":_vm.onLoadMoreProducts}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }