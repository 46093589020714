import i18n from '@/i18n';

export const NEW_ORDER_STEP_1 = 0;
export const NEW_ORDER_STEP_2 = 1;
export const NEW_ORDER_STEP_3 = 2;

const steps = {
  [NEW_ORDER_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/orders/new/SelectVenue.vue'),
    title: `Choose a ${i18n.t('global.venue')} - ${i18n.t('global.venue')}`,
    pageTitle: `Please choose a ${i18n.t('global.venue').toLowerCase()}`,
    pageInfo: `Choose a ${i18n.t('global.venue').toLowerCase()} that is going to receive this order.`,
    nextCta: 'Next Step',
  },
  [NEW_ORDER_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/orders/new/SelectProducts.vue'),
    title: 'Add Products',
    pageTitle: 'And now select products',
    pageInfo: `Select products that will be sent to this ${i18n.t('global.venue').toLowerCase()}`,
    nextCta: 'Go to Overview',
  },
  [NEW_ORDER_STEP_3]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/orders/new/overview/Index.vue'),
    title: `Create ${i18n.t('global.venue')} - Overview`,
    pageTitle: 'Overview',
    nextCta: 'Submit',
  },
};

export default steps;
