<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { LOADING_KEY } from '@/util/constants';
import { clone } from '@/util/utils';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzButton from '@/components/ui/Button';
import EzInput from '@/components/ui/Input';

/**
 * AddLocationModal
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'AddLocationModal',
  components: { EzFormModal, EzButton, EzInput },
  data() {
    return {
      supplierCy,
      hasDuplicates: false,
      warehouse: {},
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    ...mapState('entities/products', ['inventoryManagement']),
    updateWarehouseLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_UPDATE_WAREHOUSE);
    },
    hasMadeChanges() {
      const origWarehouse = JSON.stringify(this.inventoryManagement.warehouse.to);
      const currWarehouse = JSON.stringify(this.warehouse);
      return origWarehouse !== currWarehouse;
    },
    hasInvalidLocations() {
      return !!this.warehouse.locations?.some(l => !l.code);
    },
    updateWarehouseDisabled() {
      if (!this.hasMadeChanges) return true;
      return this.hasInvalidLocations || !this.formValid;
    },
    formValid() {
      if (this.hasDuplicates) return false;
      return !this.warehouse.locations?.some(l => !l.code);
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorUpdateWarehouse']),
    ...mapMutations('entities/products', ['UPDATE_INVENTORY_MANAGEMENT_DRAFT']),
    open(warehouse) {
      this.warehouse = clone(warehouse);
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.$emit('close');
    },
    isNewLocation(location) {
      return !location.id;
    },
    addLocation() {
      this.warehouse.locations.push({ code: '' });
    },
    updateLocation(idx, val) {
      this.hasDuplicates = false;
      this.warehouse.locations.splice(idx, 1, {
        ...this.warehouse.locations[idx],
        code: val.trim(),
      });
    },
    hasError(idx, code) {
      if (!code) return '';

      const foundIdx = this.warehouse.locations.findIndex((l, i) => l.code === code && i !== idx);
      if (foundIdx === -1 || foundIdx > idx) return '';

      this.hasDuplicates = true;
      return 'Location with this name already exists';
    },
    removeLocation(idx) {
      this.warehouse.locations.splice(idx, 1);
    },
    async updateWarehouse() {
      const { data } = await this.distributorUpdateWarehouse({
        id: this.warehouse.id,
        body: this.warehouse,
      });
      this.UPDATE_INVENTORY_MANAGEMENT_DRAFT({
        warehouse: { ...this.inventoryManagement.warehouse, to: clone(data.data) },
      });
      this.close();
      this.$emit('success');
    },
  },
};
</script>

<template>
  <ez-form-modal ref="modal" @close="onClose">
    <template #title>Edit {{ warehouse.name }}</template>
    <template #content>
      <div class="single-location mt-4" v-for="(location, idx) in warehouse.locations" :key="idx">
        <ez-button
          v-if="isNewLocation(location)"
          class="remove-location-btn"
          type="link"
          @click="removeLocation(idx)"
          :dataCy="
            `${supplierCy.INVENTORY_FLOWS.TRANSFER.LOCATIONS.ADD_LOCATION.BUTTON__REMOVE}-${idx}`
          "
        >
          <font-awesome-icon icon="times" /> Remove
        </ez-button>
        <ez-input
          formKey=""
          class="mt-12"
          label="Location"
          :key="`location-${idx}`"
          :name="`location-${idx}`"
          :value="location.code"
          :errorMsg="hasError(idx, location.code)"
          :disabled="!isNewLocation(location)"
          @onChange="updateLocation(idx, $event)"
          :dataCy="
            `${supplierCy.INVENTORY_FLOWS.TRANSFER.LOCATIONS.ADD_LOCATION.INPUT__NAME}-${idx}`
          "
        />
      </div>
      <ez-button
        class="mt-16"
        type="secondary"
        :disabled="hasInvalidLocations"
        @click="addLocation"
        isFullWidth
        :data-cy="supplierCy.INVENTORY_FLOWS.TRANSFER.LOCATIONS.ADD_LOCATION.BUTTON__ADD"
      >
        <font-awesome-icon icon="plus" /> Add Location
      </ez-button>
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close"
        :data-cy="supplierCy.INVENTORY_FLOWS.TRANSFER.LOCATIONS.ADD_LOCATION.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        formType="button"
        @click="updateWarehouse"
        :disabled="updateWarehouseDisabled"
        :isLoading="updateWarehouseLoading"
        :data-cy="supplierCy.INVENTORY_FLOWS.TRANSFER.LOCATIONS.ADD_LOCATION.BUTTON__SAVE"
      >
        Save Changes
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.single-location {
  position: relative;

  .remove-location-btn {
    @include font-size(12px, 18px);
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    padding: 0;
  }
}
</style>
