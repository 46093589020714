<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import Paper from '@/components/layout/WizardOverviewPaper';
import EzMultipleImageUpload from '@/components/ui/ImageUpload/EzMultipleImageUpload';
import EzButton from '@/components/ui/Button';
import { mapState, mapActions, mapGetters } from 'vuex';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader';
import { EXPENSE_STEP_1, EXPENSE_STEP_2 } from '@/views/platform/distributor/crm/expense-tracking/new-expense/steps';

export default {
  name: 'Overview',
  mixins: [wizardListenerMixin],
  components: {
    Paper,
    EzButton,
    EzMultipleImageUpload,
    EzLoader,
  },
  data() {
    return {
      loading: false,
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/distributors', ['expense']),
    ...mapGetters('entities/distributors', ['getExpense']),
  },
  methods: {
    ...mapActions('entities/distributors', ['createCheckIn']),
    editInfo() {
      this.$emit('stepBack', EXPENSE_STEP_1);
    },
    editExpense() {
      this.$emit('stepBack', EXPENSE_STEP_2);
    },
    async onNextStep() {
      this.loading = true;
      try {
        const { data } = await this.createCheckIn({
          data: this.getExpense,
        });
        const { id } = data.data;
        await this.$nextTick();
        this.loading = false;
        const { href } = this.$router.resolve({ name: 'distributor-expense-tracking-single', params: { id } });
        this.$router.push({
          name: 'distributor-expense-tracking',
        });
        flash.success({
          title: 'Expense successfully added!',
          message: 'You can see the overview of your expense in Expense Details.',
          ctas: `<a href="${href}">View Expense Details</a>`,
        });
      } catch (e) {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.enableNextStep();
  },
};
</script>
<template>
  <div>
    <paper class="expense-info">
      <template #title>Expense Info</template>
      <template #actions>
      <ez-button
        type="link"
        formType="button"
        @click="editInfo"
        :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.OVERVIEW.BUTTON__EDIT_INFO"
      >
        <font-awesome-icon icon="pen" />
        <span>Edit</span>
      </ez-button>
      </template>
      <div class="info info--vertical">
        <small>Expense Type</small>
        <span
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.OVERVIEW.TEXT__TYPE"
        >
          {{ expense.type.name }}
        </span>
      </div>
      <div class="info info--vertical">
        <small>Date & Time</small>
        <span
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.OVERVIEW.TEXT__DATE_TIME"
        >
          {{ expense.datetime | dateTime }}
        </span>
      </div>
      <div v-if="expense.note" class="info info--vertical">
        <small>Note from Account Visit</small>
        <span
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.OVERVIEW.TEXT__NOTE"
        >
          {{ expense.note }}
        </span>
      </div>
    </paper>
    <paper>
      <template #title>Expense Details</template>
      <template #actions>
      <ez-button
        type="link"
        formType="button"
        @click="editExpense"
        :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.OVERVIEW.BUTTON__EDIT_DETAILS"
      >
        <font-awesome-icon icon="pen" />
        <span>Edit</span>
      </ez-button>
      </template>
      <div class="info info--vertical">
        <small>Money Spent</small>
        <span
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.OVERVIEW.TEXT__MONEY_SPENT"
        >
          {{ expense.moneySpent | price }}
        </span>
      </div>
      <div class="info info--vertical">
        <small>Tax</small>
        <span
          v-if="expense.taxRate.id === 1"
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.OVERVIEW.TEXT__TAX"
        >
          GST Included
        </span>
        <span
          v-else
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.OVERVIEW.TEXT__TAX"
        >
          No Tax
        </span>
      </div>
    </paper>
    <paper v-if="expense.receipts.length">
      <template #title>Receipts ({{ expense.receipts.length }})</template>
      <ez-multiple-image-upload
        ref="imageUpload"
        accept="image/jpeg,image/png,application/pdf"
        formKey="receipt"
        :card-type="true"
        :editable="false"
        :value="expense.receipts"
        name="invoice"
        class="upload-component"
        :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.OVERVIEW.IMG__RECEIPT"
      />
    </paper>
    <ez-loader :show="loading">Loading...</ez-loader>
  </div>
</template>
<style lang="scss" scoped>
:deep() .loader {
  @extend %whole-screen;
}

.check-in-info :deep() .entity-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .entity-info__info {
    justify-content: center;
    font-size: 16px;
  }
}

.upload-component {
  :deep() .preview__images {
    display: flex;
    flex-direction: column;

    .preview__image {
      border: none;
      box-shadow: none
    }
  }
  :deep() .preview__image + .preview__image {
    margin-left: 0;
  }
}
</style>
