<script>
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import { getDateFormat, formatDate } from '@/util/utils';

/**
 *
 * @version 1.0.0
 * @since 3.11.1
 */

const Types = {
  1: 'available',
  2: 'urgent',
  3: 'unavailable',
};

export default {
  name: 'VProvinoScheduleSlotPicker',
  components: {
    EzSimpleDropdown,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Set Schedule Slot',
    },
    selected: {
      type: Object,
      required: false,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      expanded: false,
      dates: [],
      slots: [],
      types: Types,
      activeDate: null,
      activeRow: null,
      selectedCopy: '',
    };
  },
  computed: {
    hasSelected() {
      return Object.keys(this.selected).length;
    },
  },
  watch: {
    data() {
      this.initPicker();
    },
  },
  methods: {
    initPicker() {
      const { dates, slots } = this.data;
      const dateFormat = getDateFormat().replace('/YYYY', '');
      this.dates = dates.map(date => formatDate(date, `ddd ${dateFormat}`));
      this.slots = slots.map(slot => ({ cells: slot.cells, id: +slot.id }));

      if (this.hasSelected) {
        const { date, time, slotId } = this.selected;
        const foundSlot = this.slots.find(slt => slt.id === slotId);
        const cell = {
          date,
          slot_name: time,
          slot_state: foundSlot?.cells?.find(cl => cl.date === date).slot_state,
          is_selectable: foundSlot?.cells?.find(cl => cl.date === date).is_selectable,
          requested: foundSlot?.cells?.find(cl => cl.date === date).requested,
        };
        const row = {
          id: slotId,
        };

        if (!row.id || !cell.slot_state) {
          this.selectedCopy = '';
          return;
        }

        this.selectSlot(cell, row, null);
      }
    },
    onDropdownOpen() {
      this.expanded = true;
    },
    onDropdownClose() {
      this.expanded = false;
    },
    selectSlot(cell, row, e) {
      /**
       * this.types[3] = 'unavailable' slot, look const Types at the top of the page
       */
      if (this.types[cell.slot_state] === this.types[3] || !cell.is_selectable) {
        if (e) {
          e.stopPropagation();
        }
        return;
      }
      this.activeDate = cell.date;
      this.activeRow = row.id;

      const selectedDate = formatDate(cell.date, `ddd, ${getDateFormat()}`);
      this.selectedCopy = `${selectedDate} at ${cell.slot_name}`;

      const selectedSlot = {
        deliveryDate: cell.date,
        deliveryTimeslotId: row.id,
        type: this.types[cell.slot_state],
        slotState: cell.slot_state,
        selectedCopy: this.selectedCopy,
        requested: cell.requested,
      };
      this.$emit('slotSelected', selectedSlot);
    },
    isUnselectable(cell) {
      return this.types[cell.slot_state] !== this.types[3] && !cell.is_selectable;
    },
  },
  created() {
    this.initPicker();
  },
};
</script>

<template>
  <ez-simple-dropdown
    class="schedule-slot-picker"
    @open="onDropdownOpen"
    @close="onDropdownClose"
    :disabled="disabled"
    :label="label"
  >
    <template #display>
      {{ selectedCopy || placeholder }}
      <span :class="{ 'calendar-active': expanded }">
        <font-awesome-icon :icon="['far', 'calendar-alt']" />
      </span>
    </template>
    <template #dropdown>
      <div>
        <div class="schedule-data">
          <div class="dates">
            <div
              v-for="date in dates"
              :key="date"
              class="dates__cell cell"
            >
              {{ date }}
            </div>
          </div>
          <div class="slots">
            <div
              v-for="row in slots"
              :key="row.id"
              class="slots__row"
            >
              <div
                v-tooltip="{
                  placement: 'top',
                  content: isUnselectable(cell) ? 'This time slot currently doesn’t have any remaining order capacity' : '',
                  classes: ['tooltip-general', 'tooltip--reset-margin'],
                }"
                v-for="(cell) in row.cells"
                :key="cell.date"
                @click="(e) => selectSlot(cell, row, e)"
                :class="[
                  'slots__row__cell',
                  `slots__row__cell--${types[cell.slot_state]}`,
                  {[`slots__row__cell--${types[cell.slot_state]}--disabled`]: isUnselectable(cell)},
                  'cell',
                  {[`slots__row__cell--${types[cell.slot_state]}--active`]: (activeDate === cell.date && activeRow === row.id)},
                ]">
                {{ cell.slot_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ez-simple-dropdown>
</template>

<style lang="scss" scoped>
.schedule-slot-picker {
  width: 100%;

  :deep() > label {
    text-transform: none;
  }

  .schedule-data {
    padding: 12px 16px;
    display: inline-flex;
    flex-direction: column;
    .dates {
      display: inline-flex;
      &__cell {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 104px;
        @include font-size(12px, 18px);
        color: $color-gray-6C;
      }
    }
    .slots {
      display: inline-flex;
      flex-direction: column;
      margin-top: 8px;

      &__row {
        display: inline-flex;
        &__cell {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 104px;
          height: 36px;
          @include font-size(14px, 20px);
          border-radius: 3px;
          font-weight: 600;
          cursor: pointer;

          &--unavailable {
            background-color: $color-gray-F5;
          }
          &--available {
            color: $color-gray-6C;
            border: 1px solid #ECEEF5;
            &--active {
              background-color: $color-pastel-blue;
              border-color: $color-primary-blue;
              color: $color-primary-blue;
            }
            &--disabled {
              border-color: #eceef5;
              opacity: .48;
              cursor: not-allowed;
            }
          }
          &--urgent {
            border: 1px solid $color-primary-yellow;
            color: $color-primary-yellow;
            &--active {
              background-color: $color-pastel-yellow;
            }
            &--disabled {
              border-color: #FBDE7B;
              opacity: .48;
              cursor: not-allowed;
            }
          }
        }
        & + .slots__row {
          margin-top: 4px;
        }
      }
    }
    .cell {
      & + .cell {
        margin-left: 4px;
      }
    }
  }
  .calendar-active {
    svg {
      color: $color-primary-blue;
    }
  }
  :deep() .ez-simple-dropdown {
    &__display-container {
      &--active {
        background-color: #fff;
        border: 2px solid #416DE8;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
