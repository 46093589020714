<script>
import uuid from 'uuid/v4';
import { mapGetters } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { LOADING_KEY } from '@/util/constants';
import { clone } from '@/util/utils';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzForm from '@/components/ui/Form';
import EzTabs from '@/components/ui/Tabs/EzTabs.vue';
import EzTab from '@/components/ui/Tabs/EzTab.vue';
import EzInput from '@/components/ui/Input';
import EzOnOff from '@/components/ui/OnOff';
import EzButton from '@/components/ui/Button';
/**
 * EditWarehouseModal
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'EditWarehouseModal',
  props: {},
  components: { EzFormModal, EzForm, EzTabs, EzTab, EzInput, EzOnOff, EzButton },
  data() {
    return {
      supplierCy,
      formValid: false,
      primaryToggleDisabled: false,
      initialValues: null,
      warehouseId: null,
      values: {
        name: null,
        street: null,
        city: null,
        zipCode: null,
        country: null,
        state: null,
        email: null,
        phone: null,
        primary: false,
        includedForCustomer: false,
        isProvino: false,
        locations: [],
        contactName: null,
      },
      requiredValues: {
        name: null,
        street: null,
        city: null,
        country: null,
      },
      formKey: 'edit-warehouse-form',
      keyId: null,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    hasInvalidLocations() {
      return this.values.locations?.some(l => !l.code);
    },
    hasMadeChanges() {
      return JSON.stringify(this.initialValues) !== JSON.stringify(this.values);
    },
    isLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_UPDATE_WAREHOUSE);
    },
  },
  methods: {
    async open(data, id) {
      this.formValid = false;
      this.values = clone(data);
      this.initialValues = clone(data);
      this.primaryToggleDisabled = data.primary;
      this.warehouseId = id;

      Object.keys(this.requiredValues).forEach((key) => {
        this.requiredValues[key] = true;
      });

      this.keyId = uuid();
      this.changeTab('mainInfo');
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.$refs.form.reset();
      this.$refs.form.clearErrors();
    },
    changeTab(tab) {
      this.$refs.tabs.selectTab(tab);
    },
    submitForm() {
      this.$refs.form.onSubmit();
    },
    async validateForm() {
      await this.$nextTick();
      this.formValid = true;

      Object.keys(this.requiredValues).forEach((key) => {
        if (!this.requiredValues[key]) this.formValid = false;
      });

      if (this.hasInvalidLocations) this.formValid = false;
      if (!this.hasMadeChanges) this.formValid = false;
    },
    async updateFormState(name, value) {
      const isToggleInput = ['primary', 'includedForCustomer', 'isProvino'].includes(name);
      this.values[name] = isToggleInput ? value : value.trim();
      if (!isToggleInput && name !== 'locations') {
        this.requiredValues[name] = await this.$validator.validate(name, value);
      }
      this.validateForm();
    },
    addLocation() {
      this.values.locations = [...this.values.locations, { code: '' }];
      this.validateForm();
    },
    updateLocation(idx, value) {
      this.values.locations[idx].code = value.trim();
      this.validateForm();
    },
    removeLocation(idx) {
      this.values.locations = this.values.locations.filter((_, i) => i !== idx);
      this.validateForm();
    },
    transformer() {
      const trimmed = Object.entries(this.values).reduce(
        (acc, [key, val]) => ({
          ...acc,
          ...(typeof val === 'string' ? { [key]: val.trim() } : {}),
        }),
        {},
      );
      const { street, city, zipCode, country, state, ...rest } = trimmed;

      return {
        ...rest,
        address: { street, city, zipCode, country, state },
        locations: [...this.values.locations],
        primary: this.values.primary,
        includedForCustomer: this.values.includedForCustomer,
        isProvino: this.values.isProvino,
      };
    },
    hasError(idx, code) {
      if (!code) return '';

      const foundIdx = this.values.locations.findIndex((l, i) => l.code === code && i !== idx);

      if (foundIdx === -1) return '';
      if (foundIdx > idx) return '';

      this.formValid = false;
      return 'Location with this name already exists';
    },
    onSuccess() {
      this.$emit('success');
      this.close();
    },
    onError() {
      this.changeTab('mainInfo');
      this.$emit('error');
    },
  },
};
</script>

<template>
  <ez-form-modal ref="modal" @close="onClose">
    <template #title>Warehouse Details</template>
    <template #content>
      <ez-tabs ref="tabs">
        <ez-tab title="Main Info" hash="mainInfo">
          <ez-form
            :key="keyId"
            ref="form"
            method="put"
            :formKey="formKey"
            :action="`/distributor/warehouses/${warehouseId}`"
            :transformer="transformer"
            @success="onSuccess"
            @error="onError"
          >
            <ez-input
              :key="`${keyId}-name`"
              name="name"
              :formKey="formKey"
              label="Warehouse Name*"
              placeholder="Enter Name"
              validators="required"
              :value="values.name"
              @onChange="updateFormState('name', $event)"
              class="mt-16"
              :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.INPUT__NAME"
            />
            <ez-input
              :key="`${keyId}-street`"
              name="address[street]"
              :formKey="formKey"
              label="Street Address*"
              placeholder="Enter Street Address"
              validators="required"
              :value="values.street"
              @onChange="updateFormState('street', $event)"
              class="mt-12"
              :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.INPUT__STREET"
            />
            <div class="mt-12 u-flex-center two-column-inputs">
              <ez-input
                :key="`${keyId}-city`"
                name="address[city]"
                :formKey="formKey"
                label="City*"
                placeholder="Enter City"
                validators="required"
                :value="values.city"
                @onChange="updateFormState('city', $event)"
                :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.INPUT__CITY"
              />
              <ez-input
                :key="`${keyId}-zipCode`"
                name="address[zipCode]"
                :formKey="formKey"
                label="Zip Code"
                placeholder="Enter Zip Code"
                :value="values.zipCode"
                @onChange="updateFormState('zipCode', $event)"
                :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.INPUT__ZIP_CODE"
              />
            </div>
            <div class="mt-12 u-flex-center two-column-inputs">
              <ez-input
                :key="`${keyId}-country`"
                name="address[country]"
                :formKey="formKey"
                label="Country*"
                placeholder="Enter Country"
                validators="required"
                :value="values.country"
                @onChange="updateFormState('country', $event)"
                :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.INPUT__COUNTRY"
              />
              <ez-input
                :key="`state`"
                name="address[state]"
                :formKey="formKey"
                label="State"
                placeholder="Enter State"
                :value="values.state"
                @onChange="updateFormState('state', $event)"
                :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.INPUT__STATE"
              />
            </div>
            <hr />
            <ez-input
                :key="`${keyId}-contactName`"
                name="contactName"
                :formKey="formKey"
                label="Contact Name"
                placeholder="Enter Contact Name"
                :value="values.contactName"
                @onChange="updateFormState('contactName', $event)"
                class="mt-12"
                :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.INPUT__CONTACT_NAME"
            />
            <ez-input
              :key="`${keyId}-email`"
              name="email"
              :formKey="formKey"
              label="Email Address"
              placeholder="Enter Email Address"
              :value="values.email"
              @onChange="updateFormState('email', $event)"
              :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.INPUT__EMAIL"
              class="mt-12"
            />
            <ez-input
              :key="`${keyId}-phone`"
              name="phone"
              :formKey="formKey"
              label="Phone Number"
              placeholder="Enter Phone Number"
              :value="values.phone"
              @onChange="updateFormState('phone', $event)"
              :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.INPUT__PHONE"
              class="mt-12"
            />
            <hr />
            <div class="switch-option">
              <div class="help-block">
                <h4 class="help-block__title">Primary Warehouse</h4>
                <p class="help-block__text">
                  The warehouse where all orders are fulfilled from. <br />
                  This can be changed during order acceptance
                </p>
              </div>
              <ez-on-off
                :formKey="formKey"
                name="primary"
                :disabled="primaryToggleDisabled"
                :checked="values.primary"
                @change="updateFormState('primary', $event)"
                :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.BUTTON__IS_PRIMARY"
              />
            </div>
            <div class="switch-option">
              <div class="help-block">
                <h4 class="help-block__title">Include Inventory Levels</h4>
                <p class="help-block__text">
                  When enabled, stock from this warehouse will be included <br />
                  in the stock numbers available to customers.
                </p>
              </div>
              <ez-on-off
                :formKey="formKey"
                :key="`${keyId}-includedForCustomer`"
                name="includedForCustomer"
                :checked="values.includedForCustomer"
                @change="updateFormState('includedForCustomer', $event)"
              />
            </div>
            <div class="switch-option">
              <div class="help-block">
                <h4 class="help-block__title">Fulfill from Provino</h4>
              </div>
              <ez-on-off
                :formKey="formKey"
                :key="`${keyId}-isProvino`"
                name="isProvino"
                :checked="values.isProvino"
                @change="updateFormState('isProvino', $event)"
              />
            </div>
          </ez-form>
        </ez-tab>
        <ez-tab title="Locations" hash="locations">
          <div class="single-location mt-4" v-for="(location, idx) in values.locations" :key="idx">
            <ez-button class="remove-location-btn" type="link" @click="removeLocation(idx)">
              <font-awesome-icon icon="times" /> Remove
            </ez-button>
            <ez-input
              :key="`${keyId}-location-${idx}`"
              :name="`location-${idx}`"
              :formKey="formKey"
              label="Location"
              :value="location.code"
              :errorMsg="hasError(idx, location.code)"
              @onChange="updateLocation(idx, $event)"
              class="mt-12"
              :data-cy="`${supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.INPUT__LOCATION_NAME}-${idx}`"
            />
          </div>
          <ez-button
            class="mt-16"
            type="secondary"
            :disabled="hasInvalidLocations"
            @click="addLocation"
            isFullWidth
            :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.BUTTON__ADD_LOCATION"
          >
            <font-awesome-icon icon="plus" /> Add Location
          </ez-button>
        </ez-tab>
      </ez-tabs>
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close"
        :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        formType="button"
        @click="submitForm"
        :disabled="!formValid"
        :isLoading="isLoading"
        :data-cy="supplierCy.SETTINGS.WAREHOUSES.EDIT_WAREHOUSE.BUTTON__UPDATE"
      >
        Update Details
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
:deep() .ez-tabs {
  ul li:first-of-type button {
    margin-left: 0;
  }
}

.two-column-inputs {
  & > *:first-of-type {
    flex: 1 0 auto;
  }

  & > *:last-of-type {
    flex-basis: 144px;
    padding-left: 12px;
  }
}

.switch-option {
  display: flex;
  justify-content: space-between;

  .help-block {
    &__text {
      @include font-size(14px, 20px);
    }
  }
}

.single-location {
  position: relative;

  .remove-location-btn {
    @include font-size(12px, 18px);
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    padding: 0;
  }
}
</style>
