var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ez-simple-dropdown',{staticClass:"schedule-slot-picker",attrs:{"disabled":_vm.disabled,"label":_vm.label},on:{"open":_vm.onDropdownOpen,"close":_vm.onDropdownClose},scopedSlots:_vm._u([{key:"display",fn:function(){return [_vm._v(" "+_vm._s(_vm.selectedCopy || _vm.placeholder)+" "),_c('span',{class:{ 'calendar-active': _vm.expanded }},[_c('font-awesome-icon',{attrs:{"icon":['far', 'calendar-alt']}})],1)]},proxy:true},{key:"dropdown",fn:function(){return [_c('div',[_c('div',{staticClass:"schedule-data"},[_c('div',{staticClass:"dates"},_vm._l((_vm.dates),function(date){return _c('div',{key:date,staticClass:"dates__cell cell"},[_vm._v(" "+_vm._s(date)+" ")])}),0),_c('div',{staticClass:"slots"},_vm._l((_vm.slots),function(row){return _c('div',{key:row.id,staticClass:"slots__row"},_vm._l((row.cells),function(cell){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                placement: 'top',
                content: _vm.isUnselectable(cell) ? 'This time slot currently doesn’t have any remaining order capacity' : '',
                classes: ['tooltip-general', 'tooltip--reset-margin'],
              }),expression:"{\n                placement: 'top',\n                content: isUnselectable(cell) ? 'This time slot currently doesn’t have any remaining order capacity' : '',\n                classes: ['tooltip-general', 'tooltip--reset-margin'],\n              }"}],key:cell.date,class:[
                'slots__row__cell',
                `slots__row__cell--${_vm.types[cell.slot_state]}`,
                {[`slots__row__cell--${_vm.types[cell.slot_state]}--disabled`]: _vm.isUnselectable(cell)},
                'cell',
                {[`slots__row__cell--${_vm.types[cell.slot_state]}--active`]: (_vm.activeDate === cell.date && _vm.activeRow === row.id)},
              ],on:{"click":(e) => _vm.selectSlot(cell, row, e)}},[_vm._v(" "+_vm._s(cell.slot_name)+" ")])}),0)}),0)])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }