<template>
  <span v-tooltip="tooltip" class="tax-label" v-if="Number.isFinite(tax)">
    <font-awesome-icon icon="info-circle"/> <template v-if="hasText">Including Taxes</template>
    <span class="v-subtotal-info__total" :data-cy="dataCy">
      {{ isTbd ? $t('global.tbd') : $helpers.formatPrice(priceAfterTax, currency) }}
    </span>
  </span>
</template>

<script>
/**
 * Show more information about the final price (tax).
 * @version 1.0.0
 * @since 2.1.0
 * @deprecated 3.9.1 Replaced with VSubtotalInfo.
 */
export default {
  props: {
    tax: {
      required: true,
    },
    priceBeforeTax: {
      type: Number,
      required: true,
    },
    priceAfterTax: {
      type: Number,
      required: true,
    },
    deliveryFee: {
      type: Number,
      required: true,
    },
    taxAmount: {
      required: true,
      type: Number,
    },
    hasText: {
      type: Boolean,
      required: false,
      default: true,
    },
    isTbd: {
      type: Boolean,
      required: false,
      default: false,
    },
    currency: {
      type: Object,
      required: false,
      default: null,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    content() {
      return `
      <div class="tax">
        <div class="tax__item">
          <strong class="tax__label">Subtotal:</strong>
          <span class="tax__number">
            ${this.isTbd ? this.$t('global.tbd') : this.$helpers.formatPrice(this.priceBeforeTax, this.currency)}
          </span>
        </div>
        <div class="tax__item">
          <strong class="tax__label">Delivery Fee:</strong>
          <span class="tax__number">
            +${this.$helpers.formatPrice(this.deliveryFee, this.currency)}
          </span>
        </div>
        <div class="tax__item">
          <strong class="tax__label">Tax (${this.tax}%):</strong>
          <span class="tax__number">
            +${(this.isTbd && this.tax > 0) ? this.$t('global.tbd') : this.$helpers.formatPrice(this.taxAmount, this.currency)}
          </span>
        </div>
        <div class="tax__item tax__item--total">
          <strong class="tax__label">Total:</strong>
          <span class="tax__number">
            ${this.isTbd ? this.$t('global.tbd') : this.$helpers.formatPrice(this.priceAfterTax, this.currency)}
          </span>
        </div>
      </div>
`;
    },
    tooltip() {
      return {
        html: true,
        placement: 'top-end',
        classes: ['tooltip-general'],
        content: this.content,
      };
    },
  },
};
</script>

<style lang="scss">
.tax-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #6C7995;
  @include font-size(18px);
  font-weight: 600;
  vertical-align: middle;
}

.tax {
  min-width: 164px;
  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    &--total {
      padding-top: 6px;
      border-top: 1px solid rgba(#fff, .2);
    }
  }
}
</style>
