<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import dayjs from 'dayjs';

import { clone } from '@/util/utils';
import downloadAttachment from '@/util/downloadAttachment';
import {
  creditNoteStatusLabels,
  CREDIT_NOTE_DATE_FILTER_DEFAULT,
  LOADING_KEY,
} from '@/util/constants';

import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';

import FilterDropdown from './FilterDropdown.vue';
import CreditsNotesList from './CreditsNotesList.vue';
import CreditNoteDrawer from './single-credit-note/CreditNoteDrawer.vue';

/**
 * CreditNotes
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'CreditNotes',
  props: {
    creditNoteId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  components: {
    EzSimpleDropdown,
    EzButton,
    EzLoader,

    FilterDropdown,
    CreditsNotesList,
    CreditNoteDrawer,
  },
  data() {
    return {
      venues: [],
      creditNotes: [],
      meta: {},
      filters: {},
      statusFilters: {},
      dateFilterName: CREDIT_NOTE_DATE_FILTER_DEFAULT.name,
      dateFilter: {
        start: CREDIT_NOTE_DATE_FILTER_DEFAULT.from,
        end: CREDIT_NOTE_DATE_FILTER_DEFAULT.to,
      },
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_CREDIT_NOTES,
        LOADING_KEY.DISTRIBUTOR_FETCH_SINGLE_CREDIT_NOTE,
        LOADING_KEY.DISTRIBUTOR_EXPORT_CREDIT_NOTES_PDF,
        LOADING_KEY.DISTRIBUTOR_EXPORT_CREDIT_NOTES_CSV,
        LOADING_KEY.DISTRIBUTOR_EXPORT_CREDIT_NOTE,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_CREDIT_NOTES);
    },
    isLoadingExportPDF() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_EXPORT_CREDIT_NOTES_PDF);
    },
    isLoadingExportCSV() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_EXPORT_CREDIT_NOTES_CSV);
    },
    statusFilter() {
      return Object.entries(creditNoteStatusLabels).map(([status, label]) => ({
        id: status,
        name: label,
      }));
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorFetchVenueFilterOptions']),
    ...mapActions('creditNotes', [
      'distributorFetchCreditNotes',
      'distributorFetchCreditNotesTotal',
      'distributorExportCreditNotesPDF',
      'distributorExportCreditNotesCSV',
    ]),
    ...mapMutations('creditNotes', ['UPDATE_META']),
    async fetchCreditNotes(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_CREDIT_NOTES) {
      const requestParams = this.getRequestParams();

      const [{ data: creditNotesData }, { data: totalData }] = await Promise.all([
        this.distributorFetchCreditNotes({ ...requestParams, loadingKey }),
        this.distributorFetchCreditNotesTotal(requestParams),
      ]);

      this.creditNotes = clone([...this.creditNotes, ...creditNotesData.data]);
      this.meta = clone({ ...creditNotesData.meta, totalAmount: totalData.data });
      this.UPDATE_META({ meta: this.meta });
    },
    async fetchMoreCreditNotes() {
      await this.fetchCreditNotes(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_CREDIT_NOTES);
    },
    async fetchVenues() {
      const { data } = await this.distributorFetchVenueFilterOptions({
        query: { for: 'credit_notes' },
      });
      this.venues = data.data;
    },
    async exportPDF() {
      const requestParams = this.getRequestParams();

      const { data, headers } = await this.distributorExportCreditNotesPDF(requestParams);
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'template.pdf';
      downloadAttachment(data, name);
    },
    async exportCSV() {
      const requestParams = this.getRequestParams();

      const { data, headers } = await this.distributorExportCreditNotesCSV(requestParams);
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'template.xlsx';
      downloadAttachment(data, name);
    },
    getRequestParams() {
      const status = Object.keys(this.statusFilters)
        .map(s => `status=${s}`)
        .join('&');

      const query = {
        ...(this.filters.term ? { term: this.filters.term } : {}),
        ...(this.filters.venueId ? { venueId: this.filters.venueId } : {}),
        ...(this.filters.date ? { from: this.filters.date.from, to: this.filters.date.to } : {}),
        ...(this.meta?.nextId ? { nextId: this.meta.nextId } : {}),
      };

      return { status, query };
    },
    clearState() {
      this.creditNotes = [];
      this.meta = {};
    },
    async updateFilters(filters) {
      const statusFilterKeys = Object.keys(creditNoteStatusLabels);
      this.statusFilters = Object.entries(filters).reduce(
        (acc, [key, val]) => (statusFilterKeys.includes(key) && val ? { ...acc, [key]: val } : acc),
        {},
      );
      const hasStatusFilters = Object.keys(this.statusFilters).length;

      this.filters = {
        ...(filters.term ? { term: filters.term } : {}),
        ...(filters.venue ? { venueId: filters.venue.id } : {}),
        ...(hasStatusFilters ? this.statusFilters : {}),
        ...(filters.date
          ? {
            date: {
              from: dayjs(filters.date.start || filters.date.from).valueOf(),
              to: dayjs(filters.date.end || filters.date.to).valueOf(),
            },
          }
          : {}),
      };

      this.dateFilter = {
        start: dayjs(filters.date?.from || this.dateFilter?.start).valueOf(),
        end: dayjs(filters.date?.to || this.dateFilter?.end).valueOf(),
      };

      this.clearState();
      this.$nextTick(() => this.$refs.filterDropdown.closeFilterDropdown());
      await this.fetchCreditNotes();
    },
    async resetFilters() {
      this.filters = {};
      this.statusFilters = {};

      this.dateFilterName = CREDIT_NOTE_DATE_FILTER_DEFAULT.name;
      this.dateFilter = {
        start: dayjs(CREDIT_NOTE_DATE_FILTER_DEFAULT.from).valueOf(),
        end: dayjs(CREDIT_NOTE_DATE_FILTER_DEFAULT.to).valueOf(),
      };

      this.clearState();
      this.$nextTick(() => this.$refs.filterDropdown.syncLocalFilters());
      await this.fetchCreditNotes();
    },
    changeDateFilterName(rangeName) {
      this.dateFilterName = rangeName;
    },
    async onRowClick(row) {
      await this.$router.replace({ name: 'distributor-credit-notes', params: { id: row.id } });
      this.openCreditNoteDrawer();
    },
    addNewCreditNote() {
      return this.$router.push({ name: 'distributor-credit-notes-new' });
    },
    openCreditNoteDrawer() {
      this.$refs.creditNoteDrawer.open();
    },
    onCreditNoteDrawerClose() {
      this.$router.replace({ name: 'distributor-credit-notes' });
    },
    async cleanRefresh() {
      this.clearState();
      await this.fetchCreditNotes();
    },
  },
  async mounted() {
    await Promise.all([this.fetchCreditNotes(), this.fetchVenues()]);

    if (this.creditNoteId) this.openCreditNoteDrawer();
  },
};
</script>

<template>
  <div class="credit-notes">
    <div class="credit-notes__header-wrapper mt-24">
      <filter-dropdown
        ref="filterDropdown"
        :filters="filters"
        :statusFilter="statusFilter"
        :dateFilter="dateFilter"
        :dateFilterName="dateFilterName"
        :venues="venues"
        @filterUpdated="updateFilters"
        @resetFilters="resetFilters"
        @rangeNameChange="changeDateFilterName"
      />

      <div class="credit-notes__actions">
        <ez-simple-dropdown
          placeholder="Export"
          class="action-export"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.BUTTON__EXPORT"
        >
          <template #dropdown>
            <div class="action-export__actions">
              <ez-button
                type="link"
                :isLoading="isLoadingExportPDF"
                @click="exportPDF"
                :data-cy="supplierCy.ORDERS.CREDIT_NOTES.BUTTON__EXPORT_PDF"
              >
                PDF
              </ez-button>
              <ez-button
                type="link"
                :isLoading="isLoadingExportCSV"
                @click="exportCSV"
                :data-cy="supplierCy.ORDERS.CREDIT_NOTES.BUTTON__EXPORT_CSV"
              >
                CSV
              </ez-button>
            </div>
          </template>
        </ez-simple-dropdown>
        <ez-button
          class="action-new ml-16"
          @click="addNewCreditNote"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.BUTTON__ADD_NEW_CREDIT_NOTE"
        >
          Add New Credit Note
        </ez-button>
      </div>
    </div>

    <credits-notes-list
      :creditNotes="creditNotes"
      :isLoading="isLoading"
      :isLoadingMore="isLoadingMore"
      :meta="meta"
      @rowClick="onRowClick"
      @loadMore="fetchMoreCreditNotes"
    />

    <ez-loader :show="isLoading">Loading...</ez-loader>

    <credit-note-drawer
      ref="creditNoteDrawer"
      :creditNoteId="creditNoteId"
      @close="onCreditNoteDrawerClose"
      @approve="cleanRefresh"
      @update="cleanRefresh"
      @delete="cleanRefresh"
    />
  </div>
</template>

<style lang="scss" scoped>
.credit-notes {
  &__header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    .action-export {
      width: 100px;

      &__actions {
        :deep() .button {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}
</style>
