<template>
  <v-tag-select-search
    :selectedTags="selectedTags"
    :unselectedTags="unselectedTags"
    @change="onChange"
  />
</template>

<script>
import { mapState } from 'vuex';
import VTagSelectSearch from '@/components/v3/elements/VTagSelectSearch';

export default {
  name: 'EzProductTagsFilter',
  components: {
    VTagSelectSearch,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    selectedTags: {
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      updatedTags: [],
    };
  },
  computed: {
    ...mapState('tags', ['allTags']),
    selectedTagsIds() {
      return this.updatedTags.map(item => item.id);
    },
    unselectedTags() {
      return this.allTags.filter(item => this.selectedTagsIds.indexOf(item.id) === -1);
    },
  },
  methods: {
    onChange(value) {
      if (!value.isUnselect) {
        this.updatedTags.push(value.tag);
        this.updatedTags.sort((obj1, obj2) => obj1.priority - obj2.priority);
      } else {
        this.updatedTags = this.updatedTags.filter(item => item.id !== value.tag.id);
      }
      this.$emit('change', this.updatedTags);
    },
    reset() {
      this.updatedTags = [];
    },
  },
  created() {
    this.updatedTags = this.selectedTags;
  },
};
</script>

<style scoped lang="scss">
</style>
