<template>
  <div>
    <ez-form
      :action="formAction"
      :formKey="formKey"
      :method="formMethod"
      submitType="multipart"
      @success="onSubmitSuccess"
      @error="onSubmitError"
      ref="productForm"
      class="product-form">
      <section class="product-form__details">
        <ez-image-upload
          :formKey="formKey"
          :previewUrl="draft.image"
          :isDefaultImage="draft.isDefaultImage"
          :disabled="isTradeGecko"
          columnMode
          name="image"
          @removeImage="onRemoveImage"></ez-image-upload>
        <ez-input
          :formKey="formKey"
          name="name"
          label="Product name*"
          :value="draft.name"
          :disabled="isTradeGecko"
          placeholder="E.g. Elephant Sloe Gin 0.5L"></ez-input>
        <ez-input
          :formKey="formKey"
          name="sku"
          label="SKU number"
          :value="draft.sku"
          :disabled="isTradeGecko"
          placeholder="E.g. 09120000"></ez-input>
        <ez-dropdown
          :formKey="formKey"
          name="categoryId"
          label="Category"
          :selected="draft.categoryId"
          :data="listedCategories"
          :disabled="isTradeGecko"
          placeholder="Please select product category"></ez-dropdown>
        <ez-input
          :formKey="formKey"
          name="price"
          label="Price"
          :value="draft.price"
          :disabled="isTradeGecko">
          <template #prefix>
            <div>{{ currencySymbol }}</div>
          </template>
        </ez-input>
        <v-product-group-settings name="productGroupId" :selectedId="draft.productGroup?.id" />
        <ez-textarea
          :formKey="formKey"
          name="description"
          label="Description"
          :value="draft.description"
          :disabled="isTradeGecko"
          placeholder="E.g. It's very nice!"/>
        <div class="product-form__tier-label">
          <b>Tier Pricing</b> Optional
        </div>
        <ez-tiers
          name="defaultTierPricing"
          :formKey="formKey"
          :tier="draft.defaultTierPricing"
          @addTier="onAddDefaultTier"
          @removeTier="onRemoveDefaultTier"
          @tierChange="onDefaultTierChange"></ez-tiers>
      </section>
      <section class="product-form__special-tiers">
        <ez-special-tier-list
          :formKey="formKey"
          @tierClick="onEditSpecialTier"
          @addTier="onAddSpecialTier"
          @removeTier="onRemoveSpecialTier"></ez-special-tier-list>
      </section>
      <template #buttons>
        <slot name="buttons"></slot>
      </template>
    </ez-form>
    <ez-special-tier-modal
      ref="editSpecialTierModal"
      :title="title"
      :formKey="formKey"
      :tier="specialTierDraft"
      :specialTierIndex="specialTierIndex"></ez-special-tier-modal>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import EzDropdown from '@/components/ui/Dropdown';
import EzForm from '@/components/ui/Form';
import EzImageUpload from '@/components/ui/ImageUpload';
import EzInput from '@/components/ui/Input';
import { EzSpecialTierList, EzSpecialTierModal } from '@/views/common/special-tier';
import EzTiers from '@/components/ui/Tiers';
import Category from '@/models/Category';
import EzTextarea from '@/components/ui/Textarea';
import { getCurrency } from '@/util/utils';
import VProductGroupSettings from '@/components/v3/elements/VProductGroupSettings/index.vue';

export default {
  components: {
    EzDropdown,
    EzForm,
    EzImageUpload,
    EzInput,
    EzSpecialTierList,
    EzSpecialTierModal,
    EzTiers,
    EzTextarea,
    VProductGroupSettings,
  },
  props: {
    formAction: {
      type: String,
      required: true,
    },
    formKey: {
      type: String,
      required: true,
    },
    formMethod: {
      type: String,
      required: false,
      default: 'post',
    },
    productId: {
      type: [Number, String],
      required: false,
    },
    isTradeGecko: {
      type: Boolean,
      required: false,
      default: false,
    },
    categories: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isNewTier: false,
      specialTierIndex: null,
    };
  },
  computed: {
    title() {
      return this.isNewTier ? 'Add Venue Specific Pricing' : 'Edit Special Tier Prices';
    },
    listedCategories() {
      return this.categories ? this.categories : Category.query().where('parentId', v => !!v).all();
    },
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return symbol;
    },
    ...mapGetters('entities/products', [
      'getProduct',
      'getAllSpecialTierForProduct',
      'getSpecialTierDraftIndex',
    ]),
    ...mapState('entities/products', [
      'draft',
      'specialTierDraft',
    ]),
  },
  methods: {
    submitForm() {
      this.$refs.productForm.onSubmit();
    },
    onSubmitSuccess(response) {
      this.$emit('submitSuccess', response);
    },
    onSubmitError(response) {
      this.$emit('submitError', response);
    },
    onAddDefaultTier() {
      this.NEW_DEFAULT_TIER_ITEM();
    },
    onRemoveDefaultTier({ id }) {
      this.REMOVE_DEFAULT_TIER_ITEM(id);
    },
    onDefaultTierChange(payload) {
      this.UPDATE_DEFAULT_TIER(payload);
    },
    onRemoveImage() {
      const { productId } = this;
      const role = localStorage.getItem('role');

      if (productId > -1) {
        if (role === 'admin') {
          const { id } = this.$route.params;
          this.removeProductForDistributor({ distributorId: id, productId });

          return;
        }

        this.removeDistributorProductImage({ id: productId });
      }
    },
    onAddSpecialTier() {
      this.isNewTier = true;
      this.NEW_SPECIAL_TIER_DRAFT();
      this.$refs.editSpecialTierModal.open();
    },
    onEditSpecialTier(tier, index) {
      this.isNewTier = false;
      this.specialTierIndex = index;
      this.NEW_SPECIAL_TIER_DRAFT(tier);
      this.$refs.editSpecialTierModal.open();
    },
    onRemoveSpecialTier({ id }) {
      this.REMOVE_SPECIAL_TIER(id);
    },
    ...mapActions('entities/products', [
      'fetchDistributorProduct',
      'removeDistributorProductImage',
      'removeProductForDistributor',
      'fetchProductForDistributor',
    ]),
    ...mapMutations('entities/products', [
      'NEW_DRAFT',
      'CLEAR_DRAFT',
      'NEW_DEFAULT_TIER_ITEM',
      'REMOVE_DEFAULT_TIER_ITEM',
      'UPDATE_DEFAULT_TIER',
      'NEW_SPECIAL_TIER_DRAFT',
      'REMOVE_SPECIAL_TIER',
    ]),
  },
  mounted() {
    const { productId } = this;
    const role = localStorage.getItem('role');

    if (productId > -1) {
      if (role === 'admin') {
        const { id } = this.$route.params;
        this.fetchProductForDistributor({ id, productId })
          .then(() => this.NEW_DRAFT(productId));

        return;
      }
      this.fetchDistributorProduct({ id: productId })
        .then(() => this.NEW_DRAFT(productId));
    } else {
      this.NEW_DRAFT();
    }
  },
};
</script>

<style scoped lang="scss">
  .product-form {
    &__details {
      margin: 2rem;
      .input-group {
        + .input-group {
          margin-top: .75rem;
        }
      }
      :deep() .ez-image-upload {
        margin-bottom: 1rem;
        &__add {
          width: 15rem;
        }
      }
    }

    &__tier-label {
      @include font-size(18px);
      font-weight: 600;
      margin: 3.5em 0 1.3em 0;
      color: $label-color;

      b {
        font-weight: 600;
        color: $primary-color;
      }
    }

    &__special-tiers {
      @include font-size(12px);
      background-color: #F1F4F7;
      padding: 2rem;

      .product-form__tier-label {
        margin-top: 0;
      }

      :deep() &--add {
        @extend %button-add;
        margin-top: 1rem;
        width: 100%;
        max-width: 18em;
      }
    }

    .ez-dropdown {
      max-width: 100%;
    }
  }
</style>
