export const TRANSFER_STEP_1 = 0;
export const TRANSFER_STEP_2 = 1;
export const TRANSFER_STEP_3 = 2;
export const TRANSFER_STEP_4 = 3;

const steps = {
  [TRANSFER_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/transfer/Warehouses.vue'),
    title: 'Warehouses',
    pageTitle: 'Please choose warehouses',
    pageInfo: 'Please, select the warehouse from which you would like to transfer the products. Select the warehouse to which the products will move, too.',
    nextCta: 'Next Step',
  },
  [TRANSFER_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/transfer/Products.vue'),
    title: 'Products',
    pageTitle: 'Now choose products to transfer',
    pageInfo: 'Please, select the products that should be transferred to the selected warehouse.',
    nextCta: 'Next Step',
  },
  [TRANSFER_STEP_3]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/transfer/Locations.vue'),
    title: 'Locations',
    pageTitle: 'Now choose destination locations in %warehouse_name%',
    pageInfo: 'In this step, you choose the locations where you want to move the selected products.',
    nextCta: 'Go to Overview',
  },
  [TRANSFER_STEP_4]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/transfer/Overview.vue'),
    title: 'Overview',
    pageTitle: 'Overview',
    pageInfo: 'Before completing the transfer of these products, check the information below to confirm you have entered everything correctly.',
    nextCta: 'Finish',
  },
};

export default steps;
