export const STOCKTAKE_STEP_1 = 0;
export const STOCKTAKE_STEP_2 = 1;
export const STOCKTAKE_STEP_3 = 2;

const steps = {
  [STOCKTAKE_STEP_1]: {
    component: () => import(/* webpackChunkName: "conduct-stocktake" */ '@/views/common/products/conduct-stocktake/Warehouse.vue'),
    title: 'Warehouse',
    pageTitle: 'Please choose warehouse',
    pageInfo: 'Please, select the warehouse for which you would like to conduct the stocktake.',
    nextCta: 'Next Step',
  },
  [STOCKTAKE_STEP_2]: {
    component: () => import(/* webpackChunkName: "conduct-stocktake" */ '@/views/common/products/conduct-stocktake/Products.vue'),
    title: 'Stocktaking',
    pageTitle: 'Take stock',
    pageInfo: 'Make sure your records are correct to finish the stocktake process for the selected products.',
    nextCta: 'Go To Overview',
  },
  [STOCKTAKE_STEP_3]: {
    component: () => import(/* webpackChunkName: "conduct-stocktake" */ '@/views/common/products/conduct-stocktake/Overview.vue'),
    title: 'Overview',
    pageTitle: 'Overview',
    pageInfo: 'Before completing the stocktake for the selected products, check the information below to confirm you have entered everything correctly.',
    nextCta: 'Finish',
  },
};

export default steps;
