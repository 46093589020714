var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header mb-32"},[_c('span',{staticClass:"header__info-text"},[_vm._v(" "+_vm._s(_vm.$t('netsuite.venues.subhead', { platform: _vm.platformName }))+" ")])]),_c('ez-filter-list',{staticClass:"mb-24",attrs:{"filters":_vm.filters},on:{"filterUpdated":_vm.updateFilters,"resetFilter":_vm.resetFilters}},[_c('ez-input',{staticClass:"search",attrs:{"formKey":"filters","name":"search","placeholder":`Search for a ${_vm.$t('global.venue')}`},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"search"}})]},proxy:true}])})],1),_c('div',[(_vm.connectedOutlets.length)?_c('ez-table',{staticClass:"sync-table",attrs:{"disable-hover":true,"data":_vm.connectedOutlets,"columns":['outlet', 'externalVenue'],"headers":{
        outlet: () => _vm.$t('global.venue'),
        externalVenue: () => `${_vm.$t('netsuite.name')} Customer`,
      },"columnProps":{
        account: { class: 'date-cell' },
        outlet: { class: 'outlet-cell' },
        externalVenue: { class: 'xxl-cell' },
      }},scopedSlots:_vm._u([{key:"cell-outlet",fn:function({ row }){return [_c('div',{staticClass:"outlet-name"},[_c('v-venue-entity-info',{staticClass:"cursor-pointer",attrs:{"venue":row}})],1)]}},{key:"cell-externalVenue",fn:function({ row }){return [_c('v-select-search',{staticClass:"select-customer",attrs:{"search":"/distributor/netsuite/customers","placeholder":`Select ${_vm.$t('netsuite.name')} Customer`,"data":_vm.customers,"min-length":3,"debounce-time":700,"selected":row.externalVenue},on:{"selected":obj => _vm.customerSelected(obj, row)}})]}}],null,false,1388918640)}):_vm._e(),(_vm.isLoadingMore)?_c('div',{staticClass:"u-text-center mt-12"},[_c('ez-spinner')],1):_vm._e(),(!_vm.isLoading && !_vm.isLoadingMore && _vm.meta.nextId)?_c('ez-load-more',{on:{"loadMore":_vm.fetchMoreOutlets}}):_vm._e(),(!_vm.connectedOutlets.length)?[_c('empty-state',{scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/no-venue-empty-state.svg"),"width":"160","alt":""}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('netsuite.venues.emptyState.title')))]},proxy:true}],null,false,914571059)})]:_vm._e(),_c('ez-loader',{attrs:{"show":_vm.isLoading}},[_vm._v("Loading...")])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }