<script>
import EzRadioButton from '@/components/ui/RadioButton/EzRadioButton';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import { BILL_COMBINED, BILL_TYPE_OPTIONS } from '@/util/constants';

const Event = {
  changeType: 'changeType',
  changeDay: 'changeDay',
};

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'VXeroBillTypeInput',
  components: { EzDropdown, EzRadioButton },
  props: {
    name: {
      required: false,
      type: String,
      default: 'combinedExpenseBill',
    },
    formKey: {
      type: String,
      required: true,
    },
    selectedType: {
      required: false,
      type: String,
      default: 'false',
    },
    selectedDay: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      options: BILL_TYPE_OPTIONS,
      showDaysDropdown: false,
      daysForBill: [],
    };
  },
  methods: {
    onChangeType(value) {
      this.showDaysDropdown = this.isValueCombined(value);
      this.$emit(Event.changeType, value);
    },
    onChangeDay(value) {
      this.$emit(Event.changeDay, value);
    },
    setSelected({ options, value }) {
      return options.map(opt => ({ ...opt, data: opt.value === value ? opt.value : '' }));
    },
    isValueCombined(val) {
      return val === BILL_COMBINED.value;
    },
  },
  created() {
    const formDayObj = (_, index) => ({ name: index + 1, value: index + 1 });
    this.daysForBill = Array.from({ length: 31 }, formDayObj);
  },
  watch: {
    selectedType: {
      deep: true,
      immediate: true,
      handler(val) {
        this.options = this.setSelected({ options: this.options, value: val });
        this.showDaysDropdown = this.isValueCombined(val);
      },
    },
  },
};
</script>

<template>
  <div class="v-xero-bill-type-input">
    <div class="info info--vertical">
      <span class="info--bold">Generate Bill to Pay</span>
      <span class="info--gray mt-4">Select how you would like to generate bills to pay.</span>
    </div>
    <div v-for="option in options" :key="option.label">
      <ez-radio-button
        :name="name"
        :formKey="formKey"
        :label="option.label"
        :value="option.value"
        :data="option.data"
        @change="onChangeType"
      />
      <div class="radio-button__desc">
        {{ option.desc }}
      </div>
    </div>

    <div v-if="showDaysDropdown">
      <ez-dropdown
        name="dayOfTheMonth"
        label="Day of the Month"
        :data="daysForBill"
        :selected="selectedDay"
        :isFullWidth="true"
        :idKey="'value'"
        @change="onChangeDay"
        placeholder="Select Day of the Month"
      />
      <p class="dropdown__day-info mt-8 mb-0">
        Select the day of the month when you want to generate a bill to pay. The bill will be
        generated with all the expenses added up to that point for the full previous calendar month.
        Days 29, 30 and 31 will count as the last day of the month for months that don’t have them.
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep() .ez-dropdown {
  .ez-dropdown__options ul {
    height: 145px;
  }
}
:deep() .ez-radio-button {
  .ez-radio-button__label {
    color: $color-gray-25;
  }
}

.v-xero-bill-type-input {
  > * {
    margin-bottom: 16px;
  }
}

.radio-button__desc {
  @include font-size(12px, 18px);
  margin-left: 20px;
  color: $color-gray-6C;
}

.dropdown__day-info {
  @include font-size(12px, 18px);
  color: $color-gray-6C;
}
</style>
