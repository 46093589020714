<script>
import EzButton from '@/components/ui/Button/EzButton';
import Paper from '@/components/layout/WizardOverviewPaper';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import { LOADING_KEY } from '@/util/constants';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { VENUE_STEP_1 } from '@/views/platform/distributor/outlets/connect-to-outlet/steps';
import { wizardListenerMixin } from '@/mixins/wizard';
import flash from '@/components/ui/FlashMessage';
import EzOnOff from '@/components/ui/OnOff/EzOnOff';
import OnboardingOptionsForm from '@/views/platform/distributor/outlets/OnboardingOptionsForm';

/**
 *
 * @version 1.0.0
 * @since 3.8.1
 */
export default {
  name: 'Overview',
  mixins: [wizardListenerMixin],
  components: {
    EzButton,
    Paper,
    VProductEntityInfo,
    EzLoadMore,
    EzSpinner,
    EzLoader,
    EzOnOff,
    OnboardingOptionsForm,
  },
  data() {
    return {
      products: [],
      firstPageProducts: [],
      meta: {},
      firstPageMetaCopy: {},
      limit: 10,
      expandBtnCopy: 'Expand List',
      iconDirection: 'down',
      isSendOnboarding: false,
      isAnyChecked: false,
    };
  },
  computed: {
    ...mapState('entities/venues', ['acceptAndSendOnboardingLink']),
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    selectedVenue() {
      return this.acceptAndSendOnboardingLink.venue;
    },
    venueId() {
      return this.acceptAndSendOnboardingLink.venue?.id;
    },
    canExpand() {
      return this.products.length < (this.limit * 2);
    },
    canLoadOnScroll() {
      return this.products.length >= (this.limit * 2);
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS,
        LOADING_KEY.DISTRIBUTOR_ONBOARDING_PREMIUM_OUTLET,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS_LOAD_MORE);
    },
    showExpandBtn() {
      return this.products?.length >= this.limit;
    },
    isPremiumOrIsLite() {
      return this.selectedVenue ? (this.$helpers.isPremium(this.selectedVenue.accountType)
        || this.$helpers.isLite(this.selectedVenue.accountType)) : false;
    },
    additionalData() {
      return {
        name: this.selectedVenue.name,
        [this.selectedVenue.xeroId ? 'xeroId' : 'venueId']: this.selectedVenue.xeroId
          ? this.selectedVenue.xeroId : this.selectedVenue.id,
      };
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'distributorFetchVenueVisibleProducts',
      'connectDistributorToVenue',
    ]),
    ...mapActions('entities/venues', ['distributorOnboardingPremiumOutlet']),
    ...mapMutations('entities/venues', [
      'CLEAR_ACCEPT_AND_SEND_ONBOARDING_LINK_DATA',
      'CLEAR_DRAFT',
    ]),
    ...mapActions('reports', ['resendConnectionInvitation']),
    fetchVisibleProducts(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS) {
      const queryParams = {
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
        sortBy: 'name',
        limit: this.limit,
        hidden: 'false',
      };
      return this.distributorFetchVenueVisibleProducts({
        venueId: this.venueId,
        query: queryParams,
        loadingKey,
      });
    },
    async expandList() {
      if (!this.canExpand) {
        await this.resetFilters();
        return;
      }
      await this.loadMore();
      this.expandBtnCopy = 'Collapse List';
      this.iconDirection = 'up';
    },
    async refresh() {
      this.meta = {};
      const { data: { data, meta } } = await this.fetchVisibleProducts();
      this.products = data;
      this.meta = meta;

      this.firstPageProducts = this.products;
      this.firstPageMetaCopy = this.meta;
    },
    async loadMore() {
      const loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS_LOAD_MORE;
      const { data: { data, meta } } = await this.fetchVisibleProducts(loadingKey);
      this.products = [
        ...this.products,
        ...data,
      ];
      this.meta = meta;
    },
    async resetFilters() {
      this.products = this.firstPageProducts;
      this.meta = this.firstPageMetaCopy;
      this.resetExpandBtn();
    },
    resetExpandBtn() {
      this.expandBtnCopy = 'Expand List';
      this.iconDirection = 'down';
    },
    editProducts() {
      this.$emit('stepBack', VENUE_STEP_1);
    },
    async onNextStep() {
      /**
       * Directly send onboarding request for premium/lite outlet.
       * @since 2.4.0
       */
      if (this.isPremiumOrIsLite && this.isSendOnboarding) {
        try {
          await this.distributorOnboardingPremiumOutlet({
            data: {
              venueId: this.selectedVenue.id,
              name: this.selectedVenue.name,
            },
          });
          flash.success({
            title: 'Onboarding link sent!',
            message: `Good job! ${this.selectedVenue.name} will be added to your list
            after they complete the onboarding.`,
          });
          await this.$router.push({ name: 'distributor-outlets-all' });
        } catch (e) {
          flash.error({
            title: 'Something went wrong!',
          });
        }
        return;
      }

      /**
       * Submit onboarding request form if non premium/lite outlet was selected.
       * @since 2.4.0
       */
      if (this.isSendOnboarding && !this.isPremiumOrIsLite) {
        this.submitOnboardingForm();
        return;
      }

      try {
        const { data: { data } } = await this.connectDistributorToVenue({
          id: this.selectedVenue.id,
        });
        const venueName = this.selectedVenue.name; // Remember the name because we clear selected
        if (data.status === 'connection') { // If connected (freemium)
          flash.success({
            title: this.$t('venues.successFlashTitleConnectionAdded'),
            message: this.$t('venues.successFlashMessageConnectionAdded', {
              venueName,
            }),
          });
          await this.$router.push({ name: 'distributor-outlets-all' });
        } else { // If request sent (premium)
          flash.success({
            title: this.$t('venues.successFlashTitleConnectionRequest'),
            message: this.$t('venues.successFlashMessageConnectionRequest', {
              venueName,
            }),
          });
          await this.$router.push({ name: 'distributor-outlets-all' });
        }
      } catch (e) {
        flash.fail({
          title: 'Something went wrong',
          message: e.response.data.error.message,
        });
      }
    },
    submitOnboardingForm() {
      this.$refs.onboardingForm.submitOnboardingForm();
    },
    onboardingChange() {
      this.isSendOnboarding = !this.isSendOnboarding;
    },
    onSuccessOnboarding() {
      flash.success({
        title: 'Onboarding link sent!',
        message: `Good job! ${this.selectedVenue.name} will be added to your list
        after they complete the onboarding.`,
      });
      this.$router.push({ name: 'distributor-outlets-all' });
    },
  },
  created() {
    this.refresh();
    this.enableNextStep();
  },
};
</script>

<template>
  <div>
    <paper>
      <template #title>Contact info</template>
      <div class="overview-item">
        <div class="info info--vertical">
          <small>{{ $t('global.venue') }} Name</small>
          <span>{{ selectedVenue.name }}</span>
        </div>
      </div>
    </paper>

    <paper class="products-paper">
      <template #title>Products ({{ meta.totalCount }})</template>
      <template #actions>
        <ez-button @click="editProducts" type="link" formType="button">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <table class="product-table">
        <tbody>
        <tr
          v-for="product in products"
          :key="product.id">
          <td class="item">
            <v-product-entity-info
              class="entity-info"
              :product="product" />
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="isLoadingMore" class="u-text-center">
        <ez-spinner/>
      </div>
      <ez-load-more
        v-if="!isLoadingMore && (canLoadOnScroll && meta.nextId)"
        @loadMore="loadMore"/>

      <template #footer>
        <div class="footer-paper mt-16">
          <ez-button
            v-if="showExpandBtn"
            @click="expandList"
            type="link"
            formType="button">
            <span>{{ expandBtnCopy }}</span>
            <font-awesome-icon class="ml-8" :icon="`angle-${iconDirection}`"/>
          </ez-button>
        </div>
      </template>
    </paper>
    <div class="onboarding">
      <hr>
      <div class="onboarding-option">
        <div class="help-block mb-20">
          <h4 class="help-block__title">Onboard this {{ $t('global.venue') }}</h4>
          <p class="help-block__text">
            Send an onboarding link to this {{ $t('global.venue') | lowercase }} to let them
            complete <br />
            the account opening form and go through your T&C.
          </p>
        </div>
        <ez-on-off
          form-key="onboardingOption"
          :checked="isSendOnboarding"
          @change="onboardingChange"
          name="inventoryTracking"/>
      </div>
      <onboarding-options-form
        ref="onboardingForm"
        @anyChecked="(ev) => isAnyChecked = ev"
        @success="onSuccessOnboarding"
        :additionalData="additionalData"
        v-if="isSendOnboarding && !isPremiumOrIsLite" />
    </div>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped>
:deep() .entity-info {
  flex-direction: column;
  align-items: center;
  &__text {
    margin: .75rem 0 0;
    justify-content: center;
  }
}

h3 {
  @include font-size(14px, 22px);
  margin: 0 0 16px;
  padding: 0;
  color: $color-gray-25;
  font-weight: 500;
}
.onboarding-option {
  display: flex;
  justify-content: space-between;
}
.dashed-line {
  max-width: 400px;
  margin: 20px auto;
}
.contact-item {
  @extend %flex-center;
  justify-content: space-between;
  @include font-size(14px, 16px);
  padding: 10px 0;
  &__name {
    color: $color-gray-25;
  }
  &__type {
    color: $color-gray-6C;
  }
}
.product-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  .item {
    width: 100%;
  }
  .entity-info {
    flex-direction: initial;
    align-items: center;
    :deep() .entity-info__text {
      margin-top: 0;
    }
  }
}
.footer-paper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.products-paper {
  max-height: 900px;
  :deep() .overview-paper__content {
    overflow-y: auto;
    max-height: 720px;
  }
}
.onboarding {
  margin: 0 30px;
}
</style>
