<script>
import EzTextarea from '@/components/ui/Textarea/EzTextarea.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index.vue';
import { EzConfirmationModal } from '@/components/ui/Modal';

export default {
  components: {
    EzConfirmationModal,
    VDatePicker,
    EzButton,
    EzTextarea,
  },
  props: {
    currentDueDate: {
      type: [Object, String, Date],
      required: true,
    },
    note: {
      type: String,
      required: false,
      default: () => (''),
    },
  },
  data() {
    return {
      dueDate: null,
      dueDateNote: '',
    };
  },
  methods: {
    updateDueDate(date) {
      this.dueDate = date;
    },
    reset() {
      this.dueDate = null;
      this.dueDateNote = '';
    },
    close() {
      this.$refs.dueDateEditModal.close();
    },
    open() {
      this.$refs.dueDateEditModal.open();
    },
    save() {
      this.$emit('dueDateSave', { dueDate: this.dueDate, message: this.dueDateNote });
    },
  },
  watch: {
    currentDueDate(val) {
      this.dueDate = val;
    },
    note(val) {
      this.dueDateNote = val;
    },
  },
};
</script>

<template>
  <ez-confirmation-modal ref="dueDateEditModal">
    <template #title>Edit Due Date</template>
    <template #content>
      <div class="mt-16">
        <v-date-picker
          class="calendar mt-8"
          :data-cy="''"
          name="dueDate"
          label="Due Date"
          v-model="dueDate"
          form-key="dueDateKey"
          @dateChange="updateDueDate"
          is-full-width
        />
        <ez-textarea
          class="mt-12"
          :data-cy="''"
          name="note"
          label="Note"
          placeholder="Leave a Note"
          form-key="dueDateKey"
          :value="dueDateNote"
          @onChange="(val) => dueDateNote = val"
        />
      </div>
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="close"
        :data-cy="''"
      >
        Cancel
      </ez-button>
      <ez-button
        @click="save"
        :data-cy="''"
      >
        Save Changes
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped></style>
