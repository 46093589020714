<script>
import EzOnOff from '@/components/ui/OnOff';
import EzMaskInput from '@/components/ui/MaskInput';

/**
 * VVenueInventorySettings
 * @version 1.0.0
 * @since 3.25.1
 */

export default {
  name: 'VVenueInventorySettings',
  props: {
    values: {
      type: Object,
      required: true,
    },
    nameGroup: {
      type: String,
      required: false,
    },
  },
  components: {
    EzMaskInput,
    EzOnOff,
  },
  data() {
    return {
      data: {
        displayStockNumbers: false,
        lowInventoryThreshold: 10,
        orderMoreThanAvailable: true,
        excludeExpired: false,
      },
    };
  },
  methods: {
    name(key) {
      if (!this.nameGroup) return key;
      return `${this.nameGroup}[${key}]`;
    },
    updateState(key, value) {
      this.data[key] = value;
      this.$emit('onChange', { key, value });
    },
  },
  watch: {
    values: {
      immediate: true,
      handler(val) {
        this.data = { ...val };
      },
    },
  },
};
</script>

<template>
  <section>
    <div class="settings-section settings-section--toggle">
      <div class="help-block">
        <h4 class="help-block__title">Display Inventory Level</h4>
        <p class="help-block__text">
          Activate to display available inventory levels to your customers.
        </p>
      </div>
      <ez-on-off
        formKey=""
        :name="name('displayStockNumbers')"
        :value="data.displayStockNumbers.toString()"
        :checked="data.displayStockNumbers"
        @change="updateState('displayStockNumbers', $event)"
      />
    </div>
    <hr />
    <div class="settings-section settings-section--inline-input">
      <div class="help-block">
        <h4 class="help-block__title">Low Inventory Indicator</h4>
        <p class="help-block__text">
          This setting displays a low inventory indicator to customers for products whose inventory
          levels are below indicated threshold. If the value is 0, the indicator will be disabled.
        </p>
      </div>

      <p class="text-inline-input">
        <span>When inventory levels below</span>
        <ez-mask-input
          formKey=""
          type="input"
          :name="name('lowInventoryThreshold')"
          label=""
          :precision="0"
          :value="data.lowInventoryThreshold"
          :allow-negative-value="false"
          :price-prefix="false"
          @input="updateState('lowInventoryThreshold', $event)"
        />
        <span>units.</span>
      </p>
    </div>
    <hr />
    <div class="settings-section settings-section--toggle">
      <div class="help-block">
        <h4 class="help-block__title">Ordering More than Available</h4>
        <p class="help-block__text">
          Allow customers to order more units than are available in inventory. When disabled,
          depends on "Display Inventory Levels" option: if inventory levels are not displayed,
          ordering limits apply only to out of stock products.
        </p>
      </div>
      <ez-on-off
        formKey=""
        :name="name('orderMoreThanAvailable')"
        :value="data.orderMoreThanAvailable.toString()"
        :checked="data.orderMoreThanAvailable"
        @change="updateState('orderMoreThanAvailable', $event)"
      />
    </div>
    <hr />
    <div class="settings-section settings-section--toggle">
      <div class="help-block">
        <h4 class="help-block__title">Exclude Expired Inventory</h4>
        <p class="help-block__text">
          When enabled, this will exclude any expired inventory when displaying to customers.
        </p>
      </div>
      <ez-on-off
        formKey=""
        :name="name('excludeExpired')"
        :value="data.excludeExpired.toString()"
        :checked="data.excludeExpired"
        @change="updateState('excludeExpired', $event)"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.settings-section {
  &--toggle {
    display: flex;
    align-items: flex-start;

    .help-block {
      margin-right: 16px;
    }
  }

  &--inline-input {
    :deep() .mask-input-wrapper {
      max-width: 64px;
      margin-left: 8px;
      margin-right: 8px;

      .mask-input__input {
        font-weight: normal;
      }
    }

    .text-inline-input {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .help-block {
    margin-bottom: 0;

    &__title {
      font-weight: 600;
    }

    &__text {
      @include font-size(14px, 20px);
      margin-bottom: 0;
    }
  }

  :deep() .input-group {
    .ez-radio-button__label {
      color: $color-gray-25;
    }

    &__input {
      input:disabled::placeholder {
        color: $color-gray-6C;
      }
    }
  }

  .mask-input :deep() input {
    font-weight: 400;
  }
}
</style>
