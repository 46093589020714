<script>
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo/index';
import VAccountOwnerEntityInfo from '@/components/v3/patterns/VAccountOwnerEntityInfo';
import EzButton from '@/components/ui/Button';
import { UNASSIGNED_ACCOUNT } from '@/util/constants';
import { falsy } from '@/util/utils';

const Event = {
  click: 'click',
  deleteAccount: 'deleteAccount',
};

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  components: {
    VVenueEntityInfo,
    VAccountOwnerEntityInfo,
    EzButton,
  },
  props: {
    venue: {
      required: true,
      type: Object,
    },
    noteTitle: {
      required: false,
      type: String,
      default: '',
    },
    note: {
      required: false,
      type: String,
      default: '',
    },
    dateLabel: {
      type: String,
      default: 'Date',
      required: false,
    },
    notifyMsg: {
      required: false,
      type: String,
      default: '',
    },
    toShowFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    toHideAccountOwner: {
      required: false,
      type: Boolean,
      default: false,
    },
    hasToDoLogo: {
      type: Boolean,
      required: false,
      default: false,
    },
    dueDate: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    accountOwner() {
      return this.venue.accountOwner || UNASSIGNED_ACCOUNT;
    },
    hasVenue() {
      return !falsy(this.venue);
    },
    hasDueDate() {
      return !falsy(this.dueDate);
    },
  },
  methods: {
    onClick() {
      this.$emit(Event.click);
    },
    deletePendingAccount() {
      this.$emit(Event.deleteAccount, this.venue);
    },
  },
};
</script>
<template>
  <div @click="onClick" class="activity-card cursor-pointer">
    <div class="activity-card__header">
      <div class="activity-card__header__venue-info">
        <v-venue-entity-info class="entity-info-card" v-if="hasVenue" :venue="venue" />
        <div class="only-todo mb-16" v-if="!hasVenue && hasToDoLogo">
          <div class="only-todo__icon">
            <font-awesome-icon icon="tasks" />
          </div>
          <div class="only-todo__text">To-Do <span>(No {{$t('global.venue')}} Assigned)</span></div>
        </div>
      </div>
      <font-awesome-icon
        v-if="notifyMsg"
        class="cursor-help"
        icon="bell"
        v-tooltip="{
          content: `${notifyMsg}`,
          classes: ['tooltip-general'],
          placement: 'start',
        }"
      />
      <ez-button
        class="activity-card__delete"
        v-if="deleteEnabled"
        type="link"
        formType="button"
        @click.stop="deletePendingAccount">
        <font-awesome-icon icon="trash"/>
      </ez-button>
    </div>
    <div class="activity-card__content">
      <div v-if="venue && venue.date" class="activity-card__content__date">
        <span class="activity-card__content__date__label">
          {{ dateLabel }}
        </span>
        {{ venue.date | date }}
      </div>

      <div class="note-due-date" v-if="note || hasDueDate">
        <div v-if="note" class="activity-card__content__date">
          <small
            v-if="noteTitle.length"
            class="activity-card__content__date__label">{{ noteTitle }}
          </small>
          <span class="activity-card__content__note">{{ note }}</span>
        </div>
        <template v-if="hasDueDate">
          <div class="due-date" v-if="dueDate.dueDate">
            <small class="due-date__label">Due Date</small>
            <span
              :class="[
              { 'due-date--blue': dueDate.isDue },
              { 'due-date--red': dueDate.isPastDue }
            ]"
            >{{ dueDate.dueDate | date }}</span>
          </div>
        </template>
      </div>

      <v-account-owner-entity-info
        v-if="!toHideAccountOwner"
        :account-owner="accountOwner"
        :to-show-name="false"
      />
    </div>
    <div class="activity-card__footer" v-if="toShowFooter">
      <div class="activity-card__footer__copy">Total</div>
      <div class="activity-card__footer__price">{{ venue.amount | price }}</div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.activity-card__content__note {
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: none;
}

.activity-card {
  width: 100%;
  background-color: #fff;
  border: 1px solid #eceef5;
  border-radius: 3px;
  padding: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  z-index: 5;

  &__delete {
    padding: 0;
    opacity: 0;
    display: flex;
    align-items: flex-start;
  }

  &:hover {
    .activity-card__delete {
      opacity: 1;
    }
  }

  &__header {
    @extend %flex-center;
    justify-content: space-between;
    color: $color-gray-6C;

    &__venue-info {
      max-width: 90%;
    }
  }
  &__content {
    margin-top: 12px;
    text-transform: capitalize;

    @extend %flex-center;
    justify-content: space-between;
    &__date {
      display: flex;
      flex-direction: column;
      @include font-size(14px);
      color: $color-gray-25;
      &__label {
        margin-bottom: 4px;
        color: $color-gray-6C;
        @include font-size(12px);
        font-weight: 500;
      }
    }
  }
  &__footer {
    @extend %flex-center;
    justify-content: space-between;
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px dashed #ccd2e0;
    font-weight: 500;
    &__copy {
      color: $color-gray-6C;
      text-transform: uppercase;
      @include font-size(12px);
    }
    &__price {
      @include font-size(16px);
      color: $color-gray-25;
    }
  }
  + .activity-card {
    margin-top: 4px;
  }
}

.only-todo {
  display: flex;
  align-items: center;
  &__icon {
    @extend %flex-center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $color-pastel-blue;
    @include font-size(18px);
    color: $color-primary-blue;
  }
  &__text {
    margin-left: 8px;
    @include font-size(14px, 16px);
    color: $color-gray-25;
    span {
      color: $color-gray-6C;
    }
  }
}

.note-due-date {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  .activity-card__content__date {
    max-height: 36px;
  }
  .activity-card__content__note {
    max-width: 155px;
    white-space: pre-wrap;
    color: $color-gray-6C;
  }
  .due-date {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: $color-gray-25;;
    &__label {
      margin-bottom: 4px;
      color: $color-gray-6C;
      @include font-size(12px);
      font-weight: 500;
    }
    &--blue {
      color: $color-primary-blue;
    }
    &--red {
      color: $color-primary-red;
    }
  }
}
</style>
