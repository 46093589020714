<script>
/**
* Xero venues
* @version 1.0.0
* @since
*/

import xeroGuard from '@/views/platform/distributor/xero/settings/xeroGuard';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch/index';
import EzTable from '@/components/ui/Table/EzTable';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo/index';
import {
  distributorGetConnectedVenues,
  distributorGetContacts,
  distributorGetXeroAccounts,
  distributorGetXeroAccountSetup,
  distributorPutConnections,
  supplierFetchTrackingCategories,
  distributorXeroConnections,
} from '@/api/endpoints/xero';
import EmptyState from '@/views/common/empty-state/EmptyState';
import flash from '@/components/ui/FlashMessage';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzLoader from '@/components/ui/Loader/EzLoader';
import FooterForUpdate from '@/views/platform/distributor/xero/settings/FooterForUpdate';
import UpdateModal from '@/views/platform/distributor/xero/settings/UpdateModal';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import { mapGetters } from 'vuex';
import { LOADING_KEY, SELECT_DESELECT_ALL } from '@/util/constants';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import { debounce } from '@/util/utils';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';

export default {
  mixins: [xeroGuard],
  components: {
    VSelectSearch,
    EzTable,
    VVenueEntityInfo,
    EmptyState,
    EzLoadMore,
    EzSpinner,
    EzLoader,
    FooterForUpdate,
    UpdateModal,
    EzCheckbox,
    VDataWithInfo,
    EzFilterList,
    EzInput,
    EzDropdown,
  },
  data() {
    return {
      contacts: [],
      connectedVenues: [],
      meta: {},
      buttonDisabled: true,
      columns: [],
      selectedVenues: [],
      trackingCategories: [],
      accountsData: {},
      config: null,
      updateData: {},
      searchResults: {},
      selected: false,
      listOfVenueIds: [],
      filters: {
        term: null,
        operative: true,
      },
      operativeFilterOptions: [
        {
          id: 1,
          name: 'Operative',
        },
        {
          id: 2,
          name: 'All',
        },
      ],
      operativeId: 1,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.SUPPLIER_UPDATE_XERO_OUTLETS,
        LOADING_KEY.DISTRIBUTOR_FETCH_XERO_VENUES,
        LOADING_KEY.DISTRIBUTOR_FETCH_XERO_CONTACTS,
        LOADING_KEY.DISTRIBUTOR_UPDATE_CONNECTIONS,
      ]);
    },
    isLoadingMoreVenues() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_XERO_VENUES);
    },
    columnsNested() {
      return [
        'outletName',
        ...this.columns,
        'sales',
        'inventory',
        'cogs',
        'paymentTerms',
        'taxRate',
        'xeroContact',
      ];
    },
    entity() {
      return this.$t('global.venues');
    },
    checkedVenues() {
      return this.selectedVenues.filter(sv => sv.checked);
    },
    uncheckedVenues() {
      return this.selectedVenues.filter(sv => !sv.checked);
    },
    checkedVenuesCount() {
      return this.selected ? this.meta.totalCount - this.listOfVenueIds.length : this.listOfVenueIds.length;
    },
    indeterminate() {
      return this.checkedVenuesCount > 0 && this.checkedVenuesCount !== this.meta.totalCount;
    },
    areAllSelected() {
      return this.checkedVenuesCount === this.meta.totalCount;
    },
    invoicesAccounts() {
      return this.accountsData?.invoicesAccounts?.length;
    },
    venuesToUpdate() {
      const venues = (this.selected && this.filters.term === null)
        ? this.uncheckedVenues
        : this.checkedVenues;
      return venues.map(vn => ({ venueId: vn.venueId, contactId: vn.xeroContact?.xeroId }));
    },
    fetchedColumnsProps() {
      return this.columns.reduce((acc, curr) => ({ ...acc, [curr]: { class: 'wrap-cell' } }), {});
    },
  },
  methods: {
    xeroSelected(contact, venue) {
      const foundedContact = {
        ...this.connectedVenues.find(item => venue.id === item.entity.id),
        xeroContact: contact.selected === null ? null : contact,
      };
      this.saveChanges(foundedContact);
    },
    async fetchVenues(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_XERO_VENUES) {
      const query = {
        ...(this.meta.limit ? { limit: this.meta.limit } : {}),
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
        ...this.filters,
      };
      const { data } = await distributorGetConnectedVenues({ query, loadingKey });

      if (loadingKey === LOADING_KEY.DISTRIBUTOR_FETCH_XERO_VENUES) {
        this.connectedVenues = data.data;
      } else {
        this.connectedVenues = [...this.connectedVenues, ...data.data];
      }
      this.columns = data.meta.columns;
      this.meta = data.meta;

      await this.$nextTick();
      this.updateContactsFromVenues(data.data);
      this.connectedVenues.forEach((item) => {
        if (this.selectedVenues.findIndex(x => x.venueId === item.entity.id) === -1) {
          this.selectedVenues.push({ ...item, venueId: item.entity.id, checked: this.selected });
        }
      });
    },
    async fetchMoreVenues() {
      await this.fetchVenues(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_XERO_VENUES);
    },
    async refreshVenues() {
      this.connectedVenues = [];
      this.meta = {};
      await this.fetchVenues();
    },
    async fetchContacts() {
      const { data: contacts } = await distributorGetContacts();
      this.contacts = [...contacts.data];
      this.searchResults = {};
    },
    updateContactsFromVenues(newVenues) {
      this.contacts = [
        ...this.contacts,
        ...newVenues
          .filter(pair => pair.xeroContact !== null)
          .map(item => item.xeroContact),
      ].reduce((acc, curr) => {
        if (!acc.some(obj => obj.xeroId === curr.xeroId)) {
          acc.push(curr);
        }
        return acc;
      }, []);
    },
    async getTrackingCategories() {
      const { data: { data } } = await supplierFetchTrackingCategories();
      this.trackingCategories = data;
    },
    async saveChanges(item) {
      const data = [{
        contactId: item.xeroContact?.xeroId || null,
        venueId: item.entity?.id,
      }];
      try {
        await distributorPutConnections(data);

        // Updated connected venues list with the updated contact
        const updatedVenueIdx = this.connectedVenues.findIndex(v => v.entity?.id === item.entity?.id);
        this.connectedVenues.splice(updatedVenueIdx, 1, item);

        // Adds selected contact if previously not in the contacts list
        const contactExists = this.contacts.find(c => c.xeroId === item.xeroContact?.xeroId);
        if (!contactExists) this.updateContactsFromVenues([item]);

        flash.success({ title: this.$t('xero.settings.venues.flashMessages.success.title') });
      } catch (e) {
        flash.error({ title: this.$t('xero.settings.venues.flashMessages.error.title') });
      }
    },
    openUpdateModal() {
      this.$refs.updateModal.open();
    },
    outletNameCheckbox(h) {
      return h('span', { class: 'u-flex-center select-all-checkbox' }, [
        h('ez-checkbox', {
          class: 'cursor-pointer',
          props: {
            indeterminate: this.indeterminate,
            label: `${this.$t('global.venue')}`,
            checked: this.areAllSelected,
          },
          on: {
            change: (ev) => {
              this.selectDeselectAll(ev);
            },
          },
        }, []),
      ]);
    },
    selectDeselectAll(ev) {
      this.selected = ev;
      this.listOfVenueIds = [];
      this.selectedVenues = this.selectedVenues.map(cv => ({ ...cv, checked: ev }));
    },
    onCheckboxChange(pr, checked) {
      if (this.selected !== checked) this.listOfVenueIds.push({ venueId: pr.entity.id });
      else this.listOfVenueIds = this.listOfVenueIds.filter(item => item.venueId !== pr.entity.id);
      this.selectedVenues.find(pro => pro.venueId === pr.entity.id).checked = checked;
    },
    isChecked(row) {
      return this.selectedVenues?.find(sp => sp.venueId === row.entity.id)?.checked;
    },
    getTrackingValue(product, col) {
      return product?.xeroConfig?.tracking?.[col] || '-';
    },
    async getXeroSetup() {
      const [
        { data: cogsAccounts },
        { data: invoicesAccounts },
        { data: inventoryAccounts },
        { data: setupData },
      ] = await Promise.all([
        distributorGetXeroAccounts({ type: 'cogs', withDefault: true }),
        distributorGetXeroAccounts({ type: 'revenue', withDefault: true }),
        distributorGetXeroAccounts({ type: 'inventory', withDefault: true }),
        distributorGetXeroAccountSetup(),
      ]);

      const selectedAccount = setupData.data.accountCode;
      const selectedAccMapped = invoicesAccounts.data.find(ac => ac.code === selectedAccount)?.name;
      this.accountsData = {
        cogsAccounts: cogsAccounts.data,
        invoicesAccounts: invoicesAccounts.data,
        inventoryAccounts: inventoryAccounts.data,
        selectedAccount,
        selectedAccMapped,
      };
    },
    async updateVenues(ev) {
      this.config = ev;
      this.updateData = {
        ...this.updateData,
        config: this.config,
        state: (this.selected && this.filters.term === null)
          ? SELECT_DESELECT_ALL.selectedAll
          : SELECT_DESELECT_ALL.deselectedAll,
      };
      await distributorXeroConnections(this.updateData);
      await this.refreshVenues();
      this.$refs.updateModal.close();
    },
    setSearchResults({ results }, row) {
      this.searchResults = {
        ...this.searchResults,
        [row.entity.id]: results,
      };
    },
    clearSearchResults(row) {
      const { [row.entity.id]: removed, ...rest } = this.searchResults;
      this.searchResults = { ...rest };
    },
    async resetFilters() {
      Object.keys(this.filters).forEach((key) => { this.filters[key] = null; });
      this.selectDeselectAll(false);
      this.selectedVenues = [];
      this.operativeId = null;
      await this.refreshVenues();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      }
      this.selectDeselectAll(false);
      this.selectedVenues = [];
      await this.refreshVenues();
    }, 300),
    setOperativeFilter(item) {
      this.operativeId = item.id;
      if (item.id === 1) this.filters.operative = true;
      else this.filters.operative = false;
    },
  },
  async created() {
    await this.fetchContacts();
    await Promise.all([
      this.refreshVenues(),
      this.getTrackingCategories(),
      this.getXeroSetup(),
    ]);
  },
  watch: {
    venuesToUpdate() {
      this.updateData = {
        ...this.updateData,
        items: this.venuesToUpdate,
      };
    },
    checkedVenues(val) {
      window.Intercom('update', {
        hide_default_launcher: !!val.length,
      });
    },
  },
};
</script>
<template>
  <div>
    <div class="header mb-24">
      <span class="header__info-text">
        To edit tracking categories and accounts, select the {{ $t('global.venues') | lowercase }}
        you want to update and click on the “Update {{ $t('global.venues') }}” button at the bottom.
      </span>
    </div>
    <ez-filter-list
      :filters="filters"
      @filterUpdated="updateFilters"
      @resetFilter="resetFilters"
      class="mb-24"
    >
      <ez-input
        formKey="filters"
        name="search"
        class="search"
        :placeholder="`Search for a ${$t('global.venue')}`"
      >
        <template #prefix>
          <font-awesome-icon icon="search" />
        </template>
      </ez-input>
      <ez-dropdown
        ref="select"
        class="operative-filter ml-12"
        label="Operative"
        placeholder="Filter Customers"
        :data="operativeFilterOptions"
        name="operative"
        is-full-width
        @change="setOperativeFilter"
        :selected="operativeId"
      />
    </ez-filter-list>
    <div>
      <ez-table
        v-if="connectedVenues.length"
        :disable-hover="true"
        :data="connectedVenues"
        :columns="columnsNested"
        :headers="{
          outletName: outletNameCheckbox,
          sales: () => 'Invoices Account',
          inventory: () => 'Inventory Account',
          cogs: () => 'COGS Account',
        }"
        :columnProps="{
          taxRate: { class: 'wrap-cell' },
          outletName: { class: 'outlet-cell' },
          xeroContact: { class: 'extra-large-cell contact-cell' },
          sales: { class: 'wrap-cell' },
          inventory: { class: 'wrap-cell' },
          cogs: { class: 'wrap-cell' },
          ...fetchedColumnsProps,
        }"
      >
        <template #cell-outletName="{ row }">
          <div class="outlet-name">
            <ez-checkbox
              class="cursor-pointer mr-8"
              :key="row.id"
              @change="onCheckboxChange(row, $event)"
              :checked="isChecked(row)"
            />
            <v-venue-entity-info
              class="cursor-pointer"
              :venue="row.entity"
              @click.native="onCheckboxChange(row, !isChecked(row))"
            />
          </div>
        </template>
        <template v-for="cl in columns" #[`cell-${cl}`]="{ row }">
          {{ getTrackingValue(row, cl) }}
        </template>
        <template #cell-xeroContact="{ row }">
          <v-select-search
            :data="searchResults[row.entity.id] || contacts"
            :has-clear="false"
            :debounce-time="700"
            search="/distributor/xero/contacts"
            value-property="xeroId"
            placeholder="Select Xero Contact"
            :selected="row.xeroContact && row.xeroContact.xeroId"
            @selected="(obj) => xeroSelected(obj, row.entity)"
            @results="(res) => setSearchResults(res, row)"
            @close="clearSearchResults(row)"
          />
        </template>
        <template #cell-sales="{ row: { xeroAccount } }">
          <v-data-with-info
            v-if="xeroAccount"
            :show-underline="xeroAccount.default"
            :info="xeroAccount.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ xeroAccount.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-inventory="{ row: { xeroAccounts: { inventory } } }">
          <v-data-with-info
            v-if="inventory"
            :show-underline="inventory.default"
            :info="inventory.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ inventory.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-cogs="{ row: { xeroAccounts: { cogs } } }">
          <v-data-with-info
            v-if="cogs"
            :show-underline="cogs.default"
            :info="cogs.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ cogs.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-paymentTerms="{ row: { xeroPaymentTerms } }">
          <v-data-with-info
            v-if="xeroPaymentTerms"
            :show-underline="xeroPaymentTerms.default"
            :info="xeroPaymentTerms.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ xeroPaymentTerms.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-taxRate="{ row: { xeroTax } }">
          <v-data-with-info
            v-if="xeroTax"
            :show-underline="xeroTax.default"
            :info="xeroTax.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ xeroTax.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>
      </ez-table>

      <div v-if="isLoadingMoreVenues" class="u-text-center mt-12">
        <ez-spinner />
      </div>

      <ez-load-more
        v-if="!isLoading && !isLoadingMoreVenues && meta.nextId"
        @loadMore="fetchMoreVenues"
      />

      <template v-if="!connectedVenues.length">
        <empty-state>
          <template #badge>
            <img src="@/assets/no-venue-empty-state.svg" width="160" alt="">
          </template>
          <template #title>{{ $t('xero.settings.venues.emptyState.title') }}</template>
          <template #info>
            {{ $t('xero.settings.venues.emptyState.info') }}
          </template>
        </empty-state>
      </template>

      <update-modal
        v-if="invoicesAccounts"
        ref="updateModal"
        :entity="entity"
        :categories="trackingCategories"
        :acc-data="accountsData"
        @update="updateVenues"
        show-tax-rate
        show-payment-terms
      />

      <footer-for-update
        v-if="checkedVenuesCount"
        :items-count="checkedVenuesCount"
        :entity="entity"
        @footerAction="openUpdateModal"
      />

      <ez-loader :show="isLoading">Loading...</ez-loader>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .outlet-name {
    display: flex;
    align-items: center;
  }
  .outlet-cell :deep() {
    .entity-info__text span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .extra-large-cell.contact-cell {
    overflow: visible;
  }
  .wrap-cell {
      :deep() .has-tooltip {
        display: inline;
      }
  }
  .select-all-checkbox {
    .input-group {
      @include font-size(12px);
      :deep() .check-indicator {
        margin-right: 8px;
      }
    }
  }
  .header {
    width: 400px;
    &__info-text {
      @include font-size(14px, 20px);
      font-weight: 500;
      color: $color-gray-6C;
    }
  }


  :deep() .ez-filter-list__items .search,
  :deep() .operative-filter.input-group {
    width: 260px;
  }
</style>
