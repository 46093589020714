<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import dayjs from 'dayjs';
import Product from '@/models/Product';
import { LOADING_KEY, UNIT_TYPE_FRACTIONAL } from '@/util/constants';
import { clone, getDateFormat } from '@/util/utils';

import flash from '@/components/ui/FlashMessage';
import EzInput from '@/components/ui/Input';
import EzTable from '@/components/ui/Table';
import EzButton from '@/components/ui/Button';
import EzLoadMore from '@/components/ui/LoadMore';
import EzMaskInput from '@/components/ui/MaskInput';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzDateInput from '@/components/ui/DateInput';
import EmptyState from '@/views/common/empty-state';
import VPrice from '@/components/v3/elements/VPrice';

/**
 * InventoryOverview
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'InventoryOverview',
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  components: {
    EzInput,
    EzTable,
    EzButton,
    EzLoadMore,
    EzMaskInput,
    EzFormModal,
    EzDateInput,
    EmptyState,
    VPrice,
  },
  data() {
    return {
      items: [],
      meta: {},
      editItem: {},
      isInvalidExpiryDate: false,
      supplierCy,
      getDateFormat,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    ...mapState('entities/users', ['loggedUser']),
    me() {
      return this.loggedUser.distributor;
    },
    loading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_OVERVIEW,
        LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_VALUATION,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_PRODUCT_INVENTORY_OVERVIEW);
    },
    isSaving() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_UPDATE_INVENTORY_ITEM);
    },
    product() {
      return Product.find(this.productId);
    },
    isFractional() {
      return this.product?.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    hasMadeChanges() {
      const orginItem = this.items.find(i => i.id === this.editItem.id);
      return JSON.stringify(orginItem) !== JSON.stringify(this.editItem);
    },
    batchCodeValid() {
      return this.me.inventorySettings?.batchTracking ? !!this.editItem.batchCode?.trim() : true;
    },
    saveDisabled() {
      return !this.hasMadeChanges || !this.batchCodeValid || this.isInvalidExpiryDate;
    },
    today() {
      return dayjs().startOf('day');
    },
    hasInventoryItems() {
      return !!this.items.length;
    },
    isNotTrackingProduct() {
      return !this.product.inventoryTracked;
    },
    shouldShowEmptyState() {
      if (this.isNotTrackingProduct) return true;
      return !this.hasInventoryItems && !this.loading && !this.isLoadingMore;
    },
    hasEditInventoryItemPermission() {
      return this.$permission.has('editInventoryItem');
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'distributorFetchProductInventoryOverview',
      'distributorUpdateInventoryItem',
    ]),
    open() {
      this.$refs.editModal.open();
    },
    close() {
      this.$refs.editModal.close();
      this.editItem = {};
    },
    async save() {
      await this.updateInventoryItem();

      this.close();
      this.items = [];
      this.meta = {};

      await this.$nextTick();
      await this.fetchInventoryItems();
    },
    handleEdit(row) {
      this.editItem = { ...clone(row), expiryDate: row.expiryDate ? dayjs(row.expiryDate) : null };
      this.open();
    },
    onInputChange(key, value) {
      this.editItem[key] = value;
    },
    onInvalidExpiryDate(value) {
      this.isInvalidExpiryDate = value;
    },
    async fetchInventoryItems(
      loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_OVERVIEW,
    ) {
      const query = {
        limit: 10,
        sortBy: 'id',
        productId: this.productId,
        includeNegative: true,
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
      };

      const response = await this.distributorFetchProductInventoryOverview({ query, loadingKey });
      const { data, meta } = response.data;
      this.items = clone([...this.items, ...data]);
      this.meta = clone(meta);
    },
    async loadMore() {
      await this.fetchInventoryItems(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_PRODUCT_INVENTORY_OVERVIEW);
    },
    async updateInventoryItem() {
      const data = {
        batchCode: this.editItem.batchCode?.trim() || null,
        expiryDate: this.editItem.expiryDate
          ? dayjs(this.editItem.expiryDate).format('YYYY-MM-DD')
          : null,
        initialInventory: +this.editItem.initialInventory,
        cogs: this.editItem.cogs,
      };
      await this.distributorUpdateInventoryItem({ id: this.editItem.id, data });
      flash.success({
        title: 'Inventory item updated',
        message: 'You have successfully updated inventory item.',
      });
    },
    formatQuantity(val) {
      const isFractional = val % 1 > 0;
      return isFractional ? parseFloat(val).toFixed(2) : val;
    },
  },
  async created() {
    if (!this.me.inventorySettings.tracking) {
      this.$router.replace({ name: 'distributor-product-single' });
    }

    await this.fetchInventoryItems();
  },
};
</script>

<template>
  <div class="inventory-overview">
    <div class="subheader">
      <div class="subheader__description">
        <p class="subheader__description__title">Inventory Overview</p>
        <p class="subheader__description__copy">
          Keep track of your product’s inventory overview here. You can edit batch details for each
          inventory log.
        </p>
      </div>
    </div>
    <ez-table
      class="inventory-items"
      :loading="loading"
      :data="shouldShowEmptyState ? [] : items"
      :columns="[
        'createdAt',
        ...(me.inventorySettings.tracking ? ['expiresSoon'] : []),
        'warehouse',
        'location',
        'batchCode',
        'expiryDate',
        ...($permission.has('seeCOGS') ? ['cogs'] : []),
        'currentInventory',
        'action',
      ]"
      :headers="{
        createdAt: () => 'Date Created',
        expiresSoon: () => '',
        batchCode: () => 'Batch ID',
        cogs: () => 'COGS',
        currentInventory: () => 'Stock',
        action: () => '',
      }"
      :columnProps="{
        createdAt: { class: 'date-cell' },
        expiresSoon: { class: 'indicator-cell' },
        batchCode: { class: 'large-cell' },
        expiryDate: { class: 'date-cell' },
        cogs: { class: 'cogs-cell medium-cell text-right-cell' },
        currentInventory: { class: 'current-quantity-cell text-right-cell' },
        action: { class: 'small-cell' },
      }"
    >
      <template #cell-createdAt="{ row, row: { createdAt } }">
        <span
          v-if="createdAt"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.TEXT__DATE_CREATED}-${row.id}`"
        >
          {{ createdAt | date }}
        </span>
        <span
          v-else
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.TEXT__DATE_CREATED}-${row.id}`"
        >
          -
        </span>
      </template>

      <template #cell-expiresSoon="{ row: { expiresSoon } }">
        <font-awesome-icon
          v-if="expiresSoon"
          v-tooltip="{
            placement: 'top-start',
            content: `This product has a batch that's about to expire soon`,
            classes: ['tooltip--reset-margin u-text-center mb-8'],
          }"
          icon="calendar-alt"
        />
      </template>

      <template #cell-warehouse="{ row, row: { warehouse } }">
        <span
          :title="warehouse.name"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.TEXT__WAREHOUSE}-${row.id}`"
        >
          {{ warehouse.name }}
        </span>
      </template>

      <template #cell-location="{ row, row: { location } }">
        <span
          v-if="location"
          :title="location.code"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.TEXT__LOCATION}-${row.id}`"
        >
          {{ location.code }}
        </span>
        <span
          v-else
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.TEXT__LOCATION}-${row.id}`"
        >
          -
        </span>
      </template>

      <template #cell-batchCode="{ row, row: { batchCode } }">
        <span
          :title="batchCode || '-'"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.TEXT__BATCH_CODE}-${row.id}`"
        >
          {{ batchCode || '-' }}
        </span>
      </template>

      <template #cell-expiryDate="{ row, row: { expiryDate } }">
        <span
          v-if="expiryDate"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.TEXT__EXPIRY_DATE}-${row.id}`"
        >
          {{ expiryDate | date }}
        </span>
        <span
          v-else
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.TEXT__EXPIRY_DATE}-${row.id}`"
        >
          -
        </span>
      </template>

      <template #cell-cogs="{ row, row: { cogs } }">
        <v-price
          :price="cogs || 0"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.TEXT__COGS}-${row.id}`"
        />
      </template>

      <template #cell-currentInventory="{ row, row: { currentInventory } }">
        <span
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.TEXT__STOCK}-${row.id}`"
        >
          {{ formatQuantity(currentInventory) }}
        </span>
      </template>

      <template #cell-action="{ row }">
        <ez-button
          type="secondary"
          class="edit-inventory-item-btn"
          :disabled="!hasEditInventoryItemPermission"
          @click="handleEdit(row)"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.BUTTON__EDIT}-${row.id}`"
        >
          Edit
        </ez-button>
      </template>
    </ez-table>

    <empty-state v-if="shouldShowEmptyState">
      <template #badge>
        <img src="@/assets/no-products.svg" width="160" alt="" />
      </template>
      <template #title>Inventory overview not available</template>
      <template #info>
        <template v-if="isNotTrackingProduct">
          In order to have access to the inventory overview, turn on product tracking for this
          particular product.
        </template>
        <template v-else>Inventory tracking is on, but there is no inventory added yet.</template>
      </template>
    </empty-state>

    <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="loadMore" />

    <ez-form-modal ref="editModal" class="edit-modal">
      <template #title>Edit Batch Details</template>

      <template #content>
        <p class="m-0">Choose what information you would like to change</p>
        <hr />
        <ez-input
          form-key=""
          name="batchCode"
          label="Batch ID"
          class="mask-input mt-12"
          placeholder="Enter Batch ID"
          :value="editItem.batchCode || ''"
          :disabled="!hasEditInventoryItemPermission"
          @onChange="onInputChange('batchCode', $event)"
          :data-cy="
            supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.EDIT_BATCH_DETAILS_MODAL
              .INPUT__BATCH_CODE
          "
        />
        <ez-date-input
          form-key=""
          label="Expiry Date"
          name="expiryDate"
          :placeholder="getDateFormat()"
          :date-format="getDateFormat()"
          :disabled="!hasEditInventoryItemPermission"
          :class="['mask-input', 'mt-12', { 'empty-date': !editItem.expiryDate }]"
          :min="today"
          v-model="editItem.expiryDate"
          @onChange="onInputChange('expiryDate', $event)"
          @invalid="onInvalidExpiryDate"
          is-tooltip-error
          :data-cy="
            supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.EDIT_BATCH_DETAILS_MODAL
              .INPUT__EXPIRY_DATE
          "
        />
        <ez-mask-input
          v-if="$permission.has('seeCOGS')"
          form-key=""
          type="input"
          name="cogs"
          label="Cost of Goods Sold (COGS)"
          class="mask-input mt-12"
          :value="editItem.cogs || 0"
          :disabled="!hasEditInventoryItemPermission"
          :allow-negative-value="false"
          @input="onInputChange('cogs', $event)"
          :data-cy="
            supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.EDIT_BATCH_DETAILS_MODAL
              .INPUT__COGS
          "
        />
      </template>

      <template #footer>
        <ez-button type="link" @click="close">Cancel</ez-button>
        <ez-button
          type="primary"
          @click="save"
          :disabled="saveDisabled"
          :is-loading="isSaving"
          :data-cy="
            supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_OVERVIEW.EDIT_BATCH_DETAILS_MODAL
              .BUTTON__SAVE
          "
        >
          Save Changes
        </ez-button>
      </template>
    </ez-form-modal>
  </div>
</template>

<style lang="scss" scoped>
.subheader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;

  &__description {
    max-width: 400px;

    &__title {
      @include font-size(14px, 22px);
      font-weight: 600;
      margin: 0 0 4px;
    }

    &__copy {
      @include font-size(14px, 20px);
      color: $color-gray-6C;
      margin: 0;
    }
  }
}

.inventory-items {
  .cogs-cell :deep() span {
    @include font-size(14px, 20px);
    color: $color-gray-6C;
    font-weight: 400;
  }

  .initial-quantity-cell,
  .current-quantity-cell {
    width: 130px;
  }

  .edit-inventory-item-btn:disabled {
    background-color: #f5f6fa;
    color: rgba($color-gray-6C, 0.48);
  }

  .indicator-cell {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    overflow: visible;
    width: 28px;
    color: $color-primary-orange-red;
  }
}

.edit-modal {
  .mask-input {
    :deep() .mask-input__input {
      font-weight: normal;
    }

    &.empty-date :deep() .placeholder {
      color: $color-gray-6C;
    }
  }
}
</style>
