<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Bold,
  Heading,
  Italic,
  Underline,
  ListItem,
  BulletList,
  OrderedList,
  Link,
  Blockquote,
  Code,
  Strike,
  HorizontalRule,
} from 'tiptap-extensions';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import EzButton from '@/components/ui/Button/EzButton';
/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EditorMenuBar,
    EditorContent,
    EzSimpleDropdown,
    EzButton,
  },
  props: {
    initData: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  methods: {
    initEditor() {
      this.editor = new Editor({
        content: this.initData,
        extensions: [
          new Bold(),
          new Heading({
            levels: [1, 2, 3],
          }),
          new Italic(),
          new Underline(),
          new ListItem(),
          new BulletList(),
          new OrderedList(),
          new Link(),
          new Blockquote(),
          new Code(),
          new Strike(),
          new HorizontalRule(),
        ],
        autoFocus: true,
        onUpdate: ({ getHTML }) => {
          const newContent = getHTML();
          this.$emit('update', newContent);
        },
      });
    },
    setLink(command) {
      // eslint-disable-next-line no-alert
      const url = prompt('URL:');
      command({ href: url });
    },
  },
  mounted() {
    this.initEditor();
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
<template>
  <div class="text-editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="text-editor__header">
        <ez-simple-dropdown
          placeholder="Text"
          class="heading text-editor__header--divider">
          <template #dropdown>
            <div class="dropdown-actions">
              <ez-button
                :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                @click="commands.heading({ level: 1})"
                class="heading1"
                type="link">
                Heading 1
              </ez-button>
              <ez-button
                :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                @click="commands.heading({ level: 2 })"
                class="heading2"
                type="link">
                Heading 2
              </ez-button>
              <ez-button
                :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                @click="commands.heading({ level: 3 })"
                class="heading3"
                type="link">
                Heading 3
              </ez-button>
            </div>
          </template>
        </ez-simple-dropdown>
        <div class="text-editor__header--divider">
          <button
            :class="['text-editor__header__commands', { 'is-active': isActive.bold() }]"
            @click="commands.bold">
            <font-awesome-icon icon="bold" />
          </button>
          <button
            :class="['text-editor__header__commands', { 'is-active': isActive.italic() }]"
            @click="commands.italic">
            <font-awesome-icon icon="italic" />
          </button>
          <button
            :class="['text-editor__header__commands', { 'is-active': isActive.underline() }]"
            @click="commands.underline">
            <font-awesome-icon icon="underline" />
          </button>
        </div>
        <div class="text-editor__header--divider">
          <button
            :class="['text-editor__header__commands', { 'is-active': isActive.bullet_list() }]"
            @click="commands.bullet_list">
            <font-awesome-icon icon="list-ul" />
          </button>
          <button
            :class="['text-editor__header__commands', { 'is-active': isActive.ordered_list() }]"
            @click="commands.ordered_list">
            <font-awesome-icon icon="list-ol" />
          </button>
        </div>
        <div>
          <button
            :class="['text-editor__header__commands', { 'is-active': isActive.link() }]"
            @click="setLink(commands.link)">
            <font-awesome-icon icon="link" />
          </button>
          <button
            :class="['text-editor__header__commands', { 'is-active': isActive.blockquote() }]"
            @click="commands.blockquote">
            <font-awesome-icon icon="quote-right" />
          </button>
          <button
            :class="['text-editor__header__commands', { 'is-active': isActive.code() }]"
            @click="commands.code">
            <font-awesome-icon icon="code" />
          </button>
          <button
            class="text-editor__header__commands"
            @click="commands.horizontal_rule">
            <font-awesome-icon icon="minus" />
          </button>
        </div>
      </div>
    </editor-menu-bar>
    <editor-content class="text-editor__content" :editor="editor" />
  </div>
</template>
<style lang="scss" scoped>
  .text-editor {
    border: 1px solid #DEE1E4;
    border-radius: 3px;
    max-width: 600px;
    height: 460px;
    overflow: hidden;
    overflow-y: scroll;

    &__header {
      display: flex;
      position: sticky;
      top: 0;
      width: 100%;
      background-color: #fff;
      z-index: 2;
      padding: 12px 16px;

      &--divider {
        padding-right: 10px;
        border-right: 1px solid #DEE1E4;
        margin-right: 10px;
      }
      &__commands {
        width: 24px;
        height: 24px;
        color: $color-gray-6C;
        &:hover {
          background-color: $color-gray-F5;
        }
      }
      .is-active {
        background-color: $color-gray-F5;
      }
    }

    &__content {
      width: 100%;
      height: auto;
      padding: 0 24px 12px 24px;
      :deep() .ProseMirror {
        color: #6C7995;
        @include font-size(16px);
        width: 100%;
        height: auto;
        &-focused {
          outline: none;
        }
        h1, h2 {
          color: $color-gray-25;
        }
        h1 {
          @include font-size(24px, 32px);
        }
        h2 {
          @include font-size(20px, 28px);
          font-weight: 600;
        }

        code {
          @include font-size(16px);
          background-color: $color-gray-F5;
          color: $color-gray-6C;
          padding: 12px 16px;
        }
        blockquote {
          margin-left: 0;
          padding-left: 16px;
          border-left: 1px solid $color-gray-E9;
        }
      }
    }

    .heading {
      :deep() .ez-simple-dropdown__display-container {
        height: 24px;
        background-color: #fff;
      }
      :deep() .ez-simple-dropdown__dropdown {
        width: 170px;
      }
      .dropdown-actions {
        .heading1, .heading2, .heading3 {
          font-weight: 600;
          color: $color-gray-25;
          width: 100%;
          text-align: left;
        }
        .heading1 {
          @include font-size(24px);
        }
        .heading2 {
          @include font-size(20px);
        }
        .heading3 {
          @include font-size(16px);
        }
      }
    }
  }
</style>
