<template>
  <ez-form-modal
    ref="specialTierModal"
    class="ez-special-tier-modal">
    <template #title>{{ title }}</template>
    <template #content>
      <div class="ez-special-tier-modal__tiers">
        <ez-input
          :name="`specialTierPricings[${specialTierIndex}][name]`"
          :formKey="formKey"
          :value="tier.name"
          placeholder="Enter Special Tier Name"
          label="Special Tier Name"
          @onChange="onNameChange"></ez-input>
        <ez-tiers
          :name="`specialTierPricings[${specialTierIndex}]`"
          :formKey="formKey"
          :tier="tier"
          @addTier="onAddTier"
          @removeTier="onRemoveTier"
          @tierChange="onTierChange"></ez-tiers>
      </div>

      <div class="ez-special-tier-modal__venues">
        <div class="ez-special-tier-modal__venues__title">
          Assign {{ $t('global.venues') }}
          <div
            v-if="specialTierMissingVenue(specialTierIndex)"
            class="ez-special-tier-modal__venues__title--has-error">
            Please assign at least one {{ $t('global.venue') | lowercase }} to this tier
          </div>
        </div>
        <ez-table
          :data="availableVenues"
          :headers="false"
          :columns="['name', 'actions']"
          disable-hover>
          <template #cell-name="{ row }">
            <ez-entity-info
              :imgUrl="row.logo"
              :imgHasBorder="true"
              imgWidth="3em"
              imgHeight="3em"
              imgBorderRadius="50%">
              <div class="venue-info">
                <span class="venue-info__name" :title="row.name">{{ row.name }}</span>
                <span :class="`venue-info__status status-${row.status || 'draft'}`">
                  {{ row.status|capitalize }}
                </span>
              </div>
            </ez-entity-info>
          </template>
          <template #cell-actions="{ row }">
            <div style="margin-left: auto; width: 10em;">
              <ez-toggle
                @toggleState="toggleAssignVenue(row)"
                :isActive="tier.venues.includes(row.id)"
              >
                <template #active><span>Assigned</span></template>
                <template #inactive><span>Assign</span></template>
              </ez-toggle>
            </div>
          </template>
        </ez-table>
      </div>
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="onCancelClick">Cancel
      </ez-button>
      <ez-button
        formType="button"
        :disabled="specialTierHasError"
        @click="onAddTierClick">Add
      </ez-button>
    </template>
  </ez-form-modal>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import EzButton from '@/components/ui/Button';
import EzEntityInfo from '@/components/ui/EntityInfo';
import { EzFormModal } from '@/components/ui/Modal';
import EzInput from '@/components/ui/Input';
import EzTiers from '@/components/ui/Tiers';
import EzTable from '@/components/ui/Table';
import EzToggle from '@/components/ui/Toggle';

export default {
  components: {
    EzButton,
    EzEntityInfo,
    EzFormModal,
    EzInput,
    EzTiers,
    EzTable,
    EzToggle,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    tier: {
      type: Object,
      required: true,
    },
    specialTierIndex: {
      type: Number,
      required: false,
    },
  },
  computed: {
    availableVenues() {
      return this.getAvailableVenuesForProduct();
    },
    specialTierFormErrors() {
      return this.getFormErrors(this.formKey);
    },
    specialTierHasError() {
      return this.specialTierHasErrors(this.productId, this.specialTierIndex);
    },
    ...mapGetters('entities/products', [
      'getAvailableVenuesForProduct',
      'specialTierHasErrors',
    ]),
    ...mapGetters('errors', [
      'getFormErrors',
    ]),
  },
  methods: {
    open() {
      this.$refs.specialTierModal.open();
    },
    close() {
      this.$refs.specialTierModal.close();
    },
    onAddTierClick() {
      this.APPLY_SPECIAL_TIER_CHANGES();
      this.close();
    },
    onCancelClick() {
      this.close();
    },
    onAddTier() {
      this.NEW_SPECIAL_TIER_ITEM(this.tier);
    },
    onRemoveTier({ id }) {
      this.REMOVE_SPECIAL_TIER_ITEM(id);
    },
    onTierChange(tier) {
      this.UPDATE_SPECIAL_TIER_ITEM(tier);
    },
    onNameChange(name) {
      this.UPDATE_SPECIAL_TIER_ITEM({ ...this.tier, name });
    },
    toggleAssignVenue(venue) {
      this.TOGGLE_SPECIAL_TIER_VENUE(venue);
    },
    specialTierMissingVenue(tierIndex) {
      const { specialTierFormErrors } = this;

      return !!specialTierFormErrors[`specialTierPricings[${tierIndex}][venueIds]`];
    },
    ...mapMutations('entities/products', [
      'NEW_SPECIAL_TIER_ITEM',
      'UPDATE_SPECIAL_TIER_ITEM',
      'TOGGLE_SPECIAL_TIER_VENUE',
      'REMOVE_SPECIAL_TIER_ITEM',
      'APPLY_SPECIAL_TIER_CHANGES',
    ]),
    ...mapMutations('errors', [
      'CLEAR_ERROR',
    ]),
  },
};
</script>
<style scoped lang="scss">
  $table-border-radius: .6em;
  $error-color: #EA4B5D;

  .ez-special-tier-modal {
    @include font-size(14px);

    &__tiers {
      padding: 1.5em 1.5em 2em 1.5em;
    }
    &__venues {
      padding: 2em 1.5em 1.5em;
      &__title {
        @include font-size(18px);
        font-weight: 600;
        padding-bottom: 1em;
        &--has-error {
          @include font-size(12px);
          color: $error-color;
        }
      }

      :deep() .table {
        min-width: auto;
        tr:first-child {
          td:first-child {
            border-top-left-radius: $table-border-radius;
          }
          td:last-child {
            border-top-right-radius: $table-border-radius;
          }
        }
        .venue-info {
          @include name-status-group();
          margin-left: 1em;
        }
      }
    }
    :deep() .ez-tiers {
      margin-top: 1.2em;

      &__inputs {
        .input-group {
          max-width: 16.5em;
        }
      }

      :deep() .button-link {
        margin: 0;
      }
    }
    :deep() .modal {
      &__inner { max-height: 90%; }
      overflow-y: hidden;
      &__text {
        padding: 0;
        overflow-y: auto;
      }
      &__content {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }
      &__header {
        flex-shrink: 0;
      }
      .button--primary {
        width: 13em;
      }
    }
  }
</style>
