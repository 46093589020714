<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import EzFormModal from '@/components/ui/Modal/EzFormModal';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import EzButton from '@/components/ui/Button/EzButton';
import EzInput from '@/components/ui/Input';
import { mapActions, mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';

const Event = {
  open: 'open',
  close: 'close',
  submitSuccess: 'submitSuccess',
  submitError: 'submitError',
};

export default {
  name: 'CreateTargetAccountModal',
  components: {
    EzButton,
    EzFormModal,
    EzDropdown,
    EzInput,
  },
  props: {
    /**
     * Can be used to set value for props in data object
     * (usage) :prefilled-data="{ accountOwnerId: 170 }" //will set accountOwnerId = 170;
     */
    prefilledData: {
      required: false,
      type: Object,
      default: () => {},
    },
    toHideAccountOwnerSelect: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formKey: 'createTargetAccountForm',
      selectedVenue: null,
      name: '',
      accountOwnerId: null,
      accountRepsOptions: [],
      supplierCy,
    };
  },
  watch: {
    prefilledData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val || this.isLoading) {
          return;
        }
        Object.keys(val).forEach((key) => {
          this[key] = val[key];
        });
      },
    },
  },
  computed: {
    ...mapGetters('defaultImages', ['getDefaultImage']),
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    isFormValid() {
      return this.name;
    },
    defaultImage() {
      return this.getDefaultImage('venue');
    },
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.CREATE_TARGET_ACCOUNT]);
    },
  },
  methods: {
    ...mapActions('defaultImages', ['fetchDefaultImages']),
    ...mapActions('reports', ['createTargetAccount', 'fetchAccountRepsAssignable']),
    clearComponentState() {
      this.name = null;
      this.accountOwnerId = null;
    },
    onCloseModal() {
      this.clearComponentState();
      this.$emit('onClose');
    },
    open() {
      this.$refs.modal.open();
      this.$emit(Event.open);
    },
    close() {
      this.$refs.modal.close();
      this.$emit(Event.close);
    },
    removeSelectedVenue() {
      this.selectedVenue = null;
      this.name = '';
      this.isFirst = false;
    },
    onAccountRepSelect(selectedRep) {
      this.accountOwnerId = selectedRep && selectedRep.id;
    },
    async addTargetAccount() {
      try {
        const res = await this.createTargetAccount({
          name: this.name,
          ...(this.accountOwnerId ? { distributorAccountOwnerId: this.accountOwnerId } : {}),
        });
        this.clearComponentState();
        this.$emit(Event.submitSuccess, res);
        this.close();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.$emit(Event.submitError, err.response);
      }
    },
  },
  async created() {
    await this.fetchDefaultImages();
    const { data } = await this.fetchAccountRepsAssignable();
    this.accountRepsOptions = [
      {
        id: null,
        name: 'Unassigned',
      },
      ...data.data,
    ];
  },
};
</script>

<template>
  <ez-form-modal ref="modal" @close="onCloseModal">
    <template #title>
      <div>
        Create New Target Account
      </div>
    </template>
    <template #content>
      <div>
        <p class="m-0">
          Enter the name of the {{ $t('global.venue') | lowercase }} and assign an account representative
          for this target account.
        </p>
        <hr />
        <div class="mb-16">
          <ez-input
            form-key="createTargetAccount"
            :value="name"
            @onChange="ev => name=ev"
            :placeholder="`Enter ${$t('global.venue')} Name`"
            name="name"
          />
        </div>
        <ez-dropdown
          v-if="!toHideAccountOwnerSelect"
          class="mb-16"
          :form-key="formKey"
          name="accountRep"
          :label="`${$t('global.accountRep')}`"
          :placeholder="`Select ${$t('global.accountRep')}`"
          :is-full-width="true"
          :data="accountRepsOptions"
          :selected="accountOwnerId"
          :disabled="!$permission.has('seeCRMForAll')"
          @change="onAccountRepSelect"
          :data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.BUTTON__ACCOUNT_REP"
        />
      </div>
    </template>
    <template #footer>
      <div>
        <ez-button
          type="link"
          @click="close"
          :data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.BUTTON__CANCEL"
        >
          Cancel
        </ez-button>
        <ez-button
          :disabled="!isFormValid"
          :is-loading="isLoading"
          @click="addTargetAccount"
          :data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.BUTTON__ADD_TODO"
        >
          Create Target Account
        </ez-button>
      </div>
    </template>
  </ez-form-modal>
</template>

<style scoped lang="scss"></style>
