<script>
/**
 * Index
 * @version 1.0.0
 * @since
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import EzNav from '@/components/ui/Nav';
import VPage from '@/components/v3/templates/VPage';
import VTotalAmount from '@/components/v3/patterns/VTotalAmount';
import { mapState } from 'vuex';

export default {
  components: {
    VTotalAmount,
    EzNav,
    VPage,
  },
  data() {
    return {
      supplierCy,
    };
  },
  computed: {
    ...mapState('reports', ['meta']),
  },
};
</script>
<template>
  <v-page>
    <template #title>{{ $t('reports.general.title') }}</template>
    <template #subtitle>{{ meta.subtitle }}</template>
    <template v-if="meta.count !== null && meta.count !== undefined" #info>
      <v-total-amount >
        <template #amount>{{ meta.count }}</template>
      </v-total-amount>
    </template>
    <template>
      <ez-nav class="mt-16">
        <template #nav>
          <ul>
            <li>
              <router-link
                :to="{ name: 'distributor-reports-venues' }"
                :data-cy="supplierCy.REPORTS.NAVIGATION.BUTTON__OUTLETS"
              >
                {{ $t('global.venues') }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'distributor-reports-products' }"
                :data-cy="supplierCy.REPORTS.NAVIGATION.BUTTON__PRODUCTS"
              >
                Products
              </router-link>
            </li>
          </ul>
        </template>
        <router-view />
      </ez-nav>
    </template>
  </v-page>
</template>

<style lang="scss" scoped>
:deep() .stats-boxes--large {
  .stats-box__value {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 24px;
  }
}

:deep() .tax-excluded-icon {
  color: $color-gray-6C;
}
</style>
