<template>
  <div class="container--small">
    <div class="overview-item">
      <ez-form
        ref="form"
        :action="actionDormant"
        :form-key="formKey"
        :transformer="transformer"
        method="put"
        @success="onEditFormSubmit"
        @error="onSubmitError"
      >
        <v-customer-categories-settings
          :selected-id="customerCategoryId"
          :data-cy="{}"
          name="customerCategoryId"
          @onSelect="onSelectCategory"
        />
        <div class="help-block mb-16">
          <h4 class="help-block__title">{{ $t('global.accountRep') }}</h4>
          <p class="help-block__text">
            Assign an {{ $t('global.accountRep') }} that will be responsible for
            this {{ $t('global.venue') | lowercase }}.
          </p>
        </div>
        <ez-dropdown
          :disabled="!canChangeAccountOwner"
          :form-key="formKey"
          class="venue-overview__select"
          name="distributorAccountOwnerId"
          :label="`${$t('global.accountRep')}`"
          placeholder="Unassigned"
          :isFullWidth="true"
          :data="options"
          :selected="accountOwnerId"
        />
        <hr class="address-separator" />

        <div class="help-block help-block mb-16">
          <h4 class="help-block__title custom-title">Payment Terms</h4>
        </div>
        <ez-radio-button
          :form-key="formKey"
          :data="venuePaymentTerm || ''"
          name="paymentTerms"
          value="no_terms"
          label="No Terms"
          class="radio-btn"
          @change="updatePaymentTerms('no_terms')"
        />
        <ez-radio-button
          v-if="paymentTermCash"
          :form-key="formKey"
          :label="paymentTermCash.name"
          :data="venuePaymentTerm || ''"
          name="paymentTerms"
          value="cash_on_delivery"
          class="radio-btn mt-16"
          @change="updatePaymentTerms(paymentTermCash.key)"
        />
        <div class="radio-info">
          Pay the full amount upon the delivery of the order.
        </div>

        <ez-radio-button
          v-if="paymentTermsOptions.length"
          :form-key="formKey"
          :data="net"
          name="paymentTerms"
          value="net"
          label="Net"
          class="radio-btn mt-16"
          @change="updatePaymentTerms('net')"
        />
        <div class="radio-info">
          Pay the full amount within a certain time period.
        </div>

        <ez-dropdown
          v-if="paymentTermsOptions.length"
          :form-key="formKey"
          :disabled="net !== 'net'"
          :data="paymentTermsOptions"
          :selected="selectedPaymentPeriod"
          idKey="key"
          name="paymentTerms"
          label="Payment Period"
          placeholder="Payment Period"
          class="mb-8 mt-12"
          @change="updateNet"
          is-full-width
        />

        <hr />

        <div class="help-block mb-16">
          <h4 class="help-block__title">Tax Rate Applicable</h4>
          <p class="help-block__text">
            <template>Tax that will be added to the final order amount.</template>
          </p>
        </div>
        <ez-dropdown
          :data="taxOptions"
          :form-key="formKey"
          is-full-width
          idKey="value"
          name="taxCalculation"
          label="Tax Exclusive / Inclusive / Exempt"
          :selected="venue.connection?.taxCalculation || defaultTaxCalculation"
          @change="onTaxCalculationChange"
          class="mb-16"
        />
        <div class="tax-wrapper">
          <ez-input
            class="mt-16"
            :form-key="formKey"
            placeholder="Tax Rate"
            label="Tax Rate"
            type="number"
            name="tax"
            step="any"
            :value="taxRateDisabled ? 0 : venueTax"
            min="0"
            :disabled="isDistributorXeroConnected || taxRateDisabled"
            :disabledTooltip="taxRateDisabled ? 'Tax Exempt set' : 'Update in accounting integration'"
          >
            <template #suffix>%</template>
          </ez-input>
          <ez-button
            class="ml-12"
            type="secondary"
            v-if="isDistributorXeroConnected"
            @click="goToAccountingIntegration"
          >
            Settings
          </ez-button>
        </div>
        <hr />

        <div class="help-block mb-16">
          <h4 class="help-block__title">Default Warehouse</h4>
          <p class="help-block__text">
            The warehouse where {{ $t('global.venue') }}’s orders are fulfilled
            from. This can be changed during order acceptance. If inventory
            levels are displayed to {{ $t('global.venue') }}, only inventory
            from this warehouse will be used.
          </p>
        </div>
        <ez-dropdown
          v-if="warehouses.length"
          :form-key="formKey"
          :data="warehouses"
          :selected="selectedWarehouseId"
          name="primaryWarehouseId"
          placeholder="Default Warehouse"
          class="mb-16 mt-12"
          @change="updateWarehouse"
          is-full-width
        />

        <div class="help-block mb-16">
          <h4 class="help-block__title">Default Driver</h4>
          <p class="help-block__text">
            The user who is delivering orders to this {{ $t('global.venue') }}. This can be changed
            during order creation or scheduling for delivery.
          </p>
        </div>
        <ez-dropdown
          :formKey="formKey"
          :data="drivers"
          :selected="defaultDriverId"
          name="defaultDriverId"
          placeholder="Select Default Driver"
          @change="updateDriver"
          isFullWidth
          class="mb-16 mt-12"
        />

        <div class="help-block mb-16">
          <h4 class="help-block__title">Tags</h4>
          <p class="help-block__text">
            Create or Add Customer {{ $t('global.tag') }}(s)
          </p>
        </div>
        <span class="button-description">Customer {{ $t('global.tags') }}</span>
        <ez-button v-if="!customerTags.length" type="secondary" is-full-width @click="openTagsModal"
          :isLoading="isLoading">
          <font-awesome-icon icon="plus" />
          Add Tags
        </ez-button>
        <ez-button v-else type="secondary" is-full-width @click="openTagsModal" class="height-fit-content"
          :isLoading="isLoading">
          <div class="all-tags">
            <v-tag
              v-for="tag in customerTags"
              :key="tag.id"
              :color="tag.color.colors[0]"
              :backgroundColor="tag.color.colors[1]"
              @click.stop="onClick(tag)"
              canRemove
            >
              {{ tag.name }}
            </v-tag>
          </div>
          <font-awesome-icon icon="pen" class="icon-absolute" />
        </ez-button>
        <add-tags-modal ref="addTagsModal" @confirm="onAddConfirm" :tagType="TagType.Customer" />
      </ez-form>
    </div>
  </div>
</template>

<script>
import EzDropdown from '@/components/ui/Dropdown/EzDropdown.vue';
import VCustomerCategoriesSettings from '@/components/v3/patterns/VCustomerCategoriesSettings/index.vue';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzForm from '@/components/ui/Form/EzForm.vue';
import EzRadioButton from '@/components/ui/RadioButton/EzRadioButton.vue';
import EzButton from '@/components/ui/Button/EzButton';
import { LOADING_KEY, UNASSIGNED_ACCOUNT, TagType, TAX_CALCULATION_OPTIONS } from '@/util/constants';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import flash from '@/components/ui/FlashMessage';
import { clone } from '@/util/utils';
import { wizardListenerMixin } from '@/mixins/wizard';
import AddTagsModal from '@/components/v3/elements/VProductTagsSettings/AddTagsModal.vue';
import VTag from '@/components/v3/elements/VTag';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzForm,
    EzDropdown,
    EzRadioButton,
    EzInput,
    VCustomerCategoriesSettings,
    EzButton,
    AddTagsModal,
    VTag,
  },
  data() {
    return {
      venue: {},
      options: [],
      users: [],
      formKey: 'dormant-form-key',
      unassignedUser: UNASSIGNED_ACCOUNT,
      paymentTerms: '',
      paymentTermsOptions: [],
      paymentTermCash: null,
      net: '',
      warehouses: [],
      selectedWarehouseId: null,
      drivers: [],
      defaultDriverId: null,
      customerCategoryId: null,
      supplierCy,
      customerTags: [],
      customerTagsIds: [],
      TagType,
      taxOptions: [
        {
          id: 0,
          value: null,
          name: 'Default',
        },
        ...TAX_CALCULATION_OPTIONS,
      ],
      defaultTaxCalculation: null,
      taxRateDisabled: false,
    };
  },
  computed: {
    ...mapGetters('entities/users', ['isDistributorXeroConnected']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapState('entities/venues', ['draft']),
    ...mapState('tags', ['selectedTags', 'selectedTagsIds']),
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_TAGS,
        LOADING_KEY.FETCH_TAG_COLORS,
      ]);
    },
    venueId() {
      return this.draft.id;
    },
    actionDormant() {
      return `/distributor/venues/${this.venue.id}/connection-options`;
    },
    accountOwnerId() {
      return this.venue.accountOwner ? this.venue.accountOwner.id : null;
    },
    canChangeAccountOwner() {
      return this.$permission.has('assignAccountOwner');
    },
    ifNetSelected() {
      return !!this.paymentTermsOptions?.find(pt => pt.key === this.venuePaymentTerm);
    },
    venuePaymentTerm() {
      return (this.venue.connection || {}).paymentTerms;
    },
    venueTax() {
      if (![null, undefined].includes(this.venue.connection?.tax)) return this.venue.connection.tax;
      return (this.loggedUser.distributor || {}).tax;
    },
    selectedPaymentPeriod() {
      return (this.ifNetSelected && this.venuePaymentTerm) || this.paymentTermsOptions[0].key;
    },
  },
  methods: {
    ...mapActions('entities/users', ['fetchAccountOwners']),
    ...mapActions('reports', ['fetchVenue']),
    ...mapActions('entities/distributors', [
      'distributorGetPaymentTerms',
      'distributorFetchWarehouses',
      'distributorFetchDrivers',
    ]),
    ...mapActions('tags', [
      'fetchTagsByType',
      'fetchTagColors',
    ]),
    ...mapMutations('entities/venues', [
      'UPDATE_SETTINGS_STEP',
      'UPDATE_CUSTOMER_TAGS',
    ]),
    ...mapMutations('tags', [
      'REMOVE_SELECTED_TAG',
      'SET_SELECTED_TAGS',
    ]),
    onEditFormSubmit({ data }) {
      this.UPDATE_SETTINGS_STEP(data.data);
      this.UPDATE_CUSTOMER_TAGS({ tags: data.data?.tags });
      flash.success({
        title: `${this.$t('global.venue')} successfully updated!`,
      });
      this.$emit('stepCompleted');
      const { tags } = this.draft;
      this.customerTags = clone(tags);
      this.customerTagsIds = tags.map(item => item.id);
    },
    async openTagsModal() {
      await this.fetchTagsByType({ query: { type: TagType.Customer } });
      await this.fetchTagColors();
      this.SET_SELECTED_TAGS(this.customerTagsIds);
      this.$refs.addTagsModal.open();
    },
    onClick(tag) {
      this.REMOVE_SELECTED_TAG(tag);
      this.customerTagsIds = this.customerTagsIds.filter(item => item !== tag.id);
      this.customerTags = this.customerTags.filter(item => item.id !== tag.id);
    },
    onAddConfirm() {
      this.customerTags = clone(this.selectedTags);
      this.customerTagsIds = clone(this.selectedTagsIds);
      this.$refs.addTagsModal.close();
    },
    onSubmitError() {
      flash.error({
        title: 'Something went wrong.',
      });
    },
    updatePaymentTerms(type) {
      if (type === 'net') {
        this.net = type;
        this.paymentTerms = this.venuePaymentTerm || this.paymentTermsOptions[0].key;
      } else {
        this.net = '';
        this.paymentTerms = type;
      }
    },
    updateNet(net) {
      this.paymentTerms = net.key;
    },
    updateWarehouse(warehouse) {
      this.selectedWarehouseId = warehouse.id;
    },
    updateDriver(driver) {
      this.defaultDriverId = driver.id;
    },
    transformer(data) {
      return { ...data, paymentTerms: this.paymentTerms, tagIds: this.customerTagsIds };
    },
    onSelectCategory(category) {
      this.customerCategoryId = category.id;
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    onNextStep() {
      this.$refs.form.onSubmit();
    },
    onTaxCalculationChange(ev) {
      if (ev.value === 'exempt') this.taxRateDisabled = true;
      else this.taxRateDisabled = false;
    },
    goToAccountingIntegration() {
      const routerData = this.$router.resolve({
        name: 'distributor-xero-venues',
      });
      window.open(routerData.href, '_blank');
    },
  },
  async created() {
    this.users = await this.fetchAccountOwners();
    this.options = [this.unassignedUser, ...this.users];
    const [
      { data: { data: venueData } },
      { data: { data: warehousesData } },
      { data: { data: driversData } },
      { data: { data: paymentTermsOptions } },
    ] = await Promise.all([
      this.fetchVenue({ venueId: this.venueId }),
      this.distributorFetchWarehouses(),
      this.distributorFetchDrivers(),
      this.distributorGetPaymentTerms(),
    ]);

    this.venue = venueData;
    this.warehouses = warehousesData;
    this.selectedWarehouseId = venueData.connection?.primaryWarehouseId ?? null;
    this.drivers = driversData;
    this.defaultDriverId = venueData.connection?.defaultDriverId ?? null;
    this.paymentTermsOptions = paymentTermsOptions.filter(terms => terms.type === 'net');
    this.paymentTermCash = paymentTermsOptions.find(terms => terms.type === 'cash_on_delivery');
    this.customerCategoryId = venueData.connection?.customerCategoryId;
    this.paymentTerms = venueData.connection?.paymentTerms;

    if (this.ifNetSelected) this.updatePaymentTerms('net');
    this.enableNextStep();

    const { tags } = this.draft;
    this.customerTags = clone(tags);
    this.customerTagsIds = tags.map(item => item.id);

    if (this.venue.connection?.taxCalculation === 'exempt') this.taxRateDisabled = true;
  },
  watch: {
    selectedWarehouseId(val) {
      if (!val) this.warehouses = this.warehouses.filter(w => !!w.id);
      else this.warehouses = [{ id: null, name: 'Default Warehouse' }, ...this.warehouses];
    },
  },
};
</script>

<style scoped lang="scss">
.help-block {
  @include font-size(14px);

  &__title {
    margin-bottom: 8px;
  }

  &__text {
    margin-top: 8px;
    color: $color-gray-6C;
  }
}

.radio-btn {
  :deep() .ez-radio-button__label {
    color: $color-gray-25;
    @include font-size(14px, 20px);
  }
}

.radio-info {
  margin-left: 20px;
  color: $color-gray-6C;
  @include font-size(12px, 18px);
}
.all-tags {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 14px;
}

.button.height-fit-content {
  height: auto;
  padding-right: 42px;
  position: relative;

  .icon-absolute {
    position: absolute;
    top: 10px;
    right: 12px;
  }
}
.button-description {
  color: $color-gray-6C;
  font-size: 12px;
  line-height: 18px;

  & + button {
    margin-top: 6px;
  }
}
.tax-wrapper {
  :deep() .input-group {
    flex: 1;
  }
  display: flex;
  align-items: flex-end;
  width: 100%;
}
</style>
