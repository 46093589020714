<template>
  <ez-confirmation-modal @close="onClose" ref="approveModal" icon="question">
    <template #title>Schedule for Delivery?</template>
    <template #content>
      <div>
        <template v-if="shouldShowWarehouseDropdown">
          <ez-select
            ref="select"
            name="warehouseId"
            label="Fulfill Order From"
            :selected="warehouseId"
            :value="warehouseId"
            :options="warehouses"
            @change="onWarehouseSelectChange"
            isFullWidth
            :data-cy="supplierCy.ORDERS.SINGLE_ORDER.SCHEDULE_FOR_DELIVERY_MODAL.BUTTON__WAREHOUSE"
          />
          <hr />
        </template>

        <template v-if="!(isDistributorProvinoConnected && isProvinoWarehouse)">
          <p v-if="order.requestedDeliveryAt">
            {{ $t('global.venue') }} has requested the delivery date for this order. <br />
            You can decline it if you are not able to complete the delivery on the requested date.
          </p>
          <p v-else>
            Choose when your order will be delivered to the {{ $t('global.venue') | lowercase }}.
          </p>
          <ez-form
            :action="action"
            method="patch"
            submit-type="multipart"
            ref="scheduleForm"
            :additional-data="additionalData"
            @success="scheduleFormSuccess"
            :formKey="formKey"
            :additional-headers="{ loadingKey }"
          >
            <ez-alert v-if="isBefore" :type="scheduleBanner.banner" size="inline" class="banner">
              <template #icon><font-awesome-icon :icon="scheduleBanner.icon" /></template>
              {{ scheduleBanner.message }}
            </ez-alert>
            <v-date-picker
              class="date-picker"
              name="month"
              :label="order.requestedDeliveryAt ? 'Requested Delivery Date' : 'Delivery Date'"
              v-model="selectedScheduled"
              formKey="filters"
              :deliveryDays="deliveryDays"
              @dateChange="updateDate"
              :data-cy="
                supplierCy.ORDERS.SINGLE_ORDER.SCHEDULE_FOR_DELIVERY_MODAL.INPUT__DELIVERY_DATE
              "
              is-full-width
            />
            <v-select-search
              :data="drivers"
              :selected="driverId"
              name="driverId"
              label="Delivery Driver"
              placeholder="No Driver"
              @selected="onDriverSelectChange"
              class="driver-dropdown mt-16"
              isFullWidth
            />
            <hr />
            <ez-notes :order="order" />
            <ez-textarea
              :form-key="formKey"
              :required="true"
              placeholder="Leave a Note"
              name="message"
              label="Note"
              class="mt-8"
              :data-cy="supplierCy.ORDERS.SINGLE_ORDER.SCHEDULE_FOR_DELIVERY_MODAL.TEXTAREA__NOTE"
            />
          </ez-form>

          <advanced-inventory-management
            v-if="shouldShowAdvancedOptions"
            :show="advancedOptions"
            :order="order"
            :warehouseId="warehouseId"
            @invalid="onInventoryManagementInvalid"
            @onChange="onInventoryManagementChange"
            :data-cy="
              supplierCy.ORDERS.SINGLE_ORDER.SCHEDULE_FOR_DELIVERY_MODAL
                .CONTAINER__ADVANCED_OPTIONS_CONTAINER
            "
            :error-data-cy="
              supplierCy.ORDERS.SINGLE_ORDER.SCHEDULE_FOR_DELIVERY_MODAL
                .TEXT__ADVANCED_OPTIONS_ERROR
            "
          />
        </template>
        <template v-else>
          <p>
            Order is scheduled for
            {{
              selectedMethod
                ? selectedMethod === defaultMethod
                  ? selectedMethod
                  : 'collection'
                : defaultMethod
            }}
            on {{ selectedDateTime.selectedCopy || firstAvailable }}
          </p>
          <ez-alert
            v-if="whichBanner && !advancedOptions"
            :type="whichBanner.banner"
            size="inline"
            class="banner"
          >
            <template #icon><font-awesome-icon :icon="whichBanner.icon" /></template>
            {{ whichBanner.message }}
          </ez-alert>
          <ez-alert
            v-if="isDiffNoteToShow && advancedOptions"
            type="red"
            size="inline"
            class="banner"
          >
            <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
            Selected delivery date is different from the one that {{ $t('global.venue') }} requested
            as a delivery date.
          </ez-alert>
          <hr />
          <ez-notes :order="order" />
          <ez-textarea
            @onChange="changeNote"
            :form-key="formKey"
            :required="true"
            placeholder="Leave a Note"
            name="message"
            label="Note"
            class="mt-8"
          />
          <div class="advanced-options" v-if="advancedOptions">
            <hr />
            <div class="help-block mb-20">
              <h4 class="help-block__title">Delivery Info</h4>
              <p class="help-block__text">Add all necessary information about the delivery.</p>
            </div>
            <ez-form
              :action="action"
              method="patch"
              submit-type="multipart"
              ref="scheduleForm"
              :additional-data="additionalData"
              :formKey="formKey"
              :additional-headers="{ loadingKey }"
              @success="scheduleFormSuccess"
            >
              <ez-dropdown
                name="provinoOptions[deliveryMethod]"
                label="Delivery Type"
                class="mb-8"
                :data="deliveryMethod || []"
                :selected="selectedMethod"
                is-full-width
                :formKey="formKey"
                idKey="key"
                placeholder="Select Delivery Method"
                @change="selectDeliveryMethod"
              />
              <ez-dropdown
                ref="deliveryTransport"
                v-if="selectedMethod === defaultMethod"
                name="provinoOptions[transportMode]"
                label="Transport Mode"
                class="mb-12"
                :data="transportMode || []"
                is-full-width
                :selected="selectedTransport || ''"
                :formKey="formKey"
                idKey="key"
                placeholder="Select Transport Mode"
                @change="selectTransport"
              />
              <ez-input
                :formKey="formKey"
                name="venueOrderNumber"
                placeholder="Reference Number"
                label="Reference Number"
                class="mb-12"
                :value="order.venueOrderNumber || ''"
              />
              <ez-input
                class="mb-12"
                :formKey="formKey"
                name="provinoOptions[notes]"
                placeholder="Delivery Instructions"
                :value="order.deliveryInstructions || ''"
                label="Delivery Instructions"
              />
              <v-provino-schedule-slot-picker
                label="Delivery Date and Time"
                v-if="slotsData"
                :selected="firstAvailableData"
                :data="slotsData"
                @slotSelected="slotSelected"
              />
              <div class="info-message-wrapper mt-16" v-if="selectedDateTime.type === 'urgent'">
                <ez-alert type="yellow-gray" size="inline" class="banner">
                  <template #icon><font-awesome-icon icon="info-circle" /></template>
                  This is a paid date and time slot. You will need to pay the additional fees to
                  {{ $t('provino.name') }} in order to use it.
                </ez-alert>
              </div>
              <div class="help-block mb-16 mt-16">
                <h4 class="help-block__title">Attachments</h4>
                <p class="help-block__text">
                  Choose which documents you want to attach. You can also upload <br />
                  custom documents.
                </p>
              </div>
              <div class="documents-checkboxes">
                <ez-checkbox
                  :formKey="formKey"
                  name="attachmentTypes[]"
                  label="Delivery Order"
                  value="delivery_order"
                  :checked="defaultData.attachmentType === 'delivery_order'"
                />
                <ez-checkbox
                  :formKey="formKey"
                  name="attachmentTypes[]"
                  label="Invoice"
                  value="invoice"
                  class="ml-16"
                  :checked="defaultData.attachmentType === 'invoice'"
                />
                <ez-checkbox
                  :formKey="formKey"
                  name=""
                  label="Custom Documents"
                  class="ml-16"
                  @change="() => (customFilesChecked = !customFilesChecked)"
                />
              </div>
              <div :class="['upload-wrapper', { 'upload-wrapper--narrow': imageData.length > 1 }]">
                <ez-multiple-image-upload
                  v-if="customFilesChecked"
                  cardType
                  ref="imageUpload"
                  accept="image/jpeg,image/png,application/pdf"
                  :formKey="formKey"
                  :number-of-files="5"
                  name="attachments"
                  class="upload-component"
                  @change="imagesUploaded"
                >
                  <template #label>Custom Documents</template>
                  <template #cta>Upload Files</template>
                </ez-multiple-image-upload>
              </div>
            </ez-form>
          </div>
        </template>
      </div>
      <ez-loader :show="checkIsSomeLoading || (isLoading && isDistributorProvinoConnected)">
        {{
          isLoading && isDistributorProvinoConnected && isProvinoWarehouse
            ? `Waiting on ${$t('provino.name')}. This may take a while...`
            : 'Loading...'
        }}
      </ez-loader>
    </template>
    <template #footer>
      <div class="footer-actions">
        <div>
          <ez-button
            v-if="isDistributorProvinoConnected ? true : shouldShowAdvancedOptions"
            type="blue-link"
            class="advanced-options-btn"
            @click="showAdvancedOptions"
            :data-cy="
              supplierCy.ORDERS.SINGLE_ORDER.SCHEDULE_FOR_DELIVERY_MODAL.BUTTON__ADVANCED_OPTIONS
            "
          >
            Advanced Options
            <font-awesome-icon class="ml-8" v-if="advancedOptions" icon="angle-up" />
            <font-awesome-icon class="ml-8" v-else icon="angle-down" />
          </ez-button>
        </div>
        <div class="standard-actions">
          <ez-button
            @click="close"
            type="link"
            :data-cy="supplierCy.ORDERS.SINGLE_ORDER.SCHEDULE_FOR_DELIVERY_MODAL.BUTTON__CANCEL"
          >
            Cancel
          </ez-button>
          <ez-button
            @click="scheduleForm"
            :disabled="scheduleDisabled"
            :is-loading="isLoading"
            :data-cy="supplierCy.ORDERS.SINGLE_ORDER.SCHEDULE_FOR_DELIVERY_MODAL.BUTTON__CONFIRM"
          >
            Confirm
          </ez-button>
        </div>
      </div>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import VProvinoScheduleSlotPicker from '@/components/v3/patterns/VProvinoScheduleSlotPicker';
import EzForm from '@/components/ui/Form/EzForm';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import { capitalizeWord, getDateFormat, formatDate } from '@/util/utils';
import EzInput from '@/components/ui/Input/EzInput';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import { EzMultipleImageUpload } from '@/components/ui/ImageUpload';
import { DEFAULT_DELIVERY_METHOD, DELIVERY_PERIODS, LOADING_KEY } from '@/util/constants';
import Order from '@/models/Order';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader/EzLoader';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import VDatePicker from '@/components/v3/patterns/VDatePicker';
import EzAlert from '@/components/ui/Alert/EzAlert';
import { EzNotes } from '@/views/common/orders/notes';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
import AdvancedInventoryManagement from '@/views/platform/distributor/orders/AdvancedInventoryManagement';
import EzSelect from '@/components/ui/Select';

/**
 * ApproveOrder
 * @version 1.0.0
 * @since 2.0.0
 */

export default {
  components: {
    EzConfirmationModal,
    EzButton,
    VProvinoScheduleSlotPicker,
    EzForm,
    EzDropdown,
    EzInput,
    EzCheckbox,
    EzMultipleImageUpload,
    EzLoader,
    VSelectSearch,
    VDatePicker,
    EzAlert,
    EzNotes,
    EzTextarea,
    AdvancedInventoryManagement,
    EzSelect,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    hasInventoryError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formKey: 'schedule-order',
      loadingKey: 'schedule-form-submit',
      canSubmit: true,
      advancedOptions: false,
      slotsData: null,
      firstAvailableData: null,
      deliveryMethod: null,
      transportMode: null,
      defaultMethod: DEFAULT_DELIVERY_METHOD,
      selectedMethod: null,
      selectedTransport: null,
      customFilesChecked: false,
      selectedDateTime: {},
      selectedOption: 'tomorrow',
      defaultData: {},
      imageData: [],
      today: dayjs().format('YYYY-MM-DD'),
      tomorrowDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      yesterday: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      selectedDate: dayjs().format('YYYY-MM-DD'),
      selectedScheduled: null,
      periods: DELIVERY_PERIODS,
      whichBanner: null,
      note: null,
      isBefore: false,
      inventoryItems: [],
      warehouses: [],
      warehouseId: null,
      isProvinoWarehouse: false,
      drivers: [],
      driverId: null,
      scheduleDeliveryDisabled: true,
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    ...mapGetters('entities/users', ['isDistributorProvinoConnected', 'getDistributor']),
    distributor() {
      return this.getDistributor;
    },
    trackingInventory() {
      return this.distributor?.inventorySettings?.tracking;
    },
    restrictDeliveryDates() {
      return this.order.restrictDeliveryDates;
    },
    deliveryDays() {
      return this.restrictDeliveryDates
        ? this.distributor?.deliveryDays
        : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    },
    checkIsSomeLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_WAREHOUSES,
        LOADING_KEY.FETCH_PROVINO_DATA,
        LOADING_KEY.JOKER,
      ]);
    },
    isLoading() {
      return this.getLoading(this.loadingKey);
    },
    action() {
      return `distributor/orders/${this.order.id}/schedule`;
    },
    hasNotes() {
      return (this.order.notes || []).filter(n => !!n.message || (n.images || []).length).length;
    },
    excludedProductsIds() {
      return (this.order.orderedProducts || []).filter(p => p.isDeclined).map(a => a.id);
    },
    isTomorrowSelected() {
      return this.selectedOption === 'tomorrow';
    },
    additionalData() {
      const formData = new FormData();
      this.excludedProductsIds.forEach(p => formData.append('declinedProducts[]', p));

      if (!(this.isDistributorProvinoConnected && this.isProvinoWarehouse)) {
        formData.append('date', this.selectedScheduled);
      }
      if (this.customFilesChecked) {
        this.imageData.forEach(file => formData.append('attachments', file));
      }
      if (this.note) {
        formData.append('message', this.note);
      }
      if (this.isDistributorProvinoConnected && this.isProvinoWarehouse) {
        const date = this.selectedDateTime?.deliveryDate || this.firstAvailableData?.date;
        formData.append('provinoOptions[date]', date);
        formData.append('provinoOptions[slotId]', this.selectedDateTime?.deliveryTimeslotId);
        formData.append('provinoOptions[slotState]', this.selectedDateTime?.slotState);
      } else if (this.shouldShowAdvancedOptions) {
        formData.append('warehouseId', this.warehouseId);
        this.inventoryItems.forEach((ii, idx) => {
          formData.append(`inventoryItems[${idx}][itemId]`, ii.itemId);
          formData.append(`inventoryItems[${idx}][quantity]`, ii.quantity);
        });
      }

      return formData;
    },
    firstAvailable() {
      const { date, time } = this.firstAvailableData || {};
      const formattedDate = formatDate(date, `ddd, ${getDateFormat()}`);
      return `${formattedDate} at ${time}`;
    },
    isNeedToFetchEnums() {
      return this.transportMode === null || this.deliveryMethod === null;
    },
    isDiffNoteToShow() {
      return (
        formatDate(this.selectedDateTime.deliveryDate) !== formatDate(this.order.expectedAt) &&
        !!this.order.requestedDeliveryAt
      );
    },
    scheduleBanner() {
      return {
        banner: 'red',
        icon: 'exclamation-circle',
        message: `Requested delivery date ${formatDate(
          this.order.expectedAt,
        )} is not available. The first available date is selected.`,
      };
    },
    scheduleDisabled() {
      if (!this.trackingInventory) return !this.canSubmit;
      return this.scheduleDeliveryDisabled;
    },
    hasTrackedProducts() {
      return !!this.filterProducts(this.order.orderedProducts || []).length;
    },
    shouldShowWarehouseDropdown() {
      if (!this.trackingInventory || !this.hasTrackedProducts) return false;
      return this.warehouses.length;
    },
    shouldShowAdvancedOptions() {
      if (!this.trackingInventory || !this.hasTrackedProducts) return false;
      return this.order.orderedProducts;
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'distributorFetchProvinoSchedule',
      'distributorFetchProvinoFirstSchedule',
      'distributorFetchProvinoEnums',
      'distributorFetchProvinoSetup',
      'distributorProvinoCustomScheduleForDelivery',
      'distributorFetchWarehouses',
    ]),
    ...mapMutations('loading', ['SET_LOADING', 'CLEAR_LOADING']),
    async open() {
      if (!this.shouldShowAdvancedOptions) {
        this.scheduleDeliveryDisabled = false;
      }

      this.$refs.approveModal.open();
      if (this.isDistributorProvinoConnected) {
        this.SET_LOADING({ [LOADING_KEY.JOKER]: true });
        await this.fetchProvinoSetup();
        await this.fetchFirstAvailable(this.selectedMethod);
        this.initWarehouses();
        this.whichBanner = await this.whichBannerFn();
        this.CLEAR_LOADING({ key: LOADING_KEY.JOKER });
      } else {
        await Promise.all([this.initWarehouses(), this.initDrivers()]);

        this.selectedScheduled = this.order.expectedAt
          ? dayjs(this.order.expectedAt).format('YYYY-MM-DD')
          : this.today;
        this.isBefore = dayjs(this.selectedScheduled).isBefore(this.today);
        this.selectedScheduled = this.isBefore ? this.today : this.selectedScheduled;
      }
    },
    close() {
      this.$refs.approveModal.close();
    },
    updateDate(val) {
      this.selectedScheduled = val.format('YYYY-MM-DD');
    },
    async scheduleForm() {
      if (!this.advancedOptions && this.isDistributorProvinoConnected && this.isProvinoWarehouse) {
        const formData = new FormData();
        this.excludedProductsIds.forEach(p => formData.append('declinedProducts[]', p));
        formData.append('provinoOptions[date]', this.firstAvailableData?.date);
        formData.append('provinoOptions[slotId]', this.firstAvailableData?.slotId);
        formData.append('provinoOptions[slotState]', this.firstAvailableData?.slotState);
        if (this.note) {
          formData.append('message', this.note);
        }
        try {
          const { data } = await this.distributorProvinoCustomScheduleForDelivery({
            orderId: this.order.id,
            data: formData,
            loadingKey: this.loadingKey,
          });
          this.scheduleFormSuccess(data);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        return;
      }
      this.$refs.scheduleForm.onSubmit();
    },
    whichBannerFn() {
      const requested = this.firstAvailableData?.requested;
      const slotState = this.firstAvailableData?.slotState === 2;
      if (requested && slotState) {
        return {
          banner: 'yellow-gray',
          icon: 'info-circle',
          message: `This is a paid date and time slot. You will need to pay the additional fees to ${this.$t(
            'provino.name',
          )} in order to use it.`,
        };
      }
      if (!this.firstAvailableData?.requested) {
        return {
          banner: 'red',
          icon: 'exclamation-circle',
          message: `Requested delivery date ${formatDate(
            this.order.expectedAt,
          )} is not available in ${this.$t('provino.name')}. The first available date is selected.`,
        };
      }
      return null;
    },
    changeNote(val) {
      this.note = val;
    },
    dateValidation(e) {
      this.canSubmit = e.valid;
    },
    showAdvancedOptions() {
      this.advancedOptions = !this.advancedOptions;
      if (!this.isDistributorProvinoConnected) return;

      if (this.isNeedToFetchEnums && this.advancedOptions) {
        this.fetchEnums();
      }
      if (this.advancedOptions) {
        this.fetchSchedule(this.selectedMethod);
      }
      this.customFilesChecked = false;
    },
    onClose() {
      this.selectedDateTime = {};
      this.slotsData = null;
      this.firstAvailableData = null;
      this.advancedOptions = false;
      this.selectedMethod = this.defaultMethod;
      this.note = null;
      this.driverId = null;
      if (this.advancedOptions && this.isDistributorProvinoConnected) {
        this.$refs.deliveryTransport.clearErrors();
      }
    },
    async fetchFirstAvailable(method = 'delivery') {
      const {
        data: { data: first },
      } = await this.distributorFetchProvinoFirstSchedule({
        method,
        declinedProducts: this.excludedProductsIds,
        orderId: this.order.id,
        loadingKey: LOADING_KEY.FETCH_PROVINO_DATA,
      });
      this.firstAvailableData = first;
    },
    async fetchSchedule(method = 'delivery') {
      this.SET_LOADING({ [LOADING_KEY.JOKER]: true });
      const {
        data: { data },
      } = await this.distributorFetchProvinoSchedule({
        method,
        declinedProducts: this.excludedProductsIds,
        orderId: this.order.id,
      });
      this.slotsData = data;
      this.CLEAR_LOADING({ key: LOADING_KEY.JOKER });
    },
    async fetchProvinoSetup() {
      const {
        data: { data: defaultData },
      } = await this.distributorFetchProvinoSetup();
      this.defaultData = defaultData;

      this.selectedMethod = this.defaultData.deliveryMethod || this.defaultMethod;
      this.selectedTransport = this.defaultData.transportMode || null;
    },
    async fetchEnums() {
      const {
        data: { data },
      } = await this.distributorFetchProvinoEnums();
      const { deliveryMethod, transportMode } = data;

      this.deliveryMethod = deliveryMethod.map(mth => ({
        key: mth.key,
        label: mth.label,
        name: capitalizeWord(mth.key),
      }));
      this.transportMode = transportMode.map(trm => ({
        key: trm.key,
        label: trm.label,
        name: capitalizeWord(trm.key),
      }));
    },
    async selectDeliveryMethod(mth) {
      if (mth !== this.defaultMethod) {
        this.selectedTransport = null;
      }
      this.selectedMethod = mth.key;
      this.SET_LOADING({ [LOADING_KEY.JOKER]: true });
      await this.fetchFirstAvailable(mth.key);
      await this.fetchSchedule(mth.key);
      this.CLEAR_LOADING({ key: LOADING_KEY.JOKER });
    },
    selectTransport(trn) {
      this.$refs.deliveryTransport.clearErrors();
      this.selectedTransport = trn.key;
    },
    slotSelected(item) {
      this.selectedDateTime = item;
      this.whichBannerFn();
    },
    imagesUploaded(formData) {
      this.imageData = [...formData.values()];
    },
    scheduleFormSuccess({ data }) {
      this.$emit('success');
      Order.update(data);
      this.close();

      const { href } = this.$router.resolve({
        name: 'distributor-orders-all',
        params: { id: this.order.id },
      });
      flash.success({
        title: 'Order scheduled for delivery!',
        message: 'You can see the order overview in Order Details.',
        ctas: `<a href="${href}">View Order Details</a>`,
      });
    },
    ...mapActions('entities/distributors', [
      'distributorFetchWarehouses',
      'distributorFetchDrivers',
    ]),
    async initWarehouses() {
      if (!this.trackingInventory) return;

      if (!this.warehouses.length) {
        const { data } = await this.distributorFetchWarehouses();
        this.warehouses = [{ id: null, name: 'Select Warehouse' }, ...data.data];
      }

      const orderWarehouseId = this.order.fulfillmentWarehouse?.id || null;
      const primaryWarehouseId = this.warehouses.find(w => w.primary)?.id || null;
      this.warehouseId = orderWarehouseId || primaryWarehouseId;
      this.isProvinoWarehouse =
        this.warehouses.find(w => w.id === this.warehouseId)?.isProvino || false;
      if (
        this.isProvinoWarehouse &&
        this.isDistributorProvinoConnected &&
        !this.hasInventoryError
      ) {
        this.scheduleDeliveryDisabled = false;
      }
    },
    async initDrivers() {
      const { data } = await this.distributorFetchDrivers();
      this.drivers = data.data;
      this.driverId = this.order.driver?.id || this.order.venue?.connection?.defaultDriverId;
    },
    onWarehouseSelectChange(val) {
      if (this.warehouseId === val.id) return;
      this.warehouseId = val.id;
      this.isProvinoWarehouse = val.isProvino;
      this.inventoryItems = [];
      if (this.advancedOptions && this.isDistributorProvinoConnected) {
        if (this.isNeedToFetchEnums && this.advancedOptions) {
          this.fetchEnums();
        }
        if (this.advancedOptions) {
          this.fetchSchedule(this.selectedMethod);
        }
        this.customFilesChecked = false;
      }
      if (val.isProvino && this.isDistributorProvinoConnected && !this.hasInventoryError) {
        this.scheduleDeliveryDisabled = false;
      }
    },
    onDriverSelectChange(selected) {
      this.driverId = selected.reset ? null : selected.id;
    },
    onInventoryManagementChange(items) {
      this.inventoryItems = items.map(p => ({ itemId: p.id, quantity: p.usedInventory }));
    },
    onInventoryManagementInvalid(val) {
      if (this.warehouseId) this.advancedOptions = val;
      this.scheduleDeliveryDisabled = val;
    },
    filterProducts(products) {
      return (
        products?.filter(p => {
          const declined = p.isDeclined ?? false;
          const included = p.isIncluded ?? true;
          return p.inventoryTracked && included && !declined;
        }) || []
      );
    },
  },
  mounted() {
    if (!this.trackingInventory || !this.hasTrackedProducts || this.isDistributorProvinoConnected) {
      this.scheduleDeliveryDisabled = false;
    }
  },
  watch: {
    inventoryItems(val) {
      if (val.length) return;
      if (this.isProvinoWarehouse && !this.hasInventoryError) return;

      const products = this.filterProducts(this.order.orderedProducts);
      if (!products.length) return;

      this.scheduleDeliveryDisabled = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.pick-a-date {
  @extend %flex-center;
  padding: px-to-rem(16px) 0;

  :deep() .input-group__input {
    margin-left: px-to-rem(8px);
  }
}
.documents-checkboxes {
  display: flex;
}
.footer-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .advanced-options-btn {
    padding-left: 0;
  }
}
.info-message-wrapper {
  .banner {
    margin: 0;
    width: 100%;
  }
}
:deep() .loader {
  @extend %whole-screen;
}
.upload-wrapper {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  &--narrow {
    padding-right: 10px;
  }

  :deep() .preview__images {
    flex-direction: column;
    .preview__image + .preview__image {
      margin-left: 0;
    }
  }
}
:deep() .date-picker.ez-simple-dropdown {
  width: 100%;
}

.date-picker {
  :deep() .ez-simple-dropdown__display-container {
    width: 100%;
    .placeholder {
      width: 100%;
    }
  }
}

.driver-dropdown {
  :deep() .select-search {
    &__search-wrapper {
      display: none;
    }

    &__item {
      @include font-size(14px, 20px);
      padding: 10px 16px;
      color: $color-gray-25;
    }
  }
}
</style>
