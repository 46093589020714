<script>
import uuid from 'uuid/v4';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { clone } from '@/util/utils';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzButton from '@/components/ui/Button';
import EzOnOff from '@/components/ui/OnOff';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

/**
 * MapProductsModal
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'MapProductsModal',
  props: {
    items: {
      type: Array,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
    productsRemaining: {
      type: Array,
      required: true,
    },
  },
  components: {
    EzEntityInfo,
    EzFormModal,
    EzButton,
    EzOnOff,
    VSelectSearch,
  },
  data() {
    return {
      supplierCy,
      location: {},
      placeAllRemaining: false,
    };
  },
  computed: {
    products() {
      return this.items.map(({ product }) => product);
    },
    hasMadeChanges() {
      const curr = this.locations.find(l => l.id === this.location.id)?.addedItems || [];
      const edit = this.filterValidProducts(this.location?.addedItems || []);

      return JSON.stringify(edit) !== JSON.stringify(curr);
    },
    firstIsUnset() {
      return this.location.addedItems?.[0].product.id.toString().includes('null');
    },
    hasInvalidProducts() {
      return this.location?.addedItems?.some(p => p.product.id.toString().includes('null'));
    },
    allProductsMapped() {
      const totalProducts = this.items.length;
      const mappedProducts = this.locations
        .filter(l => l.id !== this.location.id)
        .reduce((a, c) => a + c?.addedItems?.length, 0);
      const currentMapped = this.location?.addedItems?.length;

      return totalProducts === mappedProducts + currentMapped;
    },
    addAdditionalDisabled() {
      return this.hasInvalidProducts || this.allProductsMapped || this.placeAllRemaining;
    },
    disabled() {
      if ((this.hasMadeChanges && this.firstIsUnset) || this.placeAllRemaining) return false;
      if (this.hasInvalidProducts || !this.hasMadeChanges) return true;
      return false;
    },
  },
  methods: {
    open(data) {
      this.location = clone(data);
      this.placeAllRemaining = this.locations.length === 1;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.$emit('close');
      this.location = {};
      this.placeAllRemaining = false;
    },
    filterValidProducts(arr) {
      return clone(arr.filter(p => !p.product.id?.toString().includes('null')));
    },
    transformer(data) {
      return data.filter((p) => {
        const assigned = this.locations.some(l => l.addedItems.find(pr => pr.product.id === p.id));
        const isAdded = !!this.location.addedItems.find(pr => pr.product?.id === p.id);
        const wasAdded = !!this.locations
          .find(l => l.id === this.location.id)
          .addedItems.find(pr => pr.product.id === p.id);

        if (wasAdded && !isAdded) return true;
        if (assigned || isAdded) return false;
        return true;
      });
    },
    addProductToLocation() {
      this.location.addedItems = [
        ...this.location.addedItems,
        { product: { id: `null-${uuid()}` } },
      ];
    },
    updateProductInLocation(idx, product) {
      let insert = { product };
      if (product.reset) insert = { product: { id: `null-${uuid()}` } };

      this.location.addedItems.splice(idx, 1, insert);
    },
    removeProduct(idx) {
      this.location.addedItems.splice(idx, 1);
    },
    findSelected(id) {
      if (id.toString().includes('null')) return undefined;
      return this.location.addedItems.find(p => p.product.id === id)?.product;
    },
    saveAddedProducts() {
      this.$emit('finished', {
        ...this.location,
        addedItems: [
          ...this.location.addedItems,
          ...(this.placeAllRemaining ? [...clone(this.productsRemaining)] : []),
        ],
      });

      this.close();
    },
  },
};
</script>

<template>
  <ez-form-modal ref="modal" @close="onClose">
    <template #title>Add Products to {{ location.code }}</template>
    <template #content>
      <p class="info-text">Select the products you would like to add to this location.</p>

      <hr />

      <div class="place-all-remaining">
        <div class="help-block mb-0">
          <h4 class="help-block__title custom-title">Place All</h4>
          <p class="help-block__text mb-0">
            Add all remaining products to the selected location.
          </p>
        </div>
        <ez-on-off
          formKey=""
          name="placeAllRemaining"
          :value="placeAllRemaining.toString()"
          :checked="placeAllRemaining"
          :disabled="!productsRemaining.length"
          @change="$event = placeAllRemaining = $event"
        />
      </div>

      <hr />

      <div
        v-for="({ product }, idx) in location.addedItems"
        :key="idx"
        class="mapped-product mb-16"
      >
        <ez-button
          v-if="idx"
          class="remove-product-btn"
          type="link"
          @click="removeProduct(idx)"
          :data-cy="
            `${supplierCy.INVENTORY_FLOWS.ADDITION.LOCATIONS.MAP_PRODUCTS.BUTTON__REMOVE}-${idx}`
          "
        >
          <font-awesome-icon icon="times" /> Remove
        </ez-button>
        <v-select-search
          ref="selectSearch"
          class="products-dropdown mb-12"
          placeholder="Select Product"
          searchPlaceholder="Search for products"
          :label="`Product ${idx + 1}`"
          :data="products"
          :transformer="transformer"
          :selected="findSelected(product.id)"
          :disable="placeAllRemaining"
          @selected="updateProductInLocation(idx, $event)"
          isFullWidth
          :data-cy="
            `${supplierCy.INVENTORY_FLOWS.ADDITION.LOCATIONS.MAP_PRODUCTS.SELECT__PRODUCT}-${idx}`
          "
        >
          <template #result="{ result }">
            <ez-entity-info
              :imgUrl="result.image"
              imgWidth="2rem"
              imgHeight="2rem"
              imgBorderRadius="3px"
            >
              <span class="entity-info__name">{{ result.name }}</span>
              <span class="entity-info__sku">{{ result.sku }}</span>
            </ez-entity-info>
          </template>
        </v-select-search>
      </div>

      <ez-button
        type="secondary"
        class="add-additional-product-button"
        :disabled="addAdditionalDisabled"
        @click="addProductToLocation"
        isFullWidth
        :data-cy="supplierCy.INVENTORY_FLOWS.ADDITION.LOCATIONS.MAP_PRODUCTS.BUTTON__ADD"
      >
        <font-awesome-icon icon="plus" /> Add Additional Product
      </ez-button>
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="close"
        :data-cy="supplierCy.INVENTORY_FLOWS.ADDITION.LOCATIONS.MAP_PRODUCTS.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        type="primary"
        :disabled="disabled"
        @click="saveAddedProducts"
        :data-cy="supplierCy.INVENTORY_FLOWS.ADDITION.LOCATIONS.MAP_PRODUCTS.BUTTON__SAVE"
      >
        Add Products
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.info-text {
  @include font-size(14px, 20px);
  margin: 0;
}

.place-all-remaining {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .help-block {
    &__title.custom-title {
      font-weight: 600;
    }

    &__text {
      @include font-size(14px, 20px);
    }
  }
}

.mapped-product {
  position: relative;

  .remove-product-btn {
    @include font-size(12px, 18px);
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
  }
}

.products-dropdown {
  :deep() .select-search {
    &__trigger span {
      overflow: hidden;
      width: calc(80% - (10px + 2 * 10px));
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;

      &:not(.select-search__value) {
        color: $color-gray-6C;
      }
    }

    &__trigger span,
    &__search,
    &__item {
      @include font-size(14px, 20px);
    }

    &__list {
      max-height: 274px;

      .entity-info {
        align-items: center;

        &__text {
          flex-direction: column;
          align-items: start;
        }

        &__name {
          @include font-size(14px, 20px);
          font-weight: 600;
          color: $color-gray-25;
        }

        &__sku {
          @include font-size(12px, 14px);
          font-weight: 500;
          color: $color-gray-6C;
        }
      }
    }
  }
}

.add-additional-product-button:disabled {
  background-color: #f5f6fa;
  color: rgba($color-gray-6C, 0.48);
}
</style>
