<script>
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import { EzButtonGroup } from '@/components/ui/Button';
import ButtonAction from '@/views/platform/distributor/crm/ButtonAction';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'ButtonActions',
  components: {
    EzSimpleDropdown,
    EzButtonGroup,
  },
  props: {
    hasGreaterThenTwoActions: {
      required: true,
      type: Boolean,
    },
    actions: {
      type: Array,
      required: true,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      lastAction: [],
      restActions: [],
    };
  },
  computed: {
    actionsComponent() {
      return ButtonAction;
    },
    hasLessOrEquallyThenTwo() {
      return this.actions.length <= 2;
    },
    hasExactlyThree() {
      return this.actions.length === 3;
    },
  },
  methods: {
    initActions() {
      if (this.hasGreaterThenTwoActions) {
        if (this.hasExactlyThree) {
          this.restActions = this.actions;
          return;
        }
        const act = [...this.actions];
        const [last] = act.slice(-1);
        act.splice(-1, 1);

        this.lastAction = last;
        this.restActions = act;
        return;
      }
      this.restActions = this.actions;
    },
    onActionClicked(action) {
      this.$emit('clicked', action);
    },
  },
  created() {
    this.initActions();
  },
};
</script>

<template>
  <ez-button-group>
    <template v-if="hasLessOrEquallyThenTwo">
      <component
        @actionClicked="onActionClicked(action.event)"
        v-for="(action, ind) in restActions"
        :type="ind > 0 ? 'secondary': 'primary'"
        :key="action.text"
        :disabled="action.disabled"
        :is="actionsComponent"
        :data-cy="dataCy ? `${dataCy}-${action.event}` : ''"
      >
        {{ action.text }}
      </component>
    </template>
    <template v-else>
      <ez-simple-dropdown
        placeholder="Manage To-Do"
        direction="up"
        class="manage-todos"
        :data-cy="dataCy ? `${dataCy}-manageToDo` : ''"
      >
        <template #dropdown>
          <div class="dropdown-actions">
            <component
              @actionClicked="onActionClicked(action.event)"
              v-for="action in restActions"
              :is="actionsComponent"
              :disabled="action.disabled"
              type="link"
              :key="action.text"
              :data-cy="dataCy ? `${dataCy}-${action.event}` : ''"
            >
              {{ action.text }}
            </component>
          </div>
        </template>
      </ez-simple-dropdown>
      <component
        v-if="hasGreaterThenTwoActions && !hasExactlyThree"
        @actionClicked="onActionClicked(lastAction.event)"
        type="secondary"
        :disabled="lastAction.disabled"
        :is="actionsComponent"
        :data-cy="dataCy ? `${dataCy}-${lastAction.event}` : ''"
      >
        {{ lastAction.text }}
      </component>
    </template>
  </ez-button-group>
</template>
<style lang="scss" scoped>
.manage-todos {
  display: inline-block;
  width: 145px;
  min-width: 140px;

  .dropdown-actions {
    &__btn {
      text-align: left;
      padding-left: 8px;
      opacity: .8;
      width: 100%;
      font-weight: 400;

      &--complete {
        color: $primary-color;
      }

      &--decline, &--dispute {
        color: $color-primary-red;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  :deep() .ez-simple-dropdown {
    &__label {
      color: $color-white;
    }
    &__display-container {
      background-color: $color-primary-blue;
      svg {
        color: $color-white;
      }
      &:hover {
        background-color: #264CB8;
      }
    }
  }
}
</style>
