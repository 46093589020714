<script>
/**
 * SingleRequest
 * @version 1.0.0
 * @since 2.3.0
 */

import VenueOverview from '@/views/platform/distributor/outlets/VenueOverview';
import SinglePage from '@/views/admin/SinglePage.vue';
import StatusBadge from '@/views/common/status-badge';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import EzButton from '@/components/ui/Button';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import Venue from '@/models/Venue';
import { mapActions, mapMutations } from 'vuex';
import confirmation from '@/components/v3/patterns/VConfirmation/control';

export default {
  components: {
    EzButton,
    EzButtonDropdown,
    EzPremiumBadge,
    EzEntityInfo,
    VenueOverview,
    SinglePage,
    StatusBadge,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    venue() {
      return Venue.query()
        .find(this.venueId) || { group: {} };
    },
    isVenueEditable() { return this.venue.editable; },
  },
  methods: {
    ...mapActions('entities/venues', [
      'distributorApproveConnectionRequest',
      'distributorDeclineConnectionRequest',
      'fetchVenue',
    ]),
    ...mapMutations('entities/venues', ['SET_ACCEPT_AND_SEND_ONBOARDING_LINK_DATA']),
    async acceptConnectionRequest() {
      const { close, canceled } = await confirmation.confirm({
        title: this.$t('venues.acceptConnectionReqestConfirmTitle'),
        message: this.$t('venues.acceptConnectionReqestConfirmMessage', {
          venueName: this.venue.name,
        }),
        buttons: {
          confirm: this.$t('venues.acceptRequest'),
        },
      });
      if (canceled) return;
      await this.distributorApproveConnectionRequest(this.venueId);
      close();
      this.$router.push({
        name: 'distributor-outlets-requests',
        params: {
          flash: {
            type: 'success',
            title: this.$t('venues.successFlashTitleConnectionRequestAccept'),
            message: this.$t('venues.successFlashMessageConnectionRequestAccept', {
              venueName: this.venue.name,
            }),
          },
        },
      });
    },
    async declineConnectionRequest() {
      const { close, canceled } = await confirmation.alert({
        title: this.$t('venues.declineConnectionReqestConfirmTitle'),
        message: this.$t('venues.declineConnectionReqestConfirmMessage', {
          venueName: this.venue.name,
        }),
        buttons: {
          confirm: this.$t('venues.declineRequest'),
        },
      });
      if (canceled) return;
      await this.distributorDeclineConnectionRequest(this.venueId);
      close();
      this.$router.push({
        name: 'distributor-outlets-requests',
        params: {
          flash: {
            type: 'success',
            title: this.$t('venues.deniedFlashTitleConnectionRequest'),
          },
        },
      });
    },
    acceptAndSendOnboardingLink() {
      this.SET_ACCEPT_AND_SEND_ONBOARDING_LINK_DATA({
        venue: this.venue,
      });
      this.$router.push({ name: 'distributor-outlets-accept-send-onboarding-link' });
    },
  },
  mounted() {
    this.fetchVenue(this.venueId);
  },
};
</script>
<template>
  <single-page>
    <template #breadcrumbs>
      <router-link v-if="venue" :to="{ name: 'distributor-outlets-requests' }">
        <font-awesome-icon icon="arrow-left" />
        {{ $t('venues.backToConnectionRequestsTab') }}
      </router-link>
    </template>

    <template #title>
      <ez-entity-info
        imgBorderRadius="50%"
        imgWidth="2rem"
        imgHeight="2rem"
        :imgUrl="venue.logo">
        <h1>{{ venue.name }} <ez-premium-badge v-if="$helpers.isPremium(venue.accountType)"/></h1>
        <status-badge :status="venue.status || 'draft'"></status-badge>
      </ez-entity-info>
    </template>

    <template #actions>
      <ez-button-dropdown
        class="btn-dropdown"
        expand-on-click
      >
        <template #iconText>
          Accept
        </template>
        <template #dropdown>
          <ez-button
            @click="acceptConnectionRequest"
            type="link"
          >
            Accept
          </ez-button>
          <ez-button
            type="link"
            @click="acceptAndSendOnboardingLink"
          >
            Accept and Send Onboarding Link
          </ez-button>
        </template>
      </ez-button-dropdown>
      <ez-button-dropdown buttonType="secondary">
        <template #icon>
          <font-awesome-icon icon="ellipsis-h"/>
        </template>
        <template #dropdown>
          <ez-button
            @click="declineConnectionRequest"
            type="red-link">{{ $t('venues.declineRequest') }}
          </ez-button>
        </template>
      </ez-button-dropdown>
    </template>

    <VenueOverview :venue="venue"/>
  </single-page>
</template>
<style lang="scss" scoped></style>
