<script>
import { mapActions } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';

import { clone } from '@/util/utils';
import EzOnOff from '@/components/ui/OnOff/EzOnOff.vue';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
/**
 * ReturnInventoryToWarehouse
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'ReturnInventoryToWarehouse',
  props: {
    selected: {
      type: [Number, String, Object],
      required: false,
    },
    toggleDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  components: {
    EzOnOff,
    VSelectSearch,
  },
  data() {
    return {
      returnToWarehouse: false,
      warehouses: [],
      warehouse: null,
      supplierCy,
    };
  },
  computed: {
    toggleTooltip() {
      if (!this.toggleDisabled) return undefined;
      return {
        content: 'No products are tracked in inventory',
        classes: ['tooltip--reset-margin', 'my-8'],
      };
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    onSelectWarehouse(warehouse) {
      this.warehouse = warehouse;
    },
  },
  async created() {
    const { data } = await this.distributorFetchWarehouses();
    this.warehouses = clone(data.data);
  },
  watch: {
    selected(val) {
      this.returnToWarehouse = !!val;
      if (typeof val === 'number') this.warehouse = this.warehouses.find(w => w.id === val);
    },
    returnToWarehouse(val) {
      if (!val) this.warehouse = null;
    },
    warehouse(val) {
      this.$emit('change', val);
    },
  },
};
</script>

<template>
  <div class="return-to-warehouse">
    <div class="return-to-warehouse__toggle">
      <div class="help-block">
        <h4 class="help-block__title">Return inventory to the warehouse</h4>
      </div>
      <ez-on-off
        formKey=""
        name="returnToWarehouse"
        :value="returnToWarehouse.toString()"
        :checked="returnToWarehouse"
        :disabled="toggleDisabled"
        @change="returnToWarehouse = $event"
        v-tooltip="toggleTooltip"
        :data-cy="dataCy.LABEL__RETURN_TO_INVENTORY"
      />
    </div>
    <v-select-search
      v-if="returnToWarehouse"
      name="warehouse"
      label="Warehouse"
      placeholder="Choose Warehouse"
      :hasClear="false"
      :data="warehouses"
      :selected="warehouse"
      @selected="onSelectWarehouse"
      isFullWidth
      class="return-to-warehouse__dropdown mt-12"
      :data-cy="dataCy.BUTTON__WAREHOUSE"
    />
  </div>
</template>

<style lang="scss" scoped>
.return-to-warehouse {
  &__toggle {
    display: flex;
    align-items: flex-start;

    .help-block {
      margin-right: 16px;
      margin-bottom: 0;
      width: 100%;

      &__text {
        @include font-size(14px);
      }
    }
  }

  &__dropdown {
    :deep() .select-search {
      &__trigger span {
        @include font-size(14px, 20px);

        &:not(.select-search__value) {
          color: $color-gray-6C;
        }
      }

      &__search-wrapper {
        display: none;
      }

      &__item {
        @include font-size(14px, 20px);
      }
    }
  }
}
</style>
