<template>
  <div class="ez-special-tiers">
    <div class="ez-special-tiers__tier-label">
      <b>Special Tier Prices</b> Optional
    </div>
    <ez-button
      type="secondary"
      formType="button"
      customClass="product-form__special-tiers--add"
      :disabled="!availableVenues.length"
      @click="onAddSpecialTier">
      <font-awesome-icon icon="plus"/>Add New Special Tier Price
    </ez-button>

    <ez-special-tier-item
      v-for="(specialTier, i) in specialTiers"
      :key="specialTier.id"
      :formKey="formKey"
      :tier="specialTier"
      :defaultPrice="draft.price"
      :index="i"
      @tierClick="editSpecialTier"
      @removeTier="onRemoveSpecialTierClick"></ez-special-tier-item>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import EzButton from '@/components/ui/Button';
import EzSpecialTierItem from './EzSpecialTierItem.vue';

export default {
  components: {
    EzButton,
    EzSpecialTierItem,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    specialTiers() {
      return this.draft.specialTierPricings;
    },
    availableVenues() {
      return this.getAvailableVenuesForProduct(true);
    },
    ...mapGetters('entities/products', [
      'getAllSpecialTierForProduct',
      'getAvailableVenuesForProduct',
    ]),
    ...mapState('entities/products', [
      'draft',
    ]),
  },
  methods: {
    onAddSpecialTier() {
      this.$emit('addTier');
    },
    onRemoveSpecialTierClick(tier, index) {
      this.$emit('removeTier', tier, index);
    },
    editSpecialTier(tier, index) {
      this.$emit('tierClick', tier, index);
    },
  },
};
</script>


<style scoped lang="scss">
  $title-separator: #EAEFF4;
  $icon-color: #8790A3;
  $error-color: #EA4B5D;

  .ez-special-tiers {
    @include font-size(14px);

    &__tier-label {
      @include font-size(18px);
      font-weight: 600;
      margin-bottom: 1.3em;
      color: $label-color;

      b {
        font-weight: 600;
        color: $primary-color;
      }
    }
  }
</style>
