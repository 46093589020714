<script>
import ButtonsActions from '@/views/platform/distributor/orders/credit-notes/single-credit-note/actions-footer/ButtonActions.vue';
/**
 * ButtonSwitch
 *
 * @version 1.1.0
 * @since 3.28.0
 */

export default {
  components: {
    ButtonsActions,
  },
  props: {
    creditNote: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: false,
    },
    forceDisable: {
      type: Array, // [{ btnType: 'schedule_delivery', value: true }]
      required: false,
      default: () => [],
    },
    disableSaveChanges: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    actions() {
      return this.creditNote?.actions;
    },
    hasMoreThanTwoActions() {
      return this.actions?.length > 2;
    },
  },
};
</script>

<template>
  <buttons-actions
    :creditNote="creditNote"
    :edit-mode="editMode"
    :has-more-than-two-actions="hasMoreThanTwoActions"
    :to-disable-save-changes="disableSaveChanges"
    :force-disable="forceDisable"
    v-on="$listeners"
  />
</template>
