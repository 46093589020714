import { render, staticRenderFns } from "./EzSpecialTierList.vue?vue&type=template&id=45b8f996&scoped=true&"
import script from "./EzSpecialTierList.vue?vue&type=script&lang=js&"
export * from "./EzSpecialTierList.vue?vue&type=script&lang=js&"
import style0 from "./EzSpecialTierList.vue?vue&type=style&index=0&id=45b8f996&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b8f996",
  null
  
)

export default component.exports