<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardEmitterMixin } from '@/mixins/wizard';
import { LOADING_KEY } from '@/util/constants';

import EzButton from '@/components/ui/Button';
import EzWizard from '@/components/layout/Wizard.vue';
import EzLoader from '@/components/ui/Loader/EzLoader';
import steps, { TRANSFER_STEP_1, TRANSFER_STEP_2, TRANSFER_STEP_3, TRANSFER_STEP_4 } from './steps';

/**
 * Inventory Transfer Flow
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardEmitterMixin],
  props: {
    distributorId: {
      type: Number,
      default: null,
    },
    fromWarehouseId: {
      type: Number,
      default: null,
    },
    toWarehouseId: {
      type: Number,
      default: null,
    },
    returnPage: {
      type: String,
      default: null,
    },
  },
  components: { EzButton, EzWizard, EzLoader },
  data() {
    return {
      TRANSFER_STEP_1,
      TRANSFER_STEP_2,
      TRANSFER_STEP_3,
      TRANSFER_STEP_4,
      supplierCy,
      currentStep: 0,
      loadingMsg: 'Loading...',
      nextDisabled: true,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    ...mapState('entities/products', ['inventoryManagement']),
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.DISTRIBUTOR_FETCH_WAREHOUSES]);
    },
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      const warehouse = this.inventoryManagement?.warehouse?.to?.name;
      return steps[this.currentStep].pageTitle.replace('%warehouse_name%', warehouse || '');
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === TRANSFER_STEP_1;
    },
    isLastStep() {
      return this.currentStep === TRANSFER_STEP_4;
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributor']),
    ...mapMutations('entities/products', ['CLEAR_DRAFT', 'UPDATE_INVENTORY_MANAGEMENT_DRAFT']),
    onWizardExit() {
      this.CLEAR_DRAFT();
      return this.$router.push({ name: 'distributor-products' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= TRANSFER_STEP_1 && step <= TRANSFER_STEP_4) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= TRANSFER_STEP_1) return;

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= TRANSFER_STEP_4) {
        return;
      }

      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
  created() {
    if (this.inventoryManagement) this.CLEAR_DRAFT();
    this.currentStep = TRANSFER_STEP_1;

    if (this.fromWarehouseId || this.toWarehouseId || this.returnPage) {
      this.UPDATE_INVENTORY_MANAGEMENT_DRAFT({
        params: {
          ...(this.fromWarehouseId ? { fromWarehouseId: this.fromWarehouseId } : {}),
          ...(this.toWarehouseId ? { toWarehouseId: this.toWarehouseId } : {}),
          ...(this.returnPage ? { returnPage: this.returnPage } : {}),
        },
      });
    }
  },
  async mounted() {
    await this.initDistributor();
  },
  beforeDestroy() {
    this.CLEAR_DRAFT();
  },
};
</script>

<template>
  <ez-wizard
    :extraWide="[TRANSFER_STEP_2, TRANSFER_STEP_3].includes(currentStep)"
    @exit="onWizardExit"
    @back="goToPreviousStep"
  >
    <template #title>Transfer Products</template>
    <template #nav>
      <ul>
        <li
          :class="{
            'ez-step-active': currentStep === TRANSFER_STEP_1,
            'ez-step-completed': currentStep > TRANSFER_STEP_1,
          }"
        >
          1. Warehouses
        </li>
        <li
          :class="{
            'ez-step-active': currentStep === TRANSFER_STEP_2,
            'ez-step-completed': currentStep > TRANSFER_STEP_2,
          }"
        >
          2. Products
        </li>
        <li
          :class="{
            'ez-step-active': currentStep === TRANSFER_STEP_3,
            'ez-step-completed': currentStep > TRANSFER_STEP_3,
          }"
        >
          3. Locations
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button
        :disabled="nextDisabled"
        @click="goToNextStep"
        :data-cy="supplierCy.INVENTORY_FLOWS.TRANSFER.GENERAL.BUTTON__NEXT_STEP"
      >
        {{ nextCta }}
      </ez-button>
    </template>
    <template #prevStep>
      <ez-button
        v-if="[TRANSFER_STEP_2, TRANSFER_STEP_3, TRANSFER_STEP_4].includes(currentStep)"
        type="link"
        formType="button"
        @click="goToPreviousStep"
        :data-cy="supplierCy.INVENTORY_FLOWS.TRANSFER.GENERAL.BUTTON__PREV_STEP"
      >
        <font-awesome-icon icon="arrow-left" />
        <span>Back</span>
      </ez-button>
    </template>
    <template #pageTitle>{{ currentStepPageTitle }}</template>
    <template #pageInfo>
      <div v-html="currentStepPageInfo"></div>
    </template>
    <component
      :is="currentStepComponent"
      :distributorId="distributorId"
      @stepCompleted="onStepCompleted"
      @stepBack="onStepBack"
    />
    <ez-loader :show="isLoading">
      {{ loadingMsg }}
    </ez-loader>
  </ez-wizard>
</template>

<style lang="scss" scoped></style>
