import i18n from '@/i18n';

export const DISTRIBUTOR_STEP_1 = 0;
export const DISTRIBUTOR_STEP_2 = 1;
export const DISTRIBUTOR_STEP_3 = 2;
export const DISTRIBUTOR_STEP_4 = 3;

const steps = {
  [DISTRIBUTOR_STEP_1]: {
    component: () => import(/* webpackChunkName: "xero" */ '@/views/platform/distributor/xero/setup/AccountSetup'),
    title: i18n.t('xero.wizzard.step1.title'),
    pageTitle: i18n.t('xero.wizzard.step1.pageTitle'),
    pageInfo: i18n.t('xero.wizzard.step1.pageInfo', { platform: process.env.VUE_APP_PLATFORM_TITLE }),
    nextCta: i18n.t('global.wizzard.nextStep'),
  },
  [DISTRIBUTOR_STEP_2]: {
    component: () => import(/* webpackChunkName: "xero" */ '@/views/platform/distributor/xero/setup/ProductSync'),
    title: i18n.t('xero.wizzard.step2.title'),
    pageTitle: i18n.t('xero.wizzard.step2.pageTitle'),
    pageInfo: i18n.t('xero.wizzard.step2.pageInfo', { platform: process.env.VUE_APP_PLATFORM_TITLE }),
    nextCta: i18n.t('global.wizzard.nextStep'),
  },
  [DISTRIBUTOR_STEP_3]: {
    component: () => import(/* webpackChunkName: "xero" */ '@/views/platform/distributor/xero/setup/VenueSync'),
    title: `3. ${i18n.t('xero.wizzard.step3.title')}`,
    pageTitle: i18n.t('xero.wizzard.step3.pageTitle'),
    pageInfo: i18n.t('xero.wizzard.step3.pageInfo', { platform: process.env.VUE_APP_PLATFORM_TITLE }),
    nextCta: i18n.t('global.wizzard.goToOverview'),
  },
  [DISTRIBUTOR_STEP_4]: {
    component: () => import(/* webpackChunkName: "xero" */ '@/views/platform/distributor/xero/setup/Overview'),
    title: i18n.t('xero.wizzard.overview.title'),
    pageTitle: i18n.t('xero.wizzard.overview.pageTitle'),
    pageInfo: i18n.t('xero.wizzard.overview.pageInfo'),
    nextCta: i18n.t('global.wizzard.finish'),
  },
};

export default steps;
