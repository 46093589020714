<script>
/**
   * Total amount
   * @version 1.0.0
   * @since
   */
import TotalIcon from '@/views/common/orders/total-icon';

export default {
  name: 'VTotalAmount',
  components: {
    TotalIcon,
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: 'money-bill-wave',
    },
  },
};
</script>
<template>
  <div class="vtotal">
    <div class="vtotal__info">
      <div class="vtotal__label"><slot name="label"/></div>
      <div class="vtotal__amount"><slot name="amount"/></div>
    </div>
    <div class="vtotal__icon">
      <total-icon
        :icon="icon"
        v-tooltip="{
          classes: ['tooltip--reset-margin'],
          content: 'Tax excluded',
        }"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.vtotal {
  @extend %flex-center;

  &__info {
    margin-right: .75em;
    text-align: right;
  }
  &__label {
    @include font-size(12px);
    font-weight: 500;
    letter-spacing: 0.25px;
    color: $color-gray-6C;
    text-transform: uppercase;
  }
  &__amount {
    @include font-size(20px);
    color: $color-gray-25;
    font-weight: 500;
  }
}
</style>
