<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { clone, getDateFormat } from '@/util/utils';

import EzDateInput from '@/components/ui/DateInput';
import EzInput from '@/components/ui/Input';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

/**
 * Warehouse
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Warehouse',
  components: {
    EzDateInput,
    EzInput,
    VSelectSearch,
  },
  data() {
    return {
      warehouses: [],
      warehouse: null,
      batchCode: '',
      expiryDate: null,
      expiryDateInvalid: false,
      supplierCy,
      getDateFormat,
    };
  },
  computed: {
    ...mapState('entities/products', ['inventoryManagement']),
    ...mapState('entities/users', ['loggedUser']),
    me() {
      return this.loggedUser.distributor;
    },
    warehouseValid() {
      return !!Object.keys(this.warehouse || {}).length;
    },
    batchCodeValid() {
      return this.me.inventorySettings?.batchTracking ? !!this.batchCode.trim() : true;
    },
    today() {
      return dayjs().startOf('day');
    },
  },
  methods: {
    ...mapMutations('entities/products', ['UPDATE_INVENTORY_MANAGEMENT_DRAFT']),
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    onNextStep() {
      this.UPDATE_INVENTORY_MANAGEMENT_DRAFT({
        warehouse: this.warehouse,
        batchCode: this.batchCode.trim() || null,
        expiryDate: this.expiryDate ? dayjs(this.expiryDate) : null,
        items: [],
      });

      this.$emit('stepCompleted');
    },
    validate() {
      if (!this.warehouseValid || !this.batchCodeValid || this.expiryDateInvalid) {
        this.disableNextStep();
      } else {
        this.enableNextStep();
      }
    },
  },
  watch: {
    warehouse: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        this.validate();
      },
    },
    batchCode: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        this.validate();
      },
    },
    expiryDateInvalid: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        this.validate();
      },
    },
  },
  async created() {
    if (this.inventoryManagement.warehouse) {
      this.warehouse = clone(this.inventoryManagement.warehouse);
    }
    this.batchCode = this.inventoryManagement.batchCode || '';
    this.expiryDate = this.inventoryManagement.expiryDate || null;

    const { data } = await this.distributorFetchWarehouses({ withLocations: true });
    this.warehouses = clone(data.data);
  },
};
</script>

<template>
  <div>
    <v-select-search
      name="warehouse"
      label="Warehouse Name"
      placeholder="Choose Warehouse"
      :hasClear="false"
      :data="warehouses"
      :selected="warehouse"
      @selected="warehouse = $event"
      isFullWidth
      :data-cy="supplierCy.INVENTORY_FLOWS.ADDITION.WAREHOUSE.BUTTON__SELECT"
    />
    <ez-input
      form-key=""
      name="batchCode"
      label="Batch ID"
      class="mask-input mt-16"
      placeholder="Insert Batch ID"
      :value="batchCode"
      @onChange="batchCode = $event"
      :data-cy="supplierCy.INVENTORY_FLOWS.ADDITION.WAREHOUSE.INPUT__BATCH_CODE"
    />
    <ez-date-input
      form-key=""
      name="expiryDate"
      label="Expiry Date"
      :placeholder="getDateFormat()"
      :date-format="getDateFormat()"
      :class="['mask-input', 'mt-16', { 'empty-date': !expiryDate }]"
      :min="today"
      v-model="expiryDate"
      @onChange="expiryDate = $event"
      @invalid="expiryDateInvalid = $event"
      :data-cy="supplierCy.INVENTORY_FLOWS.ADDITION.WAREHOUSE.INPUT__DATE_PICKER"
    />
  </div>
</template>

<style lang="scss" scoped>
:deep() .select-search {
  &__trigger span {
    @include font-size(14px, 20px);
    &:not(.select-search__value) {
      color: $color-gray-6C;
    }
  }

  &__search-wrapper {
    display: none;
  }

  &__item {
    @include font-size(14px, 20px);
  }
}

.mask-input {
  :deep() .mask-input__input {
    font-weight: normal;
  }

  &.empty-date :deep() .placeholder {
    color: $color-gray-6C;
  }
}
</style>
