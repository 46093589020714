<script>
import { EzCsvUploadModal } from '@/components/ui/Modal';
import { EzButtonGroup } from '@/components/ui/Button';
import EzButton from '@/components/ui/Button/EzButton';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import EzTable from '@/components/ui/Table/EzTable';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EmptyState from '@/views/common/empty-state/EmptyState';
import VStatusDropdown from '@/components/v3/patterns/VStatusDropdown';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
  LOADING_KEY,
  DEFAULT_FRACTION_DIGITS,
  PRODUCT_VISIBILITY_HIDDEN,
  PRODUCT_VISIBILITY_VISIBLE,
} from '@/util/constants';
import flash from '@/components/ui/FlashMessage';
import { clone, falsy } from '@/util/utils';
import confirmation from '@/components/v3/patterns/VConfirmation/control';
import ProductModal from '@/views/platform/distributor/outlets/ProductModal';
import { wizardListenerMixin } from '@/mixins/wizard';
import EzInput from '@/components/ui/Input/EzInput';
import EzFilterList from '@/components/ui/FilterList/EzFilterList';
import VMarketPriceInput from '@/components/v3/patterns/VMarketPriceInput';
import StatusBadge from '@/views/common/status-badge/StatusBadge';

/**
 *
 * @version 1.0.0
 * @since 3.8.1
 */
export default {
  name: 'Products',
  mixins: [wizardListenerMixin],
  components: {
    VMarketPriceInput,
    ProductModal,
    EzCsvUploadModal,
    EzButtonGroup,
    EzButton,
    EzSimpleDropdown,
    EzTable,
    EzLoadMore,
    EmptyState,
    VStatusDropdown,
    EzEntityInfo,
    EzInput,
    EzFilterList,
    StatusBadge,
  },
  data() {
    return {
      headers: {
        name: () => 'Product',
        price: () => 'Price per unit',
        actions: () => '',
      },
      products: [],
      selectedProduct: {},
      meta: {},
      formKey: 'connect-supplier-product',
      categories: [],
      filters: {
        term: null,
      },
      DEFAULT_FRACTION_DIGITS,
    };
  },
  computed: {
    ...mapState('entities/venues', ['newVenue', 'acceptAndSendOnboardingLink']),
    ...mapState('entities/users', [
      'context',
      'contextId',
      'loggedUser',
    ]),
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    loading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_HIDE_UNHIDE_PRODUCTS_FOR_VENUE,
        LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCTS_FOR_VENUE,
        LOADING_KEY.DISTRIBUTOR_BULK_UPDATE_PRODUCTS_PRICES_FOR_VENUE,
      ]);
    },
    venueId() { return this.acceptAndSendOnboardingLink.venue?.id; },
    addProductAction() {
      return `/distributor/venues/${this.venueId}/products`;
    },
    changedPriceProducts() {
      return this.acceptAndSendOnboardingLink.products;
    },
    distributorId() { return this.loggedUser.id; },
    columns() {
      return [
        'name',
        'visibility',
        'unit',
        'pricePerUnit',
        ...(this.$permission.isPremium && this.canEditPrice ? ['actions'] : []),
      ];
    },
    setPriceAction() {
      return `/distributor/venues/${this.venueId}/products/${this.selectedProduct.id}/price`;
    },
    newAddedProducts() {
      return this.newVenue?.products;
    },
    canEditPrice() {
      return this.$permission.has('editProductPricing');
    },
    canHideProducts() {
      return this.$permission.has('hideProducts');
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'distributorFetchProductsForVenue',
      'distributorHideVenueProduct',
      'distributorUnhideVenueProduct',
      'distributorHideAllVenueProducts',
      'distributorUnhideAllVenueProducts',
      'distributorUploadVenueProducts',
      'distributorFetchVenueProduct',
      'removeDistributorProduct',
    ]),
    ...mapActions('entities/categories', [
      'distributorFetchCategories',
    ]),
    ...mapMutations('entities/venues', [
      'UPDATE_NEW_ADDED_PRODUCTS',
      'UPDATE_PRICE_CHANGED_PRODUCTS_ACCEPT',
    ]),
    ...mapActions('entities/distributors', [
      'distributorBulkUpdatePriceForTargetVenueProducts',
    ]),
    async onNextStep() {
      if (this.changedPriceProducts.length) {
        await this.distributorBulkUpdatePriceForTargetVenueProducts({
          venueId: this.venueId,
          products: this.changedPriceProducts,
        });
      }
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    proposePrice(product) {
      this.selectedProduct = product;
      this.$refs.modal.open();
    },
    newProduct() {
      this.selectedProduct = {};
      this.openEditModal();
    },
    closeEditModal() {
      this.$refs.productModal.close();
      this.selectedProduct = {};
    },
    openEditModal() {
      this.$refs.productModal.open();
    },
    async openSingleProduct(product) {
      const { id } = product;
      const { data: { data } } = await this.distributorFetchVenueProduct({
        venueId: this.venueId,
        productId: id,
      });
      this.selectedProduct = clone({
        ...data,
        price: product.price,
        marketPrice: product.marketPrice,
        forceRerender: new Date().valueOf(),
      });

      this.openEditModal();
    },
    onModalSuccess(product) {
      flash.success({ title: `Product successfully ${falsy(this.selectedProduct) ? 'added' : 'updated'}.` });
      if (falsy(this.selectedProduct)) {
        const { data: { data } } = product;
        const newUser = [];
        newUser.push(data);
        this.UPDATE_NEW_ADDED_PRODUCTS(newUser);
      }
      this.selectedProduct = {};
      this.refresh();
      this.closeEditModal();
    },
    canDelete(item) {
      return !!this.newAddedProducts.find(pr => pr.id === item.id);
    },
    async removeProduct(row) {
      const { close, canceled } = await confirmation.alert({
        title: this.$t('product.removeProductAlertTitle'),
        message: this.$t('product.removeProductAlertMessage'),
      });
      if (canceled) return;
      close();

      try {
        await this.removeDistributorProduct({
          id: row.id,
        });
        flash.success({ title: 'Product successfully removed.' });
        await this.refresh();
      } catch (e) {
        flash.error({ title: 'Something went wrong' });
      }
    },
    fetchNewDistributorProducts() {
      const queryParams = {
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
        ...(this.filters.term ? { term: this.filters.term } : {}),
        sortBy: 'name',
        limit: '20',
      };
      return this.distributorFetchProductsForVenue({
        venueId: this.venueId,
        params: queryParams,
      });
    },
    async refresh() {
      this.meta = {};
      const { data } = await this.fetchNewDistributorProducts();
      this.products = data.data;
      this.meta = data.meta;
    },
    async loadMore() {
      const { data } = await this.fetchNewDistributorProducts();
      this.products = [
        ...this.products,
        ...data.data,
      ];
      this.meta = data.meta;
    },
    onLoadMore() {
      this.loadMore();
    },
    uploadCSVModal() {
      this.$refs.csvUploadModal.open();
    },
    uploadCSVAction(data) {
      return this.distributorUploadVenueProducts({
        venueId: this.venueId,
        data,
      });
    },
    onUploadFinished({ entities }) {
      const newProducts = entities.filter(en => en.isNew);
      this.UPDATE_NEW_ADDED_PRODUCTS(newProducts);
      this.refresh();
    },
    updateProductAvailability(venue) {
      const { id, isHidden } = venue;
      const data = {
        productId: id,
        venueId: this.venueId,
      };
      return isHidden
        ? this.distributorUnhideVenueProduct(data)
        : this.distributorHideVenueProduct(data);
    },
    async updateVisibility(venue) {
      try {
        await this.updateProductAvailability(venue);
        await this.refresh();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    async bulkUpdateProductVisibility(isHidden) {
      try {
        const data = {
          venueId: this.venueId,
        };
        await (isHidden
          ? this.distributorHideAllVenueProducts(data)
          : this.distributorUnhideAllVenueProducts(data));
        await this.refresh();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    selectAll() {
      this.bulkUpdateProductVisibility(false);
    },
    deselectAll() {
      this.bulkUpdateProductVisibility(true);
    },
    updateFilters(filterName, event) {
      if (filterName === 'search') {
        this.filters = {
          ...this.filters,
          term: event,
        };
      } else {
        this.filters = {
          ...this.filters,
          [filterName]: event.id,
        };
      }
      this.refresh();
    },
    resetFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });
      this.refresh();
    },
    onPriceChange(product, newPrice) {
      if ((!product.price && (newPrice === 0)) || product.price === newPrice) {
        return;
      }
      this.UPDATE_PRICE_CHANGED_PRODUCTS_ACCEPT({ productId: product.id, price: newPrice });
      this.products.find(prod => prod.id === product.id).price = newPrice;
    },
    onSetMarketPrice(product) {
      this.UPDATE_PRICE_CHANGED_PRODUCTS_ACCEPT({ productId: product.id, marketPrice: true });
      this.products.find(prod => prod.id === product.id).marketPrice = true;
    },
    onSetRegularPrice(product) {
      this.UPDATE_PRICE_CHANGED_PRODUCTS_ACCEPT({ productId: product.id, marketPrice: false });
      this.products.find(prod => prod.id === product.id).marketPrice = false;
    },
    status(isHidden) {
      return isHidden ? PRODUCT_VISIBILITY_HIDDEN : PRODUCT_VISIBILITY_VISIBLE;
    },
  },
  async created() {
    const [categories] = await Promise.all([
      this.distributorFetchCategories(),
      this.refresh(),
    ]);
    const { data: { data } } = categories;
    this.categories = data;
    this.enableNextStep();
  },
};
</script>

<template>
  <div>
    <product-modal
      ref="productModal"
      :form-key="formKey"
      :form-action="addProductAction"
      :distributor-id="distributorId"
      :product="selectedProduct"
      :categories="categories"
      @success="onModalSuccess"
    />

    <ez-csv-upload-modal
      ref="csvUploadModal"
      :for-venue="true"
      :uploadCSV="uploadCSVAction"
      :onUploadFinished="onUploadFinished"/>

    <ez-filter-list
      :filters="filters"
      @resetFilter="resetFilters"
      @filterUpdated="updateFilters"
      class="venue-products__filters mb-24">
      <ez-input
        formKey="filters"
        label="search"
        name="search"
        class="search"
        placeholder="Search for a Product">
        <template #prefix>
          <font-awesome-icon icon="search"/>
        </template>
      </ez-input>
      <template #actions>
        <ez-button-group>
          <template v-if="canHideProducts">
            <ez-simple-dropdown placeholder="Set Visibility">
              <template #dropdown>
                <div class="dropdown-actions bulk-actions">
                  <ez-button :disabled="!products.length" type="link" @click="selectAll">
                    <font-awesome-icon icon="eye" class="mr-8"/><span>Show All</span>
                  </ez-button>
                  <ez-button :disabled="!products.length" type="link" @click="deselectAll">
                    <font-awesome-icon icon="eye-slash" class="mr-8"/><span>Hide All</span>
                  </ez-button>
                </div>
              </template>
            </ez-simple-dropdown>
            <span class="ez-button-group__line"></span>
          </template>
          <ez-button
            type="secondary"
            @click="uploadCSVModal"
          >
            Upload CSV
          </ez-button>
          <ez-button @click="newProduct">
            <span>Add New Product</span>
          </ez-button>
        </ez-button-group>
      </template>
    </ez-filter-list>

    <ez-table
      class="products-table"
      v-if="products.length"
      :data="products"
      :headers="headers"
      :loading="loading"
      :columns="columns"
      @rowClick="openSingleProduct"
      :columnProps="{
        unit: { class: 'medium-cell' },
        pricePerUnit: { class: 'price-cell-custom' },
        actions: { class: 'min-actions-cell' },
        visibility: { class: 'visibility-cell' },
      }">
      <template #cell-name="{ row }">
        <ez-entity-info
          img-width="2rem"
          img-height="2rem"
          :img-url="row.image"
        >
          <div class="product-info" :title="row.name">
            <span>{{ row.name }}</span>
            <span class="product-info-secondary">
              <span v-if="row.sku">{{ row.sku }} &#8226;</span>
              {{ row.category | categoryWithParent }}
            </span>
          </div>
        </ez-entity-info>
      </template>
      <template #cell-visibility="{ row }">
        <v-status-dropdown
          v-if="canHideProducts"
          @change="updateVisibility(row)"
          :data="$helpers.getVisibilityOptions(row.isHidden)"
        />
        <status-badge v-else :status="status(row.isHidden)" />
      </template>
      <template #cell-unit="{ row: { orderingUnit } }">
        {{ orderingUnit ? orderingUnit.label : '' }}
      </template>
      <template #cell-pricePerUnit="{ row }">
        <div class="changeable-price" @click.stop>
          <div class="u-flex-center">
            <v-market-price-input
              v-if="row"
              :key="row.id"
              :value="row.price || 0"
              :is-market-price="row.marketPrice"
              :is-it-different-from-number="row.marketPrice"
              :precision="row.currency?.fractionDigits ?? DEFAULT_FRACTION_DIGITS"
              :price-prefix="row.currency?.showSymbol ?? true"
              @input="onPriceChange(row, $event)"
              @setRegularPrice="onSetRegularPrice(row)"
              @setMarketPrice="onSetMarketPrice(row)"
              :disabled="!canEditPrice"
            />
          </div>
        </div>
      </template>
      <template #cell-actions="{ row }">
        <ez-button
          v-if="canDelete(row)"
          type="link"
          formType="button"
          @click.stop="removeProduct(row)">
          <font-awesome-icon icon="trash"/>
        </ez-button>
      </template>
    </ez-table>
    <template v-else>
      <empty-state class="empty-state-center">
        <template #badge>
          <img src="@/assets/no-product-empty-state.svg" alt=""></template>
        <template #title>No products listed</template>
      </empty-state>
    </template>
    <ez-load-more v-if="meta.nextId && !loading" @loadMore="onLoadMore"/>
  </div>
</template>

<style lang="scss" scoped>
.header {
  justify-content: flex-end;
}

.bulk-actions {
  .button {
    width: 100%;
  }
}

:deep() .products-table {

  tbody tr td {
    overflow: visible;
  }
}

.product-info {
  display: flex;
  flex-direction: column;
}

.product-info--secondary {
  display: block;
}

:deep() .table {
  tbody tr td {
    line-height: 1.5;
  }
  thead tr {
    .price-cell-custom {
      text-align: right;
    }
  }
}

:deep() .visibility-cell {
  width: 100px;
}

:deep() .loader {
  position: fixed;
}
.changeable-price {
  display: flex;
  justify-content: flex-end;
}
.min-actions-cell {
  width: 28px;
  padding-left: 0 !important;
}
.price-cell-custom {
  width: 110px;
  padding-left: 0;
  padding-right: 0 !important;
}
</style>
