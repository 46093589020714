<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { CREDIT_NOTES_ACTIONS } from '@/util/constants';
import ButtonSwitch from '@/views/platform/distributor/orders/credit-notes/single-credit-note/actions-footer/ButtonSwitch.vue';

/**
 * CreditNoteFooter
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'CreditNoteFooter',
  props: {
    creditNote: {
      required: true,
      type: Object,
    },
    updatedCreditNote: {
      required: false,
      type: Object,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ButtonSwitch,
  },
  data() {
    return {
      CREDIT_NOTES_ACTIONS,
      supplierCy,
    };
  },
  computed: {
    /**
     * Combine actions fetched from API and locally hardcoded actions for Edit flow
     */
    combinedActions() {
      return [...this.creditNote?.actions, ...CREDIT_NOTES_ACTIONS];
    },
    actionsEvents() {
      return this.combinedActions?.reduce(
        (obj, item) => ({
          ...obj,
          [item.key]: () => this.onEvent(item),
        }),
        {},
      );
    },
    content() {
      const { TEXT__FOOTER_TOTAL_TOOLTIP } = supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE;

      return `
      <div class="tax" data-cy="${TEXT__FOOTER_TOTAL_TOOLTIP}">
        <div class="tax__item">
          <strong class="tax__label">Stock Credit:</strong>
          <span
            class="tax__number"
            data-cy="${TEXT__FOOTER_TOTAL_TOOLTIP}-stockCredit"
          >
            ${this.$helpers.formatPrice(this.creditNote.stockCredit)}
          </span>
        </div>
        <div class="tax__item">
          <strong class="tax__label">Non-Stock Credit:</strong>
          <span
            class="tax__number"
            data-cy="${TEXT__FOOTER_TOTAL_TOOLTIP}-nonStockCredit">
            ${this.$helpers.formatPrice(this.creditNote.nonStockCredit)}
          </span>
        </div>
        <div class="tax__item">
          <strong
            class="tax__label"
            data-cy="${TEXT__FOOTER_TOTAL_TOOLTIP}-tax"
          >
            Tax (${this.creditNote.tax}%):
          </strong>
          <span
            class="tax__number"
            data-cy="${TEXT__FOOTER_TOTAL_TOOLTIP}-taxAmount"
          >
            ${this.$helpers.formatPrice(this.creditNote.taxAmount)}
          </span>
        </div>
        <div class="tax__item tax__item--total">
          <strong class="tax__label">Total:</strong>
          <span
            class="tax__number"
            data-cy="${TEXT__FOOTER_TOTAL_TOOLTIP}-amount"
          >
            ${this.$helpers.formatPrice(this.creditNote.amount)}
          </span>
        </div>
      </div>
`;
    },
    tooltip() {
      return {
        html: true,
        placement: 'top-end',
        classes: ['tooltip--reset-margin', 'tooltip--lift-up'],
        content: this.content,
      };
    },
    disableSaveChanges() {
      const touchedProducts = Object.prototype.hasOwnProperty.call(this.updatedCreditNote, 'items');
      const touchedNonStockCredit = Object.prototype.hasOwnProperty.call(this.updatedCreditNote, 'nonStockCredit');
      if (!touchedProducts && !touchedNonStockCredit) return true;

      const hasProducts = !touchedProducts || !!this.updatedCreditNote.items?.length;
      const hasNonStockCredit = !touchedNonStockCredit || !!this.updatedCreditNote.nonStockCredit;
      return !hasProducts && !hasNonStockCredit;
    },
  },
  methods: {
    onEvent(ev) {
      // There will be handler for actions (open modal, double confirmation or edit flow)
      this.$emit(ev.key);
    },
  },
};
</script>

<template>
  <div class="width-100 u-flex-space">
    <button-switch
      v-on="actionsEvents"
      :credit-note="creditNote"
      :editMode="editMode"
      :disableSaveChanges="disableSaveChanges"
    />

    <span v-tooltip="tooltip" class="tax-label">
      <font-awesome-icon icon="info-circle" />
      <span
        class="v-subtotal-info__total"
        :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TEXT__FOOTER_TOTAL"
      >
        {{ $helpers.formatPrice(creditNote.amount) }}
      </span>
    </span>
  </div>
</template>

<style lang="scss" scoped></style>
