<script>
import EzEntityInfo from '@/components/ui/EntityInfo';

/**
 * VProductInventoryItems
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'VProductInventoryItems',
  props: {
    items: {
      type: Array,
      required: true,
    },
    quantityLabel: {
      type: String,
      required: true,
    },
    quantityKey: {
      type: String,
      required: true,
    },
  },
  components: { EzEntityInfo },
  methods: {
    tooltipContent(item) {
      return `
        <div class="product-tooltip">
          <div class="product-tooltip__line">
            <span>${item.product.name}</span>
          </div>
          <div class="product-tooltip__line">
            <span>SKU:</span>
            <span>${item.product.sku || '-'}</span>
          </div>
          <div class="product-tooltip__line">
            <span>Batch ID:</span>
            <span>${item.batchCode || '-'}</span>
          </div>
          <div class="product-tooltip__line">
            <span>${this.quantityLabel}:</span>
            <span>${item[this.quantityKey]}</span>
          </div>
        </div>
      `;
    },
  },
};
</script>

<template>
  <div class="product-inventory-items u-flex-center">
    <ez-entity-info
      v-for="item in items"
      :key="item.id"
      v-tooltip="{
        html: true,
        placement: 'start',
        content: tooltipContent(item),
        classes: ['tooltip-general'],
      }"
      :imgUrl="item.product.image"
      imgWidth="2rem"
      imgHeight="2rem"
      imgBorderRadius="3px"
      class="product-inventory-items__item"
    />
  </div>
</template>

<style lang="scss">
.product-inventory-items__item:not(:first-child) {
  margin-left: 4px;
}

.product-tooltip {
  min-width: 160px;
  max-width: 240px;

  &__line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    span {
      display: block;
      max-width: 100%;
      white-space: normal;
      overflow-wrap: break-word;

      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
</style>
