export const REMOVAL_STEP_1 = 0;
export const REMOVAL_STEP_2 = 1;
export const REMOVAL_STEP_3 = 2;

const steps = {
  [REMOVAL_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/removal/Warehouse.vue'),
    title: 'Warehouse',
    pageTitle: 'Please choose warehouse',
    pageInfo: 'Please, select the warehouse from which you would like to remove the products.',
    nextCta: 'Next Step',
  },
  [REMOVAL_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/removal/Products.vue'),
    title: 'Products',
    pageTitle: 'Now choose products to remove',
    pageInfo: 'Please, select the products that should be removed from the selected warehouse.',
    nextCta: 'Go to Overview',
  },
  [REMOVAL_STEP_3]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/removal/Overview.vue'),
    title: 'Overview',
    pageTitle: 'Overview',
    pageInfo: 'Before removing the products from the selected warehouse, check the information below to confirm you have entered everything correctly.',
    nextCta: 'Finish',
  },
};

export default steps;
