<script>
/**
 * Netsuite AccountInfo
 * @version 1.0.0
 * @since 3.24.0
 */
import { mapActions, mapGetters } from 'vuex';
import netsuiteGuard from '@/views/platform/distributor/netsuite/netsuiteGuard';
import { LOADING_KEY } from '@/util/constants';
import flash from '@/components/ui/FlashMessage';
import EzButton from '@/components/ui/Button/EzButton';
import EzLoader from '@/components/ui/Loader';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch/index';

export default {
  mixins: [netsuiteGuard],
  name: 'AccountInfo',
  components: {
    EzButton,
    EzLoader,
    VSelectSearch,
  },
  data() {
    return {
      initialValues: {},
      values: {},
      subsidiaries: [],
      deliveryItems: [],
      customForms: [],
      searchResults: null,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    ...mapGetters('entities/users', {
      connectionExpired: 'isDistributorNetSuiteConnectionExpired',
    }),
    loading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_SUBSIDIARIES,
        LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_CUSTOM_FORMS,
        LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_DELIVERY_ITEMS,
        LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_CONFIG,
        LOADING_KEY.DISTRIBUTOR_NETSUITE_UPDATE_CONFIG,
      ]);
    },
    buttonDisabled() {
      return Object.entries(this.values).every(([key, value]) => this.initialValues[key] === value);
    },
  },
  methods: {
    ...mapActions('netsuite', [
      'distributorNetsuiteFetchSubsidiaries',
      'distributorNetsuiteFetchCustomForms',
      'distributorNetsuiteFetchDeliveryItems',
      'distributorNetsuiteFetchConfig',
      'distributorNetsuiteUpdateConfig',
    ]),
    async fetchConfig() {
      const { data } = await this.distributorNetsuiteFetchConfig();
      this.values = { ...data.data.config };
      this.initialValues = { ...data.data.config };
    },
    onChange(key, value) {
      this.values = {
        ...this.values,
        [key]: value.reset ? null : value.id,
      };
    },
    async saveChanges() {
      try {
        await this.distributorNetsuiteUpdateConfig({ data: this.values });
        flash.success({ title: this.$t('netsuite.accountInfo.flashMessages.success.title') });
        await this.fetchConfig();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        flash.success({ title: this.$t('netsuite.accountInfo.flashMessages.error.title') });
      }
    },
    onSearchResults({ results }) {
      if (results.length) this.searchResults = results;
      else this.clearSearchResults();
    },
    clearSearchResults() {
      this.searchResults = null;
    },
  },
  async created() {
    if (this.connectionExpired) return;

    const [{ data: subsidiaries }, { data: customForms }, { data: deliveryItems }] = await Promise.all([
      this.distributorNetsuiteFetchSubsidiaries(),
      this.distributorNetsuiteFetchCustomForms(),
      this.distributorNetsuiteFetchDeliveryItems(),
      this.fetchConfig(),
    ]);

    this.subsidiaries = subsidiaries.data;
    this.customForms = customForms.data;
    this.deliveryItems = deliveryItems.data;
  },
};
</script>

<template>
  <div class="container--small">
    <div class="help-block mb-16 distributor-info">
      <h4 class="distributor-info__title">General</h4>
    </div>

    <v-select-search
      label="Subsidiary"
      placeholder="Select Subsidiary"
      search="/distributor/netsuite/subsidiaries"
      :disable="connectionExpired"
      :data="searchResults || subsidiaries"
      :min-length="3"
      :debounce-time="700"
      :selected="values.subsidiary"
      @selected="onChange('subsidiary', $event)"
      @results="onSearchResults"
      @close="clearSearchResults"
      is-full-width
    />

    <a href="#" hidden aria-label="hidden" />

    <hr />

    <div class="help-block mb-16 distributor-info">
      <h4 class="distributor-info__title">Sales Orders</h4>
    </div>

    <v-select-search
      label="Custom Form"
      placeholder="Select Custom Form"
      search="/distributor/netsuite/custom-forms"
      :disable="connectionExpired"
      :data="searchResults || customForms"
      :min-length="3"
      :debounce-time="700"
      :selected="values.customForm"
      @selected="onChange('customForm', $event)"
      @results="onSearchResults"
      @close="clearSearchResults"
      is-full-width
    />

    <v-select-search
      label="Delivery Fee"
      placeholder="Select Delivery Fee"
      search="/distributor/netsuite/items"
      searchProperty="displayname"
      :disable="connectionExpired"
      :data="searchResults || deliveryItems"
      :min-length="3"
      :debounce-time="700"
      :selected="values.deliveryItem"
      @selected="onChange('deliveryItem', $event)"
      @results="onSearchResults"
      @close="clearSearchResults"
      is-full-width
      class="mt-12"
    />

    <ez-button @click="saveChanges" :disabled="buttonDisabled" class="mt-24">
      Save Changes
    </ez-button>

    <ez-loader :show="loading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped></style>
