<script>
import { mapState, mapActions } from 'vuex';
import TextEditor from '@/components/ui/TextEditor';
import EzButton from '@/components/ui/Button/EzButton';
import EzOnOff from '@/components/ui/OnOff';
import EzForm from '@/components/ui/Form';
import flash from '@/components/ui/FlashMessage';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    TextEditor,
    EzButton,
    EzOnOff,
    EzForm,
  },
  data() {
    return {
      formKey: 'supplier-sales-settings',
      loadingKeyForm: 'supplier-update-sales-settings',
      tos: '',
      notifyVenueContacts: false,
      sendCompletedNotification: false,
      distributor: {},
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    distributorId() {
      return this.loggedUser.distributor.id;
    },
  },
  methods: {
    async saveChanges() {
      try {
        this.$refs.form.onSubmit();
        await this.distributorUpdateTermsAndConditions({ terms: this.tos });
        flash.success({ title: 'Sales settings successfully updated!' });
      } catch (e) {
        flash.error({ title: 'Something went wrong!' });
      }
    },
    updateEditorValue(ev) {
      this.tos = ev;
    },
    transformer(data) {
      if (!this.notifyVenueContacts) data = { ...data, notifyVenueContacts: this.notifyVenueContacts.toString() };
      if (!this.sendCompletedNotification) data = { ...data, sendCompletedNotification: this.sendCompletedNotification.toString() };
      return data;
    },
    ...mapActions('entities/distributors', [
      'distributorFetchTermsAndConditions',
      'distributorUpdateTermsAndConditions',
      'fetchDistributorSingle',
      'distributorUpdateOnboardingSettings',
    ]),
  },
  async created() {
    const [
      { data: { data: tos } },
      { data: { data: distributor } },
    ] = await Promise.all([
      this.distributorFetchTermsAndConditions(),
      this.fetchDistributorSingle(),
    ]);

    this.tos = tos;
    this.notifyVenueContacts = distributor.notifyVenueContacts ?? false;
    this.sendCompletedNotification = distributor.config.sendCompletedNotification ?? false;
    this.distributor = distributor;
  },
};
</script>
<template>
  <div class="sales-settings">
    <ez-form
      :formKey="formKey"
      :loading-key="loadingKeyForm"
      :transformer="transformer"
      action="/distributor/me"
      method="patch"
      ref="form"
    >
      <div class="sales-settings__section sales-settings__section--notifications container--small mb-24">
        <div class="help-block">
          <h4 class="help-block__title custom-title">B2B Portal and Communication</h4>
          <p class="help-block__text mb-0">
            When enabled, {{ $t('global.venue') }} contacts will be sent an invitation to log into the B2B portal and
            will receive order status notifications.
          </p>
        </div>
        <ez-on-off
          :formKey="formKey"
          name="notifyVenueContacts"
          :value="notifyVenueContacts.toString()"
          :checked="notifyVenueContacts"
          @change="$ev => notifyVenueContacts = $ev"
        />
      </div>

      <div class="sales-settings__section sales-settings__section--notifications container--small">
        <div class="help-block">
          <h4 class="help-block__title custom-title">Send Invoices for Completed Orders</h4>
          <p class="help-block__text mb-0">
            When enabled, {{ $t('global.venue') }} contacts will receive an email with the invoice attached as a PDF upon order completion.
          </p>
        </div>
        <ez-on-off
          :formKey="formKey"
          name="sendCompletedNotification"
          :value="sendCompletedNotification.toString()"
          :checked="sendCompletedNotification"
          @change="$ev => sendCompletedNotification = $ev"
        />
      </div>

      <div class="container--small">
        <hr />
      </div>

      <div class="sales-settings__section container--small">
        <div class="help-block">
          <h4 class="help-block__title">Terms & Conditions</h4>
          <p class="help-block__text">
            Add your terms and conditions that {{ $t('global.venue') | lowercase }} will need to
            accept in order to connect with you. We’ll also generate a PDF for them to download.
          </p>
        </div>
      </div>

      <TextEditor v-if="tos || tos === null" :init-data="tos" @update="updateEditorValue" />

      <template #buttons>
        <ez-button @click="saveChanges" class="mt-16" form-type="button">Save Changes</ez-button>
      </template>
    </ez-form>
  </div>
</template>

<style lang="scss" scoped>
.sales-settings {
  &__section {
    display: flex;
    align-items: flex-start;

    &--notifications {
      .help-block {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    .help-block {
      &__title {
        &.custom-title {
          font-weight: 600;
        }
      }

      &__text {
        @include font-size(14px, 20px);
      }
    }
  }
}
</style>
