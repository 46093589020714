<template>
  <ez-account-menu :data-cy="supplierCy.MAIN_NAVIGATION.BUTTON__ACCOUNT_SETTINGS">
    <ez-entity-info
      :imgUrl="loggedUser.distributor.logo"
      imgWidth="2rem"
      imgHeight="2rem"
      imgBorderRadius="50%">
      <span>{{ loggedUser.distributor.name }}</span>
    </ez-entity-info>
    <template #dropdown>
      <div class="dropdown-section">
        <div class="dropdown-section__label">{{ loggedUser.name }}</div>
        <ez-entity-info
          :imgUrl="loggedUser.distributor.logo"
          imgWidth="2.25rem"
          imgHeight="2.25rem"
          imgBorderRadius="50%">
          <div>{{ loggedUser.distributor.name }}</div>
        </ez-entity-info>
      </div>
    </template>
    <template #userSettings>
      <router-link
        :to="{ name: 'manage-accounts' }"
        class="button button--link"
      >
        Manage Accounts
      </router-link>
      <router-link
        :data-cy="supplierCy.MAIN_NAVIGATION.BUTTON__MY_SETTINGS"
        :to="{ name: 'distributor-user-settings' }"
        class="button button--link"
      >
        My Settings
      </router-link>
      <router-link
        :data-cy="supplierCy.MAIN_NAVIGATION.BUTTON__SUPPLIER_SETTINGS"
        v-if="$permission.has('editSettings')"
        :to="{ name: 'distributor-settings' }"
        class="button button--link"
      >
        {{ $t('global.distributor') }} Settings
      </router-link>
    </template>
  </ez-account-menu>
</template>

<script>
import { mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import EzEntityInfo from '@/components/ui/EntityInfo';
import { EzAccountMenu } from '@/views/platform/user-settings';

export default {
  components: {
    EzAccountMenu,
    EzEntityInfo,
  },
  data() {
    return {
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/users', [
      'loggedUser',
    ]),
  },
};
</script>
