<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { CREDIT_NOTES_EDIT_ACTIONS_EVENTS } from '@/util/constants';

import { EzButtonGroup } from '@/components/ui/Button';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';

/**
 *
 * @version 1.1.0
 * @since 3.28.0
 */
export default {
  components: {
    EzButton,
    EzSimpleDropdown,
    EzButtonGroup,
  },
  props: {
    hasMoreThanTwoActions: {
      required: true,
      type: Boolean,
    },
    creditNote: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: false,
    },
    toDisableSaveChanges: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Forced disabled actions.
     * This is an array of objects, each object must have
     * btnType (look at the key in array of actions in response from BE)
     * and value (true or false)
     */
    forceDisable: {
      type: Array, // [{ btnType: 'schedule_delivery', value: true }]
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      firstAction: [],
      restActions: [],
      CREDIT_NOTES_EDIT_ACTIONS_EVENTS,
      supplierCy,
    };
  },
  methods: {
    initActions() {
      const { actions } = this.creditNote;

      if (this.hasMoreThanTwoActions) {
        const [first, ...rest] = actions;
        this.firstAction = first;
        this.restActions = rest;
        return;
      }
      this.restActions = actions;
    },
    isForceDisabled(actionKey) {
      const tst = this.forceDisable.find(fd => fd.btnType === actionKey);
      const val = tst?.value;
      return tst ? val : false;
    },
    clickAction(ev) {
      this.$emit(ev);
    },
  },
  mounted() {
    this.initActions();
  },
  watch: {
    creditNote() {
      this.initActions();
    },
  },
};
</script>
<template>
  <!-- Editing mode buttons -->
  <ez-button-group v-if="editMode">
    <ez-button
      :disabled="toDisableSaveChanges"
      @click="clickAction(CREDIT_NOTES_EDIT_ACTIONS_EVENTS.saveCreditEdit)"
      :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.BUTTON__ACTION_SAVE"
    >
      Save Changes
    </ez-button>
    <ez-button
      type="secondary"
      @click="clickAction(CREDIT_NOTES_EDIT_ACTIONS_EVENTS.discardCreditEdit)"
      :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.BUTTON__ACTION_DISCARD"
    >
      Discard
    </ez-button>
  </ez-button-group>
  <ez-button-group v-else>
    <template v-if="!hasMoreThanTwoActions">
      <ez-button
        v-for="(action, ind) in restActions"
        :key="action.key"
        @click="clickAction(action.key)"
        :disabled="isForceDisabled(action.key)"
        :type="ind > 0 ? 'secondary' : 'primary'"
        :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.BUTTON__ACTION}-${action.key}`"
      >
        {{ action.label }}
      </ez-button>
    </template>
    <template v-else>
      <ez-button
        @click="clickAction(firstAction.key)"
        :disabled="isForceDisabled(firstAction.key)"
        :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.BUTTON__ACTION}-${firstAction.key}`"
      >
        {{ firstAction.label }}
      </ez-button>
      <ez-simple-dropdown
        direction="up"
        placeholder="Manage Credit Note"
        class="manage-orders"
        :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.BUTTON__ACTION_MANAGE"
      >
        <template #dropdown>
          <div class="dropdown-actions">
            <ez-button
              v-for="action in restActions"
              :key="action.key"
              @click="$emit(action.key)"
              :disabled="isForceDisabled(action.key)"
              type="link"
              class="dropdown-actions__btn dropdown-actions__btn--complete"
              :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.BUTTON__ACTION}-${action.key}`"
            >
              {{ action.label }}
            </ez-button>
          </div>
        </template>
      </ez-simple-dropdown>
    </template>
  </ez-button-group>
</template>
<style lang="scss" scoped>
.manage-orders {
  display: inline-block;
  width: auto;

  :deep() .ez-simple-dropdown {
    &__label {
      padding-right: 15px;
    }

    &__dropdown {
      min-width: 100%;
      width: fit-content;
    }
  }

  .dropdown-actions {
    width: 100%;

    &__btn {
      text-align: left;
      padding: 0 8px;
      opacity: 0.8;
      width: 100%;
      font-weight: 400;
      white-space: nowrap;

      &--complete {
        color: $primary-color;
      }

      &:disabled {
        background-color: transparent;
        opacity: 0.48;
        color: $color-gray-6C;

        &:hover {
          opacity: 0.48;
        }
      }

      &--decline,
      &--dispute {
        color: $color-primary-red;

        &:disabled {
          color: $color-primary-red;
        }
      }

      &:hover {
        opacity: 1;
      }
    }
    &__label {
      @include font-size(11px, 14px);
      letter-spacing: 0.15px;
      color: $color-gray-6C;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 8px 8px 0;
      font-weight: 600;
    }
  }

  :deep() .ez-simple-dropdown {
    &__label {
      color: $color-gray-6C;
    }
  }
}
</style>
