<template>
  <tab-layout :disabled="isTrialExpired">
    <template #banner>
      <ez-alert v-if="banner" customClass="distributor-banner">
        <component :is="'style'">{{ css }}</component>
        <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
        <div class="distributor-banner__message">{{ banner.text }}</div>
        <template #cta v-if="banner.cta">
          <ez-button type="link" @click="onBannerCTAClick">
            {{ banner.cta }}
          </ez-button>
          <router-link
            v-if="isTrialExpired"
            :to="{ name: 'manage-accounts' }"
            class="banner__link ml-8"
          >
            Switch to a different account
          </router-link>
          <ez-button
            v-if="isTrialExpired"
            :data-cy="commonCy.ACCOUNT_SETTINGS.BUTTON__SIGN_OUT"
            type="link"
            formType="button"
            class="banner__link sign-out"
            @click="onLogoutClick"
          >
            Sign Out
            <font-awesome-icon icon="sign-out-alt"/>
        </ez-button>
        </template>
      </ez-alert>
    </template>

    <template #nav>
      <ul>
        <li>
          <router-link
            :data-cy="supplierCy.MAIN_NAVIGATION.LINK__ORDERS"
            :to="{ name: 'distributor-home' }"
            :class="{ 'not-active': !ordersRoutes.includes($route.name) }"
          >
            Orders
          </router-link>
          <ez-badge
            type="yellow"
            v-if="canSeePendingOrders && pendingOrderNotification"
            :count="pendingOrderNotification"
          />
        </li>
        <li v-if="canSeeProducts">
          <router-link
            :data-cy="supplierCy.MAIN_NAVIGATION.LINK__PRODUCTS"
            :to="{ name: 'distributor-products' }"
          >
            Products
          </router-link>
          <ez-badge type="green" :count="priceProposalsCount" />
        </li>
        <li v-if="$permission.isPremium">
          <router-link
            :data-cy="supplierCy.MAIN_NAVIGATION.LINK__OUTLETS"
            :to="{ name: 'distributor-outlets' }"
          >
            {{ $t('global.venues') }}
          </router-link>
          <ez-badge type="purple" :count="venueInvitationsCount" />
        </li>
        <li v-if="$permission.isPremium && canSeeReports">
          <router-link
            :data-cy="supplierCy.MAIN_NAVIGATION.LINK__REPORTS"
            :to="{ name: 'distributor-reports' }"
          >
            {{ $t('reports.general.title') }}
          </router-link>
        </li>
        <li v-if="$permission.isPremium && canSeeCRM">
          <router-link
            :data-cy="supplierCy.MAIN_NAVIGATION.LINK__CRM"
            :to="{ name: 'distributor-crm' }"
          >
            {{ $t('crm.general.title') }}
          </router-link>
          <ez-badge type="blue" :count="toDosNotification" />
        </li>
      </ul>
    </template>
    <template #accountMenu>
      <ez-account-menu />
    </template>
    <router-view></router-view>
  </tab-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import TabLayout from '@/components/layout/Tab.vue';
import EzAccountMenu from '@/views/platform/distributor/AccountMenu.vue';
import EzAlert from '@/components/ui/Alert/EzAlert.vue';
import EzButton from '@/components/ui/Button';
import EzBadge from '@/components/ui/Badge';
import { ACCOUNT_STATUS_TRIAL_EXPIRED } from '@/util/constants';
import { resetState } from '@/store';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';

export default {
  components: {
    EzAccountMenu,
    TabLayout,
    EzAlert,
    EzBadge,
    EzButton,
  },
  data() {
    return {
      supplierCy,
      commonCy,
    };
  },
  computed: {
    ...mapGetters('entities/users', [
      'pendingOrderNotification',
      'priceProposalsCount',
      'venueInvitationsCount',
      'toDosNotification',
    ]),
    ...mapState('entities/users', ['loggedUser']),
    canSeeProducts() {
      return this.$permission.has('seeProducts');
    },
    canSeePendingOrders() {
      const orderTypes = this.$permission.permissions.seeOrders.statuses;
      return orderTypes ? orderTypes.includes('pending') : true;
    },
    canSeeReports() {
      return this.$permission.has('seeReports');
    },
    canSeeCRM() {
      return this.$permission.has('seeCRM');
    },
    distributor() {
      return this.loggedUser.distributor;
    },
    isTrialExpired() {
      return this.distributor.subscriptionStatus === ACCOUNT_STATUS_TRIAL_EXPIRED;
    },
    banner() {
      return this.distributor.banner;
    },
    css() {
      return `:root {
        --banner-primary-color: ${this.banner.primaryColor};
        --banner-secondary-color: ${this.banner.secondaryColor};
      }`;
    },
    ordersRoutes() {
      return ['distributor-home', 'distributor-orders-all', 'distributor-credit-notes'];
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributor', 'managerLogout']),
    onBannerCTAClick() {
      if (this.banner.path.startsWith('https')) {
        window.open(this.banner.path, '_blank');
      } else {
        this.$router.push(this.banner.path);
      }
    },
    onLogoutClick() {
      this.managerLogout()
        .finally(() => {
          localStorage.clear();
          resetState();
          window.Intercom('shutdown');
          this.$router.replace({ name: 'platform-login' });
        });
    },
  },
  mounted() {
    const isOrderRoute = this.$route.matched.some(({ name }) => this.ordersRoutes.includes(name));
    this.initDistributor({ includeVenues: !isOrderRoute });
  },
};
</script>

<style lang="scss" scoped>
:deep() div.alert.alert--banner.distributor-banner {
  margin-top: 0;
  margin-bottom: 0;
  background-color: var(--banner-secondary-color);
  color: var(--banner-primary-color);

  &__message,
  .button,
  svg {
    color: var(--banner-primary-color);
  }

  .button {
    background-color: $color-white;
  }
  .banner__link {
    background: transparent;
    color: var(--banner-primary-color);
    font-weight: 500;
    text-decoration: none;
  }
  .sign-out {
    position: absolute;
    right: 0;
  }
}

:deep() .layout-tab__navigation ul li a.router-link-active.not-active {
  color: $color-gray-6C;
  border-bottom-color: transparent;
}
</style>
