export const EXPENSE_STEP_1 = 0;
export const EXPENSE_STEP_2 = 1;
export const EXPENSE_STEP_3 = 2;

const steps = {
  [EXPENSE_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/expense-tracking/new-expense/Info'),
    title: 'Add expense info',
    pageTitle: 'Please add expense info',
    pageInfo: 'Select the type of the expense and when it took place.',
    nextCta: 'Next Step',
  },
  [EXPENSE_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/expense-tracking/new-expense/Details'),
    title: 'Expense details',
    pageTitle: 'Now add expense details',
    pageInfo: ' Add the amount you spent and if the tax is included. Additionally, you can add receipts.',
    nextCta: 'Go to Overview',
  },
  [EXPENSE_STEP_3]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/expense-tracking/new-expense/Overview'),
    title: 'Overview',
    pageTitle: 'Expense Overview',
    pageInfo: 'Before you add this expense, check the information below to make sure you’ve entered everything correctly.',
    nextCta: 'Add Expense',
  },
};

export default steps;
