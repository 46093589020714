import i18n from '@/i18n';

export const VENUE_STEP_1 = 0;
export const VENUE_STEP_2 = 1;

const steps = {
  [VENUE_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/connect-to-outlet/Products'),
    title: `Connect to ${i18n.t('global.venue')} - Products`,
    pageTitle: 'And now select products',
    pageInfo: `Select products that will be available to this ${i18n.t('global.venue')}.`,
    nextCta: 'Go to Overview',
  },
  [VENUE_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/connect-to-outlet/Overview'),
    title: `Connect to ${i18n.t('global.venue')} - Overview`,
    pageTitle: 'Overview',
    pageInfo: 'Before you send the onboarding link, check the information below to make sure you’ve entered everything correctly.',
    nextCta: `Connect to ${i18n.t('global.venue')}`,
  },
};

export default steps;
