<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { clone, debounce, getDateFormat } from '@/util/utils';
import {
  LOADING_KEY,
  UNIT_TYPE_FRACTIONAL,
  UNIT_TYPE_PACK,
  DEFAULT_FRACTION_DIGITS,
} from '@/util/constants';

import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzFilterList from '@/components/ui/FilterList';
import EzMaskInput from '@/components/ui/MaskInput';
import EzDateInput from '@/components/ui/DateInput';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzButton from '@/components/ui/Button';
import EzBadge from '@/components/ui/Badge';
import EzTable from '@/components/ui/Table';
import EzInput from '@/components/ui/Input';
import flash from '@/components/ui/FlashMessage';
import VTooltipComponent from '@/components/v3/elements/VTooltipComponent';
import VPrice from '@/components/v3/elements/VPrice';
import { Form as ProductForm } from '@/views/common/products';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';

/**
 * Products
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Products',
  components: {
    VDataWithInfo,
    EzMaskInputSimple,
    EzEntityInfo,
    EzFilterList,
    EzMaskInput,
    EzDateInput,
    EzFormModal,
    EzLoadMore,
    EzSpinner,
    EzButton,
    EzTable,
    EzInput,
    EzBadge,
    // eslint-disable-next-line vue/no-unused-components
    VTooltipComponent,
    VPrice,
    ProductForm,
  },
  props: {
    distributorId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      supplierCy,
      modalActionDisabled: true,
      isInvalidQuantity: {},
      isInvalidExpiryDate: {},
      expiryDates: {},
      editedCogs: {},
      items: [],
      filters: {
        term: null,
      },
      packUnitType: UNIT_TYPE_PACK,
      products: [],
      meta: {},
      DEFAULT_FRACTION_DIGITS,
      getDateFormat,
    };
  },
  computed: {
    ...mapState('entities/products', ['inventoryManagement']),
    ...mapGetters('loading', ['getLoading']),
    loading() {
      return this.getLoading(LOADING_KEY.FETCH_DISTRIBUTOR_PRODUCTS);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.LOAD_MORE_PRODUCTS);
    },
    batchCode() {
      return this.inventoryManagement.batchCode;
    },
    expiryDate() {
      return this.inventoryManagement.expiryDate;
    },
    nextStepDisabled() {
      const hasInvalidQuantities = Object.values(this.isInvalidQuantity).some(e => e);
      const hasInvalidExpiryDates = Object.values(this.isInvalidExpiryDate).some(e => e);
      return hasInvalidQuantities || hasInvalidExpiryDates || !this.items.length;
    },
    today() {
      return dayjs().startOf('day').add(1, 'day');
    },
  },
  methods: {
    ...mapActions('entities/products', ['fetchDistributorProducts']),
    ...mapMutations('entities/products', ['UPDATE_INVENTORY_MANAGEMENT_DRAFT']),
    isFractional(row) {
      return row.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    onInputChange(key, row, val) {
      if (key === 'quantity') this.updateSelectedProductQuantity(row, val);
      else this.updateSelectedProduct(row.id, { [key]: val });

      if (key === 'expiryDate') this.expiryDates[row.id] = val;
      if (key === 'cogs') this.editedCogs[row.id] = val;
    },
    onInvalidExpiryDate(row, val) {
      this.isInvalidExpiryDate = {
        ...this.isInvalidExpiryDate,
        [row.id]: val,
      };
    },
    onInvalidQuantity(row, val) {
      this.isInvalidQuantity = {
        ...this.isInvalidQuantity,
        [row.id]: val,
      };

      if (!this.isSelected(row.id)) {
        this.selectProduct(row);
      }
    },
    updateSelectedProductQuantity(row, val) {
      if (!this.isSelected(row.id)) {
        if (val) this.selectProduct(row, val);
        return;
      }

      if (!val) {
        this.unselectProduct(row.id);
        return;
      }

      this.updateSelectedProduct(row.id, { quantity: val });
    },
    isSelected(id) {
      return !!this.findSelected(id);
    },
    findSelected(id, key = '') {
      const product = this.items.find(p => p.product.id === id);
      if (!key) return product;

      return product ? product[key] : this[key];
    },
    selectProduct(product, quantity = 0) {
      if (this.isSelected(product.id)) return;

      this.items.push({
        product,
        batchCode: this.batchCode,
        expiryDate: this.expiryDate,
        quantity,
        cogs: product.cogs,
      });
    },
    unselectProduct(id) {
      const idx = this.items.findIndex(p => p.product.id === id);
      this.items.splice(idx, 1);
    },
    updateSelectedProduct(id, payload) {
      const idx = this.items.findIndex(p => p.product.id === id);
      this.items[idx] = { ...this.items[idx], ...payload };
    },
    openProductModal() {
      this.$refs.productModal.open();
    },
    closeProductModal() {
      this.$refs.productModal.close();
    },
    submitModalForm() {
      this.$refs.modalForm.submit();
    },
    onModalFormValid(val) {
      this.modalActionDisabled = !val;
    },
    async onModalSuccess() {
      this.closeProductModal();
      flash.success({ title: 'Product successfully added' });
      await this.refresh();
    },
    async resetFilters() {
      this.filters.term = null;
      await this.refresh();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') this.filters.term = event;
      await this.refresh();
    }, 300),
    onNextStep() {
      this.UPDATE_INVENTORY_MANAGEMENT_DRAFT({ items: clone(this.items) });
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    async onLoadMore() {
      await this.fetchProducts(
        { filters: this.filters, sortBy: 'name' },
        LOADING_KEY.LOAD_MORE_PRODUCTS,
      );
    },
    async fetchProducts(query = {}, loadingKey = LOADING_KEY.FETCH_DISTRIBUTOR_PRODUCTS) {
      const { data } = await this.fetchDistributorProducts({
        ...(this.meta.nextId && { nextId: this.meta.nextId }),
        ...(this.meta.nextValue && { nextValue: this.meta.nextValue }),
        ...query,
        loadingKey,
      });
      if (loadingKey === LOADING_KEY.FETCH_DISTRIBUTOR_PRODUCTS) {
        this.products = data.data;
      } else {
        this.products = [...this.products, ...data.data];
      }
      this.meta = data.meta;
    },
    async refresh() {
      this.products = [];
      this.meta = {};
      await this.fetchProducts({ filters: this.filters, sortBy: 'name' });
    },
    quantityHeader(h) {
      return h('span', {}, [
        'Addition Quantity',
        h(
          VTooltipComponent,
          {
            props: {
              content:
                'The quantity is being calculated per<br />single unit. Example: Bottle, can, piece...',
              placement: 'top',
              classes: ['tooltip--reset-margin u-text-center'],
            },
          },
          [
            h('font-awesome-icon', {
              class: 'ml-4',
              props: {
                icon: 'info-circle',
              },
            }),
          ],
        ),
      ]);
    },
  },
  watch: {
    nextStepDisabled: {
      immediate: true,
      handler(val) {
        if (val) this.disableNextStep();
        else this.enableNextStep();
      },
    },
    products: {
      immediate: true,
      handler(val) {
        this.expiryDates = val.reduce(
          (a, c) => ({ ...a, ...{ [c.id]: this.expiryDates[c.id] || this.expiryDate } }),
          {},
        );
      },
    },
  },
  async created() {
    await this.refresh();
    if (this.inventoryManagement.items) {
      this.items = clone(this.inventoryManagement.items);
    }
  },
};
</script>

<template>
  <div class="products-step">
    <div class="subheader mb-24 u-flex-space">
      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <ez-input
          formKey="filters"
          label="search"
          name="search"
          class="search"
          placeholder="Search for a Product"
          :data-cy="supplierCy.INVENTORY_FLOWS.ADDITION.PRODUCTS.INPUT__SEARCH"
        >
          <template #suffix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>

      <p class="subheader__selected-count">
        Products selected:
        <ez-badge type="blue" :count="items.length || 0" showEveryNumber />
      </p>

      <div class="subheader__add-new">
        <ez-button
          type="primary"
          @click="openProductModal"
          :data-cy="supplierCy.INVENTORY_FLOWS.ADDITION.PRODUCTS.BUTTON__ADD_NEW_PRODUCT"
        >
          Create New Product
        </ez-button>
      </div>
    </div>

    <ez-table
      class="products-table"
      disableHover
      :loading="loading"
      :data="products"
      :columns="['name', 'batchCode', 'expiryDate', 'unit', 'quantity', 'cogs', 'action']"
      :headers="{
        name: () => 'Product',
        batchCode: () => 'Batch ID',
        quantity: quantityHeader,
        cogs: () => 'COGS',
        action: () => '',
      }"
      :columnProps="{
        name: { class: 'name-cell' },
        batchCode: { class: 'batchCode-cell' },
        expiryDate: { class: 'expiryDate-cell' },
        unit: { class: 'unit-cell' },
        quantity: { class: 'quantity-cell' },
        cogs: { class: 'u-text-right cogs-cell' },
        action: { class: 'action-cell' },
      }"
    >
      <template #cell-name="{ row }">
        <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
          <div class="product-info" :title="row.name">
            <span>{{ row.name }}</span>
            <span class="product-info-secondary">{{ row.sku }}</span>
          </div>
        </ez-entity-info>
      </template>

      <template #cell-batchCode="{ row }">
        <ez-input
          v-if="isSelected(row.id)"
          formKey=""
          name="batchCode"
          placeholder="Enter Batch ID"
          :value="findSelected(row.id, 'batchCode')"
          @onChange="onInputChange('batchCode', row, $event)"
          :data-cy="`${supplierCy.INVENTORY_FLOWS.ADDITION.PRODUCTS.INPUT__BATCH_CODE}-${row.id}`"
        />
        <template v-else>
          <span v-if="batchCode" :title="batchCode">{{ batchCode }}</span>
          <span v-else>-</span>
        </template>
      </template>

      <template #cell-expiryDate="{ row }">
        <ez-date-input
          form-key=""
          label=""
          name="expiryDate"
          :placeholder="getDateFormat()"
          :date-format="getDateFormat()"
          :disabled="!isSelected(row.id)"
          :class="['date-input', { 'empty-date': !findSelected(row.id, 'expiryDate') }]"
          :min="today"
          v-model="expiryDates[row.id]"
          @onChange="onInputChange('expiryDate', row, $event)"
          @invalid="onInvalidExpiryDate(row, $event)"
          is-tooltip-error
          :data-cy="`${supplierCy.INVENTORY_FLOWS.ADDITION.PRODUCTS.INPUT__DATE_PICKER}-${row.id}`"
        />
      </template>

      <template #cell-unit="{ row: { orderingUnit } }">
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="
              orderingUnit.type === packUnitType
                ? 'The quantity of pack unit products ' + 'is being managed per single unit.'
                : ''
            "
            :show-underline="orderingUnit.type === packUnitType"
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>

      <template #cell-quantity="{ row }">
        <ez-mask-input-simple
          formKey=""
          name="quantity"
          :precision="isFractional(row) ? 2 : 0"
          :value="findSelected(row.id, 'quantity')"
          :minValue="0"
          :isInvalid="isInvalidQuantity[row.id]"
          @input="onInputChange('quantity', row, $event)"
          @invalid="onInvalidQuantity(row, $event)"
          @click.stop
          :data-cy="`${supplierCy.INVENTORY_FLOWS.ADDITION.PRODUCTS.INPUT__QUANTITY}-${row.id}`"
          :has-currency="false"
        />
      </template>

      <template #cell-cogs="{ row }">
        <ez-mask-input
          v-if="isSelected(row.id)"
          formKey=""
          name="cogs"
          type="inline"
          :value="editedCogs[row.id] || findSelected(row.id, 'cogs') || 0"
          @input="onInputChange('cogs', row, $event)"
          :currency="row.currency"
          :allow-negative-value="false"
          :precision="row.currency?.fractionDigits ?? DEFAULT_FRACTION_DIGITS"
          :price-prefix="row.currency?.showSymbol ?? true"
          :data-cy="`${supplierCy.INVENTORY_FLOWS.ADDITION.PRODUCTS.INPUT__COGS}-${row.id}`"
        />
        <v-price v-else :price="editedCogs[row.id] || row.cogs || 0" />
      </template>

      <template #cell-action="{ row }">
        <ez-button
          v-if="isSelected(row.id)"
          type="green"
          @click="unselectProduct(row.id)"
          :data-cy="`${supplierCy.INVENTORY_FLOWS.ADDITION.PRODUCTS.BUTTON__SELECTED}-${row.id}`"
        >
          Selected
        </ez-button>
        <ez-button
          v-else
          type="secondary"
          @click="selectProduct(row, 1)"
          :data-cy="`${supplierCy.INVENTORY_FLOWS.ADDITION.PRODUCTS.BUTTON__SELECT}-${row.id}`"
        >
          Select
        </ez-button>
      </template>
    </ez-table>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="onLoadMore" />

    <ez-form-modal ref="productModal">
      <template #title>Create New Product</template>
      <template #content>
        <product-form
          ref="modalForm"
          :distributorId="distributorId"
          @submitSuccess="onModalSuccess"
          @formValid="onModalFormValid"
          :show-cogs="false"
        />
      </template>
      <template #footer>
        <ez-button
          @click="closeProductModal"
          type="link"
          :data-cy="supplierCy.INVENTORY_FLOWS.ADDITION.PRODUCTS.ADD_NEW_PRODUCT.BUTTON__CANCEL"
        >
          Cancel
        </ez-button>
        <ez-button
          :disabled="modalActionDisabled"
          @click="submitModalForm"
          :data-cy="
            supplierCy.INVENTORY_FLOWS.ADDITION.PRODUCTS.ADD_NEW_PRODUCT.BUTTON__ADD_NEW_PRODUCT
          "
        >
          Add New Product
        </ez-button>
      </template>
    </ez-form-modal>
  </div>
</template>

<style lang="scss" scoped>
.products-step {
  margin-top: 64px;
}

.subheader {
  border-bottom: 1px solid #eceef5;
  padding: 14px 0;

  & > * {
    flex: 1 0 33.33333%;
  }

  &__selected-count {
    @include font-size(14px, 20px);
    color: $color-gray-6C;
    text-align: center;
    margin: 0;
  }

  &__add-new {
    display: flex;
    justify-content: flex-end;
  }
}

.products-table {
  .name-cell {
    .product-info {
      display: flex;
      flex-direction: column;

      .product-info-secondary {
        display: block;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .batchCode-cell {
    width: 120px + $spacing-32;
  }

  .expiryDate-cell {
    width: 132px + $spacing-32;
    overflow: visible;

    .date-input {
      &.empty-date :deep() .placeholder {
        color: $color-gray-6C;
      }

      :deep() .placeholder {
        color: $color-gray-25;
      }

      :deep() .mask-input__input {
        font-weight: normal;
      }

      :deep() .placeholder {
        min-width: fit-content;
        max-width: 100%;
      }

      // Overriding overlapping styles inherited from 'ez-table'
      :deep() .vcalendar__table tr {
        &:hover {
          background-color: unset;
        }

        td,
        th {
          padding: 0;
          border: unset;
          background-color: unset;
          text-align: center;
        }

        th {
          @include font-size(11px, 13px);
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      :deep() .ez-simple-dropdown__dropdown {
        overflow: visible;
      }
    }
  }

  .unit-cell {
    width: 88px + $spacing-32;
    overflow: hidden;
  }

  .quantity-cell {
    width: 128px + $spacing-32;
    overflow: visible;
  }

  .cogs-cell {
    width: 96px + $spacing-32;

    :deep() {
      .mask-input {
        margin-left: auto;

        &__prefix {
          color: $color-gray-6C;
        }
      }

      .v-price__price {
        @include font-size(14px, 20px);
        color: $color-gray-6C;
        font-weight: 400;
      }
    }
  }

  .action-cell {
    width: 112px;
  }
}

:deep() .mask-input.mark-input--inline {
  .mask-input__input {
    font-weight: normal;
    color: $color-gray-25;
    text-align: center;
  }
}
</style>
