<script>
/**
 * Show info about a product
 * @version 1.0.0
 * @since
 */

import VLabel from '@/components/v3/elements/VLabel';

export default {
  components: {
    VLabel,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    productImage() {
      return {
        backgroundImage: `url('${this.product.image}')`,
      };
    },
  },
};
</script>
<template>
  <div class="p-product-info">
    <header class="p-product-info__header mb-16">
      <div class="p-product-info__image" :style="productImage"/>
      <div>
        <div class="p-product-info__name">{{ product.name }}</div>
        <div class="p-product-info__price">{{ product.price | price }}</div>
      </div>
    </header>
    <div class="p-product-info__content">
      <div v-if="product.sku" class="product__info mb-16">
        <VLabel as="small">{{ $t('product.skuNumberLabel') }}</VLabel>
        <span>{{ product.sku }}</span>
      </div>

      <div v-if="product.description" class="product__info">
        <VLabel as="small">{{ $t('product.descriptionLabel') }}</VLabel>
        <span>{{ product.description }}</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
$preview-bg-color: #F1F4F7;
$preview-border-color: #DEE1E4;

.p-product-info {
  &__header {
    @extend %flex-center;
  }
  &__name {
    font-size: 24px;
    font-weight: 600;
  }
  &__price {
    font-weight: bold;
    color: $color-gray-6C;
  }
  &__image {
    margin-right: 12px;
    flex-shrink: 0;
    background-color: $preview-bg-color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 64px;
    width: 64px;
    border: 1px solid $preview-border-color;
    border-radius: 6px;
  }
}
</style>
