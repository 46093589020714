<template>
  <ez-user-list
    :venue="venue"
    :users="users"
    :columns="['name', 'email', 'phone', 'type']"
    :headers="{
      accountName: () => `${$t('global.venue')}`,
      phone: () => 'Phone Number',
      type: () => 'Contact Type',
    }"
    @addUser="onAddUser"
    @userClick="onUserClick"
    @removeUser="onRemoveUser">
    <user-modal
      ref="userModal"
      entity="Contact"
      :formKey="formKey"
      :formAction="formAction"
      :user="user"
      :types="types"
      @success="onSubmitSuccess"
      :has-at-least-one-default-primary="hasAtLeastOneDefaultPrimary"
    />
    <template #cta>Add Contact</template>
  </ez-user-list>
</template>

<script>
/**
   * VenueUsers
   * @version 1.0.0
   * @since 2.3.0
   */

import { mapActions } from 'vuex';
import EzUserList from '@/views/common/users';
import Venue from '@/models/Venue';
import flash from '@/components/ui/FlashMessage';
import { capitalizeEveryWord, falsy } from '@/util/utils';
import UserModal from '@/views/platform/distributor/outlets/UserModal';

export default {
  components: {
    EzUserList,
    UserModal,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'venue-group-user',
      selectedUserId: null,
      roles: [],
      users: [],
      user: {},
      types: [],
      hasAtLeastOneDefaultPrimary: false,
    };
  },
  computed: {
    formAction() {
      return `/distributor/venues/${this.venueId}/users`;
    },
    isChecked() {
      return this.user?.primary;
    },
    venue() { return Venue.find(this.venueId) || {}; },
    isVenueEditable() { return this.venue.editable; },
    isIntegrationOutlet() {
      return !falsy(this.venue.provider);
    },
  },
  methods: {
    openModal() {
      this.$refs.userModal.open();
    },
    closeModal() {
      this.$refs.userModal.close();
    },
    onAddUser() {
      this.user = {};
      this.openModal();
    },
    async getUsers() {
      const { data: { data } } = await this.supplierFetchVenueUsers({
        venueId: this.venueId,
      });
      this.users = data;
      this.hasAtLeastOneDefaultPrimary = !!this.users.find(usr => usr.primaryByDefault);
    },
    async getTypes() {
      const { data: { data } } = await this.supplierFetchVenueUsersTypes();
      this.types = data.map(type => ({
        name: capitalizeEveryWord({
          string: type,
        }),
        id: type,
      }));
    },
    async onUserClick({ id }) {
      const { data: { data } } = await this.supplierFetchVenueUser({
        venueId: this.venueId,
        userId: id,
      });
      this.user = data;
      this.openModal();
    },
    async onSubmitSuccess({ isUpdate }) {
      await this.getUsers();
      flash.success({ title: `User successfully ${isUpdate ? 'updated' : 'added'}.` });
      this.closeModal();
    },
    async onRemoveUser({ id }) {
      await this.supplierDeleteVenueUser({
        venueId: this.venueId,
        userId: id,
      });
      await this.getUsers();
    },
    ...mapActions('entities/users', [
      'supplierFetchVenueUsers',
      'supplierFetchVenueUser',
      'supplierDeleteVenueUser',
      'supplierFetchVenueUsersTypes',
    ]),
  },
  created() {
    if (this.isIntegrationOutlet) {
      this.$router.push({ name: 'distributor-outlets-sales-settings' });
      return;
    }
    this.getUsers();
    this.getTypes();
  },
};
</script>
