<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { getCurrency } from '@/util/utils';

import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput.vue';
import EzTextarea from '@/components/ui/Textarea/EzTextarea.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';

/**
 * EditNonStockCreditModal
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'EditNonStockCreditModal',
  props: {
    nonStockCredit: {
      type: Number,
      required: false,
      default: 0,
    },
    note: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    EzFormModal,
    EzMaskInput,
    EzTextarea,
    EzButton,
  },
  data() {
    return {
      values: { nonStockCredit: 0, note: '' },
      editMode: true,
      getCurrency,
      supplierCy,
    };
  },
  computed: {
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return symbol;
    },
  },
  methods: {
    open({ editMode = true } = {}) {
      this.editMode = editMode;
      this.values = {
        nonStockCredit: this.nonStockCredit || 0,
        note: this.note || '',
      };
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
      this.values = { nonStockCredit: 0, note: '' };
    },
    onCancel() {
      this.$emit('cancel');
      this.close();
    },
    onSave() {
      this.$emit('save', this.values);
      this.close();
    },
    onChange(key, value) {
      this.values[key] = value;
    },
  },
};
</script>

<template>
  <ez-form-modal ref="modal" class="non-stock-credit-modal">
    <template #title>{{ editMode ? 'Edit' : 'Add' }} Non-Stock Credit</template>

    <template #content>
      <ez-mask-input
        type="input"
        name="nonStockCredit"
        label="Non-stock credit"
        :value="values.nonStockCredit"
        :pricePrefix="false"
        :allowNegativeValue="false"
        @input="onChange('nonStockCredit', $event)"
        class="mask-input mb-12"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_NON_STOCK_CREDIT_MODAL
            .INPUT__NON_STOCK_VALUE
        "
      >
        <template #suffix>{{ currencySymbol }}</template>
      </ez-mask-input>
      <ez-textarea
        formKey=""
        name="note"
        label="Note"
        placeholder="Leave a Note"
        :value="values.note"
        @onChange="onChange('note', $event)"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_NON_STOCK_CREDIT_MODAL
            .TEXTAREA__NOTE
        "
      />
    </template>

    <template #footer>
      <ez-button
        type="link"
        @click="onCancel"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_NON_STOCK_CREDIT_MODAL
            .BUTTON__CANCEL
        "
      >
        Cancel
      </ez-button>
      <ez-button
        type="primary"
        @click="onSave"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.EDIT_NON_STOCK_CREDIT_MODAL.BUTTON__SAVE
        "
      >
        {{ editMode ? 'Save Changes' : 'Add Non-stock Credit' }}
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.non-stock-credit-modal {
  .mask-input {
    :deep() {
      .label {
        font-weight: 500;
      }

      .mask-input {
        &__input {
          font-weight: normal;
          padding-left: 10px;
        }

        &__suffix {
          right: unset;
          left: $spacing-08;
        }
      }
    }
  }
}
</style>
