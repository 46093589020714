<script>
import { mapActions, mapGetters } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import uuid from 'uuid/v4';
import Product from '@/models/Product';
import { clone } from '@/util/utils';
import { LOADING_KEY, UNIT_TYPE_FRACTIONAL } from '@/util/constants';

import EzAlert from '@/components/ui/Alert';
import EzButton from '@/components/ui/Button';
import EzLoader from '@/components/ui/Loader';
import EzMaskInput from '@/components/ui/MaskInput';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

/**
 * InventoryParLevel
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'InventoryParLevel',
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  components: {
    EzAlert,
    EzButton,
    EzLoader,
    EzMaskInput,
    VSelectSearch,
  },
  data() {
    return {
      product: {},
      warehouses: [],
      initialSelectedWarehouses: [],
      selectedWarehouses: [],
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_WAREHOUSES);
    },
    isSaving() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT);
    },
    hasMadeChanges() {
      const initial = JSON.stringify(this.initialSelectedWarehouses);
      const current = JSON.stringify(this.selectedWarehouses);
      return initial !== current;
    },
    hasInvalidWarehouses() {
      return !!this.selectedWarehouses.find(w => w.id.toString().includes('empty') || !w.parLevel);
    },
    addParLevelDisabled() {
      return this.hasInvalidWarehouses
        ? true
        : this.warehouses.length === this.selectedWarehouses.length;
    },
    saveChangesDisabled() {
      return !this.hasMadeChanges || this.hasInvalidWarehouses;
    },
    isFractional() {
      return this.product?.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    inventoryTracked() {
      return this.product.inventoryTracked;
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    ...mapActions('entities/products', ['distributorUpdateProduct']),
    addParLevel() {
      this.selectedWarehouses = [
        ...this.selectedWarehouses,
        { id: `empty-${uuid()}`, parLevel: 0, idOld: false },
      ];
    },
    selectWarehouse(selected, idx) {
      let updated = { id: `empty-${uuid()}`, parLevel: 0 };

      if (!selected.reset) {
        updated = {
          ...this.selectedWarehouses[idx],
          id: selected.id,
          name: selected.name,
        };
      }

      this.selectedWarehouses.splice(idx, 1, updated);
    },
    updateParLevel(id, val) {
      const idx = this.selectedWarehouses.findIndex(w => w.id === id);
      this.selectedWarehouses[idx].parLevel = val;
    },
    removeWarehouse(id) {
      const idx = this.selectedWarehouses.findIndex(w => w.id === id);
      this.selectedWarehouses.splice(idx, 1);
    },
    findSelected(id) {
      return this.warehouses.find(w => w.id === id);
    },
    transformer(data) {
      return data.filter(({ id }) => !this.selectedWarehouses.map(w => w.id).includes(id));
    },
    getValidWarehousesFrom(warehouses) {
      return warehouses.filter(w => !w.id.toString().includes('empty') && !!w.parLevel);
    },
    async initProduct() {
      await this.$nextTick();
      this.product = clone(Product.find(this.productId));
    },
    async initSelectedWarehouses() {
      await this.$nextTick();
      this.selectedWarehouses = this.product.warehouseProducts.map(w => ({
        id: w.warehouseId,
        name: this.findSelected(w.warehouseId).name,
        parLevel: w.parLevel,
        isOld: true,
      }));

      await this.$nextTick();
      this.initialSelectedWarehouses = clone(this.selectedWarehouses);
    },
    async fetchWarehouses() {
      const { data } = await this.distributorFetchWarehouses();
      const { data: warehouses } = data;
      this.warehouses = warehouses;
    },
    async updateProduct() {
      const warehouses = this.getValidWarehousesFrom(this.selectedWarehouses);
      const warehouseProducts = warehouses.map(w => ({ warehouseId: w.id, parLevel: w.parLevel }));

      const { data } = await this.distributorUpdateProduct({
        productId: this.product.id,
        data: { warehouseProducts },
      });

      Product.insertOrUpdate({ data: data.data });

      await this.initProduct();
      await this.initSelectedWarehouses();
    },
  },
  async created() {
    await Promise.all([this.fetchWarehouses(), this.initProduct()]);
    await this.initSelectedWarehouses();
  },
};
</script>

<template>
  <div class="par-level container--small">
    <div class="help-block">
      <h4 class="help-block__title custom-title">Par Level Details</h4>
      <p class="help-block__text">
        Set or edit the par level (minimum level of inventory) for each of the warehouses down
        below.
      </p>
    </div>

    <ez-alert v-if="!warehouses.length" type="yellow" variant="disclaimer" size="big">
      <template #icon>
        <font-awesome-icon icon="info-circle" />
      </template>
      <template>You need to have at least one warehouse created to set the par levels</template>
    </ez-alert>

    <ez-alert v-else-if="!inventoryTracked" type="yellow" variant="disclaimer" size="big">
      <template #icon>
        <font-awesome-icon icon="info-circle" />
      </template>
      <template>
        You need to have inventory tracking set to ON in order to set the par levels
      </template>
    </ez-alert>

    <div class="warehouses" v-else>
      <div v-for="(wh, idx) in selectedWarehouses" :key="idx" class="warehouses__warehouse">
        <ez-button
          type="link"
          class="warehouses__warehouse__remove"
          @click="removeWarehouse(wh.id)"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_PAR_LEVEL.BUTTON__REMOVE_PAR_LEVEL}-${wh.id}`"
        >
          <font-awesome-icon icon="times" /> Remove
        </ez-button>

        <div class="warehouses__warehouse__existing" v-if="wh.isOld">
          <span class="warehouses__warehouse__existing__label">Warehouse Name</span>
          <span
            class="warehouses__warehouse__existing__name"
            :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_PAR_LEVEL.TEXT__WAREHOUSE_NAME}-${wh.id}`"
          >
            {{ findSelected(wh.id).name }}
          </span>
        </div>

        <v-select-search
          v-else-if="warehouses.length"
          label="Warehouse Name"
          placeholder="Select Warehouse"
          class="warehouses__warehouse__select"
          :data="warehouses"
          :selected="findSelected(wh.id)"
          :transformer="transformer"
          @selected="obj => selectWarehouse(obj, idx)"
          is-full-width
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_PAR_LEVEL.BUTTON__SELECT_WAREHOUSE}-${wh.id}`"
        />
        <ez-mask-input
          form-key=""
          type="input"
          name="parLevel"
          label="Par Level"
          class="warehouses__warehouse__par-level mt-16"
          :precision="isFractional ? 2 : 0"
          :value="wh.parLevel"
          :allow-negative-value="false"
          :price-prefix="false"
          @input="updateParLevel(wh.id, $event)"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_PAR_LEVEL.INPUT__PAR_LEVEL}-${wh.id}`"
        />
      </div>
      <ez-button
        type="secondary"
        class="add-par-level my-24"
        :disabled="addParLevelDisabled"
        @click="addParLevel"
        isFullWidth
        :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_PAR_LEVEL.BUTTON__ADD_PAR_LEVEL"
      >
        <font-awesome-icon icon="plus" />
        Add Par Level
      </ez-button>
    </div>
    <ez-button
      :disabled="saveChangesDisabled"
      @click="updateProduct"
      :isLoading="isSaving"
      :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_PAR_LEVEL.BUTTON__SAVE_CHANGES"
    >
      Save Changes
    </ez-button>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped>
.par-level {
  .help-block {
    &__title {
      &.custom-title {
        font-weight: 600;
      }
    }

    &__text {
      @include font-size(14px, 20px);
    }
  }

  .warehouses {
    &__warehouse {
      position: relative;
      padding-top: 24px;

      &:not(:first-child) {
        margin-top: 24px;
        border-top: 1px dashed #dee1e4;
      }

      &__existing {
        &__label {
          display: block;
          @include font-size(12px, 18px);
          font-weight: 600;
          color: $color-gray-6C;
        }

        &__name {
          display: block;
          @include font-size(14px, 20px);
          font-weight: 500;
        }
      }

      &__select {
        :deep() .select-search {
          &__trigger span {
            @include font-size(14px, 20px);

            &:not(.select-search__value) {
              color: $color-gray-6C;
            }
          }

          &__search-wrapper {
            display: none;
          }

          &__item {
            @include font-size(14px, 20px);
          }
        }
      }

      &__par-level {
        :deep() .mask-input__input {
          font-weight: normal;
        }
      }

      &__remove {
        @include font-size(12px, 18px);
        position: absolute;
        top: 24px;
        right: 0;
        height: auto;
      }
    }

    .add-par-level {
      &:disabled {
        background-color: $color-gray-F5;
        color: transparentize($color-gray-6C, 0.6);
      }
    }
  }
}
</style>
