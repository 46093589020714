import i18n from '@/i18n';

export const NEW_CREDIT_NOTE_STEP_1 = 0;
export const NEW_CREDIT_NOTE_STEP_2 = 1;
export const NEW_CREDIT_NOTE_STEP_3 = 2;

const steps = {
  [NEW_CREDIT_NOTE_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/orders/credit-notes/new/CreditInfo.vue'),
    pageTitle: 'Credit Info',
    pageInfo: `Choose a ${i18n.t('global.venue').toLowerCase()} that is going to receive this credit.`,
    nextCta: 'Go To Overview',
    secondCta: 'Add Stock Credit',
  },
  [NEW_CREDIT_NOTE_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/orders/credit-notes/new/StockCredit.vue'),
    pageTitle: 'Select products',
    pageInfo: 'If the products need to be added back to the inventory, you can choose warehouse on the overview step. If you added non-stock credit already, you can skip this step.',
    nextCta: 'Go To Overview',
  },
  [NEW_CREDIT_NOTE_STEP_3]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/orders/credit-notes/new/Overview.vue'),
    pageTitle: 'Overview',
    pageInfo: 'Before submitting this credit note, check the information below to ensure you\'ve entered everything correctly.',
    nextCta: 'Approve Credit Note',
    secondCta: 'Save Draft',
  },
};

export default steps;
