<script>
/**
 * ButtonSwitch
 *
 * @event accept
 * @event schedule
 * @event complete
 * @event decline
 *
 * @version 1.0.0
 * @since
 */

import ButtonsActions from '@/views/common/orders/orderActions/ButtonsActions';
import { areAllProductsDeclined, areSomeProductsDeclined, isIncluded } from '@/util/utils';

export default {
  components: {
    ButtonsActions,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    editingMode: {
      type: Boolean,
      required: false,
    },
    forceDisable: {
      type: Array, // [{ btnType: 'schedule_delivery', value: true }]
      required: false,
      default: () => [],
    },
  },
  computed: {
    products() {
      return this.order?.orderedProducts || [];
    },
    hasGreaterThenTwoActions() {
      return this.order?.actions?.length > 2;
    },
    areAllIncludedProductsDeclined() {
      const included = this.products.filter(isIncluded);
      return areAllProductsDeclined(included);
    },
    areSomeProductsDeclined() {
      return areSomeProductsDeclined(this.products);
    },
    toDisableSaveChanges() {
      return !this.products.length || this.products.every(p => !p.quantity);
    },
  },
};
</script>
<template>
  <buttons-actions
    @decline="$emit('decline')"
    @accept="$emit('accept')"
    @schedule="$emit('schedule')"
    @complete="$emit('complete')"
    @abandon="$emit('abandon')"
    @resolve="$emit('resolve')"
    @reorder="$emit('reorder')"
    @createFromDraft="$emit('createFromDraft')"
    @edit="$emit('edit')"
    @saveEdit="$emit('saveEdit')"
    @discardEdit="$emit('discardEdit')"
    @addCreditNote="$emit('addCreditNote')"
    @copyToClipboard="$emit('copyToClipboard')"
    @convertToOrder="$emit('convertToOrder')"
    :order="order"
    :editing-mode="editingMode"
    :are-all-products-declined="areAllIncludedProductsDeclined"
    :are-some-products-declined="areSomeProductsDeclined"
    :has-greater-then-two-actions="hasGreaterThenTwoActions"
    :to-disable-save-changes="toDisableSaveChanges"
    :force-disable="forceDisable"
  />
</template>
