<template>
  <ez-wizard
    :extra-wide="[NEW_ORDER_STEP_2, NEW_ORDER_STEP_3].includes(currentStep)"
    @exit="onWizardExit"
    @back="goToPreviousStep"
  >
    <template #title>Add New Order</template>
    <template #nav>
      <ul>
        <li :class="{
          'ez-step-active': currentStep === NEW_ORDER_STEP_1,
          'ez-step-completed': currentStep > NEW_ORDER_STEP_1,
        }">
          1. {{$t('global.venue')}}
        </li>
        <li :class="{
          'ez-step-active': currentStep === NEW_ORDER_STEP_2,
          'ez-step-completed': currentStep > NEW_ORDER_STEP_2,
        }">
          2. Products
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button
        :data-cy="supplierCy.ORDERS.NEW_ORDER.BUTTON__NEXT_STEP"
        :disabled="nextDisabled"
        @click="goToNextStep"
      >
        {{ nextCta }}
      </ez-button>
    </template>
    <template #prevStep>
      <ez-button
        v-if="currentStep > NEW_ORDER_STEP_1"
        type="link"
        formType="button"
        @click="goToPreviousStep"
        :data-cy="supplierCy.ORDERS.NEW_ORDER.BUTTON__PREV_STEP"
      >
        <font-awesome-icon icon="arrow-left"/>
        <span>Back</span>
      </ez-button>
    </template>
    <template #pageTitle>{{ currentStepPageTitle }}</template>
    <template #pageInfo>{{ currentStepPageInfo }}</template>
    <component
      :is="currentStepComponent"
      @stepCompleted="onStepCompleted"
      @stepBack="onStepBack"></component>
  </ez-wizard>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardEmitterMixin } from '@/mixins/wizard';
import EzWizard from '@/components/layout/Wizard.vue';
import EzButton from '@/components/ui/Button';

import steps, {
  NEW_ORDER_STEP_1,
  NEW_ORDER_STEP_2,
  NEW_ORDER_STEP_3,
} from './steps';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzButton,
    EzWizard,
  },
  data() {
    return {
      currentStep: 0,
      NEW_ORDER_STEP_1,
      NEW_ORDER_STEP_2,
      NEW_ORDER_STEP_3,
      nextDisabled: true,
      supplierCy,
    };
  },
  computed: {
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === NEW_ORDER_STEP_1;
    },
    isLastStep() {
      return this.currentStep === NEW_ORDER_STEP_3;
    },
    ...mapState('entities/groups', [
      'draft',
    ]),
  },
  methods: {
    ...mapMutations('entities/orders', [
      'CLEAR_DRAFT',
      'CLEAR_PRODUCTS',
      'CLEAR_SELECTED_PRODUCTS',
      'CLEAR_OUTLET_REFERENCE_NUMBER',
      'CLEAR_NEW_ORDER_AMOUNT',
    ]),
    clearState() {
      this.CLEAR_DRAFT();
      this.CLEAR_PRODUCTS();
      this.CLEAR_SELECTED_PRODUCTS();
      this.CLEAR_OUTLET_REFERENCE_NUMBER();
      this.CLEAR_NEW_ORDER_AMOUNT();
    },
    onWizardExit() {
      this.clearState();
      this.$router.push({ name: 'distributor-home' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= NEW_ORDER_STEP_1 && step <= NEW_ORDER_STEP_3) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= NEW_ORDER_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= NEW_ORDER_STEP_3) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
  beforeDestroy() {
    this.clearState();
  },
};
</script>
