<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';

import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';

import ReturnInventoryToWarehouse from './ReturnInventoryToWarehouse.vue';

/**
 * ApproveModal
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'ApproveModal',
  props: {
    creditNote: {
      type: Object,
      required: true,
    },
  },
  components: {
    EzConfirmationModal,
    EzButton,
    ReturnInventoryToWarehouse,
  },
  data() {
    return {
      warehouse: null,
      supplierCy,
    };
  },
  computed: {
    hasProducts() {
      return !!this.creditNote.items.length;
    },
    allProductsUntracked() {
      return this.creditNote.items.every(i => !i.product.inventoryTracked);
    },
    warehouseDataCy() {
      const { LABEL__RETURN_TO_INVENTORY, BUTTON__WAREHOUSE } = supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.APPROVE_MODAL;
      return { LABEL__RETURN_TO_INVENTORY, BUTTON__WAREHOUSE };
    },
  },
  methods: {
    open() {
      const { warehouse, venue } = this.creditNote;
      const warehouseId = warehouse?.id || venue?.connection?.primaryWarehouseId;

      this.warehouse = this.allProductsUntracked ? null : warehouseId;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onCancel() {
      this.warehouse = null;
      this.close();
      this.$emit('cancel');
    },
    onApprove() {
      this.close();
      this.$emit('approve', this.warehouse);
    },
    onWarehouseChange(warehouse) {
      this.warehouse = warehouse;
    },
  },
};
</script>

<template>
  <ez-confirmation-modal ref="modal" type="blue" icon="question">
    <template #title>Approve Credit Note?</template>
    <template #content>
      Make sure you went through all credit note details.
      <hr />
      <return-inventory-to-warehouse
        v-if="hasProducts"
        :selected="warehouse"
        :toggleDisabled="allProductsUntracked"
        @change="onWarehouseChange"
        :data-cy="warehouseDataCy"
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="onCancel"
        :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.APPROVE_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        type="primary"
        @click="onApprove"
        :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.APPROVE_MODAL.BUTTON__APPROVE"
      >
        Approve Credit Note
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped></style>
