<template>
  <distributor-form
    :formKey="formKey"
    :loading-key="loadingKeyForm"
    :additional-data="{ ...primaryUser }"
    hide-tax-rate-section
    action="/distributor/me"
    method="patch"
    @submitSuccess="onEditFormSubmit"
    :distributor-data="distributor"
    :distributorId="loggedUser.distributor.id"
    @formValid="e => (formValid = e)"
    hide-notification-settings
  >
    <template #primaryContact>
      <div>
        <primary-contact-dropdown
          :primary-selected="primarySelectedUser"
          :options="users"
          class="mt-12"
          label="Primary Contact"
          name="primaryUserId"
          :form-key="formKey"
          @onSelect="primaryContactChanged"
        />
        <div class="contact-info mt-8">
          <p class="contact-info__txt">
            User needs to have a phone number in order to be able to be set as a primary contact.
          </p>
        </div>
      </div>
    </template>
    <template #defaultDriver>
      <ez-dropdown
        :formKey="formKey"
        :data="drivers"
        :selected="defaultDriverId"
        name="defaultDriverId"
        label="Default Driver"
        placeholder="Select Default Driver"
        @change="updateDriver"
        isFullWidth
        class="mt-8"
      />
    </template>
    <template #buttons>
      <div class="buttons">
        <ez-button :disabled="!formValid" form-type="submit">Save Changes</ez-button>
      </div>
    </template>
  </distributor-form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EzButton from '@/components/ui/Button';
import DistributorForm from '@/views/admin/distributors/Form.vue';
import flash from '@/components/ui/FlashMessage';
import User from '@/models/User';
import Distributor from '@/models/Distributor';
import PrimaryContactDropdown from '@/views/common/users/PrimaryContactDropdown';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown.vue';

export default {
  components: {
    EzButton,
    DistributorForm,
    PrimaryContactDropdown,
    EzDropdown,
  },
  data() {
    return {
      formKey: 'distributor-settings',
      loadingKeyForm: 'distributor-settings-lk',
      primaryUser: {
        primaryUserId: '',
      },
      drivers: [],
      defaultDriverId: null,
      formValid: false,
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    users() {
      return User.all();
    },
    distributor() {
      return Distributor.find(this.loggedUser.distributor.id) || {};
    },
    primarySelectedId() {
      return this.distributor?.primaryUserId;
    },
    primarySelectedUser() {
      return User.query().find(this.primarySelectedId) || {};
    },
  },
  methods: {
    ...mapActions('entities/users', ['fetchDistributorUsers']),
    ...mapActions('entities/distributors', ['distributorFetchDrivers']),
    onEditFormSubmit({ data }) {
      Distributor.update({ data: data.data });
      flash.success({
        title: `${this.$t('global.distributor')} settings successfully updated!`,
      });
    },
    primaryContactChanged(ev) {
      this.primaryUser.primaryUserId = ev.id;
    },
    async fetchDrivers() {
      const { data } = await this.distributorFetchDrivers();
      this.drivers = data.data;
    },
    updateDriver(driver) {
      this.defaultDriverId = driver.id;
    },
  },
  async created() {
    await Promise.all([this.fetchDistributorUsers(), this.fetchDrivers()]);
    this.defaultDriverId = this.loggedUser.distributor.defaultDriverId;
  },
};
</script>

<style scoped lang="scss">
.buttons {
  margin-top: 1.5rem;
}
.contact-info {
  &__txt {
    @include font-size(12px);
    color: $color-gray-6C;
    margin: 0;
    line-height: px-to-rem(18px);
  }
}
</style>
