<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import {
  LOADING_KEY,
  INVENTORY_HISTORY_DATE_FILTER_DEFAULT,
  NEW_HISTORY_LOG_TYPE_ORDER_ACCEPT,
  NEW_HISTORY_LOG_TYPE_ORDER_CANCEL,
  NEW_HISTORY_LOG_TYPE_CREDIT_NOTE_APPROVED,
  NEW_HISTORY_LOG_TYPE_TRANSFER,
} from '@/util/constants';
import downloadAttachment from '@/util/downloadAttachment';
import Product from '@/models/Product';

import EzFilterList from '@/components/ui/FilterList';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzSelect from '@/components/ui/Select';
import EzTable from '@/components/ui/Table/EzTable';
import EzOnOff from '@/components/ui/OnOff/EzOnOff';
import EzButton from '@/components/ui/Button';
import HistoryLogType from '@/views/common/products/HistoryLogType';
import VWarehouseLocation from '@/components/v3/elements/VWarehouseLocation';
import VDatePicker from '@/components/v3/patterns/VDatePicker';
import VPrice from '@/components/v3/elements/VPrice';

/**
 * InventoryHistory
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'InventoryHistory',
  components: {
    EzFilterList,
    EzLoadMore,
    EzSpinner,
    EzSelect,
    EzTable,
    EzOnOff,
    EzButton,
    HistoryLogType,
    VWarehouseLocation,
    VDatePicker,
    VPrice,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      inventoryHistoryTypes: {},
      historyLogs: [],
      isTracked: false,
      filters: {
        type: null,
        warehouseId: null,
        from: null,
        to: null,
      },
      date: {
        start: INVENTORY_HISTORY_DATE_FILTER_DEFAULT.from,
        end: INVENTORY_HISTORY_DATE_FILTER_DEFAULT.to,
      },
      meta: {},
      today: new Date(),
      warehouses: [{ id: null, name: 'All Warehouses' }],
      supplierCy,
      NEW_HISTORY_LOG_TYPE_TRANSFER,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    ...mapState('entities/users', ['loggedUser']),
    loading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_HISTORY,
        LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_HISTORY_WAREHOUSES,
        LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_HISTORY_TYPES,
        LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT,
      ]);
    },
    loadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_INVENTORY_HISTORY);
    },
    loadingExport() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_EXPORT_INVENTORY_HISTORY);
    },
    exportDisabled() {
      return !this.historyLogs.length;
    },
    actions() {
      return [
        { id: null, name: 'All Actions' },
        ...Object.entries(this.inventoryHistoryTypes).map(([k, v]) => ({ id: k, name: v })),
      ];
    },
    product() {
      return Product.find(this.productId);
    },
    me() {
      return this.loggedUser.distributor;
    },
    hasEditInventoryItemPermission() {
      return this.$permission.has('editProductInfo');
    },
    toggleTooltip() {
      if (this.hasEditInventoryItemPermission) return undefined;
      return {
        placement: 'top',
        classes: ['tooltip--reset-margin', 'mb-8'],
        content: "You don't have required permission to edit this setting",
      };
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'distributorFetchProductInventoryHistory',
      'distributorFetchInventoryHistoryWarehouses',
      'distributorFetchInventoryHistoryTypes',
      'distributorExportInventoryHistory',
      'distributorExportSingleInventoryHistory',
      'distributorUpdateProduct',
    ]),
    async onTrackingChange(val) {
      await this.updateProduct(val);
    },
    async updateFilters(name, e) {
      if (name === 'date') {
        this.filters = {
          ...this.filters,
          from: e.start.toISOString(),
          to: e.end.toISOString(),
        };
      } else {
        this.filters = { ...this.filters, [name]: e.id };
      }

      this.historyLogs = [];
      this.meta = {};

      await this.$nextTick();
      await Promise.all([
        this.fetchInventoryHistory(this.filters),
        this.fetchInventoryHistoryWarehouses(),
      ]);
    },
    async resetFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });

      this.historyLogs = [];
      this.meta = {};

      await this.$nextTick();
      await Promise.all([
        this.fetchInventoryHistory(this.filters),
        this.fetchInventoryHistoryWarehouses(),
      ]);
    },
    async fetchInventoryHistoryTypes() {
      const { data } = await this.distributorFetchInventoryHistoryTypes({
        productId: this.productId,
      });
      this.inventoryHistoryTypes = data.data;
    },
    async fetchInventoryHistoryWarehouses() {
      const { data } = await this.distributorFetchInventoryHistoryWarehouses({
        productId: this.productId,
      });
      this.warehouses = [{ id: null, name: 'All Warehouses' }, ...data.data];
    },
    async fetchInventoryHistory(
      filters,
      loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCT_INVENTORY_HISTORY,
    ) {
      const { data } = await this.distributorFetchProductInventoryHistory({
        productId: this.productId,
        loadingKey,
        query: {
          limit: 20,
          ...filters,
          ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        },
      });

      this.historyLogs = [...this.historyLogs, ...data.data];
      this.meta = data.meta;
    },
    async updateProduct(inventoryTracked) {
      const { data } = await this.distributorUpdateProduct({
        productId: this.productId,
        data: { inventoryTracked },
      });
      Product.insertOrUpdate({ data: data.data });
    },
    async loadMore() {
      await this.fetchInventoryHistory(
        this.filters,
        LOADING_KEY.DISTRIBUTOR_FETCH_MORE_INVENTORY_HISTORY,
      );
    },
    async exportInventoryHistory() {
      const { data, headers } = await this.distributorExportInventoryHistory({
        productId: this.productId,
        query: { ...this.filters },
      });
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'template.xlsx';
      downloadAttachment(data, name);
    },
    referenceComponent(type) {
      return [
        NEW_HISTORY_LOG_TYPE_ORDER_ACCEPT,
        NEW_HISTORY_LOG_TYPE_ORDER_CANCEL,
        NEW_HISTORY_LOG_TYPE_CREDIT_NOTE_APPROVED,
      ].includes(type) ? 'a' : 'span';
    },
    referenceAttributes(row) {
      if (this.referenceComponent(row.type) === 'span') return {};
      const isCreditNoteAction = [NEW_HISTORY_LOG_TYPE_CREDIT_NOTE_APPROVED].includes(row.type);
      const pathId = row.reference.data[isCreditNoteAction ? 'creditNoteId' : 'orderId'];

      const { href } = this.$router.resolve({
        name: isCreditNoteAction ? 'distributor-credit-notes' : 'distributor-orders-all',
        params: { id: pathId },
      });
      return { href, target: '_blank' };
    },
    async exportTransfer(type, historyId) {
      const { data, headers } = await this.distributorExportSingleInventoryHistory({
        productId: this.productId,
        historyId,
        query: { exportFormat: type },
      });
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : `template.${type}`;
      downloadAttachment(data, name);
    },
  },
  async created() {
    if (!this.me.inventorySettings.tracking) {
      this.$router.replace({ name: 'distributor-product-single' });
    }

    const defaultDateFilters = { from: this.date.start, to: this.date.end };
    await Promise.all([
      this.fetchInventoryHistoryWarehouses(),
      this.fetchInventoryHistoryTypes(),
      this.fetchInventoryHistory(defaultDateFilters),
    ]);
  },
  watch: {
    product: {
      deep: true,
      immediate: true,
      handler(val) {
        this.isTracked = val.inventoryTracked;
      },
    },
  },
};
</script>

<template>
  <div class="inventory">
    <div class="inventory__header">
      <div class="help-block mb-24 container--small">
        <h4 class="help-block__title">Inventory Tracking</h4>
        <p class="help-block__text">
          Keep track of your product’s stock changes here. Each stock change will be shown in the
          history log below. You can turn off tracking for this product.
        </p>
      </div>

      <div class="inventory__header__actions">
        <div class="inventory__header__switch">
          Track {{ $t('global.product') }}
          <ez-on-off
            form-key=""
            class="ml-8"
            name="inventoryTracking"
            v-tooltip="toggleTooltip"
            :disabled="!hasEditInventoryItemPermission"
            :checked="isTracked"
            @change="onTrackingChange"
            :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.LABEL__TRACK_PRODUCT"
          />
        </div>
        <ez-button
          class="inventory__header__export"
          :isLoading="loadingExport"
          :disabled="exportDisabled"
          @click="exportInventoryHistory"
          :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.BUTTON__EXPORT_CSV"
        >
          Export CSV
        </ez-button>
      </div>
    </div>

    <div class="inventory__filters mb-24">
      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <ez-select
          name="type"
          :value="filters.type"
          :options="actions"
          :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.BUTTON__FILTER_ACTIONS"
        />
        <ez-select
          name="warehouseId"
          :value="filters.warehouseId"
          :options="warehouses"
          :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.BUTTON__FILTER_WAREHOUSE"
        />

        <v-date-picker
          formKey=""
          label=""
          name="date"
          v-model="date"
          select-predefined-range="This Month"
          :number-of-calendars="2"
          with-predefined-ranges
          range-mode
          :data-cy="supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.BUTTON__DATE_PICKER"
        />
      </ez-filter-list>
    </div>

    <ez-table
      class="inventory__table"
      :loading="loading"
      :data="historyLogs"
      :columns="[
        'action',
        'type',
        'createdAt',
        'user',
        'location',
        'batchCode',
        'reference',
        'download',
        'cogs',
        'adjustment',
        'inventory',
      ]"
      :columnProps="{
        action: { class: 'extra-small-cell action-cell' },
        type: { class: 'large-cell type-cell' },
        createdAt: { class: 'createdAt-cell' },
        user: { class: '' },
        location: { class: '' },
        batchCode: { class: '' },
        reference: { class: 'reference-cell' },
        cogs: { class: 'small-cell text-right-cell cogs-cell' },
        download: { class: 'download-report-cell' },
        adjustment: { class: 'small-cell text-right-cell adjustment-cell' },
        inventory: { class: 'small-cell' },
      }"
      :headers="{
        type: () => '',
        createdAt: () => 'Date',
        location: () => (filters.warehouseId ? 'Location' : 'Warehouse'),
        batchCode: () => 'Batch ID',
        cogs: () => 'COGS',
        inventory: () => 'Stock',
      }"
    >
      <template #cell-action="{ row }">
        <history-log-type
          :type="row.action"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.ICON__LOG_TYPE}-${row.id}`"
        />
      </template>

      <template #cell-type="{ row, row: { type } }">
        <span
          class="bold-text"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__LOG_TYPE}-${row.id}`"
        >
          {{ inventoryHistoryTypes[type] }}
        </span>
      </template>

      <template #cell-createdAt="{ row, row: { createdAt } }">
        <span
          v-if="createdAt"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__DATE_CREATED}-${row.id}`"
        >
          {{ createdAt | date }}
        </span>
        <span
          v-else
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__DATE_CREATED}-${row.id}`"
        >
          -
        </span>
      </template>

      <template #cell-user="{ row, row: { user } }">
        <span
          class="bold-text"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__USER}-${row.id}`"
        >
          {{ user }}
        </span>
      </template>

      <template #cell-location="{ row, row: { location, warehouse } }">
        <span
          v-if="filters.warehouseId && !location"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__WAREHOUSE}-${row.id}`"
        >
          -
        </span>
        <v-warehouse-location
          v-else
          :data="filters.warehouseId ? [location.code] : [warehouse.name]"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__WAREHOUSE}-${row.id}`"
        />
      </template>

      <template #cell-batchCode="{ row, row: { batchCode } }">
        <span
          :title="batchCode"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__BATCH_CODE}-${row.id}`"
        >
          {{ batchCode || '-' }}
        </span>
      </template>

      <template #cell-reference="{ row, row: { reference } }">
        <component :is="referenceComponent(row.type)" v-bind="referenceAttributes(row)">
          <span
            v-if="!reference.tooltip"
            :title="reference.text"
            :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__REFERENCE}-${row.id}`"
          >
            {{ reference.text || '-' }}
          </span>
          <span
            v-else
            class="dashed-underline"
            v-tooltip="{
              placement: 'top',
              content: reference.tooltip,
              classes: ['tooltip--reset-margin', 'mb-8'],
            }"
            :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__REFERENCE}-${row.id}`"
          >
            {{ reference.text }}
          </span>
        </component>
      </template>

      <template #cell-download="{ row }">
        <div v-if="row.type === NEW_HISTORY_LOG_TYPE_TRANSFER && row.exportEnabled">
          <img @click="exportTransfer('xlsx', row.id)" src="@/assets/icons/ic-csv.svg" alt="CSV Download">
          <img @click="exportTransfer('pdf', row.id)" src="@/assets/icons/ic-pdf.svg" alt="PDF Download">
        </div>
        <span v-else>-</span>
      </template>

      <template #cell-cogs="{ row, row: { cogs } }">
        <v-price
          :price="cogs || 0"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__COGS}-${row.id}`"
        />
      </template>

      <template #cell-adjustment="{ row, row: { adjustment } }">
        <span
          :class="[
            'bold-text',
            'adjustment',
            { 'adjustment--green': adjustment > 0 },
            { 'adjustment--red': adjustment < 0 },
          ]"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__ADJUSTMENT}-${row.id}`"
        >
          {{ adjustment > 0 ? '+' : '' }}{{ adjustment }}
        </span>
      </template>

      <template #cell-inventory="{ row, row: { inventory } }">
        <span
          class="bold-text"
          :data-cy="`${supplierCy.PRODUCTS.SINGLE_PRODUCT.INVENTORY_HISTORY.TEXT__STOCK}-${row.id}`"
        >
          {{ inventory }}
        </span>
      </template>
    </ez-table>

    <ez-load-more v-if="meta.nextId && !loadingMore" @loadMore="loadMore" />

    <div v-if="loadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.inventory {
  display: flex;
  flex-direction: column;

  &__header {
    flex: 1 0 100%;
    display: flex;
    justify-content: space-between;

    .help-block {
      &__text {
        @include font-size(14px, 20px);
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__switch {
      display: flex;
    }

    &__export {
      margin-top: 16px;
    }
  }

  &__filters {
    :deep() .ez-filter-list__items {
      & > *:not(:first-child) {
        margin-left: 16px;
      }
    }
  }

  &__table {
    tbody tr:hover {
      cursor: default;
    }

    .bold-text {
      font-weight: 600;
      color: $color-gray-25;
    }

    .action-cell {
      padding-right: 0;
    }

    .type-cell {
      padding-left: 0;
    }

    .createdAt-cell {
      width: 112px;
    }

    .reference-cell {
      overflow: visible;

      span {
        display: block;
        overflow: hidden;
        width: fit-content;
        max-width: 100%;
        text-overflow: ellipsis;
      }

      .dashed-underline {
        border-bottom: 2px dotted rgba($color-gray-6C, 0.64);
      }

      a {
        text-decoration: none;
        color: $color-gray-6C;
      }
    }

    .cogs-cell {
      &:not(th) :deep() span {
        @include font-size(14px, 20px);
        color: $color-gray-6C;
        font-weight: 400;
      }
    }

    .download-report-cell {
      width: 112px;
      img {
        cursor: pointer;
      }
    }

    .adjustment-cell {
      padding-right: 0;

      .adjustment {
        &--green {
          color: $color-primary-green;
        }

        &--red {
          color: $color-primary-red;
        }
      }
    }
  }
}
</style>
