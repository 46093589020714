<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { clone } from '@/util/utils';
import { wizardListenerMixin } from '@/mixins/wizard';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
/**
 * Warehouse
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Warehouse',
  components: { VSelectSearch },
  data() {
    return {
      supplierCy,
      warehouses: [],
      warehouse: null,
    };
  },
  computed: {
    ...mapState('entities/products', ['inventoryManagement']),
  },
  methods: {
    ...mapMutations('entities/products', ['UPDATE_INVENTORY_MANAGEMENT_DRAFT']),
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    onNextStep() {
      this.UPDATE_INVENTORY_MANAGEMENT_DRAFT({ warehouse: clone(this.warehouse), items: [] });
      this.$emit('stepCompleted');
    },
    transformer(data) {
      return data.filter(w => !w.isEmpty);
    },
  },
  watch: {
    warehouse: {
      immediate: true,
      handler(val) {
        if (!val || !Object.keys(val).length) this.disableNextStep();
        else this.enableNextStep();
      },
    },
  },
  async created() {
    if (this.inventoryManagement.warehouse) {
      this.warehouse = clone(this.inventoryManagement.warehouse);
    }

    const { data } = await this.distributorFetchWarehouses();
    this.warehouses = clone(data.data);
  },
};
</script>

<template>
  <div>
    <v-select-search
      name="warehouse"
      label="Warehouse Name"
      placeholder="Choose Warehouse"
      :hasClear="false"
      :data="warehouses"
      :selected="warehouse"
      :transformer="transformer"
      @selected="warehouse = $event"
      isFullWidth
      :data-cy="supplierCy.INVENTORY_FLOWS.REMOVAL.WAREHOUSE.BUTTON__SELECT"
    />
  </div>
</template>

<style lang="scss" scoped>
:deep() .select-search {
  &__trigger span {
    @include font-size(14px, 20px);

    &:not(.select-search__value) {
      color: $color-gray-6C;
    }
  }

  &__search-wrapper {
    display: none;
  }

  &__item {
    @include font-size(14px, 20px);
  }
}
</style>
