<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';

import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';

/**
 * DeleteModal
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'DeleteModal',
  components: {
    EzConfirmationModal,
    EzButton,
  },
  data() {
    return {
      supplierCy,
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onCancel() {
      this.close();
      this.$emit('cancel');
    },
    onDelete() {
      this.close();
      this.$emit('delete');
    },
  },
};
</script>

<template>
  <ez-confirmation-modal ref="modal" type="red" icon="question">
    <template #title>Delete this Credit Note?</template>
    <template #content>You are about to remove this credit note from your list.</template>
    <template #footer>
      <ez-button
        type="link"
        @click="onCancel"
        :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.DELETE_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        type="red"
        @click="onDelete"
        :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.DELETE_MODAL.BUTTON__DELETE"
      >
        Delete Credit Note
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped></style>
