<script>
/**
   * VPage
   * @version 1.0.0
   * @since
   */

export default {
  name: 'VPage',
};
</script>
<template functional>
  <div class="vpage">
    <div class="vpage__top">
      <header class="vpage__header">
        <h1 class="vpage__title"><slot name="title"/></h1>
        <h3 class="vpage__subtitle"><slot name="subtitle"/></h3>
      </header>
      <div class="vpage__info"><slot name="info"/></div>
    </div>
    <div class="vpage__content"><slot/></div>
  </div>
</template>
<style lang="scss" scoped>
.vpage {
  &__top {
    @extend %flex-center;
    justify-content: space-between;
  }

  &__title,
  &__subtitle {
    margin: 0;
  }

  &__title {
    @include font-size(24px);
    font-weight: 500;
  }

  &__subtitle {
    margin-top: 4px;
    @include font-size(12px, 14px);
    font-weight: normal;
    color: $color-gray-6C;
  }
}
</style>
