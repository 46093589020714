<script>
/**
 * Products
 * @version 1.0.0
 * @since
 */
import VDatePicker from '@/components/v3/patterns/VDatePicker';
import BarChartV2 from '@/util/BarChart/BarChartV2';
import dayjs from 'dayjs';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import EzDrawer from '@/components/ui/Drawer';
import VStatsBox from '@/components/v3/elements/VStatsBox';
import VStatsBoxes from '@/components/v3/elements/VStatsBoxes';
import VLabel from '@/components/v3/elements/VLabel';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import EzDropDown from '@/components/ui/Dropdown';
import VBadge from '@/components/v3/elements/VBadge';
import { formatK, formatPrice, getCurrency } from '@/util/utils';
import EmptyState from '@/views/common/empty-state';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch/index';
import EzTable from '@/components/ui/Table/EzTable';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import { FILTER_AMOUNT, FILTER_COUNT, LOADING_KEY } from '@/util/constants';
import EzLoader from '@/components/ui/Loader/EzLoader';
import VPrice from '@/components/v3/elements/VPrice';

export default {
  components: {
    EzLoader,
    EzLoadMore,
    EzTable,
    VLabel,
    EzDrawer,
    VDatePicker,
    VStatsBox,
    VStatsBoxes,
    VVenueEntityInfo,
    EzDropDown,
    VBadge,
    EmptyState,
    VSelectSearch,
    VPrice,
  },
  data() {
    return {
      loadMoreMostOrderedByKey: 'load-more-most-ordered-by-key',
      loadingMsg: 'Loading...',
      range: {
        start: null,
        end: null,
      },
      rangeName: '',
      chart: null,
      data: [],
      selectedVenue: null,
      selectedProduct: {},
      selectedProductStats: {},
      mostOrderedBy: [],
      outlets: [],
      emptyState: true,
      filter: [FILTER_AMOUNT, FILTER_COUNT],
      selectedFilter: null,
      currencyChartFormatting: {
        yAxis: {
          format: v => `${this.currencySymbol}${formatK(Math.floor(v))}`,
        },
        tooltip: {
          format: v => formatPrice(v),
        },
      },
      regularChartFormatting: {
        yAxis: {
          format: null, // Reset format to default
        },
        tooltip: {
          format: v => v.toLocaleString(),
        },
      },
    };
  },
  computed: {
    ...mapState('reports', ['meta', 'filtersProducts']),
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.INIT_DISTRIBUTOR,
        LOADING_KEY.FETCH_REPORT_VENUES_SEARCH,
        LOADING_KEY.FETCH_PRODUCT_REPORT,
        LOADING_KEY.FETCH_SINGLE_PRODUCT_REPORT,
        LOADING_KEY.FETCH_MOST_ORDERED_BY,
        LOADING_KEY.JOKER,
      ]);
    },
    isMostOrderedByLoadingMore() {
      return this.getLoading(this.loadMoreMostOrderedByKey);
    },
    selectedProductInfo() {
      return this.selectedProductStats.product || {};
    },
    isAmountSelected() {
      return this.selectedFilter.id === FILTER_AMOUNT.id;
    },
    headerLabel() {
      return this.isAmountSelected
        ? this.$t('reports.general.productLabelSpent')
        : this.$t('reports.general.productLabelAmount');
    },
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return symbol;
    },
    whichPriceLabel() {
      return this.selectedProductInfo.priceUnit
        ? this.selectedProductInfo.priceUnit.label
        : this.selectedProductInfo.orderingUnit.label;
    },
  },
  created() {
    this.range = { ...this.filtersProducts.range };
    this.rangeName = this.filtersProducts.rangeName;
    this.selectedVenue = { ...this.filtersProducts.venue };
    this.selectedFilter = { ...this.filtersProducts.byType };

    this.updateChart();
  },
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    ...mapActions('reports', [
      'fetchProductReport',
      'fetchSingleProductReport',
      'fetchReportVenuesSearch',
      'fetchMostOrderedBy',
    ]),
    ...mapMutations('reports', [
      'SET_META_DATA',
      'UPDATE_FILTERS_PRODUCTS',
    ]),
    ...mapMutations('loading', ['SET_LOADING', 'CLEAR_LOADING']),
    onRangeNameChange(rangeName) {
      this.rangeName = rangeName;
      this.UPDATE_FILTERS_PRODUCTS({ rangeName });
    },
    onDateChange(range) {
      this.range = range;
      this.UPDATE_FILTERS_PRODUCTS({ range });
      this.updateChart();
    },
    async createChart() {
      const chart = document.getElementById('chart');
      this.chart = new BarChartV2(chart, {
        data: this.data,
        options: {
          on: {
            click: this.chartClick,
          },
          ...this.currencyChartFormatting,
        },
      });
    },
    filterSelectedResults(results) {
      return results.filter(product => (this.selectedProduct || {}).id !== product.id);
    },
    onVenueSelect(data) {
      this.selectedVenue = data.reset ? null : data;
      this.UPDATE_FILTERS_PRODUCTS({ venue: data.id ? data : null });
      this.refresh();
    },
    filterChange(item) {
      this.selectedFilter = item;
      this.UPDATE_FILTERS_PRODUCTS({ byType: item });
      this.refresh();
    },
    async fetchData() {
      const [
        { data: { data, meta } },
        { data: { data: venues } },
      ] = await Promise.all([
        this.fetchProductReport({
          from: dayjs(this.range.start).toISOString(),
          to: dayjs(this.range.end).toISOString(),
          ...(this.selectedFilter && { type: this.selectedFilter.id }),
          ...(this.selectedVenue && { venueId: this.selectedVenue.id }),
        }),
        this.fetchReportVenuesSearch(),
      ]);

      this.outlets = venues;

      this.data = data;
      this.emptyState = !this.data.length;

      const { amount } = meta;
      const count = this.isAmountSelected ? this.$helpers.formatPrice(amount) : amount;

      if (this.isAmountSelected) {
        // Set header data
        this.SET_META_DATA({
          subtitle: this.$tc('reports.general.productSubtitle', meta.count),
          label: this.headerLabel,
          count,
        });
      } else { // this is preventive here, maybe miraculously come back :)
        this.SET_META_DATA({
          subtitle: null,
          label: null,
          count: null,
        });
      }
    },
    async refresh() {
      await this.fetchData();

      if (!this.chart) {
        this.emptyState = !this.data.length;
        await this.$nextTick();
        if (this.emptyState) {
          return;
        }
        await this.createChart();
      }

      this.chart.update(this.data, {
        ...(this.isAmountSelected && this.currencyChartFormatting),
        ...(!this.isAmountSelected && this.regularChartFormatting),
      });
    },
    updateChart() {
      this.refresh();
    },
    onLoadMoreClick() {
      const { nextId, nextValue } = this.meta;
      this.fetchMostOrderedBySingleView({
        nextId,
        nextValue,
        loadingKey: this.loadMoreMostOrderedByKey,
      });
    },
    async chartClick({ entity }) {
      const { id } = entity;
      if (id) {
        this.SET_LOADING({ [LOADING_KEY.JOKER]: true });

        const from = dayjs(this.range.start).toISOString();
        const to = dayjs(this.range.end).toISOString();
        const type = this.selectedFilter.id;
        const { data } = await this.fetchSingleProductReport({
          id,
          from,
          to,
          type,
        });
        this.selectedProduct = entity;
        this.selectedProductStats = data.data;
        this.mostOrderedBy = [];
        await this.fetchMostOrderedBySingleView();

        this.CLEAR_LOADING({ key: LOADING_KEY.JOKER });

        this.$refs.singleItemDrawer.open();
      }
    },
    destroy() {
      if (this.chart) this.chart.destroy();
    },
    async fetchMostOrderedBySingleView({ nextId = null, nextValue = null, loadingKey } = {}) {
      const { id } = this.selectedProduct;
      const from = dayjs(this.range.start).toISOString();
      const to = dayjs(this.range.end).toISOString();
      const type = this.selectedFilter.id;
      const res = await this.fetchMostOrderedBy({
        productId: id,
        from,
        to,
        sortBy: type,
        nextId,
        nextValue,
        ...(loadingKey ? { loadingKey } : null),
      });
      const { data, meta } = res.data;
      this.SET_META_DATA({
        ...this.meta,
        nextId: meta.nextId,
        nextValue: meta.nextValue,
      });
      this.mostOrderedBy = [...this.mostOrderedBy, ...data];
    },
    getProductUnitLabel(product) {
      const { priceUnit, orderingUnit } = product || {};
      return priceUnit?.label || orderingUnit?.label || '';
    },
  },
};
</script>
<template>
  <div>
    <ez-loader :show="isLoading">
      {{loadingMsg}}
    </ez-loader>
    <div class="u-flex-center mt-24 mb-48">
      <v-select-search
        v-if="outlets.length"
        class="select-product"
        :data="outlets"
        value-property="id"
        :placeholder="`Showing for all ${$t('global.venues')}`"
        :searchPlaceholder="`Select ${$t('global.venue')}`"
        :selected="selectedVenue"
        @selected="onVenueSelect"
        align-left
      >
        <template #result="{result}">
          <v-venue-entity-info :venue="result" />
        </template>
      </v-select-search>
      <ez-drop-down
        class="ml-12 filter-select"
        :data="filter"
        is-full-width
        name="filter"
        @change="filterChange"
        :selected="selectedFilter.id"
      />
      <v-date-picker
        class="ml-12"
        name="range"
        label="Range"
        v-model="range"
        rangeMode
        hideLabel
        withPredefinedRanges
        :numberOfCalendars="2"
        :selectPredefinedRange="rangeName"
        @dateChange="onDateChange"
        @rangeNameChange="onRangeNameChange"
      />
    </div>
    <div id="chart" v-show="!emptyState"></div>
    <div class="pt-48" v-show="emptyState">
      <empty-state>
        <template #badge>
          <img src="@/assets/no-reports.svg" width="160" height="138" alt="Empty state image" />
        </template>
        <template #title>No data for selected filters</template>
        <template #info>Try with different filters.</template>
      </empty-state>
    </div>

    <ez-drawer ref="singleItemDrawer">
      <template #title>
        <v-badge>{{ selectedProductInfo.category | categoryWithParent }}</v-badge>
      </template>
      <template #content>
        <div class="item-drawer__container">
          <header class="item-drawer__header mb-24 pb-24">
            <div>
              <h2
                class="product-title drawer-header__text--ellipses"
                :title="selectedProductInfo.name"
              >
                {{ selectedProductInfo.name }}
              </h2>
              <div class="product-price mb-16">
                <v-price
                  flex-row
                  :price="selectedProductInfo.price || 0"
                  :currency="selectedProductInfo.currency"
                  :is-market-price="selectedProductInfo.marketPrice"
                  :show-market-price-long="true"
                  :show-market-price-info="false"
                  :unit="getProductUnitLabel(selectedProductInfo)"
                />
              </div>
              <div class="contact-info__item" v-if="selectedProductInfo.sku">
                <v-label as="small">SKU Number</v-label>
                <div
                  class="contact-info__value drawer-header__text--ellipses"
                  :title="selectedProductInfo.sku"
                >
                  {{ selectedProductInfo.sku }}
                </div>
              </div>
            </div>
            <div>
              <img
                :src="selectedProductInfo.image"
                class="product-image"
                :alt="`${selectedProductInfo.name} product image`"
              />
            </div>
          </header>

          <v-label as="small" class="mb-12">Stats for {{ rangeName }}</v-label>
          <v-stats-boxes class="mb-24 stats-boxes--large">
            <v-stats-box>
              <template #label>Units Sold</template>
              <template #value>{{ selectedProductStats.totalCount | formatNumber }}</template>
            </v-stats-box>
            <v-stats-box>
              <template #label>Average Price per Unit</template>
              <template #value>{{ selectedProductStats.avgPrice | price }}</template>
            </v-stats-box>
            <v-stats-box>
              <template #label>Sales</template>
              <template #value>{{ selectedProductStats.totalAmount | price }}</template>
            </v-stats-box>
            <v-stats-box>
              <template #label>Gross Margin</template>
              <template #value v-if="!selectedProductStats.grossMargin">-</template>
              <template #value v-else>{{ selectedProductStats.grossMargin }}%</template>
            </v-stats-box>
          </v-stats-boxes>

          <v-label as="h4" class="drawer-subtitle mb-2">Most Ordered By</v-label>
          <v-label as="small" class="mb-16">{{ rangeName }}</v-label>

          <div class="drawer-table-container">
            <ez-table
              :data="mostOrderedBy"
              :columns="['name', 'filter']"
              :headers="{
                name: () => $t('global.venue'),
                filter: () => `${selectedFilter.tableColName}`,
              }"
              :column-props="{
                name: { class: 'width-100-cell' },
                filter: { class: 'price-cell padding-right-price-cell' },
              }"
            >
              <template #cell-name="{ row }">
                <v-venue-entity-info :venue="row" />
              </template>
              <template #cell-filter="{ row }">
                <span v-if="isAmountSelected">
                  {{ row.amount | price }}
                </span>
                <span v-else>
                  {{ row.count }}
                </span>
              </template>
            </ez-table>
            <div v-if="isMostOrderedByLoadingMore" class="u-text-center mt-12">
              <ez-spinner/>
            </div>
            <ez-load-more
              v-if="!isMostOrderedByLoadingMore && meta.nextId"
              @loadMore="onLoadMoreClick"
            />
          </div>
        </div>
      </template>
    </ez-drawer>
  </div>
</template>
<style lang="scss" scoped>
:deep() .table {
  .unit-cell {
    width: 95px;
  }
  tbody {
    tr {
      &:hover {
        cursor: default;
        td {
          background-color: inherit;
        }
      }
    }
  }
}

:deep() .drawer-subtitle {
  @include font-size(16px, 22px);
  font-weight: bold;
  color: inherit;
}

.padding-right-price-cell {
  padding-right: 5px !important;
}

.drawer-table-container {
  height: 630px;
  overflow: auto;
}

.drawer-header__text--ellipses {
  @extend %text-overflow-ellipsis;
  width: 424px;
}

.contact-info {
  display: flex;
  align-items: center;

  &__item {
    flex: 1;
  }

  &__value {
    margin-top: 6px;
    color: #2b2d3e;
    font-size: 14px;
    letter-spacing: -0.25px;
    line-height: 16px;
  }
}

.item-drawer {
  &__container {
    padding: 0 24px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #dee1e4;
  }
}

.product-image {
  @include size(120px);
  object-fit: cover;
  object-position: center;
  border: 1px solid #dee1e4;
  border-radius: 6px;
}

.product-title {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 29px;
}

.product-price {
  color: $color-primary-dark-blue;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 19px;
}

.select-product {
  :deep() .select-search {
    &__trigger {
      min-width: 240px;
    }
  }
}

.filter-select.ez-dropdown {
  max-width: none;
  width: 240px;
}
</style>
<style lang="scss">
@import '../../../../scss/chart';
</style>
