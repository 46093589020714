<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import uuid from 'uuid/v4';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { clone, debounce } from '@/util/utils';
import { LOADING_KEY } from '@/util/constants';

import EzFilterList from '@/components/ui/FilterList';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzButton from '@/components/ui/Button';
import EzBadge from '@/components/ui/Badge';
import EzTable from '@/components/ui/Table';
import EzInput from '@/components/ui/Input';
import EmptyState from '@/views/common/empty-state';
import VProductInventoryItems from '@/components/v3/patterns/VProductInventoryItems';

import AddLocationModal from './AddLocationModal.vue';
import MapProductsModal from './MapProductsModal.vue';

/**
 * Locations
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Locations',
  components: {
    EzFilterList,
    EzLoadMore,
    EzSpinner,
    EzButton,
    EzBadge,
    EzTable,
    EzInput,
    EmptyState,
    VProductInventoryItems,
    AddLocationModal,
    MapProductsModal,
  },
  data() {
    return {
      supplierCy,
      items: [],
      meta: {},
      locations: [],
      editingLocation: {},
      filters: {
        term: null,
      },
    };
  },
  computed: {
    ...mapState('entities/products', ['inventoryManagement']),
    ...mapGetters('loading', ['getLoading']),
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_LOCATIONS);
    },
    loadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_INVENTORY_LOCATIONS);
    },
    warehouse() {
      return this.inventoryManagement.warehouse;
    },
    productsLeft() {
      return this.items.filter(i => !i.locationId);
    },
    noLocationsFound() {
      return !this.loading && !this.loadingMore && !this.locations.length;
    },
  },
  methods: {
    ...mapMutations('entities/products', ['UPDATE_INVENTORY_MANAGEMENT_DRAFT']),
    ...mapActions('entities/products', ['distributorFetchInvetoryLocations']),
    ...mapActions('entities/distributors', ['distributorUpdateWarehouse']),
    openEditWarehouseModal() {
      this.$refs.editWarehouseModal.open(this.warehouse);
    },
    async onEditWarehouseModalSuccess() {
      this.locations = [];
      this.meta = {};
      await this.$nextTick();
      await this.fetchLocations();
    },
    hasAddedProducts(locationId) {
      return this.items.some(p => p.locationId === locationId);
    },
    openAddProductsModal(location) {
      this.$refs.addProductsModal.open({
        ...clone(location),
        ...(location?.addedItems?.length
          ? {}
          : { addedItems: [{ product: { id: `null-${uuid()}` } }] }),
      });
    },
    async saveAddedProducts(location) {
      const idx = this.locations.findIndex(l => l.id === location.id);
      const addedProducts = this.filterValidProducts(location.addedItems || []);
      const addedProductsIds = addedProducts.map(l => l?.product?.id);
      const addedItems = this.items.filter(i => addedProductsIds.includes(i.product.id));

      this.locations.splice(idx, 1, { ...this.locations[idx], addedItems });
      await this.$nextTick();

      this.items = this.items.map((i) => {
        const found = this.locations.find(
          l => !!l.addedItems.find(pp => pp.product.id === i.product.id),
        );
        if (found) return { ...i, locationId: found.id };

        const { locationId, ...rest } = i;
        return rest;
      });
    },
    filterValidProducts(arr) {
      return clone(arr.filter(p => !p.product.id.toString().includes('null')));
    },
    async fetchLocations(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_LOCATIONS) {
      const query = {
        limit: 10,
        sortBy: 'name',
        ...(this.filters.term ? { term: this.filters.term } : {}),
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
      };

      const {
        data: { data, meta },
      } = await this.distributorFetchInvetoryLocations({
        warehouseId: this.warehouse.id,
        query,
        loadingKey,
      });

      if (loadingKey === LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_LOCATIONS) {
        this.locations = clone(data);
      } else {
        this.locations = clone([...this.locations, ...data]);
      }
      this.locations = this.locations.map(l => ({
        ...l,
        addedItems: this.getLocationProducts(l.id),
      }));
      this.meta = clone(meta);
    },
    async onLoadMore() {
      await this.fetchLocations(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_INVENTORY_LOCATIONS);
    },
    getLocationProducts(locationId) {
      return this.items.filter(i => i.locationId === locationId) || [];
    },
    onNextStep() {
      this.UPDATE_INVENTORY_MANAGEMENT_DRAFT({ items: clone(this.items) });
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    async resetFilters() {
      this.filters.term = null;
      this.locations = [];
      this.meta = {};

      await this.$nextTick();
      await this.fetchLocations();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') this.filters.term = event;
      this.locations = [];
      this.meta = {};

      await this.$nextTick();
      await this.fetchLocations();
    }, 300),
  },
  async created() {
    if (this.inventoryManagement.items) {
      this.items = clone(this.inventoryManagement.items);
    }
    await this.fetchLocations();
  },
  watch: {
    productsLeft: {
      immediate: true,
      handler(val) {
        if (!val.length) this.enableNextStep();
        else this.disableNextStep();
      },
    },
  },
};
</script>

<template>
  <div class="locations-step">
    <div class="subheader mb-24 u-flex-space">
      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <ez-input
          formKey="filters"
          label="search"
          name="search"
          class="search"
          placeholder="Search for Locations"
          :data-cy="supplierCy.INVENTORY_FLOWS.ADDITION.LOCATIONS.INPUT__SEARCH"
        >
          <template #suffix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>

      <p class="subheader__selected-count">
        Remaining products to add:
        <ez-badge type="blue" :count="productsLeft.length" showEveryNumber />
      </p>

      <div class="subheader__add-new">
        <ez-button
          v-if="!noLocationsFound"
          type="primary"
          @click="openEditWarehouseModal"
          :data-cy="supplierCy.INVENTORY_FLOWS.ADDITION.LOCATIONS.BUTTON__ADD_NEW_LOCATION"
        >
          Add New Location
        </ez-button>
      </div>
    </div>

    <ez-table
      class="locations-table"
      :loading="loading"
      :data="locations"
      :columns="['location', 'inventoryItems', 'addedItems', 'actions']"
      :headers="{
        location: () => 'Locations',
        inventoryItems: () => 'Products in Location',
        addedItems: () => 'Added Products',
      }"
      :columnProps="{
        location: { class: 'large-cell' },
        inventoryItems: { class: 'products-cell' },
        addedItems: { class: 'products-cell' },
        actions: { class: 'medium-cell' },
      }"
    >
      <template #cell-location="{ row: { code } }">
        <span :title="code">{{ code }}</span>
      </template>

      <template #cell-inventoryItems="{ row: { inventoryItems } }">
        <span v-if="!inventoryItems.length" class="no-products">Empty</span>
        <v-product-inventory-items
          v-else
          :items="inventoryItems"
          quantityLabel="Inventory"
          quantityKey="currentInventory"
        />
      </template>

      <template #cell-addedItems="{ row: { addedItems } }">
        <span v-if="!addedItems.length" class="no-products">Empty</span>
        <v-product-inventory-items
          v-else
          :items="addedItems"
          quantityLabel="Quantity"
          quantityKey="quantity"
        />
      </template>

      <template #cell-actions="{ row }">
        <ez-button
          type="secondary"
          v-if="hasAddedProducts(row.id)"
          @click="openAddProductsModal(row)"
          :data-cy="
            `${supplierCy.INVENTORY_FLOWS.ADDITION.LOCATIONS.BUTTON__ADD_PRODUCT}-${row.id}`
          "
        >
          Edit
        </ez-button>
        <ez-button
          v-else
          type="secondary"
          class="add-product-button"
          @click="openAddProductsModal(row)"
          :disabled="!productsLeft.length"
          :data-cy="`${supplierCy.INVENTORY_FLOWS.ADDITION.LOCATIONS.BUTTON__EDIT}-${row.id}`"
        >
          Add Product
        </ez-button>
      </template>
    </ez-table>

    <empty-state v-if="noLocationsFound">
      <template #badge><img src="@/assets/no-products-search-state.svg" alt=""/></template>
      <template #title>No locations listed</template>
      <template #info>Please, create the locations to add your products to them.</template>
      <ez-button class="mt-12" type="primary" @click="openEditWarehouseModal">
        Add New Location
      </ez-button>
    </empty-state>

    <div v-if="loadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="!loadingMore && meta.nextId" @loadMore="onLoadMore" />

    <add-location-modal ref="editWarehouseModal" @success="onEditWarehouseModalSuccess" />
    <map-products-modal
      ref="addProductsModal"
      :items="items"
      :locations="locations"
      :productsRemaining="productsLeft"
      @finished="saveAddedProducts"
    />
  </div>
</template>

<style lang="scss" scoped>
.locations-step {
  margin-top: 64px;

  .subheader {
    border-bottom: 1px solid #eceef5;
    padding: 14px 0;

    & > * {
      flex: 1 0 33.33333%;
    }

    &__selected-count {
      @include font-size(14px, 20px);
      color: $color-gray-6C;
      text-align: center;
      margin: 0;
    }

    &__add-new {
      display: flex;
      justify-content: flex-end;
    }
  }

  .locations-table {
    .products-cell .no-products {
      @include font-size(14px, 20px);
      font-weight: 500;
      color: $color-gray-6C;
    }

    .add-product-button:disabled {
      background-color: #f5f6fa;
      color: rgba($color-gray-6C, 0.48);
    }
  }

  :deep() .ez-empty-state {
    .ez-empty-state__image {
      max-width: 240px;

      img {
        width: 240px;
      }
    }
  }
}
</style>
