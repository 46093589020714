<template>
  <div class="mt-16">
    <ez-table
      v-if="products.length"
      :data="products"
      :headers="{ name: () => 'Product' }"
      :columns="['name', 'sku', 'venue', 'category', 'currentPrice', 'proposedPrice']"
      :columnProps="{
        category: { class: 'category-cell' },
        currentPrice: { class: 'price-cell' },
        proposedPrice: { class: 'price-cell' },
      }"
      @rowClick="handleRowClick"
    >
      <template #cell-name="{ row: { product } }">
        <VProductEntityInfo :product="product" />
      </template>

      <template #cell-sku="{ row }">{{ row.product.sku }}</template>

      <template #cell-category="{ row: { product } }">
        <VBadge>{{ (product.category || {}).name || 'Uncategorized' }}</VBadge>
      </template>

      <template #cell-venue="{ row: { venue } }">
        <VVenueEntityInfo :venue="venue" />
      </template>

      <template #cell-currentPrice="{ row }">
        <span class="old-price">{{ row.product.price | price }}</span>
      </template>

      <template #cell-proposedPrice="{ row }">
        <span class="u-text-green">{{ row.price | price }}</span>
      </template>
    </ez-table>

    <ez-drawer ref="singleProductDrawer">
      <template #title>
        <VBadge>{{ (selectedProduct.category || {}).name || 'Uncategorized' }}</VBadge>
      </template>

      <template #content>
        <div class="content">
          <VProductInfo :product="selectedProduct" />
          <div v-if="hasTiers">
            <hr />
            <VLabel as="small">{{ $t('product.pricingTiersLabel') }}</VLabel>
            <VTiers
              class="mt-16"
              :price="selectedRow.price || 0"
              :tiers="selectedProduct.tiers"
            />
          </div>
          <div>
            <hr />
            <VLabel as="small">{{ $t('global.venue') }}</VLabel>
            <div class="u-flex-space">
              <VVenueEntityInfo :venue="selectedRow.venue" />
              <ez-button
                type="secondary"
                @click="showVenueInfo"
                :is-loading="isOutletInfoLoading"
              >
                <font-awesome-icon icon="info-circle" />
                <span>{{ $t('global.venue') }} Info</span>
              </ez-button>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div>
          <ez-button
            @click="confirmAcceptProposal"
            :disabled="isDeclineProposedPriceLoading"
          >
            {{ $t('product.acceptProposal') }}
          </ez-button>
          <ez-button
            class="ml-8"
            type="secondary"
            @click="confirmDeclineProposal"
            :is-loading="isDeclineProposedPriceLoading"
          >
            {{ $t('product.declineProposal') }}
          </ez-button>
        </div>

        <div>
          <VLabel>{{ $t('product.proposedPrice') }}</VLabel>
          <div class="price">{{ selectedRow.price | price }}</div>
        </div>
      </template>
    </ez-drawer>

    <ez-info-modal ref="infoModal" :data="venueInfo">
      <template #title>{{ $t('global.venue') }} Info</template>
    </ez-info-modal>
  </div>
</template>

<script>
/**
 * ProposalsProducts
 * @version 1.0.0
 * @since
 */

import EzTable from '@/components/ui/Table';
import EzDrawer from '@/components/ui/Drawer';
import { EzInfoModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import VProductInfo from '@/components/v3/patterns/VProductInfo';
import VTiers from '@/components/v3/patterns/VTiers';
import VBadge from '@/components/v3/elements/VBadge';
import VLabel from '@/components/v3/elements/VLabel';
import confirmation from '@/components/v3/patterns/VConfirmation/control';
import { mapActions, mapGetters } from 'vuex';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';

export default {
  components: {
    EzButton,
    EzInfoModal,
    VVenueEntityInfo,
    VProductEntityInfo,
    VTiers,
    VProductInfo,
    VBadge,
    VLabel,
    EzTable,
    EzDrawer,
  },
  data() {
    return {
      products: [],
      selectedRow: {},
      venueInfo: {},
    };
  },
  computed: {
    ...mapGetters('entities/users', ['priceProposalsCount']),
    ...mapGetters('loading', ['getLoading']),
    isOutletInfoLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_VENUE);
    },
    isDeclineProposedPriceLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_DECLINE_PROPOSED_PRICE);
    },
    selectedProduct() {
      return this.selectedRow.product || {};
    },
    hasTiers() {
      return this.selectedProduct?.tiers?.length;
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'distributorFetchProposedPricing',
      'distributorFetchSingleProposedPricing',
      'distributorApproveProposedPrice',
      'distributorDeclineProposedPrice',
    ]),
    ...mapActions('entities/venues', ['distributorFetchVenue']),
    ...mapActions('entities/users', ['initDistributorLoggedUser']),
    closeDrawer() {
      this.$refs.singleProductDrawer.close();
    },
    async confirmAcceptProposal() {
      const { close, canceled } = await confirmation.confirm({
        title: this.$t('product.acceptProposalConfirmTitle'),
        message: this.$t('product.acceptProposalConfirmMessage', {
          from: this.$helpers.formatPrice(this.selectedProduct.price),
          to: this.$helpers.formatPrice(this.selectedRow.price),
        }),
        buttons: {
          confirm: 'Accept Proposal',
        },
        loadingKey: LOADING_KEY.DISTRIBUTOR_APPROVE_PROPOSED_PRICE,
      });
      if (canceled) return;
      await this.distributorApproveProposedPrice({ id: this.selectedRow.id });
      close();
      this.closeDrawer();
      await this.refresh();
      flash.success({
        title: this.$t('product.acceptProposalFlashTitle'),
        message: this.$t('product.acceptProposalFlashMessage', {
          productName: this.selectedProduct.name,
        }),
      });
    },
    async confirmDeclineProposal() {
      await this.distributorDeclineProposedPrice({ id: this.selectedRow.id });
      this.closeDrawer();
      await this.refresh();
      flash.success({
        title: this.$t('product.declineProposalFlashTitle'),
        message: this.$t('product.declineProposalFlashMessage', {
          productName: this.selectedProduct.name,
        }),
      });
    },
    async showVenueInfo() {
      const { data } = await this.distributorFetchVenue({ id: this.selectedRow.venue.id });
      this.venueInfo = data.data;
      this.$refs.infoModal.open();
    },
    async handleRowClick(row) {
      const proposedPriceId = row.id;
      const { data } = await this.distributorFetchSingleProposedPricing({ id: proposedPriceId });
      this.selectedRow = data.data;
      this.$refs.singleProductDrawer.open();
    },
    async refresh() {
      const [
        { data: { data } },
        // Init Distributor
      ] = await Promise.all([
        this.distributorFetchProposedPricing(),
        this.initDistributorLoggedUser(),
      ]);

      this.products = data;

      if (this.priceProposalsCount < 1) {
        this.$router.push({ name: 'distributor-products' });
      }
    },
  },
  created() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
.old-price {
  text-decoration: line-through;
  color: $color-gray-6C;
}

:deep() .drawer__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $color-gray-E9;
}
.price {
  @include font-size(20px);
  color: $color-primary-green;
  font-weight: bold;
  margin-top: 4px;
}

.content {
  padding: 0 1.5rem;
}
</style>
