<script>
import EzForm from '@/components/ui/Form/EzForm';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzInput from '@/components/ui/Input/EzInput';
import flash from '@/components/ui/FlashMessage';
import { mapMutations } from 'vuex';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  components: {
    EzForm,
    EzCheckbox,
    EzInput,
  },
  props: {
    additionalData: {
      type: Object,
      required: false,
      default: () => {},
    },
    action: {
      required: false,
      type: String,
      default: '/distributor/me/onboarding-requests',
    },
    method: {
      required: false,
      type: String,
      default: 'post',
    },
    data: {
      required: false,
      type: Object,
      default: () => null,
    },
    loadingKey: {
      required: false,
      type: String,
      default: 'onboarding-option',
    },
    diffFormKey: {
      type: String,
      required: false,
      default: 'onboardingOption',
    },
  },
  data() {
    return {
      formKey: '',
      email: null,
      smsNumber: null,
      whatsAppNumber: null,
      emailEnabled: false,
      smsEnabled: false,
      whatsAppEnabled: false,
      productVisibility: true,
      additionalDataCopy: {},
    };
  },
  computed: {
    isAnyChecked() {
      return this.emailEnabled || this.smsEnabled || this.whatsAppEnabled;
    },
  },
  watch: {
    isAnyChecked() {
      if (this.isAnyChecked) this.$emit('anyChecked', true);
      else this.$emit('anyChecked', false);
    },
    data: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val) {
          return;
        }

        this.productVisibility = val.productVisibility !== 'false';
        this.email = val.email || '';
        this.smsNumber = val.smsNumber || '';
        this.whatsAppNumber = val.whatsAppNumber || '';

        this.emailEnabled = Boolean(val.email);
        this.smsEnabled = Boolean(val.smsNumber);
        this.whatsAppEnabled = Boolean(val.whatsAppNumber);
      },
    },
    additionalData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val) {
          return;
        }
        this.additionalDataCopy = { ...this.additionalDataCopy, ...val };
      },
    },
  },
  methods: {
    ...mapMutations('errors', [
      'CLEAR_ERRORS',
    ]),
    reset(fields = []) {
      this.$refs.onboardingOptionsForm.reset(fields);
    },
    clearErrors(fields = []) {
      this.$refs.onboardingOptionsForm.clearErrors(fields);
    },
    productVisibilityChange() {
      this.productVisibility = !this.productVisibility;
      this.additionalDataCopy = {
        ...this.additionalDataCopy,
        productVisibility: this.productVisibility,
      };
    },
    onSubmitSuccess({ data }) {
      this.$emit('success', data);
    },
    onSubmitError() {
      flash.error({ title: 'Something went wrong' });
    },
    submitOnboardingForm() {
      this.$refs.onboardingOptionsForm.onSubmit();
    },
    smsChanged(val) {
      this.smsEnabled = val;
      this.smsNumber = null;
    },
    whatsAppChanged(val) {
      this.whatsAppEnabled = val;
      this.whatsAppNumber = null;
    },
    emailChanged(val) {
      this.emailEnabled = val;
      this.email = null;
    },
  },
  mounted() {
    this.additionalDataCopy = this.additionalData;
    this.additionalDataCopy = {
      ...this.additionalDataCopy,
      productVisibility: this.productVisibility,
    };
    this.formKey = this.diffFormKey;
  },
  beforeDestroy() {
    this.CLEAR_ERRORS({ key: this.formKey });
  },
};
</script>
<template>
  <div>
    <ez-form
      :action="action"
      :formKey="formKey"
      :loading-key="loadingKey"
      :method="method"
      :additionalData="additionalDataCopy"
      @success="onSubmitSuccess"
      @error="onSubmitError"
      ref="onboardingOptionsForm"
    >
      <slot></slot>
      <ez-checkbox
        :formKey="formKey"
        :checked="emailEnabled"
        class="mb-8"
        label="Email"
        @change="emailChanged"
      />
      <ez-input
        :formKey="formKey"
        :readonly="!emailEnabled"
        required
        :validators="`${emailEnabled ? 'required' : ''}|email`"
        class="notification-number-input"
        placeholder="Enter Email Address"
        name="email"
        :value="email"
      />
      <ez-checkbox
        :formKey="formKey"
        :checked="smsEnabled"
        class="mt-12 mb-8"
        label="SMS"
        @change="smsChanged"
      />
      <ez-input
        :formKey="formKey"
        :readonly="!smsEnabled"
        :validators="`${smsEnabled ? 'required' : ''}`"
        class="notification-number-input"
        placeholder="Enter Phone Number"
        name="smsNumber"
        :value="smsNumber"
      />
      <ez-checkbox
        :formKey="formKey"
        :checked="whatsAppEnabled"
        label="WhatsApp"
        class="mt-12 mb-8"
        @change="whatsAppChanged"
      />
      <ez-input
        :formKey="formKey"
        :readonly="!whatsAppEnabled"
        :validators="`${whatsAppEnabled ? 'required' : ''}`"
        class="notification-number-input"
        placeholder="Enter Phone Number"
        name="whatsAppNumber"
        :value="whatsAppNumber"
      />
    </ez-form>
  </div>
</template>
<style lang="scss" scoped>
  .onboarding-option {
    display: flex;
    justify-content: space-between;
  }
</style>
