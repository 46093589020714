<script>
import EzFormModal from '@/components/ui/Modal/EzFormModal';
import EzForm from '@/components/ui/Form/EzForm';
import { mapMutations } from 'vuex';
import EzInput from '@/components/ui/Input/EzInput';
import EzButton from '@/components/ui/Button/EzButton';
import EzOnOff from '@/components/ui/OnOff/EzOnOff';

const Event = {
  error: 'error',
  success: 'success',
};

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'ConnectionRequestModal',
  components: { EzOnOff, EzButton, EzInput, EzForm, EzFormModal },
  props: {
    action: {
      required: false,
      type: String,
      default: '',
    },
    method: {
      required: false,
      type: String,
      default: '',
    },
    formKey: {
      required: false,
      type: String,
      default: 'connectionRequestModal',
    },
    data: {
      required: false,
      type: Object,
      default: () => null,
    },
    additionalData: {
      required: false,
      type: Object,
      default: () => {},
    },
    namesToDisable: {
      required: false,
      type: Array,
      default: () => [],
    },
    sendDisabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataCopy: {},
      additionalDataCopy: {},
    };
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler(val) {
        this.dataCopy = { name: '', ...val };
      },
    },
    additionalData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val) {
          return;
        }
        this.additionalDataCopy = {
          ...this.additionalDataCopy,
          ...val,
        };
      },
    },
  },
  computed: {
    additionalDataExtended() {
      if (!this.sendDisabled) {
        return this.additionalDataCopy;
      }

      const data = this.data || {};
      const disabledData = {};
      this.namesToDisable.forEach((name) => {
        disabledData[name] = data[name];
      });

      return { ...this.additionalDataCopy, ...disabledData };
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERRORS']),
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
      this.CLEAR_ERRORS({ key: this.formKey });
    },
    sendRequest() {
      this.$refs.form.onSubmit();
    },
    clearErrorsState() {
      this.CLEAR_ERRORS({ key: this.formKey });
    },
    isDisabled(name) {
      return !!this.namesToDisable.find(x => x === name);
    },
    onSuccessForm(res) {
      this.$emit(Event.success, res);
    },
    onErrorForm(error) {
      this.$emit(Event.error, error);
    },
    onChangeOnboarding(checked) {
      this.additionalDataCopy = { ...this.additionalDataCopy, onboarding: checked };
    },
  },
};
</script>
<template>
  <ez-form-modal ref="modal" @close="clearErrorsState">
    <template #title>Send Connection Request</template>
    <template #content>
      <ez-form
        ref="form"
        :action="action"
        :method="method"
        :form-key="formKey"
        :additional-data="additionalDataExtended"
        @success="onSuccessForm"
        @error="onErrorForm"
      >
        <ez-input
          name="name"
          :label="`${$t('global.venue')} Name`"
          :placeholder="`Enter ${$t('global.venue')} Name`"
          :disabled="isDisabled('name')"
          :form-key="formKey"
          :value="dataCopy.name"
        />
        <hr />
        <div class="u-flex-row">
          <div>
            <h4 class="help-block__title">Onboard this {{ $t('global.venue') }}</h4>
            <p class="help-block__text">
              Send an onboarding link to this {{ $t('global.venue') | lowercase }} to let them
              complete the account opening form and go through your T&C.
            </p>
          </div>
          <ez-on-off
            name="onboarding"
            :formKey="formKey"
            :checked="additionalDataCopy.onboarding"
            @change="onChangeOnboarding"
          />
        </div>
      </ez-form>
    </template>
    <template #footer>
      <ez-button type="link" @click="close">
        Cancel
      </ez-button>
      <ez-button formType="button" @click="sendRequest">
        Send Request
      </ez-button>
    </template>
  </ez-form-modal>
</template>
<style scoped lang="scss"></style>
