<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';

import EzButton from '@/components/ui/Button/EzButton.vue';
import EzTable from '@/components/ui/Table/EzTable.vue';
import VPrice from '@/components/v3/elements/VPrice';
import VIconBadge from '@/components/v3/patterns/VIconBadge';

import EditNonStockCreditModal from './EditNonStockCreditModal.vue';

const NON_STOCK_CREDIT = 'nonStockCredit';

/**
 * AdditionalItems
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'AdditionalItems',
  props: {
    creditNote: {
      required: true,
      type: Object,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    EzButton,
    EzTable,
    VPrice,
    VIconBadge,
    EditNonStockCreditModal,
  },
  data() {
    return {
      hoveredRow: null,
      updatedValues: {},
      supplierCy,
    };
  },
  computed: {
    additionalItems() {
      return [
        {
          id: NON_STOCK_CREDIT,
          icon: 'file-invoice-dollar',
          title: 'Non-Stock Credit',
          description: 'The amount is added to the credit total. It can be edited if needed.',
          amount: this.editMode
            ? this.updatedValues[NON_STOCK_CREDIT]
            : this.creditNote.nonStockCredit,
          target: 'nonStockCreditModal',
        },
      ].filter(i => !!i.amount);
    },
  },
  methods: {
    rowHover(row) {
      if (!this.editMode) return;
      this.hoveredRow = row;
    },
    isRowHovered(row) {
      return this.hoveredRow ? this.hoveredRow.id === row.id : false;
    },
    edit(row) {
      this.$refs[row.target].open();
    },
    remove(row) {
      this.updatedValues = { ...this.updatedValues, [row.id]: null };
    },
    onNonStockCreditSave({ nonStockCredit, note }) {
      this.updatedValues = { ...this.updatedValues, nonStockCredit, note };
    },
    resetValues() {
      this.updatedValues = {};
      this.hoveredRow = null;
    },
    initValues() {
      this.updatedValues = {
        nonStockCredit: this.creditNote.nonStockCredit,
      };
    },
    addNonStockCredit() {
      this.$refs.nonStockCreditModal.open({ editMode: false });
    },
  },
  watch: {
    updatedValues(val, oldVal) {
      if (!this.editMode || !Object.keys(oldVal || {}).length) return;
      this.$emit('update', val);
    },
    editMode(newVal, oldVal) {
      if (oldVal && !newVal) this.resetValues();
      else this.initValues();
    },
  },
};
</script>

<template>
  <div>
    <ez-table
      v-if="editMode || additionalItems.length"
      :data="additionalItems"
      :hasMinHeight="false"
      :columns="['name', 'amount']"
      :columnProps="{
        name: { class: 'name-cell' },
        amount: { class: 'amount-cell no-side-padding-cell' },
      }"
      :headers="{
        name: () => 'Additional Items',
        amount: () => 'Total Amount',
      }"
      @rowHover="rowHover"
      class="additional-items mt-16"
    >
      <template #cell-name="{ row }">
        <div class="media-info">
          <v-icon-badge :icon="row.icon" color="yellow-dark" class="media-info__icon" />
          <div class="media-info__content">
            <span
              class="media-info__title"
              :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.TEXT__ADDITIONAL_ITEM_TITLE}-${row.id}`"
            >
              {{ row.title }}
            </span>
            <span
              class="media-info__subtitle"
              :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.TEXT__ADDITIONAL_ITEM_DESCRIPTION}-${row.id}`"
            >
              {{ row.description }}
            </span>
          </div>
        </div>
      </template>
      <template #cell-amount="{ row }">
        <v-price
          v-if="!isRowHovered(row)"
          :price="row.amount"
          :show-market-price-info="false"
          :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.TEXT__ADDITIONAL_ITEM_AMOUNT}-${row.id}`"
        />
        <div v-else class="row-actions">
          <ez-button
            @click="edit(row)"
            type="link"
            class="row-actions__action"
            :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.BUTTON__EDIT_ADDITIONAL_ITEM}-${row.id}`"
          >
            <font-awesome-icon icon="pen" v-tooltip="`Edit ${row.title}`" />
          </ez-button>
          <ez-button
            @click="remove(row)"
            type="link"
            class="row-actions__action"
            :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.BUTTON__DELETE_ADDITIONAL_ITEM}-${row.id}`"
          >
            <font-awesome-icon icon="trash" v-tooltip="`Remove ${row.title}`" />
          </ez-button>
        </div>
      </template>
    </ez-table>

    <ez-button
      v-if="!additionalItems.length && editMode"
      class="mt-16"
      type="secondary"
      is-full-width
      @click="addNonStockCredit"
      :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.BUTTON__ADD_NON_STOCK_CREDIT"
    >
      <font-awesome-icon icon="plus" /> Add non-stock credit
    </ez-button>

    <edit-non-stock-credit-modal
      ref="nonStockCreditModal"
      :nonStockCredit="creditNote.nonStockCredit"
      :note="updatedValues.note || ''"
      @save="onNonStockCreditSave"
    />
  </div>
</template>

<style lang="scss" scoped>
.additional-items {
  .name-cell {
    .media-info {
      display: flex;
      align-items: center;

      &__icon {
        border: unset;
        @include font-size(15px);
      }

      &__content {
        margin-left: 8px;
      }

      &__title {
        display: block;
        @include font-size(14px, 20px);
        color: $color-gray-25;
        font-weight: 500;
      }

      &__subtitle {
        display: block;
        @include font-size(12px, 14px);
      }
    }
  }

  .amount-cell {
    width: 96px;

    :deep() .v-price__price {
      color: $color-gray-25;
    }

    .row-actions {
      &__action {
        padding: 0;
        width: 24px;
        height: 24px;

        &:not(:first-child) {
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
