<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import EzFormModal from '@/components/ui/Modal/EzFormModal';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import EzButton from '@/components/ui/Button/EzButton';
import EzAutocomplete from '@/components/ui/Autocomplete/EzAutocomplete';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import { mapActions, mapGetters } from 'vuex';
import EzSelectedVenue from '@/components/ui/SelectedItem/SelectedVenue';
import dayjs from 'dayjs';
import { DATE_INPUT_FORMAT, DATE_PLACEHOLDER, LOADING_KEY } from '@/util/constants';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import { getDateFormat } from '@/util/utils';

const Event = {
  open: 'open',
  close: 'close',
  submitSuccess: 'submitSuccess',
  submitError: 'submitError',
};

export default {
  name: 'AddToDoModal',
  components: {
    EzSelectedVenue,
    EzEntityInfo,
    EzAutocomplete,
    EzButton,
    EzFormModal,
    EzDropdown,
    EzTextarea,
    VVenueEntityInfo,
    VDatePicker,
  },
  props: {
    /**
     * Can be used to set value for props in data object
     * (usage) :prefilled-data="{ accountOwnerId: 170 }" //will set accountOwnerId = 170;
     */
    prefilledData: {
      required: false,
      type: Object,
      default: () => {},
    },
    toHideAccountOwnerSelect: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formKey: 'addToDoForm',
      selectedVenue: null,
      name: '',
      accountOwnerId: null,
      note: '',
      accountRepsOptions: [],
      isFirst: false,
      today: dayjs(new Date()),
      formatDate: DATE_INPUT_FORMAT,
      datePlaceholder: DATE_PLACEHOLDER,
      dueDate: null,
      dueDateToSent: null,
      getDateFormat,
      supplierCy,
    };
  },
  watch: {
    prefilledData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val) {
          return;
        }
        Object.keys(val).forEach(key => {
          this[key] = val[key];
        });
      },
    },
  },
  computed: {
    ...mapGetters('defaultImages', ['getDefaultImage']),
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    isFormValid() {
      return this.accountOwnerId && this.note;
    },
    defaultImage() {
      return this.getDefaultImage('venue');
    },
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.CREATE_TO_DO]);
    },
  },
  methods: {
    ...mapActions('defaultImages', ['fetchDefaultImages']),
    ...mapActions('reports', ['createToDo', 'fetchToDoVenues', 'fetchAccountRepsAssignable']),
    clearComponentState() {
      this.selectedVenue = null;
      this.name = null;
      this.accountOwnerId = null;
      this.note = '';
      this.dueDate = null;
      this.dueDateToSent = null;
    },
    onCloseModal() {
      this.clearComponentState();
      this.$emit('onClose');
    },
    open() {
      this.$refs.modal.open();
      this.$emit(Event.open);
    },
    close() {
      this.$refs.modal.close();
      this.$emit(Event.close);
    },
    onVenueSelect(selectObj) {
      const { selected, first, query } = selectObj;
      this.isFirst = first;
      this.name = query;
      this.selectedVenue = first ? { name: query, logo: this.defaultImage } : selected;
    },
    removeSelectedVenue() {
      this.selectedVenue = null;
      this.name = '';
      this.isFirst = false;
    },
    onAccountRepSelect(selectedRep) {
      this.accountOwnerId = selectedRep && selectedRep.id;
    },
    onNoteChange(note) {
      this.note = note;
    },
    updateDate(date) {
      if (!date) {
        this.dueDateToSent = null;
        return;
      }
      if (dayjs(date).isSame(this.today, 'day')) {
        this.dueDate = date;
        this.dueDateToSent = dayjs(new Date()).add(5, 'minutes').toISOString();
        return;
      }
      this.dueDateToSent = dayjs(date).toISOString();
      this.dueDate = date;
    },
    async addToDo() {
      const venueId = this.selectedVenue && this.selectedVenue.id;
      try {
        const res = await this.createToDo({
          ...(this.isFirst ? { name: this.name } : null),
          ...(!this.isFirst ? { venueId } : null),
          accountOwnerId: this.accountOwnerId,
          note: this.note,
          dueDate: this.dueDateToSent,
        });
        this.clearComponentState();
        this.$emit(Event.submitSuccess, res);
        this.close();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.$emit(Event.submitError, err.response);
      }
    },
  },
  async created() {
    await this.fetchDefaultImages();
    const { data } = await this.fetchAccountRepsAssignable();
    this.accountRepsOptions = data.data;
  },
};
</script>

<template>
  <ez-form-modal ref="modal" @close="onCloseModal">
    <template #title>
      <div>Add New To-Do</div>
    </template>
    <template #content>
      <div>
        <p class="m-0">
          Select a {{ $t('global.venue') | lowercase }}, an account representative you want to
          assign this task to and leave a note explaining the task.
        </p>
        <hr />
        <div class="mb-16">
          <ez-autocomplete
            v-if="!selectedVenue"
            ref="autocomplete"
            :source="`/account-activity/search/venues`"
            search-property="name"
            :show-selected="true"
            @selected="onVenueSelect"
            :placeholder="`Enter ${$t('global.venue')} Name`"
            :data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.BUTTON__SELECT_OUTLET"
            :result-data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.BUTTON__SELECT_OUTLET"
          >
            <template #firstResult="{ input }">
              <li>
                <ez-entity-info
                  :img-url="defaultImage"
                  :img-has-border="true"
                  img-border-radius="50%"
                  :data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.BUTTON__CREATE_NEW_OUTLET"
                >
                  <span>Create New "{{ input }}" {{ $t('global.venue') }}</span>
                </ez-entity-info>
              </li>
            </template>
            <template #result="{ result }">
              <v-venue-entity-info
                :venue="result"
                :data-cy="`${supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.TEXT__OUTLET}-${result.id}`"
              />
            </template>
          </ez-autocomplete>
          <ez-selected-venue
            v-else
            :venue="selectedVenue"
            :only-basic-info="true"
            @remove="removeSelectedVenue"
            :data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.TEXT__SELECTED_OUTLET"
          />
        </div>
        <ez-dropdown
          v-if="!toHideAccountOwnerSelect"
          class="mb-16"
          :form-key="formKey"
          name="accountRep"
          :label="`${$t('global.accountRep')}`"
          :placeholder="`Select ${$t('global.accountRep')}`"
          :is-full-width="true"
          :data="accountRepsOptions"
          :selected="accountOwnerId"
          :disabled="!$permission.has('seeCRMForAll')"
          @change="onAccountRepSelect"
          :data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.BUTTON__ACCOUNT_REP"
        />
        <v-date-picker
          name="dueDate"
          label="Due Date"
          class="mb-16 w-100"
          v-model="dueDate"
          :form-key="formKey"
          :placeholder="getDateFormat()"
          :min="today.format(formatDate)"
          @dateChange="updateDate"
          is-full-width
          :data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.BUTTON__DUE_DATE"
        />
        <ez-textarea
          class="mb-16"
          :form-key="formKey"
          name="note"
          label="Note"
          placeholder="Leave Note"
          :value="note"
          @onChange="onNoteChange"
          :data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.TEXTAREA__NOTE"
        />
      </div>
    </template>
    <template #footer>
      <div>
        <ez-button
          type="link"
          @click="close"
          :data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.BUTTON__CANCEL"
        >
          Cancel
        </ez-button>
        <ez-button
          :disabled="!isFormValid"
          :is-loading="isLoading"
          @click="addToDo"
          :data-cy="supplierCy.CRM.TO_DOS.ADD_TO_DO_MODAL.BUTTON__ADD_TODO"
        >
          Add To-Do
        </ez-button>
      </div>
    </template>
  </ez-form-modal>
</template>

<style scoped lang="scss">
:deep() .ez-simple-dropdown.date-picker {
  width: auto;
}
</style>
