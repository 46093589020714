<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Distributor from '@/models/Distributor';
import { clone } from '@/util/utils';

import VVenueInventorySettings from '@/components/v3/patterns/VVenueInventorySettings';
import EzRadioButton from '@/components/ui/RadioButton';
import EzMaskInput from '@/components/ui/MaskInput';
import EzButton from '@/components/ui/Button';
import EzOnOff from '@/components/ui/OnOff';
import EzForm from '@/components/ui/Form';
import flash from '@/components/ui/FlashMessage';

/**
 * IventorySettings
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'IventorySettings',
  components: {
    VVenueInventorySettings,
    EzRadioButton,
    EzMaskInput,
    EzButton,
    EzOnOff,
    EzForm,
  },
  data() {
    return {
      formKey: 'products-settings',
      loadingKeyForm: 'distributor-settings-inventory',
      values: {
        tracking: false,
        valuation: '',
        batchTracking: false,
        expiryDays: 0,

        // Outlet settings
        displayStockNumbers: false,
        lowInventoryThreshold: 10,
        orderMoreThanAvailable: true,
        excludeExpired: false,
      },
      initialValues: {},
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    me() {
      return Distributor.find(this.loggedUser.distributor.id);
    },
    hasMadeChanges() {
      const initial = JSON.stringify(this.initialValues);
      const current = JSON.stringify(this.values);

      return initial !== current;
    },
    requiredFieldsPopulated() {
      if (!this.values.tracking) return true;
      return !!this.values.valuation;
    },
    saveDisabled() {
      if (!this.requiredFieldsPopulated) return true;
      return !this.hasMadeChanges;
    },
    canEditProduct() {
      return this.$permission.has('editProductInfo');
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['fetchDistributor']),
    ...mapMutations('entities/users', ['SET_LOGGED_USER']),
    updateState(key, val) {
      this.values[key] = val;
    },
    async onSubmitSuccess() {
      flash.success({ title: `${this.$t('global.distributor')} settings successfully updated!` });
      await this.initDistributor();
      await this.initValues();

      const { distributors: [distributor] } = await this.initDistributor();
      this.SET_LOGGED_USER({ ...this.loggedUser, distributor });
    },
    async initValues() {
      await this.$nextTick();
      const values = { ...this.values, ...this.me.inventorySettings };
      this.values = clone(values);
      this.initialValues = clone(values);
    },
    initDistributor() {
      return this.fetchDistributor(this.loggedUser.distributor.id);
    },
    transformer(data) {
      const params = new URLSearchParams(decodeURI(data));

      Object.keys(this.values).forEach((key) => {
        const property = `inventorySettings[${key}]`;
        if (!params.has(property)) params.append(property, this.values[key]);
      });

      return params;
    },
  },
  async created() {
    await this.initDistributor();
    await this.initValues();
  },
};
</script>

<template>
  <div class="product-settings container--small">
    <ez-form
      :formKey="formKey"
      :loading-key="loadingKeyForm"
      submitType="urlencoded"
      action="/distributor/me"
      method="patch"
      @success="onSubmitSuccess"
      :transformer="transformer"
      ref="form"
    >
      <div class="product-settings__section product-settings__section--toggle">
        <div class="help-block">
          <h4 class="help-block__title custom-title">Inventory Tracking</h4>
          <p class="help-block__text mb-0">
            Activate to keep track of your inventory. You will be able to individually set stock for
            all your products.
          </p>
        </div>
        <ez-on-off
          :formKey="formKey"
          name="inventorySettings[tracking]"
          :value="values.tracking.toString()"
          :checked="values.tracking"
          @change="updateState('tracking', $event)"
        />
      </div>
      <template v-if="values.tracking">
        <hr />
        <h3 class="context-section-heading">{{ $t('global.distributor') }} View</h3>
        <div class="product-settings__section product-settings__section--expiryDays">
          <div class="help-block">
            <h4 class="help-block__title custom-title">Expiry Date Indicator</h4>
            <p class="help-block__text mb-0">
              This setting allows you to create an expiry date indicator for products which expiry
              date is close to the number of days you set here. If the value is 0 the expiry date
              indicator will be disabled.
            </p>
          </div>

          <p class="text-inline-input">
            <span>Expiry date within the next</span>
            <ez-mask-input
              :formKey="formKey"
              type="input"
              name="inventorySettings[expiryDays]"
              label=""
              :precision="0"
              :value="values.expiryDays"
              :allow-negative-value="false"
              :price-prefix="false"
              @input="updateState('expiryDays', $event)"
            />
            <span>days.</span>
          </p>
        </div>
        <hr />
        <div class="product-settings__section product-settings__section--toggle">
          <div class="help-block">
            <h4 class="help-block__title custom-title">Batch Tracking</h4>
            <p class="help-block__text mb-0">
              When enabled, Batch ID becomes a mandatory field for inventory management.
            </p>
          </div>
          <ez-on-off
            :formKey="formKey"
            name="inventorySettings[batchTracking]"
            :value="values.batchTracking.toString()"
            :checked="values.batchTracking"
            @change="updateState('batchTracking', $event)"
          />
        </div>
        <hr />
        <div class="product-settings__section">
          <div class="help-block">
            <h4 class="help-block__title custom-title">Inventory Valuation</h4>
            <p class="help-block__text mb-16">Choose how the product value is determined.</p>
          </div>
          <ez-radio-button
            :formKey="formKey"
            class="mb-16"
            value="actual_cost"
            name="inventorySettings[valuation]"
            label="Actual Cost"
            :data="values.valuation"
            @change="updateState('valuation', $event)"
          />
          <ez-radio-button
            :formKey="formKey"
            class="mb-16"
            value="fifo"
            name="inventorySettings[valuation]"
            label="First In First Out"
            :data="values.valuation"
            @change="updateState('valuation', $event)"
          />
          <ez-radio-button
            :formKey="formKey"
            class="mb-16"
            value="lifo"
            name="inventorySettings[valuation]"
            label="Last in First Out"
            :data="values.valuation"
            @change="updateState('valuation', $event)"
          />
          <ez-radio-button
            :formKey="formKey"
            value="avg_cost"
            name="inventorySettings[valuation]"
            label="Weighted Average Cost"
            :data="values.valuation"
            @change="updateState('valuation', $event)"
          />
        </div>

        <template v-if="canEditProduct">
          <hr />
          <h3 class="context-section-heading">{{ $t('global.venue') }} View</h3>
          <v-venue-inventory-settings
            :values="values"
            name-group="inventorySettings"
            @onChange="$event => updateState($event.key, $event.value)"
          />
        </template>
      </template>
      <template #buttons>
        <ez-button class="mt-24" form-type="submit" :disabled="saveDisabled">
          Save Changes
        </ez-button>
      </template>
    </ez-form>
  </div>
</template>

<style lang="scss" scoped>
.product-settings {
  &__section {
    &--toggle {
      display: flex;
      align-items: flex-start;

      .help-block {
        margin-right: 16px;
      }
    }

    &--expiryDays,
    &--lowInventory {
      :deep() .mask-input-wrapper {
        max-width: 64px;
        margin-left: 8px;
        margin-right: 8px;

        .mask-input__input {
          font-weight: normal;
        }
      }

      .text-inline-input {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    .help-block {
      margin-bottom: 0;

      &__title {
        &.custom-title {
          font-weight: 600;
        }
      }

      &__text {
        @include font-size(14px, 20px);
      }
    }

    :deep() .input-group {
      .ez-radio-button__label {
        color: $color-gray-25;
      }

      &__input {
        input:disabled::placeholder {
          color: $color-gray-6C;
        }
      }
    }

    .mask-input :deep() input {
      font-weight: 400;
    }
  }
}
</style>
