<script>
import { mapActions } from 'vuex';
import httpService from '@/api/http';
import flash from '@/components/ui/FlashMessage';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import downloadAttachment from '@/util/downloadAttachment';
import { formatDate } from '@/util/utils';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import EzButtonGroup from '@/components/ui/Button/EzButtonGroup.vue';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo.vue';
import EzLiteBadge from '@/components/ui/LiteBadge';
import EzInfoModal from '@/components/ui/Modal/EzInfoModal.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzAlert from '@/components/ui/Alert/EzAlert.vue';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EditWarehouseModal from './modals/EditWarehouseModal.vue';

/**
 * CreditNoteHeader
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'CreditNoteHeader',
  props: {
    creditNote: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    EzPremiumBadge,
    EzButtonGroup,
    EzEntityInfo,
    EzLiteBadge,
    EzInfoModal,
    EzButton,
    EzAlert,
    EditWarehouseModal,
    EzLoader,
  },
  data() {
    return {
      updatedCreditNote: {},
      supplierCy,
      loading: false,
    };
  },
  computed: {
    detailsArr() {
      return [
        {
          label: 'Credit Note No.',
          value: this.creditNote.creditNoteNumber,
          dataCy: supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TEXT__CREDIT_NOTE_NUMBER,
        },
        {
          label: 'Order No.',
          value: this.creditNote.orderNumber,
          dataCy: supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TEXT__ORDER_NUMBER,
        },
        {
          label: 'Created Date',
          value: formatDate(this.creditNote.date),
          dataCy: supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TEXT__CREATED_DATE,
        },
        {
          label: 'Warehouse',
          value: this.creditNote.warehouse?.name,
          editable: true,
          dataCy: supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TEXT__WAREHOUSE,
          btnDataCy: supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.BUTTON__EDIT_WAREHOUSE,
        },
      ].filter(({ value }) => !!value);
    },
  },
  methods: {
    ...mapActions('creditNotes', ['distributorExportCreditNotePDF']),
    showVenueInfo() {
      this.$refs.infoModal.open();
    },
    edit(label) {
      if (label === 'Warehouse') this.$refs.editWarehouseModal.open();
    },
    onEditWarehouseCancel() {
      this.$refs.editWarehouseModal.close();
    },
    onEditWarehouseSave(warehouse) {
      this.updatedCreditNote = { ...this.updatedCreditNote, warehouseId: warehouse?.id || null };
    },
    async exportPDF() {
      const { data, headers } = await this.distributorExportCreditNotePDF({
        id: this.creditNote.id,
      });
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'template.pdf';
      downloadAttachment(data, name);
    },
    async onWarningCTA(warning) {
      this.loading = true;

      try {
        await httpService.put(warning.url);
        this.loading = false;
        flash.success({ title: 'Action completed successfully!' });
        this.creditNote.warnings = this.creditNote.warnings.filter(item => item.url !== warning.url);
      } catch (e) {
        this.loading = false;
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({ title: 'Something went wrong!' });
      }
    },
  },
  watch: {
    updatedCreditNote(val) {
      this.$emit('update', val);
    },
  },
};
</script>

<template>
  <div class="credit-note__header">
    <ez-alert v-if="editMode" variant="disclaimer" customClass="edit-mode-alert">
      <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
      <template #title>Edit mode active</template>
      <template>Edit the existing products in this credit note.</template>
    </ez-alert>

    <ez-alert
      v-else
      v-for="(warning, idx) in creditNote.warnings"
      :key="idx"
      size="big"
      variant="disclaimer"
      customClass="warning-alert"
    >
      <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
      <template>
        {{ warning.text }}
        <ez-button
          v-if="warning.cta"
          type="blue-link"
          @click="onWarningCTA(warning)"
          class="warning-alert__cta"
          :disabled="loading"
        >
          {{ warning.cta }}
        </ez-button>
      </template>
    </ez-alert>

    <div class="u-flex-space">
      <div class="customer-info">
        <span class="customer-info__label">{{ $t('global.venue') }}</span>
        <ez-entity-info
          imgWidth="2rem"
          imgHeight="2rem"
          imgBorderRadius="50%"
          :imgUrl="creditNote.venue.logo"
          class="customer-info__details"
        >
          <span
            :title="creditNote.venue.name"
            class="customer-name"
            :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TEXT__OUTLET_NAME"
          >
            {{ creditNote.venue.name }}
            <ez-premium-badge v-if="$helpers.isPremium(creditNote.venue.accountType)" />
            <ez-lite-badge v-if="$helpers.isLite(creditNote.venue.accountType)" />
          </span>
        </ez-entity-info>
      </div>
      <ez-button-group class="ml-12">
        <ez-button
          type="secondary"
          @click="exportPDF"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.BUTTON__EXPORT_PDF"
        >
          <span>Export PDF</span>
        </ez-button>
        <ez-button
          type="secondary"
          @click="showVenueInfo"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.BUTTON__CUSTOMER_INFO"
        >
          <font-awesome-icon icon="info-circle" />
          <span>{{ $t('global.venue') }} Info</span>
        </ez-button>
      </ez-button-group>
    </div>

    <hr />

    <div class="details-row">
      <div
        class="details"
        v-for="{ label, value, editable, dataCy, btnDataCy } in detailsArr"
        :key="value"
      >
        <div class="details__label">
          {{ label }}
          <ez-button
            v-if="editMode && editable"
            type="link"
            @click="edit(label)"
            class="details__edit"
            :dataCy="btnDataCy"
          >
            <font-awesome-icon
              icon="pen"
              v-tooltip="{ content: `Edit ${label}`, classes: ['tooltip--reset-margin mb-8'] }"
            />
          </ez-button>
        </div>
        <span class="details__value" :data-cy="dataCy">{{ value }}</span>
      </div>
    </div>

    <ez-info-modal
      ref="infoModal"
      v-if="creditNote.customerInfo"
      :data="creditNote.customerInfo"
      :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.CUSTOMER_INFO_MODAL"
    >
      <template #title>{{ $t('global.venue') }} Info</template>
    </ez-info-modal>

    <edit-warehouse-modal
      ref="editWarehouseModal"
      :creditNote="creditNote"
      @cancel="onEditWarehouseCancel"
      @save="onEditWarehouseSave"
    />
    <ez-loader :show="loading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped>
.credit-note__header {
  padding: 0 $spacing-24;

  :deep() .edit-mode-alert {
    align-items: flex-start;
    margin: 0 0 16px;
    height: auto;
  }

  :deep() .warning-alert {
    &__cta {
      display: block;
      padding: 0;
      height: 25px;
    }
  }

  :deep() .modal {
    color: $color-gray-25;
  }

  .customer-info {
    &__label {
      display: block;
      margin-bottom: 4px;
      @include font-size(12px, 18px);
      color: $color-gray-6C;
      font-weight: 500;
    }

    &__details {
      .customer-name {
        @include font-size(14px, 22px);
        color: $color-gray-25;
        font-weight: 600;
      }
    }
  }

  .details-row {
    display: flex;
    border-radius: 3px;
    padding: 8px 12px;
    background-color: $color-gray-F5;

    .details {
      flex: 1 1 0;
      text-align: center;

      & + .details {
        border-left: 1px solid $color-gray-E1;
      }

      &__label {
        @extend %gray-label;
        @include font-size(12px, 18px);
      }

      &__edit {
        margin-left: $spacing-04;
        padding: 0;
        height: 18px;
        width: 18px;
      }

      &__value {
        margin-top: 2px;
        @include font-size(14px);
        color: $color-gray-25;
      }
    }
  }

  :deep() .loader__text {
    color: $color-gray-25;
  }
}
</style>
