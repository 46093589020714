<script>
/**
 *
 * @version 1.0.0
 * @since
 */
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
  addActivityAction,
  addExpenseAction,
  completeToDoAction,
  ALL_ACCOUNT_REPS,
  LOADING_KEY,
  PENDING_ACCOUNT_OPENING,
  resendInviteAction,
  sendConnectionReqAction,
  sendOnboardingAction,
  TARGET_ACCOUNT, toDosMap,
} from '@/util/constants';
import AccountDrawerData from '@/views/platform/distributor/crm/AccountDrawerData.model';
import {
  falsy,
  formatContactInfoForOnboardingInfo,
  formatContactInfoForVenue,
  formatErrorMessageFromRes,
  formatPrice,
  groupedListBySegments,
  isLite,
  isPremium,
} from '@/util/utils';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader/EzLoader';
import AccountDrawer from '@/views/platform/distributor/crm/AccountDrawer';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import VAccountOwnerEntityInfo from '@/components/v3/patterns/VAccountOwnerEntityInfo';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import EzTable from '@/components/ui/Table/EzTable';
import EzButton from '@/components/ui/Button/EzButton';
import EzDropDown from '@/components/ui/Dropdown/EzDropdown';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import AddToDoModal from '@/views/platform/distributor/crm/to-dos/AddToDoModal';
import OnboardingLinkModal from '@/views/platform/distributor/outlets/OnboardingLinkModal';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import ConnectionRequestModal from '@/views/platform/distributor/outlets/ConnectionRequestModal';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';

export default {
  name: 'To-dos',
  components: {
    EzSpinner,
    ConnectionRequestModal,
    EzConfirmationModal,
    OnboardingLinkModal,
    AddToDoModal,
    VSelectSearch,
    EzButton,
    EzTable,
    VAccountOwnerEntityInfo,
    VVenueEntityInfo,
    EzLoadMore,
    EmptyState,
    AccountDrawer,
    EzLoader,
    EzDropDown,
    EzTextarea,
  },
  props: {
    singleId: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      routeNameSingle: 'distributor-to-dos-single',
      routeName: 'distributor-to-dos',
      list: [],
      meta: {},
      range: null,
      rangeName: '',
      filterVenue: null,
      venuesOptions: [],
      accountOwnerOptions: [],
      allAccounts: ALL_ACCOUNT_REPS,
      filtersVals: {
        types: null,
        accountOwnerId: null,
        venueId: null,
      },
      singleView: new AccountDrawerData({}),
      toDoId: null,
      invitationId: null,
      isLoaderShown: false,
      loadingKeyMore: 'fetch-list-more',
      loadingKeyMoreOrders: 'fetch-orders-more',
      completeNote: null,
      supplierCy,
    };
  },
  computed: {
    ...mapState('reports', ['filtersToDos', 'metaOrders']),
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    ...mapState('entities/users', ['loggedUser']),
    isLoadingMoreOrders() {
      return this.isSomeLoading([
        this.loadingKeyMoreOrders,
      ]);
    },
    isLoadingMore() {
      return this.isSomeLoading([
        this.loadingKeyMore,
      ]);
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_TO_DO_SINGLE,
        LOADING_KEY.FETCH_TO_DO_ACCOUNT_REPS_OPTIONS,
        LOADING_KEY.FETCH_TO_DO_VENUES,
        LOADING_KEY.FETCH_TO_DO_LIST,
        LOADING_KEY.DELETE_TO_DO,
        LOADING_KEY.FETCH_ORDER_HISTORY,
      ]);
    },
    onboardingAction() {
      return `/distributor/invitations/${this.invitationId}/resend`;
    },
    canDeleteToDo() {
      return this.$permission.has('createTodos');
    },
    onboardingData() {
      const venue = this.singleView && this.singleView.venue;
      return venue && (venue.onboardingInfo || venue);
    },
    groupedDict() {
      return groupedListBySegments({
        list: this.list,
        segments: toDosMap,
      });
    },
    hasResults() {
      return !Object.keys(this.groupedDict).every(group => !this.groupedDict[group].items.length);
    },
    loggedUserId() {
      return this.loggedUser.id;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', [
      'UPDATE_CHECK_IN_INFO',
      'UPDATE_EXPENSE_INFO',
    ]),
    ...mapMutations('reports', [
      'UPDATE_FILTERS_TO_DOS',
      'SET_META_DATA',
      'SET_META_DATA_ORDERS',
      'CLEAR_META_DATA_ORDERS',
    ]),
    ...mapActions('reports', [
      'fetchToDoList',
      'fetchToDoVenues',
      'fetchToDoSingle',
      'fetchToDoAccountRepsOptions',
      'fetchOrderHistory',
      'deleteToDo',
    ]),
    ...mapMutations('entities/venues', ['SET_ONBOARDING_LINK_DATA']),
    ...mapActions('entities/users', ['initDistributor']),
    ...mapActions('entities/distributors', ['createCheckIn']),
    getSingeViewActions({ venue = {}, canResend }) {
      const { accountType } = venue;
      if ((isPremium(accountType) || isLite(accountType)) && this.isTargetAccount(venue)) {
        return [addActivityAction, addExpenseAction, completeToDoAction, sendConnectionReqAction];
      }
      if (this.isTargetAccount(venue)) {
        return [addActivityAction, addExpenseAction, completeToDoAction, sendOnboardingAction];
      }
      if (canResend) {
        return [addActivityAction, addExpenseAction, completeToDoAction, resendInviteAction];
      }
      return [addActivityAction, addExpenseAction, completeToDoAction];
    },
    openConnectionReqModal() {
      this.$refs.connectionReqModal.open();
    },
    openDeleteModal(id) {
      this.toDoId = id;
      this.$refs.confirmationModal.open();
    },
    openCompleteModal() {
      this.$refs.completeToDo.open();
    },
    closeCompleteModal() {
      this.$refs.completeToDo.close();
    },
    onCloseComplete() {
      this.completeNote = null;
    },
    closeDeleteModal() {
      this.$refs.confirmationModal.close();
    },
    async deleteTodoCard() {
      this.closeDeleteModal();
      this.$refs.accountDrawer.close();

      await Promise.all([
        this.deleteToDo({ id: this.toDoId }),
        this.refreshComponentState(),
      ]);

      flash.success({
        title: 'To-do removed!',
        message: 'You have successfully removed this to-do.',
      });
    },
    async completeTodoCard() {
      this.$refs.accountDrawer.close();

      const data = new FormData();
      const dateTime = new Date();

      data.append('type', 'todo');
      data.append('todoId', this.toDoId);
      data.append('createdAt', dateTime.toISOString());
      if (this.completeNote) {
        data.append('note', this.completeNote);
      }

      await Promise.all([
        this.createCheckIn({ data }),
      ]);

      await this.refreshComponentState();
      await this.initDistributor();
      this.closeCompleteModal();

      flash.success({
        title: 'To-do completed!',
        message: 'You have successfully completed this to-do.',
      });
    },
    async refreshComponentState() {
      this.meta = {};
      [this.list, this.venuesOptions, this.accountOwnerOptions] = await Promise.all([
        this.fetchList(),
        this.fetchVenueOptions(),
        this.fetchUsers(),
      ]);
      this.accountOwnerOptions = [this.allAccounts, ...this.accountOwnerOptions];
    },
    async onLoadMoreOrders(venueId) {
      const currentOrders = this.singleView.orders;
      const loadingKey = this.loadingKeyMoreOrders;
      const orders = await this.fetchOrders({ venueId, loadingKey });
      const orderHistoryList = [...currentOrders, ...orders];

      this.singleView = { ...this.singleView, orders: orderHistoryList };
    },
    async onSuccessConnectionReq() {
      this.$refs.connectionReqModal.close();
      this.$refs.accountDrawer.close();

      await this.refreshComponentState();

      flash.success({
        title: 'Your action was successful!',
      });
    },
    async onSuccessOnboarding(res) {
      this.$refs.onboardingModal.close();
      const { data } = res;
      const { venueInfo, onboardingInfo } = data;

      let contactInfoData = formatContactInfoForOnboardingInfo(onboardingInfo);
      contactInfoData = [...formatContactInfoForVenue(venueInfo), ...contactInfoData];

      this.singleView.venue = { ...this.singleView.venue, ...venueInfo };
      this.singleView = {
        ...this.singleView,
        contactInfoData,
      };
      await this.refreshComponentState();

      flash.success({
        title: 'Your action was successful!',
      });
    },
    addToDo() {
      this.$refs.addToDoModal.open();
    },
    async onAddToDoSuccess(res) {
      this.meta = {};

      await this.refreshComponentState();

      const {
        data: {
          data: { id, accountOwner },
        },
      } = res;
      const { href } = this.$router.resolve({ name: this.routeNameSingle, params: { id } });
      const { name } = accountOwner;

      flash.success({
        title: 'To-do successfully added!',
        message: `You have assigned this to-do to ${name}.`,
        ctas: `<a href="${href}">View To-Do Details</a>`,
      });
    },
    onAddToDoError(res) {
      const message = formatErrorMessageFromRes(res);
      flash.error({
        title: 'Unsuccessful action.',
        message,
        delay: 5000,
      });
    },
    addActivity() {
      const todoId = this.toDoId;
      const { venue } = this.singleView;
      const disableVenueChange = true;
      const isVenueAssigned = !falsy(venue);

      this.UPDATE_CHECK_IN_INFO({ disableVenueChange, todoId, venue, isVenueAssigned });
      this.$router.push({ name: 'account-check-in-new' });
    },
    addExpense() {
      const todoId = this.toDoId;
      this.UPDATE_EXPENSE_INFO({ todoId });
      this.$router.push({ name: 'account-expense-new' });
    },
    sendOnboardingLink() {
      const { venue } = this.singleView;
      const { id } = venue;
      this.SET_ONBOARDING_LINK_DATA({
        route: 'distributor-to-dos',
        name: venue.name,
        id,
        invitationId: venue.invitationId,
      });
      this.$router.push({ name: 'distributor-outlets-send-onboarding-link' });
    },
    setRouteSingle(id) {
      if (this.$route.name === this.routeNameSingle) {
        return;
      }

      this.$router.replace({
        name: this.routeNameSingle,
        params: { id },
      });
    },
    setRouteList() {
      this.$router.replace({
        name: this.routeName,
      });
    },
    async openSingleView(toDo) {
      this.CLEAR_META_DATA_ORDERS();
      const { id } = toDo;
      this.toDoId = id;

      this.isLoaderShown = true;

      const { data: { data } } = await this.fetchToDoSingle({ id });
      const venueId = data?.venue?.id;
      let orders;
      if (venueId) {
        orders = await this.fetchOrders({ venueId });
      }

      this.isLoaderShown = false;

      const { venue } = data;
      const hasVenue = !!Object.keys(venue || {}).length;
      this.invitationId = venue?.invitationId;

      const dueDate = {
        isDue: data.isDue,
        isPastDue: data.isPastDue,
        dueDate: data.dueDate,
      };

      const actions = this.getSingeViewActions({ venue: venue || {}, canResend: data.canResend });

      this.singleView = new AccountDrawerData({
        ...({ venue } || {}),
        disableRedirectByVenue: this.isTargetAccount(venue || {})
          || this.isPendingAccount(venue || {}),
        badge: venue?.accountActivityStatus,
        accountOwner: data.accountOwner,
        dateLabel: 'Date Created',
        dateTime: data.createdAt,
        noteTitle: 'Note',
        note: data.note,
        contentTitle: 'Account History',
        icon: { text: 'To-Do Info', icon: 'tasks' },
        dueDate,
        orders,
        totalLabel: 'Total',
        total: data.amount,
        taxIncluded: data.taxIncluded,
        contactInfoCopy: `${this.$t('global.venue')} Info`,
        contactInfoData: (this.isTargetAccount(venue || {}) || !hasVenue) ? null
          : formatContactInfoForVenue(venue || {}),
        actions,
      });
      const { note, invoices } = this.singleView;
      this.singleView.contentTitle = note || invoices ? 'Additional Details' : null;

      this.setRouteSingle(id);
      this.$refs.accountDrawer.openDrawer();
    },
    async onLoadMore() {
      const list = await this.fetchList({ loadingKey: this.loadingKeyMore });
      this.list = [...this.list, ...list];
    },
    async selectAccountOwner(accountOwner) {
      const { id } = accountOwner;
      this.UPDATE_FILTERS_TO_DOS({ accountOwnerId: id });
      this.filtersVals = {
        ...this.filtersVals,
        accountOwnerId: id,
      };
      this.meta = {};
      this.list = await this.fetchList();
    },
    async selectVenue(data) {
      this.filterVenue = data;
      this.UPDATE_FILTERS_TO_DOS({ venue: data.id ? data : null });
      this.filtersVals = {
        ...this.filtersVals,
        venueId: data ? data.id : null,
      };

      this.meta = {};
      this.list = await this.fetchList();
    },
    onRangeNameChange(rangeName) {
      this.rangeName = rangeName;
      this.UPDATE_FILTERS_TO_DOS({ rangeName });
    },
    async fetchVenueOptions() {
      const { data } = await this.fetchToDoVenues();
      return data.data;
    },
    async fetchOrders({ venueId, loadingKey = null }) {
      const { nextId, nextValue } = this.metaOrders;

      const { data: { data, meta } } = await this.fetchOrderHistory({
        venueId,
        nextId,
        nextValue,
        ...(loadingKey && { loadingKey }),
      });

      this.SET_META_DATA_ORDERS({ ...meta });
      return data;
    },
    async fetchUsers() {
      try {
        const { data } = await this.fetchToDoAccountRepsOptions();
        return data.data;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        flash.error({
          title: `Something went wrong with fetching options for ${this.$t('global.accountReps')}.`,
        });
        return [];
      }
    },
    async fetchList({ loadingKey = null } = {}) {
      const { nextId, nextValue } = this.meta;
      const res = await this.fetchToDoList({
        filters: this.filtersVals,
        nextId,
        nextValue,
        ...(loadingKey ? { loadingKey } : null),
      });
      const { data, meta } = res.data;

      this.meta = meta;

      this.SET_META_DATA({
        subtitle: `${meta.totalCount} to-dos`,
        label: 'TOTAL MONEY SPENT',
        count: Number.isFinite(meta.totalAmount) ? formatPrice(meta.totalAmount) : null,
      });

      return data;
    },
    isTargetAccount(venue) {
      return TARGET_ACCOUNT === venue.accountActivityStatus;
    },
    isPendingAccount(venue) {
      return PENDING_ACCOUNT_OPENING === venue.accountActivityStatus;
    },
    updateNote(note) {
      this.completeNote = note;
    },
  },
  async created() {
    const { range, rangeName, accountOwnerId } = this.filtersToDos;

    this.range = range;
    this.rangeName = rangeName;

    this.filtersVals = {
      ...this.filtersVals,
      accountOwnerId,
    };

    [this.accountOwnerOptions, this.venuesOptions, this.list] = await Promise.all(([
      this.fetchUsers(),
      this.fetchVenueOptions(),
      this.fetchList(),
    ]));
    this.accountOwnerOptions = [this.allAccounts, ...this.accountOwnerOptions];

    const { singleId } = this;
    if (Number.isFinite(singleId)) {
      this.openSingleView({ id: singleId });
    }
  },
};
</script>

<template>
  <div>
    <div class="to-dos__controls mt-24">
      <div class="to-dos__filters">
        <ez-drop-down
          v-if="$permission.has('seeCRMForAll')"
          :placeholder="allAccounts.name"
          :data="accountOwnerOptions"
          :selected="filtersVals.accountOwnerId"
          name="accountOwnerId"
          @change="selectAccountOwner"
          :data-cy="supplierCy.CRM.TO_DOS.BUTTON__SELECT_ACCOUNT_REP"
        />

        <v-select-search
          v-if="venuesOptions.length"
          :data="venuesOptions"
          value-property="id"
          :placeholder="`All ${$t('global.venues')}`"
          :searchPlaceholder="`Select ${$t('global.venue')}`"
          :selected="filterVenue"
          @selected="selectVenue"
          align-left
          :data-cy="supplierCy.CRM.TO_DOS.BUTTON__SELECT_OUTLET"
        >
          <template #result="{result}">
            <v-venue-entity-info :venue="result" />
          </template>
        </v-select-search>
      </div>

      <div class="to-dos__actions">
        <ez-button @click="addToDo" :data-cy="supplierCy.CRM.TO_DOS.BUTTON__ADD_TODO">
          Add To-Do
        </ez-button>
      </div>
    </div>
    <div class="account-check-in__table" v-if="hasResults">
      <div v-for="(group, key) in groupedDict" :key="key">
        <div v-if="group.items.length">
          <h3 class="to-dos__group-title mt-24 mb-16">
            {{ group.title }}
          </h3>
          <ez-table
            :data="group.items"
            :columns="['account', 'outlet', 'dateDue', 'date', 'note']"
            :headers="{
              account: () => $t('global.accountRep'),
              outlet: () => $t('global.venue'),
              dateDue: () => 'Due Date',
              date: () => 'Date Created',
              note: () => 'Note',
            }"
            :column-props="{
            account: { class: 'xxl-cell' },
              amount: { class: 'price-cell' },
              date: { class: 'date-cell' },
              dateDue: { class: 'date-cell' },
              note: { class: 'xxl-cell' },
            }"
            @rowClick="openSingleView"
          >
            <template #cell-account="{ row }">
              <v-account-owner-entity-info
                :account-owner="row.accountOwner"
                :data-cy="`${supplierCy.CRM.TO_DOS.TEXT__ACCOUNT_REP}-${key}-${row.id}`"
              />
            </template>
            <template #cell-outlet="{ row }">
              <v-venue-entity-info
                v-if="row.venue"
                :venue="row.venue"
                :data-cy="`${supplierCy.CRM.TO_DOS.TEXT__OUTLET}-${key}-${row.id}`"
              />
              <span
                v-else
                :data-cy="`${supplierCy.CRM.TO_DOS.TEXT__OUTLET}-${key}-notAssigned`"
              >
                No {{ $t('global.venue') }} Assigned
              </span>
            </template>
            <template #cell-date="{ row }">
              <span
                :data-cy="`${supplierCy.CRM.TO_DOS.TEXT__DATE_CREATED}-${key}-${row.id}`"
              >
                {{ row.createdAt | date }}
              </span>
            </template>
            <template #cell-dateDue="{ row }">
              <span
                :class="[{ 'due-date': row.isDue }, { 'due-date-past': row.isPastDue }]"
                :data-cy="`${supplierCy.CRM.TO_DOS.TEXT__DUE_DATE}-${key}-${row.id}`"
              >
                {{ row.dueDate | date }}
              </span>
            </template>
            <template #cell-note="{ row }">
              <span :data-cy="`${supplierCy.CRM.TO_DOS.TEXT__NOTE}-${key}-${row.id}`">
                {{ row.note }}
              </span>
            </template>
          </ez-table>
        </div>
      </div>

      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner/>
      </div>

      <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="onLoadMore" />
    </div>

    <empty-state class="mt-48" v-else>
      <template #badge><img src="@/assets/no-products.svg" alt=""/></template>
      <template #title>No to-dos for selected filters.</template>
      <template #info>Try with different filtering options.</template>
    </empty-state>

    <account-drawer
      ref="accountDrawer"
      :title="singleView.title"
      :badge="singleView.badge"
      :venue="singleView.venue"
      :disable-redirect-by-venue="singleView.disableRedirectByVenue"
      :date-label="singleView.dateLabel"
      :date-time="singleView.dateTime"
      :account-owner="singleView.accountOwner"
      :products="singleView.products"
      :orders="singleView.orders"
      :next-id-orders="metaOrders.nextId"
      :content-title="singleView.contentTitle"
      :note-title="singleView.noteTitle"
      :note="singleView.note"
      :invoices="singleView.invoices"
      :contact-info-data="singleView.contactInfoData"
      :contact-info-copy="singleView.contactInfoCopy"
      :total="singleView.total"
      :total-label="singleView.totalLabel"
      :tax-included="singleView.taxIncluded"
      :actions="singleView.actions"
      :delete-enabled="canDeleteToDo"
      :is-loading-more-orders="isLoadingMoreOrders"
      :due-date="singleView.dueDate"
      :logo-icon="singleView.icon"
      :data-cy="supplierCy.CRM.TO_DOS.SINGLE_TO_DO"
      @deletePending="openDeleteModal(toDoId)"
      @sendOnboardingLink="sendOnboardingLink"
      @resendInvite="sendOnboardingLink"
      @addActivity="addActivity"
      @addExpense="addExpense"
      @sendConnectionReq="openConnectionReqModal"
      @onClose="setRouteList"
      @loadMoreOrders="onLoadMoreOrders"
      @completeToDo="openCompleteModal"
    />

    <ez-loader :show="isLoaderShown || isLoading">Loading...</ez-loader>
    <add-to-do-modal
      ref="addToDoModal"
      :prefilled-data="{ accountOwnerId: loggedUserId }"
      @submitSuccess="onAddToDoSuccess"
      @submitError="onAddToDoError"
    />
    <onboarding-link-modal
      ref="onboardingModal"
      :action="onboardingAction"
      method="put"
      :data="onboardingData"
      :names-to-disable="['name']"
      :send-disabled="true"
      @success="onSuccessOnboarding"
    />
    <connection-request-modal
      ref="connectionReqModal"
      :action="onboardingAction"
      method="put"
      :data="onboardingData"
      :names-to-disable="['name']"
      :send-disabled="true"
      @success="onSuccessConnectionReq"
    />
    <ez-confirmation-modal ref="confirmationModal" type="red">
      <template #title>Remove this To-Do?</template>
      <template #content>
        <p>
          You are about to remove this to-do from the list. <br />
          The account representative it was assigned to won’t see it anymore.
        </p>
      </template>
      <template #footer>
        <ez-button
          @click="closeDeleteModal"
          type="link"
          :data-cy="supplierCy.CRM.TO_DOS.REMOVE_TO_DO_MODAL.BUTTON__CANCEL"
        >
          Cancel
        </ez-button>
        <ez-button
          @click="deleteTodoCard"
          type="red"
          :data-cy="supplierCy.CRM.TO_DOS.REMOVE_TO_DO_MODAL.BUTTON__REMOVE"
        >
          Remove To-Do
        </ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-confirmation-modal @close="onCloseComplete" ref="completeToDo" icon="question">
      <template #title>Complete To-Do?</template>
      <template #content>
        <p>Before completing this to-do make sure you've logged everything needed.</p>
        <ez-textarea
          :value="completeNote"
          form-key="note"
          @onChange="updateNote"
          placeholder="Leave a Note"
          name="note"
          label="Note"
          :data-cy="supplierCy.CRM.TO_DOS.COMPLETE_TO_DO_MODAL.TEXTAREA__NOTE"
        />
      </template>
      <template #footer>
        <ez-button
          type="link"
          @click="closeCompleteModal"
          :data-cy="supplierCy.CRM.TO_DOS.COMPLETE_TO_DO_MODAL.BUTTON__BACK"
        >
          Back
        </ez-button>
        <ez-button
          @click="completeTodoCard"
          :data-cy="supplierCy.CRM.TO_DOS.COMPLETE_TO_DO_MODAL.BUTTON__COMPLETE"
        >
          Yes, Complete To-Do
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<style lang="scss" scoped>
:deep() .invoice {
  padding: 0;
}

:deep() .select-search {
  color: $color-gray-6C;

  &__list {
    max-height: 244px;
  }
}

.to-dos__group-title {
  @include font-size(18px, 24px);
}

.to-dos__controls {
  @extend %flex-center;
  justify-content: space-between;
}

.to-dos__filters {
  @extend %flex-center;
  & > * {
    margin-right: 16px;
  }
}

.to-dos__actions {
  @extend %flex-center;
  & > * {
    margin-left: 16px;
  }
}

.due-date {
  color: $color-primary-blue;
}
.due-date-past {
  color: $color-primary-red;
}
</style>
