<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { EzSelectedVenue } from '@/components/ui/SelectedItem';
import EzAutocomplete from '@/components/ui/Autocomplete/EzAutocomplete.vue';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzOnOff from '@/components/ui/OnOff/EzOnOff.vue';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo/index.vue';

import { NEW_CREDIT_NOTE_STEP_3 } from './steps';

/**
 * CreditInfo
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'CreditInfo',
  components: {
    EzSelectedVenue,
    EzAutocomplete,
    EzMaskInput,
    EzButton,
    EzOnOff,
    VVenueEntityInfo,
  },
  data() {
    return {
      isNonStockCredit: false,
      nonStockCredit: 0,
      supplierCy,
      nonStockCreditError: false,
    };
  },
  computed: {
    ...mapState('creditNotes', ['draft']),
    ...mapGetters('creditNotes', {
      selectedVenue: 'getDraftVenue',
      selectedProducts: 'getDraftProducts',
      fromOrder: 'getIsFromOrder',
      isTotalCreditAmountExceeded: 'getTotalCreditNoteAmountInvalid',
    }),
    hasSelectedVenue() {
      return !!this.selectedVenue && Object.keys(this.selectedVenue || {}).length > 0;
    },
    isNonStockSelectedAndValid() {
      return this.isNonStockCredit && !!this.nonStockCredit;
    },
    isGoToOverviewEnabled() {
      if (this.isTotalCreditAmountExceeded) return false;
      return (this.hasSelectedVenue && this.isNonStockSelectedAndValid) || !!this.selectedProducts.length;
    },
  },
  methods: {
    ...mapMutations('creditNotes', [
      'SET_DRAFT_VENUE',
      'CLEAR_DRAFT_VENUE',
      'SET_DRAFT_NON_STOCK_CREDIT_VALUE',
      'CLEAR_DRAFT_NON_STOCK_CREDIT_VALUE',
      'CLEAR_DRAFT_ORDER',
    ]),
    onVenueSelect(venue) {
      this.SET_DRAFT_VENUE(venue.selected);
    },
    removeSelectedVenue() {
      this.disableNextStep();
      this.CLEAR_DRAFT_VENUE();
    },
    toggleNonStockCredit(ev) {
      this.isNonStockCredit = ev;
      if (!ev) this.CLEAR_DRAFT_NON_STOCK_CREDIT_VALUE();
    },
    onNonStockCreditValueChange(ev) {
      this.nonStockCredit = ev;
      this.SET_DRAFT_NON_STOCK_CREDIT_VALUE(this.nonStockCredit);
    },
    onNextStep() {
      this.updateDraftState();
      this.$emit('stepCompleted');
    },
    onGoToStep() {
      this.updateDraftState();
      this.$emit('stepBack', NEW_CREDIT_NOTE_STEP_3);
    },
    updateDraftState() {
      if (!this.isNonStockCredit) this.CLEAR_DRAFT_NON_STOCK_CREDIT_VALUE();
      else this.SET_DRAFT_NON_STOCK_CREDIT_VALUE(this.nonStockCredit);
    },
    createStandaloneCreditNote() {
      this.CLEAR_DRAFT_ORDER();
    },
  },
  created() {
    this.isNonStockCredit = !!this.draft.nonStockCredit;
    this.nonStockCredit = this.draft.nonStockCredit ?? 0;
  },
  watch: {
    isGoToOverviewEnabled: {
      immediate: true,
      handler(val) {
        if (val) this.enableNextStep();
        else this.disableNextStep();
      },
    },
  },
};
</script>

<template>
  <div>
    <ez-autocomplete
      v-if="!hasSelectedVenue"
      ref="autocomplete"
      source="/distributor/search/venues"
      :requestParams="{ connected: true }"
      searchProperty="name"
      @selected="onVenueSelect"
      :label="$t('global.venue')"
      :placeholder="`Enter ${$t('global.venue')} name`"
      :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.CREDIT_INFO.INPUT__CUSTOMER_SEARCH"
      :result-data-cy="
        supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.CREDIT_INFO.BUTTON__CUSTOMER_RESULT
      "
    >
      <template #result="{ result }">
        <v-venue-entity-info
          :venue="result"
          :data-cy="
            supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.CREDIT_INFO.TEXT__CUSTOMER_RESULT_NAME
          "
        />
      </template>
    </ez-autocomplete>

    <ez-selected-venue
      v-else
      @remove="removeSelectedVenue"
      :venue="selectedVenue"
      only-basic-info
      :data-cy="
        supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.CREDIT_INFO.TEXT__SELECTED_CUSTOMER_NAME
      "
      :remove-button-data-cy="
        supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.CREDIT_INFO.BUTTON__REMOVE_SELECTED_CUSTOMER
      "
      :hide-remove-button="fromOrder"
    />

    <hr />

    <div class="nonStock-toggle-wrapper">
      <div class="help-block">
        <h4 class="help-block__title custom-title">Non-stock credit</h4>
        <p class="help-block__text mb-0">If toggle on, enter total amount of the credit.</p>
      </div>
      <ez-on-off
        form-key="nonStockOnOff"
        name="nonStock"
        :checked="isNonStockCredit"
        @change="toggleNonStockCredit"
        :data-cy="
          supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.CREDIT_INFO.LABEL__NON_STOCK_CREDIT
        "
      />
    </div>

    <ez-mask-input
      v-if="isNonStockCredit"
      name="nonStockCredit"
      label="Non-stock credit*"
      class="mask-input"
      :value="nonStockCredit"
      type="input"
      :allow-negative-value="false"
      :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.CREDIT_INFO.INPUT__NON_STOCK_CREDIT"
      @input="onNonStockCreditValueChange"
    >
      <template #error>
        <span v-if="isTotalCreditAmountExceeded">
          Credit amount exceeds order value, please
          <ez-button type="red-link" class="error-link" @click="createStandaloneCreditNote"
            >create a standalone credit note</ez-button
          >.
        </span>
      </template>
    </ez-mask-input>
  </div>
</template>

<style lang="scss" scoped>
.nonStock-toggle-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mask-input {
  :deep() .mask-input__input {
    font-weight: normal;
  }
}

.error-link {
  padding: 0;
  height: auto;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: underline;
}
</style>
