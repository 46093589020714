<template>
  <div class="distributor-products">
    <ez-loader :show="isLoading">
      {{ loadingMsg }}
    </ez-loader>
    <header class="products-header">
      <div class="products-header__main">
        <h1>Products</h1>
        <span v-if="meta.term">{{ `${meta.totalCount} results for ${meta.term}` }}</span>
        <span v-else>{{ `${meta.totalCount || 0} in total` }}</span>
      </div>

      <div class="products-header__valuation" v-if="$permission.has('seeCOGS')">
        <span class="products-header__valuation__amount">
          {{ meta.inventoryValuation | price }}
        </span>
        <span class="products-header__valuation__icon">
          <span class="products-header__valuation__icon__inner">
            <font-awesome-icon
              icon="money-bill-wave"
              v-tooltip="{
                placement: 'top',
                classes: ['tooltip--reset-margin', 'm-8'],
                content: 'Inventory Value',
              }"
            />
          </span>
        </span>
      </div>
    </header>

    <ez-nav class="mt-16">
      <template #nav>
        <ul>
          <li>
            <router-link
              :to="{ name: 'distributor-products-all' }"
              :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__PRODUCTS_TAB"
            >
              {{ $t('product.allProductsTab') }}
            </router-link>
          </li>
          <li v-if="shouldShowStocktakes">
            <router-link
              :to="{ name: 'distributor-products-stocktakes' }"
              :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__STOCKTAKE_TAB"
            >
              {{ $t('product.stocktake') }}
            </router-link>
          </li>
          <li v-if="priceProposalsCount > 0">
            <router-link :to="{ name: 'distributor-products-proposal' }">
              {{ $t('product.priceProposalsTab') }}
            </router-link>
            <ez-badge type="green" :count="priceProposalsCount" />
          </li>
        </ul>
      </template>
      <router-view />
    </ez-nav>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import EzBadge from '@/components/ui/Badge';
import EzNav from '@/components/ui/Nav';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { LOADING_KEY } from '@/util/constants';

export default {
  components: {
    EzLoader,
    EzBadge,
    EzNav,
  },
  props: {
    productId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      loadingMsg: 'Loading...',
      stocktakes: [],
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/products', ['meta']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('entities/users', ['priceProposalsCount', 'isDistributor']),
    ...mapGetters('loading', ['isSomeLoading']),
    tradeGeckoRedirectUrl() {
      const { href } = this.$router.resolve({ name: 'distributor-trade-gecko' });
      return `${window.location.origin}${href}`;
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_DISTRIBUTOR_PRODUCTS,
        LOADING_KEY.DISTRIBUTOR_FETCH_AUTH_URL,
        LOADING_KEY.DISTRIBUTOR_FETCH_PROPOSED_PRICING,
        LOADING_KEY.ME,
        LOADING_KEY.DISTRIBUTOR_DOWNLOAD_PRODUCTS_CSV,
        LOADING_KEY.DISTRIBUTOR_FETCH_SINGLE_PROPOSED_PRICING,
        LOADING_KEY.DISTRIBUTOR_FETCH_INVENTORY_VALUATION,
      ]);
    },
    trackingInventory() {
      return this.loggedUser.distributor.inventorySettings.tracking;
    },
    shouldShowStocktakes() {
      if (!this.isDistributor) return false;
      if (!this.$permission.has('conductStocktake')) return false;
      if (!this.trackingInventory && !this.stocktakes.length) return false;
      return true;
    },
  },
  methods: {
    ...mapActions('entities/products', ['addTradeGeckoCode']),
    ...mapActions('entities/users', ['initDistributor']),
    ...mapActions('entities/distributors', ['distributorFetchStocktakes']),
    async fetchStocktakes() {
      const { data } = await this.distributorFetchStocktakes({ query: {} });
      this.stocktakes = [...data.data];
    },
  },
  async created() {
    await this.fetchStocktakes();

    if (this.$route.matched.some(({ name }) => name === 'distributor-trade-gecko')) {
      this.addTradeGeckoCode({
        code: this.$route.query.code,
        redirectUrl: this.tradeGeckoRedirectUrl,
      }).finally(() => {
        this.initDistributor();
        this.$router.push({ name: 'distributor-products' });
      });
    }
  },
};
</script>

<style scoped lang="scss">
:deep() .loader {
  @extend %whole-screen;
}

.products-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__main {
    h1 {
      @include font-size(24px);
      margin: 0;
      font-weight: 500;
    }
    > span {
      @include font-size(12px);
      color: $color-gray-6C;
    }
  }

  &__valuation {
    display: flex;
    align-items: center;

    &__amount {
      @include font-size(20px, 28px);
      color: $color-gray-25;
      font-weight: 600;
      margin-right: 12px;
    }

    &__icon {
      @extend %flex-center;
      justify-content: center;
      color: $color-white;
      background-color: $color-pastel-orange;
      width: 40px;
      height: 40px;
      border-radius: 50%;

      &__inner {
        @include font-size(11px);
        @extend %flex-center;
        justify-content: center;
        background-color: $color-primary-orange;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
