<script>
import { mapActions } from 'vuex';
import { camalize } from '@/util/utils';
import { distributorGetXeroTaxes } from '@/api/endpoints/xero';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button/EzButton';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzRadioButton from '@/components/ui/RadioButton/EzRadioButton';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'UpdateModal',
  components: {
    EzConfirmationModal,
    EzButton,
    EzCheckbox,
    EzRadioButton,
    VSelectSearch,
  },
  props: {
    entity: {
      type: String,
      required: false,
      default: 'Products',
    },
    categories: {
      type: Array,
      required: true,
      default: () => [],
    },
    accData: {
      type: Object,
      required: true,
      default: () => {},
    },
    showTaxRate: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPaymentTerms: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formKey: 'updateModalXero',
      categoriesCopy: [],
      isAccountChecked: {
        invoices: false,
        inventory: false,
        cogs: false,
      },
      selectedCategories: {},
      config: {},
      disabled: true,
      accountCode: '',
      cogsAccountCode: '',
      inventoryAccountCode: '',

      taxes: [],
      taxType: '',
      paymentTerms: '',
      paymentTermsData: [],
      netpaymentPeriodSelected: null,
      netPaymentPeriodValue: '',
      isTaxTypeChecked: false,
      isPaymentTermsChecked: false,
    };
  },
  computed: {
    mappedCategories() {
      return this.categoriesCopy;
    },
    invoicesAccountDisabled() {
      return !this.isAccountChecked.invoices || (this.isAccountChecked.invoices && this.accountCode === '');
    },
    cogsAccountDisabled() {
      return !this.isAccountChecked.cogs || (this.isAccountChecked.cogs && this.cogsAccountCode === '');
    },
    inventoryAccountDisabled() {
      return !this.isAccountChecked.inventory || (this.isAccountChecked.inventory && this.inventoryAccountCode === '');
    },
    taxTypeDisabled() {
      return !this.isTaxTypeChecked || (this.isTaxTypeChecked && this.taxType === '');
    },
    paymentTermsDisabled() {
      return !this.isPaymentTermsChecked || (this.isPaymentTermsChecked && this.paymentTerms === '');
    },
    restDisabled() {
      return this.disabled;
    },
    isDisabled() {
      return (
        this.invoicesAccountDisabled
        && this.inventoryAccountDisabled
        && this.cogsAccountDisabled
        && this.taxTypeDisabled
        && this.paymentTermsDisabled
        && this.restDisabled
      );
    },
    invoicesAccounts() {
      return this.accData?.invoicesAccounts;
    },
    inventoryAccounts() {
      return this.accData?.inventoryAccounts;
    },
    cogsAccounts() {
      return this.accData?.cogsAccounts;
    },
    netPaymentTerms() {
      return this.paymentTermsData?.filter(terms => terms.type === 'net') || [];
    },
    cashOnDeliveryTerm() {
      return this.paymentTermsData.find(terms => terms.type === 'cash_on_delivery');
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorGetPaymentTerms']),
    onClose() {
      this.categoriesCopy.forEach((cat) => {
        const comp = this.$refs[cat.ref];
        comp[0].reset();
        cat.checked = false;
      });
      this.isAccountChecked = {
        invoices: false,
        inventory: false,
        cogs: false,
      };
      this.$refs.invoicesAccountDropdown.reset();
      if (this.cogsAccounts) this.$refs.cogsAccountDropdown.reset();
      if (this.inventoryAccounts) this.$refs.inventoryAccountDropdown.reset();
      this.accountCode = '';
      this.cogsAccountCode = '';
      this.inventoryAccountCode = '';

      this.isTaxTypeChecked = false;
      this.isPaymentTermsChecked = false;
      this.taxType = '';
      this.paymentTerms = '';
      this.netPaymentPeriodValue = '';

      this.disabled = true;
      this.config = {};
      this.selectedCategories = {};
    },
    open() {
      this.$refs.updateModal.open();
    },
    close() {
      this.$refs.updateModal.close();
    },
    onCheckboxChange(cat, ev) {
      const category = this.categoriesCopy.find(ct => ct.trackingCategoryID === cat.trackingCategoryID);
      category.checked = ev;
      if (!ev) {
        const comp = this.$refs[category.ref];
        comp[0].reset();
      }
      this.onDropdownChange(category, null);
    },
    onDropdownChange(cat, ev) {
      this.selectedCategories = {
        ...this.selectedCategories,
        [cat.name]: ev?.name || null,
      };

      const reducedObj = Object.keys(this.selectedCategories).reduce((newObj, key) => {
        const value = this.selectedCategories[key];
        if (value) {
          newObj[key] = value;
        }
        return newObj;
      }, {});

      this.config = {
        ...this.config,
        tracking: {
          ...reducedObj,
        },
      };
      const equal = this.categoriesCopy.filter(ct => ct.checked)?.length === Object.values(this.config.tracking)?.length;
      const checkBoxLength = this.categoriesCopy.filter(ct => ct.checked)?.length === 0;
      this.disabled = !equal || checkBoxLength;
    },
    onAccountCheckboxChange(type, val) {
      this.isAccountChecked = {
        ...this.isAccountChecked,
        [type]: val,
      };

      if (val) return;

      this.$refs[`${type}AccountDropdown`].reset();

      if (type === 'invoices') {
        this.accountCode = '';
        delete this.config.accountCode;
        return;
      }

      this[`${type}AccountCode`] = '';
      delete this.config[`${type}AccountCode`];
    },
    onAccountDropdownChange(type, val) {
      if (type === 'invoices') {
        this.accountCode = val.code;
        this.config = {
          ...this.config,
          accountCode: val.code,
        };
        return;
      }

      this[`${type}AccountCode`] = val.code ?? null;
      this.config = {
        ...this.config,
        [`${type}AccountCode`]: val.code ?? null,
      };
    },
    onTaxTypeCheck(val) {
      this.isTaxTypeChecked = val;
      this.taxType = '';
    },
    onPaymentTermsCheck(val) {
      this.isPaymentTermsChecked = val;
      this.paymentTerms = val ? 'no_terms' : '';
    },
    updatePaymentTerms(type, selectedNet) {
      if (['cash_on_delivery', 'no_terms'].includes(type)) {
        this.netPaymentPeriodValue = '';
        this.paymentTerms = type;
        return;
      }

      if (!selectedNet) this.netPaymentPeriodValue = type;
      else this.netpaymentPeriodSelected = selectedNet;
      this.paymentTerms = selectedNet?.key || this.netpaymentPeriodSelected?.key || this.netPaymentTerms[0].key;
    },
    update() {
      this.$emit('update', this.config);
    },
  },
  async created() {
    this.categoriesCopy = this.categories.map(cat => ({ ...cat, checked: false, ref: camalize(cat.trackingCategoryID) }));

    /**
     * Conditionally make requests to fetch taxes and payment terms.
     * Needed because the component is shared between 'Venues' and 'Products' pages.
     */
    const [{ data: taxes }, { data: paymentTerms }] = await Promise.all([
      this.showTaxRate ? distributorGetXeroTaxes({ withDefault: true }) : Promise.resolve({ data: null }),
      this.showPaymentTerms ? this.distributorGetPaymentTerms() : Promise.resolve({ data: null }),
    ]);

    this.taxes = taxes?.data || [];
    this.paymentTermsData = paymentTerms?.data || [];
  },
  watch: {
    categories() {
      this.categoriesCopy = this.categories.map(cat => ({ ...cat, checked: false, ref: camalize(cat.trackingCategoryID) }));
    },
    paymentTerms(val) {
      if (!val) delete this.config.paymentTerms;
      else this.config = { ...this.config, paymentTerms: val };
    },
    taxType(val) {
      if (val === '') delete this.config.taxType;
      else this.config = { ...this.config, taxType: val };
    },
  },
};
</script>

<template>
  <ez-confirmation-modal @close="onClose" ref="updateModal" icon="question">
    <template #title>Update {{ entity }}?</template>
    <template #content>
      <p>Choose what information you would like to change for selected {{ entity }}.</p>
      <hr>
      <ez-checkbox
        label="Invoices Account"
        class="cursor-pointer mb-12 height-auto"
        :checked="isAccountChecked.invoices"
        @change="onAccountCheckboxChange('invoices', $event)"
      />
      <v-select-search
        ref="invoicesAccountDropdown"
        :disable="!isAccountChecked.invoices"
        class="mb-12"
        align-left
        is-full-width
        name="account"
        :formKey="formKey"
        :data="invoicesAccounts"
        value-property="code"
        placeholder="Select Account"
        searchPlaceholder="Search Account"
        :has-clear="false"
        @selected="onAccountDropdownChange('invoices', $event)"
      >
        <template #result="{result}">
          <span>
            {{ result.name }}
          </span>
        </template>
      </v-select-search>
      <template v-if="inventoryAccounts">
        <hr>
        <ez-checkbox
          label="Inventory Account"
          class="cursor-pointer mb-12 height-auto"
          :checked="isAccountChecked.inventory"
          @change="onAccountCheckboxChange('inventory', $event)"
        />
        <v-select-search
          ref="inventoryAccountDropdown"
          :disable="!isAccountChecked.inventory"
          class="mb-12"
          align-left
          :formKey="formKey"
          is-full-width
          name="inventoryAccount"
          :data="inventoryAccounts"
          value-property="code"
          placeholder="Select Account"
          searchPlaceholder="Search Account"
          @selected="onAccountDropdownChange('inventory', $event)"
        >
          <template #result="{result}">
            <span>
              {{ result.name }}
            </span>
          </template>
        </v-select-search>
      </template>
      <template v-if="cogsAccounts">
        <hr>
        <ez-checkbox
          label="COGS Account"
          class="cursor-pointer mb-12 height-auto"
          :checked="isAccountChecked.cogs"
          @change="onAccountCheckboxChange('cogs', $event)"
        />
        <v-select-search
          ref="cogsAccountDropdown"
          :disable="!isAccountChecked.cogs"
          class="mb-12"
          align-left
          :formKey="formKey"
          is-full-width
          name="cogsAccount"
          :data="cogsAccounts"
          value-property="code"
          placeholder="Select Account"
          searchPlaceholder="Search Account"
          @selected="onAccountDropdownChange('cogs', $event)"
        >
          <template #result="{result}">
            <span>
              {{ result.name }}
            </span>
          </template>
        </v-select-search>
      </template>
      <hr v-if="mappedCategories.length">
      <p class="cat-label" v-if="mappedCategories.length">Tracking Categories</p>
      <div v-for="cat in mappedCategories" :key="cat.trackingCategoryID">
        <ez-checkbox
          :label="cat.name"
          class="cursor-pointer mb-12 height-auto"
          :checked="cat.checked"
          @change="onCheckboxChange(cat, $event)"
        />
        <v-select-search
          :ref="cat.ref"
          :disable="!cat.checked"
          class="mb-12"
          align-left
          :formKey="formKey"
          is-full-width
          name="account"
          :data="cat.options"
          value-property="value"
          :placeholder="`Select ${cat.name}`"
          :searchPlaceholder="`Search ${cat.name}`"
          :has-clear="false"
          @selected="onDropdownChange(cat, $event)"
        >
          <template #result="{result}">
            <span>
              {{ result.name }}
            </span>
          </template>
        </v-select-search>
      </div>

      <template v-if="showTaxRate">
        <hr>
        <ez-checkbox
          label="Tax Rate"
          :checked="isTaxTypeChecked"
          @change="onTaxTypeCheck"
          class="cursor-pointer mb-12 height-auto"
        />
        <v-select-search
          :disable="!isTaxTypeChecked"
          align-left
          :formKey="formKey"
          is-full-width
          name="taxType"
          :data="taxes"
          value-property="taxType"
          placeholder="Select Tax Rate"
          searchPlaceholder="Search Tax Rate"
          :has-clear="false"
          @selected="taxType = $event.taxType"
        >
          <template #result="{result}">
            <span>
              {{ result.name }}
            </span>
          </template>
        </v-select-search>
      </template>

      <template v-if="showPaymentTerms">
        <hr>
        <ez-checkbox
          label="Payment Terms"
          :checked="isPaymentTermsChecked"
          @change="onPaymentTermsCheck"
          class="cursor-pointer mb-12 height-auto"
        />
        <ez-radio-button
          :formKey="formKey"
          label="No Terms"
          name="payment"
          value="no_terms"
          :data="paymentTerms"
          :disabled="!isPaymentTermsChecked"
          @change="updatePaymentTerms('no_terms')"
          class="radio-btn"
        />
        <ez-radio-button
          v-if="cashOnDeliveryTerm"
          name="payment"
          value="cash_on_delivery"
          :formKey="formKey"
          :label="cashOnDeliveryTerm.name"
          :data="paymentTerms"
          :disabled="!isPaymentTermsChecked"
          @change="updatePaymentTerms(cashOnDeliveryTerm.key)"
          class="radio-btn mt-16"
        />
        <div class="radio-info">Pay the full amount upon the delivery of the order.</div>

        <ez-radio-button
          v-if="netPaymentTerms.length"
          label="Net"
          value="net"
          name="payment"
          :formKey="formKey"
          :data="netPaymentPeriodValue"
          :disabled="!isPaymentTermsChecked"
          @change="updatePaymentTerms('net')"
          class="radio-btn mt-16"
        />
        <div class="radio-info">Pay the full amount within a certain time period.</div>

        <v-select-search
          v-if="netPaymentTerms.length"
          :disable="!isPaymentTermsChecked || netPaymentPeriodValue !== 'net'"
          class="mb-8 mt-12"
          align-left
          :formKey="formKey"
          is-full-width
          name="payment"
          label="Payment Period"
          :data="netPaymentTerms"
          value-property="key"
          placeholder="Payment Period"
          searchPlaceholder="Payment Period"
          :selected="netpaymentPeriodSelected?.key || netPaymentTerms[0].key"
          :has-clear="false"
          @selected="updatePaymentTerms('net', $event)"
        >
          <template #result="{result}">
            <span>
              {{ result.name }}
            </span>
          </template>
        </v-select-search>
        <div class="dropdown-info">
          Set how much time {{ $t('global.venue') | lowercase }} has to complete the payment for the
          issued invoice.
        </div>
      </template>
    </template>
    <template #footer>
      <ez-button @click="close" type="link">Cancel</ez-button>
      <ez-button :disabled="isDisabled" @click="update">Update {{ entity }}</ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped>
.cat-label {
  @include font-size(14px, 22px);
  color: $color-gray-25;
}

.radio-btn {
  :deep() .ez-radio-button__label {
    color: $color-gray-25;
    @include font-size(14px, 20px);
  }
}

.radio-info {
  margin-left: 20px;
  color: $color-gray-6C;
  @include font-size(12px, 18px);
}

.dropdown-info {
  color: $color-gray-6C;
  @include font-size(12px, 18px);
}

:deep() .select-search__trigger {
  span {
    color: $color-gray-6C;
  }
  svg {
    color: $color-gray-6C;
  }
  span.select-search__value {
    color: $color-gray-25;
  }
}

:deep() .select-search--disabled .select-search__trigger {
  span, svg {
    color: $input-disabled-color;
  }
}

:deep() .select-search__list li.select-search__item {
  padding: 0.625rem 1rem;
}

:deep() .select-search__list li.select-search__item.clear {
  padding: 0rem 1rem;
}
</style>
