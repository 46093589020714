<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { clone } from '@/util/utils';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

/**
 * Warehouse
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Warehouses',
  components: { VSelectSearch },
  data() {
    return {
      supplierCy,
      warehouses: [],
      warehouse: {
        from: null,
        to: null,
      },
    };
  },
  computed: {
    ...mapState('entities/products', ['inventoryManagement']),
  },
  methods: {
    ...mapMutations('entities/products', ['UPDATE_INVENTORY_MANAGEMENT_DRAFT']),
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    onNextStep() {
      this.UPDATE_INVENTORY_MANAGEMENT_DRAFT({ warehouse: clone(this.warehouse), items: [] });

      this.$emit('stepCompleted');
    },
    selected(key, val) {
      this.warehouse = { ...this.warehouse, [key]: val };
    },
    transformerFrom(data) {
      return data.filter(w => !w.isEmpty);
    },
    transformerTo(data) {
      const { fromWarehouseId } = this.inventoryManagement.params;
      return data.filter(w => w.id !== fromWarehouseId);
    },
  },
  watch: {
    warehouse: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.from && val.to) this.enableNextStep();
        else this.disableNextStep();
      },
    },
  },
  async created() {
    const { data } = await this.distributorFetchWarehouses({ withLocations: true });
    this.warehouses = clone(data.data);

    await this.$nextTick();

    const { warehouse, params } = this.inventoryManagement;

    if (Object.keys(warehouse).length) {
      this.warehouse = clone(warehouse);
    } else if (params.fromWarehouseId || params.toWarehouseId) {
      const fromWarehouse = this.warehouses.find(w => +w.id === params.fromWarehouseId);
      const toWarehouse = this.warehouses.find(w => +w.id === params.toWarehouseId);

      this.warehouse = {
        from: fromWarehouse ? clone(fromWarehouse) : null,
        to: toWarehouse ? clone(toWarehouse) : null,
      };
    }
  },
};
</script>

<template>
  <div>
    <v-select-search
      name="warehouseFrom"
      label="From Warehouse"
      placeholder="Choose Warehouse"
      :hasClear="false"
      :data="warehouses"
      :selected="warehouse.from"
      :transformer="transformerFrom"
      @selected="selected('from', $event)"
      isFullWidth
      :data-cy="supplierCy.INVENTORY_FLOWS.TRANSFER.WAREHOUSE.BUTTON__SELECT_FROM"
    />
    <v-select-search
      class="mt-16"
      name="warehouseTo"
      label="To Warehouse"
      placeholder="Choose Warehouse"
      :hasClear="false"
      :data="warehouses"
      :selected="warehouse.to"
      :transformer="transformerTo"
      @selected="selected('to', $event)"
      isFullWidth
      :data-cy="supplierCy.INVENTORY_FLOWS.TRANSFER.WAREHOUSE.BUTTON__SELECT_TO"
    />
  </div>
</template>

<style lang="scss" scoped>
:deep() .select-search {
  &__trigger span {
    @include font-size(14px, 20px);

    &:not(.select-search__value) {
      color: $color-gray-6C;
    }
  }

  &__search-wrapper {
    display: none;
  }

  &__item {
    @include font-size(14px, 20px);
  }
}
</style>
