<script>
/**
 * Xero Products
 * @version 1.0.0
 * @since
 */

import xeroGuard from '@/views/platform/distributor/xero/settings/xeroGuard';
import {
  distributorGetProducts,
  distributorGetXeroAccounts,
  distributorUpdateXeroProducts,
  supplierFetchTrackingCategories,
} from '@/api/endpoints/xero';
import ProductsSyncModal from '@/views/platform/distributor/xero/settings/ProductsSyncModal';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { LOADING_KEY, SELECT_DESELECT_ALL } from '@/util/constants';
import EzButton from '@/components/ui/Button/EzButton';
import EzTable from '@/components/ui/Table/EzTable';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import FooterForUpdate from '@/views/platform/distributor/xero/settings/FooterForUpdate';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import UpdateModal from '@/views/platform/distributor/xero/settings/UpdateModal';
import { debounce } from '@/util/utils';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';

export default {
  mixins: [xeroGuard],
  components: {
    UpdateModal,
    VDataWithInfo,
    EzLoadMore,
    EzSpinner,
    FooterForUpdate,
    EzEntityInfo,
    EzCheckbox,
    EmptyState,
    EzTable,
    EzButton,
    ProductsSyncModal,
    EzLoader,
    EzFilterList,
    EzInput,
    EzDropdown,
  },
  data() {
    return {
      syncedItems: [],
      meta: {},
      products: [],
      dynamicColumns: [],
      trackingCategories: [],
      accountsData: {},
      selected: false,
      listOfProductIds: [],
      filters: {
        term: null,
        operative: true,
      },
      operativeFilterOptions: [
        {
          id: 1,
          name: 'Operative',
        },
        {
          id: 2,
          name: 'All',
        },
      ],
      operativeId: 1,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    ...mapState('xero', ['bulkActionProducts']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_GET_SYNCED_ITEMS,
        LOADING_KEY.DISTRIBUTOR_GET_PRODUCTS,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_GET_MORE_PRODUCTS);
    },
    allColumns() {
      return [
        'name',
        ...this.dynamicColumns,
        'sales',
        'inventory',
        'cogs',
        'taxRate',
        'price',
      ];
    },
    entity() {
      return this.$t('global.products');
    },
    bulkActionProductsCount() {
      return this.selected ? this.meta.totalCount - this.listOfProductIds.length : this.listOfProductIds.length;
    },
    areAllSelected() {
      return this.bulkActionProductsCount === this.meta.totalCount;
    },
  },
  methods: {
    ...mapMutations('xero', [
      'UPDATE_BULK_ACTION_PRODUCTS',
      'CLEAR_BULK_ACTION_PRODUCTS',
    ]),
    openSyncModal() {
      this.$refs.productsSync.open();
    },
    nameCheckbox(h) {
      return h('span', { class: 'u-flex-center' }, [
        h(
          'ez-checkbox',
          {
            class: 'mr-8',
            props: {
              label: 'Product',
              indeterminate: this.bulkActionProductsCount > 0 && !this.areAllSelected,
              checked: this.areAllSelected,
            },
            on: {
              change: (event) => {
                this.selectDeselectAll(event);
              },
            },
          },
          [],
        ),
      ]);
    },
    async selectDeselectAll(selected) {
      this.selected = selected;
      this.listOfProductIds = [];
      const bulkActionProducts = {};
      this.products.forEach((prod) => {
        bulkActionProducts[prod.id] = selected;
      });

      this.UPDATE_BULK_ACTION_PRODUCTS(bulkActionProducts);
    },
    onCheckboxChange(product, checked) {
      if (this.selected !== checked) this.listOfProductIds.push({ productId: product.id });
      else this.listOfProductIds = this.listOfProductIds.filter(item => item.productId !== product.id);
      this.UPDATE_BULK_ACTION_PRODUCTS({ [product.id]: checked });
    },
    async bulkActionsForProducts(config) {
      let items;
      if (this.filters.term === null) {
        items = Object.entries(this.bulkActionProducts)
          .filter(([, value]) => value === !this.selected)
          .map(productId => ({
            productId: Number(productId[0]),
          }));
      } else {
        items = Object.entries(this.bulkActionProducts)
          .filter(([, value]) => value)
          .map(productId => ({
            productId: Number(productId[0]),
          }));
      }
      await distributorUpdateXeroProducts({
        body: {
          items,
          config,
          state: (this.selected && this.filters.term === null)
            ? SELECT_DESELECT_ALL.selectedAll
            : SELECT_DESELECT_ALL.deselectedAll,
        },
      });

      await this.refresh();

      this.$refs.updateModal.close();
    },
    isProductSelected(product) {
      return this.bulkActionProducts[product.id];
    },
    async onLoadMore(params = {}) {
      const {
        data: { data, meta },
      } = await distributorGetProducts({
        ...this.meta,
        loadingKey: LOADING_KEY.DISTRIBUTOR_GET_MORE_PRODUCTS,
        ...params,
        ...this.filters,
      });
      this.products = [...this.products, ...data];
      if (this.selected) {
        data.forEach((prod) => {
          this.UPDATE_BULK_ACTION_PRODUCTS({
            [prod.id]:
              !(this.bulkActionProducts[prod.id] !== undefined && this.bulkActionProducts[prod.id] === false),
          });
        });
      }
      this.setMeta(meta);
    },
    openUpdateModal() {
      this.$refs.updateModal.open();
    },
    setMeta(meta) {
      this.meta = meta;
      this.dynamicColumns = meta.columns;
    },
    getTrackingValue(product, col) {
      return product?.xeroConfig?.tracking?.[col] || '-';
    },
    async refresh() {
      const {
        data: { data, meta },
      } = await distributorGetProducts(this.filters);
      this.products = data;
      this.setMeta(meta);
    },
    async resetFilters() {
      Object.keys(this.filters).forEach((key) => { this.filters[key] = null; });
      this.CLEAR_BULK_ACTION_PRODUCTS();
      this.selectDeselectAll(false);
      this.operativeId = null;
      await this.refresh();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      }
      this.CLEAR_BULK_ACTION_PRODUCTS();
      this.selectDeselectAll(false);
      await this.refresh();
    }, 300),
    setOperativeFilter(item) {
      this.operativeId = item.id;
      if (item.id === 1) this.filters.operative = true;
      else this.filters.operative = false;
    },
  },
  async created() {
    await this.refresh();

    const {
      data: { data: categories },
    } = await supplierFetchTrackingCategories();
    this.trackingCategories = categories;

    const [
      { data: cogsAccounts },
      { data: invoicesAccounts },
      { data: inventoryAccounts },
    ] = await Promise.all([
      distributorGetXeroAccounts({ type: 'cogs', withDefault: true }),
      distributorGetXeroAccounts({ type: 'revenue', withDefault: true }),
      distributorGetXeroAccounts({ type: 'inventory', withDefault: true }),
    ]);

    this.accountsData = {
      cogsAccounts: cogsAccounts.data,
      invoicesAccounts: invoicesAccounts.data,
      inventoryAccounts: inventoryAccounts.data,
    };
  },
  watch: {
    bulkActionProductsCount(val) {
      window.Intercom('update', {
        hide_default_launcher: !!val,
      });
    },
  },
};
</script>
<template>
  <div>
    <div class="products__header mb-24">
      <span class="products__info-text">
        {{ products.length ? $t('xero.settings.products.infoText') : '' }}
      </span>
      <ez-button type="secondary" @click="openSyncModal">
        {{ $t('xero.settings.products.syncButtonText') }}
      </ez-button>
    </div>
    <ez-filter-list
      :filters="filters"
      @filterUpdated="updateFilters"
      @resetFilter="resetFilters"
      class="mb-24"
    >
      <ez-input
        formKey="filters"
        name="search"
        class="search"
        :placeholder="`Search for a ${$t('global.product')}`"
      >
        <template #prefix>
          <font-awesome-icon icon="search" />
        </template>
      </ez-input>
      <ez-dropdown
        ref="select"
        class="operative-filter ml-12"
        label="Operative"
        placeholder="Filter Products"
        :data="operativeFilterOptions"
        name="operative"
        is-full-width
        @change="setOperativeFilter"
        :selected="operativeId"
      />
    </ez-filter-list>
    <div>
      <ez-table
        v-if="products.length"
        :data="products"
        :columns="allColumns"
        :column-props="{
          price: {
            class: 'price-cell',
          },
        }"
        :headers="{
          name: nameCheckbox,
          sales: () => 'Invoices Account',
          inventory: () => 'Inventory Account',
          cogs: () => 'COGS Account',
        }"
        :disable-hover="true"
      >
        <template #cell-name="{ row }">
          <div class="u-flex-row u-flex-center">
            <ez-checkbox
              class="cursor-pointer mr-8"
              :key="row.id"
              :checked="isProductSelected(row)"
              @change="onCheckboxChange(row, $event)"
            />
            <ez-entity-info class="width-100" imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
              <div
                class="width-90 cursor-pointer"
                :title="row.name"
                @click="onCheckboxChange(row, !isProductSelected(row))"
              >
                <span class="product-info__name">
                  <span class="product-info">{{ row.name }}</span>
                  <img
                    v-if="row.xeroId"
                    class="product-info__name-icon ml-4"
                    src="@/assets/logo-xero.png"
                    alt="Xero Logo"
                  />
                </span>
                <span class="product-info-secondary">
                  {{ row.sku }}
                </span>
              </div>
            </ez-entity-info>
          </div>
        </template>

        <template v-for="cl in dynamicColumns" #[`cell-${cl}`]="{ row }">
          {{ getTrackingValue(row, cl) }}
        </template>

        <template #cell-sales="{ row: { xeroAccounts: { sales } } }">
          <v-data-with-info
            v-if="sales"
            :show-underline="sales.default"
            :info="sales.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ sales.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-inventory="{ row: { xeroAccounts: { inventory } } }">
          <v-data-with-info
            v-if="inventory"
            :show-underline="inventory.default"
            :info="inventory.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ inventory.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-cogs="{ row: { xeroAccounts: { cogs } } }">
          <v-data-with-info
            v-if="cogs"
            :show-underline="cogs.default"
            :info="cogs.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ cogs.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-taxRate="{ row: { xeroTax } }">
          <v-data-with-info
            v-if="xeroTax"
            :show-underline="xeroTax.default"
            :info="xeroTax.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ xeroTax.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-price="{ row: { price } }">
          {{ price | price }}
        </template>
      </ez-table>

      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner />
      </div>

      <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="onLoadMore" />
    </div>
    <template v-if="!products.length">
      <empty-state class="empty-state-center">
        <template #badge> <img src="@/assets/no-product-empty-state.svg" alt=""/></template>

        <template #title>{{ $t('xero.settings.products.emptyState.title') }}</template>
        <template #info>
          {{ $t('xero.settings.products.emptyState.info') }}
        </template>
      </empty-state>
    </template>

    <products-sync-modal ref="productsSync" @synced="refresh" />

    <ez-loader :show="isLoading">Loading...</ez-loader>

    <update-modal
      v-if="accountsData && accountsData.invoicesAccounts"
      ref="updateModal"
      :entity="entity"
      :categories="trackingCategories"
      :acc-data="accountsData"
      @update="bulkActionsForProducts"
      :show-tax-rate="true"
    />

    <footer-for-update
      v-if="bulkActionProductsCount"
      :entity="entity"
      :items-count="bulkActionProductsCount"
      @footerAction="openUpdateModal"
    />
  </div>
</template>
<style lang="scss" scoped>
.products__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.products__info-text {
  @include font-size(14px, 20px);

  width: 400px;
  font-weight: 500;
  color: $color-gray-6C;
}

.synced-items-list {
  &--header {
    margin-bottom: 25px;
    @extend %flex-space;

    span {
      @include font-size(14px);
      color: $color-gray-6C;
    }
  }

  :deep() .table {
    tbody tr td {
      &.price-cell {
        color: $color-gray-25;
      }
    }
  }
}

.product-info__name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.product-info__name-icon {
  @include size(20px);

  border-radius: 50%;
}

:deep() .ez-filter-list__items .search,
:deep() .operative-filter.input-group {
  width: 260px;
}
</style>
