<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { mapState, mapMutations, mapActions } from 'vuex';
import dayjs from 'dayjs';
import EzInput from '@/components/ui/Input';
import EzTextarea from '@/components/ui/Textarea';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import {
  DATE_INPUT_FORMAT,
  DATE_PLACEHOLDER,
  TIME_INPUT_FORMAT,
  TIME_PLACEHOLDER,
} from '@/util/constants';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';

export default {
  name: 'Info',
  mixins: [wizardListenerMixin],
  components: {
    EzInput,
    EzTextarea,
    EzDropdown,
    VDatePicker,
  },
  data() {
    return {
      type: null,
      today: dayjs(new Date()),
      date: null,
      dateObj: null,
      tomorrow: dayjs().add(1, 'day').format(DATE_INPUT_FORMAT),
      time: null,
      note: null,
      expenseTypes: [],
      formatDate: DATE_INPUT_FORMAT,
      formatTime: TIME_INPUT_FORMAT,
      datePlaceholder: DATE_PLACEHOLDER,
      timePlaceholder: TIME_PLACEHOLDER,
      isTimeValid: false,
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/distributors', ['expense']),
    timeValidators() {
      if (this.dateObj.isSame(this.today, 'day')) {
        const timeLimit = this.today.add(1, 'minute').format(this.formatTime);
        return `required|date_format:${this.formatTime}|before:${timeLimit}`;
      }
      return 'required';
    },
    hasFirstStep() {
      return !!(this.type && this.type.value && this.date && this.time) && this.isTimeValid;
    },
    hasFirstStepPrefilled() {
      return this.expense.type && this.expense.datetime;
    },
    datetime() {
      return `${this.date} ${this.time}`;
    },
    expenseTypeId() {
      return this.expense.type?.id;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', [
      'SET_EXPENSE_TYPE',
      'SET_EXPENSE_DATETIME',
      'SET_EXPENSE_NOTE',
      'CLEAR_EXPENSE_SPENT',
      'CLEAR_EXPENSE_TAX',
      'CLEAR_EXPENSE_RECEIPTS',
    ]),
    ...mapActions('reports', ['fetchExpenseTypes']),
    onValidationChangeTime(newState) {
      this.isTimeValid = newState.valid;
    },
    onNextStep() {
      this.SET_EXPENSE_TYPE(this.type);
      this.SET_EXPENSE_DATETIME(dayjs(this.datetime));
      this.SET_EXPENSE_NOTE(this.note);
      this.$emit('stepCompleted');
    },
    updateType(type) {
      this.type = type;
    },
    updateDate(date) {
      this.date = dayjs(date).format('YYYY-MM-DD');
      this.dateObj = dayjs(date);
    },
    updateTime(time) {
      this.time = time;
    },
    updateNote(note) {
      this.note = note;
    },
  },
  async created() {
    this.date = dayjs().format(this.formatDate);
    this.dateObj = dayjs();
    this.time = dayjs().format(this.formatTime);

    const { data } = await this.fetchExpenseTypes();
    this.expenseTypes = data.data.map(type => ({
      id: type.key,
      value: type.key,
      name: type.name,
    }));

    if (this.hasFirstStepPrefilled) {
      this.type = this.expense.type;
      this.date = this.expense.datetime.format(this.formatDate);
      this.time = this.expense.datetime.format(this.formatTime);
      if (this.expense.note) {
        this.note = this.expense.note;
      }
    }
  },
  watch: {
    hasFirstStep(val) {
      if (val) {
        this.enableNextStep();
      } else {
        this.disableNextStep();
      }
    },
  },
};
</script>
<template>
  <div>
    <ez-dropdown
      ref="select"
      class="expense-select mt-12"
      label="Expense Type"
      :data="expenseTypes"
      name="expenseType"
      placeholder="Select Expense Type"
      is-full-width
      @change="updateType"
      :selected="expenseTypeId"
      :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.INFO.BUTTON__EXPENSE_TYPE"
    />
    <hr />
    <div class="date-time">
      <v-date-picker
        form-key="date"
        name="date"
        label="Date"
        class="mr-12"
        :date="date"
        :placeholder="datePlaceholder"
        :max="today.format(formatDate)"
        @dateChange="updateDate"
        :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.INFO.BUTTON__DATE"
      />
      <ez-input
        form-key="time"
        @onChange="updateTime"
        label="Time"
        name="time"
        type="time"
        :value="time"
        :max="today.format(formatTime)"
        :required="true"
        :validators="timeValidators"
        @validationChange="onValidationChangeTime"
        :placeholder="timePlaceholder"
        :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.INFO.INPUT__TIME"
      />
    </div>
    <ez-textarea
      form-key="note"
      @onChange="updateNote"
      :value="note"
      placeholder="Leave a Note"
      name="note"
      label="Note"
      :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.INFO.TEXTAREA__NOTE"
    />
  </div>
</template>
<style lang="scss" scoped>
.date-time {
  margin-bottom: 12px;
  display: flex;
  :deep() .input-group {
    width: 100%;
  }

  :deep() .ez-simple-dropdown.date-picker {
    width: auto;
  }
}

:deep() .autocomplete__input {
  font-size: 14px;
}

.expense-select {
  :deep() .ez-dropdown {
    &__label {
      color: $color-gray-6C;
    }
  }
}
</style>
