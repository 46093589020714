<script>
import Page from '@/views/admin/SinglePage';
import VStatusBadge from '@/components/v3/elements/VStatusBadge';
import { mapGetters } from 'vuex';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'Index',
  components: {
    Page,
    VStatusBadge,
  },
  computed: {
    ...mapGetters('entities/users', ['isDistributorHubDocConnected']),
  },
};
</script>

<template>
  <page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'distributor-integrations' }">
        <font-awesome-icon icon="arrow-left" />
        Back to Supplier Settings
      </router-link>
    </template>
    <template #title>
      <div class="u-flex-center logo">
        <img src="@/assets/logo-hubdoc.svg" width="32" height="32" alt="Email Forwarding Logo" />
        <h2 class="m-0 ml-8 provino-title">{{ $t('hubDoc.name') }}</h2>
        <v-status-badge type="green" v-if="isDistributorHubDocConnected">Connected</v-status-badge>
        <v-status-badge type="gray" v-else>Not Connected</v-status-badge>
      </div>
    </template>

    <template #navigation>
      <ul>
        <li>
          <router-link :to="{ name: 'distributor-hub-doc-account' }"> Account Info </router-link>
        </li>
      </ul>
    </template>
  </page>
</template>

<style lang="scss" scoped>
.logo {
  .provino-title {
    @include font-size(24px);
    font-weight: 500;
  }
  img {
    border-radius: 50%;
    border: 1px solid #e1e5ed;
  }
}
</style>
