<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardEmitterMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button';
import EzWizard from '@/components/layout/Wizard';
import steps, {
  CHECK_IN_STEP_1, CHECK_IN_STEP_2, CHECK_IN_STEP_3,
} from '@/views/platform/distributor/crm/account-check-in/new-check-in/steps';
import { mapMutations, mapState } from 'vuex';
import EzLoader from '@/components/ui/Loader/EzLoader';

export default {
  name: 'Index',
  mixins: [wizardEmitterMixin],
  components: {
    EzLoader,
    EzButton,
    EzWizard,
  },
  data() {
    return {
      CHECK_IN_STEP_1,
      CHECK_IN_STEP_2,
      CHECK_IN_STEP_3,
      currentStep: 0,
      nextDisabled: true,
      steps,
      isFromToDo: null,
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/distributors', [
      'checkInInfo',
    ]),
    hasCheckInInfoVenue() {
      return Object.keys(this.checkInInfo?.venue || {}).length > 0;
    },
    withVenueCase() {
      return this.isFirstStep && this.hasCheckInInfoVenue;
    },
    hasToDoId() {
      return !!this.checkInInfo?.todoId;
    },
    withoutVenueCase() {
      return this.isFirstStep && !this.hasCheckInInfoVenue && this.hasToDoId;
    },
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      switch (true) {
        case this.withVenueCase:
          return steps[this.currentStep].pageInfo2;
        case this.withoutVenueCase:
          return steps[this.currentStep].pageInfo3;
        default:
          return steps[this.currentStep].pageInfo;
      }
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === CHECK_IN_STEP_1;
    },
    isSecondStep() {
      return this.currentStep === CHECK_IN_STEP_2;
    },
    isLastStep() {
      return this.currentStep === CHECK_IN_STEP_3;
    },
    disableNext() {
      return false;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', [
      'CLEAR_CHECK_IN_INFO',
    ]),
    onWizardExit() {
      this.CLEAR_CHECK_IN_INFO();
      if (this.isFromToDo) {
        this.$router.push({ name: 'distributor-to-dos' });
        return;
      }
      this.$router.push({ name: 'distributor-account-check-in' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= CHECK_IN_STEP_1 && step <= CHECK_IN_STEP_3) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= CHECK_IN_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= CHECK_IN_STEP_3) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
  created() {
    this.isFromToDo = this.hasToDoId;
  },
  beforeDestroy() {
    this.CLEAR_CHECK_IN_INFO();
  },
};
</script>
<template>
  <div>
    <ez-wizard
      @exit="onWizardExit"
      @back="goToPreviousStep"
    >
      <template #title>Add Activity</template>
      <template #nav>
        <ul>
          <li :class="{
          'ez-step-active': currentStep === CHECK_IN_STEP_1,
          'ez-step-completed': currentStep > CHECK_IN_STEP_1,
        }">
            1. Activity Info
          </li>
          <li :class="{
          'ez-step-active': currentStep === CHECK_IN_STEP_2,
          'ez-step-completed': currentStep > CHECK_IN_STEP_2,
        }">
            2. Expenses
          </li>
        </ul>
      </template>
      <template #actions>
        <ez-button
          type="secondary"
          class="mr-8"
          v-if="isSecondStep"
          @click="goToStep({ step: CHECK_IN_STEP_3 });"
          :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.BUTTON__SKIP_STEP"
        >
          {{ steps[CHECK_IN_STEP_2].secondCta }}
        </ez-button>
        <ez-button
          :disabled="nextDisabled"
          @click="goToNextStep"
          :data-cy="
            isFirstStep
              ? supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.BUTTON__ADD_EXPENSE
              : supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.BUTTON__NEXT_STEP
          "
        >
          {{ nextCta }}
        </ez-button>
        <ez-button
          class="ml-8"
          v-if="isFirstStep"
          :disabled="nextDisabled"
          @click="goToStep({ step: CHECK_IN_STEP_3 })"
          :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.BUTTON__NEXT_STEP"
        >
          {{ steps[CHECK_IN_STEP_2].nextCta }}
        </ez-button>
      </template>
      <template #prevStep>
        <ez-button
          v-if="currentStep > CHECK_IN_STEP_1 && currentStep < CHECK_IN_STEP_3"
          type="link"
          formType="button"
          @click="goToPreviousStep"
          :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.BUTTON__PREV_STEP"
        >
          <font-awesome-icon icon="arrow-left"/>
          <span>Back</span>
        </ez-button>
      </template>
      <template #pageTitle>{{ currentStepPageTitle }}</template>
      <template #pageInfo><span v-html="currentStepPageInfo"/></template>
      <component
        :is="currentStepComponent"
        @stepCompleted="onStepCompleted"
        @stepBack="onStepBack"
      />
    </ez-wizard>
  </div>
</template>
<style lang="scss" scoped>
</style>
