<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { LOADING_KEY } from '@/util/constants';

import flash from '@/components/ui/FlashMessage';
import EzAlert from '@/components/ui/Alert';
import EzTable from '@/components/ui/Table';
import EzButton from '@/components/ui/Button';
import StatusBadge from '@/views/common/status-badge';

import CreateWarehouseModal from './CreateWarehouseModal.vue';
import EditWarehouseModal from './EditWarehouseModal.vue';
import DeleteWarehouseModal from './DeleteWarehouseModal.vue';
/**
 * NewWarehouses
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'Warehouses',
  props: {},
  components: {
    EzAlert,
    EzTable,
    EzButton,
    StatusBadge,
    CreateWarehouseModal,
    EditWarehouseModal,
    DeleteWarehouseModal,
  },
  data() {
    return {
      supplierCy,
      warehouses: [],
      hoveredRow: null,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    ...mapState('entities/users', ['loggedUser']),
    me() {
      return this.loggedUser.distributor;
    },
    trackingOff() {
      return !this.me.inventorySettings.tracking;
    },
    hasWarehouses() {
      return this.warehouses.length;
    },
    loading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_WAREHOUSES,
        LOADING_KEY.DISTRIBUTOR_DELETE_WAREHOUSE,
      ]);
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'distributorFetchWarehouses',
      'distributorDeleteWarehouse',
    ]),
    openCreateModal() {
      this.$refs.createModal.open(!this.hasWarehouses);
    },
    editWarehouse(row) {
      const { id, address, ...mainFields } = row;
      const { formattedAddress, ...addressFields } = address || {};
      const warehouse = { ...mainFields, ...addressFields };

      this.$refs.editModal.open(warehouse, id);
    },
    rowHover(row) {
      this.hoveredRow = row;
    },
    isRowHovered(row) {
      return row.id === this.hoveredRow?.id;
    },
    openDeleteModal(warehouse) {
      this.$refs.deleteModal.open(warehouse);
    },
    async warehouseCreated() {
      await this.refresh();
      flash.success({
        title: 'Warehouse successfully created!',
      });
    },
    async warehouseUpdated() {
      await this.refresh();
      flash.success({
        title: 'Warehouse successfully updated!',
      });
    },
    async warehouseDeleted() {
      await this.refresh();
      flash.success({
        title: 'Warehouse successfully deleted!',
      });
    },
    async refresh() {
      const { data } = await this.distributorFetchWarehouses({ withLocations: true });
      const { data: warehouse } = data;
      this.warehouses = warehouse;
    },
  },
  async created() {
    await this.refresh();
  },
};
</script>

<template>
  <div>
    <ez-alert v-if="trackingOff" type="yellow-gray" class="tracking-alert mt-0">
      <template #icon>
        <font-awesome-icon icon="info-circle" />
      </template>
      <template>
        While inventory tracking is OFF, the option for adding new warehouses is not available.
      </template>
    </ez-alert>
    <ez-table
      :loading="loading"
      :data="warehouses"
      :columns="['name', 'address', 'email', 'phone']"
      :columnProps="{
        name: { class: 'xxl-cell name-cell' },
        email: { class: 'xxl-cell' },
        phone: { class: 'extra-large-cell' },
      }"
      :headers="{
        email: () => 'Email Address',
        phone: () => 'Phone Number',
      }"
      :rowDataCy="row => `${supplierCy.SETTINGS.WAREHOUSES.GENERAL.ROW__EDIT_WAREHOUSE}-${row.id}`"
      @rowHover="rowHover"
      @rowClick="editWarehouse"
    >
      <template #cell-name="{ row: { name, primary } }">
        <div>
          <span class="warehouse-name" :title="name">{{ name }}</span>
          <status-badge v-if="primary" status="default" />
        </div>
      </template>

      <template #cell-address="{ row: { address } }">
        {{ address ? address.formattedAddress : '-' }}
      </template>

      <template #cell-email="{ row: { email } }">
        {{ email || '-' }}
      </template>

      <template #cell-phone="{ row }">
        <span v-if="isRowHovered(row)" class="delete-warehouse-btn">
          <font-awesome-icon
            icon="trash"
            v-tooltip="'Delete Warehouse'"
            @click.stop="openDeleteModal(row)"
          />
        </span>
        <span v-else>{{ row.phone || '-' }}</span>
      </template>
    </ez-table>

    <ez-button
      type="secondary"
      formType="button"
      isFullWidth
      class="new-warehouse-button mt-12"
      @click="openCreateModal"
      :disabled="trackingOff"
      :data-cy="supplierCy.SETTINGS.WAREHOUSES.GENERAL.BUTTON__ADD_NEW"
    >
      <font-awesome-icon icon="plus" /> Add New Warehouse
    </ez-button>

    <create-warehouse-modal ref="createModal" @success="warehouseCreated" />
    <edit-warehouse-modal ref="editModal" @success="warehouseUpdated" />
    <delete-warehouse-modal
      ref="deleteModal"
      :warehouses="warehouses"
      @success="warehouseDeleted"
    />
  </div>
</template>

<style lang="scss" scoped>
:deep() .tracking-alert {
  justify-content: flex-start;
}

:deep() .table {
  tbody tr td {
    padding: 18px;
  }

  .name-cell div {
    display: flex;
    align-items: center;

    .warehouse-name {
      font-weight: 600;
      color: $color-gray-25;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .status {
      margin-left: 8px;
      font-weight: 700;
    }
  }

  .delete-warehouse-btn {
    padding: 8px;
  }
}

.new-warehouse-button:disabled {
  background-color: $input-disabled-bg-color;
  color: rgba($color-gray-6C, 0.48);
}
</style>
