<template>
  <ez-confirmation-modal @close="onClose" @open="onOpen" ref="approveModal" icon="question">
    <template #title>Convert to Order?</template>
    <template #content>
      <p>Please add additional information before converting this quote to an order.</p>
      <hr />
      <ez-select
        ref="select"
        name="orderType"
        label="Convert To"
        :selected="orderType.id"
        :value="orderType.name"
        :options="orderTypes"
        @change="onOrderTypeSelectChange"
        isFullWidth
      />
      <v-date-picker
        class="date-picker mt-16"
        name="month"
        label="Delivery Date"
        v-model="selectedDate"
        formKey="filters"
        :min="minDate || today"
        :deliveryDays="deliveryDays"
        @dateChange="updateDate"
        :data-cy="''"
      />
      <v-select-search
        :data="drivers"
        :selected="driverId"
        name="driverId"
        label="Delivery Driver"
        placeholder="No Driver"
        @selected="onDriverSelectChange"
        class="driver-dropdown mt-16"
        isFullWidth
      />
      <hr />
      <ez-notes :order="order" />
      <ez-textarea
        :form-key="formKey"
        :required="true"
        placeholder="Leave a Note"
        name="message"
        label="Note"
        class="mt-8"
        :data-cy="''"
        @onChange="val => (note = val)"
      />
      <div class="checkboxes-block">
        <p>Choose in which documents you want to include this note.</p>
        <div class="checkboxes-block__checkbox">
          <ez-checkbox
            :formKey="formKey"
            :checked="false"
            class="note-checkbox mr-16"
            name="deliveryOrder"
            label="Delivery Order"
            @change="value => (deliveryOrder = value)"
          />
          <ez-checkbox
            :formKey="formKey"
            :checked="false"
            class="note-checkbox"
            name="invoice"
            label="Invoice"
            @change="value => (invoice = value)"
          />
        </div>
      </div>

      <ez-loader :show="isLoading" />
    </template>

    <template #footer>
      <ez-button @click="close" type="link" :data-cy="''"> Cancel </ez-button>
      <ez-button @click="confirm" :disabled="false" :is-loading="isLoading" :data-cy="''">
        Confirm
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import dayjs from 'dayjs';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import { mapActions, mapGetters } from 'vuex';
import { DELIVERY_PERIODS, LOADING_KEY } from '@/util/constants';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader/EzLoader';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import VDatePicker from '@/components/v3/patterns/VDatePicker';
import { EzNotes } from '@/views/common/orders/notes';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox.vue';
import EzSelect from '@/components/ui/Select';

/**
 * ApproveOrder
 * @version 1.0.0
 * @since 2.0.0
 */

export default {
  components: {
    EzCheckbox,
    EzConfirmationModal,
    EzButton,
    EzLoader,
    VSelectSearch,
    VDatePicker,
    EzNotes,
    EzTextarea,
    EzSelect,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'convert-order',
      loadingKey: LOADING_KEY.DISTRIBUTOR_CONVERT_QUOTE,
      selectedDateTime: {},
      today: dayjs().format('YYYY-MM-DD'),
      selectedDate: dayjs().format('YYYY-MM-DD'),
      periods: DELIVERY_PERIODS,
      note: null,
      minDate: null,
      isBefore: false,
      drivers: [],
      driverId: null,
      invoice: false,
      deliveryOrder: false,
      orderTypes: [
        {
          id: 1,
          name: 'Pending',
          value: 'pending',
        },
        {
          id: 2,
          name: 'Backorder',
          value: 'backorder',
        },
      ],
      orderType: {
        id: 1,
        name: 'Pending',
        value: 'pending',
      },
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    ...mapGetters('entities/users', ['getDistributor']),
    distributor() {
      return this.getDistributor;
    },
    restrictDeliveryDates() {
      return this.order.restrictDeliveryDates;
    },
    deliveryDays() {
      return this.restrictDeliveryDates
        ? this.distributor.deliveryDays
        : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    },
    isLoading() {
      return this.getLoading(this.loadingKey);
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorFetchDrivers']),
    ...mapActions('entities/orders', ['distributorConvertQuoteToOrder']),
    close() {
      this.$refs.approveModal.close();
    },
    open() {
      this.$refs.approveModal.open();
    },
    updateDate(val) {
      this.selectedDate = val.format('YYYY-MM-DD');
    },
    onOpen() {
      this.selectedDate = dayjs().format('YYYY-MM-DD');
      this.driverId = this.order?.driver?.id;
    },
    onClose() {
      this.selectedDate = null;
      this.orderType = {
        id: 1,
        name: 'Pending',
        value: 'pending',
      };
      this.note = null;
      this.driverId = null;
      this.invoice = false;
      this.deliveryOrder = false;
    },
    async initDrivers() {
      const { data } = await this.distributorFetchDrivers();
      this.drivers = data.data;
    },
    onDriverSelectChange(selected) {
      this.driverId = selected.reset ? null : selected.id;
    },
    async confirm() {
      const data = {
        driverId: this.driverId,
        date: this.selectedDate,
        status: this.orderType.value,
        message: this.note,
        messageDisplay: {
          invoice: this.invoice,
          deliveryOrder: this.deliveryOrder,
        },
      };

      try {
        await this.distributorConvertQuoteToOrder({
          orderId: this.order.id,
          data,
        });
        this.close();
        this.$emit('success');
        flash.success({ title: 'Order successfully created!' });
      } catch (e) {
        this.close();
        console.error(e);
      }
    },
    onOrderTypeSelectChange(val) {
      this.orderType = val;
    },
  },
  created() {
    this.initDrivers();
  },
};
</script>

<style lang="scss" scoped>
.pick-a-date {
  @extend %flex-center;
  padding: px-to-rem(16px) 0;

  :deep() .input-group__input {
    margin-left: px-to-rem(8px);
  }
}
.documents-checkboxes {
  display: flex;
}
.footer-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .advanced-options-btn {
    padding-left: 0;
  }
}
.info-message-wrapper {
  .banner {
    margin: 0;
    width: 100%;
  }
}
:deep() .loader {
  @extend %whole-screen;
}
.date-picker {
  :deep() .ez-simple-dropdown__display-container {
    width: 100%;
    .placeholder {
      width: 100%;
    }
  }
}

.driver-dropdown {
  :deep() .select-search {
    &__search-wrapper {
      display: none;
    }

    &__item {
      @include font-size(14px, 20px);
      padding: 10px 16px;
      color: $color-gray-25;
    }
  }
}

.checkboxes-block {
  display: flex;
  flex-direction: column;

  p {
    color: $color-gray-6C;
    @include font-size(14px);
  }

  &__checkbox {
    display: flex;
  }

  :deep() .input-group {
    height: 16px;

    .check-indicator {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
