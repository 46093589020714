<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { clone } from '@/util/utils';

import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzTable from '@/components/ui/Table/EzTable.vue';
import VPrice from '@/components/v3/elements/VPrice';

import EditProductModal from './EditProductModal.vue';

/**
 * ProductsList
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'ProductsList',
  props: {
    creditNote: {
      required: true,
      type: Object,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    EzEntityInfo,
    EzButton,
    EzTable,
    VPrice,
    EditProductModal,
  },
  data() {
    return {
      hoveredRow: null,
      items: [],
      supplierCy,
    };
  },
  computed: {
    products() {
      return this.editMode ? this.items : this.creditNote.items;
    },
  },
  methods: {
    rowHover(row) {
      if (!this.editMode) return;
      this.hoveredRow = row;
    },
    isRowHovered(row) {
      return this.hoveredRow ? this.hoveredRow.id === row.id : false;
    },
    editProduct(row) {
      this.$refs.editProductModal.open(row);
    },
    onEditProductSave(item) {
      const idx = this.items.findIndex(i => i.id === item.id);
      this.items.splice(idx, 1, { ...item });
    },
    deleteProduct(row) {
      const items = clone(this.items);
      const idx = items.findIndex(i => i.id === row.id);
      items.splice(idx, 1);
      this.items = clone(items);
    },
    resetValues() {
      this.items = {};
      this.hoveredRow = null;
    },
    initValues() {
      this.items = clone(this.creditNote.items);
    },
    totalAmount(row) {
      const calculatedTotal = +(row.price * row.quantity).toFixed(2);
      return calculatedTotal === row.totalPrice ? row.totalPrice : calculatedTotal;
    },
  },
  watch: {
    items(val, oldVal) {
      if (!this.editMode || !oldVal?.length) return;
      this.$emit('update', val);
    },
    editMode(newVal, oldVal) {
      if (oldVal && !newVal) this.resetValues();
      else this.initValues();
    },
  },
};
</script>

<template>
  <div>
    <ez-table
      v-if="products.length"
      :data="products"
      :columns="['name', 'quantity', 'price']"
      :headers="{
        name: () => 'Product',
        quantity: () => 'Qty',
        price: () => 'Total Amount',
      }"
      :columnProps="{
        name: { class: 'name-cell' },
        quantity: { class: 'u-text-right productQty-cell' },
        price: { class: 'amount-cell no-side-padding-cell' },
      }"
      @rowHover="rowHover"
      class="products-table mt-16"
    >
      <template #cell-name="{ row: { image, name, productId, price, orderingUnit, sku } }">
        <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="image">
          <div class="product-info" :title="name">
            <span
              class="product-info__name"
              v-tooltip="{ content: name }"
              :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.TEXT__PRODUCT_NAME}-${productId}`"
            >
              {{ name }}
            </span>
            <div class="product-info__secondary">
              <span>
                <v-price
                  class="product-info__price"
                  :price="price || 0"
                  :show-market-price-info="false"
                  :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.TEXT__PRODUCT_PRICE}-${productId}`"
                />
                /
                <span
                  v-if="orderingUnit"
                  class="product-info__unit"
                  :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.TEXT__PRODUCT_UNIT}-${productId}`"
                >
                  {{ orderingUnit.label }}
                </span>
              </span>
              <span v-if="orderingUnit && sku" class="product-info__bull ml-2 mr-2">&bull;</span>
              <span
                v-if="sku"
                class="product-info__sku"
                :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.TEXT__PRODUCT_SKU}-${productId}`"
              >
                {{ sku }}
              </span>
            </div>
          </div>
        </ez-entity-info>
      </template>

      <template #cell-quantity="{ row }">
        <span
          :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.TEXT__PRODUCT_QUANTITY}-${row.productId}`"
        >
          {{ row.quantity }}
        </span>
      </template>

      <template #cell-price="{ row }">
        <v-price
          v-if="!isRowHovered(row)"
          :price="totalAmount(row) || 0"
          :show-market-price-info="false"
          :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.TEXT__PRODUCT_TOTAL}-${row.productId}`"
        />

        <div v-else class="row-actions">
          <ez-button
            @click.stop="editProduct(row)"
            type="link"
            class="row-actions__action"
            :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.BUTTON__EDIT_PRODUCT}-${row.productId}`"
          >
            <font-awesome-icon
              icon="pen"
              v-tooltip="{ content: 'Edit details', classes: ['tooltip-general'] }"
            />
          </ez-button>
          <ez-button
            @click.stop="deleteProduct(row)"
            type="link"
            class="row-actions__action"
            :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.STOCK_CREDIT.BUTTON__DELETE_PRODUCT}-${row.productId}`"
          >
            <font-awesome-icon
              icon="trash"
              v-tooltip="{ content: 'Remove from order', classes: ['tooltip-general'] }"
            />
          </ez-button>
        </div>
      </template>
    </ez-table>

    <edit-product-modal ref="editProductModal" @save="onEditProductSave" />
  </div>
</template>

<style lang="scss" scoped>
.products-table {
  .name-cell {
    .product-info {
      display: flex;
      flex-direction: column;

      &__secondary {
        display: flex;
        align-items: center;
      }

      &__name,
      &__unit,
      &__price {
        font-weight: 500;
        color: $color-gray-25;
      }

      &__bull,
      &__sku {
        color: $color-gray-6C;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__price :deep() {
        display: inline-flex;

        .v-price__price {
          @include font-size(12px, 14px);
        }
      }
    }
  }

  .productQty-cell {
    width: 32px + $spacing-32;
  }

  .amount-cell {
    width: 96px;

    :deep() .v-price__price {
      color: $color-gray-25;
    }

    .row-actions {
      &__action {
        padding: 0;
        width: 24px;
        height: 24px;

        &:not(:first-child) {
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
