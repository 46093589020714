<script>
import EzTextarea from '@/components/ui/Textarea/EzTextarea.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown.vue';

export default {
  components: {
    EzDropdown,
    EzConfirmationModal,
    EzButton,
    EzTextarea,
  },
  props: {
    drivers: {
      type: Array,
      required: true,
    },
    selectedDriver: {
      type: Object,
      required: true,
    },
    note: {
      type: String,
      required: false,
      default: () => (''),
    },
  },
  data() {
    return {
      driversData: [],
      driverNote: '',
      driver: {},
    };
  },
  methods: {
    updateDriver(driver) {
      this.driver = driver;
    },
    reset() {
      this.driversData = [];
      this.driverNote = '';
      this.driver = {};
    },
    close() {
      this.$refs.driverEditModal.close();
    },
    open() {
      this.$refs.driverEditModal.open();
    },
    save() {
      this.$emit('driverSave', { driver: this.driver, message: this.driverNote });
    },
  },
  watch: {
    drivers(val) {
      this.driversData = val;
    },
    note(val) {
      this.driverNote = val;
    },
    selectedDriver(val) {
      this.driver = val;
    },
  },
};
</script>

<template>
  <ez-confirmation-modal ref="driverEditModal">
    <template #title>Edit Driver</template>
    <template #content>
      <div class="mt-16">
        <ez-dropdown
          formKey="driverKey"
          :data="driversData"
          :selected="driver?.id"
          name="driverId"
          placeholder="Select Driver"
          @change="updateDriver"
          isFullWidth
          class="mb-16"
        />
        <ez-textarea
          class="mt-12"
          :data-cy="''"
          name="note"
          label="Note"
          placeholder="Leave a Note"
          form-key="driverKey"
          :value="driverNote"
          @onChange="(val) => driverNote = val"
        />
      </div>
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="close"
        :data-cy="''"
      >
        Cancel
      </ez-button>
      <ez-button
        @click="save"
        :data-cy="''"
      >
        Save Changes
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped></style>
