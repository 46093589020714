<template>
  <div>
    <ez-form
    :formKey="formKey"
    :loading-key="loadingKeyForm"
    :action="formAction"
    :method="formMethod"
    :additional-data="{
      ...priceUnits,
      ...tags,
      ...((tax && !isTaxDefault) ? { tax } : { tax: null}),
    }"
    submitType="multipart"
    @success="onSubmitSuccess"
    @error="onSubmitError"
    ref="form"
  >
    <ez-image-upload
      :formKey="formKey"
      :previewUrl="draft.image || defaultImage"
      :isDefaultImage="draft.isDefaultImage"
      :disabled="isTradeGecko"
      :add-button-data-cy="dataCyAttr('BUTTON__IMAGE')"
      columnMode
      name="image"
    >
      <template #addImage>
        <span v-if="isExistingProduct">Change Product Image</span>
        <span v-else>Add Product Image</span>
      </template>
    </ez-image-upload>
      <ez-input
        :formKey="formKey"
        name="name"
        label="Product name*"
        :value="draft.name"
        :disabled="isTradeGecko"
        placeholder="Enter product name"
        :data-cy="dataCyAttr('INPUT__NAME')"
      />
      <ez-input
        :formKey="formKey"
        name="sku"
        label="SKU number"
        :value="draft.sku"
        :disabled="isTradeGecko"
        placeholder="Enter SKU Number"
        :data-cy="dataCyAttr('INPUT__SKU')"
      />

      <ez-category-filter
        label="Category"
        :is-parent-disabled="true"
        name="categoryId"
        :data-cy="dataCyAttr('BUTTON__CATEGORY')"
      />

      <v-product-group-settings name="productGroupId" :selectedId="draft.productGroup?.id" />

      <ez-textarea
        :formKey="formKey"
        name="description"
        label="Description"
        :value="draft.description"
        :disabled="isTradeGecko"
        placeholder="Enter description"
        :data-cy="dataCyAttr('TEXTAREA__DESCRIPTION')"
      />
    </ez-form>
    <v-units-settings
      @disabled="(val) => priceUnitsDisabled = val"
      @unitsUpdated="unitsUpdated"
      @onUnitChange="getUnits"
      @successDeleteUnit="getUnits"
      :units="units"
      :form-key="formKey"
      :product="product"
      :allow-negative-price="false"
      :show-cogs="showCogs"
      :data-cy="unitsDataCy"
    />
    <hr />
    <div class="mb-16">
      <p class="section-title">Tax Rate</p>
    </div>
    <div class="tax-wrapper">
      <ez-input
        class="mt-1 mb-12"
        :formKey="formKey"
        placeholder="Tax Rate"
        label="Tax Rate"
        type="number"
        name="tax"
        step="any"
        :value="product.tax"
        :disabled="isDistributorXeroConnected || isTaxDefault"
        @onChange="(ev) => tax = ev"
      >
        <template #suffix>%</template>
      </ez-input>
      <ez-button
        class="ml-12 mb-12"
        type="secondary"
        v-if="isDistributorXeroConnected"
        @click="goToAccountingIntegration"
      >
        Settings
      </ez-button>
    </div>
    <ez-checkbox
      v-if="!isDistributorXeroConnected"
      :form-key="formKey"
      name="isTaxDefault"
      label="Default Tax Rate"
      @change="onDefaultTaxRateChange"
      :checked="isTaxDefault"
    />
    <div v-if="!isAdmin" class="tags">
      <p class="section-title">Add Tags</p>
      <span class="button-description">Create or Add Product Tag(s)</span>
      <ez-button v-if="!productTags.length" type="secondary" is-full-width @click="openTagsModal"
        :isLoading="isLoading">
        <font-awesome-icon icon="plus" />
        Add Tags
      </ez-button>
      <ez-button v-else type="secondary" is-full-width @click="openTagsModal" class="height-fit-content"
        :isLoading="isLoading">
        <div class="all-tags">
          <v-tag
            v-for="tag in productTags"
            :key="tag.id"
            :color="tag.color.colors[0]"
            :backgroundColor="tag.color.colors[1]"
            @click.stop="onClick(tag)"
            canRemove
          >
            {{ tag.name }}
          </v-tag>
        </div>
        <font-awesome-icon icon="pen" class="icon-absolute" />
      </ez-button>
    </div>
    <add-tags-modal v-if="!isAdmin" ref="addTagsModal" @confirm="onAddConfirm" :tagType="TagType.Product"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import EzForm from '@/components/ui/Form';
import EzImageUpload from '@/components/ui/ImageUpload';
import EzInput from '@/components/ui/Input';
import EzButton from '@/components/ui/Button/EzButton';
import Category from '@/models/Category';
import EzTextarea from '@/components/ui/Textarea/';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import { getCurrency, clone } from '@/util/utils';
import { isAdmin, LOADING_KEY, TagType } from '@/util/constants';
import VUnitsSettings from '@/components/v3/patterns/VUnitsSettings';
import VProductGroupSettings from '@/components/v3/elements/VProductGroupSettings/index.vue';
import { getUnits } from '@/views/common/products/ProductsActions';
import AddTagsModal from '@/components/v3/elements/VProductTagsSettings/AddTagsModal.vue';
import VTag from '@/components/v3/elements/VTag';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';

export default {
  components: {
    EzCategoryFilter,
    EzTextarea,
    EzInput,
    EzImageUpload,
    EzForm,
    VUnitsSettings,
    VProductGroupSettings,
    EzButton,
    AddTagsModal,
    VTag,
    EzCheckbox,
  },
  props: {
    // From params
    distributorId: {
      type: Number,
      default: null,
    },
    showCogs: {
      type: Boolean,
      required: false,
      default: true,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      formKey: 'new-product',
      loadingKeyForm: LOADING_KEY.DISTRIBUTOR_ADD_PRODUCT_WIZARD_FORM,
      priceUnits: {},
      productTags: [],
      productTagsIds: [],
      units: [],
      priceUnitsDisabled: false,
      TagType,
      tax: null,
      isTaxDefault: false,
    };
  },
  computed: {
    ...mapGetters('defaultImages', [
      'getDefaultImage',
    ]),
    ...mapState('entities/products', [
      'draft',
    ]),
    ...mapState('tags', ['selectedTags', 'selectedTagsIds']),
    ...mapGetters('loading', ['isSomeLoading']),
    ...mapGetters('entities/users', ['isDistributorXeroConnected']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_TAGS,
        LOADING_KEY.FETCH_TAG_COLORS,
      ]);
    },
    defaultImage() {
      return this.getDefaultImage('category');
    },
    product() {
      return {
        price: this.draft?.price,
        marketPrice: this.draft?.marketPrice || false,
        orderingUnit: this.draft?.orderingUnit || {},
        priceUnit: this.draft?.priceUnit,
        minimumQuantity: this.draft?.minimumQuantity || 1,
        orderingUnitIncrement: this.draft?.orderingUnitIncrement || 1,
        tax: this.draft?.tax,
        isTaxDefault: this.draft?.isTaxDefault || false,
      };
    },
    categories() {
      const categories = Category.query()
        .where('parentId', v => !!v)
        .all();

      return [
        {
          id: null,
          name: 'Uncategorized',
        },
        ...categories.map(({ id, name }) => ({
          id,
          name,
        })),
      ];
    },
    formAction() {
      const { distributorId, draft } = this;
      const route = isAdmin()
        ? `/admin/distributors/${distributorId}/products`
        : '/distributor/products';
      return this.isExistingProduct ? `${route}/${draft.id}` : route;
    },
    formMethod() {
      return this.isExistingProduct ? 'patch' : 'post';
    },
    isTradeGecko() {
      return false;
    },
    isExistingProduct() {
      return this.draft && this.draft.id;
    },
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return symbol;
    },
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
    unitsDataCy() {
      if (!this.hasDataCy) return {};

      return {
        cogs: this.dataCyAttr('INPUT__COGS'),
        price: this.dataCyAttr('INPUT__PRICE'),
        minimumQuantity: this.dataCyAttr('INPUT__MINIMUM_QUANTITY'),
        orderingQuantityIncrement: this.dataCyAttr('INPUT__QUANTITY_INCREMENT'),
        orderingUnitId: this.dataCyAttr('BUTTON__SALES_UNIT'),
        priceUnitId: this.dataCyAttr('BUTTON__PRICE_UNIT'),
        marketPrice: this.dataCyAttr('LABEL__MARKET_PRICE'),
        priceUnit: this.dataCyAttr('LABEL__PRICE_UNIT'),
      };
    },
    tags() {
      if (!this.productTagsIds.length) return { tagIds: '' };
      const tagIdsObject = {};
      this.productTagsIds.forEach((id, index) => {
        tagIdsObject[`tagIds[${index}]`] = id;
      });
      return tagIdsObject;
    },
    isAdmin() {
      return isAdmin();
    },
  },
  methods: {
    ...mapMutations('errors', [
      'CLEAR_ERRORS',
    ]),
    ...mapMutations('tags', [
      'REMOVE_SELECTED_TAG',
      'SET_SELECTED_TAGS',
    ]),
    ...mapActions('tags', [
      'fetchTagsByType',
      'fetchTagColors',
    ]),
    submit() {
      this.$refs.form.onSubmit();
    },
    onSubmitSuccess(response) {
      this.$emit('submitSuccess', response);
    },
    onSubmitError(response) {
      this.$emit('submitError', response);
    },
    unitsUpdated(data) {
      this.priceUnits = data;
    },
    onDefaultTaxRateChange(val) {
      this.isTaxDefault = val;
    },
    async getUnits() {
      const { data: { data } } = await getUnits({ distributorId: this.distributorId });
      this.units = data;
    },
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
    async openTagsModal() {
      await this.fetchTagsByType({ query: { type: TagType.Product } });
      await this.fetchTagColors();
      this.SET_SELECTED_TAGS(this.productTagsIds);
      this.$refs.addTagsModal.open();
    },
    onAddConfirm() {
      this.productTags = clone(this.selectedTags);
      this.productTagsIds = clone(this.selectedTagsIds);
      this.$refs.addTagsModal.close();
    },
    onClick(tag) {
      this.REMOVE_SELECTED_TAG(tag);
      this.productTagsIds = this.productTagsIds.filter(item => item !== tag.id);
      this.productTags = this.productTags.filter(item => item.id !== tag.id);
    },
    goToAccountingIntegration() {
      const routerData = this.$router.resolve({
        name: 'distributor-xero-products',
      });
      window.open(routerData.href, '_blank');
    },
  },
  watch: {
    priceUnitsDisabled(val) {
      this.$emit('formValid', !val);
    },
  },
  created() {
    this.getUnits();
    const { tags } = this.draft;
    this.productTags = clone(tags);
    this.productTagsIds = tags.map(item => item.id);
    this.tax = this.draft.tax;
    this.isTaxDefault = this.draft.isTaxDefault;
  },
  beforeDestroy() {
    this.CLEAR_ERRORS({ key: this.formKey });
  },
};
</script>

<style scoped lang="scss">
.ez-form {
  :deep() .ez-select {
    width: 100%;
  }

  .ez-image-upload {
    margin-bottom: 1.5rem;
  }

  .input-group+.input-group {
    margin-top: .75rem;
  }

  .ez-dropdown {
    max-width: 100%;
  }
}

.all-tags {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 14px;
}

.button.height-fit-content {
  height: auto;
  padding-right: 42px;
  position: relative;

  .icon-absolute {
    position: absolute;
    top: 10px;
    right: 12px;
  }
}

.button-description {
  color: $color-gray-6C;
  font-size: 12px;
  line-height: 18px;

  & + button {
    margin-top: 6px;
  }
}

.tax-wrapper {
  :deep() .input-group {
    flex: 1;
  }
  display: flex;
  align-items: flex-end;
  width: 100%;
}
</style>
