<script>
import EzTextarea from '@/components/ui/Textarea/EzTextarea.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index.vue';
import { EzConfirmationModal } from '@/components/ui/Modal';

export default {
  components: {
    EzConfirmationModal,
    VDatePicker,
    EzButton,
    EzTextarea,
  },
  props: {
    currentOrderDate: {
      type: [Object, String, Date],
      required: true,
    },
    note: {
      type: String,
      required: false,
      default: () => (''),
    },
  },
  data() {
    return {
      orderDate: null,
      orderDateNote: '',
    };
  },
  methods: {
    updateOrderDate(date) {
      this.orderDate = date;
    },
    reset() {
      this.orderDate = null;
      this.orderDateNote = '';
    },
    close() {
      this.$refs.orderDateEditModal.close();
    },
    open() {
      this.$refs.orderDateEditModal.open();
    },
    save() {
      this.$emit('orderDateSave', { orderDate: this.orderDate, message: this.orderDateNote });
    },
  },
  watch: {
    currentOrderDate(val) {
      this.orderDate = val;
    },
    note(val) {
      this.orderDateNote = val;
    },
  },
};
</script>

<template>
  <ez-confirmation-modal ref="orderDateEditModal">
    <template #title>Edit Order Date</template>
    <template #content>
      <div class="mt-16">
        <v-date-picker
          class="calendar mt-8"
          :data-cy="''"
          name="orderDate"
          label="Order Date"
          v-model="orderDate"
          form-key="orderDateKey"
          @dateChange="updateOrderDate"
          is-full-width
        />
        <ez-textarea
          class="mt-12"
          :data-cy="''"
          name="note"
          label="Note"
          placeholder="Leave a Note"
          form-key="orderDateKey"
          :value="orderDateNote"
          @onChange="(val) => orderDateNote = val"
        />
      </div>
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="close"
        :data-cy="''"
      >
        Cancel
      </ez-button>
      <ez-button
        @click="save"
        :data-cy="''"
      >
        Save Changes
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped></style>
