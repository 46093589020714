<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { LOADING_KEY } from '@/util/constants';
import downloadAttachment from '@/util/downloadAttachment';

import EzLoader from '@/components/ui/Loader';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table';
import flash from '@/components/ui/FlashMessage';
import Paper from '@/components/layout/WizardOverviewPaper.vue';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import EzSelect from '@/components/ui/Select/EzSelect.vue';
import { TRANSFER_STEP_1, TRANSFER_STEP_2 } from './steps';

/**
 * Overview
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Overview',
  components: {
    EzLoader,
    EzButton,
    EzTable,
    Paper,
    VProductEntityInfo,
    EzSelect,
  },
  data() {
    return {
      supplierCy,
      nextPage: 'distributor-products-all',
      productsListExpanded: false,
      downloadOptions: [
        {
          id: null,
          name: 'No Download',
          value: 'noDownload',
        },
        {
          id: 1,
          name: 'PDF',
          value: 'pdf',
        },
        {
          id: 2,
          name: 'CSV',
          value: 'xlsx',
        },
      ],
      selected: {
        id: null,
        name: 'No Download',
        value: 'noDownload',
      },
    };
  },
  computed: {
    ...mapState('entities/products', ['inventoryManagement']),
    ...mapGetters('loading', ['getLoading']),
    warehouseFrom() {
      return this.inventoryManagement.warehouse?.from || {};
    },
    warehouseTo() {
      return this.inventoryManagement.warehouse?.to || {};
    },
    items() {
      return this.inventoryManagement.items.filter(item => item.quantity);
    },
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_PRODUCTS_TRANSFER_INVENTORY);
    },
    listItems() {
      if (!this.productsListExpanded) return this.items.slice(0, 6);
      return this.items;
    },
    expandButtonCopy() {
      return this.productsListExpanded ? 'Collapse List' : 'Expand List';
    },
    expandButtonIcon() {
      return this.productsListExpanded ? 'angle-up' : 'angle-down';
    },
  },
  methods: {
    ...mapActions('entities/products', ['distributorProductsTransferInventory']),
    ...mapMutations('entities/products', ['CLEAR_DRAFT']),
    toggleListExpanded() {
      this.productsListExpanded = !this.productsListExpanded;
    },
    editWarehouse() {
      this.$emit('stepBack', TRANSFER_STEP_1);
    },
    editProducts() {
      this.$emit('stepBack', TRANSFER_STEP_2);
    },
    async onNextStep() {
      const req = {
        fromWarehouseId: this.warehouseFrom.id,
        toWarehouseId: this.warehouseTo.id,
        items: this.items.map(({ id: itemId, quantity, locationId, batchCode, cogs }) => ({
          itemId,
          quantity,
          locationId,
          batchCode,
          cogs,
        })),
        ...(this.selected.id ? { export: true } : { export: false }),
        ...(this.selected.id ? { exportFormat: this.selected.value } : {}),
      };

      try {
        const { data, headers } = await this.distributorProductsTransferInventory({ data: req });

        if (this.selected.id) {
          const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
          const name = Array.isArray(filename) ? decodeURI(filename[1]) : `template.${this.selected.value}`;
          downloadAttachment(data, name);
        }

        this.CLEAR_DRAFT();

        flash.success({ title: 'Inventory transferred successfully.' });
        this.$router.push({ name: this.nextPage });
      } catch (e) {
        // do nothing
      }
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    onChange(val) {
      this.selected = val;
    },
  },
  created() {
    this.enableNextStep();

    const { returnPage } = this.inventoryManagement.params;
    if (returnPage) this.nextPage = returnPage;
  },
};
</script>

<template>
  <div class="ez-inventory-overview">
    <paper class="download-section">
      <template #title>DOWNLOAD INVENTORY TRANSFER</template>
      <template>
        <ez-select
          ref="select"
          class="input-group"
          :selected="selected.id"
          isFullWidth
          :options="downloadOptions"
          @change="onChange"
        />
      </template>
    </paper>
    <paper>
      <template #title>TRANSFER</template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editWarehouse"
          :data-cy="supplierCy.INVENTORY_FLOWS.TRANSFER.OVERVIEW.BUTTON__EDIT_WAREHOUSE"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <template>
        <div class="overview-warehouses">
          <div class="overview-warehouses__warehouse">
            <div class="warehouse-section">
              <p class="warehouse-section__label">From</p>
              <p class="warehouse-section__text">{{ warehouseFrom.name }}</p>
            </div>
            <div class="warehouse-section">
              <p class="warehouse-section__label">To</p>
              <p class="warehouse-section__text">{{ warehouseTo.name }}</p>
            </div>
          </div>
        </div>
      </template>
    </paper>
    <paper class="products-section">
      <template #title>TRANSFERRED PRODUCTS ({{ items.length }})</template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editProducts"
          :data-cy="supplierCy.INVENTORY_FLOWS.TRANSFER.OVERVIEW.BUTTON__EDIT_WAREHOUSE"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <template>
        <ez-table
          class="products-table"
          :data="listItems"
          :columns="['name', 'quantity']"
          :headers="false"
          :columnProps="{
            name: { class: 'name-cell' },
            quantity: { class: 'quantity-cell small-cell' },
          }"
          disableHover
        >
          <template #cell-name="{ row: { product } }">
            <v-product-entity-info :product="product" />
          </template>
        </ez-table>
      </template>
      <template #footer>
        <div v-if="items.length > 6" class="u-flex-h-center mt-16">
          <ez-button @click="toggleListExpanded" type="link" formType="button">
            <span>{{ expandButtonCopy }}</span>
            <font-awesome-icon class="ml-8" :icon="expandButtonIcon" />
          </ez-button>
        </div>
      </template>
    </paper>
    <ez-loader :show="loading" />
  </div>
</template>

<style lang="scss" scoped>
.overview-warehouses {
  &__warehouse {
    &:not(:last-child) {
      margin-bottom: 16px;
      border-bottom: 1px dashed $color-gray-E1;
      padding-bottom: 16px;
    }

    .warehouse-section {
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &__label {
        @include font-size(12px, 18px);
        margin: 0 0 4px;
        color: $color-gray-6C;
        font-weight: 600;
      }

      &__text {
        @include font-size(14px, 20px);
        margin: 0;
      }
    }
  }
}

.products-table {
  tbody tr td {
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 0;

    &.name-cell {
      :deep() .entity-info__text {
        white-space: nowrap;
      }
    }

    &.quantity-cell {
      color: $color-gray-25;
    }
  }
}

.download-section {
  :deep() .overview-paper__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
</style>
