var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ez-wizard',{attrs:{"extraWide":_vm.currentStep === _vm.REMOVAL_STEP_2},on:{"exit":_vm.onWizardExit,"back":_vm.goToPreviousStep},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Remove Products")]},proxy:true},{key:"nav",fn:function(){return [_c('ul',[_c('li',{class:{
          'ez-step-active': _vm.currentStep === _vm.REMOVAL_STEP_1,
          'ez-step-completed': _vm.currentStep > _vm.REMOVAL_STEP_1,
        }},[_vm._v(" 1. Warehouse ")]),_c('li',{class:{
          'ez-step-active': _vm.currentStep === _vm.REMOVAL_STEP_2,
          'ez-step-completed': _vm.currentStep > _vm.REMOVAL_STEP_2,
        }},[_vm._v(" 2. Products ")])])]},proxy:true},{key:"actions",fn:function(){return [_c('ez-button',{attrs:{"disabled":_vm.nextDisabled,"dataCy":_vm.supplierCy.INVENTORY_FLOWS.REMOVAL.GENERAL.BUTTON__NEXT_STEP},on:{"click":_vm.goToNextStep}},[_vm._v(" "+_vm._s(_vm.nextCta)+" ")])]},proxy:true},{key:"prevStep",fn:function(){return [([_vm.REMOVAL_STEP_2, _vm.REMOVAL_STEP_3].includes(_vm.currentStep))?_c('ez-button',{attrs:{"type":"link","formType":"button","dataCy":_vm.supplierCy.INVENTORY_FLOWS.REMOVAL.GENERAL.BUTTON__PREV_STEP},on:{"click":_vm.goToPreviousStep}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),_c('span',[_vm._v("Back")])],1):_vm._e()]},proxy:true},{key:"pageTitle",fn:function(){return [_vm._v(_vm._s(_vm.currentStepPageTitle))]},proxy:true},{key:"pageInfo",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.currentStepPageInfo)}})]},proxy:true}])},[_c(_vm.currentStepComponent,{tag:"component",attrs:{"distributorId":_vm.distributorId},on:{"stepCompleted":_vm.onStepCompleted,"stepBack":_vm.onStepBack}}),_c('ez-loader',{attrs:{"show":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.loadingMsg)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }