<template>
  <ez-select
    v-if="warehousesData.length"
    ref="select"
    class="input-group"
    :name="name"
    :disabled="disabled"
    :isFullWidth="isFullWidth"
    :value="selected"
    :selected="selected"
    :label="label"
    :options="warehousesData"
    @change="onChange"
    :data-cy="dataCy"
  />
</template>

<script>
import { mapActions } from 'vuex';
import EzSelect from '../Select';

export default {
  name: 'EzWarehouseFilter',
  components: {
    EzSelect,
  },
  data() {
    return {
      internalWarehouses: [],
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    warehouses: {
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Number, String],
      required: false,
    },
    label: {
      type: String,
    },
    firstItemText: {
      type: String,
      required: false,
      default: 'All Warehouses',
    },
    withLocations: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    warehousesData() {
      return this.warehouses || this.internalWarehouses || [];
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    onChange(value) {
      this.$emit('change', value);
    },
    reset() {
      this.$refs.select.reset();
    },
  },
  async created() {
    if (this.warehouses) return;
    const { data } = await this.distributorFetchWarehouses({ withLocations: this.withLocations });
    this.internalWarehouses = [{ id: null, name: this.firstItemText }, ...data.data];
  },
};
</script>

<style scoped lang="scss">
:deep() .ez-select__display-container {
  height: 2.25rem;
}
</style>
