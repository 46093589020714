<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import ButtonActions from '@/views/platform/distributor/crm/ButtonActions';

export default {
  name: 'ButtonSwitch',
  components: {
    ButtonActions,
  },
  props: {
    actions: {
      type: Array,
      required: true,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    hasGreaterThenTwoActions() {
      return this.actions.length > 2;
    },
  },
  methods: {
    onClick(ev) {
      this.$emit('click', ev);
    },
  },
};
</script>

<template>
  <button-actions
    @clicked="onClick"
    :has-greater-then-two-actions="hasGreaterThenTwoActions"
    :actions="actions"
    :data-cy="dataCy"
  />
</template>

<style lang="scss" scoped>

</style>
