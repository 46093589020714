var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.trackingOff)?_c('ez-alert',{staticClass:"tracking-alert mt-0",attrs:{"type":"yellow-gray"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"info-circle"}})]},proxy:true}],null,false,2451887082)},[[_vm._v(" While inventory tracking is OFF, the option for adding new warehouses is not available. ")]],2):_vm._e(),_c('ez-table',{attrs:{"loading":_vm.loading,"data":_vm.warehouses,"columns":['name', 'address', 'email', 'phone'],"columnProps":{
      name: { class: 'xxl-cell name-cell' },
      email: { class: 'xxl-cell' },
      phone: { class: 'extra-large-cell' },
    },"headers":{
      email: () => 'Email Address',
      phone: () => 'Phone Number',
    },"rowDataCy":row => `${_vm.supplierCy.SETTINGS.WAREHOUSES.GENERAL.ROW__EDIT_WAREHOUSE}-${row.id}`},on:{"rowHover":_vm.rowHover,"rowClick":_vm.editWarehouse},scopedSlots:_vm._u([{key:"cell-name",fn:function({ row: { name, primary } }){return [_c('div',[_c('span',{staticClass:"warehouse-name",attrs:{"title":name}},[_vm._v(_vm._s(name))]),(primary)?_c('status-badge',{attrs:{"status":"default"}}):_vm._e()],1)]}},{key:"cell-address",fn:function({ row: { address } }){return [_vm._v(" "+_vm._s(address ? address.formattedAddress : '-')+" ")]}},{key:"cell-email",fn:function({ row: { email } }){return [_vm._v(" "+_vm._s(email || '-')+" ")]}},{key:"cell-phone",fn:function({ row }){return [(_vm.isRowHovered(row))?_c('span',{staticClass:"delete-warehouse-btn"},[_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Delete Warehouse'),expression:"'Delete Warehouse'"}],attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteModal(row)}}})],1):_c('span',[_vm._v(_vm._s(row.phone || '-'))])]}}])}),_c('ez-button',{staticClass:"new-warehouse-button mt-12",attrs:{"type":"secondary","formType":"button","isFullWidth":"","disabled":_vm.trackingOff,"data-cy":_vm.supplierCy.SETTINGS.WAREHOUSES.GENERAL.BUTTON__ADD_NEW},on:{"click":_vm.openCreateModal}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" Add New Warehouse ")],1),_c('create-warehouse-modal',{ref:"createModal",on:{"success":_vm.warehouseCreated}}),_c('edit-warehouse-modal',{ref:"editModal",on:{"success":_vm.warehouseUpdated}}),_c('delete-warehouse-modal',{ref:"deleteModal",attrs:{"warehouses":_vm.warehouses},on:{"success":_vm.warehouseDeleted}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }