<template>
  <div>
    <h5>Primary Users</h5>
    <div class="mandatory-users">
      <paper>
        <template #title>Account Owner</template>
        <create-new-user
          mandatory-form
          :action="actionOwner"
          :method="hasOwner ? 'patch' : 'post'"
          :data="draftAdmin.owner"
          @complete="addedOwner"
          class="user-form"
          :hacCancel="false"
          @formValid="(val) => formOwnerValid = val"
          :additionalData="!hasOwner ? makeAdditionalData('venue_admin') : {}"
          formKey="addNewOwner"
          loading-key="add-new-owner"
          ref="ownerNewUser"
        />
      </paper>
      <paper>
        <template #title>Finance Contact</template>
        <template #actions>
          <ez-checkbox
            form-key="sameOwner"
            :checked="sameAsAccountFinance"
            @change="() => sameAsAccountFinance = !sameAsAccountFinance"
            class="ez-checkbox"
            label="Same As Account"
            name="owner"/>
        </template>
        <create-new-user
          mandatory-form
          :action="actionFinance"
          :data="draftAdmin.finance"
          :method="hasFinance ? 'patch' : 'post'"
          @complete="addedFinance"
          @formValid="(val) => formFinanceValid = val"
          :additionalData="!hasFinance ? makeAdditionalData('venue_finance') : {}"
          :disabled="sameAsAccountFinance"
          class="user-form"
          :hacCancel="false"
          formKey="addNewFinance"
          loading-key="add-new-finance"
          ref="financeNewUser" />
      </paper>
      <paper>
        <template #title>Ordering Contact</template>
        <template #actions>
          <ez-checkbox
            form-key="sameOrdering"
            :checked="sameAsAccountOrdering"
            @change="() => sameAsAccountOrdering = !sameAsAccountOrdering"
            class="ez-checkbox"
            label="Same As Account"
            name="ordering"/>
        </template>
        <create-new-user
          :data="draftAdmin.ordering"
          :action="actionOrdering"
          :method="hasOrdering ? 'patch' : 'post'"
          @complete="addedOrdering"
          @formValid="(val) => formOrderingValid = val"
          :additionalData="!hasOrdering ? makeAdditionalData('venue_orderer') : {}"
          :disabled="sameAsAccountOrdering"
          @dirty="(val) => orderingDirty = val"
          class="user-form"
          :hacCancel="false"
          formKey="addNewOrdering"
          loading-key="add-new-ordering"
          ref="orderingNewUser" />
      </paper>
    </div>
    <hr>
    <h5>Additional Contacts</h5>
    <ez-user-list
      :users="users"
      :columns="['name', 'email']"
      :columnProps="{
        venue: { class: 'venue-cell' },
        role: { class: 'role-cell' },
      }"
      :headers="{
        accountName: () => 'Venue',
        venue: () => $t('global.venue').toLowerCase(),
      }"
      @addUser="onAddUser"
      @userClick="onUserClick"
      @removeUser="onRemoveUser">
        <new-venue-user-modal
          entity="Contact"
          ref="userModal"
          :formKey="formKey"
          :formAction="action"
          :user="user"
          @success="onSubmitSuccess">
        </new-venue-user-modal>
      <template #cta>Add Contact</template>
    </ez-user-list>
  </div>
</template>

<script>
/**
   * NewVenueUsers
   * @version 1.0.0
   * @since 2.3.0
   */

import { mapActions, mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import EzUserList from '@/views/common/users';
import User from '@/models/User';
import flash from '@/components/ui/FlashMessage';
import Paper from '@/components/layout/WizardOverviewPaper';
import CreateNewUser from '@/views/platform/venue/suppliers/onboarding/CreateNewUser';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import NewVenueUserModal from '@/views/platform/distributor/outlets/NewVenueUserModal';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzUserList,
    Paper,
    CreateNewUser,
    EzCheckbox,
    NewVenueUserModal,
  },
  data() {
    return {
      formKey: 'venue-user',
      formKeyAdmin: 'venue-user-admin',
      roles: [],
      selectedRoleId: null,
      selectedUserId: null,
      allowGroupPermissionsInternal: false,
      formFinanceValid: false,
      formOrderingValid: false,
      formOwnerValid: false,
      requiredValues: {
        name: null,
        email: null,
      },
      orderingDirty: false,
      sameAsAccountFinance: false,
      sameAsAccountOrdering: false,
      owner: {},
      financeUser: {},
      orderingUser: {},
    };
  },
  computed: {
    ...mapState('entities/venues', [
      'draft',
      'draftAdmin',
    ]),
    formValid() {
      return (this.formOwnerValid && (!this.sameAsAccountFinance && this.formFinanceValid))
        || (this.formOwnerValid && this.sameAsAccountFinance);
    },
    adminUserId() { return this.draftAdmin.id; },
    adminUserFormMethod() { return this.adminUserId !== -1 ? 'patch' : 'post'; },
    defaultDataForAdmin() {
      return { optionalPermissions: [], roleKey: 'venue_admin' };
    },
    user() {
      return User.query()
        .with('venue')
        .find(this.selectedUserId) || {};
    },
    isChecked() {
      return this.user?.primary;
    },
    adminUser() {
      return User.query().find(this.adminUserId) || {};
    },
    users() {
      return User.query()
        .with('venue')
        .where('groupId', this.draft.id)
        .where(user => user.role.key === 'venue_subscriber')
        .all();
    },
    action() {
      return `/distributor/venues/${this.draft.id}/users`;
    },
    actionFinance() {
      return this.hasFinance ? `${this.action}/${this.draftAdmin.finance.id}` : this.action;
    },
    actionOrdering() {
      return this.hasOrdering ? `${this.action}/${this.draftAdmin.ordering.id}` : this.action;
    },
    actionOwner() {
      return this.hasOwner ? `${this.action}/${this.draftAdmin.owner.id}` : this.action;
    },
    hasFinance() {
      return Object.keys(this.draftAdmin.finance).length > 0;
    },
    hasOrdering() {
      return Object.keys(this.draftAdmin.ordering).length > 0;
    },
    hasOwner() {
      return Object.keys(this.draftAdmin.owner).length > 0;
    },
  },
  watch: {
    formValid(val) {
      if (val) this.enableNextStep();
      else this.disableNextStep();
    },
  },
  methods: {
    ...mapActions('entities/users', [
      'initDistributor',
      'fetchUsers',
      'removeUser',
    ]),
    ...mapMutations('entities/venues', ['UPDATE_DRAFT_ADMIN']),
    makeAdditionalData(role) {
      return {
        roleKey: role,
      };
    },
    onNextStep() {
      this.$refs.ownerNewUser.$refs.userForm.onSubmit();
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    openModal() {
      this.$refs.userModal.open();
    },
    onUserClick({ id }) {
      this.fetchUsers({
        route: `/distributor/venues/${this.draft.id}/users/${id}`,
        groupId: this.draft.id,
        distributorId: this.draft.id,
      })
        .then(() => {
          this.selectedUserId = id;
          this.openModal();
        });
    },
    onSubmitSuccess({ data, isUpdate }) {
      this.closeModal();
      User.insertOrUpdate({ data: { ...data.data, groupId: this.draft.id } });
      flash.success({ title: `User successfully ${isUpdate ? 'updated' : 'added'}.` });
    },
    onAdminAdded({ data }) {
      this.UPDATE_DRAFT_ADMIN(data.data);
      User.insertOrUpdate({ data: { ...data.data, groupId: this.draft.id } });
      this.$emit('stepCompleted');
    },
    closeModal() {
      this.$refs.userModal.close();
    },
    onAddUser() {
      this.selectedUserId = -1;
      this.openModal();
    },
    onRemoveUser({ id }) {
      this.removeUser({ route: `/distributor/venues/${this.draft.id}/users/${id}`, id });
    },
    addedOwner({ data }) {
      this.owner = data.data;
      this.updateDraft({
        owner: this.owner,
      });
      if (!this.sameAsAccountFinance) {
        this.$refs.financeNewUser.$refs.userForm.onSubmit();
      } else if (!this.sameAsAccountOrdering && this.orderingDirty) {
        this.$refs.orderingNewUser.$refs.userForm.onSubmit();
      } else {
        this.completeStep();
      }
    },
    addedFinance({ data }) {
      this.financeUser = data.data;
      this.updateDraft({
        finance: this.financeUser,
      });
      if (!this.sameAsAccountOrdering && this.orderingDirty) {
        this.$refs.orderingNewUser.$refs.userForm.onSubmit();
      } else {
        this.completeStep();
      }
    },
    addedOrdering({ data }) {
      this.orderingUser = data.data;
      this.completeStep();
    },
    updateDraft(data) {
      this.UPDATE_DRAFT_ADMIN({
        ...data,
        sameAsAccountFinance: this.sameAsAccountFinance,
        sameAsAccountOrdering: this.sameAsAccountOrdering,
      });
    },
    completeStep() {
      this.updateDraft({
        owner: this.owner,
        finance: this.sameAsAccountFinance ? this.owner : this.financeUser,
        ordering: this.sameAsAccountOrdering ? this.owner : this.orderingUser,
      });
      this.$emit('stepCompleted');
    },
  },
  mounted() {
    this.sameAsAccountFinance = this.draftAdmin.sameAsAccountFinance
      ? this.draftAdmin.sameAsAccountFinance : false;
    this.sameAsAccountOrdering = this.draftAdmin.sameAsAccountOrdering
      ? this.draftAdmin.sameAsAccountOrdering : false;
  },
};
</script>

<style scoped lang="scss">
  .mandatory-users {
    display: flex;
    justify-content: space-between;
    .user-form {
      border-top: 0;
      padding-top: 0;
    }
    :deep() .overview-paper + .overview-paper {
      margin-top: 0;
      margin-left: 12px;
    }
  }
  .ordering-fields {
    display: flex;

    &__field {
      flex: 1;
      &:first-child { padding-right: 8px; }
      &:last-child { padding-left: 8px; }
    }
  }
  .ez-checkbox {
    :deep() .input-group {
      &__label {
        color: $color-gray-6C;
      }
    }
  }
</style>
