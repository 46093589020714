<template>
  <div
    class="distributor-venues"
    style="position: relative"
    ref="distributorVenues"
  >
    <div class="popover" :style="`top: ${tagPopOver.top}px; left: ${tagPopOver.left}px`" v-if="tagHover">
      <v-tag
        v-for="tag in tagPopOver.tags"
        :key="tag.id"
        :color="tag.color.colors[0]"
        :backgroundColor="tag.color.colors[1]"
      >
        {{ tag.name }}
      </v-tag>
    </div>
    <div class="filter-container">
      <ez-filter-list
        :filters="listFilters"
        @resetFilter="resetFilters(FilterArea.List)"
        @filterUpdated="(...$event) => updateFilters(FilterArea.List, $event)"
        class="mr-16"
      >
        <ez-input
          formKey="filters"
          label="search"
          name="term"
          :placeholder="`Search for a ${$t('global.venue')}`"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>
      <v-filter-dropdown
        :filters="dropdownFilters"
        @filterUpdated="$event => updateFilters(FilterArea.Dropdown, $event)"
        @resetFilters="resetFilters(FilterArea.Dropdown)"
        ref="filtersGroup"
        @filterClosed="filterClosed"
      >
        <template #firstRow>
          <ez-category-filter
            name="categoryId"
            label="Category"
            :categories="categories"
            :selected="dropdownFilters.categoryId"
            isFullWidth
            class="category-filter"
          />
        </template>
        <template #thirdRow>
          <ez-product-tags-filter
            class="tags-filter"
            name="productTags"
            :selectedTags="selectedProductTags"
            @change="onSelectedTagsChange"
          />
        </template>
      </v-filter-dropdown>
      <div class="distributor-venues__actions">
        <ez-simple-dropdown
          v-if="$permission.has('exportVenues')"
          class="import-export"
          placeholder="Import/Export CSV"
        >
          <template #dropdown>
            <ez-button
              type="link"
              formType="button"
              @click="openImportCsvModal"
            >
              Import CSV
            </ez-button>
            <ez-button
              type="link"
              formType="button"
              @click="exportCSV"
            >
              Export CSV
            </ez-button>
          </template>
        </ez-simple-dropdown>
        <ez-button-dropdown
          class="create-new-outlet"
          expand-on-click
        >
          <template #iconText>
            Add New {{ $t('global.venue') }}
          </template>
          <template #dropdown>
            <div
              class="create-new-item"
              v-if="canCreateNewVenues"
              @click="openConnectModal"
            >
              <font-awesome-icon
                class="mr-8 create-new-item--icon"
                icon="store-alt" />
              Create New {{ $t('global.venue') }}
            </div>
            <div
              class="create-new-item"
              @click="onSendOnboardingLink"
            >
              <font-awesome-icon
                class="mr-8 create-new-item--icon"
                icon="paper-plane" />
              Send Onboarding Link
            </div>
          </template>
        </ez-button-dropdown>
      </div>
    </div>

    <ez-alert type="purple" v-if="venueInvitationsCount > 0">
      <template #icon><font-awesome-icon icon="exclamation-circle"/></template>
      <template>
        {{ $tc('venues.connectionRequestAlert', venueInvitationsCount) }}
      </template>
      <template #cta>
        <router-link :to="{ name: 'distributor-outlets-requests' }">
          <ez-button type="purple-link">View Connections</ez-button>
        </router-link>
      </template>
    </ez-alert>

    <ez-table
      v-if="connectedVenues.length"
      :data="connectedVenues"
      :headers="{
        registeredAt: () => 'Date Account Opened',
      }"
      :columns="['name', 'category', 'registeredAt', 'tags', 'status', 'actions']"
      @rowClick="openSingleVenue"
      :columnProps="{
        name: { class: 'venue-cell' },
        status: { class: 'medium-cell' },
        actions: { class: 'large-cell' },
      }"
    >
      <template #cell-name="{ row }">
        <ez-entity-info :imgUrl="row.logo" :imgHasBorder="true" imgBorderRadius="50%">
          <span :title="row.name">{{ row.name }}</span>
          <ez-premium-badge v-if="$helpers.isPremium(row.accountType)" />
        </ez-entity-info>
      </template>
      <template #cell-category="{ row: { customerCategory } }">{{ customerCategory?.name || '-' }}</template>
      <template #cell-registeredAt="{ row: { accountOpenedAt } }">
        <div v-if="accountOpenedAt">{{ accountOpenedAt | date }}</div>
        <div v-else>-</div>
      </template>
      <template #cell-tags="{ row }">
        <div
          class="tags"
          v-if="row.tags.length"
          @mouseenter="tagsShowHover(row.id, row.tags)"
          @mouseleave="tagsRemoveHover"
          :ref="`tag-${row.id}`"
        >
          <v-tag
            class="mr-8"
            :color="row.tags[0].color.colors[0]"
            :backgroundColor="row.tags[0].color.colors[1]"
          >
            {{ row.tags[0].name }}
          </v-tag>
          <span v-if="row.tags.length > 1">+{{ row.tags.length - 1 }}</span>
        </div>
        <span v-else>-</span>
      </template>
      <template #cell-status="{ row: { status } }">
        <status-badge :status="status || 'draft'"></status-badge>
      </template>
      <template #cell-actions="{ row }">
        <template v-if="isDraft(row.status) || isActive(row.status)">
          <ez-button
            v-if="isDraft(row.status)"
            type="primary"
            @click.stop="sendInvite(row)"
            :stop-propagation="true"
            :disabled="isShopify(row.provider)"
          >
            Send Invite
          </ez-button>
          <ez-button
            class="invite-accepted"
            v-if="isActive(row.status)"
            type="secondary"
            :stop-propagation="true"
          >
            Invite Accepted
          </ez-button>
        </template>
        <ez-button
          v-else
          class="invite-accepted"
          type="secondary"
          :stop-propagation="true"
        >
          Invite Sent
        </ez-button>
      </template>
    </ez-table>

    <div v-if="isLoadingMoreVenues" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more
      v-if="!isLoadingVenues && !isLoadingMoreVenues && meta.nextId"
      @loadMore="loadMoreConnectedVenues"
    />

    <template v-if="!connectedVenues.length">
      <empty-state>
        <template #badge>
          <img src="@/assets/no-venue-empty-state.svg" width="160" alt=""></template>
        <template #title>{{ $t('xero.settings.venues.emptyState.title') }}</template>
      </empty-state>
    </template>

    <ez-form-modal class="connect-modal" size="xl" ref="connect" @open="focusOnInput">
      <template #title>Add New {{ $t('global.venue') }}</template>
      <template #content>
        <div class="connect-modal__content">
          <ez-autocomplete
            ref="autocomplete"
            :source="`/distributor/search/venues`"
            searchProperty="name"
            @selected="onVenueSelect"
            :label="`Search for a ${$t('global.venue')}`"
            disable-result-prop-name="differentCurrency"
            :disable-based-on-status="{
              property: 'invitationStatus',
              statuses: ['invitation_sent', 'invitation_received'],
            }"
          >
            <template #firstResult="{ input }">
              <li>
                <ez-entity-info :imgUrl="defaultImage" :imgHasBorder="true" imgBorderRadius="50%">
                  <span>Create New "{{ input }}" {{ $t('global.venue') }}</span>
                </ez-entity-info>
              </li>
            </template>
            <template #result="{ result }">
              <div
                class="width-100"
                v-tooltip="{
                  content: tooltipContent(result),
                  classes: ['tooltip-general'],
                }"
              >
                <div class="u-flex-space width-100">
                  <div
                    :class="[
                      'venue-result',
                      { 'venue-result--disabled': result.differentCurrency
                        || isInvitationSent(result) }
                    ]"
                  >
                    <img :src="result.logo" alt="" class="venue-result__image">
                    <div class="venue-result__info">
                      <div class="venue-result__title">
                        <span :title="result.name">{{ result.name }}</span>
                        <ez-premium-badge v-if="$helpers.isPremium(result.accountType)" />
                      </div>
                      <div class="venue-result__label">
                        <small v-if="result.xeroId">Xero</small>
                        <small v-else>{{ oezPlatformName }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="u-flex-row">
                    <status-badge v-if="isInvitationSent(result)" status="invitation_sent" />
                    <status-badge v-if="result.differentCurrency" status="currency-no-matching" />
                  </div>
                </div>
              </div>
            </template>
          </ez-autocomplete>

          <ez-selected-venue
            ref="selectedVenueForm"
            @anyChecked="(ev) => this.isAnyChecked = ev"
            @success="onOnboardingSuccess"
            @onboarding="() => isOnboarding = !isOnboarding"
            @remove="removeSelectedVenue"
            :venue="selectedVenue" />
        </div>
      </template>
      <template #footer>
        <ez-button type="link" @click="closeConnectModal">Cancel</ez-button>
        <ez-button @click="connectSelected" :disabled="isDisabled">
          {{ selectedVenue && selectedVenue.xeroId ? 'Connect' : 'Setup Products & Connect' }}
        </ez-button>
      </template>
    </ez-form-modal>
    <ez-csv-outlet-upload-modal
      ref="csvUploadModal"
      :uploadCSV="uploadAction"
      :onUploadFinished="onUploadFinished"
    />
  </div>
</template>

<script>
/**
 * Outlets
 * @version 1.0.0
 * @since 2.2.0
 */

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { distributorXeroConnectContact } from '@/api/endpoints/xero';
import { LOADING_KEY, PENDING_INVITE_SENT, TagType } from '@/util/constants';
import downloadAttachment from '@/util/downloadAttachment';
import { falsy, debounce, clone } from '@/util/utils';
import StatusBadge from '@/views/common/status-badge';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzCsvOutletUploadModal from '@/components/ui/Modal/EzCsvOutletUploadModal';
import { EzSelectedVenue } from '@/components/ui/SelectedItem';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown.vue';
import { EzFormModal } from '@/components/ui/Modal';
import EzAutocomplete from '@/components/ui/Autocomplete';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzFilterList from '@/components/ui/FilterList/EzFilterList.vue';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table';
import EzInput from '@/components/ui/Input';
import EzAlert from '@/components/ui/Alert';
import flash from '@/components/ui/FlashMessage';
import VTag from '@/components/v3/elements/VTag';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import EzProductTagsFilter from '@/components/ui/Filter/ProductTags.vue';

const FilterArea = {
  List: 'list',
  Dropdown: 'dropdown',
};

export default {
  name: 'distributor-outlets',
  components: {
    StatusBadge,
    EmptyState,
    EzCsvOutletUploadModal,
    EzSelectedVenue,
    EzButtonDropdown,
    EzSimpleDropdown,
    EzFormModal,
    EzAutocomplete,
    EzPremiumBadge,
    EzEntityInfo,
    EzFilterList,
    VFilterDropdown,
    EzCategoryFilter,
    EzProductTagsFilter,
    EzLoadMore,
    EzSpinner,
    EzButton,
    EzTable,
    EzInput,
    EzAlert,
    VTag,
  },
  data() {
    return {
      connectedVenues: [],
      meta: {},
      dropdownFilters: {},
      listFilters: { term: null },
      selectedVenue: null,
      isOnboarding: false,
      isAnyChecked: false,
      tagHover: false,
      tagPopOver: {
        tags: [],
        top: null,
        left: null,
      },
      FilterArea,
      selectedProductTags: [],
      appliedProductTags: [],
      categories: [],
    };
  },
  computed: {
    ...mapGetters('defaultImages', ['getDefaultImage']),
    ...mapGetters('loading', ['getLoading']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('entities/users', ['venueInvitationsCount']),
    isLoadingVenues() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_CONNECTED_VENUES);
    },
    isLoadingMoreVenues() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_CONNECTED_VENUES);
    },
    defaultImage() {
      return this.getDefaultImage('venue');
    },
    distributorId() {
      return this.loggedUser.distributor.id;
    },
    canCreateNewVenues() {
      return this.$permission.isPremium;
    },
    isPremiumOrIsLite() {
      return this.selectedVenue ? (this.$helpers.isPremium(this.selectedVenue.accountType)
        || this.$helpers.isLite(this.selectedVenue.accountType)) : false;
    },
    isDisabled() {
      if (this.isPremiumOrIsLite) return false;
      if (this.isOnboarding) return !this.isAnyChecked;
      return !this.selectedVenue;
    },
    filters() {
      return { ...this.listFilters, ...this.dropdownFilters };
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'uploadDistributorOutletCSV',
      'distributorDownloadOutletsCsv',
      'distributorSendInvite',
      'distributorFetchConnectedVenues',
      'distributorFetchCustomerCategories',
    ]),
    ...mapActions('defaultImages', ['fetchDefaultImages']),
    ...mapActions('tags', ['fetchTagsByType']),
    ...mapMutations('tags', [
      'CLEAR_ALL_TAGS',
      'SET_SELECTED_TAGS',
    ]),
    ...mapMutations('entities/venues', ['SET_ACCEPT_AND_SEND_ONBOARDING_LINK_DATA']),
    async fetchConnectedVenues(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_CONNECTED_VENUES) {
      const query = {
        ...(this.meta.limit ? { limit: this.meta.limit } : {}),
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
        ...(this.filters.term ? { term: this.filters.term } : {}),
        ...(this.filters.categoryId ? { customerCategoryId: this.filters.categoryId } : {}),
        ...{ tagIds: this.filters.tagIds },
      };

      const { data } = await this.distributorFetchConnectedVenues({ query, loadingKey });
      if (loadingKey === LOADING_KEY.DISTRIBUTOR_FETCH_CONNECTED_VENUES) {
        this.connectedVenues = data.data;
      } else {
        this.connectedVenues = [...this.connectedVenues, ...data.data];
      }
      this.meta = { ...data.meta };
    },
    async refreshConnectedVenues() {
      this.connectedVenues = [];
      this.meta = {};
      await this.fetchConnectedVenues();
    },
    async loadMoreConnectedVenues() {
      await this.fetchConnectedVenues(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_CONNECTED_VENUES);
    },
    async fetchCustomerCategories() {
      const { data: customerCategories } = await this.distributorFetchCustomerCategories();
      this.categories = customerCategories.data;
    },
    isInvitationSent(venue) {
      return venue.invitationStatus === PENDING_INVITE_SENT;
    },
    tooltipContent(venue) {
      if (!venue) {
        return '';
      }
      const venueStr = this.$t('global.venue').toLowerCase();
      const msg = `Can’t connect with a ${venueStr} that has a different default currency.`;
      return venue.differentCurrency ? msg : '';
    },
    openSingleVenue(venue) {
      if (!falsy(venue.provider)) {
        this.$router.push({ name: 'distributor-outlets-sales-settings', params: { id: venue.id } });
        return;
      }
      const routeData = this.$router.resolve({ name: 'distributor-outlets-single', params: { id: venue.id } });
      window.open(routeData.href, '_blank');
    },
    onVenueSelect(venue) {
      if (venue.first) {
        this.closeConnectModal();
        this.$router.push({
          name: 'distributor-outlets-new',
          params: { prefilledName: venue.query },
        });
      } else {
        this.selectedVenue = venue.selected;
      }
    },
    removeSelectedVenue() {
      this.selectedVenue = null;
      this.isOnboarding = false;
      this.$refs.selectedVenueForm.$refs.selectedItem.resetOnOff();
    },
    async connectSelected() {
      if (this.selectedVenue.xeroId) { // Xero Contact
        await distributorXeroConnectContact({
          contactId: this.selectedVenue.xeroId,
        });
        this.closeConnectModal();
        flash.success({
          title: 'Xero contact successfully created.',
        });
        await this.refreshConnectedVenues();

        return;
      }
      this.SET_ACCEPT_AND_SEND_ONBOARDING_LINK_DATA({
        venue: this.selectedVenue,
      });
      this.closeConnectModal();
      await this.$router.push({ name: 'distributor-outlets-connect' });
    },
    onOnboardingSuccess() {
      flash.success({
        title: 'Onboarding link sent!',
        message: `Good job! ${this.selectedVenue.name} will be added to your list
        after they complete the onboarding.`,
      });
      this.closeConnectModal();
      this.isOnboarding = false;
    },
    focusOnInput() {
      this.$refs.autocomplete.focus();
    },
    closeConnectModal() {
      this.$refs.connect.close();
      this.selectedVenue = null;
    },
    openConnectModal() {
      this.$refs.connect.open();
    },
    async resetFilters(area) {
      if (area === FilterArea.List) {
        Object.keys(this.listFilters).forEach((key) => { this.listFilters[key] = null; });
      } else if (area === FilterArea.Dropdown) {
        this.selectedProductTags = [];
        this.appliedProductTags = [];
        this.dropdownFilters = {};
      }
      await this.refreshConnectedVenues();
    },
    updateFilters: debounce(async function deb(area, event) {
      if (area === FilterArea.List) {
        const [filterName, value] = event;
        this.listFilters = { ...this.listFilters, [filterName]: value };
      } else if (area === FilterArea.Dropdown) {
        const tagIds = this.selectedProductTags.map(item => item.id);
        this.dropdownFilters = {
          ...this.dropdownFilters,
          ...((typeof event.categoryId === 'object' && event.categoryId !== null) && { categoryId: event.categoryId.id }),
          ...(tagIds.length ? { tagIds } : { tagIds: null }),
        };
        this.appliedProductTags = clone(this.selectedProductTags);
        this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      }
      await this.refreshConnectedVenues();
    }, 300),
    openImportCsvModal() {
      this.$refs.csvUploadModal.open();
    },
    async exportCSV() {
      const { data, headers } = await this.distributorDownloadOutletsCsv();
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : `${this.me.name}'s outlets.xlsx`;
      downloadAttachment(data, name);
    },
    async onUploadFinished() {
      await this.refreshConnectedVenues();
    },
    uploadAction(data) {
      return this.uploadDistributorOutletCSV({ data });
    },
    isDraft(status) {
      return status === 'draft';
    },
    isActive(status) {
      return status === 'active';
    },
    isShopify(provider) {
      return provider === 'shopify';
    },
    async sendInvite(outlet) {
      const data = new FormData();

      data.append('ids[0]', outlet.id);
      try {
        await this.distributorSendInvite({ data });
        await this.refreshConnectedVenues();
        flash.success({
          title: 'Invite successfully sent!',
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    onSendOnboardingLink() {
      this.$router.push({
        name: 'distributor-outlets-send-onboarding-link',
      });
    },
    tagsShowHover(id, tags) {
      this.tagHover = true;
      this.tagPopOver.tags = tags;
      const { top, left, bottom } = this.$refs[`tag-${id}`].getBoundingClientRect();
      const topParent = this.$refs.distributorVenues.getBoundingClientRect().top;
      const leftParent = this.$refs.distributorVenues.getBoundingClientRect().left;
      // Adding the height of the element
      this.tagPopOver.top = top - topParent + (bottom - top);
      this.tagPopOver.left = left - leftParent;
    },
    tagsRemoveHover() {
      this.tagHover = false;
      this.tagPopOver = {
        tags: [],
        top: null,
        left: null,
      };
    },
    filterClosed() {
      this.selectedProductTags = clone(this.appliedProductTags);
    },
    onSelectedTagsChange(tags) {
      this.selectedProductTags = tags;
    },
  },
  async created() {
    await this.fetchDefaultImages();
    await this.fetchTagsByType({ query: { type: TagType.Customer } });
    this.SET_SELECTED_TAGS([]);
  },
  async mounted() {
    await Promise.all([this.refreshConnectedVenues(), this.fetchCustomerCategories()]);
  },
};
</script>

<style lang="scss" scoped>
.venue-result--disabled {
  opacity: 0.7;
}

.distributor-venues {
  @include platform-tab-content();

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;

    .import-export {
      width: auto;
      margin-right: 1rem;

      :deep() .ez-simple-dropdown {
        &__label {
          margin-right: 16px;
        }

        &__display-container--active {
          background-color: $color-gray-E9;
        }

        &__dropdown {
          width: max-content;
          overflow: visible;
        }
      }

      .button--link {
        width: 100%;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        text-align: left;
        color: $color-gray-25;
        font-weight: 400;

        &:hover {
          background: $color-gray-F5;
        }

        &:disabled {
          color: $color-gray-6C;
          background-color: transparent;
        }
      }
    }
  }

  .venue-cell {
    width: 240px;
  }
  @media (min-width: 1200px) {
    .venue-cell {
      width: 300px;
    }
  }
  @media (min-width: 1440px) {
    .venue-cell {
      width: 400px;
    }
  }
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $spacing-24 0;

  :deep() .select-search {
    color: $color-gray-6C;
  }
}

.autocomplete {
  padding-bottom: $spacing-16;
  margin-bottom: $spacing-16;
  border-bottom: 1px dashed $color-gray-E9;
}

.venue-result {
  @extend %flex-center;

  &__image {
    @include size(32px);
    border-radius: 50%;
    border: 1px solid #DEE1E4;
  }

  &__info {
    margin-left: 8px;
  }

  &__titele {
    color: #252631;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  &__label {
    color: #6C7995;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
}
.connect-modal {
  :deep() .modal__text {
    padding-right: 0;
  }
  &__content {
    max-height: 564px;
    overflow-y: auto;
    padding-right: 20px;

    .autocomplete {
      border: unset;
    }
  }
}

.invite-accepted {
  box-shadow: none;
  cursor: not-allowed;
  opacity: .6;
}

.create-new-outlet {
  :deep() .button-dropdown {
    &__toggle-container {
      .button span {
        margin-right: 0 !important;
        padding-right: 15px;
      }
    }


    &__dropdown {
      width: 200px;
      .create-new-item {
        padding: 14px 10px;
        cursor: pointer;
        @include font-size(14px, 20px);
        color: $color-gray-25;
        &--icon {
          color: $color-gray-6C;
        }
        &:hover {
          background-color: #F5F6FA;
        }
      }
    }
  }
}

.popover {
  position: absolute;
  background-color: white;
  margin-top: 10px;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  padding: 16px;
  max-width: 360px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  overflow: visible;
}

.category-filter {
  :deep() .parent.ez-option {
    font-weight: 400;
  }
}

.tags-filter {
  :deep() .tags-wrapper {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  :deep() .tags-wrapper__selected {
    padding-bottom: 22px;
  }

  :deep() .input-group.search {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    font-weight: 600;
  }

  :deep() .input-group__label {
    margin-bottom: 0;
    margin-right: 16px;
  }
}
:deep() .tags {
  display: flex;

  .v-tag {
    min-width: 0;
    flex-shrink: 1;
    overflow: hidden;
  }
}

:deep() .category-dropdown .ez-select__display-container {
  height: 2.25rem;
}
</style>
