<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import uuid from 'uuid/v4';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import Product from '@/models/Product';
import { clone } from '@/util/utils';
import { UNIT_TYPE_FRACTIONAL } from '@/util/constants';
import { wizardListenerMixin } from '@/mixins/wizard';

import EzMaskInput from '@/components/ui/MaskInput';
import EzButton from '@/components/ui/Button';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

/**
 * Warehouses
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Warehouses',
  components: {
    EzMaskInput,
    EzButton,
    VSelectSearch,
  },
  props: {
    warehouses: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      selectedWarehouses: [],
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/products', ['draft']),
    disabled() {
      if (this.selectedWarehouses.find(w => w.id.toString().includes('empty'))) return true;
      return this.warehouses.length === this.selectedWarehouses.length;
    },
    product() {
      return Product.find(this.draft.id);
    },
    isFractional() {
      return this.product?.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
  },
  methods: {
    ...mapActions('entities/products', ['distributorUpdateProduct']),
    ...mapMutations('entities/products', ['UPDATE_DRAFT']),
    async onNextStep() {
      await this.updateProduct();
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    addParLevel() {
      this.selectedWarehouses = [
        ...this.selectedWarehouses,
        { id: `empty-${uuid()}`, parLevel: 0 },
      ];
    },
    selectWarehouse(selected, idx) {
      const updated = selected.reset
        ? { id: `empty-${uuid()}`, parLevel: 0 }
        : { ...this.selectedWarehouses[idx], ...selected };
      this.selectedWarehouses.splice(idx, 1, updated);
    },
    updateParLevel(id, val) {
      const idx = this.selectedWarehouses.findIndex(w => w.id === id);
      this.selectedWarehouses[idx].parLevel = val;
    },
    removeParLevel(id) {
      const idx = this.selectedWarehouses.findIndex(w => w.id === id);
      this.selectedWarehouses.splice(idx, 1);
    },
    findSelected(id) {
      return this.warehouses.find(w => w.id === id);
    },
    async updateProduct() {
      const warehouses = this.getValidWarehousesFrom(this.selectedWarehouses);
      const warehouseProducts = warehouses.map(w => ({ warehouseId: w.id, parLevel: w.parLevel }));

      let productData = clone(this.product);

      // removeDTP - include default tier pricing
      const removeDTP = !this.product.defaultTierPricing.tiers.length;
      if (removeDTP) {
        const { defaultTierPricing, ...rest } = productData;
        productData = rest;
      }

      const tagIds = productData.tags.map(item => item.id);

      const { data } = await this.distributorUpdateProduct({
        productId: this.product.id,
        data: {
          ...productData,
          warehouseProducts,
          tagIds,
          ...(productData.isTaxDefault ? { tax: null } : { tax: productData.tax }),
        },
      });

      this.UPDATE_DRAFT({ warehouses });
      Product.insertOrUpdate({ data: data.data });
    },
    getValidWarehousesFrom(warehouses) {
      return warehouses.filter(w => !w.id.toString().includes('empty') && !!w.parLevel);
    },
    transformer(data) {
      return data.filter(({ id }) => !this.selectedWarehouses.map(w => w.id).includes(id));
    },
  },
  created() {
    this.enableNextStep();
    this.selectedWarehouses = this.draft.warehouses.length
      ? clone(this.draft.warehouses)
      : [{ id: `empty-${uuid()}`, parLevel: 0 }];
  },
};
</script>

<template>
  <div class="warehouses">
    <div v-for="(wh, idx) in selectedWarehouses" :key="idx" class="warehouses__warehouse">
      <ez-button
        v-if="idx"
        type="link"
        class="warehouses__warehouse__remove"
        @click="removeParLevel(wh.id)"
        :data-cy="`${supplierCy.PRODUCTS.NEW_PRODUCT.PAR_LEVEL.BUTTON__REMOVE}-${idx}`"
      >
        <font-awesome-icon icon="times" /> Remove
      </ez-button>
      <v-select-search
        v-if="warehouses.length"
        label="Warehouse Name"
        placeholder="Select Warehouse"
        class="warehouses__warehouse__select"
        :data="warehouses"
        :selected="findSelected(wh.id)"
        :transformer="transformer"
        @selected="obj => selectWarehouse(obj, idx)"
        is-full-width
        :data-cy="`${supplierCy.PRODUCTS.NEW_PRODUCT.PAR_LEVEL.BUTTON__SELECT}-${idx}`"
      />
      <ez-mask-input
        form-key=""
        type="input"
        name="parLevel"
        label="Par Level"
        class="warehouses__warehouse__par-level mt-16"
        :precision="isFractional ? 2 : 0"
        :value="wh.parLevel"
        :allow-negative-value="false"
        :price-prefix="false"
        @input="updateParLevel(wh.id, $event)"
        :data-cy="`${supplierCy.PRODUCTS.NEW_PRODUCT.PAR_LEVEL.INPUT__PAR_LEVEL}-${idx}`"
      />
      <hr />
    </div>
    <ez-button
      type="secondary"
      :disabled="disabled"
      @click="addParLevel"
      isFullWidth
      :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.PAR_LEVEL.BUTTON__ADD"
    >
      <font-awesome-icon icon="plus" />
      Add Par Level
    </ez-button>
  </div>
</template>

<style lang="scss" scoped>
.warehouses {
  &__warehouse {
    position: relative;

    &__select {
      :deep() .select-search {
        &__trigger span {
          @include font-size(14px, 20px);

          &:not(.select-search__value) {
            color: $color-gray-6C;
          }
        }

        &__search-wrapper {
          display: none;
        }

        &__item {
          @include font-size(14px, 20px);
        }
      }
    }

    &__par-level {
      :deep() .mask-input__input {
        font-weight: normal;
      }
    }

    &__remove {
      @include font-size(12px, 18px);
      position: absolute;
      top: 0;
      right: 0;
      height: auto;
    }
  }
}
</style>
