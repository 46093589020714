<script>
import EzButton from '@/components/ui/Button/EzButton';
import { EzModal } from '@/components/ui/Modal';
import VMergeProducts from '@/components/v3/patterns/VMergeProducts/index';
import { distributorGetXeroProducts, distributorPutSyncItems } from '@/api/endpoints/xero';
import { mapMutations, mapState } from 'vuex';
import flash from '@/components/ui/FlashMessage';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzLoader from '@/components/ui/Loader/EzLoader';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  components: {
    EzButton,
    EzModal,
    VMergeProducts,
    EmptyState,
    EzLoader,
  },
  data() {
    return {
      products: [],
      syncButtonDisabled: true,
      syncItems: [],
      loading: false,
    };
  },
  computed: {
    ...mapState('xero', ['selectedProducts']),
  },
  methods: {
    ...mapMutations('xero', ['UPDATE_SELECTED_PRODUCTS', 'CLEAR_STATE']),

    setDefaultSelectedProducts(products) {
      const defaultProducts = {};
      products.forEach((product) => {
        defaultProducts[product.code] = false;
      });
      this.UPDATE_SELECTED_PRODUCTS(defaultProducts);
    },
    close() {
      this.$refs.modal.close();
      this.$emit('close');
    },
    open() {
      this.getProducts();
      this.$refs.modal.open();
      this.$emit('open');
    },
    onClose() {
      this.CLEAR_STATE();
      this.$emit('close');
    },
    async getProducts() {
      this.loading = true;
      try {
        const { data: { data } } = await distributorGetXeroProducts();
        this.products = data;

        this.setDefaultSelectedProducts(this.products);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      this.loading = false;
    },
    async productsSync() {
      Object.keys(this.selectedProducts).forEach((item) => {
        if (!this.selectedProducts[item]) {
          this.syncItems.push(item);
        }
      });
      try {
        await distributorPutSyncItems(this.syncItems);
        this.$emit('synced');
        this.close();
        flash.success({
          title: this.$t('xero.settings.products.modal.flashMessages.success.title'),
        });
      } catch (e) {
        flash.error({
          title: this.$t('xero.settings.products.modal.flashMessages.error.title'),
        });
      }
    },
    isAnySelected(val) {
      this.syncButtonDisabled = !val;
    },
  },
};
</script>
<template>
  <EzModal ref="modal" @close="onClose()">
    <div class="modal__inner">
      <button class="modal__close" @click="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <!-- eslint-disable-next-line max-len -->
          <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"/>
        </svg>
      </button>
      <div class="modal__title">
        <h2>{{ $t('xero.settings.products.modal.title') }}</h2>
      </div>
      <div class="modal__content">
        <v-merge-products class="products-merge-list"
                          v-if="products.length"
                          :products="products"
                          @selected="isAnySelected"/>
        <template v-if="!products.length">
          <empty-state>
            <template #badge>
              <img src="@/assets/no-product-empty-state.svg" alt=""></template>
            <template #title>
              {{ $t('xero.settings.products.modal.emptyState.title') }}
            </template>
          </empty-state>
        </template>
      </div>
      <div class="modal__footer">
        <slot name="footer">
          <ez-button @click="close()" type="link">Cancel</ez-button>
          <ez-button
            @click="productsSync"
            :disabled="syncButtonDisabled"
          >{{ $t('xero.settings.products.modal.buttonText') }}</ez-button>
        </slot>
      </div>
    </div>
    <ez-loader :show="loading">Loading...</ez-loader>
  </EzModal>
</template>
<style lang="scss" scoped>
  .modal {
    &__inner {
      position: relative;
      padding: px-to-rem(24px) px-to-rem(24px) px-to-rem(16px) px-to-rem(24px);
      max-width: 640px;
    }

    &__close {
      @extend %button-reset;
      position: absolute;
      top: 25px;
      right: px-to-rem(24px);
      @include font-size(18px);
      cursor: pointer;

      svg {
        fill: $secondary-color;
      }
    }

    &__title {
      margin-bottom: 1.5em;
      @extend %flex-center;

      h2 {
        margin: 0;
        @include font-size(20px);
        font-weight: 500;
      }
    }

    &__content {
      max-height: 564px;
      overflow: auto;

      .products-merge-list {
        :deep() .floating-bar {
          top: 0;
          margin-bottom: 0;
        }
        :deep() table {
          th, td {
            &:last-child {
              padding-right: 16px;
            }
          }
        }
      }

      :deep() .ez-empty-state {
        &__image {
          max-width: none;
        }
        img {
          width: 256px;
          height: 118px;
        }
      }
    }

    &__footer {
      @extend %flex-center;
      justify-content: flex-end;
      padding-top: 16px;
    }
  }
</style>
