<script>
/**
 * Requests
 * @version 1.0.0
 * @since 2.3.0
 */

import EzTable from '@/components/ui/Table';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    EzTable,
    VVenueEntityInfo,
  },
  data() {
    return {
      connectionRequests: [],
    };
  },
  computed: {
    ...mapGetters('entities/users', ['venueInvitationsCount']),
  },
  methods: {
    ...mapActions('entities/venues', ['distributorFetchConnectionRequests']),
    async refresh() {
      const { data } = await this.distributorFetchConnectionRequests();
      this.connectionRequests = data.data;
      if (this.venueInvitationsCount < 1) this.$router.push({ name: 'distributor-outlets-all' });
    },
    openSingleVenue(venue) {
      this.$router.push({ name: 'distributor-outlets-requests-single', params: { id: venue.id } });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
<template>
  <div class="mt-16">
    <EzTable
      :data="connectionRequests"
      :headers="{
        name: () => $t('venues.venueNameColumn'),
      }"
      :columns="['name']"
      @rowClick="openSingleVenue"
    >
      <template #cell-name="{ row }">
        <VVenueEntityInfo :venue="row" />
      </template>
    </EzTable>
  </div>
</template>
<style lang="scss" scoped></style>
