/* eslint-disable */
import * as d3 from 'd3';
import { get, mergeDeep } from '@/util/utils';

/**
 * Given a string wrap it into multiple lines based on the given width.
 * Split the `<text>` into multiple `<tspan>` so they fit the width.
 *
 * @param {string} label
 * @param {number} width
 */
function wrap(label, width) {
  label.each(function () {
    const maxNumberOfLines = 2;
    let lines = 0;
    const text = d3.select(this);
    const words = text.text().split(/\s+/).reverse();
    let word = words.pop();
    let line = [];
    let lineNumber = 0;
    const lineHeight = 1.1; // ems
    const y = text.attr('y');
    const dy = parseFloat(text.attr('dy'));
    let tspan = text.text(null).append('tspan').attr('x', 0).attr('y', y)
      .attr('dy', `${dy}em`);
    while (word && lines < maxNumberOfLines) {
      // Truncate long words
      if (word.length > 11) {
        word = `${word.substring(0, 8)}...`;
      }
      line.push(word);
      tspan.text(line.join(' '));
      if (tspan.node().getComputedTextLength() > width) {
        lines += 1;
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        lineNumber += 1;
        if (lines < maxNumberOfLines) {
          tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', `${lineNumber * lineHeight + dy}em`)
            .text(word);
        }
      }
      word = words.pop();
    }
  });
}

const margin = {
  top: 20, right: 0, bottom: 120, left: 10,
};
const barWidth = 8;
const minBarMargin = 80;
const tooltipYMargin = 10;
const yAxisWidth = 50;
/**
 * Number of ticks on a Y axis.
 *
 * D3js:
 * The specified count is only a hint; the scale may return more or fewer values
 * depending on the input domain.
 * @type {number}
 */
const NUMBER_OF_TICKS = 5;
/**
 * The area behind the bar that will be used to trigger the events and hover effect.
 * The maximum width this can be is `minBarMargin + barWidth`
 *
 * @type {number}
 */
const HOVER_AREA_WIDTH = 50;
/**
 * Radius of the circle that appears on top of the bar on hover.
 * @type {number}
 */
const HOVER_CIRCLE_R = 6;


export default class BarChartV2 {
  constructor(parent, options) {
    this.parent = typeof parent === 'string' ? document.querySelector(parent) : parent;

    if (!(this.parent instanceof HTMLElement)) {
      throw new Error('Parent provided is not an HTML element');
    }

    this.data = options.data;
    this.options = options.options;
    this.events = this.options.on;

    this.setup();
    this.draw();
  }

  getFormatting() {
    this.yFormatting = get(this.options, 'yAxis.format');
    this.tooltipFormatting = get(this.options, 'tooltip.format') || (d => d3.format(',.0f')(d));
  }

  setup() {
    this.getFormatting();
    this.values = this.data.map(o => o.value);
    this.maxValue = Math.max(...this.values);
    this.calculateDomainMaxValue();
    this.minValue = Math.min(...this.values);
    this.hasLinearScale = true;

    // Clear parent
    this.parent.innerHTML = '';
    this.parent.classList.add('chart');

    this.yAxisContainer = document.createElement('div');
    this.yAxisContainer.classList.add('chart__yaxis-container');
    this.parent.appendChild(this.yAxisContainer);

    this.chartContainer = document.createElement('div');
    this.chartContainer.classList.add('chart__container');
    this.parent.appendChild(this.chartContainer);
  }

  animate() {
    // Animation
    this.xAxis.selectAll('.bar')
      .transition()
      .duration(300)
      .attr('y', d => this.yDomain(d.value))
      .attr('height', d => this.baseHeight - this.yDomain(d.value))
      .delay((d, i) => (i * 100));
  }

  update(data, options) {
    this.data = data;

    if (this.data.length < 1) return;

    if (options) {
      this.options = mergeDeep(this.options, options);
    }

    this.destroy();
    this.setup();
    this.draw();
  }

  draw() {
    this.calculateSize();
    this.generateDomain();
    this.makeYAxis();
    this.makeXAxis();
    this.makeTooltip();
    this.makeGridlines();
    this.makeBars();
    this.animate();
  }

  calculateSize() {
    this.baseHeight = 250 - margin.top + margin.bottom;
    this.height = this.baseHeight + margin.top + margin.bottom;

    // eslint-disable-next-line max-len
    const neededWidth = this.values.length * (minBarMargin + barWidth) + margin.left + margin.right;
    const parentWidth = this.parent.getBoundingClientRect().width - yAxisWidth;

    this.width = Math.max(parentWidth, neededWidth);
  }

  generateDomain() {
    // X Domain.
    this.xDomain = d3.scaleBand()
      .domain(this.data.map(d => d.entity.id))
      .range([margin.left, this.values.length * (minBarMargin + barWidth) + margin.left])
      .round(true)
      .paddingInner(0.1);

    this.yDomain = d3.scaleLinear()
      .domain([0, this.domainMaxValue])
      .range([this.baseHeight, 0]);
  }

  makeXAxis() {
    this.xAxis = d3.select(this.chartContainer)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height);

    const xCall = d3.axisBottom(this.xDomain)
      .tickSize(0)
      .tickFormat(id => this.data.find(dataObj => dataObj.entity.id === id).entity.name);

    this.xAxis.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(${margin.left}, ${this.baseHeight + margin.top})`)
      .call(xCall)
      .selectAll('.tick text')
      .call(wrap, this.xDomain.bandwidth())
      .attr('transform', 'translate(-20, 36) rotate(-45)')
      .style('text-anchor', 'middle')
      .style('font-size', 14)
      .style('fill', '#252631')
      .select('.domain')
      .remove();
  }

  makeYAxis() {
    this.yAxis = d3.select(this.yAxisContainer)
      .append('svg')
      .attr('height', this.baseHeight)
      .attr('width', 50)
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    this.yCall = d3.axisLeft(this.yDomain).tickArguments([NUMBER_OF_TICKS]).tickSize(0);

    if (this.yFormatting) this.yCall.tickFormat(this.yFormatting)

    this.yAxis.append('g')
      .attr('class', 'y axis')
      .call(this.yCall)
      .attr('dx', '-0.3em')
      .attr('transform', 'translate(45, 0)');
  }

  /**
   * Calculate the Y domain base on the max value.
   * If we have a chart that has a max value of `1700` our tick will be `[0, 500, 1000, 1500]`
   * Which means our max value will be above the max tick. This means we need to insert another tick.
   *
   * To insert a tick we must expend the Y domain so that D3 thinks that our max value is bigger.
   * We do this by finding the next tick in the sequence and subtracting the difference to the max.
   *
   * @see generateMissingMaxTick
   */
  calculateDomainMaxValue() {
    /**
     *  Increment the domain value by 10% so we have a tick above the max value.
     *
     *  @example
     *  If our chart goes from `0 - 98 000` the tick will be `[0, 20000, 40000, 60000, 80000]`
     *  Therefore, the max value will be above the last tick. We extend the domain to
     *  be 10% bigger than the actual value.
     *
     * @type {number}
     */
    this.domainMaxValue = this.maxValue * 1.1;

    /**
     * Create a temporary Y domain just to generate the ticks for the domain.
     * We will use these ticks to see if wee need to add more before generating the real domain.
     */
    const tmpDomain = d3.scaleLinear().domain([0, this.domainMaxValue])
    this.yTickValues = d3.axisLeft(tmpDomain).scale().ticks(NUMBER_OF_TICKS);


    let missingValueToNextTick = 0;
    if (!this.hasMaxTick()) {
      missingValueToNextTick = this.generateMissingMaxTick();
    }

    this.domainMaxValue = this.domainMaxValue + missingValueToNextTick;
  }

  /**
   * Check if the maximum value is below the last tick.
   * If it isn't we need to generate one more tick.
   *
   * @return {boolean} If the max value is below the last tick.
   */
  hasMaxTick() {
    const ticksLen = this.yTickValues.length;
    if (ticksLen < 2) return true;
    return this.yTickValues[ticksLen - 1] > this.maxValue;
  }

  /**
   * If the max value is above the last tick we need to generate one more.
   * To generate one more tick we need to extend the yDomain for the amount missing to the next tick.
   *
   * 1. Find the next tick
   *    We can do this by finding the delta value between two consecutive ticks.
   *    Add the delta value to the final tick an that will give us the next tick.
   *
   * 2. Find the delta between max value
   *    Subtract the maxvalue from the next tick we calculated.
   *    This will give us how much we need to add to the domain to get to the next tick.
   *
   * @return {boolean|number}
   */
  generateMissingMaxTick() {
    const ticksLen = this.yTickValues.length;
    if (ticksLen < 2) return true;

    const lastValue = this.yTickValues[ticksLen - 1];
    const nextToLastValue = this.yTickValues[ticksLen - 2];
    const deltaValue = lastValue - nextToLastValue;

    return Math.ceil((lastValue + deltaValue) - this.domainMaxValue);
  }

  makeBars() {
    /**
     * Create a group for bars and start the data.
     */
    const group = this.xAxis
      .append('g')
      .attr('class', 'bars')
      .attr('transform', `translate(${margin.left + (minBarMargin / 2 - barWidth / 2)}, ${margin.top})`)
      .selectAll('.bar')
      .data(this.data)
      .enter()
      .append('g')
      .attr('class', 'bars__item');

    /**
     * Add the hover area for each bar.
     * This is an invisible rect that we will use to attach all the events to.
     */
    group
        .append('rect')
        .attr('class', 'behind')
        .attr('y', 0)
        .attr('x', d => this.xDomain(d.entity.id) - (HOVER_AREA_WIDTH / 2) + (barWidth / 2))
        .attr('width', HOVER_AREA_WIDTH)
        .attr('height', () => this.baseHeight)
        .style('cursor', 'pointer')
        .style('cursor', 'pointer')
        .on('mouseover', this.onMouseover.bind(this))
        .on('mouseout', this.onMouseout.bind(this))
        .on('click', this.events.click)

    /**
     * Hover line
     * Add a line that will be displayed on hover.
     */
    group
      .append('line')
      .attr('class', 'line')
      .attr('fill', 'none')
      .attr('x1', d => this.xDomain(d.entity.id) + barWidth / 2)
      .attr('y1', this.baseHeight)
      .attr('x2',  d => this.xDomain(d.entity.id) + barWidth / 2)
      .attr('y2', 0);

    /**
     * Bar
     * Add the actual bar of the bar chart.
     * At first set it to equal 0 and then animate it to the correct value.
     */
    group
        .append('rect')
        .attr('class', 'bar')
        .attr('y', d => this.yDomain(d.value))
        .attr('x', d => this.xDomain(d.entity.id))
        .attr('rx', barWidth / 2)
        .attr('width', barWidth)
        .attr('height', d => this.baseHeight - this.yDomain(d.value))
        // no bar at the beginning thus:
        .attr('height', () => this.baseHeight - this.yDomain(1)) // always equal to 0
        .attr('y', () => this.yDomain(1));

    /**
     * Hover Circle
     * Add a circle that will be displayed on hover.
     */
    group
      .append('circle')
      .attr('class', 'circle')
      .attr('cx', d => this.xDomain(d.entity.id) + barWidth / 2)
      .attr('cy',  d => this.yDomain(d.value))
      .attr('r', HOVER_CIRCLE_R);
  }

  makeTooltip() {
    // Create it only once, not on each update.
    if (!this.tooltip) {
      this.tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'chart-tooltip')
        .style('opacity', 0);
    }
  }

  makeGridlines() {
    let gridlineCall = d3.axisLeft(this.yDomain)
      .ticks(NUMBER_OF_TICKS)
      .tickSize(-this.width)
      .tickFormat('');

    this.xAxis.append('g')
      .attr('class', 'grid')
      .call(gridlineCall)
      .attr('transform', `translate(${margin.left}, ${margin.top})`);
  }

  onMouseover(d) {
    // Add the content first, therefore, we can get real measurements.
    this.tooltip.html(`
<strong>${this.tooltipFormatting(d.value)}</strong>
`);

    const bbox = this.getElementBoundingBox();
    const tooltipBB = this.tooltip.node().getBoundingClientRect();
    const pos = {
      top: bbox.n.y - tooltipBB.height - tooltipYMargin,
      left: bbox.n.x - tooltipBB.width / 2,
    };

    this.tooltip
      .transition()
      .duration(200)
      .style('opacity', 0.9);

    this.tooltip
      .style('left', `${pos.left}px`)
      .style('top', `${pos.top}px`);
  }

  /**
   * Given a shape on the screen, will return an SVGPoint for the directions
   * n(north), s(south), e(east), w(west), ne(northeast),
   * se(southeast), nw(northwest), sw(southwest).
   *
   *    +-+-+
   *    |   |
   *    +   +
   *    |   |
   *    +-+-+
   * @return {{n, s, e, w, nw, sw, ne, se}}
   */
  getElementBoundingBox() {
    const targetel = d3.event.target;
    const point = this.xAxis.node().createSVGPoint();

    const bbox = {};
    const matrix = targetel.getScreenCTM();
    const tbbox = targetel.getBBox();
    const { width } = tbbox;
    const { height } = tbbox;
    const { x } = tbbox;
    const { y } = tbbox;

    point.x = x;
    point.y = y;
    bbox.nw = point.matrixTransform(matrix);
    point.x += width;
    bbox.ne = point.matrixTransform(matrix);
    point.y += height;
    bbox.se = point.matrixTransform(matrix);
    point.x -= width;
    bbox.sw = point.matrixTransform(matrix);
    point.y -= height / 2;
    bbox.w = point.matrixTransform(matrix);
    point.x += width;
    bbox.e = point.matrixTransform(matrix);
    point.x -= width / 2;
    point.y -= height / 2;
    bbox.n = point.matrixTransform(matrix);
    point.y += height;
    bbox.s = point.matrixTransform(matrix);

    return bbox;
  }

  onMouseout() {
    this.tooltip
      .transition()
      .duration(500)
      .style('opacity', 0);
  }

  on(event, callback) {
    this.xAxis
      .selectAll('.bar')
      .on(event, callback);
  }

  destroy() {
    // Clean tooltip, before creating a new one.
    if (this.tooltip) {
      const tooltipNode = this.tooltip.node();
      tooltipNode.parentNode.removeChild(tooltipNode);
      this.tooltip = null;
    }
  }
}
