<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardEmitterMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button';
import EzWizard from '@/components/layout/Wizard';
import steps, { EXPENSE_STEP_1, EXPENSE_STEP_2, EXPENSE_STEP_3,
} from '@/views/platform/distributor/crm/expense-tracking/new-expense/steps';
import { mapGetters, mapMutations, mapState } from 'vuex';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { LOADING_KEY } from '@/util/constants';

export default {
  name: 'Index',
  mixins: [wizardEmitterMixin],
  components: {
    EzLoader,
    EzButton,
    EzWizard,
  },
  data() {
    return {
      EXPENSE_STEP_1,
      EXPENSE_STEP_2,
      EXPENSE_STEP_3,
      currentStep: 0,
      nextDisabled: true,
      loadingMsg: 'Loading...',
      isFromToDo: null,
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    ...mapState('entities/distributors', ['expense']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_EXPENSE_TYPES,
      ]);
    },
    hasToDoId() {
      return !!this.expense?.todoId;
    },
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === EXPENSE_STEP_1;
    },
    isLastStep() {
      return this.currentStep === EXPENSE_STEP_3;
    },
    disableNext() {
      return false;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', [
      'CLEAR_EXPENSE',
    ]),
    onWizardExit() {
      this.CLEAR_EXPENSE();
      if (this.isFromToDo) {
        this.$router.push({ name: 'distributor-to-dos' });
        return;
      }
      this.$router.push({ name: 'distributor-expense-tracking' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= EXPENSE_STEP_1 && step <= EXPENSE_STEP_3) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= EXPENSE_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= EXPENSE_STEP_3) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
  created() {
    this.isFromToDo = this.hasToDoId;
  },
  beforeDestroy() {
    this.CLEAR_EXPENSE();
  },
};
</script>
<template>
  <div>
    <ez-loader :show="isLoading">
      {{loadingMsg}}
    </ez-loader>
    <ez-wizard
      @exit="onWizardExit"
      @back="goToPreviousStep"
    >
      <template #title>Add Expense</template>
      <template #nav>
        <ul>
          <li :class="{
          'ez-step-active': currentStep === EXPENSE_STEP_1,
          'ez-step-completed': currentStep > EXPENSE_STEP_1,
        }">
            1. Expense Info
          </li>
          <li :class="{
          'ez-step-active': currentStep === EXPENSE_STEP_2,
          'ez-step-completed': currentStep > EXPENSE_STEP_2,
        }">
            2. Expense Details
          </li>
        </ul>
      </template>
      <template #actions>
        <ez-button
          :disabled="nextDisabled"
          @click="goToNextStep"
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.BUTTON__NEXT_STEP"
        >
          {{ nextCta }}
        </ez-button>
      </template>
      <template #prevStep>
        <ez-button
          v-if="currentStep > EXPENSE_STEP_1 && currentStep < EXPENSE_STEP_3"
          type="link"
          formType="button"
          @click="goToPreviousStep"
          :data-cy="supplierCy.CRM.EXPENSE_TRACKING.ADD_EXPENSE.BUTTON__PREV_STEP"
        >
          <font-awesome-icon icon="arrow-left"/>
          <span>Back</span>
        </ez-button>
      </template>
      <template #pageTitle>{{ currentStepPageTitle }}</template>
      <template #pageInfo><span v-html="currentStepPageInfo"/></template>
      <component
        :is="currentStepComponent"
        @stepCompleted="onStepCompleted"
        @stepBack="onStepBack"
      />
    </ez-wizard>
  </div>
</template>
<style lang="scss" scoped>
</style>
