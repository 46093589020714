<script>
import EzButton from '@/components/ui/Button/EzButton';
import Paper from '@/components/layout/WizardOverviewPaper';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import { LOADING_KEY } from '@/util/constants';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { VENUE_STEP_1, VENUE_STEP_2 } from '@/views/platform/distributor/outlets/send-onboarding-link/steps';
import { wizardListenerMixin } from '@/mixins/wizard';
import flash from '@/components/ui/FlashMessage';

const fieldsToCheck = [
  'smsNumber',
  'whatsAppNumber',
];

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'Overview',
  mixins: [wizardListenerMixin],
  components: {
    EzButton,
    Paper,
    VProductEntityInfo,
    EzLoadMore,
    EzSpinner,
    EzLoader,
  },
  data() {
    return {
      products: [],
      firstPageProducts: [],
      meta: {},
      firstPageMetaCopy: {},
      limit: 10,
      expandBtnCopy: 'Expand List',
      iconDirection: 'down',
    };
  },
  computed: {
    ...mapState('entities/venues', ['sendOnboardingLinkData']),
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    venueId() {
      return this.sendOnboardingLinkData.id;
    },
    draftData() {
      return this.sendOnboardingLinkData;
    },
    canExpand() {
      return this.products.length < (this.limit * 2);
    },
    canLoadOnScroll() {
      return this.products.length >= (this.limit * 2);
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS,
        LOADING_KEY.DISTRIBUTOR_SEND_ONBOARDING_LINK,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS_LOAD_MORE);
    },
    showExpandBtn() {
      return this.products?.length >= this.limit;
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'distributorFetchVenueVisibleProducts',
      'distributorSendOnboardingLink',
    ]),
    ...mapMutations('entities/venues', [
      'CLEAR_SEND_ONBOARDING_LINK_DATA',
      'CLEAR_DRAFT',
    ]),
    ...mapActions('reports', ['resendConnectionInvitation']),
    fetchVisibleProducts(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS) {
      const queryParams = {
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
        sortBy: 'name',
        limit: this.limit,
        hidden: 'false',
      };
      return this.distributorFetchVenueVisibleProducts({
        venueId: this.venueId,
        query: queryParams,
        loadingKey,
      });
    },
    async expandList() {
      if (!this.canExpand) {
        await this.resetFilters();
        return;
      }
      await this.loadMore();
      this.expandBtnCopy = 'Collapse List';
      this.iconDirection = 'up';
    },
    async refresh() {
      this.meta = {};
      const { data: { data, meta } } = await this.fetchVisibleProducts();
      this.products = data;
      this.meta = meta;

      this.firstPageProducts = this.products;
      this.firstPageMetaCopy = this.meta;
    },
    async loadMore() {
      const loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_VENUE_VISIBLE_PRODUCTS_LOAD_MORE;
      const { data: { data, meta } } = await this.fetchVisibleProducts(loadingKey);
      this.products = [
        ...this.products,
        ...data,
      ];
      this.meta = meta;
    },
    async resetFilters() {
      this.products = this.firstPageProducts;
      this.meta = this.firstPageMetaCopy;
      this.resetExpandBtn();
    },
    resetExpandBtn() {
      this.expandBtnCopy = 'Expand List';
      this.iconDirection = 'down';
    },
    editInfo() {
      this.$emit('stepBack', VENUE_STEP_1);
    },
    editProducts() {
      this.$emit('stepBack', VENUE_STEP_2);
    },
    async onNextStep() {
      const data = {
        venueId: this.venueId,
        name: this.draftData.name,
        contactName: this.draftData.contactName,
        ...(this.draftData.smsEnabled && { smsNumber: this.draftData.smsNumber }),
        ...(this.draftData.emailEnabled && { email: this.draftData.email }),
        ...(this.draftData.whatsAppEnabled && { whatsAppNumber: this.draftData.whatsAppNumber }),
      };
      try {
        if (this.draftData.invitationId) {
          await this.resendConnectionInvitation({
            invitationId: this.draftData.invitationId,
            onboardingInfo: {
              ...data,
            },
          });
        } else {
          await this.distributorSendOnboardingLink({ data });
        }
        await this.$router.push({
          name: this.draftData.route,
          ...(this.draftData.params ? { params: this.draftData.params } : {}),
        });
        await this.CLEAR_SEND_ONBOARDING_LINK_DATA();
        await this.CLEAR_DRAFT();
        flash.success({
          title: 'Onboarding link sent!',
          message: 'Good job! The onboarding link has been sent.',
        });
      } catch (e) {
        const { response: { data: { error } } } = e;
        const fields = Object.keys(error.fields);

        if (fields.some(item => fieldsToCheck.includes(item))) {
          flash.error({
            title: 'Wrong phone number format!',
            message: 'Phone number format is [+][country code][number] (e.g. +6585557801).',
            delay: 6000,
          });
        }
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  },
  created() {
    this.refresh();
    this.enableNextStep();
  },
};
</script>

<template>
  <div>
    <paper>
      <template #title>Contact info</template>
      <template #actions>
      <ez-button @click="editInfo" type="link" formType="button">
        <font-awesome-icon icon="pen"/>
        <span>Edit</span>
      </ez-button>
      </template>
      <div class="overview-item">
        <div class="info info--vertical">
          <small>{{ $t('global.venue') }} Name</small>
          <span>{{ sendOnboardingLinkData.name }}</span>
        </div>
      </div>
      <div class="overview-item mt-12">
        <div class="info info--vertical">
          <small>Ordering Contact</small>
          <span>{{ sendOnboardingLinkData.contactName }}</span>
        </div>
      </div>
      <hr>
      <div class="info info--vertical">
        <h3>Send Onboarding Link Via</h3>
        <template v-if="sendOnboardingLinkData.emailEnabled">
          <small>Email</small>
          <span>{{ sendOnboardingLinkData.email }}</span>
        </template>
        <template v-if="sendOnboardingLinkData.smsEnabled">
          <small class="mt-12">SMS</small>
          <span>{{ sendOnboardingLinkData.smsNumber }}</span>
        </template>
        <template v-if="sendOnboardingLinkData.whatsAppEnabled">
          <small class="mt-12">WhatsApp</small>
          <span>{{ sendOnboardingLinkData.whatsAppNumber }}</span>
        </template>
      </div>
    </paper>

    <paper class="products-paper">
      <template #title>Products ({{ meta.totalCount }})</template>
      <template #actions>
      <ez-button @click="editProducts" type="link" formType="button">
        <font-awesome-icon icon="pen"/>
        <span>Edit</span>
      </ez-button>
      </template>
      <table class="product-table">
        <tbody>
        <tr
          v-for="product in products"
          :key="product.id">
          <td class="item">
            <v-product-entity-info
              class="entity-info"
              :product="product" />
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="isLoadingMore" class="u-text-center">
        <ez-spinner/>
      </div>
      <ez-load-more
        v-if="!isLoadingMore && (canLoadOnScroll && meta.nextId)"
        @loadMore="loadMore"/>

      <template #footer>
        <div class="footer-paper mt-16">
          <ez-button
            v-if="showExpandBtn"
            @click="expandList"
            type="link"
            formType="button">
            <span>{{ expandBtnCopy }}</span>
            <font-awesome-icon class="ml-8" :icon="`angle-${iconDirection}`"/>
          </ez-button>
        </div>
      </template>
    </paper>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped>
:deep() .entity-info {
  flex-direction: column;
  align-items: center;
  &__text {
    margin: .75rem 0 0;
    justify-content: center;
  }
}

h3 {
  @include font-size(14px, 22px);
  margin: 0 0 16px;
  padding: 0;
  color: $color-gray-25;
  font-weight: 500;
}
.onboarding-option {
  display: flex;
  justify-content: space-between;
}
.dashed-line {
  max-width: 400px;
  margin: 20px auto;
}
.contact-item {
  @extend %flex-center;
  justify-content: space-between;
  @include font-size(14px, 16px);
  padding: 10px 0;
  &__name {
    color: $color-gray-25;
  }
  &__type {
    color: $color-gray-6C;
  }
}
.product-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  .item {
    width: 100%;
  }
  .entity-info {
    flex-direction: initial;
    align-items: center;
    :deep() .entity-info__text {
      margin-top: 0;
    }
  }
}
.footer-paper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.products-paper {
  max-height: 900px;
  :deep() .overview-paper__content {
    overflow-y: auto;
    max-height: 720px;
  }
}
</style>
