<script>
import { EzFormModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button/EzButton';
import OnboardingOptionsForm from '@/views/platform/distributor/outlets/OnboardingOptionsForm';
import EzInput from '@/components/ui/Input/EzInput';
import { mapMutations } from 'vuex';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzFormModal,
    EzButton,
    OnboardingOptionsForm,
    EzInput,
  },
  props: {
    action: {
      required: false,
      type: String,
      default: '/distributor/me/onboarding-requests',
    },
    method: {
      required: false,
      type: String,
      default: 'post',
    },
    data: {
      required: false,
      type: Object,
      default: () => null,
    },
    additionalData: {
      required: false,
      type: Object,
      default: () => null,
    },
    namesToDisable: {
      required: false,
      type: Array,
      default: () => [],
    },
    sendDisabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formKey: 'onboardingOption',
      dataCopy: {},
      additionalDataCopy: {},
    };
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler(val) {
        this.dataCopy = { name: '', contactName: '', ...val };
      },
    },
    additionalData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val) {
          return;
        }
        this.additionalDataCopy = { ...this.additionalDataCopy, ...val };
      },
    },
  },
  computed: {
    additionalDataExtended() {
      if (!this.sendDisabled) {
        return this.additionalDataCopy;
      }

      const data = this.data || {};
      const disabledData = {};
      this.namesToDisable.forEach((name) => {
        disabledData[name] = data[name];
      });

      return { ...this.additionalDataCopy, ...disabledData };
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERRORS']),
    isDisabled(name) {
      return !!this.namesToDisable.find(x => x === name);
    },
    clearErrorsState() {
      this.CLEAR_ERRORS({ key: this.formKey });
    },
    onSuccessOnboarding(data) {
      this.$emit('success', data);
    },
    open() {
      this.$refs.onboardingModal.open();
    },
    close() {
      this.$refs.onboardingModal.close();
      this.CLEAR_ERRORS({ key: this.formKey });
    },
    sendLink() {
      this.$refs.onboardingForm.submitOnboardingForm();
    },
  },
  beforeDestroy() {
    this.CLEAR_ERRORS({ key: this.formKey });
  },
};
</script>
<template>
  <ez-form-modal ref="onboardingModal" @close="clearErrorsState">
    <template #title>Send Onboarding Link</template>
    <template #content>
      <p class="info-text">
        Enter the name of the {{ $t('global.venue') | lowercase }} and your onboarding contact and
        choose how you’d like to send the onboarding link.
      </p>
      <hr />
      <onboarding-options-form
        ref="onboardingForm"
        :action="action"
        :method="method"
        :data="data"
        :additional-data="additionalDataExtended"
        @success="onSuccessOnboarding"
      >
        <ez-input
          class="mt-12"
          :disabled="isDisabled('name')"
          :formKey="formKey"
          validators="required"
          :label="`${$t('global.venue')} Name`"
          :placeholder="`Enter ${$t('global.venue')} Name`"
          name="name"
          :value="dataCopy.name"
        />
        <ez-input
          class="mt-12"
          :disabled="isDisabled('contactName')"
          :formKey="formKey"
          validators="required"
          label="Ordering Contact"
          placeholder="Enter Ordering Contact Name"
          name="contactName"
          :value="dataCopy.contactName"
        />
        <hr />
      </onboarding-options-form>
    </template>
    <template #footer>
      <EzButton type="link" @click="close">Cancel</EzButton>
      <EzButton formType="button" @click="sendLink">Send Link</EzButton>
    </template>
  </ez-form-modal>
</template>
<style lang="scss" scoped>
.info-text {
  @include font-size(14px, 20px);
}
</style>
