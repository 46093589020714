<template>
  <div class="distributor-venues">
    <ez-loader :show="isLoading">
      {{ loadingMsg }}
    </ez-loader>

    <header>
      <h1>{{ $t('global.venues') }}</h1>
      <span>{{ meta.totalCount }} connected</span>
    </header>

    <ez-nav class="mt-16">
      <template #nav>
        <ul>
          <li>
            <router-link :to="{ name: 'distributor-outlets-all' }">
              {{ $t('venues.allVenusTab') }}
            </router-link>
          </li>
          <li v-if="canSeeRequests">
            <router-link :to="{ name: 'distributor-outlets-requests' }">
              {{ $t('venues.connectionRequestsTab') }}
            </router-link>
            <ez-badge type="purple" :count="venueInvitationsCount" />
          </li>
        </ul>
      </template>
      <router-view />
    </ez-nav>
  </div>
</template>

<script>
/**
 * Outlets
 * @version 1.0.0
 * @since 2.2.0
 */

import EzBadge from '@/components/ui/Badge';
import EzNav from '@/components/ui/Nav';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import EzLoader from '@/components/ui/Loader/EzLoader';

export default {
  name: 'distributor-outlets',
  components: {
    EzLoader,
    EzBadge,
    EzNav,
  },
  data() {
    return {
      loadingMsg: 'Loading...',
      meta: {},
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.INIT_DISTRIBUTOR,
        LOADING_KEY.FETCH_CONNECTED_VENUES,
        LOADING_KEY.DISTRIBUTOR_FETCH_CONNECTION_REQUESTS,
        LOADING_KEY.DISTRIBUTOR_FETCH_CONNECTED_VENUES,
      ]);
    },
    ...mapGetters('entities/users', ['venueInvitationsCount']),
    canSeeRequests() {
      return this.venueInvitationsCount > 0 && this.$permission.isPremium;
    },
  },
  methods: {
    ...mapMutations('entities/venues', ['CLEAR_DISTRIBUTOR_VENUE_SINGLE']),
    ...mapActions('entities/distributors', ['distributorFetchConnectedVenues']),
    async fetchConnectedVenues() {
      const { data } = await this.distributorFetchConnectedVenues({});
      this.meta = { ...data.meta };
    },
  },
  async mounted() {
    await this.fetchConnectedVenues();
    /**
     * On enter clear the selected venue.
     * This will prevent flashing information of the previous venue.
     */
    this.CLEAR_DISTRIBUTOR_VENUE_SINGLE();
  },
};
</script>

<style lang="scss" scoped>
.distributor-venues {
  @include platform-tab-content();
}
</style>
