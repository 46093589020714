<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';

import EmptyState from '@/views/common/empty-state/EmptyState.vue';
import EzNote from '@/views/common/orders/notes/Note.vue';
import VAccountOwnerEntityInfo from '@/components/v3/patterns/VAccountOwnerEntityInfo';

/**
 * NotesList
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'NotesList',
  props: {
    creditNote: {
      required: true,
      type: Object,
    },
  },
  components: {
    EmptyState,
    EzNote,
    VAccountOwnerEntityInfo,
  },
  data() {
    return {
      supplierCy,
    };
  },
  computed: {
    hasNotes() {
      return !!this.creditNote.notes.length;
    },
  },
};
</script>

<template>
  <div class="notes-container">
    <template v-if="hasNotes">
      <ez-note v-for="(note, idx) in creditNote.notes" :key="note.id" isResponse>
        <template #avatar>
          <v-account-owner-entity-info
            :account-owner="note.user"
            :toShowName="false"
            :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.NOTES.TEXT__USER}-${idx}`"
          />
        </template>
        <template #date>
          <span
            :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.NOTES.TEXT__DATE}-${idx}`"
          >
            {{ note.createdAt | date }}
          </span>
        </template>
        <div>
          <span
            :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.NOTES.TEXT__NOTE}-${idx}`"
          >
            {{ note.note }}
          </span>
        </div>
      </ez-note>
    </template>

    <empty-state v-else>
      <template #badge><img src="@/assets/no-notes-state.svg" alt="" /></template>
      <template #title>No notes here</template>
      <template #info>There are no notes included in this credit note.</template>
    </empty-state>
  </div>
</template>

<style lang="scss" scoped>
.notes-container {
  margin: $spacing-16 0;
}
</style>
