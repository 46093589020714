var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-card cursor-pointer",on:{"click":_vm.onClick}},[_c('div',{staticClass:"activity-card__header"},[_c('div',{staticClass:"activity-card__header__venue-info"},[(_vm.hasVenue)?_c('v-venue-entity-info',{staticClass:"entity-info-card",attrs:{"venue":_vm.venue}}):_vm._e(),(!_vm.hasVenue && _vm.hasToDoLogo)?_c('div',{staticClass:"only-todo mb-16"},[_c('div',{staticClass:"only-todo__icon"},[_c('font-awesome-icon',{attrs:{"icon":"tasks"}})],1),_c('div',{staticClass:"only-todo__text"},[_vm._v("To-Do "),_c('span',[_vm._v("(No "+_vm._s(_vm.$t('global.venue'))+" Assigned)")])])]):_vm._e()],1),(_vm.notifyMsg)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: `${_vm.notifyMsg}`,
        classes: ['tooltip-general'],
        placement: 'start',
      }),expression:"{\n        content: `${notifyMsg}`,\n        classes: ['tooltip-general'],\n        placement: 'start',\n      }"}],staticClass:"cursor-help",attrs:{"icon":"bell"}}):_vm._e(),(_vm.deleteEnabled)?_c('ez-button',{staticClass:"activity-card__delete",attrs:{"type":"link","formType":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.deletePendingAccount.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1),_c('div',{staticClass:"activity-card__content"},[(_vm.venue && _vm.venue.date)?_c('div',{staticClass:"activity-card__content__date"},[_c('span',{staticClass:"activity-card__content__date__label"},[_vm._v(" "+_vm._s(_vm.dateLabel)+" ")]),_vm._v(" "+_vm._s(_vm._f("date")(_vm.venue.date))+" ")]):_vm._e(),(_vm.note || _vm.hasDueDate)?_c('div',{staticClass:"note-due-date"},[(_vm.note)?_c('div',{staticClass:"activity-card__content__date"},[(_vm.noteTitle.length)?_c('small',{staticClass:"activity-card__content__date__label"},[_vm._v(_vm._s(_vm.noteTitle)+" ")]):_vm._e(),_c('span',{staticClass:"activity-card__content__note"},[_vm._v(_vm._s(_vm.note))])]):_vm._e(),(_vm.hasDueDate)?[(_vm.dueDate.dueDate)?_c('div',{staticClass:"due-date"},[_c('small',{staticClass:"due-date__label"},[_vm._v("Due Date")]),_c('span',{class:[
            { 'due-date--blue': _vm.dueDate.isDue },
            { 'due-date--red': _vm.dueDate.isPastDue }
          ]},[_vm._v(_vm._s(_vm._f("date")(_vm.dueDate.dueDate)))])]):_vm._e()]:_vm._e()],2):_vm._e(),(!_vm.toHideAccountOwner)?_c('v-account-owner-entity-info',{attrs:{"account-owner":_vm.accountOwner,"to-show-name":false}}):_vm._e()],1),(_vm.toShowFooter)?_c('div',{staticClass:"activity-card__footer"},[_c('div',{staticClass:"activity-card__footer__copy"},[_vm._v("Total")]),_c('div',{staticClass:"activity-card__footer__price"},[_vm._v(_vm._s(_vm._f("price")(_vm.venue.amount)))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }