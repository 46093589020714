import i18n from '@/i18n';

export const CHECK_IN_STEP_1 = 0;
export const CHECK_IN_STEP_2 = 1;
export const CHECK_IN_STEP_3 = 2;

const steps = {
  [CHECK_IN_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/account-check-in/new-check-in/Outlet'),
    title: 'Add activity info',
    pageTitle: 'Please add activity info',
    pageInfo: `Select a ${i18n.t('global.venue').toLowerCase()} if needed and add the necessary details.`,
    pageInfo2: `Select an activity that you had with this ${i18n.t('global.venue').toLowerCase()} and add the necessary details.`,
    pageInfo3: 'Select an activity that you had and add the necessary details.',
    nextCta: 'Add Expense',
  },
  [CHECK_IN_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/account-check-in/new-check-in/Expense'),
    title: 'Expense',
    pageTitle: 'Now add expense details',
    pageInfo: ' This step is optional. You can skip it if there are no expenses.',
    nextCta: 'Go to Overview',
    secondCta: 'Skip This Step',
  },
  [CHECK_IN_STEP_3]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/crm/account-check-in/new-check-in/Overview'),
    title: 'Overview',
    pageTitle: 'Activity Overview',
    pageInfo: 'Before you add this activity, check the information below to make sure you’ve entered everything correctly.',
    nextCta: 'Add Activity',
  },
};

export default steps;
