<script>
/**
 * NetSuite Settings Page
 * @version 1.0.0
 * @since 3.24.0
 */
import { mapActions, mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';

import Page from '@/views/admin/SinglePage.vue';

import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import EzButton from '@/components/ui/Button/EzButton';

import VStatusBadge from '@/components/v3/elements/VStatusBadge';

export default {
  components: {
    EzButton,
    Page,
    VStatusBadge,
    EzConfirmationModal,
    EzButtonDropdown,
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    ...mapGetters('entities/users', {
      connected: 'isDistributorNetSuiteConnected',
      reauthNeeded: 'isDistributorNetSuiteReAuthNeeded',
      connectionExpired: 'isDistributorNetSuiteConnectionExpired',
    }),
    reauthLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_NETSUITE_GET_AUTHORIZATION_URL);
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributorLoggedUser']),
    ...mapActions('netsuite', [
      'distributorNetsuiteDisconnect',
      'distributorNetsuiteConnect',
      'distributorNetsuiteAuthorize',
    ]),
    async confirmDisconnect() {
      try {
        await this.distributorNetsuiteDisconnect();
        this.$router.push({
          name: 'distributor-integrations',
          params: {
            flash: {
              title: this.$t('netsuite.integrations.disconnect.flashMessages.success.title'),
            },
          },
        });
        await this.initDistributorLoggedUser();
      } catch {
        this.$refs.netsuiteDisconnectModal.close();
      }
    },
    reauthorize() {
      this.distributorNetsuiteConnect({ route: 'distributor-netsuite-account-info' });
    },
  },
  async created() {
    const params = window.location.search;
    if (!params) return;

    if (this.connected && !this.reauthNeeded && !this.connectionExpired) return;

    try {
      await this.distributorNetsuiteAuthorize({ params });
      await this.initDistributorLoggedUser();
    } catch (e) {
      this.$router.push({
        name: 'distributor-integrations',
        params: {
          flash: {
            title: this.$t('netsuite.integrations.disconnect.flashMessages.error.title'),
            message: this.$t('netsuite.integrations.disconnect.flashMessages.error.message'),
            type: 'fail',
          },
        },
      });
    }
  },
};
</script>

<template>
  <page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'distributor-integrations' }">
        <font-awesome-icon icon="arrow-left" />
        Back to Supplier Settings
      </router-link>
    </template>

    <template #title>
      <div class="u-flex-center title-container">
        <img src="@/assets/logo-netsuite.png" width="32" height="32" alt="NetSuite Logo" />
        <h2 class="m-0 ml-8 netsuite-title">{{ $t('netsuite.name') }}</h2>
        <v-status-badge type="green" v-if="connected">Connected</v-status-badge>
        <v-status-badge type="gray" v-else>Not Connected</v-status-badge>
      </div>
    </template>

    <template #actions>
      <ez-button v-if="reauthNeeded" class="mr-12" @click="reauthorize" :is-loading="reauthLoading">
        <font-awesome-icon icon="sync-alt" />
        <span>Reauthorize</span>
      </ez-button>
      <ez-button-dropdown v-if="connected" buttonType="secondary">
        <template #icon>
          <font-awesome-icon icon="ellipsis-h" />
        </template>
        <template #dropdown>
          <ez-button v-open="'netsuiteDisconnectModal'" type="red-link">
            {{ $t('global.actions.disconnect') }}
          </ez-button>
        </template>
      </ez-button-dropdown>
    </template>

    <template #navigation>
      <ul>
        <li>
          <component
            class="navigation-link"
            :is="connected ? 'router-link' : 'span'"
            :to="connected ? { name: 'distributor-netsuite-account-info' } : null"
          >
            Account Info
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="connected ? 'router-link' : 'span'"
            :to="connected ? { name: 'distributor-netsuite-warehouses' } : null"
          >
            Warehouses
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="connected ? 'router-link' : 'span'"
            :to="connected ? { name: 'distributor-netsuite-outlets' } : null"
          >
            {{ $t('global.venues') }}
          </component>
        </li>
      </ul>
    </template>

    <ez-confirmation-modal ref="netsuiteDisconnectModal" type="red">
      <template #title>{{ $t('netsuite.integrations.disconnect.modal.title') }}</template>
      <template #content>
        <div v-html="$t('netsuite.integrations.disconnect.modal.content', { platform: platformName })" />
      </template>
      <template #footer>
        <ez-button v-close="'netsuiteDisconnectModal'" type="link">
          {{ $t('global.actions.cancel') }}
        </ez-button>
        <ez-button @click="confirmDisconnect" type="red">
          {{ $t('global.actions.disconnect') }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </page>
</template>

<style lang="scss" scoped>
.title-container {
  img {
    border: 1px solid #e1e5ed;
    border-radius: 50%;
  }

  .netsuite-title {
    @include font-size(24px);
    font-weight: 500;
  }
}
</style>
