<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { LOADING_KEY } from '@/util/constants';
import downloadAttachment from '@/util/downloadAttachment';

import EzLoader from '@/components/ui/Loader';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table';
import EzOnOff from '@/components/ui/OnOff';

import flash from '@/components/ui/FlashMessage';
import Paper from '@/components/layout/WizardOverviewPaper.vue';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import { REMOVAL_STEP_1, REMOVAL_STEP_2 } from './steps';

/**
 * Overview
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'Overview',
  components: {
    EzLoader,
    EzButton,
    EzTable,
    EzOnOff,
    Paper,
    VProductEntityInfo,
  },
  data() {
    return {
      downloadReport: false,
      supplierCy,
      productsListExpanded: false,
    };
  },
  computed: {
    ...mapState('entities/products', ['inventoryManagement']),
    ...mapGetters('loading', ['getLoading']),
    warehouse() {
      return this.inventoryManagement.warehouse;
    },
    items() {
      return this.inventoryManagement.items;
    },
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_PRODUCTS_REMOVE_INVENTORY);
    },
    listItems() {
      if (!this.productsListExpanded) return this.items.slice(0, 6);
      return this.items;
    },
    expandButtonCopy() {
      return this.productsListExpanded ? 'Collapse List' : 'Expand List';
    },
    expandButtonIcon() {
      return this.productsListExpanded ? 'angle-up' : 'angle-down';
    },
  },
  methods: {
    ...mapActions('entities/products', ['distributorProductsRemoveInventory']),
    ...mapMutations('entities/products', ['CLEAR_DRAFT']),
    toggleListExpanded() {
      this.productsListExpanded = !this.productsListExpanded;
    },
    editWarehouse() {
      this.$emit('stepBack', REMOVAL_STEP_1);
    },
    editProducts() {
      this.$emit('stepBack', REMOVAL_STEP_2);
    },
    async onNextStep() {
      const req = { items: this.items.map(i => ({ itemId: i.id, quantity: i.quantity })) };

      try {
        const { data, headers } = await this.distributorProductsRemoveInventory({ data: req });

        if (this.downloadReport) {
          const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
          const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'template.xlsx';
          downloadAttachment(data, name);
        }

        this.CLEAR_DRAFT();

        flash.success({ title: 'Inventory removed successfully.' });
        this.$router.push({ name: 'distributor-products-all' });
      } catch (e) {
        // do nothing
      }
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
  },
  created() {
    this.enableNextStep();
  },
};
</script>

<template>
  <div class="ez-inventory-overview">
    <paper class="download-section">
      <template>
        <div class="help-block mb-0 mr-16">
          <h4 class="help-block__title custom-title">Download report</h4>
          <p class="help-block__text mb-0">Download excel report for this inventory action</p>
        </div>
        <ez-on-off
          formKey=""
          name="downloadReport"
          :value="downloadReport.toString()"
          :checked="downloadReport"
          @change="$event => (downloadReport = $event)"
          :data-cy="supplierCy.INVENTORY_FLOWS.REMOVAL.OVERVIEW.BUTTON__DOWNLOAD_REPORT"
        />
      </template>
    </paper>
    <paper>
      <template #title>WAREHOUSE</template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editWarehouse"
          :data-cy="supplierCy.INVENTORY_FLOWS.REMOVAL.OVERVIEW.BUTTON__EDIT_WAREHOUSE"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <template>
        <div class="overview-warehouses">
          <div class="overview-warehouses__warehouse">
            <div class="warehouse-section">
              <p class="warehouse-section__label">Warehouse Name</p>
              <p class="warehouse-section__text">{{ warehouse.name }}</p>
            </div>
          </div>
        </div>
      </template>
    </paper>
    <paper class="products-section">
      <template #title>REMOVED PRODUCTS ({{ items.length }})</template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editProducts"
          :data-cy="supplierCy.INVENTORY_FLOWS.REMOVAL.OVERVIEW.BUTTON__EDIT_PRODUCTS"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <template>
        <ez-table
          class="products-table"
          :data="listItems"
          :columns="['name', 'quantity']"
          :headers="false"
          :columnProps="{
            name: { class: 'name-cell' },
            quantity: { class: 'quantity-cell small-cell' },
          }"
          disableHover
        >
          <template #cell-name="{ row: { product } }">
            <v-product-entity-info :product="product" />
          </template>
        </ez-table>
      </template>
      <template #footer>
        <div v-if="items.length > 6" class="u-flex-h-center mt-16">
          <ez-button @click="toggleListExpanded" type="link" formType="button">
            <span>{{ expandButtonCopy }}</span>
            <font-awesome-icon class="ml-8" :icon="expandButtonIcon" />
          </ez-button>
        </div>
      </template>
    </paper>
    <ez-loader :show="loading" />
  </div>
</template>

<style lang="scss" scoped>
.overview-warehouses {
  &__warehouse {
    &:not(:last-child) {
      margin-bottom: 16px;
      border-bottom: 1px dashed $color-gray-E1;
      padding-bottom: 16px;
    }

    .warehouse-section {
      &__label {
        @include font-size(12px, 18px);
        margin: 0 0 4px;
        color: $color-gray-6C;
        font-weight: 600;
      }

      &__text {
        @include font-size(14px, 20px);
        margin: 0;
      }
    }
  }
}

.products-table {
  tbody tr td {
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 0;

    &.name-cell {
      :deep() .entity-info__text {
        white-space: nowrap;
      }
    }

    &.quantity-cell {
      color: $color-gray-25;
    }
  }
}

.download-section {
  :deep() .overview-paper__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .help-block {
      &__title {
        font-weight: 600;
      }

      &__text {
        @include font-size(14px, 20px);
      }
    }
  }
}
</style>
