<script>
import i18n from '@/i18n';
import ActivityTrack from '@/views/platform/distributor/crm/ActivityTrack';
import ActivityCard from '@/views/platform/distributor/crm/ActivityCard';
import EzDropDown from '@/components/ui/Dropdown/EzDropdown';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import dayjs from 'dayjs';
import EzButton from '@/components/ui/Button/EzButton';
import AccountDrawer from '@/views/platform/distributor/crm/AccountDrawer';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
  AccountDrawerType,
  ACCOUNT_CONNECTION_INVITATION,
  ACCOUNT_OPEN_YET_TO_BUY,
  NOT_ORDERING_ACCOUNT,
  ORDERING_ACCOUNT,
  PENDING_ACCOUNT_OPENING,
  UNASSIGNED_ACCOUNT,
  TARGET_ACCOUNT,
  sendConnectionReqAction,
  sendOnboardingAction,
  createOutletAction,
  resendInviteAction,
  ALL_ACCOUNT_REPS,
  LOADING_KEY,
} from '@/util/constants';
import {
  clone,
  findItemInPaginalList,
  formatContactInfoForOnboardingInfo,
  formatContactInfoForVenue,
  formatPrice,
  isLite,
  isPremium,
  formatErrorMessageFromRes,
} from '@/util/utils';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { getAllTimeRange } from '@/util/utils-date';
import flash from '@/components/ui/FlashMessage';
import downloadAttachment from '@/util/downloadAttachment';
import OnboardingLinkModal from '@/views/platform/distributor/outlets/OnboardingLinkModal';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import EzBadge from '@/components/ui/Badge/EzBadge';
import AccountDrawerData from '@/views/platform/distributor/crm/AccountDrawerData.model';
import AccountDrawerAction from '@/views/platform/distributor/crm/AccountDrawerAction.model';
import ConnectionRequestModal from '@/views/platform/distributor/outlets/ConnectionRequestModal';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import CreateTargetAccountModal from '@/views/platform/distributor/crm/CreateTargetAccountModal';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown.vue';

const BAD_URL_FOR_SINGLE_VIEW = "We didn't find account data with that id.";

const CONNECTED_OUTLETS = `connected ${i18n.t('global.venues').toLowerCase()}`;
const TOTAL_SALES_REVENUE = 'Total Sales revenue';

const NOTIFY_MSG_DORMANT = 'Dormant account';

const ACCOUNT_HISTORY_LABEL = 'Account History';

const resendRequestAction = new AccountDrawerAction({
  event: 'resendRequest',
  text: 'Resend Request',
  type: 'primary',
});

const SingleViewTargetConstants = {
  dateLabel: 'Date Created',
  badge: TARGET_ACCOUNT,
  listName: 'targetList',
};
const SingleViewPendingConstants = {
  dateLabel: 'Date Sent',
  badge: PENDING_ACCOUNT_OPENING,
  listName: 'pendingList',
};
const SingleViewOpenedConstants = {
  dateLabel: 'Date Opened',
  badge: ACCOUNT_OPEN_YET_TO_BUY,
  listName: 'openedList',
};
const SingleViewNotOrderingConstants = {
  dateLabel: 'Last Order Date',
  badge: NOT_ORDERING_ACCOUNT,
  listName: 'notOrderingList',
};
const SingleViewOrderingConstants = {
  dateLabel: 'Last Order Date',
  badge: ORDERING_ACCOUNT,
  listName: 'orderingList',
};

/**
 *
 * @version 1.0.0
 * @since 3.0.2
 */
export default {
  components: {
    EzSpinner,
    ConnectionRequestModal,
    EzBadge,
    OnboardingLinkModal,
    ActivityTrack,
    ActivityCard,
    EzDropDown,
    VDatePicker,
    EzButton,
    AccountDrawer,
    EzLoadMore,
    EzLoader,
    EzConfirmationModal,
    VSelectSearch,
    VVenueEntityInfo,
    CreateTargetAccountModal,
    EzSimpleDropdown,
  },
  props: {
    singleId: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loadingKeySingleOrders: 'load-more-single-orders',
      loadingKeySingleProducts: 'load-more-single-products',
      range: getAllTimeRange(),
      rangeName: 'All Time',
      filtersVals: {
        to: null,
        from: null,
      },
      routeName: 'distributor-account-activity',
      outlets: [],
      selectedVenue: null,
      cachedData: null,
      // If u change name of targetList also change SingleViewTargetConstants
      targetList: [],
      // If u change name of pendingList also change SingleViewPendingConstants
      pendingList: [],
      // If u change name of openedList also change SingleViewOpenedConstants
      openedList: [],
      // If u change name of notOrderingList also change SingleViewNotOrderingConstants
      notOrderingList: [],
      // If u change name of orderingList also change SingleViewOrderingConstants
      orderingList: [],
      targetCount: 0,
      pendingCount: 0,
      openedCount: 0,
      notOrderingCount: 0,
      orderingCount: 0,
      singleView: new AccountDrawerData({}),
      openedFormListName: '',
      unassignedAccount: { ...UNASSIGNED_ACCOUNT, id: '' },
      allAccounts: ALL_ACCOUNT_REPS,
      targetConstants: SingleViewTargetConstants,
      pendingConstants: SingleViewPendingConstants,
      openedConstants: SingleViewOpenedConstants,
      notOrderingConstants: SingleViewNotOrderingConstants,
      orderingConstants: SingleViewOrderingConstants,
      isLoaderShown: false,
      accountOwners: [],
      accountToBeDeleted: null,
      invitationId: null,
      loadingKeyMoreTarget: 'fetch-list-more-target',
      loadingKeyMorePending: 'fetch-list-more-pending',
      loadingKeyMoreOpened: 'fetch-list-more-opened',
      loadingKeyMoreNotOrdering: 'fetch-list-more-not-ordering',
      loadingKeyMoreOrdering: 'fetch-list-more-ordering',
    };
  },
  methods: {
    ...mapActions('reports', [
      'fetchVenue',
      'fetchTargetAccountList',
      'fetchPendingAccountList',
      'fetchOpenedAccountList',
      'fetchNotOrderingAccountList',
      'fetchOrderingAccountList',
      'fetchPendingAccountSingle',
      'deletePendingAccountSingle',
      'resendConnectionInvitation',
      'fetchMostOrderedItemsPerVenue',
      'fetchOrderHistory',
      'fetchAccountActivityTotalAmount',
      'fetchActiveAccountOwners',
      'exportAccountsOrderingData',
      'exportAccountsAllData',
      'updateConnectionOptions',
      'fetchMoneySpentByAccountRep',
      'fetchAccountActivityVenues',
      'fetchAccountActivityByVenue',
    ]),
    ...mapMutations('reports', [
      'SET_META_DATA',
      'SET_META_DATA_ORDERING',
      'SET_META_DATA_NOT_ORDERING',
      'SET_META_DATA_OPENED',
      'SET_META_DATA_PENDING',
      'SET_META_DATA_TARGET',
      'SET_META_DATA_ORDERS',
      'SET_META_DATA_PRODUCTS',
      'CLEAR_META_DATA_ORDERS',
      'CLEAR_META_DATA_PRODUCTS',
      'RESET_META',
      'UPDATE_FILTERS_ACTIVITY',
    ]),
    ...mapActions('entities/users', ['fetchAccountOwners']),
    ...mapActions('entities/venues', ['distributorFetchVenue']),
    ...mapMutations('entities/venues', ['SET_ONBOARDING_LINK_DATA', 'UPDATE_DRAFT']),
    async onSetAccountOwner(accountOwner) {
      const distributorAccountOwnerId = accountOwner.id;
      const { dormantPeriod, id } = this.singleView.venue;

      try {
        await this.updateConnectionOptions({
          venueId: id,
          dormantPeriod,
          distributorAccountOwnerId,
        });

        this.singleView = { ...this.singleView, accountOwner };
        const card = this[this.openedFormListName].find(c => c.id === id);

        this.accountOwners = await this.fetchActiveAccountOwnersData();
        this.accountOwners = [this.allAccounts, this.unassignedAccount, ...this.accountOwners];

        if (card) {
          card.accountOwner = accountOwner;
        }

        flash.success({
          title: `${this.$t('global.accountRep')} is updated`,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    async fetchMoneySpent(venueId) {
      const { from, to } = this.filtersVals;
      const { data } = await this.fetchMoneySpentByAccountRep({
        venueId,
        from,
        to,
      });
      return data.data.totalAmount;
    },
    async fetchUsers() {
      if (!this.$permission.has('assignAccountOwner')) {
        return null;
      }

      try {
        return await this.fetchAccountOwners();
      } catch (err) {
        const { response } = err;

        if (response && response.status === 403) {
          return null;
        }

        // eslint-disable-next-line no-console
        console.error(err);
        flash.error({
          title: `Something went wrong with fetching options for ${this.$t('global.accountReps')}.`,
        });
        return null;
      }
    },
    resetCounts() {
      this.targetCount = 0;
      this.pendingCount = 0;
      this.openedCount = 0;
      this.notOrderingCount = 0;
      this.orderingCount = 0;
    },
    cacheData() {
      this.cachedData = {
        targetList: clone(this.targetList),
        pendingList: clone(this.pendingList),
        openedList: clone(this.openedList),
        notOrderingList: clone(this.notOrderingList),
        orderingList: clone(this.orderingList),
        filtersVals: clone(this.filtersVals),
        meta: clone(this.meta),
        targetCount: this.targetCount,
        pendingCount: this.pendingCount,
        openedCount: this.openedCount,
        notOrderingCount: this.notOrderingCount,
        orderingCount: this.orderingCount,
      };
    },
    loadCachedData() {
      Object.entries(this.cachedData).forEach(([key, value]) => {
        if (key === 'meta') this.SET_META_DATA({ ...value });
        else this[key] = clone(value);
      });
    },
    scrollIntoView(list) {
      if (!this.$refs[list]) return;
      this.$refs[list].$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    async fetchActivityByVenue() {
      const {
        data: { data },
      } = await this.fetchAccountActivityByVenue({
        venueId: this.selectedVenue.id,
        query: {
          from: dayjs(this.range.start).toISOString(),
          to: dayjs(this.range.end).toISOString(),
        },
      });

      this.resetCounts();

      switch (data.status) {
        case TARGET_ACCOUNT:
          this.targetList = [data];
          this.targetCount = 1;
          this.scrollIntoView('targetList');
          this.SET_META_DATA({ count: formatPrice(0) });
          break;
        case PENDING_ACCOUNT_OPENING:
          this.pendingList = [data];
          this.pendingCount = 1;
          this.scrollIntoView('pendingList');
          this.SET_META_DATA({ count: formatPrice(0) });
          break;
        case ACCOUNT_OPEN_YET_TO_BUY:
          this.openedList = [data];
          this.openedCount = 1;
          this.scrollIntoView('openedList');
          this.SET_META_DATA({ count: formatPrice(0) });
          break;
        case NOT_ORDERING_ACCOUNT:
          this.notOrderingList = [data];
          this.notOrderingCount = 1;
          this.scrollIntoView('notOrderingList');
          this.SET_META_DATA({ count: formatPrice(0) });
          break;
        case ORDERING_ACCOUNT:
          this.orderingList = [data];
          this.orderingCount = 1;
          this.scrollIntoView('orderingList');
          this.SET_META_DATA({
            count: Number.isFinite(data.amount) ? formatPrice(data.amount) : null,
          });
          break;
        default:
          break;
      }
    },
    async onVenueSelect(data) {
      const { reset } = data;
      this.selectedVenue = reset ? null : data;

      if (reset) {
        this.resetActivity();
        if (this.dateFilterSameAsCache) this.loadCachedData();
        else await this.fetchAllActivity();

        this.cachedData = null;
        return;
      }

      await this.$nextTick();
      if (!this.selectedVenue) this.cacheData();

      this.resetActivity();
      await this.fetchActivityByVenue();
    },
    selectAccountOwner(accountOwner) {
      const { id } = accountOwner;
      this.UPDATE_FILTERS_ACTIVITY({ accountOwnerId: id });
      this.filtersVals = {
        ...this.filtersVals,
        accountOwnerId: id,
      };
      this.resetActivity();
      this.fetchAllActivity();
    },
    async selectDateRange(range) {
      this.UPDATE_FILTERS_ACTIVITY({ range });
      this.range = range;
      this.filtersVals = {
        ...this.filtersVals,
        from: dayjs(this.range.start).toISOString(),
        to: dayjs(this.range.end).toISOString(),
      };
      this.resetActivity();
      if (this.selectedVenue) await this.fetchActivityByVenue();
      else await this.fetchAllActivity();
    },
    onRangeNameChange(rangeName) {
      this.rangeName = rangeName;
      this.UPDATE_FILTERS_ACTIVITY({ rangeName });
    },
    openConnectionReqModal() {
      this.$refs.connectionReqModal.open();
    },
    onSuccessConnectionReq(res) {
      this.$refs.connectionReqModal.close();
      const { data } = res;
      this.singleView.venue = { ...this.singleView.venue, ...data };
      this.singleView = { ...this.singleView, badge: this.pendingConstants.badge };
      this.closeAccountDrawer();
      this.resetActivity();
      this.fetchAllActivity();

      flash.success({
        title: 'Your action was successful!',
      });
    },
    openOnboardingLinkWizard() {
      this.SET_ONBOARDING_LINK_DATA({
        route: 'distributor-account-activity',
        id: this.singleView.venue?.id,
        name: this.singleView.venue?.name,
        invitationId: this.invitationId,
      });
      this.$router.push({ name: 'distributor-outlets-send-onboarding-link' });
    },
    async openCreateOutletWizard() {
      const { data } = await this.distributorFetchVenue({ id: this.singleView.venue?.id });
      this.UPDATE_DRAFT({ id: this.singleView.venue?.id });
      this.$router.push({
        name: 'distributor-outlets-new',
        params: { venueData: data.data },
      });
    },
    openOnboardingModal() {
      this.$refs.onboardingModal.open();
    },
    onSuccessOnboarding(res) {
      this.$refs.onboardingModal.close();
      const { data } = res;
      this.singleView.venue = { ...this.singleView.venue, ...data };
      this.singleView = { ...this.singleView, badge: this.pendingConstants.badge };
      this.closeAccountDrawer();
      this.resetActivity();
      this.fetchAllActivity();

      flash.success({
        title: 'Your action was successful!',
      });
    },
    notifyMsgForAccount(account) {
      return account.dormantPeriodExceeded ? NOTIFY_MSG_DORMANT : '';
    },
    async onExportAccountsData() {
      try {
        this.isLoaderShown = true;
        const { data, headers } = await this.exportAccountsOrderingData({ ...this.filtersVals });
        const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
        const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'Ordering accounts';
        downloadAttachment(data, name);
        this.isLoaderShown = false;
      } catch (err) {
        this.isLoaderShown = false;
        // eslint-disable-next-line no-console
        console.error(err);
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    async onExportAllAccountsData() {
      try {
        this.isLoaderShown = true;
        const { data, headers } = await this.exportAccountsAllData({ ...this.filtersVals });
        const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
        const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'All accounts';
        downloadAttachment(data, name);
        this.isLoaderShown = false;
      } catch (err) {
        this.isLoaderShown = false;
        // eslint-disable-next-line no-console
        console.error(err);
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    openAccountDrawer(id, type) {
      this.setRouteId(id, type);
      this.$refs.accountDrawer.openDrawer();
    },
    closeAccountDrawer() {
      this.resetRouteId();
      this.$refs.accountDrawer.close();
    },
    getRouteName(type) {
      return `${this.routeName}-${type}`;
    },
    setRouteId(id, type) {
      if (this.$route.name === this.getRouteName(type)) {
        return;
      }

      this.$router.replace({
        name: this.getRouteName(type),
        params: { id },
      });
    },
    resetRouteId() {
      this.$router.replace({
        name: this.routeName,
      });
    },
    async fetchOrders({ venueId, loadingKey }) {
      const { nextId, nextValue } = this.metaOrders;

      const {
        data: { data, meta },
      } = await this.fetchOrderHistory({
        venueId,
        nextId,
        nextValue,
        ...(loadingKey && { loadingKey }),
      });

      this.SET_META_DATA_ORDERS({ ...meta });
      return data;
    },
    async fetchProducts({ venueId, loadingKey }) {
      const { nextId, nextValue } = this.metaProducts;
      const {
        data: { data, meta },
      } = await this.fetchMostOrderedItemsPerVenue({
        venueId,
        nextId,
        nextValue,
        ...(loadingKey && { loadingKey }),
      });

      this.SET_META_DATA_PRODUCTS({ ...meta });
      return data;
    },
    async onLoadMoreOrders(venueId) {
      const currentOrders = this.singleView.orders;
      const orders = await this.fetchOrders({
        venueId,
        loadingKey: this.loadingKeySingleOrders,
      });
      const orderHistoryList = [...currentOrders, ...orders];

      this.singleView = { ...this.singleView, orders: orderHistoryList };
    },
    async onLoadMoreProducts(venueId) {
      const currentProducts = this.singleView.products;
      const products = await this.fetchProducts({
        venueId,
        loadingKey: this.loadingKeySingleProducts,
      });
      const mostOrderItemList = [...currentProducts, ...products];

      this.singleView = { ...this.singleView, products: mostOrderItemList };
    },
    getActionsTarget({ venue }) {
      const { accountType } = venue;
      if (isPremium(accountType) || isLite(accountType)) {
        return [sendConnectionReqAction, createOutletAction];
      }
      return [sendOnboardingAction, createOutletAction];
    },
    getActionsOpening({ venue }) {
      const { accountType } = venue;
      if (isPremium(accountType) || isLite(accountType)) {
        return [resendRequestAction, createOutletAction];
      }
      return [resendInviteAction, createOutletAction];
    },
    async openTargetAccount(account) {
      const { id, accountOwner, date, venueId } = account;
      this.invitationId = id;

      this.CLEAR_META_DATA_ORDERS();
      const [
        {
          data: { data },
        },
        orderHistoryList,
        moneySpent,
      ] = await Promise.all([
        this.fetchPendingAccountSingle({ invitationId: this.invitationId }),
        this.fetchOrders({ venueId }),
        this.fetchMoneySpent(venueId),
      ]);
      const { venueInfo, onboardingInfo, lastResend } = data;
      const venue = { ...account, ...venueInfo, lastResend, onboardingInfo };

      const actions = this.getActionsTarget({ venue });

      this.singleView = new AccountDrawerData({
        venue,
        disableRedirectByVenue: true,
        badge: this.targetConstants.badge,
        dateLabel: this.targetConstants.dateLabel,
        date,
        accountOwner: accountOwner || this.unassignedAccount,
        contentTitle: ACCOUNT_HISTORY_LABEL,
        orders: orderHistoryList,
        notification: this.notifyMsgForAccount(account),
        moneySpent,
        actions,
      });

      this.openedFormListName = this.targetConstants.listName;
      this.openAccountDrawer(this.invitationId, AccountDrawerType.Target);
    },
    async openPendingAccount(account) {
      const { id, accountOwner, date, venueId } = account;
      this.invitationId = id;

      this.CLEAR_META_DATA_ORDERS();
      const [
        {
          data: { data },
        },
        moneySpent,
        orderHistoryList,
      ] = await Promise.all([
        this.fetchPendingAccountSingle({ invitationId: this.invitationId }),
        this.fetchMoneySpent(venueId),
        this.fetchOrders({ venueId }),
      ]);

      const { venueInfo, onboardingInfo, canResend, lastResend } = data;
      const venue = { ...account, ...venueInfo, lastResend, onboardingInfo };

      let contactInfo = formatContactInfoForOnboardingInfo(onboardingInfo);
      contactInfo = [...formatContactInfoForVenue(venueInfo), ...contactInfo];

      const actions = canResend ? this.getActionsOpening({ venue }) : [];

      this.singleView = new AccountDrawerData({
        venue,
        disableRedirectByVenue: true,
        badge: this.pendingConstants.badge,
        dateLabel: this.pendingConstants.dateLabel,
        date,
        accountOwner: accountOwner || this.unassignedAccount,
        contactInfoData: contactInfo,
        notification: this.notifyMsgForAccount(account),
        orders: orderHistoryList,
        contentTitle: ACCOUNT_HISTORY_LABEL,
        moneySpent,
        actions,
      });

      this.openedFormListName = this.pendingConstants.listName;
      this.openAccountDrawer(this.invitationId, AccountDrawerType.Pending);
    },
    async openOpenedAccount(account) {
      this.invitationId = null;
      const { id, accountOwner, date } = account;
      const venueId = id;

      this.CLEAR_META_DATA_ORDERS();
      const [
        {
          data: { data },
        },
        orderHistoryList,
        moneySpent,
      ] = await Promise.all([
        this.fetchVenue({ venueId }),
        this.fetchOrders({ venueId }),
        this.fetchMoneySpent(venueId),
      ]);

      this.singleView = new AccountDrawerData({
        venue: data,
        badge: this.openedConstants.badge,
        date,
        dateLabel: this.openedConstants.dateLabel,
        accountOwner: accountOwner || this.unassignedAccount,
        accountOptions: this.users ? [this.unassignedAccount, ...this.users] : null,
        contactInfoData: formatContactInfoForVenue(data),
        orders: orderHistoryList,
        contentTitle: ACCOUNT_HISTORY_LABEL,
        notification: this.notifyMsgForAccount(account),
        moneySpent,
      });

      this.openedFormListName = this.openedConstants.listName;
      this.openAccountDrawer(venueId, AccountDrawerType.Opened);
    },
    async openNotOrderingAccount(account) {
      this.invitationId = null;
      const { id, accountOwner, date } = account;
      const venueId = id;

      this.CLEAR_META_DATA_PRODUCTS();
      this.CLEAR_META_DATA_ORDERS();
      const [
        {
          data: { data },
        },
        mostOrderItemList,
        orderHistoryList,
        moneySpent,
      ] = await Promise.all([
        this.fetchVenue({ venueId }),
        this.fetchProducts({ venueId }),
        this.fetchOrders({ venueId }),
        this.fetchMoneySpent(venueId),
      ]);

      this.singleView = new AccountDrawerData({
        venue: data,
        badge: this.notOrderingConstants.badge,
        dateLabel: this.notOrderingConstants.dateLabel,
        date,
        accountOwner: accountOwner || this.unassignedAccount,
        accountOptions: this.users ? [this.unassignedAccount, ...this.users] : null,
        contactInfoData: formatContactInfoForVenue(data),
        products: mostOrderItemList,
        orders: orderHistoryList,
        notification: this.notifyMsgForAccount(account),
        showProductsEmpty: true,
        moneySpent,
      });

      this.openedFormListName = this.notOrderingConstants.listName;
      this.openAccountDrawer(venueId, AccountDrawerType.NotOrdering);
    },
    async openOrderingAccount(account) {
      this.invitationId = null;
      const { id, accountOwner, date } = account;
      const venueId = id;

      const { from, to } = this.filtersVals;

      this.CLEAR_META_DATA_ORDERS();
      const [
        {
          data: { data },
        },
        mostOrderItemList,
        orderHistoryList,
        moneySpent,
        {
          data: { data: total },
        },
      ] = await Promise.all([
        this.fetchVenue({ venueId }),
        this.fetchProducts({ venueId }),
        this.fetchOrders({ venueId }),
        this.fetchMoneySpent(venueId),
        this.fetchAccountActivityTotalAmount({ query: { venueId, from, to } }),
      ]);

      this.singleView = new AccountDrawerData({
        venue: data,
        badge: this.orderingConstants.badge,
        dateLabel: this.orderingConstants.dateLabel,
        date,
        accountOwner: accountOwner || this.unassignedAccount,
        accountOptions: this.users ? [this.unassignedAccount, ...this.users] : null,
        contactInfoData: formatContactInfoForVenue(data),
        contentTitle: ACCOUNT_HISTORY_LABEL,
        products: mostOrderItemList,
        orders: orderHistoryList,
        notification: this.notifyMsgForAccount(account),
        showProductsEmpty: true,
        total,
        totalLabel: this.totalLabel,
        moneySpent,
      });

      this.openedFormListName = this.orderingConstants.listName;
      this.openAccountDrawer(venueId, AccountDrawerType.Ordering);
    },
    async openSingleView(id, routeName) {
      if (id === undefined || id === null) {
        return;
      }
      this.isLoaderShown = true;
      switch (routeName) {
        case this.getRouteName(AccountDrawerType.Target): {
          const account = await findItemInPaginalList({
            id,
            list: clone(this.targetList),
            getNewListFn: this.fetchTargetListClean,
            page: this.metaTarget.page,
          });

          if (!account) {
            flash.error({
              title: BAD_URL_FOR_SINGLE_VIEW,
            });
            break;
          }
          await this.openTargetAccount(account);
          break;
        }
        case this.getRouteName(AccountDrawerType.Pending): {
          const account = await findItemInPaginalList({
            id,
            list: clone(this.pendingList),
            getNewListFn: this.fetchPendingListClean,
            page: this.metaPending.page,
          });

          if (!account) {
            flash.error({
              title: BAD_URL_FOR_SINGLE_VIEW,
            });
            break;
          }
          await this.openPendingAccount(account);
          break;
        }
        case this.getRouteName(AccountDrawerType.Opened): {
          const account = await findItemInPaginalList({
            id,
            list: clone(this.openedList),
            getNewListFn: this.fetchOpenedListClean,
            page: this.metaOpened.page,
          });

          if (!account) {
            flash.error({
              title: BAD_URL_FOR_SINGLE_VIEW,
            });
            break;
          }
          await this.openOpenedAccount(account);
          break;
        }
        case this.getRouteName(AccountDrawerType.NotOrdering): {
          const account = await findItemInPaginalList({
            id,
            list: clone(this.notOrderingList),
            getNewListFn: this.fetchNotOrderingListClean,
            page: this.metaNotOrdering.page,
          });

          if (!account) {
            flash.error({
              title: BAD_URL_FOR_SINGLE_VIEW,
            });
            break;
          }

          await this.openNotOrderingAccount(account);
          break;
        }
        case this.getRouteName(AccountDrawerType.Ordering): {
          const account = await findItemInPaginalList({
            id,
            list: clone(this.orderingList),
            getNewListFn: this.fetchOrderingListClean,
            page: this.metaOrdering.page,
          });

          if (!account) {
            flash.error({
              title: BAD_URL_FOR_SINGLE_VIEW,
            });
            break;
          }
          await this.openOrderingAccount(account);
          break;
        }
        default: {
          break;
        }
      }
      this.isLoaderShown = false;
    },
    async fetchTargetListClean({ page, filters }) {
      const { data } = await this.fetchTargetAccountList({
        filters,
        page,
      });
      return data.data;
    },
    async fetchPendingListClean({ page, filters }) {
      const { data } = await this.fetchPendingAccountList({
        filters,
        page,
      });
      return data.data;
    },
    async fetchOpenedListClean({ page, filters }) {
      const { data } = await this.fetchOpenedAccountList({
        filters,
        page,
      });
      return data.data;
    },
    async fetchNotOrderingListClean({ page, filters }) {
      const { data } = await this.fetchNotOrderingAccountList({
        filters,
        page,
      });
      return data.data;
    },
    async fetchOrderingListClean({ page, filters }) {
      const { data } = await this.fetchOrderingAccountList({
        filters,
        page,
      });
      return data.data;
    },
    async fetchTargetList(providedPage = null, loadingKey = null) {
      const { page } = this.metaTarget;
      const currentPage = providedPage || page;

      const { data } = await this.fetchTargetAccountList({
        filters: this.filtersVals,
        page: currentPage,
        ...(loadingKey ? { loadingKey } : null),
      });
      this.SET_META_DATA_TARGET({ ...data.meta });

      this.targetCount = data.meta.totalCount;
      this.targetList = [...this.targetList, ...data.data];
    },
    async fetchPendingList(providedPage = null, loadingKey = null) {
      const { page } = this.metaPending;
      const currentPage = providedPage || page;

      const { data } = await this.fetchPendingAccountList({
        filters: this.filtersVals,
        page: currentPage,
        ...(loadingKey ? { loadingKey } : null),
      });
      this.SET_META_DATA_PENDING({ ...data.meta });

      this.pendingCount = data.meta.totalCount;
      this.pendingList = [...this.pendingList, ...data.data];
    },
    async fetchOpenedList(providedPage = null, loadingKey = null) {
      const { page } = this.metaOpened;
      const currentPage = providedPage || page;

      const { data } = await this.fetchOpenedAccountList({
        filters: this.filtersVals,
        page: currentPage,
        ...(loadingKey ? { loadingKey } : null),
      });
      this.SET_META_DATA_OPENED({ ...data.meta });

      this.openedCount = data.meta.totalCount;
      this.openedList = [...this.openedList, ...data.data];
    },
    async fetchNotOrderingList(providedPage = null, loadingKey = null) {
      const { page } = this.metaNotOrdering;
      const currentPage = providedPage || page;

      const { data } = await this.fetchNotOrderingAccountList({
        filters: this.filtersVals,
        page: currentPage,
        ...(loadingKey ? { loadingKey } : null),
      });
      this.SET_META_DATA_NOT_ORDERING({ ...data.meta });

      this.notOrderingCount = data.meta.totalCount;
      this.notOrderingList = [...this.notOrderingList, ...data.data];
    },
    async fetchOrderingList(providedPage = null, loadingKey = null) {
      const { page } = this.metaOrdering;
      const currentPage = providedPage || page;

      const res = await this.fetchOrderingAccountList({
        filters: this.filtersVals,
        page: currentPage,
        ...(loadingKey ? { loadingKey } : null),
      });
      const { data, meta } = res.data;

      this.SET_META_DATA({
        ...this.meta,
        label: `${TOTAL_SALES_REVENUE}`,
        count: Number.isFinite(meta.totalAmount) ? formatPrice(meta.totalAmount) : null,
      });

      this.SET_META_DATA_ORDERING({ ...meta });

      this.orderingCount = meta.totalCount;
      this.orderingList = [...this.orderingList, ...data];
    },
    resetActivity() {
      this.RESET_META();
      this.targetList = [];
      this.pendingList = [];
      this.openedList = [];
      this.notOrderingList = [];
      this.orderingList = [];
    },
    async fetchAllActivity() {
      try {
        await Promise.all([
          this.fetchTargetList(),
          this.fetchPendingList(),
          this.fetchOpenedList(),
          this.fetchNotOrderingList(),
          this.fetchOrderingList(),
        ]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    async fetchActiveAccountOwnersData() {
      const {
        data: { data },
      } = await this.fetchActiveAccountOwners();
      return data;
    },
    setSubtitle() {
      const { distributor } = this.loggedUser;
      const subtitle = distributor.connectedVenuesCount
        ? `${distributor.connectedVenuesCount} ${CONNECTED_OUTLETS}`
        : '';
      this.SET_META_DATA({
        ...this.meta,
        subtitle,
      });
    },
    deleteEnabled(account) {
      return account && account.type === ACCOUNT_CONNECTION_INVITATION;
    },
    openConfirmationModal(account) {
      this.accountToBeDeleted = account;
      this.$refs.deletePendingAccountModal.open();
    },
    close() {
      this.$refs.deletePendingAccountModal.close();
    },
    async deletePendingAccount() {
      const invitationId = this.accountToBeDeleted.id;
      try {
        await this.deletePendingAccountSingle({ invitationId });
        this.$refs.accountDrawer.close();
        this.resetRouteId();
        this.resetActivity();
        await this.fetchAllActivity();

        flash.success({
          title: 'Account successfully deleted',
          message: 'This account is now deleted from the platform.',
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      this.close();
    },
    addTargetAccount() {
      this.$refs.createTargetAccountModal.open();
    },
    async onCreateAccountSuccess() {
      this.targetList = [];
      await this.fetchTargetList(1, LOADING_KEY.FETCH_TARGET_ACCOUNT_LIST);

      flash.success({
        title: 'Target account successfully added!',
      });
    },
    onCreateAccountError(res) {
      const message = formatErrorMessageFromRes(res);
      flash.error({
        title: 'Unsuccessful action.',
        message,
        delay: 5000,
      });
    },
  },
  computed: {
    ...mapState('reports', [
      'meta',
      'metaTarget',
      'metaPending',
      'metaOpened',
      'metaNotOrdering',
      'metaOrdering',
      'metaOrders',
      'metaProducts',
      'filtersActivity',
    ]),
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    isLoadingMoreTarget() {
      return this.getLoading(this.loadingKeyMoreTarget);
    },
    isLoadingMorePending() {
      return this.getLoading(this.loadingKeyMorePending);
    },
    isLoadingMoreOpened() {
      return this.getLoading(this.loadingKeyMoreOpened);
    },
    isLoadingMoreNotOrdering() {
      return this.getLoading(this.loadingKeyMoreNotOrdering);
    },
    isLoadingMoreOrdering() {
      return this.getLoading(this.loadingKeyMoreOrdering);
    },
    isLoadingMoreSingleOrders() {
      return this.getLoading(this.loadingKeySingleOrders);
    },
    isLoadingMoreSingleProducts() {
      return this.getLoading(this.loadingKeySingleProducts);
    },

    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_PENDING_ACCOUNT_SINGLE,
        LOADING_KEY.FETCH_MONEY_SPENT_BY_ACCOUNT_REP,
        LOADING_KEY.FETCH_VENUE,
        LOADING_KEY.GET_USERS,
        LOADING_KEY.UPDATE_CONNECTION_OPTIONS,
        LOADING_KEY.FETCH_MOST_ORDERED_ITEMS_PER_VENUE,
        LOADING_KEY.FETCH_ORDER_HISTORY,
        LOADING_KEY.FETCH_ACCOUNT_ACTIVITY_TOTAL_AMOUNT,
        LOADING_KEY.DELETE_PENDING_ACCOUNT_SINGLE,
        LOADING_KEY.FETCH_TARGET_ACCOUNT_LIST,
        LOADING_KEY.FETCH_PENDING_ACCOUNT_LIST,
        LOADING_KEY.FETCH_OPENED_ACCOUNT_LIST,
        LOADING_KEY.FETCH_NOT_ORDERING_ACCOUNT_LIST,
        LOADING_KEY.FETCH_ORDERING_ACCOUNT_LIST,
        LOADING_KEY.DISTRIBUTOR_FETCH_ACCOUNT_ACTIVITY_VENUES,
        LOADING_KEY.DISTRIBUTOR_FETCH_ACCOUNT_ACTIVITY_BY_VENUE,
      ]);
    },
    dropdownDisabled() {
      return !this.$permission.has('seeAllAccounts');
    },
    action() {
      return `/distributor/invitations/${this.invitationId}/resend`;
    },
    onboardingData() {
      const venue = this.singleView && this.singleView.venue;
      return venue && (venue.onboardingInfo || venue);
    },
    totalLabel() {
      return `${this.$t('global.venue')} Total For ${this.rangeName}`;
    },
    canExportOrderingAccountsData() {
      return this.$permission.has('accountActivityExport');
    },
    dateFilterSameAsCache() {
      return (
        this.cachedData?.filtersVals.to === this.filtersVals.to &&
        this.cachedData?.filtersVals.from === this.filtersVals.from
      );
    },
  },
  async created() {
    const { range, rangeName, accountOwnerId } = this.filtersActivity;
    this.range = range;
    this.rangeName = rangeName;

    this.filtersVals = {
      ...this.filtersVals,
      from: dayjs(range.start).toISOString(),
      to: dayjs(range.end).toISOString(),
      accountOwnerId,
    };

    [
      this.accountOwners,
      this.users,
      {
        data: { data: this.outlets },
      },
    ] = await Promise.all([
      this.fetchActiveAccountOwnersData(),
      this.fetchUsers(),
      this.fetchAccountActivityVenues(),
      this.fetchAllActivity(),
    ]);

    this.accountOwners = [this.allAccounts, this.unassignedAccount, ...this.accountOwners];

    if (this.dropdownDisabled) {
      this.filtersVals.accountOwnerId = this.loggedUser.id;
    }

    this.setSubtitle();

    const { singleId } = this;
    await this.openSingleView(singleId, this.$route.name);
  },
  beforeDestroy() {
    this.resetActivity();
  },
};
</script>
<template>
  <div class="activity">
    <div class="actions mt-24">
      <div class="filters">
        <v-select-search
          v-if="outlets.length"
          class="select-product mr-12"
          search="/account-activity/search/venues"
          :data="outlets"
          value-property="id"
          :placeholder="`All ${$t('global.venues')}`"
          :searchPlaceholder="`Select ${$t('global.venue')}`"
          :selected="selectedVenue"
          @selected="onVenueSelect"
          align-left
        >
          <template #result="{ result }">
            <v-venue-entity-info :venue="result" />
          </template>
        </v-select-search>

        <ez-drop-down
          v-if="$permission.has('seeCRMForAll')"
          :disabled="dropdownDisabled"
          :placeholder="allAccounts.name"
          class="filter-select"
          :data="accountOwners"
          :selected="filtersVals.accountOwnerId"
          name="accountOwnerId"
          @change="selectAccountOwner"
        />

        <v-date-picker
          class="ml-12"
          name="range"
          label="Range"
          v-model="range"
          rangeMode
          hideLabel
          withPredefinedRanges
          :numberOfCalendars="2"
          :selectPredefinedRange="rangeName"
          @rangeNameChange="onRangeNameChange"
          @dateChange="selectDateRange"
        />
      </div>
      <div class="account-activity__actions">
        <ez-simple-dropdown
          v-if="canExportOrderingAccountsData"
          class="export-csv"
          :expandOnClick="true"
          buttonType="secondary"
          placeholder="Export Accounts Data"
        >
          <template #dropdown>
            <ez-button
              type="link"
              formType="button"
              customClass="upload-csv"
              @click="onExportAccountsData"
            >
              Export Ordering Accounts Data
            </ez-button>
            <ez-button
              type="link"
              formType="button"
              customClass="upload-csv"
              @click="onExportAllAccountsData"
            >
              Export All Accounts Data
            </ez-button>
          </template>
        </ez-simple-dropdown>
        <ez-button @click="addTargetAccount" class="ml-12"> Create Target Account </ez-button>
      </div>
    </div>
    <div class="activity-container">
      <activity-track ref="targetList" :is-content-empty="!targetList || !targetList.length">
        <template #header>
          <div class="u-flex-space width-100">
            <span> Target Accounts </span>
            <ez-badge type="grey" :count="targetCount" :show-every-number="true" />
          </div>
        </template>
        <template #content>
          <activity-card
            v-for="account in targetList"
            :key="account.id"
            :venue="account"
            :date-label="targetConstants.dateLabel"
            :notify-msg="notifyMsgForAccount(account)"
            :delete-enabled="deleteEnabled(account)"
            @click="openTargetAccount(account)"
            @deleteAccount="openConfirmationModal(account)"
          />

          <div v-if="isLoadingMoreTarget" class="u-text-center mt-12">
            <ez-spinner />
          </div>

          <ez-load-more
            v-if="!(isLoading || isLoadingMoreTarget) && metaTarget.lastPage > metaTarget.page"
            @loadMore="fetchTargetList(metaTarget.page + 1, loadingKeyMoreTarget)"
          />
        </template>
      </activity-track>
      <activity-track ref="pendingList" :is-content-empty="!pendingList || !pendingList.length">
        <template #header>
          <div class="u-flex-space width-100">
            <span> Pending Account Opening </span>
            <ez-badge type="grey" :count="pendingCount" :show-every-number="true" />
          </div>
        </template>
        <template #content>
          <activity-card
            v-for="account in pendingList"
            :key="account.id"
            :venue="account"
            :date-label="pendingConstants.dateLabel"
            :notify-msg="notifyMsgForAccount(account)"
            :delete-enabled="deleteEnabled(account)"
            @click="openPendingAccount(account)"
            @deleteAccount="openConfirmationModal(account)"
          />

          <div v-if="isLoadingMorePending" class="u-text-center mt-12">
            <ez-spinner />
          </div>

          <ez-load-more
            v-if="!isLoadingMorePending && metaPending.lastPage > metaPending.page"
            @loadMore="fetchPendingList(metaPending.page + 1, loadingKeyMorePending)"
          />
        </template>
      </activity-track>
      <activity-track ref="openedList" :is-content-empty="!openedList || !openedList.length">
        <template #header>
          <div class="u-flex-space width-100">
            <span> Account Open, Yet to Buy </span>
            <ez-badge type="grey" :count="openedCount" :show-every-number="true" />
          </div>
        </template>
        <template #content>
          <activity-card
            v-for="account in openedList"
            :key="account.id"
            :venue="account"
            :date-label="openedConstants.dateLabel"
            :notify-msg="notifyMsgForAccount(account)"
            @click="openOpenedAccount(account)"
          />

          <div v-if="isLoadingMoreOpened" class="u-text-center mt-12">
            <ez-spinner />
          </div>

          <ez-load-more
            v-if="!isLoadingMoreOpened && metaOpened.lastPage > metaOpened.page"
            @loadMore="fetchOpenedList(metaOpened.page + 1, loadingKeyMoreOpened)"
          />
        </template>
      </activity-track>
      <activity-track
        ref="notOrderingList"
        :is-content-empty="!notOrderingList || !notOrderingList.length"
      >
        <template #header>
          <div class="u-flex-space width-100">
            <span> Not Ordering </span>
            <ez-badge type="grey" :count="notOrderingCount" :show-every-number="true" />
          </div>
        </template>
        <template #content>
          <activity-card
            v-for="account in notOrderingList"
            :key="account.id"
            :venue="account"
            :date-label="notOrderingConstants.dateLabel"
            :notify-msg="notifyMsgForAccount(account)"
            @click="openNotOrderingAccount(account)"
          />

          <div v-if="isLoadingMoreNotOrdering" class="u-text-center mt-12">
            <ez-spinner />
          </div>

          <ez-load-more
            v-if="!isLoadingMoreNotOrdering && metaNotOrdering.lastPage > metaNotOrdering.page"
            @loadMore="fetchNotOrderingList(metaNotOrdering.page + 1, loadingKeyMoreNotOrdering)"
          />
        </template>
      </activity-track>
      <activity-track ref="orderingList" :is-content-empty="!orderingList || !orderingList.length">
        <template #header>
          <div class="u-flex-space width-100">
            <span>Ordering</span>
            <ez-badge type="grey" :count="orderingCount" :show-every-number="true" />
          </div>
        </template>
        <template #content>
          <activity-card
            v-for="account in orderingList"
            :key="account.id"
            :venue="account"
            :date-label="orderingConstants.dateLabel"
            :notify-msg="notifyMsgForAccount(account)"
            :to-show-footer="true"
            @click="openOrderingAccount(account)"
          />

          <div v-if="isLoadingMoreOrdering" class="u-text-center mt-12">
            <ez-spinner />
          </div>

          <ez-load-more
            v-if="!isLoadingMoreOrdering && metaOrdering.lastPage > metaOrdering.page"
            @loadMore="fetchOrderingList(metaOrdering.page + 1, loadingKeyMoreOrdering)"
          />
        </template>
      </activity-track>
    </div>

    <account-drawer
      id="rukiDrawer"
      ref="accountDrawer"
      :badge="singleView.badge"
      :venue="singleView.venue"
      :disable-redirect-by-venue="singleView.disableRedirectByVenue"
      :date-label="singleView.dateLabel"
      :date="singleView.date"
      :account-owner="singleView.accountOwner"
      :account-options="singleView.accountOptions"
      :products="singleView.products"
      :orders="singleView.orders"
      :content-title="singleView.contentTitle"
      :content-subtitle="singleView.contentSubtitle"
      :contact-info-data="singleView.contactInfoData"
      :total="singleView.total"
      :total-label="singleView.totalLabel"
      :notification="singleView.notification"
      :next-id-orders="metaOrders.nextId"
      :next-id-products="metaProducts.nextId"
      :showProductsEmpty="singleView.showProductsEmpty"
      :money-spent="singleView.moneySpent"
      :delete-enabled="deleteEnabled(singleView.venue)"
      :actions="singleView.actions"
      :is-loading-more-orders="isLoadingMoreSingleOrders"
      :is-loading-more-products="isLoadingMoreSingleProducts"
      @sendConnectionReq="openConnectionReqModal"
      @resendRequest="openConnectionReqModal"
      @sendOnboardingLink="openOnboardingLinkWizard"
      @createOutlet="openCreateOutletWizard"
      @resendInvite="openOnboardingModal"
      @loadMoreOrders="onLoadMoreOrders"
      @loadMoreProducts="onLoadMoreProducts"
      @setAccountOwner="onSetAccountOwner"
      @onClose="resetRouteId"
      @deletePending="openConfirmationModal({ id: invitationId })"
    />

    <create-target-account-modal
      ref="createTargetAccountModal"
      :prefilled-data="{ accountOwnerId: loggedUser.id }"
      @submitSuccess="onCreateAccountSuccess"
      @submitError="onCreateAccountError"
    />

    <onboarding-link-modal
      ref="onboardingModal"
      :action="action"
      method="put"
      :data="onboardingData"
      :names-to-disable="['name']"
      :send-disabled="true"
      @success="onSuccessOnboarding"
    />

    <connection-request-modal
      ref="connectionReqModal"
      :action="action"
      method="put"
      :data="onboardingData"
      :names-to-disable="['name']"
      :send-disabled="true"
      @success="onSuccessConnectionReq"
    />

    <ez-loader :show="isLoaderShown || isLoading"> Loading... </ez-loader>
    <ez-confirmation-modal ref="deletePendingAccountModal" type="red">
      <template #title>Remove this Account?</template>
      <template #content>
        <p>
          You are about to remove this account from your list. <br />
          The account will not be able to access the onboarding link after you remove it. <br />
          All to-dos created for this account will be removed, too.
        </p>
      </template>
      <template #footer>
        <ez-button @click="close" type="link">Cancel</ez-button>
        <ez-button @click="deletePendingAccount" type="red">Remove Account</ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<style lang="scss" scoped>
:deep() .select-search {
  color: $color-gray-6C;

  &__list {
    max-height: 244px;
  }
}

.activity {
  display: flex;
  flex-direction: column;
  .activity-container {
    display: flex;
    margin-top: 24px;
    overflow: auto;
  }
  .actions {
    @extend %flex-center;
    justify-content: space-between;
  }
  .filters {
    @extend %flex-center;
  }

  .account-activity__actions {
    margin-left: auto;
    display: flex;
    align-items: center;

    .export-csv {
      width: auto;

      :deep() .ez-simple-dropdown {
        &__label {
          margin-right: 16px;
        }

        &__display-container--active {
          background-color: $color-gray-E9;
        }

        &__dropdown {
          width: fit-content;
          overflow: visible;
        }
      }

      .button--link {
        min-width: 100%;
        width: max-content;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        text-align: left;
        font-weight: 400;

        &:hover {
          background: $color-gray-F5;
        }

        &:disabled {
          color: $color-gray-6C;
          background-color: transparent;
        }
      }
    }
  }
}
</style>
