<template>
  <div>
    <venue-overview v-if="!isVenueEditable" :venue="venueInfo" />
    <div class="info-container" v-else>
      <venue-form
        ref="venueForm"
        submitType="multipart"
        :additional-data="{ ...(imgModified && { logo: '' }) }"
        :formKey="editFormKey"
        :action="`/distributor/venues/${venueId}`"
        :venueId="venueId"
        method="patch"
        @submitSuccess="onEditFormSubmit"
      >
        <template #prepend>
          <ez-image-upload
            class="image-uploader"
            :formKey="editFormKey"
            :isRound="true"
            :previewUrl="venue.logo || defaultImage"
            :isDefaultImage="venue.isDefaultImage"
            @change="imgModified = true"
            name="logo"
            @removeImage="onRemoveImage(venue)"
          >
            <template #addImage>
              <span>Change {{ $t('global.venue') }} Logo</span>
            </template>
            <template #removeImage>
              <font-awesome-icon icon="times" />
              <span>Remove Logo</span>
            </template>
          </ez-image-upload>
        </template>

        <template #buttons>
          <ez-button form-type="submit" class="mt-16">
            Save Changes
          </ez-button>
        </template>
      </venue-form>
    </div>
  </div>
</template>

<script>
/**
 * VenueInfo
 * @version 1.0.0
 * @since 2.3.0
 */

import flash from '@/components/ui/FlashMessage';
import VenueForm from '@/views/admin/venues/Form.vue';
import Venue from '@/models/Venue';
import VenueOverview from '@/views/platform/distributor/outlets/VenueOverview.vue';
import EzImageUpload from '@/components/ui/ImageUpload';

import { mapGetters, mapState } from 'vuex';
import EzButton from '@/components/ui/Button/EzButton';
import { falsy } from '@/util/utils';

export default {
  components: {
    EzButton,
    EzImageUpload,
    VenueOverview,
    VenueForm,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editFormKey: 'edit-venue',
      distributorCopy: {},
      imgModified: false,
    };
  },
  computed: {
    ...mapGetters('entities/users', ['isDistributor']),
    ...mapGetters('defaultImages', ['getDefaultImage']),
    ...mapState('entities/venues', {
      venueInfo: 'singleDistributorVenue',
    }),
    venue() {
      return Venue.find(this.venueId) || {};
    },
    isVenueEditable() {
      return this.venue.editable;
    },
    defaultImage() {
      return this.getDefaultImage('venue');
    },
    isIntegrationOutlet() {
      return !falsy(this.venue.provider);
    },
  },
  methods: {
    onRemoveImage(venue) {
      venue.logo = null;
      venue.isDefaultImage = true;
      this.imgModified = true;
    },
    onEditFormSubmit({ data }) {
      Venue.update({ data: data.data });
      flash.success({
        title: `${this.$t('global.venue')} successfully updated!`,
      });
    },
  },
  created() {
    if (this.isIntegrationOutlet) {
      this.$router.push({ name: 'distributor-outlets-sales-settings' });
    }
  },
};
</script>

<style scoped lang="scss">
.address {
  @include separator('both');
}
.overview-item {
  @include separator('bottom');
  h5 {
    line-height: px-to-rem(16px);
    font-max-size: px-to-rem(14px);
    font-weight: 500;
    letter-spacing: -0.25px;
  }
}

:deep() .ez-image-upload {
  margin-bottom: 16px;
}
</style>
