<template>
  <div>
    <venue-form
      ref="form"
      :method="method"
      :action="action"
      submitType="multipart"
      :formKey="formKey"
      :loading-key="loadingKeyForm"
      :venueId="venueId"
      :prefilledName="prefilledName"
      :venueData="venueData"
      :additional-data="additionalData"
      @formValid="formValid"
      @submitSuccess="onSubmitSuccess"
      @submitError="onSubmitError"
    >
      <template #prepend>
        <ez-image-upload
          class="image-uploader"
          :formKey="formKey"
          :isRound="true"
          :previewUrl="draft.logo || defaultImage"
          :isDefaultImage="draft.isDefaultImage"
          :columnMode="true"
          name="logo"
          @removeImage="onRemoveAvatar"
          @change="onImageChanged"
        >
          <template #addImage>
            <span>Add {{ $t('global.venue') }} Logo</span>
          </template>
          <template #removeImage>
            <font-awesome-icon icon="times" />
            <span>Remove Logo</span>
          </template>
        </ez-image-upload>
      </template>

      <template #prependBottom>
        <div class="netsuite-options" v-if="netsuiteConnected">
          <hr />

          <h4>{{ $t('netsuite.name') }}</h4>

          <v-select-search
            name="netsuite[salesRepId]"
            label="Sales Rep"
            placeholder="Select Sales Rep"
            search="/distributor/netsuite/employees"
            :disable="connectionExpired"
            :data="searchResults || salesReps"
            :min-length="3"
            :debounce-time="700"
            :selected="netsuite.salesRepId"
            @selected="onChange('salesRepId', $event)"
            @results="onSearchResults"
            @close="clearSearchResults"
            is-full-width
          />

          <v-select-search
            class="mt-12"
            name="netsuite[priceLevelId]"
            label="Price Level"
            placeholder="Select Price Level"
            search="/distributor/netsuite/price-levels"
            :disable="connectionExpired"
            :data="searchResults || priceLevels"
            :min-length="3"
            :debounce-time="700"
            :selected="netsuite.priceLevelId"
            @selected="onChange('priceLevelId', $event)"
            @results="onSearchResults"
            @close="clearSearchResults"
            is-full-width
          />
        </div>
      </template>
    </venue-form>
  </div>
</template>

<script>
/**
 * NewVenueInfo
 * @version 1.0.0
 * @since 2.3.0
 */

import VenueForm from '@/views/admin/venues/Form.vue';
import EzImageUpload from '@/components/ui/ImageUpload';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import Venue from '@/models/Venue';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch/index';

export default {
  mixins: [wizardListenerMixin],
  components: {
    VenueForm,
    EzImageUpload,
    VSelectSearch,
  },
  props: {
    prefilledName: {
      type: String,
    },
    venueData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      formKey: 'venue-settings',
      imgModified: false,
      groupCopy: {},
      loadingKeyForm: 'create-update-venue',
      netsuite: {
        salesRepId: null,
        priceLevelId: null,
      },
      salesReps: [],
      priceLevels: [],
      searchResults: null,
    };
  },
  computed: {
    ...mapState('entities/venues', ['draft']),
    ...mapGetters('defaultImages', ['getDefaultImage']),
    ...mapGetters('entities/users', {
      netsuiteConnected: 'isDistributorNetSuiteConnected',
      connectionExpired: 'isDistributorNetSuiteConnectionExpired',
    }),
    defaultImage() {
      return this.getDefaultImage('venue');
    },
    venueId() {
      return this.draft.id;
    },
    isNewVenue() {
      return this.venueId === -1;
    },
    method() {
      return this.isNewVenue ? 'post' : 'patch';
    },
    action() {
      return this.isNewVenue ? this.createAction : this.updateAction;
    },
    createAction() {
      return '/distributor/venues';
    },
    updateAction() {
      return `/distributor/venues/${this.venueId}`;
    },
    additionalData() {
      return {
        ...(this.imgModified && { logo: '' }),
        ...(!this.isNewVenue && { targetAccount: false }),
      };
    },
  },
  methods: {
    ...mapMutations('entities/venues', ['UPDATE_DRAFT']),
    ...mapActions('netsuite', [
      'distributorNetsuiteFetchEmployees',
      'distributorNetsuiteFetchPriceLevels',
    ]),
    onSubmitSuccess({ data }) {
      Venue.insertOrUpdate({ data: data.data });
      this.UPDATE_DRAFT(data.data);

      this.$emit('stepCompleted');
    },
    onNextStep() {
      this.$refs.form.submitForm();
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    formValid(valid) {
      if (valid) this.enableNextStep();
      else this.disableNextStep();
    },
    onSubmitError() {},
    onRemoveAvatar() {
      this.UPDATE_DRAFT({ logo: null, isDefaultImage: true });
      this.imgModified = true;
    },
    onImageChanged(img) {
      this.UPDATE_DRAFT({ logo: img, isDefaultImage: !img });
      this.imgModified = true;
    },
    onChange(key, value) {
      this.netsuite = {
        ...this.netsuite,
        [key]: value.reset ? null : value.id,
      };
    },
    onSearchResults({ results }) {
      if (results.length) this.searchResults = results;
      else this.clearSearchResults();
    },
    clearSearchResults() {
      this.searchResults = null;
    },
  },
  async created() {
    if (!this.netsuiteConnected || this.connectionExpired) return;

    const [{ data: salesReps }, { data: priceLevels }] = await Promise.all([
      this.distributorNetsuiteFetchEmployees(),
      this.distributorNetsuiteFetchPriceLevels(),
    ]);

    this.salesReps = salesReps.data;
    this.priceLevels = priceLevels.data;
  },
  mounted() {
    this.netsuite = { ...this.draft.netsuite };
  },
};
</script>

<style lang="scss" scoped>
.image-uploader {
  margin-bottom: 24px;
}

.netsuite-options h4 {
  @include font-size(14px);
  font-weight: 400;
  padding: 0;
  margin-bottom: 16px;
}
</style>
