<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';

import EzTabs from '@/components/ui/Tabs/EzTabs.vue';
import EzTab from '@/components/ui/Tabs/EzTab.vue';

import ProductsList from './credit-note-content/ProductsList.vue';
import AdditionalItems from './credit-note-content/AdditionalItems.vue';
import NotesList from './credit-note-content/NotesList.vue';

/**
 * CreditNoteContent
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'CreditNoteContent',
  props: {
    creditNote: {
      required: true,
      type: Object,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    EzTabs,
    EzTab,
    ProductsList,
    AdditionalItems,
    NotesList,
  },
  data() {
    return {
      updatedCreditNote: {},
      supplierCy,
    };
  },
  methods: {
    onUpdateProducts(items) {
      this.updatedCreditNote = { ...this.updatedCreditNote, items };
    },
    onUpdateAdditionalItem(item) {
      this.updatedCreditNote = { ...this.updatedCreditNote, ...item };
    },
  },
  watch: {
    updatedCreditNote(val) {
      this.$emit('update', val);
    },
  },
};
</script>

<template>
  <ez-tabs>
    <ez-tab
      title="Stock Credit"
      hash="tab-1"
      :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.BUTTON__STOCK_CREDIT_TAB"
    >
      <products-list :creditNote="creditNote" :editMode="editMode" @update="onUpdateProducts" />
      <additional-items
        :creditNote="creditNote"
        :editMode="editMode"
        @update="onUpdateAdditionalItem"
      />
    </ez-tab>

    <ez-tab
      title="Notes"
      hash="tab-2"
      :data-cy="supplierCy.ORDERS.CREDIT_NOTES.SINGLE_CREDIT_NOTE.TABS.BUTTON__NOTES_TAB"
    >
      <notes-list :creditNote="creditNote" />
    </ez-tab>
  </ez-tabs>
</template>

<style lang="scss" scoped>
:deep() .ez-tabs {
  &__buttons {
    li:first-child {
      button {
        margin-left: 0;
      }
    }
  }
}
</style>
