import i18n from '@/i18n';

export const VENUE_STEP_1 = 0;
export const VENUE_STEP_2 = 1;
export const VENUE_STEP_3 = 2;

const steps = {
  [VENUE_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/send-onboarding-link/Info'),
    title: 'Send Onboarding Link - Contact Info',
    pageTitle: 'Please add contact info',
    pageInfo: `Enter the name of the ${i18n.t('global.venue')} and your onboarding contact, and choose how you'd like to send the onboarding link.`,
    nextCta: 'Next Step',
  },
  [VENUE_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/send-onboarding-link/Products'),
    title: 'Send Onboarding Link - Products',
    pageTitle: 'And now select products',
    pageInfo: `Select products that will be available to this ${i18n.t('global.venue')}.`,
    nextCta: 'Go to Overview',
  },
  [VENUE_STEP_3]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/send-onboarding-link/Overview'),
    title: 'Send Onboarding Link - Overview',
    pageTitle: 'Overview',
    pageInfo: 'Before you send the onboarding link, check the information below to make sure you’ve entered everything correctly.',
    nextCta: 'Send Onboarding Link',
  },
};

export default steps;
