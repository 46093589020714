<template>
  <single-page>
    <ez-loader :show="isLoading">
      {{ loadingMsg }}
    </ez-loader>
    <template #breadcrumbs>
      <router-link :to="{ name: 'distributor-outlets' }">
        <font-awesome-icon icon="arrow-left" />
        <span>Back to {{ $t('global.venues') }}</span>
      </router-link>
    </template>

    <template #title>
      <ez-entity-info imgBorderRadius="50%" imgWidth="2rem" imgHeight="2rem" :imgUrl="venue.logo">
        <h1>{{ venue.name }} <ez-premium-badge v-if="$helpers.isPremium(venue.accountType)" /></h1>
      </ez-entity-info>
    </template>
    <template v-if="!isProviderShopify" #actions>
      <ez-button-dropdown buttonType="secondary">
        <template #icon>
          <font-awesome-icon icon="ellipsis-h" />
        </template>
        <template #dropdown>
          <ez-button @click="removeVenue" type="red-link">
            Disconnect {{ $t('global.venue') }}
          </ez-button>
        </template>
      </ez-button-dropdown>
    </template>
    <template #navigation>
      <ul>
        <li v-if="!isIntegrationOutlet">
          <router-link :to="{ name: 'distributor-outlets-single' }">
            {{ $t('global.venue') }} Info
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'distributor-outlets-sales-settings' }">Settings</router-link>
        </li>
        <li v-if="canEditProduct && isTrackingInventory">
          <router-link :to="{ name: 'distributor-outlets-inventory-settings' }">
            Inventory Settings
          </router-link>
        </li>
        <li v-if="!isIntegrationOutlet">
          <router-link :to="{ name: 'distributor-outlets-single-users' }">Contacts</router-link>
        </li>
        <li v-if="!isIntegrationOutlet">
          <router-link :to="{ name: 'distributor-outlets-single-products' }">Products</router-link>
        </li>
      </ul>
    </template>
  </single-page>
</template>

<script>
/**
 * VenueSingle
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapActions, mapGetters, mapState } from 'vuex';
import EzButton from '@/components/ui/Button';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import EzEntityInfo from '@/components/ui/EntityInfo';
import SinglePage from '@/views/admin/SinglePage.vue';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import Venue from '@/models/Venue';
import ConnectedEntries from '@/models/ConnectedEntries';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { LOADING_KEY } from '@/util/constants';
import confirmation from '@/components/v3/patterns/VConfirmation/control';
import { falsy } from '@/util/utils';

export default {
  components: {
    EzLoader,
    EzButton,
    EzButtonDropdown,
    EzEntityInfo,
    SinglePage,
    EzPremiumBadge,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loadingMsg: 'Loading...',
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_DISTRIBUTOR_VENUE,
        LOADING_KEY.FETCH_TIME_ZONES,
        LOADING_KEY.GET_USERS,
        LOADING_KEY.FETCH_VENUE_USERS,
        LOADING_KEY.DISCONNECT_VENUE,
        LOADING_KEY.REMOVE_USER,
        LOADING_KEY.JOKER,
      ]);
    },
    distributor() {
      return this.loggedUser?.distributor;
    },
    venue() {
      return Venue.query().find(this.venueId) || { group: {} };
    },
    isIntegrationOutlet() {
      return !falsy(this.venue.provider);
    },
    isProviderShopify() {
      return this.isIntegrationOutlet && this.venue.provider === 'shopify';
    },
    isVenueEditable() {
      return this.venue.editable;
    },
    canEditProduct() {
      return this.$permission.has('editProductInfo');
    },
    isTrackingInventory() {
      return this.distributor.inventorySettings?.tracking;
    },
  },
  methods: {
    async removeVenue() {
      const { venueId } = this;
      const venue = this.$t('global.venue').toLowerCase();
      const venues = this.$t('global.venues').toLowerCase();
      const { close, canceled } = await confirmation.confirm({
        title: `Disconnect from this ${this.$t('global.venue')}?`,
        message: `When disconnected, you won’t be able to see this ${venue}
        in your list of ${venues} nor to create orders on behalf of them.
        This ${venue}’s past order info will still be accessible to you.`,
        buttons: {
          confirm: 'Disconnect',
        },
      });
      if (canceled) return;
      close();

      this.disconnectVenue({ id: venueId }).then(() => {
        ConnectedEntries.delete(venueId);
        this.$router.push({
          name: 'distributor-outlets',
          params: {
            flash: {
              type: 'success',
              title: `${this.$t('global.venue')} successfully disconnected!`,
            },
          },
        });
      });
    },
    ...mapActions('entities/venues', ['disconnectVenue', 'fetchVenue']),
  },
  created() {
    this.fetchVenue(this.venueId);
  },
};
</script>
