<script>
import EzBadge from '@/components/ui/Badge/EzBadge';
import EzButton from '@/components/ui/Button/EzButton';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'footerForUpdate',
  components: {
    EzBadge,
    EzButton,
  },
  props: {
    entity: {
      type: String,
      required: false,
      default: 'Products',
    },
    itemsCount: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  methods: {
    onCtaClick() {
      this.$emit('footerAction');
    },
  },
};
</script>

<template>
  <div class="footer">
    <div class="footer__container">
      <div
        class="selected-items"
      >
        {{ entity }} Selected: <ez-badge type="blue" :count="itemsCount"/>
      </div>
      <ez-button @click="onCtaClick">
        Update {{ entity }}
      </ez-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  background-color: #ffffff;
  border-top: 1px solid $color-gray-E9;
  width: 100%;
  height: 56px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  &__container {
    padding: 0 48px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .selected-items {
    color: $color-gray-6C;
    @include font-size(14px, 20px);
  }
}
</style>
