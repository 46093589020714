<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardEmitterMixin } from '@/mixins/wizard';
import { LOADING_KEY } from '@/util/constants';

import EzButton from '@/components/ui/Button';
import EzWizard from '@/components/layout/Wizard.vue';
import EzLoader from '@/components/ui/Loader/EzLoader';
import steps, { REMOVAL_STEP_1, REMOVAL_STEP_2, REMOVAL_STEP_3 } from './steps';

/**
 * Inventory Remove Flow
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  mixins: [wizardEmitterMixin],
  props: {
    distributorId: {
      type: Number,
      default: null,
    },
  },
  components: { EzButton, EzWizard, EzLoader },
  data() {
    return {
      REMOVAL_STEP_1,
      REMOVAL_STEP_2,
      REMOVAL_STEP_3,
      supplierCy,
      currentStep: 0,
      loadingMsg: 'Loading...',
      nextDisabled: true,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    ...mapState('entities/products', ['inventoryManagement']),
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.DISTRIBUTOR_FETCH_WAREHOUSES]);
    },
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === REMOVAL_STEP_1;
    },
    isLastStep() {
      return this.currentStep === REMOVAL_STEP_3;
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributor']),
    ...mapMutations('entities/products', ['CLEAR_DRAFT']),
    onWizardExit() {
      this.CLEAR_DRAFT();
      return this.$router.push({ name: 'distributor-products' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= REMOVAL_STEP_1 && step <= REMOVAL_STEP_3) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= REMOVAL_STEP_1) return;

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= REMOVAL_STEP_3) {
        return;
      }

      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
  created() {
    if (this.inventoryManagement) this.CLEAR_DRAFT();
    this.currentStep = REMOVAL_STEP_1;
  },
  async mounted() {
    await this.initDistributor();
  },
  beforeDestroy() {
    this.CLEAR_DRAFT();
  },
};
</script>

<template>
  <ez-wizard
    :extraWide="currentStep === REMOVAL_STEP_2"
    @exit="onWizardExit"
    @back="goToPreviousStep"
  >
    <template #title>Remove Products</template>
    <template #nav>
      <ul>
        <li
          :class="{
            'ez-step-active': currentStep === REMOVAL_STEP_1,
            'ez-step-completed': currentStep > REMOVAL_STEP_1,
          }"
        >
          1. Warehouse
        </li>
        <li
          :class="{
            'ez-step-active': currentStep === REMOVAL_STEP_2,
            'ez-step-completed': currentStep > REMOVAL_STEP_2,
          }"
        >
          2. Products
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button
        :disabled="nextDisabled"
        @click="goToNextStep"
        :dataCy="supplierCy.INVENTORY_FLOWS.REMOVAL.GENERAL.BUTTON__NEXT_STEP"
      >
        {{ nextCta }}
      </ez-button>
    </template>
    <template #prevStep>
      <ez-button
        v-if="[REMOVAL_STEP_2, REMOVAL_STEP_3].includes(currentStep)"
        type="link"
        formType="button"
        @click="goToPreviousStep"
        :dataCy="supplierCy.INVENTORY_FLOWS.REMOVAL.GENERAL.BUTTON__PREV_STEP"
      >
        <font-awesome-icon icon="arrow-left" />
        <span>Back</span>
      </ez-button>
    </template>
    <template #pageTitle>{{ currentStepPageTitle }}</template>
    <template #pageInfo>
      <div v-html="currentStepPageInfo"></div>
    </template>
    <component
      :is="currentStepComponent"
      :distributorId="distributorId"
      @stepCompleted="onStepCompleted"
      @stepBack="onStepBack"
    />
    <ez-loader :show="isLoading">
      {{ loadingMsg }}
    </ez-loader>
  </ez-wizard>
</template>
