<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { mapState, mapMutations, mapActions } from 'vuex';
import dayjs from 'dayjs';
import EzAutocomplete from '@/components/ui/Autocomplete';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import { EzSelectedVenue } from '@/components/ui/SelectedItem';
import EzInput from '@/components/ui/Input';
import EzTextarea from '@/components/ui/Textarea';
import { capitalizeEveryWord } from '@/util/utils';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import {
  ActivityType,
  DATE_INPUT_FORMAT,
  DATE_PLACEHOLDER,
  TIME_INPUT_FORMAT,
  TIME_PLACEHOLDER,
} from '@/util/constants';

export default {
  name: 'Outlet',
  mixins: [wizardListenerMixin],
  components: {
    EzAutocomplete,
    EzSelectedVenue,
    EzTextarea,
    EzInput,
    VVenueEntityInfo,
    EzDropdown,
    VDatePicker,
  },
  data() {
    return {
      result: {},
      today: dayjs(),
      tomorrow: dayjs().add(1, 'day').format(DATE_INPUT_FORMAT),
      date: null,
      dateObj: null,
      time: null,
      note: null,
      activityTypes: [],
      type: null,
      formatDate: DATE_INPUT_FORMAT,
      formatTime: TIME_INPUT_FORMAT,
      datePlaceholder: DATE_PLACEHOLDER,
      timePlaceholder: TIME_PLACEHOLDER,
      isTimeValid: false,
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/distributors', ['checkInInfo']),
    timeValidators() {
      if (this.dateObj.isSame(this.today, 'day')) {
        const timeLimit = this.today.add(1, 'minute').format(this.formatTime);
        return `required|date_format:${this.formatTime}|before:${timeLimit}`;
      }
      return 'required';
    },
    hasSelectedVenue() {
      return Object.keys(this.result || {}).length > 0;
    },
    hasFirstStep() {
      return (
        this.date && this.time && this.note && this.type && this.type.value && this.isTimeValid
      );
    },
    hasFirstStepPrefilled() {
      return this.checkInInfo.datetime && this.checkInInfo.note && this.checkInInfo.type;
    },
    datetime() {
      return `${this.date} ${this.time}`;
    },
    activityTypeId() {
      return this.checkInInfo.type?.id;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', [
      'CLEAR_CHECK_IN_INFO_SPENT',
      'CLEAR_CHECK_IN_INFO_TAX',
      'CLEAR_CHECK_IN_INFO_RECEIPTS',
      'UPDATE_CHECK_IN_INFO',
    ]),
    ...mapActions('reports', ['fetchActivityTypes']),
    onValidationChangeTime(newState) {
      this.isTimeValid = newState.valid;
    },
    onGoToStep({ step }) {
      this.UPDATE_CHECK_IN_INFO({
        type: this.type,
        venue: this.result,
        datetime: dayjs(this.datetime),
        note: this.note,
      });
      this.$emit('stepBack', step);
    },
    onNextStep() {
      this.UPDATE_CHECK_IN_INFO({
        type: this.type,
        venue: this.result,
        datetime: dayjs(this.datetime),
        note: this.note,
      });
      this.$emit('stepCompleted');
    },
    onVenueSelect(result) {
      this.result = result.selected;
      this.CLEAR_CHECK_IN_INFO_RECEIPTS();
      this.CLEAR_CHECK_IN_INFO_SPENT();
      this.CLEAR_CHECK_IN_INFO_TAX();
    },
    removeSelectedVenue() {
      this.result = {};
    },
    updateDate(date) {
      this.date = dayjs(date).format('YYYY-MM-DD');
      this.dateObj = dayjs(date);
    },
    updateTime(time) {
      this.time = time;
    },
    updateNote(note) {
      this.note = note;
    },
    updateType(type) {
      this.type = type;
    },
  },
  async created() {
    this.date = dayjs().format(this.formatDate);
    this.dateObj = dayjs();
    this.time = dayjs().format(this.formatTime);

    const { data } = await this.fetchActivityTypes();
    this.activityTypes = data.data
      .filter(type => type.key !== ActivityType.TODO)
      .map(type => ({
        id: type.key,
        value: type.key,
        name: capitalizeEveryWord({
          string: type.name,
        }),
      }));

    this.result = this.checkInInfo.venue;

    if (this.hasFirstStepPrefilled) {
      this.type = this.checkInInfo.type;
      this.result = this.checkInInfo.venue;
      this.date = this.checkInInfo.datetime.format(this.formatDate);
      this.time = this.checkInInfo.datetime.format(this.formatTime);
      this.note = this.checkInInfo.note;
    }
  },
  watch: {
    hasFirstStep(val) {
      if (val) {
        this.enableNextStep();
      } else {
        this.disableNextStep();
      }
    },
  },
};
</script>
<template>
  <div>
    <div v-if="checkInInfo.isVenueAssigned">
      <ez-autocomplete
        v-if="!hasSelectedVenue"
        :source="`distributor/expenses/search/venues`"
        :hasConnectedParam="false"
        search-property="name"
        @selected="onVenueSelect"
        :placeholder="`Enter ${$t('global.venue')} Name`"
        :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.INFO.INPUT__OUTLET"
        :result-data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.INFO.INPUT__OUTLET"
      >
        <template #result="{ result }">
          <v-venue-entity-info :venue="result" />
        </template>
      </ez-autocomplete>
      <ez-selected-venue
        v-else
        @remove="removeSelectedVenue"
        :venue="result"
        :only-basic-info="true"
        :hide-remove-button="checkInInfo.disableVenueChange"
        :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.INFO.TEXT__SELECTED_OUTLET"
        :remove-button-data-cy="
          supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.INFO.BUTTON__REMOVE_SELECTED_OUTLET
        "
      />
    </div>
    <ez-dropdown
      ref="activityType"
      class="activity-select mt-12"
      label="Activity Type"
      :data="activityTypes"
      name="activityType"
      placeholder="Select Activity Type"
      is-full-width
      @change="updateType"
      :selected="activityTypeId"
      :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.INFO.BUTTON__ACTIVITY_TYPE"
    />
    <hr />
    <div class="date-time">
      <v-date-picker
        form-key="date"
        name="date"
        label="Date"
        class="mr-12"
        v-model="date"
        :placeholder="datePlaceholder"
        :max="today.format(formatDate)"
        @dateChange="updateDate"
        :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.INFO.BUTTON__DATE"
      />
      <ez-input
        form-key="time"
        @onChange="updateTime"
        label="Time"
        name="time"
        type="time"
        :value="time"
        :max="today.format(formatTime)"
        :required="true"
        :validators="timeValidators"
        @validationChange="onValidationChangeTime"
        :placeholder="timePlaceholder"
        :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.INFO.INPUT__TIME"
      />
    </div>
    <ez-textarea
      form-key="note"
      @onChange="updateNote"
      :value="note"
      :required="true"
      placeholder="Leave a Note"
      name="note"
      label="Note"
      :data-cy="supplierCy.CRM.TEAM_ACTIVITY.ADD_ACTIVITY.INFO.TEXTAREA__NOTE"
    />
  </div>
</template>
<style lang="scss" scoped>
.date-time {
  margin-bottom: 12px;
  display: flex;
  :deep() .input-group {
    width: 100%;
  }
  :deep() .ez-simple-dropdown.date-picker {
    width: auto;
  }
}

:deep() .autocomplete__input {
  font-size: 14px;
}

.activity-select {
  :deep() .ez-dropdown {
    &__label {
      color: $color-gray-6C;
    }
  }
}
</style>
