var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-notes__content-wrapper mt-24"},[_vm._l((_vm.groupedCreditNotes),function(group,id){return _c('div',{key:id,staticClass:"credit-notes__group"},[_c('h2',[_vm._v(_vm._s(group.title))]),_c('ez-table',{attrs:{"data":group.items,"columns":['creditNoteNumber', 'orderNumber', 'venue', 'date', 'status', 'amount'],"columnProps":{
        creditNoteNumber: { class: 'creditNoteNumber-cell' },
        orderNumber: { class: 'orderNumber-cell' },
        date: { class: 'creditNoteDate-cell' },
        status: { class: 'creditNoteStatus-cell' },
        amount: { class: 'amount-cell' },
      },"headers":{
        creditNoteNumber: () => 'Credit Note No.',
        orderNumber: () => 'Order No.',
        venue: () => _vm.$t('global.venue'),
      }},on:{"rowClick":_vm.onRowClick},scopedSlots:_vm._u([{key:"cell-creditNoteNumber",fn:function({ row }){return [_c('span',{attrs:{"data-cy":`${_vm.supplierCy.ORDERS.CREDIT_NOTES.TEXT__CREDIT_NOTE_NUMBER}-${row.id}`}},[_vm._v(" "+_vm._s(row.creditNoteNumber || '-')+" ")])]}},{key:"cell-orderNumber",fn:function({ row }){return [_c('span',{attrs:{"data-cy":`${_vm.supplierCy.ORDERS.CREDIT_NOTES.TEXT__ORDER_NUMBER}-${row.id}`}},[_vm._v(" "+_vm._s(row.orderNumber || '-')+" ")])]}},{key:"cell-venue",fn:function({ row }){return [_c('v-venue-entity-info',{attrs:{"venue":row.venue,"data-cy":`${_vm.supplierCy.ORDERS.CREDIT_NOTES.TEXT__CUSTOMER}-${row.id}`}})]}},{key:"cell-date",fn:function({ row }){return [_c('span',{attrs:{"data-cy":`${_vm.supplierCy.ORDERS.CREDIT_NOTES.TEXT__DATE}-${row.id}`}},[_vm._v(" "+_vm._s(_vm._f("date")(row.date))+" ")])]}},{key:"cell-status",fn:function({ row }){return [_c('status-badge',{attrs:{"status":row.status,"data-cy":`${_vm.supplierCy.ORDERS.CREDIT_NOTES.TEXT__STATUS}-${row.id}`}})]}},{key:"cell-amount",fn:function({ row }){return [_c('v-price',{attrs:{"price":row.amount || 0,"currency":row.currency,"show-market-price-info":false,"data-cy":`${_vm.supplierCy.ORDERS.CREDIT_NOTES.TEXT__AMOUNT}-${row.id}`}})]}}],null,true)})],1)}),(_vm.hasNoCreditNotes)?_c('empty-state',{staticClass:"mt-48",scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/no-products.svg"),"alt":""}})]},proxy:true},{key:"title",fn:function(){return [_vm._v("No credit notes")]},proxy:true},{key:"info",fn:function(){return [_vm._v("Add a new credit note or try with different filters.")]},proxy:true}],null,false,2613309792)}):_vm._e(),(_vm.isLoadingMore)?_c('div',{staticClass:"u-text-center mt-12"},[_c('ez-spinner')],1):_vm._e(),(_vm.meta.nextId && !_vm.isLoadingMore)?_c('ez-load-more',{on:{"loadMore":_vm.onLoadMore}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }