<script>
/**
 * QuickBooks Settings Page
 * @version 1.0.0
 * @since
 */
import Page from '@/views/admin/SinglePage.vue';
import VStatusBadge from '@/components/v3/elements/VStatusBadge';
import { mapActions, mapGetters } from 'vuex';
import EzButton from '@/components/ui/Button/EzButton';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown.vue';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import { distributorDisconnectQuickBooks } from '@/api/endpoints/quick-books';

export default {
  components: {
    EzButton,
    EzButtonDropdown,
    Page,
    VStatusBadge,
    EzConfirmationModal,
  },
  computed: {
    ...mapGetters('entities/users', [
      'getDistributor',
      'isDistributorQuickBooksConnected',
      'isDistributorQuickBooksTokenValid',
      'isDistributorQuickBooksReAuthorizationNeeded',
    ]),
    canReauthorize() {
      return this.isDistributorQuickBooksReAuthorizationNeeded;
    },
    organizationName() {
      return this.getDistributor?.quickBooks?.name || 'QuickBooks';
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributorLoggedUser']),
    ...mapActions('quickBooks', ['quickBooksAuthorizeDistributor']),
    reauthorizeQuickBooks() {
      this.quickBooksAuthorizeDistributor({
        route: 'distributor-quick-books-setup',
      });
    },
    async confirmQuickBooksDisconnect() {
      try {
        await distributorDisconnectQuickBooks();
        this.$router.push({
          name: 'distributor-integrations',
          params: {
            flash: {
              title: 'You have successfully disconnected from QuickBooks.',
            },
          },
        });
      } finally {
        this.$refs.quickBooksDisconnectModal.close();
      }
    },
  },
};
</script>
<template>
  <page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'distributor-integrations' }">
        <font-awesome-icon icon="arrow-left" />
        Back to Supplier Settings
      </router-link>
    </template>
    <template #title>
      <div class="u-flex-center title">
        <img src="@/assets/logo-qb.png" width="32" height="32" alt="QuickBooks Logo" />
        <h2 class="m-0 ml-8 xero-title">{{ organizationName }}</h2>
        <v-status-badge type="green" v-if="isDistributorQuickBooksConnected"
          >Connected</v-status-badge
        >
        <v-status-badge type="gray" v-else>Not Connected</v-status-badge>
      </div>
    </template>
    <template #actions>
      <div class="u-flex-center header-actions">
        <ez-button v-if="canReauthorize" class="mr-12" @click="reauthorizeQuickBooks">
          <font-awesome-icon icon="sync-alt" />
          <span>Reauthorize</span>
        </ez-button>
        <ez-button-dropdown v-if="isDistributorQuickBooksConnected" buttonType="secondary">
          <template #icon>
            <font-awesome-icon icon="ellipsis-h" />
          </template>
          <template #dropdown>
            <ez-button v-open="'quickBooksDisconnectModal'" type="red-link">
              {{ $t('global.actions.disconnect') }}
            </ez-button>
          </template>
        </ez-button-dropdown>
      </div>
    </template>
    <template #navigation>
      <ul>
        <li>
          <component
            class="navigation-link"
            :is="isDistributorQuickBooksConnected ? 'router-link' : 'span'"
            :to="
              isDistributorQuickBooksConnected ? { name: 'distributor-quick-books-account' } : null
            "
          >
            Account Info
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isDistributorQuickBooksConnected ? 'router-link' : 'span'"
            :to="
              isDistributorQuickBooksConnected ? { name: 'distributor-quick-books-outlets' } : null
            "
          >
            {{ $t('global.venues') }}
          </component>
        </li>
      </ul>
    </template>

    <ez-confirmation-modal ref="quickBooksDisconnectModal" type="red">
      <template #title>{{ $t('quickBooks.integrations.disconnectModalTitle') }}</template>
      <template #content>
        <div
          class="xero-description"
          v-html="$t('quickBooks.integrations.disconnectModalContent', { platform: platformName })"
        />
      </template>
      <template #footer>
        <ez-button v-close="'quickBooksDisconnectModal'" type="link">
          {{ $t('global.actions.cancel') }}
        </ez-button>
        <ez-button @click="confirmQuickBooksDisconnect" type="red">
          {{ $t('global.actions.disconnect') }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </page>
</template>
<style lang="scss" scoped>
.xero-title {
  @include font-size(24px);
  font-weight: 500;
}
.title {
  img {
    border: 1px solid #e1e5ed;
    border-radius: 50%;
  }
}
.header-actions__action {
  height: 43px;
}
</style>
