<script>
/**
 * NetSuite Outlets
 * @version 1.0.0
 * @since 3.24.0
 */

import { mapGetters, mapActions } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import netsuiteGuard from '@/views/platform/distributor/netsuite/netsuiteGuard';
import { debounce } from '@/util/utils';

import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzTable from '@/components/ui/Table/EzTable';
import flash from '@/components/ui/FlashMessage';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzFilterList from '@/components/ui/FilterList';

import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo/index';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch/index';

import EmptyState from '@/views/common/empty-state/EmptyState';

export default {
  mixins: [netsuiteGuard],
  name: 'Outlets',
  components: {
    EzLoadMore,
    EzSpinner,
    EzLoader,
    EzTable,
    VVenueEntityInfo,
    VSelectSearch,
    EmptyState,
    EzInput,
    EzFilterList,
  },
  data() {
    return {
      customers: [],
      connectedOutlets: [],
      meta: {},
      filters: {
        term: null,
      },
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    ...mapGetters('entities/users', {
      connectionExpired: 'isDistributorNetSuiteConnectionExpired',
    }),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_NETSUITE_GET_AUTHORIZATION_URL,
        LOADING_KEY.DISTRIBUTOR_NETSUITE_AUTHORIZE,
        LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_OUTLETS,
        LOADING_KEY.DISTRIBUTOR_NETSUITE_UPDATE_OUTLETS,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_MORE_OUTLETS);
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('netsuite', [
      'distributorNetsuiteFetchOutlets',
      'distributorNetsuiteUpdateOutlets',
    ]),
    async fetchOutlets(loadingKey = LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_OUTLETS) {
      const query = {
        ...(this.meta.limit ? { limit: this.meta.limit } : {}),
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
        ...this.filters,
      };
      const { data } = await this.distributorNetsuiteFetchOutlets({ query, loadingKey });

      this.connectedOutlets = [...this.connectedOutlets, ...data.data];
      this.meta = data.meta;

      await this.$nextTick();
      this.updateCustomersFromOutlets(data.data);
    },
    async fetchMoreOutlets() {
      await this.fetchOutlets(LOADING_KEY.DISTRIBUTOR_NETSUITE_FETCH_MORE_OUTLETS);
    },
    updateCustomersFromOutlets(outlets) {
      this.customers = [...this.customers, ...outlets.map(o => o.externalVenue)].reduce(
        (acc, curr) => [...acc, ...(curr && !acc.some(obj => obj.id === curr.id) ? [curr] : [])], // removes `null` and duplicates
        [],
      );
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      }
      this.connectedOutlets = [];
      this.meta = {};
      await this.fetchOutlets();
    }, 300),
    async resetFilters() {
      Object.keys(this.filters).forEach((key) => { this.filters[key] = null; });
      this.meta = {};
      this.connectedOutlets = [];
      await this.fetchOutlets();
    },
    customerSelected(customer, outlet) {
      const found = {
        ...this.connectedOutlets.find(item => item.id === outlet.id),
        externalVenue: customer.selected === null ? null : customer,
      };
      this.saveChanges(found);
    },
    async saveChanges(item) {
      const data = [
        {
          externalId: item.externalVenue?.id || null,
          venueId: item.id,
        },
      ];
      try {
        await this.distributorNetsuiteUpdateOutlets({ data });

        // Updates connected outlets list with the updated customer
        const updatedOutletIdx = this.connectedOutlets.findIndex(v => v.id === item.id);
        this.connectedOutlets.splice(updatedOutletIdx, 1, item);

        // Adds selected customer if previously not in the customers list
        const customerExists = this.customers.find(c => c?.id === item.externalVenue?.id);
        if (!customerExists) this.updateCustomersFromOutlets([item]);

        flash.success({ title: this.$t('netsuite.venues.flashMessages.success.title') });
      } catch (e) {
        flash.error({ title: this.$t('netsuite.venues.flashMessages.error.title') });
      }
    },
  },
  async created() {
    if (this.connectionExpired) return;

    await this.fetchOutlets();
  },
};
</script>
<template>
  <div>
    <div class="header mb-32">
      <span class="header__info-text">
        {{ $t('netsuite.venues.subhead', { platform: platformName }) }}
      </span>
    </div>
    <ez-filter-list
      :filters="filters"
      @filterUpdated="updateFilters"
      @resetFilter="resetFilters"
      class="mb-24"
    >
      <ez-input
        formKey="filters"
        name="search"
        class="search"
        :placeholder="`Search for a ${$t('global.venue')}`"
      >
        <template #prefix>
          <font-awesome-icon icon="search" />
        </template>
      </ez-input>
    </ez-filter-list>
    <div>
      <ez-table
        v-if="connectedOutlets.length"
        class="sync-table"
        :disable-hover="true"
        :data="connectedOutlets"
        :columns="['outlet', 'externalVenue']"
        :headers="{
          outlet: () => $t('global.venue'),
          externalVenue: () => `${$t('netsuite.name')} Customer`,
        }"
        :columnProps="{
          account: { class: 'date-cell' },
          outlet: { class: 'outlet-cell' },
          externalVenue: { class: 'xxl-cell' },
        }"
      >
        <template #cell-outlet="{ row }">
          <div class="outlet-name">
            <v-venue-entity-info class="cursor-pointer" :venue="row" />
          </div>
        </template>
        <template #cell-externalVenue="{ row }">
          <v-select-search
            search="/distributor/netsuite/customers"
            class="select-customer"
            :placeholder="`Select ${$t('netsuite.name')} Customer`"
            :data="customers"
            :min-length="3"
            :debounce-time="700"
            :selected="row.externalVenue"
            @selected="obj => customerSelected(obj, row)"
          />
        </template>
      </ez-table>

      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner />
      </div>

      <ez-load-more
        v-if="!isLoading && !isLoadingMore && meta.nextId"
        @loadMore="fetchMoreOutlets"
      />

      <template v-if="!connectedOutlets.length">
        <empty-state>
          <template #badge>
            <img src="@/assets/no-venue-empty-state.svg" width="160" alt="" />
          </template>
          <template #title>{{ $t('netsuite.venues.emptyState.title') }}</template>
        </empty-state>
      </template>

      <ez-loader :show="isLoading">Loading...</ez-loader>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.outlet-name {
  display: flex;
  align-items: center;
}

.outlet-cell {
  width: 500px;
}

.header {
  width: 400px;

  &__info-text {
    @include font-size(14px, 20px);
    font-weight: 500;
    color: $color-gray-6C;
  }
}

.sync-table tbody tr td {
  overflow: visible;
}

.select-customer :deep() {
  .select-search__trigger > svg {
    margin-left: 16px;
  }
}
</style>
