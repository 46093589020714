<script>
import { mapGetters, mapActions } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { LOADING_KEY } from '@/util/constants';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal.vue';
import EzButton from '@/components/ui/Button';
import EzSelect from '@/components/ui/Select';

/**
 * DeleteWarehouseModal
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'DeleteWarehouseModal',
  props: {
    warehouses: {
      type: Array,
      required: true,
    },
  },
  components: {
    EzConfirmationModal,
    EzButton,
    EzSelect,
  },
  data() {
    return {
      supplierCy,
      warehouse: {},
      nextPrimaryWarehouseId: null,
    };
  },
  computed: {
    ...mapGetters('entities/users', ['getDistributor']),
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_DELETE_WAREHOUSE);
    },
    text() {
      return this.warehouse.isEmpty
        ? 'Are you sure you want to delete this warehouse?'
        : 'You still have products in this warehouse. Deleting this warehouse will delete the existing inventory from it. Do you want to transfer these products first before deleting the warehouse?';
    },
    dropdownShown() {
      return this.warehouse.primary && this.warehouses.length > 2;
    },
    deleteDisabled() {
      return this.dropdownShown && !this.nextPrimaryWarehouseId;
    },
    options() {
      const placeholder = { id: null, name: 'Select Warehouse' };
      return [placeholder, ...this.warehouses.filter(w => w.id !== this.warehouse.id)];
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorDeleteWarehouse']),
    open({ id, isEmpty, primary }) {
      this.warehouse = { id, isEmpty, primary };
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
      this.nextPrimaryWarehouseId = null;
    },
    warehouseChange(val) {
      this.nextPrimaryWarehouseId = val.id;
    },
    async confirmDelete() {
      try {
        await this.distributorDeleteWarehouse({
          id: this.warehouse.id,
          primaryWarehouseId: this.nextPrimaryWarehouseId,
        });
        this.$emit('success');
        this.close();
      } catch (err) {
        this.$emit('error');
      }
    },
    startTransferFlow() {
      this.close();
      this.$router.push({
        name: 'distributor-transfer-inventory',
        params: {
          id: this.getDistributor.id,
          fromWarehouseId: this.warehouse.id,
          returnPage: this.$route.name,
        },
      });
    },
  },
};
</script>

<template>
  <ez-confirmation-modal ref="modal" type="red" icon="question">
    <template #title>Delete Warehouse?</template>

    <template #content>
      <p class="m-0">{{ text }}</p>
      <template v-if="dropdownShown">
        <p>
          Since this warehouse is currently set as primary, you need to select the new primary
          warehouse.
        </p>
        <ez-select
          ref="select"
          class="mt-24"
          name="nextPrimaryWarehouseId"
          label="Primary Warehouse"
          :options="options"
          :value="nextPrimaryWarehouseId"
          :selected="nextPrimaryWarehouseId"
          @change="warehouseChange"
          isFullWidth
          :data-cy="supplierCy.SETTINGS.WAREHOUSES.DELETE_WAREHOUSE.SELECT__NEXT"
        />
      </template>
    </template>

    <template #footer>
      <ez-button
        type="link"
        @click="warehouse.isEmpty ? close() : startTransferFlow()"
        :data-cy="supplierCy.SETTINGS.WAREHOUSES.DELETE_WAREHOUSE.BUTTON__CANCEL"
      >
        {{ warehouse.isEmpty ? 'Cancel' : 'Transfer Products' }}
      </ez-button>
      <ez-button
        type="red"
        :disabled="deleteDisabled"
        :isLoading="isLoading"
        @click="confirmDelete"
        :data-cy="supplierCy.SETTINGS.WAREHOUSES.DELETE_WAREHOUSE.BUTTON__DELETE"
      >
        Delete Warehouse
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>
