<script>
/**
*
* @version 1.0.0
* @since 3.0.2
*/
export default {
  props: {
    isContentEmpty: {
      required: false,
      type: Boolean,
      default: false,
    },
    emptyContent: {
      required: false,
      type: String,
      default: 'No accounts match selected filters.',
    },
  },
  data() {
    return {
      scrolled: false,
    };
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
  },
  methods: {
    handleScroll(event) {
      this.scrolled = event.target.scrollTop > 0;
    },
  },
};
</script>
<template>
  <div class="track">
    <div :class="['track__header', { 'track__header--scroll': scrolled }]">
      <slot name="header"></slot>
    </div>
    <div :class="['track__content', {
      'track__content--empty': isContentEmpty,
      'track__content--has-footer': hasFooterSlot,
      }]">
      <div @scroll="handleScroll">
        <slot name="content"></slot>
        <slot name="contentEmpty">
          <span v-if="isContentEmpty">
            {{ emptyContent }}
          </span>
        </slot>
      </div>
    </div>
    <div class="track__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.track {
  width: 100%;
  height: 572px;
  background-color: $color-gray-F5;
  border-radius: 3px;
  overflow: hidden;
  flex: 1 1 100%;
  max-width: 288px;
  min-width: 288px;
  &__header {
    height: 44px;
    width: 100%;
    padding: 12px;
    @extend %flex-center;
    color: $color-gray-6C;
    @include font-size(14px);
    transition: all ease-in-out 300ms;
    z-index: 10;

    &--scroll {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
      border-radius: 3px 3px 0 0;
    }
  }
  &__content {
    padding: 4px 12px 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: calc(100% - 44px);
    > div {
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  // IE and Edge
      scrollbar-width: none;  // Firefox
    }
    &--has-footer {
      height: calc(100% - 88px);
    }
  }
  + .track {
    margin-left: 16px;
  }

  &__footer {
    height: 44px;
    width: 100%;
    background-color: $color-gray-F5;
    @extend %flex-center;
    justify-content: center;
  }
}

.track__content--empty {
  @extend %flex-center;
  justify-content: center;

  @include font-size(14px, 22px);
  color: $color-gray-6C;
}
</style>
