export const ADDITION_STEP_1 = 0;
export const ADDITION_STEP_2 = 1;
export const ADDITION_STEP_3 = 2;
export const ADDITION_STEP_4 = 3;

const steps = {
  [ADDITION_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/addition/Warehouse.vue'),
    title: 'Warehouse',
    pageTitle: 'Please choose warehouse',
    pageInfo: 'Please, select the warehouse to which you would like to add your products.',
    nextCta: 'Next Step',
  },
  [ADDITION_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/addition/Products.vue'),
    title: 'Products',
    pageTitle: 'Now choose products to add',
    pageInfo: 'Please, select the products you would wish to add to the selected warehouse.',
    nextCta: 'Next Step',
  },
  [ADDITION_STEP_3]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/addition/Locations.vue'),
    title: 'Locations',
    pageTitle: 'Add products to locations',
    pageInfo: 'In this step, you choose the locations where you want to add your selected products.',
    nextCta: 'Go To Overview',
  },
  [ADDITION_STEP_4]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/common/products/inventory-flows/addition/Overview.vue'),
    title: 'Overview',
    pageTitle: 'Overview',
    pageInfo: 'Before adding these products to the selected warehouse, check the information below to confirm you have entered everything correctly.',
    nextCta: 'Finish',
  },
};

export default steps;
