<script>
/**
 * QuickBooks suppliers
 * @version 1.0.0
 * @since
 */

import quickBooksGuard from '@/views/platform/distributor/quick-books/settings/quickBooksGuard';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch/index';
import EzTable from '@/components/ui/Table/EzTable';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo/index';
import {
  distributorGetVenues,
  distributorGetContacts,
  distributorGetQuickBooksClasses,
  distributorQuickBooksAddVenue,
  distributorQuickBooksConnections,
  distributorGetQuickBooksTaxesSetup,
} from '@/api/endpoints/quick-books';
import flash from '@/components/ui/FlashMessage';
import EmptyState from '@/views/common/empty-state/EmptyState';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import EzLoader from '@/components/ui/Loader/EzLoader';
import FooterForUpdate from '@/views/platform/distributor/xero/settings/FooterForUpdate';
import UpdateModal from '@/views/platform/distributor/quick-books/settings/UpdateModal';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import { mapGetters } from 'vuex';
import { LOADING_KEY, SELECT_DESELECT_ALL } from '@/util/constants';
import { debounce } from '@/util/utils';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';

export default {
  mixins: [quickBooksGuard],
  components: {
    VSelectSearch,
    EzTable,
    EzLoadMore,
    EzSpinner,
    VVenueEntityInfo,
    EmptyState,
    VDataWithInfo,
    EzLoader,
    FooterForUpdate,
    UpdateModal,
    EzCheckbox,
    EzFilterList,
    EzInput,
    EzDropdown,
  },
  data() {
    return {
      isLoaderShown: false,
      isLoadingMore: false,
      buttonDisabled: true,
      contacts: [],
      distributors: [],
      meta: {},
      columns: [],
      columnsHeaders: {},
      trackingCategories: [],
      accountsData: {},
      taxes: [],
      selectedDistributors: [],
      updateData: {},
      selected: false,
      listOfDistributorIds: [],
      filters: {
        term: null,
        operative: true,
      },
      operativeFilterOptions: [
        {
          id: 1,
          name: 'Operative',
        },
        {
          id: 2,
          name: 'All',
        },
      ],
      operativeId: 1,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoadingQB() {
      return this.isSomeLoading([LOADING_KEY.VENUE_UPDATE_QUICK_BOOKS_SUPPLIERS]);
    },
    columnsNested() {
      return ['distributorName', ...this.columns, 'account', 'taxRate', 'quickBooksContact'];
    },
    tableHeaders() {
      return {
        distributorName: this.supplierNameCheckbox,
        ...this.columnsHeaders,
        account: () => 'Class',
        quickBooksContact: () => 'QuickBooks Contact',
      };
    },
    entity() {
      return this.$t('global.venues');
    },
    accounts() {
      return this.accountsData?.accounts?.length;
    },
    checkedDistributors() {
      return this.selectedDistributors.filter(sv => sv.checked);
    },
    uncheckedDistributors() {
      return this.selectedDistributors.filter(sv => !sv.checked);
    },
    checkedDistributorsCount() {
      return this.selected
        ? this.meta.totalCount - this.listOfDistributorIds.length
        : this.listOfDistributorIds.length;
    },
    indeterminate() {
      return (
        this.checkedDistributorsCount > 0 && this.checkedDistributorsCount !== this.meta.totalCount
      );
    },
    areAllSelected() {
      return this.checkedDistributorsCount === this.meta.totalCount;
    },
    distributorsToUpdate() {
      const distributors = this.selected ? this.uncheckedDistributors : this.checkedDistributors;
      return distributors.map(vn => ({
        venueId: vn.venueId,
        externalId: vn.integrationEntity?.id || null,
      }));
    },
    isLoading() {
      return this.isLoaderShown || this.isLoadingQB;
    },
  },
  methods: {
    openUpdateModal() {
      this.$refs.updateModal.open();
    },
    contactSelected(contact, venueId) {
      this.saveChanges({
        id: venueId,
        contactId: contact.id ? contact.id : null,
        contactName: contact.name ? contact.name : null,
      });
    },
    async saveChanges(item) {
      try {
        this.isLoaderShown = true;
        await distributorQuickBooksAddVenue({
          items: [
            {
              externalId: item.contactId,
              venueId: item.id,
            },
          ],
        });
        const index = this.distributors.findIndex(distributor => distributor.id === item.id);
        if (item.contactId) {
          this.distributors[index].integrationEntity = {
            id: item.contactId,
            name: item.contactName,
          };
        } else {
          this.distributors[index].integrationEntity = null;
        }
        this.isLoaderShown = false;
        flash.success({
          title: this.$t('quickBooks.settings.venues.flashMessages.success.title'),
        });
      } catch (e) {
        flash.error({
          title: this.$t('quickBooks.settings.venues.flashMessages.error.title'),
        });
      }
    },
    isChecked(row) {
      return this.selectedDistributors?.find(sp => sp.venueId === row.id)?.checked;
    },
    getTrackingValue(item, col) {
      return item?.tracking?.[col]?.name || '-';
    },
    supplierNameCheckbox(h) {
      return h('span', { class: 'u-flex-center select-all-checkbox' }, [
        h(
          'ez-checkbox',
          {
            class: 'cursor-pointer',
            props: {
              indeterminate: this.indeterminate,
              label: `${this.$t('global.venue')}`,
              checked: this.areAllSelected,
            },
            on: {
              change: ev => {
                this.selectDeselectAll(ev);
              },
            },
          },
          [],
        ),
      ]);
    },
    selectDeselectAll(ev) {
      this.selected = ev;
      this.listOfDistributorIds = [];
      this.selectedDistributors = this.selectedDistributors.map(cv => ({ ...cv, checked: ev }));
    },
    onCheckboxChange(pr, checked) {
      if (this.selected !== checked) {
        this.listOfDistributorIds.push({
          venueId: pr.id,
          ...(pr.integrationEntity?.id
            ? { externalId: pr.integrationEntity.id }
            : { externalId: null }),
        });
      } else {
        this.listOfDistributorIds = this.listOfDistributorIds.filter(
          item => item.venueId !== pr.id,
        );
      }
      this.selectedDistributors.find(pro => pro.venueId === pr.id).checked = checked;
    },
    async getVenuesListAndContacts() {
      this.isLoaderShown = true;
      const { data: contacts } = await distributorGetContacts();
      this.contacts = contacts.data;

      const { data: distributors } = await distributorGetVenues({
        ...this.filters,
      });
      this.meta = distributors.meta;
      this.distributors = distributors.data;
      this.columns = distributors.meta.columns.map(column => column.key);
      distributors.meta.columns.forEach(item => {
        this.columnsHeaders[item.key] = () => item.name;
      });
      this.distributors.forEach(item => {
        if (this.selectedDistributors.findIndex(x => x.venueId === item.id) === -1) {
          this.selectedDistributors.push({
            ...item,
            venueId: item.id,
            checked: this.selected,
          });
        }
      });
      this.isLoaderShown = false;
    },
    async fetchMoreSuppliers() {
      this.isLoadingMore = true;
      const { data: distributors } = await distributorGetVenues({
        ...this.meta,
        ...this.filters,
      });
      this.distributors = [...this.distributors, ...distributors.data];
      this.distributors.forEach(item => {
        if (this.selectedDistributors.findIndex(x => x.distributorId === item.id) === -1) {
          this.selectedDistributors.push({
            ...item,
            venueId: item.id,
            checked: this.selected,
          });
        }
      });
      this.meta = distributors.meta;
      this.isLoadingMore = false;
    },
    async getAccounts() {
      const {
        data: { data: accounts },
      } = await distributorGetQuickBooksClasses();
      this.accountsData = accounts;
      this.accountsData = {
        accounts,
      };

      const {
        data: { data: taxes },
      } = await distributorGetQuickBooksTaxesSetup({ withDefault: true });
      this.taxes = taxes;
    },
    async updateDistributors(ev) {
      this.config = ev;
      this.updateData = {
        ...this.updateData,
        config: this.config,
        state: this.selected ? SELECT_DESELECT_ALL.selectedAll : SELECT_DESELECT_ALL.deselectedAll,
      };
      await distributorQuickBooksConnections(
        this.updateData,
        this.filters.term,
        this.filters.operative,
      );
      await this.getVenuesListAndContacts();
      this.$refs.updateModal.close();
    },
    async resetFilters() {
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });
      this.selectDeselectAll(false);
      this.selectedDistributors = [];
      this.operativeId = null;
      await this.getVenuesListAndContacts();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      }
      this.selectDeselectAll(false);
      this.selectedDistributors = [];
      await this.getVenuesListAndContacts();
    }, 300),
    setOperativeFilter(item) {
      this.operativeId = item.id;
      if (item.id === 1) this.filters.operative = true;
      else this.filters.operative = false;
    },
  },
  created() {
    this.getVenuesListAndContacts();
    this.getAccounts();
  },
  watch: {
    distributorsToUpdate() {
      this.updateData = {
        ...this.updateData,
        items: this.distributorsToUpdate,
      };
    },
    checkedDistributors(val) {
      window.Intercom('update', {
        hide_default_launcher: !!val.length,
      });
    },
  },
};
</script>
<template>
  <div>
    <div class="header mb-24">
      <span class="header__info-text">
        To edit tracking categories and accounts, select the
        {{ $t('global.venues') | lowercase }} you want to update and click on the “Update
        {{ $t('global.venues') }}” button at the bottom.
      </span>
    </div>
    <ez-filter-list
      :filters="filters"
      @filterUpdated="updateFilters"
      @resetFilter="resetFilters"
      class="mb-24"
    >
      <ez-input
        formKey="filters"
        name="search"
        class="search"
        :placeholder="`Search for a ${$t('global.venue')}`"
      >
        <template #prefix>
          <font-awesome-icon icon="search" />
        </template>
      </ez-input>
      <ez-dropdown
        ref="select"
        class="operative-filter ml-12"
        label="Operative"
        placeholder="Filter Customers"
        :data="operativeFilterOptions"
        name="operative"
        is-full-width
        @change="setOperativeFilter"
        :selected="operativeId"
      />
    </ez-filter-list>
    <div>
      <ez-table
        v-if="distributors.length"
        class="sync-table"
        :disable-hover="true"
        :data="distributors"
        :columns="columnsNested"
        :headers="tableHeaders"
        :columnProps="{
          supplierName: { class: 'supplier-cell' },
          quickBooksContact: { class: 'xxl-cell' },
        }"
      >
        <template #cell-distributorName="{ row }">
          <div class="supplier-name">
            <ez-checkbox
              class="cursor-pointer mr-8"
              :key="row.id"
              @change="onCheckboxChange(row, $event)"
              :checked="isChecked(row)"
            />
            <v-venue-entity-info
              class="cursor-pointer"
              :venue="row"
              @click.native="onCheckboxChange(row, !isChecked(row))"
            />
          </div>
        </template>
        <template v-for="cl in columns" #[`cell-${cl}`]="{ row }">
          {{ getTrackingValue(row.integrationConfig, cl) }}
        </template>
        <template #cell-account="{ row: { integrationConfig } }">
          <v-data-with-info
            v-if="integrationConfig && integrationConfig.account"
            :show-underline="integrationConfig.account.default"
            :info="
              integrationConfig.account.default
                ? $t('quickBooks.settings.table.account.defaultInfo')
                : ''
            "
          >
            {{ integrationConfig.account.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>
        <template #cell-taxRate="{ row: { integrationConfig } }">
          <v-data-with-info
            v-if="integrationConfig && integrationConfig.tax"
            :show-underline="integrationConfig.tax.default"
            :info="
              integrationConfig.tax.default ? $t('xero.settings.table.account.defaultInfo') : ''
            "
          >
            {{ integrationConfig.tax.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>
        <template #cell-quickBooksContact="{ row }">
          <v-select-search
            :data="contacts"
            :search="`/distributor/quick-books/external-venues`"
            value-property="id"
            placeholder="Select QuickBooks Contact"
            :selected="row.integrationEntity?.id ?? null"
            @selected="obj => contactSelected(obj, row.id)"
          />
        </template>
      </ez-table>
      <template v-if="!distributors.length">
        <empty-state>
          <template #badge>
            <img src="@/assets/no-venue-empty-state.svg" width="160" alt="" />
          </template>
          <template #title>{{ $t('quickBooks.settings.venues.emptyState.title') }}</template>
        </empty-state>
      </template>
      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner />
      </div>
      <ez-load-more
        v-if="meta.nextId && meta.nextValue && !isLoadingMore"
        @loadMore="fetchMoreSuppliers"
      />
      <update-modal
        v-if="accounts"
        ref="updateModal"
        :entity="entity"
        :categories="trackingCategories"
        :acc-data="accountsData"
        :taxes="taxes"
        @update="updateDistributors"
      />
      <footer-for-update
        v-if="checkedDistributorsCount"
        :items-count="checkedDistributorsCount"
        :entity="entity"
        @footerAction="openUpdateModal"
      />
      <ez-loader :show="isLoading">Loading...</ez-loader>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.sync-table tbody tr td {
  overflow: visible;
}
.supplier-name {
  display: flex;
  align-items: center;
}
.supplier-cell {
  width: 500px;
}
.select-all-checkbox {
  .input-group {
    @include font-size(12px);
    :deep() .check-indicator {
      margin-right: 8px;
    }
  }
}
.header {
  width: 400px;
  &__info-text {
    @include font-size(14px, 20px);
    font-weight: 500;
    color: $color-gray-6C;
  }
}

:deep() .ez-filter-list__items .search,
:deep() .operative-filter.input-group {
  width: 260px;
}

:deep() .select-search__trigger {
  min-width: 220px;
}
</style>
