<template>
  <div class="ez-product-overview">
    <paper class="ez-product-overview__product-info">
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editInfo"
          :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.BUTTON__EDIT_PRODUCT_INFO"
        >
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <ez-entity-info
        :img-url="product.image"
        img-width="4rem"
        img-height="4rem"
        img-border-radius="0.375rem"
        :img-data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.IMG__PRODUCT"
      >
        <div
          class="ez-product-overview__product-name"
          :title="product.name"
          :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.TEXT__NAME"
        >
          {{ product.name }}
        </div>
        <div class="ez-product-overview__product-price">
          <v-price
            flex-row
            :price="product.price"
            :is-market-price="product.marketPrice"
            :show-market-price-long="true"
            :show-market-price-info="false"
            :unit="productUnitLabel"
            :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.TEXT__PRICE"
          />
        </div>
      </ez-entity-info>
      <div v-if="product.sku" class="info info--vertical">
        <small>SKU Number</small>
        <span :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.TEXT__SKU">
          {{ product.sku }}
        </span>
      </div>
      <div v-if="product.category" class="info info--vertical">
        <small>Category</small>
        <span :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.TEXT__CATEGORY">
          {{ product.category.name }}
        </span>
      </div>
      <div v-if="product.productGroup" class="info info--vertical">
        <small>Product Group</small>
        <span :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.TEXT__PRODUCT_GROUP">
          {{ product.productGroup.name }}
        </span>
      </div>
      <div v-if="product.description" class="info info--vertical">
        <small>Description</small>
        <span :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.TEXT__DESCRIPTION">
          {{ product.description }}
        </span>
      </div>
      <div v-if="product.tax" class="info info--vertical">
        <small>Tax Rate</small>
        <span>
          {{ product.isTaxDefault ? 'Default' : `${product.tax}%` }}
        </span>
      </div>
      <div v-if="product.tags.length" class="info info--vertical">
        <small class="mb-16">Tags</small>
        <div class="all-tags">
          <v-tag
            v-for="tag in tagsToDisplay"
            :key="tag.id"
            :color="tag.color.colors[0]"
            :backgroundColor="tag.color.colors[1]"
          >
            {{ tag.name }}
          </v-tag>
            <span class="tags-number-indicator" v-if="product.tags.length > numberOfTagsToDisplay && !tagsListExpanded">
              +{{ product.tags.length - numberOfTagsToDisplay }}
            </span>
        </div>
        <ez-button
          v-if="product.tags.length > numberOfTagsToDisplay"
          class="u-flex-h-center mt-16"
          @click="toggleListExpanded"
          isFullWidth
          type="link"
          formType="button"
        >
          <span>{{ expandButtonCopy }}</span>
          <font-awesome-icon class="ml-8" :icon="expandButtonIcon" />
        </ez-button>
      </div>
    </paper>
    <paper v-if="hasTiers">
      <template #title>{{ hasDefaultTiers ? 'TIERS' : 'SPECIAL TIERS' }}</template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editTiers"
          :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.BUTTON__EDIT_TIER_PRICING"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div v-if="hasDefaultTiers" class="ez-product-overview__default-tiers">
        <default-tier-preview
          :tiers="defaultTiers"
          :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.TIER_PREVIEW"
        />
      </div>
      <div v-if="hasSpecialTiers" class="ez-product-overview__special-tiers">
        <h4 v-if="hasDefaultTiers">SPECIAL TIERS</h4>
        <div
          v-for="specialTier in product.specialTierPricings"
          class="ez-product-overview__special-tiers-item"
          :key="specialTier.id"
        >
          <h5 :data-cy="`${supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.SPECIAL_TIER_PREVIEW.TEXT__NAME}-${specialTier.id}`">
            {{ specialTier.name }}
          </h5>
          <special-tier-preview
            :specialTier="specialTier"
            :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.SPECIAL_TIER_PREVIEW"
          />
        </div>
      </div>
    </paper>
    <paper v-if="hasWarehouses">
      <template #title>WAREHOUSES</template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editWarehouses"
          :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.OVERVIEW.BUTTON__EDIT_PAR_LEVEL"
        >
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <template>
        <div class="overview-warehouses">
          <div v-for="warehouse in warehouses" :key="warehouse.id" class="overview-warehouses__warehouse">
            <div class="warehouse-section">
              <p class="warehouse-section__label">Warehouse Name</p>
              <p class="warehouse-section__text">{{ warehouse.name }}</p>
            </div>
            <div class="warehouse-section">
              <p class="warehouse-section__label">Par Level</p>
              <p class="warehouse-section__text">{{ warehouse.parLevel }}</p>
            </div>
          </div>
        </div>
      </template>
    </paper>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button';
import EzEntityInfo from '@/components/ui/EntityInfo';
import Paper from '@/components/layout/WizardOverviewPaper.vue';
import DefaultTierPreview from '@/views/common/products/tiers/Preview.vue';
import SpecialTierPreview from '@/views/common/products/special-tiers/Preview.vue';
import VPrice from '@/components/v3/elements/VPrice';
import VTag from '@/components/v3/elements/VTag';
import { NUMBER_OF_TAGS_TO_DISPLAY, TAGS_DISPLAY_TRESHOLD } from '@/util/constants';
import { PRODUCT_STEP_1, PRODUCT_STEP_2, PRODUCT_STEP_3 } from './steps';

export default {
  mixins: [wizardListenerMixin],
  components: {
    VPrice,
    EzButton,
    EzEntityInfo,
    DefaultTierPreview,
    SpecialTierPreview,
    Paper,
    VTag,
  },
  props: {
    distributorId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      supplierCy,
      tagsListExpanded: false,
    };
  },
  computed: {
    product() {
      return this.draft;
    },
    productUnitLabel() {
      const { orderingUnit, priceUnit } = this.product;
      return priceUnit?.label || orderingUnit?.label || '';
    },
    defaultTiers() {
      return this.product.defaultTierPricing?.tiers || [];
    },
    hasTiers() {
      return this.hasDefaultTiers || this.hasSpecialTiers;
    },
    hasDefaultTiers() {
      return !!this.defaultTiers.length;
    },
    hasSpecialTiers() {
      return !!(this.product.specialTierPricings || []).length;
    },
    hasWarehouses() {
      return !!this.warehouses?.length;
    },
    redirectRoute() {
      return this.isAdmin ? 'admin-distributor-products' : 'distributor-products';
    },
    isAdmin() {
      return localStorage.getItem('role') === 'admin';
    },
    warehouses() {
      return this.draft.warehouses;
    },
    ...mapState('entities/products', ['draft']),
    numberOfTagsToDisplay() {
      if (this.product.tags.length - NUMBER_OF_TAGS_TO_DISPLAY > TAGS_DISPLAY_TRESHOLD) return NUMBER_OF_TAGS_TO_DISPLAY;
      return this.product.tags.length;
    },
    tagsToDisplay() {
      if (!this.tagsListExpanded) {
        return this.product.tags.slice(0, this.numberOfTagsToDisplay);
      }
      return this.product.tags;
    },
    expandButtonCopy() {
      return this.tagsListExpanded ? 'Collapse List' : 'Show more tags';
    },
    expandButtonIcon() {
      return this.tagsListExpanded ? 'angle-up' : 'angle-down';
    },
  },
  methods: {
    editInfo() {
      this.$emit('stepBack', PRODUCT_STEP_1);
    },
    editTiers() {
      this.$emit('stepBack', PRODUCT_STEP_2);
    },
    editWarehouses() {
      this.$emit('stepBack', PRODUCT_STEP_3);
    },
    onNextStep() {
      this.CLEAR_DRAFT();

      this.$router.push({
        name: this.redirectRoute,
        params: {
          id: this.distributorId,
          flash: {
            title: 'Product created!',
            type: 'success',
          },
        },
      });
    },
    ...mapMutations('entities/products', [
      'CLEAR_DRAFT',
    ]),
    toggleListExpanded() {
      this.tagsListExpanded = !this.tagsListExpanded;
    },
  },
  created() {
    this.enableNextStep();
  },
};
</script>

<style scoped lang="scss">
  .all-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
  }
  .tags-number-indicator {
    @include font-size(14px, 16px);
    color: $color-gray-6C;
  }
  :deep() .info .all-tags .v-tag span {
      @include font-size(11px, 14px);
    }
  .ez-product-overview {
    &__product-info {
      :deep() .entity-info {
        @include separator('bottom');
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__text {
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: .75rem;
        }
      }
    }

    .overflow__product-title-text {
      @include font-size(16px, 22px);
      font-weight: 700;
    }

    &__product-name {
      @extend .overflow__product-title-text;

      margin-top: 0.375rem;
      width: 100%;
      overflow:hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }

    &__product-price {
      @extend .overflow__product-title-text;
      color: $color-primary-blue;

      :deep() .v-price__price {
        @extend .overflow__product-title-text;
      }
      :deep() .v-price__market-price--row {
        @extend .overflow__product-title-text;
      }
      :deep() .v-price__unit--row {
        @include font-size(16px, 22px);
      }
    }

    &__default-tiers {
      + .ez-product-overview__special-tiers {
        @include separator('top', .75rem, 1.5rem);
      }
    }

    &__special-tiers {
      h4 {
        @include font-size(12px, 14px);
        margin: 0 0 1rem 0;
        padding: 0;
        color: $color-gray-6C;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    &__special-tiers-item {
      h5 {
        @include font-size(14px, 16px);
        font-weight: 400;
        margin: 0 0 1rem 0;
      }

      + .ez-product-overview__special-tiers-item {
        @include separator('top', .5rem, 1rem);
      }

      :deep() .special-tier-preview__venues {
        margin-bottom: .75rem;
      }
    }
  }

  .overview-warehouses {
    &__warehouse {
      &:not(:last-child) {
        margin-bottom: 16px;
        border-bottom: 1px dashed $color-gray-E1;
        padding-bottom: 16px;
      }

      .warehouse-section {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &__label {
          @include font-size(12px, 18px);
          margin: 0 0 4px;
          color: $color-gray-6C;
          font-weight: 600;
        }

        &__text {
          @include font-size(14px, 20px);
          margin: 0;
        }
      }
    }
  }
</style>
