<script>
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { CREDIT_NOTE_DATE_FILTER_DEFAULT } from '@/util/constants';

import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzInput from '@/components/ui/Input';

import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';

/**
 * FilterDropdown
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'FilterDropdown',
  props: {
    filters: {
      type: Object,
      required: true,
    },
    statusFilter: {
      type: Array,
      required: true,
    },
    dateFilter: {
      type: Object,
      required: true,
    },
    dateFilterName: {
      type: String,
      required: true,
    },
    venues: {
      type: Array,
      required: true,
    },
  },
  components: {
    EzCheckbox,
    EzInput,

    VVenueEntityInfo,
    VFilterDropdown,
    VSelectSearch,
    VDatePicker,
  },
  data() {
    return {
      date: this.dateFilter,
      supplierCy,
    };
  },
  computed: {
    today() {
      return new Date();
    },
    defaultDateFilters() {
      return {
        date: {
          from: dayjs(CREDIT_NOTE_DATE_FILTER_DEFAULT.from).valueOf(),
          to: dayjs(CREDIT_NOTE_DATE_FILTER_DEFAULT.to).valueOf(),
        },
      };
    },
  },
  methods: {
    onFiltersUpdate(filters) {
      this.$emit('filterUpdated', filters);
    },
    onFiltersReset() {
      this.$emit('resetFilters');
    },
    onRangeNameChange(rangeName) {
      this.$emit('rangeNameChange', rangeName);
    },
    closeFilterDropdown() {
      this.$refs.filtersGroup.closeFilterDropdown();
    },
    syncLocalFilters() {
      this.$refs.filtersGroup.syncLocalFilters();
    },
  },
  watch: {
    dateFilter(val) {
      this.date = val;
    },
  },
};
</script>

<template>
  <div class="credit-notes__filters">
    <v-filter-dropdown
      :filters="filters"
      :defaultDateFilters="defaultDateFilters"
      @filterUpdated="onFiltersUpdate"
      @resetFilters="onFiltersReset"
      ref="filtersGroup"
      :data-cy="supplierCy.ORDERS.CREDIT_NOTES.BUTTON__FILTERS"
    >
      <template #firstRow>
        <ez-input
          name="term"
          label="CN/Order Number"
          placeholder="Enter CN or Order No."
          :value="filters.term"
          formKey="filters"
          class="filter__search"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.INPUT__SEARCH_FILTER"
        >
          <template #suffix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>

        <v-select-search
          name="venue"
          :data="venues"
          :selected="filters.venueId ? filters.venueId : ''"
          :label="$t('global.venue')"
          :placeholder="`All ${$t('global.venues')}`"
          :searchPlaceholder="`Select ${$t('global.venue')}`"
          alignLeft
          isFullWidth
          class="filter__customer"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.BUTTON__CUSTOMER_FILTER"
        >
          <template #result="{ result }">
            <v-venue-entity-info :venue="result" />
          </template>
        </v-select-search>

        <v-date-picker
          name="date"
          label="Delivery Date"
          v-model="date"
          :numberOfCalendars="2"
          :selectPredefinedRange="dateFilterName"
          @rangeNameChange="onRangeNameChange"
          rangeMode
          withPredefinedRanges
          isAllTimeInFuture
          isFullWidth
          class="filter__date"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.BUTTON__DATE_RANGE_FILTER"
        />
      </template>

      <template #secondRowLabel>Status</template>
      <template #secondRow>
        <ez-checkbox
          v-for="status in statusFilter"
          :key="status.id"
          :name="status.id"
          :checked="filters[status.id]"
          :label="status.name"
          formKey="filters"
          class="filter__status"
          :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.LABEL__STATUS_FILTER}-${status.name}`"
        />
      </template>
    </v-filter-dropdown>
  </div>
</template>

<style lang="scss" scoped>
.credit-notes__filters {
  .filter {
    &__search {
      min-width: 168px;
    }

    &__customer {
      :deep() .select-search__trigger {
        width: 100%;
      }
    }

    &__date {
      grid-column: 1 / -1;

      :deep() .date-picker.ez-simple-dropdown {
        width: 100%;
      }

      :deep() .date-picker {
        .ez-simple-dropdown__display-container {
          width: 100%;
          .placeholder {
            width: 100%;
            color: $color-gray-6C;
          }
        }
      }
    }

    &__status {
      @include font-size(14px, 20px);
    }
  }
}
</style>
