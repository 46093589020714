<template>
  <div
    :class="{
      'ez-special-tier': true,
      'ez-special-tier--has-error': specialTierHasError()
    }" @click="onTierClick">
    <section class="ez-special-tier__tier-title">
      {{ tier.name }}
      <ez-button
        type="link"
        formType="button"
        @click.stop="onRemoveClick">
        <font-awesome-icon icon="trash-alt"/>
      </ez-button>
    </section>
    <section class="ez-special-tier__tier-content">
      <input type="hidden"
             :name="`specialTierPricings[${index}][name]`"
             :value="tier.name">
      <input type="hidden"
             :name="`specialTierPricings[${index}][discountType]`"
             :value="tier.discountType">
      <div class="ez-special-tier__section-label">{{ $t('global.venues') }}</div>
      <div class="ez-special-tier__venues">
        <div v-for="(venue, j) in tier.connectedVenues" :key="venue.id">
          <input type="hidden"
                 :name="`specialTierPricings[${index}][venueIds][${j}]`"
                 :value="venue.id">

          <div class="ez-special-tier__single-venue">
            <ez-entity-info
              :imgUrl="venue.logo"
              imgBorderRadius="50%">
            </ez-entity-info>
            <div class="ez-special-tier__single-venue-tooltip">
              {{ venue.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="ez-special-tier__section-label">Tiers</div>
      <div class="ez-special-tier__tier-list">
        <div v-for="(t, ind) in tier.tiers"
             :key="t.id"
             class="ez-special-tier__single-tier">
          <input type="hidden"
                 :name="`specialTierPricings[${index}][tiers][${ind}][value]`"
                 :value="t.value">
          <input type="hidden"
                 :name="`specialTierPricings[${index}][tiers][${ind}][minQuantity]`"
                 :value="t.minQuantity">

          <b>
            Tier #{{ ind + 1 }}
            <span v-if="tier.discountType === 'percentage'">
              (-{{ t.value|percentage }})
            </span>
          </b>
          <small>
            {{ !!tier.tiers[ind + 1]
            ? `From ${t.minQuantity} to ${tier.tiers[ind + 1].minQuantity}`
            : `From ${t.minQuantity || 0}` }}
          </small>
          <hr>
          <div class="ez-special-tier--price">
            <div
              class="ez-special-tier--price-old">{{ defaultPrice|price }}</div>
            <template v-if="tier.discountType === 'price'">
              <div>{{ t.value|price }}</div>
            </template>
            <template v-else>
              <div>{{ ((1 - t.value / 100) * defaultPrice)|price }}</div>
            </template>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EzButton from '@/components/ui/Button';
import EzEntityInfo from '@/components/ui/EntityInfo';

export default {
  components: {
    EzButton,
    EzEntityInfo,
  },
  props: {
    tier: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    defaultPrice: {
      type: Number,
      required: true,
    },
    formKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    specialTierErrors() {
      return this.getFormErrors(this.formKey);
    },
    ...mapGetters('errors', [
      'getFormErrors',
    ]),
  },
  methods: {
    onRemoveClick() {
      this.$emit('removeTier', this.tier, this.index);
    },
    onTierClick() {
      this.$emit('tierClick', this.tier, this.index);
    },
    specialTierHasError() {
      const { specialTierErrors } = this;

      return !!Object.keys(specialTierErrors)
        .some(key => key.indexOf(`specialTierPricings[${this.index}]`) !== -1);
    },
  },
};
</script>

<style scoped lang="scss">
$item-background: #FFFFFF;
$title-separator: #EAEFF4;
$icon-color: #8790A3;
$error-color: #EA4B5D;

.ez-special-tier {
  background-color: $item-background;
  margin-top: 2em;
  box-shadow: 0 12px 20px -16px rgba(0, 0, 0, 0.24);
  border-radius: .3em;

  &--price {
    @include font-size(16px);
    font-weight: 600;
  }

  &--price-old {
    @include font-size(12px);
    color: $icon-color;
    margin-bottom: .5em ;
    text-decoration: line-through;
  }

  &--has-error {
    border: 1px solid $error-color;
  }

  &:hover {
    cursor: pointer;
  }

  &__tier-title {
    @extend %flex-center;
    justify-content: space-between;
    padding: 1.2em;
    font-weight: bold;
    border-bottom: 1px solid $title-separator;

    svg {
      color: $icon-color;
    }

    .button {
      padding: 0;
    }
  }

  &__tier-content {
    padding: 1.2em;
  }

  &__tier-list {
  }

  &__venues {
    display: flex;
    margin-bottom: 1.2em;
  }

  &__single-venue {
    position: relative;
    margin-right: .5em;
    &:hover {
      .ez-special-tiers__single-venue-tooltip {
        display: block;
      }
    }
    &-tooltip {
      @include font-size(12px);
      position: absolute;
      left: 50%;
      text-align: center;
      transform: translateX(-50%);
      top: calc(100% + .8em);
      display: none;
      color: #FFFFFF;
      background-color: #252631;
      max-width: 8em;
      padding: .2em .5em;
      border-radius: .25em;

      &:before {
        bottom: 100%;
        left: 50%;
        content: " ";
        position: absolute;
        pointer-events: none;
        border: .5em solid transparent;
        border-bottom-color: #252631;
        margin-left: -.5em;
      }
    }
  }

  &__section-label {
    @extend %gray-label;
    margin-bottom: 1.2em;
  }

  &__single-tier {
    @include font-size(12px);
    min-width: 13em;
    padding: 1em;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #D8DDE3;
    border-radius: .3em;

    b {
      @include font-size(14px);
      font-weight: 600;
      margin-bottom: .5em;
    }

    small {
      @include font-size(12px);
      color: $icon-color;
    }

    hr {
      margin: .8em 0;
      width: 2em;
      border: 0;
      border-top: 1px solid #D8DDE3;
    }
    +.ez-special-tier__single-tier {
      margin-left: 1.5em;
    }
  }
}
</style>
