<template>
  <div class="tiers">
    <div class="tiers__item">
      <h4>Tier Pricing</h4>
      <p>Tier pricing gives customers discounts when they order certain amount of the products.</p>
      <tiers
        :productId="draft.id"
        :distributorId="distributorId"
        :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.TIER_PRICING.TIERS"
        @success="onTierSuccess"
      />
    </div>

    <div class="tiers__item">
      <h4>Special Tier Pricing</h4>
      <!-- eslint-disable-next-line -->
      <p>Special tier pricing gives selected customers special discounts when ordering certain
        amounts of products.</p>
      <special-tiers
        :product="draft"
        :distributorId="distributorId"
        :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.TIER_PRICING.SPECIAL_TIERS"
        @success="onTierSuccess"
        @deleteSuccess="onDeleteSuccess"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import Tiers from '@/views/common/products/tiers';
import SpecialTiers from '@/views/common/products/special-tiers';
import { clone } from '@/util/utils';

export default {
  mixins: [wizardListenerMixin],
  components: {
    Tiers,
    SpecialTiers,
  },
  props: {
    distributorId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      supplierCy,
    };
  },
  computed: {
    ...mapState('entities/products', ['draft']),
  },
  methods: {
    ...mapMutations('entities/products', ['UPDATE_DRAFT']),
    onNextStep() {
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    openTierModal() {
      this.$refs.tierModal.open();
    },
    onAddTierClick() {
      this.openTierModal();
    },
    onTierSuccess(data) {
      this.UPDATE_DRAFT(data);
    },
    onDeleteSuccess(id) {
      const productCloned = clone(this.draft);
      const { specialTierPricings } = productCloned;

      const idx = specialTierPricings.findIndex(tr => tr.id === id);
      specialTierPricings.splice(idx, 1);

      this.UPDATE_DRAFT({ specialTierPricings });
    },
  },
  created() {
    this.enableNextStep();
  },
};
</script>

<style scoped lang="scss">
.tiers {
  &__item {
    &:not(:first-child) {
      @include separator('top', 2rem);
    }

    h4 {
      @include font-size(16px, 19px);
      font-weight: 500;
      margin: .5rem 0;
    }

    p {
      @include font-size(14px, 22px);
      margin: 0 0 1rem 0;
      color: $color-gray-6C;
    }
  }
}
</style>
