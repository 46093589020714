<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import dayjs from 'dayjs';

import { wizardListenerMixin } from '@/mixins/wizard';
import { clone, debounce, getDateFormat } from '@/util/utils';
import {
  DEFAULT_FRACTION_DIGITS,
  LOADING_KEY,
  UNIT_TYPE_FRACTIONAL,
  UNIT_TYPE_PACK,
} from '@/util/constants';

import EzFilterList from '@/components/ui/FilterList/EzFilterList.vue';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo.vue';
import EzDateInput from '@/components/ui/DateInput/EzDateInput.vue';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput.vue';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzTable from '@/components/ui/Table/EzTable.vue';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzBadge from '@/components/ui/Badge/EzBadge.vue';

import VTooltipComponent from '@/components/v3/elements/VTooltipComponent';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import EmptyState from '@/views/common/empty-state/EmptyState.vue';
import EzAlert from '@/components/ui/Alert/EzAlert.vue';
import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';

/**
 * StockCredit
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  mixins: [wizardListenerMixin],
  name: 'StockCredit',
  components: {
    EzAlert,
    EzMaskInputSimple,
    EzFilterList,
    EzEntityInfo,
    EzDateInput,
    EzMaskInput,
    EzLoadMore,
    EzSpinner,
    EzButton,
    EzTable,
    EzInput,
    EzBadge,
    // eslint-disable-next-line vue/no-unused-components
    VTooltipComponent,
    VDataWithInfo,
    EmptyState,
  },
  data() {
    return {
      products: [],
      meta: {},
      selectedProducts: [],
      filters: { term: '' },
      getDateFormat,
      isInvalidQuantity: {},
      supplierCy,
      DEFAULT_FRACTION_DIGITS,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    ...mapGetters('creditNotes', {
      venue: 'getDraftVenue',
      nonStockCredit: 'getDraftNonStockCredit',
      draftProducts: 'getDraftProducts',
      orderId: 'getCreditNoteOrderId',
      isTotalCreditAmountExceeded: 'getTotalCreditNoteAmountInvalid',
    }),
    loading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_CREDIT_NOTE_PRODUCTS);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_CREDIT_NOTE_PRODUCTS);
    },
    hasFilters() {
      return Object.values(this.filters).some(v => !!v);
    },
    noProductsFound() {
      return !this.loading && !this.isLoadingMore && !this.products.length;
    },
  },
  methods: {
    ...mapActions('creditNotes', ['distributorFetchCreditNoteProducts']),
    ...mapMutations('creditNotes', ['SET_DRAFT_PRODUCTS', 'CLEAR_DRAFT_ORDER']),
    onGoToStep({ step }) {
      this.$emit('stepBack', step);
    },
    onPreviousStep() {
      this.SET_DRAFT_PRODUCTS(clone(this.selectedProducts));
      this.$emit('stepBack');
    },
    async fetchProducts(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_CREDIT_NOTE_PRODUCTS) {
      const query = {
        ...(this.meta.nextId && { nextId: this.meta.nextId }),
        ...(this.meta.nextValue && { nextValue: this.meta.nextValue }),
        ...(this.filters.term && { term: this.filters.term }),
        ...(this.orderId && { orderId: this.orderId }),
        venueId: this.venue.id,
      };
      const { data } = await this.distributorFetchCreditNoteProducts({ query, loadingKey });

      if (loadingKey === LOADING_KEY.DISTRIBUTOR_FETCH_CREDIT_NOTE_PRODUCTS) {
        this.products = this.remapExpiryDate(data.data);
      }
      if (this.products.length) {
        const productIds = [...new Set(this.products.map(p => p.id))];
        data.data = data.data.filter(p => !productIds.includes(p.id));
        this.products = [...this.products, ...this.remapExpiryDate(data.data)];
      }

      this.meta = clone(data.meta);
    },
    async onLoadMore() {
      await this.fetchProducts(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_CREDIT_NOTE_PRODUCTS);
    },
    remapExpiryDate(products) {
      return clone(products).map(p => ({ ...clone(p), expiryDate: dayjs(p.expiryDate) }));
    },
    clearState() {
      this.products = [];
      this.meta = {};
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') this.filters.term = event;
      this.clearState();
      await this.fetchProducts();
    }, 300),
    async resetFilters() {
      this.filters.term = null;
      this.clearState();
      await this.fetchProducts();
    },
    isFractional(row) {
      return row.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    increment(row) {
      return this.isFractional(row) ? 0.01 : 1;
    },
    maxValue(row) {
      return this.orderId && row.orderQuantity ? row.orderQuantity : Number.MAX_SAFE_INTEGER;
    },
    onInputChange(key, row, val) {
      if (key === 'quantity') this.updateSelectedProductQuantity(row, val);
      else this.updateSelectedProduct(row.id, { [key]: val });
    },
    onInvalidQuantity(row, val) {
      this.isInvalidQuantity = {
        ...this.isInvalidQuantity,
        [row.id]: val,
      };
    },
    updateSelectedProductQuantity(row, val) {
      if (!this.isSelected(row.id)) {
        if (val) this.selectProduct(row, val);
        return;
      }

      if (!val) {
        this.unselectProduct(row.id);
        return;
      }

      this.updateSelectedProduct(row.id, { quantity: +val.toFixed(2) });
    },
    isSelected(id) {
      return !!this.findSelected(id);
    },
    findSelected(id, key = '') {
      const product = this.selectedProducts.find(p => p.id === id);
      if (!key) return product;

      return product ? product[key] : this[key];
    },
    selectProduct(product, quantity = 0) {
      if (this.isSelected(product.id)) return;

      this.selectedProducts.push({ ...product, quantity });
      this.SET_DRAFT_PRODUCTS(clone(this.selectedProducts));
    },
    unselectProduct(id) {
      const idx = this.selectedProducts.findIndex(p => p.id === id);
      this.selectedProducts.splice(idx, 1);
      this.SET_DRAFT_PRODUCTS(clone(this.selectedProducts));
    },
    updateSelectedProduct(id, payload) {
      const idx = this.selectedProducts.findIndex(p => p.id === id);
      this.selectedProducts[idx] = { ...this.selectedProducts[idx], ...payload };
      this.SET_DRAFT_PRODUCTS(clone(this.selectedProducts));
    },
    isUnitType(unit) {
      return unit.type === UNIT_TYPE_PACK;
    },
    quantityHeader(h) {
      return h('span', {}, [
        'Quantity',
        h(
          VTooltipComponent,
          {
            props: {
              content:
                'The quantity is being calculated per<br />single unit. Example: Bottle, can, piece...',
              placement: 'top',
              classes: ['tooltip--reset-margin u-text-center'],
            },
          },
          [
            h('font-awesome-icon', {
              class: 'ml-4',
              props: {
                icon: 'info-circle',
              },
            }),
          ],
        ),
      ]);
    },
    createStandaloneCreditNote() {
      this.CLEAR_DRAFT_ORDER();
    },
  },
  async created() {
    if (this.draftProducts.length) {
      this.selectedProducts = this.remapExpiryDate(this.draftProducts);
      this.products = this.remapExpiryDate(this.draftProducts);
    }

    await this.fetchProducts();
  },
  watch: {
    selectedProducts: {
      immediate: true,
      handler(val) {
        if (val.length || !!this.nonStockCredit) this.enableNextStep();
        else this.disableNextStep();
      },
    },
    isInvalidQuantity: {
      immediate: true,
      handler(val) {
        if (!Object.keys(val).length || !this.selectedProducts.length) return;

        const isSomeQuantityInvalid = Object.values(val).some(e => e);
        if (isSomeQuantityInvalid) this.disableNextStep();
        else this.enableNextStep();
      },
    },
  },
};
</script>

<template>
  <div class="products-step">
    <div class="subheader mb-24 u-flex-space">
      <div></div>

      <p class="subheader__selected-count">
        Products selected:
        <ez-badge
          type="blue"
          :count="selectedProducts.length || 0"
          showEveryNumber
          :data-cy="
            supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.STOCK_CREDIT.TEXT__SELECTED_PRODUCTS
          "
        />
      </p>

      <ez-filter-list
        :filters="filters"
        @resetFilter="resetFilters"
        @filterUpdated="updateFilters"
        class="subheader__filter-list"
      >
        <ez-input
          formKey="filters"
          label="search"
          name="search"
          class="search"
          placeholder="Search for a product"
          :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.STOCK_CREDIT.INPUT__SEARCH"
        >
          <template #suffix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>
    </div>

    <ez-alert v-if="isTotalCreditAmountExceeded" type="white-peach" size="inline" :data-cy="''">
      <template #icon>
        <font-awesome-icon class="select-products__alert-icon" icon="exclamation-circle" />
      </template>
      Credited units exceed those in the sales order, update quantity or
      <ez-button type="link" class="select-products__error-link" @click="createStandaloneCreditNote"
        >create a standalone credit note</ez-button
      >.
    </ez-alert>

    <ez-table
      :loading="loading"
      :data="products"
      :columns="['name', 'batchCode', 'expiryDate', 'unit', 'quantity', 'price', 'action']"
      :headers="{
        name: () => 'Product',
        batchCode: () => 'Batch ID',
        quantity: quantityHeader,
        action: () => '',
      }"
      :columnProps="{
        name: { class: 'name-cell' },
        batchCode: { class: 'batchCode-cell large-cell' },
        expiryDate: { class: 'expiryDate-cell large-cell' },
        quantity: { class: 'quantity-cell medium-cell' },
        unit: { class: 'medium-cell' },
        price: { class: 'u-text-right price-cell' },
        action: { class: 'action-cell medium-cell' },
      }"
      class="products-table"
      disableHover
    >
      <template #cell-name="{ row, row: { name, sku, image } }">
        <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="image">
          <div class="product-info" :title="name">
            <span
              class="product-info__name"
              :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.STOCK_CREDIT.TEXT__PRODUCT_NAME}-${row.id}`"
            >
              {{ name }}
            </span>
            <span class="product-info__sku">{{ sku }}</span>
          </div>
        </ez-entity-info>
      </template>

      <template #cell-batchCode="{ row }">
        <ez-input
          formKey=""
          name="batchCode"
          placeholder="Enter Batch ID"
          :value="row.batchCode"
          :disabled="!row.inventoryTracked || !isSelected(row.id)"
          @onChange="onInputChange('batchCode', row, $event)"
          :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.STOCK_CREDIT.INPUT__BATCH_ID}-${row.id}`"
        />
      </template>

      <template #cell-expiryDate="{ row }">
        <ez-date-input
          formKey=""
          label=""
          name="expiryDate"
          :placeholder="getDateFormat()"
          :dateFormat="getDateFormat()"
          :disabled="!row.inventoryTracked || !isSelected(row.id)"
          :class="['date-input', { 'empty-date': !findSelected(row.id, 'expiryDate') }]"
          v-model="row.expiryDate"
          @onChange="onInputChange('expiryDate', row, $event)"
          isTooltipError
          :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.STOCK_CREDIT.INPUT__EXPIRY_DATE}-${row.id}`"
        />
      </template>

      <template #cell-unit="{ row, row: { orderingUnit } }">
        <v-data-with-info
          v-if="orderingUnit"
          :info="
            isUnitType(orderingUnit)
              ? 'The quantity of pack unit products is being managed per single unit.'
              : ''
          "
          :showUnderline="isUnitType(orderingUnit)"
        >
          <span
            :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.STOCK_CREDIT.TEXT__UNIT}-${row.id}`"
          >
            {{ orderingUnit.label }}
          </span>
        </v-data-with-info>
      </template>

      <template #cell-quantity="{ row }">
        <ez-mask-input-simple
          formKey=""
          name="quantity"
          :value="findSelected(row.id, 'quantity')"
          :min-value="0"
          :max-value="maxValue(row)"
          :precision="isFractional(row) ? 2 : 0"
          :isInvalid="isInvalidQuantity[row.id]"
          @invalid="onInvalidQuantity(row, $event)"
          @input="onInputChange('quantity', row, $event)"
          :has-currency="false"
          @click.stop
          :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.STOCK_CREDIT.INPUT__QUANTITY}-${row.id}`"
        />
      </template>

      <template #cell-price="{ row }">
        <ez-mask-input
          :disabled="!isSelected(row.id)"
          formKey=""
          name="price"
          type="inline"
          :value="findSelected(row.id, 'price') || 0"
          @input="onInputChange('price', row, $event)"
          :currency="row.currency"
          :allowNegativeValue="false"
          :precision="row.currency?.fractionDigits ?? DEFAULT_FRACTION_DIGITS"
          :pricePrefix="row.currency?.showSymbol ?? true"
          :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.STOCK_CREDIT.INPUT__PRICE}-${row.id}`"
        />
      </template>

      <template #cell-action="{ row }">
        <ez-button
          v-if="isSelected(row.id)"
          type="green"
          @click="unselectProduct(row.id)"
          :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.STOCK_CREDIT.BUTTON__TOGGLE_SELECTED}-${row.id}`"
        >
          Selected
        </ez-button>
        <ez-button
          v-else
          type="secondary"
          @click="selectProduct(row, increment(row))"
          :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.STOCK_CREDIT.BUTTON__TOGGLE_SELECTED}-${row.id}`"
        >
          Select
        </ez-button>
      </template>
    </ez-table>

    <empty-state v-if="noProductsFound && hasFilters">
      <template #badge><img src="@/assets/no-products-search-state.svg" alt="" /></template>
      <template #title>No products match this search</template>
      <template #info>Try with a different search.</template>
    </empty-state>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="onLoadMore" />
  </div>
</template>

<style lang="scss" scoped>
.products-step {
  margin-top: 64px;

  .subheader {
    border-bottom: 1px solid #eceef5;
    padding: 14px 0;

    & > * {
      flex: 1 0 33.33333%;
    }

    &__selected-count {
      @include font-size(14px, 20px);
      color: $color-gray-6C;
      text-align: center;
      margin: 0;
    }

    &__filter-list {
      :deep() .ez-filter-list {
        &__items {
          justify-content: flex-end;

          .input-group__input {
            width: 240px;
          }
        }

        &__action {
          display: none;
        }
      }
    }
  }

  .products-table {
    .name-cell {
      .product-info {
        display: flex;
        flex-direction: column;

        &__name {
          color: $color-gray-25;
          font-weight: 500;
        }

        &__sku {
          display: block;
          color: $color-gray-6C;
        }

        span {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .batchCode-cell {
      :deep() input:disabled::placeholder {
        color: $input-disabled-color;
      }
    }

    .expiryDate-cell,
    .quantity-cell {
      overflow: visible;
    }

    .price-cell {
      width: 112px;

      :deep() {
        .mask-input {
          margin-left: auto;

          &__prefix {
            color: $color-gray-6C;
          }
        }

        .v-price__price {
          @include font-size(14px, 20px);
          color: $color-gray-6C;
          font-weight: 400;
        }
      }
    }
  }

  .select-products {
    &__alert-icon {
      color: $color-primary-red;
    }

    &__error-link {
      padding: 0;
      height: auto;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      text-decoration: underline;
    }
  }
}

:deep() .mask-input-wrapper {
  .mask-input__input {
    text-align: center;
    font-weight: normal;
    color: $color-gray-25;
  }
}
</style>
