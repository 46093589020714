<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import EzForm from '@/components/ui/Form/EzForm';
import EzOnOff from '@/components/ui/OnOff';
import EzInput from '@/components/ui/Input/EzInput';
import EzButton from '@/components/ui/Button/EzButton';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
import flash from '@/components/ui/FlashMessage';
import EzRadioButton from '@/components/ui/RadioButton/EzRadioButton';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import EzCheckbox from '@/components/ui/Checkbox';
import EzImageUpload from '@/components/ui/ImageUpload';
import VLabel from '@/components/v3/elements/VLabel';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'PaymentInformation',
  components: {
    EzForm,
    EzOnOff,
    EzInput,
    EzButton,
    EzTextarea,
    EzRadioButton,
    EzDropdown,
    EzCheckbox,
    EzImageUpload,
    VLabel,
  },
  data() {
    return {
      formKey: 'payment-settings',
      loadingKeyForm: 'payment-settings-lk',
      paymentTerms: '',
      paymentTermsData: [],
      documentLayoutData: [
        {
          id: 'large_header',
          name: 'Large Header',
        },
        {
          id: 'small_header',
          name: 'Small Header',
        },
        {
          id: 'large_footer',
          name: 'Large Footer',
        },
        {
          id: 'small_footer',
          name: 'Small Footer',
        },
      ],
      imgModified: false,
      paymentTermsDataCash: null,
      qrCode: null,
      cashVal: '',
      noTerms: 'no_terms',
      net: '',
      additionalData: {},
      showTaxInvoice: false,
      deliveryFeeWithoutTax: false,
      discountDisplaySettings: {
        quote: false,
        invoice: false,
      },
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    distributor() {
      return this.loggedUser?.distributor;
    },
    ifFromNet() {
      return !!this.paymentTermsData?.find(pt => pt.key === this.distributor.paymentTerms);
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributor']),
    ...mapMutations('entities/users', ['SET_LOGGED_USER']),
    ...mapActions('entities/distributors', ['distributorGetPaymentTerms']),
    onSubmitSuccess({ data }) {
      this.SET_LOGGED_USER({ ...this.loggedUser, distributor: data.data });
      flash.success({ title: 'Payment information successfully updated!' });
    },
    updatePaymentTerms(type) {
      if (type === 'cash_on_delivery') {
        this.cashVal = type;
        this.net = '';
        this.paymentTerms = type;
      } else if (type === 'no_terms') {
        this.paymentTerms = type;
        this.net = '';
        this.cashVal = '';
      } else {
        this.cashVal = '';
        this.net = type;
        this.paymentTerms = this.distributor.paymentTerms || this.paymentTermsData[0].key;
      }
      this.additionalData = {
        paymentTerms: this.paymentTerms,
      };
    },
    updateNet(net) {
      this.paymentTerms = net.key;
      this.additionalData = {
        paymentTerms: this.paymentTerms,
      };
    },
    transformer(data) {
      const showTaxInvoice = data.get('showTaxInvoice');
      if (!showTaxInvoice) {
        data.append('showTaxInvoice', 'false');
      }
      const deliveryFeeWithoutTax = data.get('deliveryFeeWithoutTax');
      if (!deliveryFeeWithoutTax) {
        data.append('deliveryFeeWithoutTax', 'false');
      }
      Object.entries(this.discountDisplaySettings).forEach(([key, value]) => {
        data.append(`discountDisplaySettings[${key}]`, value.toString());
      });
      return data;
    },
    onRemoveImage() {
      this.qrCode = null;
      this.imgModified = true;
    },
  },
  async created() {
    await this.initDistributor();
    const {
      data: { data },
    } = await this.distributorGetPaymentTerms();
    this.paymentTermsData = data.filter(terms => terms.type === 'net');
    const [first] = data.filter(terms => terms.type === 'cash_on_delivery');
    this.paymentTermsDataCash = first;
    if (this.ifFromNet) {
      this.updatePaymentTerms('net');
    }
    this.showTaxInvoice = this.distributor.showTaxInvoice ?? false;
    this.qrCode = this.distributor.qrCode;
    this.deliveryFeeWithoutTax = this.distributor.deliveryFeeWithoutTax ?? false;

    const { quote, invoice } = this.distributor.discountDisplaySettings;
    this.discountDisplaySettings = {
      ...this.discountDisplaySettings,
      ...(quote && { quote }),
      ...(invoice && { invoice }),
    };
  },
};
</script>

<template>
  <div class="container--small">
    <div class="help-block mb-16">
      <h4 class="help-block__title custom-title">Business Details</h4>
      <p class="help-block__text">
        Information regarding your business that will be displayed on <br />
        your invoices.
      </p>
    </div>
    <ez-form
      ref="form"
      :form-key="formKey"
      :loading-key="loadingKeyForm"
      :additional-data="{
        ...(imgModified && { qrCode: '' }),
        ...additionalData,
      }"
      submitType="multipart"
      :transformer="transformer"
      action="distributor/me"
      @success="onSubmitSuccess"
      method="patch"
    >
      <ez-input
        :form-key="formKey"
        name="legalName"
        :value="distributor.legalName"
        placeholder="Enter Your Legal Business Name"
        label="Legal Business Name"
      />
      <ez-input
        class="mt-12"
        :form-key="formKey"
        :value="distributor.companyRegistrationNumber"
        name="companyRegistrationNumber"
        placeholder="Enter Your Company Registration Number / Tax ID"
        label="Company Registration Number / Tax ID"
      />
      <ez-input
        class="mt-12"
        :form-key="formKey"
        name="taxNumber"
        :value="distributor.taxNumber"
        placeholder="GST / Tax ID Number"
        label="Enter Your GST / Tax ID Number"
      />
      <ez-textarea
        class="mt-12 bank-details"
        :formKey="formKey"
        :value="distributor.bankDetails"
        name="bankDetails"
        label="Bank Details"
        placeholder="Enter Your Bank Details"
      />
      <v-label as="small" class="mt-8">QR Code</v-label>
      <ez-image-upload
        :formKey="formKey"
        :previewUrl="qrCode"
        class="mt-4"
        :is-default-image="false"
        @change="imgModified = true"
        name="qrCode"
        @removeImage="onRemoveImage"
      >
        <template #addImage>
          <span v-if="distributor.qrCode"> Change QR Code</span>
          <span v-else>Add QR Code</span>
        </template>
        <template #removeImage>
          <font-awesome-icon icon="times" />
          <span>Remove QR Code</span>
        </template>
      </ez-image-upload>
      <hr />
      <div class="help-block mb-16">
        <h4 class="help-block__title custom-title">Payment Terms</h4>
      </div>
      <ez-radio-button
        class="radio-btn"
        :formKey="formKey"
        name="payment"
        label="No Terms"
        :data="distributor.paymentTerms"
        value="no_terms"
        @change="updatePaymentTerms('no_terms')"
      />
      <ez-radio-button
        v-if="paymentTermsDataCash"
        class="radio-btn mt-16"
        :formKey="formKey"
        name="payment"
        :label="paymentTermsDataCash.name"
        :data="distributor.paymentTerms"
        value="cash_on_delivery"
        @change="updatePaymentTerms(paymentTermsDataCash.key)"
      />
      <div class="radio-info">Pay the full amount upon the delivery of the order.</div>

      <ez-radio-button
        v-if="paymentTermsData.length"
        class="radio-btn mt-16"
        :formKey="formKey"
        name="payment"
        label="Net"
        :data="net"
        value="net"
        @change="updatePaymentTerms('net')"
      />
      <div class="radio-info">Pay the full amount within a certain time period.</div>

      <ez-dropdown
        v-if="paymentTermsData.length"
        :disabled="net !== 'net'"
        ref="fromInput"
        name="payment"
        :formKey="formKey"
        label="Payment Period"
        class="mb-8 mt-12"
        :data="paymentTermsData"
        :selected="(ifFromNet && distributor.paymentTerms) || paymentTermsData[0].key"
        is-full-width
        :idKey="'key'"
        placeholder="Payment Period"
        @change="updateNet"
      />
      <div class="dropdown-info">
        Set how much time {{ $t('global.venue') | lowercase }} has to complete the payment for the
        issued invoice.
      </div>

      <hr />

      <div class="help-block mb-16">
        <h4 class="help-block__title custom-title">Tax Rate Applicable</h4>
        <p class="help-block__text">Tax that will be added to the final order amount.</p>
      </div>
      <ez-input
        class="mt-16"
        :formKey="formKey"
        placeholder="Tax Rate"
        label="Tax Rate"
        type="number"
        name="tax"
        step="any"
        :value="distributor.tax"
        min="0"
      >
        <template #suffix>%</template>
      </ez-input>

      <div class="invoice-settings mt-16">
        <div class="help-block">
          <h4 class="help-block__title custom-title">Tax Invoice</h4>
          <p class="help-block__text mb-0">
            When enabled the invoices will indicate “Tax Invoice” otherwise it will indicate
            “Invoice”.
          </p>
        </div>
        <ez-on-off
          :formKey="formKey"
          name="showTaxInvoice"
          :value="showTaxInvoice.toString()"
          :checked="showTaxInvoice"
          @change="showTaxInvoice = $event"
        />
      </div>

      <div class="invoice-settings">
        <div class="help-block">
          <h4 class="help-block__title custom-title">Apply Tax to Delivery Fee</h4>
          <p class="help-block__text mb-0">
            When enabled tax amount for delivery fee will be added to the total order amount.
          </p>
        </div>
        <ez-on-off
          :formKey="formKey"
          name="deliveryFeeWithoutTax"
          :value="deliveryFeeWithoutTax.toString()"
          :checked="deliveryFeeWithoutTax"
          @change="deliveryFeeWithoutTax = $event"
        />
      </div>

      <div class="help-block mb-16">
        <h4 class="help-block__title custom-title">Display Discounts</h4>
        <p class="help-block__text">Choose which documents display discounts.</p>
      </div>
      <ez-checkbox
        :formKey="formKey"
        :value="discountDisplaySettings.quote.toString()"
        :checked="discountDisplaySettings.quote"
        name="quote"
        label="Quote"
        @change="value => (discountDisplaySettings.quote = value)"
      />
      <ez-checkbox
        :formKey="formKey"
        :value="discountDisplaySettings.invoice.toString()"
        :checked="discountDisplaySettings.invoice"
        name="invoice"
        label="Invoice"
        @change="value => (discountDisplaySettings.invoice = value)"
        class="mt-12 mb-24"
      />

      <div class="help-block mb-16">
        <h4 class="help-block__title custom-title">Document Layout</h4>
        <p class="help-block__text">Choose the layout of the generated documents.</p>
      </div>
      <ez-dropdown
        name="documentLayout"
        :formKey="formKey"
        class="mb-8 mt-12"
        :data="documentLayoutData"
        :selected="distributor.documentLayout || documentLayoutData[0].id"
        is-full-width
        placeholder="Document Layout"
      />

      <ez-button class="mt-24" form-type="submit">Save Changes</ez-button>
    </ez-form>
  </div>
</template>

<style lang="scss" scoped>
.bank-details {
  :deep() textarea {
    height: 136px;
  }
}
.custom-title {
  font-weight: 600;
}
.radio-btn {
  :deep() .ez-radio-button__label {
    color: $color-gray-25;
    @include font-size(14px, 20px);
  }
}
.radio-info {
  margin-left: 20px;
  color: $color-gray-6C;
  @include font-size(12px, 18px);
}
.dropdown-info {
  color: $color-gray-6C;
  @include font-size(12px, 18px);
}

.invoice-settings {
  display: flex;
  align-items: flex-start;

  .help-block {
    margin-right: 16px;
  }
}
</style>
