import i18n from '@/i18n';

export const VENUE_STEP_1 = 0;
export const VENUE_STEP_2 = 1;

const steps = {
  [VENUE_STEP_1]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/accept-send-onboarding-link/Products'),
    title: 'Send Onboarding Link - Products',
    pageTitle: 'And now select products',
    pageInfo: `Select products that will be available to this ${i18n.t('global.venue')}.`,
    nextCta: 'Go to Overview',
  },
  [VENUE_STEP_2]: {
    component: () => import(/* webpackChunkName: "distributor" */ '@/views/platform/distributor/outlets/accept-send-onboarding-link/Overview'),
    title: 'Send Onboarding Link - Overview',
    pageTitle: 'Overview',
    pageInfo: 'Before you send the onboarding link, check the information below to make sure you’ve entered everything correctly.',
    nextCta: 'Accept and Send Onboarding Link',
  },
};

export default steps;
