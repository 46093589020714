<script>
/**
 * Xero Customer Categories
 * @version 1.0.0
 * @since
 */

import xeroGuard from '@/views/platform/distributor/xero/settings/xeroGuard';
import {
  distributorGetCustomerCategories,
  distributorUpdateXeroCustomerCategories,
  supplierFetchTrackingCategories,
  distributorGetXeroAccounts,
  distributorGetXeroAccountSetup,
} from '@/api/endpoints/xero';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { mapGetters } from 'vuex';
import { LOADING_KEY, SELECT_DESELECT_ALL } from '@/util/constants';
import EzTable from '@/components/ui/Table/EzTable';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import FooterForUpdate from '@/views/platform/distributor/xero/settings/FooterForUpdate';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import UpdateModal from '@/views/platform/distributor/xero/settings/UpdateModal';
import { debounce } from '@/util/utils';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input/EzInput.vue';

export default {
  mixins: [xeroGuard],
  components: {
    UpdateModal,
    VDataWithInfo,
    EzLoadMore,
    EzSpinner,
    FooterForUpdate,
    EzCheckbox,
    EmptyState,
    EzTable,
    EzLoader,
    EzFilterList,
    EzInput,
  },
  data() {
    return {
      meta: {},
      customerCategories: [],
      columns: [],
      selected: false,
      listOfCustomerCategoryIds: [],
      trackingCategories: [],
      accountsData: {},
      filters: {
        term: null,
      },
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_GET_CUSTOMER_CATEGORIES,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_GET_MORE_CUSTOMER_CATEGORIES);
    },
    columnsNested() {
      return [
        'name',
        ...this.columns,
        'sales',
        'inventory',
        'cogs',
        'paymentTerms',
        'taxRate',
      ];
    },
    entity() {
      return this.$t('global.customerCategories');
    },
    /**
     * Selected indicates if selected all checkbox is checked.
     * In case when this is true, listOfCustomerCategoryIds contains objects that shouldn't be  updated,
     * and are unselected in this case, therefore, they are subtracted from the total count.
     * On the other hande, when select all checkbox is unchecked,
     * listOfCustomerCategoryIds contains objects that should be updated, and they are the only ones that count.
     */
    bulkActionCustomerCategoriesCount() {
      return (this.selected && this.filters.term === null)
        ? this.meta.totalCount - this.listOfCustomerCategoryIds.length
        : this.listOfCustomerCategoryIds.length;
    },
    areAllSelected() {
      return this.bulkActionCustomerCategoriesCount === this.meta.totalCount;
    },
    fetchedColumnsProps() {
      return this.columns.reduce((acc, curr) => ({ ...acc, [curr]: { class: 'wrap-cell' } }), {});
    },
  },
  methods: {
    nameCheckbox(h) {
      return h('span', { class: 'u-flex-center select-all-checkbox' }, [
        h('ez-checkbox', {
          class: 'cursor-pointer',
          props: {
            indeterminate: this.indeterminate,
            label: 'Customer Categories',
            checked: this.areAllSelected,
          },
          on: {
            change: (ev) => {
              this.selectDeselectAll(ev);
            },
          },
        }, []),
      ]);
    },
    async selectDeselectAll(selected) {
      this.selected = selected;
      this.listOfCustomerCategoryIds = [];
      if (this.selected && this.filters.term !== null) {
        this.customerCategories.forEach(item => this.listOfCustomerCategoryIds.push({ id: item.id }));
      }
    },
    /**
     * Depending on Select all checkbox status, listOfCustomerCategoryIds stores objects' ids
     * that are not selected(in the case of select all checked), or the ones that are checked, when select all checkbox is unchecked.
     */
    onCheckboxChange(customerCategory, checked) {
      if (this.filters.term === null) {
        if (this.selected !== checked) this.listOfCustomerCategoryIds.push({ id: customerCategory.id });
        else this.listOfCustomerCategoryIds = this.listOfCustomerCategoryIds.filter(item => item.id !== customerCategory.id);
      } else if (checked) {
        this.listOfCustomerCategoryIds.push({ id: customerCategory.id });
      } else {
        this.listOfCustomerCategoryIds = this.listOfCustomerCategoryIds.filter(item => item.id !== customerCategory.id);
      }
    },
    async bulkActionsForCustomerCategories(config) {
      const items = this.listOfCustomerCategoryIds;
      await distributorUpdateXeroCustomerCategories({
        body: {
          items,
          config,
          state: (this.selected && this.filters.term === null)
            ? SELECT_DESELECT_ALL.selectedAll
            : SELECT_DESELECT_ALL.deselectedAll,
        },
      });

      await this.refresh();

      this.$refs.updateModal.close();
    },
    isCustomerCategorySelected(customerCategory) {
      return (this.selected && this.filters.term === null && this.listOfCustomerCategoryIds.findIndex(el => el.id === customerCategory.id) < 0)
        || ((!this.selected || this.filters.term) && this.listOfCustomerCategoryIds.findIndex(el => el.id === customerCategory.id) >= 0);
    },
    openUpdateModal() {
      this.$refs.updateModal.open();
    },
    setMeta(meta) {
      this.meta = meta;
      this.columns = meta.columns;
    },
    getTrackingValue(customerCategory, col) {
      return customerCategory?.xeroConfig?.tracking?.[col] || '-';
    },
    async refresh() {
      const {
        data: { data, meta },
      } = await distributorGetCustomerCategories(this.filters);
      this.customerCategories = data;
      this.setMeta(meta);
    },
    async onLoadMore(params = {}) {
      const {
        data: { data, meta },
      } = await distributorGetCustomerCategories({
        ...this.meta,
        loadingKey: LOADING_KEY.DISTRIBUTOR_GET_MORE_CUSTOMER_CATEGORIES,
        ...params,
        ...this.filters,
      });
      this.customerCategories = [...this.customerCategories, ...data];
      this.setMeta(meta);
    },
    async resetFilters() {
      Object.keys(this.filters).forEach((key) => { this.filters[key] = null; });
      this.selectDeselectAll(false);
      await this.refresh();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      }
      this.selectDeselectAll(false);
      await this.refresh();
    }, 300),
  },
  async created() {
    await this.refresh();

    const {
      data: { data: categories },
    } = await supplierFetchTrackingCategories();
    this.trackingCategories = categories;

    const [
      { data: cogsAccounts },
      { data: invoicesAccounts },
      { data: inventoryAccounts },
      { data: setupData },
    ] = await Promise.all([
      distributorGetXeroAccounts({ type: 'cogs', withDefault: true }),
      distributorGetXeroAccounts({ type: 'revenue', withDefault: true }),
      distributorGetXeroAccounts({ type: 'inventory', withDefault: true }),
      distributorGetXeroAccountSetup(),
    ]);

    const selectedAccount = setupData.data.accountCode;
    const selectedAccMapped = invoicesAccounts.data.find(ac => ac.code === selectedAccount)?.name;
    this.accountsData = {
      cogsAccounts: cogsAccounts.data,
      invoicesAccounts: invoicesAccounts.data,
      inventoryAccounts: inventoryAccounts.data,
      selectedAccount,
      selectedAccMapped,
    };
  },
  watch: {
    bulkActionCustomerCategoriesCount(val) {
      window.Intercom('update', {
        hide_default_launcher: !!val,
      });
    },
  },
};
</script>
<template>
  <div>
    <div class="products__header mb-24">
      <span class="products__info-text">
        {{ customerCategories.length ? $t('xero.settings.customerCategories.infoText') : '' }}
      </span>
    </div>
    <ez-filter-list
      :filters="filters"
      @filterUpdated="updateFilters"
      @resetFilter="resetFilters"
      class="mb-24"
    >
      <ez-input
        formKey="filters"
        name="search"
        class="search"
        :placeholder="`Search for a ${$t('global.customerCategory')}`"
      >
        <template #prefix>
          <font-awesome-icon icon="search" />
        </template>
      </ez-input>
    </ez-filter-list>
    <div>
      <ez-table
        v-if="customerCategories.length"
        :data="customerCategories"
        :columns="columnsNested"
        :headers="{
          name: nameCheckbox,
          sales: () => 'Invoices Account',
          inventory: () => 'Inventory Account',
          cogs: () => 'COGS Account',
        }"
        :columnProps="{
          taxRate: { class: 'wrap-cell' },
          customerCategory: { class: 'outlet-cell' },
          sales: { class: 'wrap-cell' },
          inventory: { class: 'wrap-cell' },
          cogs: { class: 'wrap-cell' },
          ...fetchedColumnsProps,
        }"
        :disable-hover="true"
      >
        <template #cell-name="{ row }">
          <div class="u-flex-row u-flex-center">
            <ez-checkbox
              class="cursor-pointer mr-8"
              :key="row.id"
              :checked="isCustomerCategorySelected(row)"
              @change="onCheckboxChange(row, $event)"
            />
            <span class="product-info">{{ row.name }}</span>
          </div>
        </template>

        <template v-for="cl in columns" #[`cell-${cl}`]="{ row }">
          {{ getTrackingValue(row, cl) }}
        </template>

        <template #cell-sales="{ row: { xeroAccounts: { sales } } }">
          <v-data-with-info
            v-if="sales"
            :show-underline="sales.default"
            :info="sales.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ sales.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-inventory="{ row: { xeroAccounts: { inventory } } }">
          <v-data-with-info
            v-if="inventory"
            :show-underline="inventory.default"
            :info="inventory.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ inventory.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-cogs="{ row: { xeroAccounts: { cogs } } }">
          <v-data-with-info
            v-if="cogs"
            :show-underline="cogs.default"
            :info="cogs.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ cogs.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-paymentTerms="{ row: { xeroPaymentTerms } }">
          <v-data-with-info
            v-if="xeroPaymentTerms"
            :show-underline="xeroPaymentTerms.default"
            :info="xeroPaymentTerms.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ xeroPaymentTerms.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-taxRate="{ row: { xeroTax } }">
          <v-data-with-info
            v-if="xeroTax"
            :show-underline="xeroTax.default"
            :info="xeroTax.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ xeroTax.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>
      </ez-table>

      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner />
      </div>

      <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="onLoadMore" />
    </div>
    <template v-if="!customerCategories.length">
      <empty-state class="empty-state-center">
        <template #badge> <img src="@/assets/no-venue-empty-state.svg" alt=""/></template>

        <template #title>{{ $t('xero.settings.customerCategories.emptyState.title') }}</template>
        <template #info>
          {{ $t('xero.settings.customerCategories.emptyState.info') }}
        </template>
      </empty-state>
    </template>

    <ez-loader :show="isLoading">Loading...</ez-loader>

    <update-modal
      v-if="accountsData && accountsData.invoicesAccounts"
      ref="updateModal"
      :entity="entity"
      :categories="trackingCategories"
      :acc-data="accountsData"
      @update="bulkActionsForCustomerCategories"
      show-tax-rate
      show-payment-terms
    />

    <footer-for-update
      v-if="bulkActionCustomerCategoriesCount"
      :entity="entity"
      :items-count="bulkActionCustomerCategoriesCount"
      @footerAction="openUpdateModal"
    />
  </div>
</template>
<style lang="scss" scoped>
.products__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.products__info-text {
  @include font-size(14px, 20px);

  width: 400px;
  font-weight: 500;
  color: $color-gray-6C;
}

.product-info__name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

:deep() .ez-filter-list__items .search {
  width: 260px;
}

.wrap-cell {
    :deep() .has-tooltip {
      display: inline;
    }
}

.select-all-checkbox {
    .input-group {
      @include font-size(12px);
      :deep() .check-indicator {
        margin-right: 8px;
      }
    }
  }
</style>
