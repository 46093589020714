<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { LOADING_KEY, STOCKTAKE_DATE_FILTER_DEFAULT } from '@/util/constants';
import dayjs from 'dayjs';

import EzFilterList from '@/components/ui/FilterList';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table';
import VAccountOwnerEntityInfo from '@/components/v3/patterns/VAccountOwnerEntityInfo';
import VDatePicker from '@/components/v3/patterns/VDatePicker';
import EmptyState from '@/views/common/empty-state';

/**
 * Stocktakes
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'Stocktakes',
  components: {
    EzFilterList,
    EzLoadMore,
    EzSpinner,
    EzButton,
    EzTable,
    VAccountOwnerEntityInfo,
    VDatePicker,
    EmptyState,
  },
  data() {
    return {
      stocktakes: [],
      meta: {},
      filters: {
        from: null,
        to: null,
      },
      date: {
        start: STOCKTAKE_DATE_FILTER_DEFAULT.from,
        end: STOCKTAKE_DATE_FILTER_DEFAULT.to,
      },
      today: new Date(),
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    ...mapState('entities/users', ['loggedUser']),
    isLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_STOCKTAKES);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_STOCKTAKES);
    },
    distributor() {
      return this.loggedUser.distributor;
    },
    trackingInventory() {
      return this.distributor.inventorySettings.tracking;
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['distributorFetchStocktakes']),
    rowDataCy(row) {
      return `${supplierCy.PRODUCTS.STOCKTAKE.ROW__SINGLE_STOCKTAKE}-${row.id}`;
    },
    openSingleStocktake(row) {
      const routeData = this.$router.resolve({ name: 'distributor-single-stocktake', params: { id: row.id } });
      window.open(routeData.href, '_blank');
    },
    conductStocktake() {
      this.$router.push({
        name: 'distributor-conduct-stocktake',
        params: { distributorId: this.distributor.id },
      });
    },
    async updateFilters(name, event) {
      if (name === 'date') {
        this.filters = {
          ...this.filters,
          from: dayjs(event.start).toISOString(),
          to: dayjs(event.end).toISOString(),
        };
      }

      this.stocktakes = [];
      this.meta = {};
      await this.fetchStocktakes();
    },
    async resetFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });
      this.stocktakes = [];
      this.meta = {};
      await this.fetchStocktakes();
    },
    async fetchStocktakes(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_STOCKTAKES) {
      const query = {
        limit: 10,
        ...this.filters,
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
      };
      const { data } = await this.distributorFetchStocktakes({ query, loadingKey });

      this.stocktakes = [...this.stocktakes, ...data.data];
      this.meta = data.meta;
    },
    async loadMore() {
      await this.fetchStocktakes(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_STOCKTAKES);
    },
  },
  async created() {
    await this.fetchStocktakes();
    await this.$nextTick();

    if (!this.trackingInventory && !this.stocktakes.length) {
      await this.$router.push({ name: 'distributor-products-all' });
    }
  },
};
</script>

<template>
  <div>
    <div class="u-flex-space my-24">
      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <v-date-picker
          label=""
          name="date"
          v-model="date"
          :number-of-calendars="2"
          :what-date-to-display="today"
          with-predefined-ranges
          range-mode
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.BUTTON__DATE_PICKER"
        />
      </ez-filter-list>
      <ez-button
        :disabled="!trackingInventory"
        @click="conductStocktake"
        :data-cy="supplierCy.PRODUCTS.STOCKTAKE.BUTTON__CONDUCT_STOCKTAKE"
      >
        Conduct Stocktake
      </ez-button>
    </div>
    <ez-table
      :loading="isLoading"
      :data="stocktakes"
      :columns="['users', 'warehouse', 'createdAt', 'note']"
      :headers="{
        createdAt: () => 'Date & Time',
      }"
      :columnProps="{
        users: { class: 'users-cell' },
      }"
      @rowClick="openSingleStocktake"
      :rowDataCy="rowDataCy"
    >
      <template #cell-users="{ row, row: { users } }">
        <div class="stocktake-users">
          <v-account-owner-entity-info
            v-for="(user, idx) in users"
            :key="idx"
            class="stocktake-users__user"
            :account-owner="user"
            :toShowName="false"
            :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.TEXT__USER}-${row.id}-${idx}`"
          />
        </div>
      </template>

      <template #cell-warehouse="{ row, row: { warehouse } }">
        <span :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.TEXT__WAREHOUSE}-${row.id}`">{{
          warehouse.name
        }}</span>
      </template>

      <template #cell-createdAt="{ row, row: { createdAt } }">
        <span :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.TEXT__DATE}-${row.id}`">{{
          createdAt | dateTime
        }}</span>
      </template>

      <template #cell-note="{ row, row: { note } }">
        <span :title="note" :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.TEXT__NOTE}-${row.id}`">{{
          note || '-'
        }}</span>
      </template>
    </ez-table>

    <empty-state v-if="!stocktakes.length && !isLoading && !isLoadingMore">
      <template #badge><img src="@/assets/no-products.svg" alt="" /></template>
      <template #title>No stocktakes yet</template>
      <template #info>Once you conduct stocktakes, they will show up here.</template>
    </empty-state>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="loadMore" />
  </div>
</template>

<style lang="scss" scoped>
.users-cell {
  overflow: visible;

  .stocktake-users {
    display: flex;
    align-items: center;

    &__user {
      border: 2px solid white;
      border-radius: 50%;

      &:not(:first-child) {
        margin-left: -8px;
      }
    }
  }
}
</style>
