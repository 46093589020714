<script>
/**
 * Index
 * @version 1.0.0
 * @since 3.22.0
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import EzNav from '@/components/ui/Nav';
import VPage from '@/components/v3/templates/VPage';
import VTotalAmount from '@/components/v3/patterns/VTotalAmount';
import { mapGetters, mapState } from 'vuex';
import EzBadge from '@/components/ui/Badge/EzBadge';

export default {
  components: {
    VTotalAmount,
    EzNav,
    VPage,
    EzBadge,
  },
  data() {
    return {
      supplierCy,
    };
  },
  computed: {
    ...mapState('reports', ['meta']),
    ...mapGetters('entities/users', ['toDosNotification']),
    canSeeTodos() {
      return this.$permission.has('createTodos');
    },
  },
};
</script>
<template>
  <v-page>
    <template #title>{{ $t('crm.general.title') }}</template>
    <template #subtitle>{{ meta.subtitle }}</template>
    <template v-if="meta.count !== null && meta.count !== undefined" #info>
      <v-total-amount >
        <template #amount>{{ meta.count }}</template>
      </v-total-amount>
    </template>
    <template>
      <ez-nav class="mt-16">
        <template #nav>
          <ul>
            <li>
              <router-link
                :to="{ name: 'distributor-account-activity' }"
                :data-cy="supplierCy.CRM.NAVIGATION.BUTTON__ACCOUNT_ACTIVITY"
              >
                Account Activity
              </router-link>
            </li>
            <li v-if="canSeeTodos">
              <router-link
                :to="{ name: 'distributor-to-dos' }"
                :data-cy="supplierCy.CRM.NAVIGATION.BUTTON__TO_DOS"
              >
                To-Dos
              </router-link>
              <ez-badge
                type="blue"
                :count="toDosNotification"/>
            </li>
            <li>
              <router-link
                :to="{ name: 'distributor-account-check-in' }"
                :data-cy="supplierCy.CRM.NAVIGATION.BUTTON__TEAM_ACTIVITY"
              >
                Team Activity
              </router-link>
              <ez-badge
                v-if="!canSeeTodos"
                type="blue"
                :count="toDosNotification"/>
            </li>
            <li>
              <router-link
                :to="{ name: 'distributor-expense-tracking' }"
                :data-cy="supplierCy.CRM.NAVIGATION.BUTTON__EXPENSE_TRACKING"
              >
                Expense Tracking
              </router-link>
            </li>
          </ul>
        </template>
        <router-view />
      </ez-nav>
    </template>
  </v-page>
</template>

<style lang="scss" scoped>
:deep() .stats-boxes--large {
  .stats-box__value {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 24px;
  }
}

:deep() .tax-excluded-icon {
  color: $color-gray-6C;
}
</style>
