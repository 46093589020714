<script>
/**
 * SelectProducts
 * @version 1.0.0
 * @since 2.3.0
 */
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardListenerMixin } from '@/mixins/wizard';
import { debounce, isNullish, clone } from '@/util/utils';
import ProductsSelectList from '@/views/common/products/ProductsSelectList.vue';
import EzBadge from '@/components/ui/Badge/EzBadge';
import EzFilterList from '@/components/ui/FilterList/EzFilterList';
import EzInput from '@/components/ui/Input/EzInput';
import EzAlert from '@/components/ui/Alert/EzAlert';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import EzWarehouseFilter from '@/components/ui/Filter/Warehouse.vue';
import EzProductGroupFilter from '@/components/ui/Filter/ProductGroup.vue';
import EzProductTagsFilter from '@/components/ui/Filter/ProductTags.vue';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import { addTotalPriceCustom } from '@/util/utilsFinCalculator';
import { LOADING_KEY, TagType } from '@/util/constants';
import uuid from 'uuid/v4';
import { DISCOUNT_TYPE, getDiscountValue } from './overview/utils';

const FilterArea = {
  List: 'list',
  Dropdown: 'dropdown',
};

export default {
  mixins: [wizardListenerMixin],
  components: {
    VSubtotalInfo,
    EzAlert,
    EzInput,
    EzFilterList,
    EzBadge,
    ProductsSelectList,
    EzCategoryFilter,
    EzWarehouseFilter,
    EzProductGroupFilter,
    EzProductTagsFilter,
    VFilterDropdown,
  },
  data() {
    return {
      meta: {},
      listFilters: {
        term: null,
      },
      supplierCy,
      LOADING_KEY,
      dropdownFilters: {},
      categories: [],
      warehouses: [],
      FilterArea,
      selectedProductTags: [],
      appliedProductTags: [],
      uuid,
    };
  },
  computed: {
    ...mapState('entities/orders', ['draft', 'newOrder']),
    ...mapGetters('entities/orders', ['getDraftVenue']),
    ...mapGetters('entities/users', ['getDistributor']),
    ...mapGetters('entities/users', ['isDistributor']),
    minimumOrderAmount() {
      return this.getDistributor?.minimumOrderAmount;
    },
    products() {
      return this.newOrder.products;
    },
    selectedProducts() {
      return this.newOrder.selectedProducts;
    },
    selectedVenueId() {
      return this.getDraftVenue.id;
    },
    selectedProductsMsg() {
      return this.selectedProducts.length ? 'Products selected:' : 'No products selected';
    },
    me() {
      return this.isDistributor ? this.getDistributor : null;
    },
    isTrackingInventory() {
      return this.me?.inventorySettings?.tracking;
    },
    isSomeOutOfStock() {
      return this.selectedProducts.find(p => p.inventoryTracked && p.quantity > p.inventory);
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      return this.selectedProducts.map(prod => addTotalPriceCustom({ item: prod }));
    },
    draftDeliveryFee() {
      return this.draft?.deliveryFee ?? null;
    },
    deliveryFee() {
      return this.draftDeliveryFee || 0;
    },
    deliveryFeeTaxable() {
      return this.getDistributor.deliveryFeeWithoutTax;
    },
    taxCalculation() {
      return this.getDraftVenue.connection?.taxCalculation;
    },
    tax() {
      if (!isNullish(this.getDraftVenue.connection?.tax)) return this.getDraftVenue.connection?.tax;
      if (!isNullish(this.getDistributor?.tax)) return this.getDistributor?.tax;
      return 0;
    },
    hasSomeTbd() {
      return this.selectedProducts.some(pr => pr.marketPrice || (pr.priceUnit && pr.price > 0));
    },
    // Props for VSubtotalInfo - End
    filters() {
      return { ...this.listFilters, ...this.dropdownFilters };
    },
  },
  methods: {
    ...mapMutations('entities/orders', [
      'SET_DRAFT_PRODUCTS',
      'SET_PRODUCTS',
      'ADD_SELECTED_PRODUCT',
      'REMOVE_SELECTED_PRODUCT',
      'UPDATE_PRODUCT',
      'CALCULATE_NEW_ORDER_AMOUNT',
      'UPDATE_DELIVERY_FEE_TO_NEW_ORDER',
      'UPDATE_DRAFT',
    ]),
    ...mapMutations('tags', ['SET_SELECTED_TAGS']),
    ...mapMutations('entities/products', ['UPDATE_META']),
    ...mapActions('entities/orders', {
      fetchProducts: 'fetchProductsForVenue',
    }),
    ...mapActions('entities/categories', ['distributorFetchCategories']),
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    ...mapActions('entities/products', ['distributorFetchProductGroups']),
    ...mapActions('tags', ['fetchTagsByType']),
    ...mapActions('cart', ['fetchProductPriceForQuantity']),
    ...mapActions('entities/products', ['distributorNewOrderFetchProductPrice']),
    async fetchCategories() {
      const { data } = await this.distributorFetchCategories();
      this.categories = data.data;
    },
    async fetchWarehouses() {
      const { data } = await this.distributorFetchWarehouses({ withLocations: false });
      this.warehouses = [{ id: null, name: 'All Warehouses' }, ...data.data];
    },
    async fetchProductGroups() {
      const { data } = await this.distributorFetchProductGroups();
      this.productGroups = [{ id: null, name: 'All Product Groups' }, ...data.data];
    },
    handleDeliveryFee() {
      if (this.selectedProducts.length) {
        this.UPDATE_DRAFT({ oldAmount: this.newOrder.amount });
        this.CALCULATE_NEW_ORDER_AMOUNT();
        this.UPDATE_DELIVERY_FEE_TO_NEW_ORDER({
          distributor: this.me,
          taxCalculation: this.taxCalculation,
        });
      }
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    onNextStep() {
      this.SET_DRAFT_PRODUCTS(this.selectedProducts);
      this.$emit('stepCompleted');
    },
    async onLoadMore(nextId) {
      const {
        data: { data, meta },
      } = await this.fetchProducts({
        venueId: this.selectedVenueId,
        withWarehouseInventory: true,
        inventoryByUnit: true,
        nextId,
        nextValue: this.meta.nextValue,
        loadingKey: LOADING_KEY.FETCH_MORE_PRODUCTS_FOR_VENUE,
        filters: this.filters,
      });
      this.meta = meta;

      if (this.selectedProducts.length) {
        const productIds = [...new Set(this.selectedProducts.map(p => p.internalId))];
        const dataPr = data.filter(p => !productIds.includes(p.internalId));

        this.SET_PRODUCTS([...this.products, ...dataPr]);
        return;
      }

      this.SET_PRODUCTS([...this.products, ...data]);
    },
    async onProductSelect(product) {
      const checked = !product.checked;
      if (checked) {
        this.ADD_SELECTED_PRODUCT(product);
        let { minimumQuantity } = product;
        minimumQuantity = minimumQuantity < 1 ? 1 : minimumQuantity;
        let { quantity } = product;
        quantity = !quantity ? minimumQuantity : quantity;

        const updatedProduct = { ...product, quantity, checked, quantityInvalid: false };
        this.UPDATE_PRODUCT(updatedProduct);

        /**
         * In order to properly fetch the product price, we need to trick
         * the component into thinking we manually entered quantity of 1
         */
        await this.onQuantityChange({ ...updatedProduct, quantity: 0 }, quantity);
      } else {
        await this.onQuantityChange(product, 0);
        this.REMOVE_SELECTED_PRODUCT({ ...product, quantity: 0 });
      }

      this.handleDeliveryFee();
    },
    async onAddAnotherProduct(product) {
      const internalId = this.uuid();
      this.ADD_SELECTED_PRODUCT({ ...product, bonusId: null, isBonus: false, internalId });
      const { minimumQuantity } = product;
      let { quantity } = product;
      quantity = !quantity ? minimumQuantity : quantity;

      /**
       * In order to properly fetch the product price, we need to trick
       * the component into thinking we manually entered quantity of 1
       */
      await this.onQuantityChange(
        { ...product, quantity: 0, isBonus: false, bonusId: null, internalId },
        quantity,
      );
      this.handleDeliveryFee();
    },
    onPriceChange(product, newPrice) {
      if (product.price === newPrice) {
        return;
      }
      const productClone = {
        ...product,
        price: newPrice,
        priceModified: true,
        ...(!product.marketPrice &&
          !product.defaultMarketPrice && {
            discountType: DISCOUNT_TYPE.amount,
            discountValue: product.defaultPrice - newPrice,
          }),
      };
      productClone.discountAmount = getDiscountValue(productClone);
      this.UPDATE_PRODUCT({ ...productClone });

      this.handleDeliveryFee();
    },
    setRegularPrice(product) {
      this.UPDATE_PRODUCT({ ...product, marketPrice: false });
      this.handleDeliveryFee();
    },
    setMarketPrice(product) {
      this.UPDATE_PRODUCT({ ...product, marketPrice: true });
      this.handleDeliveryFee();
    },
    onQuantityChange: debounce(async function deb(product, quantity) {
      if (quantity === product.quantity) return;
      if (quantity === '' && product.quantity === 0) return;

      const selectedProd = this.selectedProducts.find(p => p.internalId === product.internalId);
      const isChecked = !!quantity;

      if (!selectedProd && isChecked) {
        this.ADD_SELECTED_PRODUCT(product);
      }
      if (selectedProd && !isChecked) {
        this.REMOVE_SELECTED_PRODUCT({ ...product, quantity });
      }

      if (!quantity) {
        this.UPDATE_PRODUCT({ ...product, checked: isChecked, quantity });
        this.handleDeliveryFee();
        return;
      }

      // When first time increment is clicked set to minimumQuantity
      const { minimumQuantity, quantity: selectedQuantity } = selectedProd || product;
      quantity = quantity + selectedQuantity < minimumQuantity ? minimumQuantity : quantity;

      let productClone = { ...product };

      if (!productClone.priceModified && !productClone.isBonus) {
        const { data } = await this.distributorNewOrderFetchProductPrice({
          productId: productClone.id,
          venueId: this.selectedVenueId,
          quantity,
        });

        productClone = {
          ...productClone,
          price: data.data.pricePerUnit,
          marketPrice: data.data.marketPrice,
          defaultPrice: data.data.defaultPrice,
          discountType: data.data.discountType,
          discountValue: data.data.discountValue,
        };
        // Only used for calculation in totals tooltip
        productClone.discountAmount = getDiscountValue(productClone);
      }
      this.UPDATE_PRODUCT({ ...productClone, checked: isChecked, quantity });

      this.handleDeliveryFee();
    }, 300),
    searchForProduct: debounce(async function deb(term) {
      if (!term) {
        await this.resetFilters(FilterArea.List);
        return;
      }

      this.listFilters.term = term;

      const venueId = this.selectedVenueId;
      const {
        data: { data, meta },
      } = await this.fetchProducts({
        venueId,
        filters: this.filters,
        withWarehouseInventory: true,
        inventoryByUnit: true,
      });

      this.meta = meta;
      this.SET_PRODUCTS(data);
    }, 300),
    updateFilters: debounce(async function deb(area, event) {
      if (area === FilterArea.Dropdown) {
        const tagIds = this.selectedProductTags.map(item => item.id);
        this.dropdownFilters = {
          ...this.dropdownFilters,
          ...(typeof event.categoryId === 'object' &&
            event.categoryId !== null && { categoryId: event.categoryId.id }),
          ...(typeof event.warehouseId === 'object' &&
            event.warehouseId !== null && { warehouseId: event.warehouseId.id }),
          ...(typeof event.productGroupId === 'object' &&
            event.productGroupId !== null && { productGroupId: event.productGroupId.id }),
          ...(tagIds.length ? { tagIds } : { tagIds: null }),
        };
        this.appliedProductTags = clone(this.selectedProductTags);
        this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      }

      const {
        data: { data, meta },
      } = await this.fetchProducts({
        venueId: this.selectedVenueId,
        withWarehouseInventory: true,
        inventoryByUnit: true,
        filters: this.filters,
        sortBy: 'name',
      });

      this.meta = meta;

      this.SET_PRODUCTS(data);
    }, 300),
    async resetFilters(area) {
      if (area === FilterArea.List) {
        Object.keys(this.listFilters).forEach(key => {
          this.listFilters[key] = null;
        });
      } else if (area === FilterArea.Dropdown) {
        this.selectedProductTags = [];
        this.appliedProductTags = [];
        this.dropdownFilters = {};
      }

      const venueId = this.selectedVenueId;
      const {
        data: { data, meta },
      } = await this.fetchProducts({
        venueId,
        withWarehouseInventory: true,
        inventoryByUnit: true,
        filters: this.filters,
      });

      this.meta = meta;

      if (this.selectedProducts.length) {
        const selectedProductIds = [...new Set(this.selectedProducts.map(p => p.internalId))];
        const newProducts = data.filter(p => !selectedProductIds.includes(p.internalId));
        this.SET_PRODUCTS([...this.selectedProducts, ...newProducts]);
        return;
      }

      this.SET_PRODUCTS(data);
    },
    filterClosed() {
      this.selectedProductTags = clone(this.appliedProductTags);
    },
    onSelectedTagsChange(tags) {
      this.selectedProductTags = tags;
    },
  },
  watch: {
    selectedProducts: {
      deep: true,
      handler(newVal) {
        const isRegularPriceInvalid = prod => !prod.marketPrice && prod.price < 0;

        const invalidProduct = newVal.find(
          prod =>
            isRegularPriceInvalid(prod) ||
            prod.quantity <= 0 ||
            (!prod.isBonus && prod.quantity < prod.minimumQuantity),
        );
        if (newVal.length && !invalidProduct) {
          this.enableNextStep();
        } else {
          this.disableNextStep();
        }
      },
    },
  },
  async created() {
    this.SET_PRODUCTS([]);
    const {
      data: { data, meta },
    } = await this.fetchProducts({
      venueId: this.selectedVenueId,
      withWarehouseInventory: true,
      inventoryByUnit: true,
    });
    let products = data.map(prod => ({ ...prod, quantity: prod.minimumQuantity }));
    await Promise.all([
      this.fetchCategories(),
      this.fetchWarehouses(),
      this.fetchProductGroups(),
      this.fetchTagsByType({ query: { type: TagType.Product } }),
    ]);
    this.SET_SELECTED_TAGS([]);

    this.meta = meta;

    if (this.selectedProducts.length) {
      const productIds = [...new Set(this.selectedProducts.map(p => p.internalId))];
      products = products.filter(p => !productIds.includes(p.internalId));

      this.SET_PRODUCTS([...this.selectedProducts, ...products]);
      return;
    }

    this.SET_PRODUCTS(products);
  },
};
</script>

<template>
  <div>
    <div class="select-product__actions-container">
      <div class="select-product__actions u-flex-space">
        <div class="filters-dropdown">
          <v-filter-dropdown
            :filters="dropdownFilters"
            @filterUpdated="$event => updateFilters(FilterArea.Dropdown, $event)"
            @resetFilters="resetFilters(FilterArea.Dropdown)"
            ref="filtersGroup"
            @filterClosed="filterClosed"
          >
            <template #firstRow>
              <ez-category-filter
                name="categoryId"
                label="Category"
                :categories="categories"
                :selected="dropdownFilters.categoryId"
                :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__CATEGORY_FILTER"
                isFullWidth
              />
              <ez-warehouse-filter
                name="warehouseId"
                label="Warehouse"
                :warehouses="warehouses"
                :selected="dropdownFilters.warehouseId"
                :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__WAREHOUSE_FILTER"
                isFullWidth
              />
              <ez-product-group-filter
                name="productGroupId"
                label="Product Group"
                :productGroups="productGroups"
                :selected="dropdownFilters.productGroupId"
                isFullWidth
              />
            </template>
            <template #thirdRow>
              <ez-product-tags-filter
                class="tags-filter"
                name="productTags"
                :selectedTags="selectedProductTags"
                @change="onSelectedTagsChange"
              />
            </template>
          </v-filter-dropdown>
        </div>
        <span class="select-products__selected-msg">
          <span> {{ selectedProductsMsg }} </span>
          <ez-badge
            class="ml-4"
            :count="selectedProducts.length"
            type="blue"
            :data-cy="supplierCy.ORDERS.NEW_ORDER.SELECT_PRODUCTS.TEXT__SELECTED_PRODUCTS"
          />
        </span>
        <span class="wizard-products__table-actions-filters">
          <ez-filter-list :filters="listFilters" @resetFilter="resetFilters(FilterArea.List)">
            <ez-input
              formKey="product-search"
              name="search"
              label="Search"
              placeholder="Search for a Product"
              @onChange="searchForProduct"
              :data-cy="supplierCy.ORDERS.NEW_ORDER.SELECT_PRODUCTS.INPUT__SEARCH"
            >
              <template #suffix>
                <font-awesome-icon icon="search" />
              </template>
            </ez-input>
          </ez-filter-list>
        </span>
      </div>

      <ez-alert
        v-if="isTrackingInventory && isSomeOutOfStock"
        type="white-peach"
        size="inline"
        :data-cy="supplierCy.ORDERS.NEW_ORDER.SELECT_PRODUCTS.TEXT__BANNER"
      >
        <template #icon>
          <font-awesome-icon class="select-products__alert-icon" icon="exclamation-circle" />
        </template>
        Some of the added products exceed the available quantity. You are still able to create this
        order.
      </ez-alert>
    </div>

    <products-select-list
      :data-cy="supplierCy.ORDERS.NEW_ORDER.SELECT_PRODUCTS"
      :products="newOrder.products"
      :next-id="meta.nextId"
      :show-inventory="true"
      :loading-key="LOADING_KEY.FETCH_PRODUCTS_FOR_VENUE"
      :loading-more-key="LOADING_KEY.FETCH_MORE_PRODUCTS_FOR_VENUE"
      @productSelect="onProductSelect"
      @addAnotherProduct="onAddAnotherProduct"
      @priceChange="onPriceChange"
      @setRegularPrice="setRegularPrice"
      @setMarketPrice="setMarketPrice"
      @quantityChange="onQuantityChange"
      @loadMore="onLoadMore"
    />

    <footer class="select-products__footer">
      <div class="total">
        <v-subtotal-info
          quantityKey="quantity"
          :item-list="itemList"
          :delivery-fee="deliveryFee"
          :delivery-fee-taxable="deliveryFeeTaxable"
          :tax="tax"
          :taxCalculation="taxCalculation"
          :is-tbd="hasSomeTbd"
          :data-cy="supplierCy.ORDERS.NEW_ORDER.SELECT_PRODUCTS.TEXT__ORDER_TOTAL"
        >
          Total (incl. taxes)
        </v-subtotal-info>
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
$border-color: #eceef5;

$actions-top: 64px;
$actions-height: 64px;

$action-min-width: 240px;

$z-index: 10;

.select-products__alert-icon {
  color: $color-primary-red;
}

.select-product__actions-container {
  position: sticky;
  top: $actions-top;

  width: 100%;

  background-color: white;
  z-index: $z-index;

  margin-bottom: 31px;
}

.select-product__actions {
  height: $actions-height;

  border-bottom: 1px solid $border-color;
  margin-bottom: 24px;
}

.status-checkbox {
  @include font-size(14px, 20px);
}

.filters-dropdown {
  justify-content: space-between;
  flex: 1 1 0;
  :deep() .dropdown-filters {
    .input-group {
      margin: 0;
    }

    .filters-area__row:not(:first-child) {
      justify-content: start;
    }
  }

  .tags-filter {
    :deep() .tags-wrapper {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    :deep() .tags-wrapper__selected {
      padding-bottom: 22px;
    }

    :deep() .input-group.search {
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      font-weight: 600;
    }

    :deep() .input-group__label {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }

  :deep() .ez-filter-list {
    &__items {
      margin: 0;
    }
  }
}

.select-products__selected-msg {
  @include font-size(14px, 22px);
  @extend %flex-center;
  justify-content: center;
  flex: 1 1 0;

  min-width: $action-min-width;
  color: $color-gray-6C;

  background-color: white;
}

.select-products__footer {
  position: fixed;
  bottom: 0;

  width: 1200px;
  height: 56px;

  background-color: white;
  border-top: 1px solid $border-color;
}

.total {
  @extend %flex-center;
  justify-content: flex-end;

  margin-top: 16px;

  &__label {
    margin-right: 12px;
    @include font-size(12px);
    color: $color-gray-6C;
    text-transform: uppercase;
  }

  &__amount {
    @include font-size(18px);
    font-weight: bold;
    color: $color-gray-25;
  }
}

.wizard-products__table-actions-filters {
  flex: 1 1 0;
  :deep() .ez-filter-list__items {
    justify-content: flex-end;
  }
}

:deep() .category-dropdown .ez-select__display-container {
  height: 2.25rem;
}
</style>
