<script>
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { groupListByDateFormat } from '@/util/utils';

import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import EzTable from '@/components/ui/Table/EzTable.vue';

import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import VPrice from '@/components/v3/elements/VPrice';
import StatusBadge from '@/views/common/status-badge/StatusBadge.vue';
import EmptyState from '@/views/common/empty-state';

/**
 * CreditsNotesList
 * @version 1.0.0
 * @since 3.27.0
 */

export default {
  name: 'CreditsNotesList',
  props: {
    creditNotes: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isLoadingMore: {
      type: Boolean,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  components: {
    EzLoadMore,
    EzSpinner,
    EzTable,
    VVenueEntityInfo,
    VPrice,
    StatusBadge,
    EmptyState,
  },
  data() {
    return {
      supplierCy,
    };
  },
  computed: {
    groupedCreditNotes() {
      return groupListByDateFormat({ list: this.creditNotes, dateKey: 'date' });
    },
    hasNoCreditNotes() {
      return !this.isLoading && !this.isLoadingMore && !this.creditNotes.length;
    },
  },
  methods: {
    onRowClick(row) {
      this.$emit('rowClick', row);
    },
    onLoadMore() {
      this.$emit('loadMore');
    },
  },
};
</script>

<template>
  <div class="credit-notes__content-wrapper mt-24">
    <div v-for="(group, id) in groupedCreditNotes" :key="id" class="credit-notes__group">
      <h2>{{ group.title }}</h2>
      <ez-table
        :data="group.items"
        :columns="['creditNoteNumber', 'orderNumber', 'venue', 'date', 'status', 'amount']"
        :columnProps="{
          creditNoteNumber: { class: 'creditNoteNumber-cell' },
          orderNumber: { class: 'orderNumber-cell' },
          date: { class: 'creditNoteDate-cell' },
          status: { class: 'creditNoteStatus-cell' },
          amount: { class: 'amount-cell' },
        }"
        :headers="{
          creditNoteNumber: () => 'Credit Note No.',
          orderNumber: () => 'Order No.',
          venue: () => $t('global.venue'),
        }"
        @rowClick="onRowClick"
      >
        <template #cell-creditNoteNumber="{ row }">
          <span :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.TEXT__CREDIT_NOTE_NUMBER}-${row.id}`">
            {{ row.creditNoteNumber || '-' }}
          </span>
        </template>
        <template #cell-orderNumber="{ row }">
          <span :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.TEXT__ORDER_NUMBER}-${row.id}`">
            {{ row.orderNumber || '-' }}
          </span>
        </template>
        <template #cell-venue="{ row }">
          <v-venue-entity-info
            :venue="row.venue"
            :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.TEXT__CUSTOMER}-${row.id}`"
          />
        </template>
        <template #cell-date="{ row }">
          <span :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.TEXT__DATE}-${row.id}`">
            {{ row.date | date }}
          </span>
        </template>
        <template #cell-status="{ row }">
          <status-badge
            :status="row.status"
            :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.TEXT__STATUS}-${row.id}`"
          />
        </template>
        <template #cell-amount="{ row }">
          <v-price
            :price="row.amount || 0"
            :currency="row.currency"
            :show-market-price-info="false"
            :data-cy="`${supplierCy.ORDERS.CREDIT_NOTES.TEXT__AMOUNT}-${row.id}`"
          />
        </template>
      </ez-table>
    </div>

    <empty-state v-if="hasNoCreditNotes" class="mt-48">
      <template #badge><img src="@/assets/no-products.svg" alt="" /></template>
      <template #title>No credit notes</template>
      <template #info>Add a new credit note or try with different filters.</template>
    </empty-state>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="onLoadMore" />
  </div>
</template>

<style lang="scss" scoped>
.credit-notes {
  &__group {
    h2 {
      @include font-size(16px);
      font-weight: 500;
      margin: 0 0 1rem 0;
    }

    + .credit-notes__group {
      margin-top: 2rem;
    }
  }

  &__content-wrapper {
    .creditNoteNumber-cell {
      width: 96px + $spacing-16;
    }

    .orderNumber-cell {
      width: 96px + $spacing-32;
    }

    .creditNoteDate-cell {
      width: 144px + $spacing-32;
    }

    .creditNoteStatus-cell {
      width: 144px + $spacing-32;
    }

    .amount-cell {
      width: 120px + $spacing-16;
    }

    .creditNoteNumber-cell,
    .orderNumber-cell,
    .amount-cell {
      span {
        font-weight: 600;
        color: $color-gray-25;
      }
    }
  }
}
</style>
