<script>
import EzTable from '@/components/ui/Table/EzTable';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'ProductsTable',
  components: {
    EzSpinner,
    EzTable,
    EzLoadMore,
    VProductEntityInfo,
    VDataWithInfo,
  },
  props: {
    products: {
      required: false,
      type: Array,
      default: () => null,
    },
    nextIdProducts: {
      required: false,
      type: Number,
      default: null,
    },
    isLoadingMore: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onLoadMoreProducts() {
      this.$emit('loadMore');
    },
  },
};
</script>

<template>
  <div class="products-container">
    <ez-table
      disableHover
      :data="products"
      :headers="{
        name: () => 'Product',
        quantity: () => 'Qty',
        total: () => 'Total Amount',
      }"
      :columns="['name', 'quantity', 'unit', 'total']"
      :column-props="{
        name: { class: 'width-100-cell' },
        quantity: { class: 'qty-cell text-right-cell' },
        unit: { class: 'unit-cell text-right-cell' },
        total: { class: 'price-cell padding-right-price-cell' },
      }"
    >
      <template #cell-name="{ row }">
        <v-product-entity-info :product="row" />
      </template>
      <template #cell-quantity="{ row }">
        {{ row.count }}
      </template>
      <template #cell-unit="{ row: { orderingUnit } }">
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="orderingUnit.abbreviation ? `${orderingUnit.name}` : ''"
            :show-underline="!!orderingUnit.abbreviation"
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>
      <template #cell-total="{ row }">
        {{ row.amount | price }}
      </template>
    </ez-table>

    <div v-show="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="!isLoadingMore && nextIdProducts" @loadMore="onLoadMoreProducts" />
  </div>
</template>

<style lang="scss" scoped>
.products-container {
  max-height: 500px;
  overflow: auto;
  padding-top: 12px;

  .table {
    .unit-cell {
      width: 95px;
      overflow: visible;
    }
  }
}
</style>
