<script>
import { EzTab, EzTabs } from '@/components/ui/Tabs';
import ProductsTable from '@/views/platform/distributor/crm/ProductsTable';
import Timeline from '@/views/common/orders/history-log/Timeline';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';

/**
 *
 * @version 1.0.0
 * @since 3.3.1
 */
export default {
  name: 'NotOrderingTabs',
  components: {
    EzSpinner,
    EzTabs,
    EzTab,
    ProductsTable,
    Timeline,
    EzLoadMore,
    EmptyState,
  },
  props: {
    products: {
      required: false,
      type: Array,
      default: () => null,
    },
    nextIdProducts: {
      required: false,
      type: Number,
      default: null,
    },
    isLoadingMoreProducts: {
      required: false,
      type: Boolean,
      default: false,
    },
    orders: {
      required: false,
      type: Array,
      default: () => null,
    },
    nextIdOrders: {
      required: false,
      type: Number,
      default: null,
    },
    isLoadingMoreOrders: {
      required: false,
      type: Boolean,
      default: false,
    },
    contentSubtitle: {
      required: false,
      type: String,
      default: '',
    },

  },
  methods: {
    onLoadMoreProducts() {
      this.$emit('loadMoreProducts');
    },
    onLoadMoreOrders() {
      this.$emit('loadMoreOrders');
    },
  },
};
</script>

<template>
  <ez-tabs :defaultTabIndex="0">
    <div v-if="contentSubtitle" class="additional-info mt-16">
      {{ contentSubtitle }}
    </div>
    <ez-tab title="Account History" hash="tab-1">
      <div v-if="orders" class="history">
        <timeline :history="orders" />

        <div v-show="isLoadingMoreOrders" class="u-text-center mt-12">
          <ez-spinner/>
        </div>
        <ez-load-more v-if="!isLoadingMoreOrders && nextIdOrders" @loadMore="onLoadMoreOrders" />

        <empty-state
          class="empty-products"
          v-if="orders && !orders.length"
        >
          <template #badge>
            <img
              src="@/assets/no-products.svg"
              width="160"
              height="138"
              alt="Empty state image"
            />
          </template>
          <template #title>No account history</template>
          <template #info>There’s no account history for this account.</template>
        </empty-state>
      </div>
    </ez-tab>
    <ez-tab title="Most Ordered Products" hash="tab-2">
      <products-table
        :products="products"
        :next-id-products="nextIdProducts"
        :is-loading-more="isLoadingMoreProducts"
        @loadMore="onLoadMoreProducts"
      />
      <empty-state
        class="empty-products"
        v-if="products && !products.length"
      >
        <template #badge>
          <img
            src="@/assets/no-products.svg"
            width="160"
            height="138"
            alt="Empty state image"
          />
        </template>
        <template
          #title>
          No products to show
        </template>
        <template #info>There are no accepted orders between you and this {{ $t('global.venue') | lowercase }}.</template>
      </empty-state>
    </ez-tab>
  </ez-tabs>
</template>

<style lang="scss" scoped>
:deep() .ez-tabs__content {
  padding: 0 24px;
}

.history {
  padding-bottom: 48px;
  height: 100%;
  overflow: auto;
}

.additional-info {
  @include font-size(12px, 16px);
  font-weight: 600;
  color: $color-gray-6C;
}
</style>
