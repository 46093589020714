<template>
  <div class="all-products">
    <ez-product-list
      :headers="{
        name: () => 'Product',
        expiresSoon: () => '',
        parLevelReached: () => '',
        warehouses: () => (filters.warehouseId ? 'Location' : 'Warehouse'),
        price: () => 'Price per unit',
        quantity: () => 'Stock',
        cogs: () => 'COGS',
        tags: () => 'Tags',
      }"
      :columns="columns"
      :columnProps="{
        name: { class: 'name-cell' },
        expiresSoon: { class: 'indicator-cell' },
        parLevelReached: { class: 'indicator-cell' },
        warehouses: { class: 'location-cell' },
        price: { class: 'price-cell' },
        cogs: { class: 'cogs-cell' },
        stock: { class: 'stock-cell' },
      }"
      :products="products"
      @rowClick="loadProductDrawer"
      :responsiveInfo="false"
      :warehouseId="filters.warehouseId"
      :inventoryDate="filters.inventoryDate"
    >
      <template #filters>
        <div class="all-products__filters">
          <ez-filter-list
            :filters="listFilters"
            @filterUpdated="(...$event) => updateFilters(FilterArea.List, $event)"
            @resetFilter="resetFilters(FilterArea.List)"
            class="mr-16"
          >
            <ez-input
              formKey="filters"
              label="search"
              name="term"
              class="search"
              placeholder="Search for a Product"
              :data-cy="supplierCy.PRODUCTS.GENERAL.INPUT__SEARCH"
            >
              <template #prefix>
                <font-awesome-icon icon="search" />
              </template>
            </ez-input>
          </ez-filter-list>
          <v-filter-dropdown
            :filters="dropdownFilters"
            @filterUpdated="$event => updateFilters(FilterArea.Dropdown, $event)"
            @resetFilters="resetFilters(FilterArea.Dropdown)"
            ref="filtersGroup"
            @filterClosed="filterClosed"
          >
            <template #firstRow>
              <ez-category-filter
                name="categoryId"
                label="Category"
                :categories="categories"
                :selected="dropdownFilters.categoryId"
                :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__CATEGORY_FILTER"
                isFullWidth
              />
              <ez-warehouse-filter
                name="warehouseId"
                label="Warehouse"
                :warehouses="warehouses"
                :selected="dropdownFilters.warehouseId"
                :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__WAREHOUSE_FILTER"
                isFullWidth
              />
              <v-date-picker
                name="inventoryDate"
                label="Stock Date"
                v-model="inventoryDate"
                isFullWidth
              />
              <ez-product-group-filter
                name="productGroupId"
                label="Product Group"
                :productGroups="productGroups"
                :selected="dropdownFilters.productGroupId"
                isFullWidth
              />
            </template>
            <template #secondRowLabel>Status</template>
            <template #secondRow>
              <ez-checkbox
                class="status-checkbox"
                :checked="dropdownFilters.expiresSoon"
                key="expiresSoon"
                name="expiresSoon"
                label="Expiring Soon"
              />
              <ez-checkbox
                class="status-checkbox"
                :checked="dropdownFilters.parLevelReached"
                key="parLevelReached"
                name="parLevelReached"
                label="Reached Par Level"
              />
              <ez-checkbox
                class="status-checkbox"
                :checked="dropdownFilters.outOfStock"
                key="outOfStock"
                name="outOfStock"
                label="Out of Stock"
              />
            </template>
            <template #thirdRow>
              <ez-product-tags-filter
                class="tags-filter"
                name="productTags"
                :selectedTags="selectedProductTags"
                @change="onSelectedTagsChange"
              />
            </template>
          </v-filter-dropdown>
          <div class="all-products__new">
            <ez-simple-dropdown
              v-if="$permission.has('exportProducts')"
              class="import-export"
              placeholder="Import/Export CSV"
              :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__IMPORT_EXPORT_CSV"
            >
              <template #dropdown>
                <ez-button
                  type="link"
                  formType="button"
                  customClass="upload-csv"
                  @click="uploadCSVModal"
                  :disabled="isActionDisabled(SUPPLIER_PRODUCTS_ACTIONS.IMPORT_PRODUCTS, 'convert')"
                  :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__UPLOAD_CSV"
                >
                  Import CSV
                </ez-button>
                <ez-button
                  type="link"
                  formType="button"
                  customClass="upload-csv"
                  @click="exportCSV"
                  :disabled="isActionDisabled(SUPPLIER_PRODUCTS_ACTIONS.EXPORT_PRODUCTS, 'convert')"
                  :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__EXPORT_CSV"
                >
                  Export CSV
                </ez-button>
                <div
                  v-tooltip="{
                    placement: 'top',
                    classes: ['tooltip--reset-margin u-text-center'],
                    content: !me.inventoryTracking
                      ? 'Due to inventory tracking being OFF in supplier settings, this action is not available'
                      : '',
                  }"
                  class="new-product__button"
                >
                  <ez-button
                    type="link"
                    formType="button"
                    customClass="upload-csv"
                    @click="exportInventoryCSV"
                    :disabled="!me.inventoryTracking"
                  >
                    Export Inventory CSV
                  </ez-button>
                </div>
              </template>
            </ez-simple-dropdown>
            <template v-if="!$permission.isPremium">
              <ez-button
                type="primary"
                formType="button"
                class="new-product"
                @click="onAddNewProductClick"
                :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__ADD_ONE_PRODUCT"
              >
                Add One Product
              </ez-button>
            </template>
            <ez-simple-dropdown
              v-else
              class="new-product"
              placeholder="Manage Products"
              :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__MANAGE_PRODUCTS"
            >
              <template #dropdown>
                <div
                  v-tooltip="inventoryManagementTooltip(SUPPLIER_PRODUCTS_ACTIONS.CREATE_PRODUCT)"
                  class="new-product__button"
                >
                  <ez-button
                    type="link"
                    formType="button"
                    isFullWidth
                    @click="onAddNewProductClick"
                    :disabled="isActionDisabled(SUPPLIER_PRODUCTS_ACTIONS.CREATE_PRODUCT)"
                    :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__CREATE_PRODUCT"
                  >
                    Create New Product
                  </ez-button>
                </div>
                <div
                  v-tooltip="inventoryManagementTooltip(SUPPLIER_PRODUCTS_ACTIONS.ADD_INVENTORY)"
                  class="new-product__button"
                >
                  <ez-button
                    type="link"
                    formType="button"
                    isFullWidth
                    @click="startProductsAdditionFlow"
                    :disabled="isActionDisabled(SUPPLIER_PRODUCTS_ACTIONS.ADD_INVENTORY)"
                    :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__ADD_INVENTORY"
                  >
                    Add Inventory
                  </ez-button>
                </div>
                <div
                  v-tooltip="
                    inventoryManagementTooltip(SUPPLIER_PRODUCTS_ACTIONS.TRANSFER_INVENTORY)
                  "
                  class="new-product__button"
                >
                  <ez-button
                    type="link"
                    formType="button"
                    isFullWidth
                    @click="startProductsTransferFlow"
                    :disabled="isActionDisabled(SUPPLIER_PRODUCTS_ACTIONS.TRANSFER_INVENTORY)"
                    :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__TRANSFER_INVENTORY"
                  >
                    Transfer Inventory
                  </ez-button>
                </div>
                <div
                  v-tooltip="inventoryManagementTooltip(SUPPLIER_PRODUCTS_ACTIONS.REMOVE_INVENTORY)"
                  class="new-product__button"
                >
                  <ez-button
                    type="link"
                    formType="button"
                    isFullWidth
                    @click="startProductsRemovalFlow"
                    :disabled="isActionDisabled(SUPPLIER_PRODUCTS_ACTIONS.REMOVE_INVENTORY)"
                    :data-cy="supplierCy.PRODUCTS.GENERAL.BUTTON__REMOVE_INVENTORY"
                  >
                    Remove Inventory
                  </ez-button>
                </div>
              </template>
            </ez-simple-dropdown>
          </div>
        </div>
      </template>
      <template v-if="!products.length">
        <empty-state class="empty-state-center" v-if="hasFilters">
          <template #badge><img src="@/assets/no-products-search-state.svg" alt="" /></template>
          <template #title>No products match this search</template>
          <template #info>Try with a different search.</template>
        </empty-state>
        <empty-state class="empty-state-center" v-else>
          <template #badge><img src="@/assets/no-product-empty-state.svg" alt="" /></template>
          <template #title>No products listed</template>
          <!-- eslint-disable-next-line -->
          <template #info
            >You can add products by uploading a CSV file, or add one product at a time.</template
          >
        </empty-state>
      </template>

      <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="onLoadMoreClick"></ez-load-more>
    </ez-product-list>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-csv-upload-modal
      ref="csvUploadModal"
      :uploadCSV="uploadAction"
      :onUploadFinished="onUploadFinished"
    />
  </div>
</template>

<script>
/**
 * AllProducts
 * @version 1.0.0
 * @since
 */
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { EzCsvUploadModal } from '@/components/ui/Modal';
import EmptyState from '@/views/common/empty-state';
import EzButton from '@/components/ui/Button';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import EzWarehouseFilter from '@/components/ui/Filter/Warehouse.vue';
import EzProductGroupFilter from '@/components/ui/Filter/ProductGroup.vue';
import EzProductTagsFilter from '@/components/ui/Filter/ProductTags.vue';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzLoadMore from '@/components/ui/LoadMore';
import EzProductList from '@/views/common/products/List.vue';
import downloadAttachment from '@/util/downloadAttachment';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown.vue';
import { LOADING_KEY, SUPPLIER_PRODUCTS_ACTIONS, TagType } from '@/util/constants';
import { debounce, clone } from '@/util/utils';
import httpService from '@/api/http';

const FilterArea = {
  List: 'list',
  Dropdown: 'dropdown',
};

export default {
  components: {
    EzSpinner,
    EmptyState,
    EzCategoryFilter,
    EzWarehouseFilter,
    EzProductGroupFilter,
    EzProductTagsFilter,
    EzButton,
    VFilterDropdown,
    VDatePicker,
    EzFilterList,
    EzInput,
    EzCheckbox,
    EzLoadMore,
    EzProductList,
    EzCsvUploadModal,
    EzSimpleDropdown,
  },
  props: {
    productId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      supplierCy,
      SUPPLIER_PRODUCTS_ACTIONS,
      actions: {
        manage: [],
        convert: [],
      },
      dropdownFilters: {},
      listFilters: { term: null },
      inventoryValuation: 0,
      products: [],
      categories: [],
      warehouses: [],
      today: dayjs().startOf('day'),
      inventoryDate: dayjs(this.today),
      FilterArea,
      selectedProductTags: [],
      appliedProductTags: [],
    };
  },
  computed: {
    ...mapGetters('entities/users', ['getDistributor']),
    ...mapGetters('loading', ['getLoading']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapState('entities/products', ['meta']),
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.LOAD_MORE_PRODUCTS);
    },
    hasFilters() {
      return this.filters.term || this.filters.distributorId || this.filters.categoryId;
    },
    me() {
      return this.loggedUser.distributor;
    },
    columns() {
      return [
        'name',
        ...(this.me.inventorySettings.tracking ? ['expiresSoon', 'parLevelReached'] : []),
        'tags',
        'warehouses',
        'parLevel',
        'stock',
        'unit',
        ...(this.$permission.has('seeCOGS') ? ['cogs'] : []),
        'price',
      ];
    },
    filters() {
      return { ...this.listFilters, ...this.dropdownFilters };
    },
  },
  methods: {
    ...mapMutations('tags', ['CLEAR_ALL_TAGS', 'SET_SELECTED_TAGS']),
    inventoryManagementTooltip(action) {
      const actionDisabled = this.isActionDisabled(action);
      if (this.me?.inventorySettings?.tracking || !actionDisabled) return undefined;

      return {
        placement: 'top',
        classes: ['tooltip--reset-margin u-text-center'],
        content:
          'Due to inventory tracking being OFF in supplier settings, this action is not available',
      };
    },
    isActionDisabled(action, scope = 'manage') {
      return !this.actions[scope].includes(action);
    },
    uploadAction(data) {
      return this.uploadDistributorCSV({ data });
    },
    uploadCSVModal() {
      this.$refs.csvUploadModal.open();
    },
    async onUploadFinished() {
      await this.resetFilters();
    },
    async exportCSV() {
      const query = {
        ...(this.filters.warehouseId ? { warehouseId: this.filters.warehouseId } : {}),
        ...(this.filters.categoryId ? { categoryId: this.filters.categoryId } : {}),
        ...(this.filters.productGroupId ? { productGroupId: this.filters.productGroupId } : {}),
        ...(this.filters.inventoryDate ? { inventoryDate: this.filters.inventoryDate } : {}),
        ...(this.filters.tagIds && this.filters.tagIds.length
          ? { tagIds: this.filters.tagIds }
          : {}),
      };

      const { data, headers } = await this.distributorDownloadProductsCsv(query);
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename)
        ? decodeURI(filename[1])
        : `${this.me.name}'s products.xlsx`;
      downloadAttachment(data, name);
    },
    async exportInventoryCSV() {
      const query = {
        ...(this.filters.warehouseId ? { warehouseId: this.filters.warehouseId } : {}),
        ...(this.filters.categoryId ? { categoryId: this.filters.categoryId } : {}),
        ...(this.filters.productGroupId ? { productGroupId: this.filters.productGroupId } : {}),
        ...(this.filters.inventoryDate ? { inventoryDate: this.filters.inventoryDate } : {}),
        ...(this.filters.tagIds && this.filters.tagIds.length
          ? { tagIds: this.filters.tagIds }
          : {}),
      };
      const { data, headers } = await httpService.get('/distributor/inventory/export', {
        responseType: 'arraybuffer',
        params: {
          ...query,
        },
      });
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename)
        ? decodeURI(filename[1])
        : `${this.me.name}'s inventory.xlsx`;
      downloadAttachment(data, name);
    },
    updateFilters: debounce(async function deb(area, event) {
      if (area === FilterArea.List) {
        const [filterName, value] = event;
        this.listFilters = { ...this.listFilters, [filterName]: value };
      } else if (area === FilterArea.Dropdown) {
        const tagIds = this.selectedProductTags.map(item => item.id);
        const inventoryDate = dayjs(event.inventoryDate).format('YYYY-MM-DD');
        this.dropdownFilters = {
          ...this.dropdownFilters,
          ...(typeof event.categoryId === 'object' &&
            event.categoryId !== null && { categoryId: event.categoryId.id }),
          ...(typeof event.warehouseId === 'object' &&
            event.warehouseId !== null && { warehouseId: event.warehouseId.id }),
          ...(event.inventoryDate && { inventoryDate }),
          ...(typeof event.productGroupId === 'object' &&
            event.productGroupId !== null && { productGroupId: event.productGroupId.id }),
          ...(event.expiresSoon !== undefined && { expiresSoon: event.expiresSoon }),
          ...(event.parLevelReached !== undefined && { parLevelReached: event.parLevelReached }),
          ...(event.outOfStock !== undefined && { outOfStock: event.outOfStock }),
          ...(tagIds.length ? { tagIds } : { tagIds: null }),
        };
        if (this.dropdownFilters.expiresSoon === false) delete this.dropdownFilters.expiresSoon;
        if (this.dropdownFilters.parLevelReached === false)
          delete this.dropdownFilters.parLevelReached;
        if (this.dropdownFilters.outOfStock === false) delete this.dropdownFilters.outOfStock;
        this.appliedProductTags = clone(this.selectedProductTags);
        this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      }

      this.products = [];
      this.UPDATE_META({
        meta: {
          nextId: null,
          nextValue: null,
          ...(this.filters.term && { term: this.filters.term }),
        },
      });
      await Promise.all([
        this.fetchProducts({ filters: this.filters, sortBy: 'name' }),
        this.fetchInventoryValuation(),
      ]);
    }, 300),
    async resetFilters(area) {
      if (area === FilterArea.List) {
        Object.keys(this.listFilters).forEach(key => {
          this.listFilters[key] = null;
        });
      } else if (area === FilterArea.Dropdown) {
        this.selectedProductTags = [];
        this.appliedProductTags = [];
        this.dropdownFilters = {};
        this.inventoryDate = dayjs(this.today);
      }

      this.$nextTick(() => this.$refs.filtersGroup.syncLocalFilters());

      this.products = [];
      this.UPDATE_META({
        meta: {
          nextId: null,
          nextValue: null,
          ...(area === FilterArea.List && { term: null }),
        },
      });
      await Promise.all([
        this.fetchProducts({ filters: this.filters, sortBy: 'name' }),
        this.fetchInventoryValuation(),
      ]);
    },
    filterClosed() {
      this.selectedProductTags = clone(this.appliedProductTags);
    },
    onSelectedTagsChange(tags) {
      this.selectedProductTags = tags;
    },
    loadProductDrawer({ id }) {
      const routeData = this.$router.resolve({
        name: 'distributor-product-single',
        params: { id },
      });
      window.open(routeData.href, '_blank');
    },
    onAddNewProductClick() {
      const { id } = this.getDistributor;
      this.$router.push({
        name: 'distributor-new-product',
        params: { id },
      });
      this.CLEAR_ALL_TAGS();
    },
    startProductsAdditionFlow() {
      const { id } = this.getDistributor;
      this.$router.push({
        name: 'distributor-add-inventory',
        params: { id },
      });
    },
    startProductsTransferFlow() {
      const { id } = this.getDistributor;
      this.$router.push({
        name: 'distributor-transfer-inventory',
        params: { id },
      });
    },
    startProductsRemovalFlow() {
      const { id } = this.getDistributor;
      this.$router.push({
        name: 'distributor-remove-inventory',
        params: { id },
      });
    },
    async onLoadMoreClick() {
      await this.fetchProducts(
        { filters: this.filters, sortBy: 'name' },
        LOADING_KEY.LOAD_MORE_PRODUCTS,
      );
    },
    ...mapActions('entities/products', [
      'fetchDistributorProducts',
      'uploadDistributorCSV',
      'distributorDownloadProductsCsv',
      'distributorFetchProductsActions',
      'distributorFetchInventoryValuation',
    ]),
    ...mapActions('entities/users', ['initDistributor']),
    ...mapMutations('entities/products', ['UPDATE_META']),
    ...mapActions('entities/categories', ['distributorFetchCategories']),
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    ...mapActions('entities/products', ['distributorFetchProductGroups']),
    ...mapActions('tags', ['fetchTagsByType']),
    async fetchCategories() {
      const { data } = await this.distributorFetchCategories();
      this.categories = data.data;
    },
    async fetchWarehouses() {
      const { data } = await this.distributorFetchWarehouses({ withLocations: false });
      this.warehouses = [{ id: null, name: 'All Warehouses' }, ...data.data];
    },
    async fetchProductGroups() {
      const { data } = await this.distributorFetchProductGroups();
      this.productGroups = [{ id: null, name: 'All Product Groups' }, ...data.data];
    },
    async fetchProducts(query = {}, loadingKey = LOADING_KEY.FETCH_DISTRIBUTOR_PRODUCTS) {
      const { data } = await this.fetchDistributorProducts({
        ...(this.meta.nextId && { nextId: this.meta.nextId }),
        ...(this.meta.nextValue && { nextValue: this.meta.nextValue }),
        ...query,
        loadingKey,
        withWarehouseInventory: true,
      });
      if (loadingKey === LOADING_KEY.FETCH_DISTRIBUTOR_PRODUCTS) {
        this.products = data.data;
      } else {
        this.products = [...this.products, ...data.data];
      }
      this.UPDATE_META({ meta: data.meta });
    },
    async fetchInventoryValuation() {
      const { data } = await this.distributorFetchInventoryValuation({ query: this.filters });
      this.inventoryValuation = data.data;
    },
  },
  watch: {
    inventoryValuation(val) {
      this.UPDATE_META({ meta: { inventoryValuation: val } });
    },
  },
  async created() {
    this.dropdownFilters = this.me.config.filterWarehouseId
      ? { warehouseId: this.me.config.filterWarehouseId }
      : {};
    this.UPDATE_META({ meta: { nextId: null, nextValue: null } });
    const [
      {
        data: { data: actions },
      },
      // distProducts
    ] = await Promise.all([
      this.distributorFetchProductsActions(),
      this.fetchProducts({ filters: this.filters, sortBy: 'name' }),
      this.fetchInventoryValuation(),
      this.fetchCategories(),
      this.fetchWarehouses(),
      this.fetchProductGroups(),
      this.fetchTagsByType({ query: { type: TagType.Product } }),
    ]);

    this.actions = actions;
    this.SET_SELECTED_TAGS([]);

    const { productId } = this;
    if (productId) {
      this.loadProductDrawer({ id: productId });
    }
  },
};
</script>

<style lang="scss" scoped>
$dropdown-link: #252631;
.all-products {
  @include platform-tab-content();

  &__filters {
    justify-content: space-between;
    :deep() .dropdown-filters {
      .input-group {
        margin: 0;
      }

      .filters-area__row:not(:first-child) {
        justify-content: start;
      }
    }

    .tags-filter {
      :deep() .tags-wrapper {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }

      :deep() .tags-wrapper__selected {
        padding-bottom: 22px;
      }

      :deep() .input-group.search {
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        font-weight: 600;
      }

      :deep() .input-group__label {
        margin-bottom: 0;
        margin-right: 16px;
      }
    }

    :deep() .ez-filter-list {
      &__items {
        margin: 0;
      }
    }
  }
  &__new {
    margin-left: auto;
    display: flex;
    align-items: center;

    .import-export {
      width: auto;

      :deep() .ez-simple-dropdown {
        &__label {
          margin-right: 16px;
        }

        &__display-container--active {
          background-color: $color-gray-E9;
        }

        &__dropdown {
          width: fit-content;
          overflow: visible;
        }
      }

      .button--link {
        width: 100%;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        text-align: left;
        color: $dropdown-link;
        font-weight: 400;

        &:hover {
          background: $color-gray-F5;
        }

        &:disabled {
          color: $color-gray-6C;
          background-color: transparent;
        }
      }
    }

    .new-product {
      margin-left: 1rem;
      width: auto;

      &__button {
        display: block;
      }

      :deep() .ez-simple-dropdown {
        &__label,
        &__display-container svg {
          color: $color-white;
        }

        &__label {
          margin-right: 16px;
        }

        &__display-container {
          background-color: $color-primary-blue;

          &--active {
            background-color: $color-primary-dark-blue;
          }
        }

        &__dropdown {
          left: auto;
          right: 0;
          width: max-content;
        }
      }

      .button--link {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        text-align: left;
        color: $dropdown-link;
        font-weight: 400;

        &:hover {
          background: $color-gray-F5;
        }

        &:disabled {
          color: $color-gray-6C;
          background-color: transparent;
        }
      }
      svg {
        margin-right: 0.5rem;
      }
      hr {
        margin: 0.25rem 0.75rem;
        border-style: solid;
      }
    }
  }
  &__sync {
    @extend %flex-center;
    margin-left: 1em;
    flex: 1 1 auto;
    justify-content: flex-end;

    > * + * {
      margin-left: 1rem;
    }

    .sync-date {
      @extend %gray-label;
      margin-left: auto;
      margin-right: 1rem;
      font-weight: 500;
    }
    .button-dropdown {
      flex-shrink: 0;
      :deep() &__dropdown {
        width: 100%;
        .button--link {
          color: $dropdown-link;
          padding-left: 0.625rem;
          padding-right: 0.625rem;
        }
      }
      :deep() .button--secondary {
        @extend %flex-center;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        img {
          width: auto;
          height: 1rem;
          display: block;
        }
        span {
          margin: 0 0.5rem;
          font-weight: 500;
        }
      }
    }
    .sync-button {
      svg {
        @include font-size(12px);
        margin-right: 0.75rem;
      }
    }
  }
  .table {
    td {
      &.price-cell {
        color: $color-primary-blue;
      }
    }
    .category-badge {
      @extend %category-badge;
    }
  }
  :deep() {
    .table {
      .indicator-cell {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        overflow: visible;
        width: 28px;
        color: $color-primary-orange-red;
      }

      .location-cell {
        overflow: hidden;
      }

      .cogs-cell {
        text-align: right;

        &:not(th) span:not(.status) span {
          @include font-size(14px, 20px);
          color: $color-gray-6C;
          font-weight: 400;
        }
      }
      .name-cell {
        width: 300px;
      }

      .stock-cell {
        width: 100px;
      }
    }
  }
  .ez-load-more {
    margin-top: 2em;
  }
}
.status-checkbox {
  @include font-size(14px, 20px);
}

:deep() .category-dropdown .ez-select__display-container {
  height: 2.25rem;
}

:deep() .ez-select .ez-select__display-container {
  height: 2.25rem;
}

:deep() .ez-simple-dropdown.date-picker {
  width: auto;
}
</style>
