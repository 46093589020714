<script>
/**
 * Show tiers
 * @version 1.0.0
 * @since
 */

export default {
  props: {
    tiers: {
      type: Array,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
  },
};
</script>
<template>
  <div class="tier">
    <div class="tier-list">
      <div
        v-for="(tier, ind) in tiers"
        :key="tier.id"
        class="tier-list__item-container ">
        <div v-if="ind !== 0" class="tier-list__vertical-separator"></div>
        <div class="tier-list__item">
          <b>Tier {{ ind + 1 }}</b>

          <small>
            {{ !!tiers[ind + 1]
            ? `From ${tier.minQuantity} to ${tiers[ind + 1].minQuantity}`
            : `From ${tier.minQuantity || 0} and up` }}
          </small>

          <div>
            {{ (tier.discountType === 'price'
            ? tier.value
            : (1 - tier.value / 100) * price)|price }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.tier {
  .tier-list {
    @extend %flex-center;
    background-color: $color-gray-F5;
    padding: .75rem;
    &__item {
      @extend %flex-center;
      justify-content: space-between;
      flex-direction: column;
      height: 3.75rem;
      margin: 0 auto;
      b {
        @extend  %gray-label;
        font-weight: 500;
        text-transform: uppercase;
      }
      small {
        @include font-size(12px, 14px);
      }
      div {
        @include font-size(16px, 19px);
        font-weight: bold;
      }
    }
    &__item-container {
      @extend %flex-center;
      flex: 1 1 auto;
    }
    &__vertical-separator {
      width: 1px;
      background-color: #DDDFE6;
      height: 2.5rem;
    }
  }
}
</style>
