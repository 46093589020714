<template>
 <page>
   <ez-loader :show="isLoading">
     {{loadingMsg}}
   </ez-loader>
   <template #breadcrumbs>
     <router-link :to="{ name: 'platform-home' }">
        <font-awesome-icon icon="arrow-left"/>
        Back to Home
      </router-link>
   </template>
   <template #title>
     <h1>{{ $t('global.distributor')}} Settings</h1>
   </template>
   <template #navigation>
     <ul>
        <li>
          <router-link
            :to="{ name: 'distributor-settings' }">{{ $t('global.distributor') }} Info
          </router-link>
        </li>
       <li>
         <router-link
           :to="{ name: 'distributor-payment-information' }">Payment Information</router-link>
       </li>
       <li>
         <router-link :to="{ name: 'distributor-settings-inventory' }">Inventory Settings</router-link>
       </li>
       <li>
         <router-link :to="{ name: 'distributor-warehouse' }">Warehouses</router-link>
       </li>
        <li v-if="$permission.has('userAdministration')">
          <router-link :to="{ name: 'distributor-settings-users' }">Users</router-link>
        </li>
       <li v-if="$permission.isPremium">
         <router-link :to="{ name: 'distributor-integrations'}">
           {{ $t('xero.integrations.tabTitle') }}
         </router-link>
       </li>
       <li v-if="$permission.isPremium">
         <router-link :to="{ name: 'distributor-venue-onboarding' }">Sales Settings</router-link>
       </li>
     </ul>
   </template>
 </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import EzLoader from '@/components/ui/Loader/EzLoader';
import Page from '@/views/admin/SinglePage.vue';

export default {
  components: {
    EzLoader,
    Page,
  },
  data() {
    return {
      loadingMsg: 'Loading...',
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.ME,
        LOADING_KEY.FETCH_DISTRIBUTOR,
        LOADING_KEY.FETCH_TIME_ZONES,
        LOADING_KEY.INIT_DISTRIBUTOR,
        LOADING_KEY.GET_DISTRIBUTOR_ROLES,
        LOADING_KEY.GET_USERS,
        LOADING_KEY.DELETE_DISTRIBUTOR_USER,
        LOADING_KEY.DISTRIBUTOR_FETCH_TERMS_AND_CONDITIONS,
        LOADING_KEY.DISTRIBUTOR_UPDATE_TERMS_AND_CONDITIONS,
        LOADING_KEY.JOKER,
      ]);
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributorLoggedUser']),
  },
  mounted() {
    this.initDistributorLoggedUser();
  },
};
</script>

<style scoped lang="scss">
:deep() .loader {
  @extend %whole-screen;
}

.admin-single-page {
  h1 {
    @include font-size(24px, 32px);
    font-weight: 500;
    margin: 0 0 .5rem 0;
    letter-spacing: -1px;
  }
}
</style>
