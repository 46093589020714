<template>
  <div>
    <ez-autocomplete
      v-if="!hasSelectedVenue"
      ref="autocomplete"
      :source="`/distributor/search/venues`"
      :requestParams="{ connected: true, includeTargetAccounts: true }"
      searchProperty="name"
      @selected="onVenueSelect"
      :label="`Search for a ${$t('global.venue')}`"
      :errorMsg="errorMsg"
      :data-cy="supplierCy.ORDERS.NEW_ORDER.SELECT_OUTLET.INPUT__OUTLET_SEARCH"
      :result-data-cy="supplierCy.ORDERS.NEW_ORDER.SELECT_OUTLET.BUTTON__SEARCH_RESULT"
    >
      <template #firstResult="{ input }">
        <li>
          <ez-entity-info :img-url="defaultImage" img-border-radius="50%">
            <span>Create New "{{ input }}" {{ $t('global.venue') }}</span>
          </ez-entity-info>
        </li>
      </template>
      <template #result="{ result }">
        <v-venue-entity-info
          :venue="result"
          :data-cy="supplierCy.ORDERS.NEW_ORDER.SELECT_OUTLET.TEXT__RESULT_OUTLET_NAME"
        />
      </template>
    </ez-autocomplete>

    <ez-selected-venue
      v-else
      @remove="removeSelectedVenue"
      :venue="selectedVenue"
      only-basic-info
      :data-cy="supplierCy.ORDERS.NEW_ORDER.SELECT_OUTLET.TEXT__OUTLET_NAME"
      :remove-button-data-cy="supplierCy.ORDERS.NEW_ORDER.SELECT_OUTLET.BUTTON__REMOVE_OUTLET"
    />
    <ez-input
      v-if="hasSelectedVenue"
      formKey="order-reference-number"
      name="orderReferenceNumber"
      label="Reference Number"
      placeholder="Enter Reference Number"
      :value="outletReferenceNumber"
      class="mt-16"
      @onChange="onRefNumChange"
      :data-cy="supplierCy.ORDERS.NEW_ORDER.SELECT_OUTLET.INPUT__ORDER_REFERENCE"
    />

    <ez-dropdown
      v-if="hasSelectedVenue && isNewCustomer"
      formKey=""
      name="accountRep"
      :data="accountRepsOptions"
      :selected="selectedVenue.accountRepId"
      :label="$t('global.accountRep')"
      :placeholder="`Select ${$t('global.accountRep').toLowerCase()}`"
      @change="onAccountOwnerUpdate"
      isFullWidth
      class="mt-16"
    />
  </div>
</template>

<script>
/**
 * SelectVenue
 * @version 1.0.0
 * @since 2.3.0
 */

import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { formatErrorMessageFromRes } from '@/util/utils';
import EzAutocomplete from '@/components/ui/Autocomplete';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo.vue';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import confirmation from '@/components/v3/patterns/VConfirmation/control';
import { EzSelectedVenue } from '@/components/ui/SelectedItem';
import { mapActions, mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import { mapGetters } from 'vuex/dist/vuex.esm.browser';
import EzInput from '@/components/ui/Input/EzInput';
import EzDropdown from '@/components/ui/Dropdown';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzAutocomplete,
    EzEntityInfo,
    VVenueEntityInfo,
    EzSelectedVenue,
    EzInput,
    EzDropdown,
  },
  data() {
    return {
      outletReferenceNumVal: null,
      accountRepsOptions: [],
      isNewCustomer: false,
      errorMsg: '',
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('entities/orders', ['getDraftVenue', 'hasDraftProducts']),
    ...mapState('entities/orders', ['outletReferenceNumber']),
    ...mapGetters('defaultImages', ['getDefaultImage']),
    defaultImage() {
      return this.getDefaultImage('venue');
    },
    selectedVenue() {
      return this.getDraftVenue;
    },
    hasSelectedVenue() {
      return Object.keys(this.selectedVenue).length > 0;
    },
  },
  methods: {
    ...mapMutations('entities/orders', [
      'CLEAR_DRAFT',
      'SET_DRAFT_VENUE',
      'CLEAR_DRAFT_VENUE',
      'SET_OUTLET_REFERENCE_NUMBER',
      'CLEAR_PRODUCTS',
      'CLEAR_SELECTED_PRODUCTS',
      'CLEAR_OUTLET_REFERENCE_NUMBER',
    ]),
    ...mapActions('entities/distributors', ['fetchDistributor', 'distributorCreateTargetVenue']),
    ...mapActions('defaultImages', ['fetchDefaultImages']),
    ...mapActions('reports', ['fetchAccountRepsAssignable']),
    async onVenueSelect(result) {
      const { selected, first, query: name } = result;

      this.SET_DRAFT_VENUE(first ? { name, accountRepId: null } : selected);
      this.isNewCustomer = !!first;
      this.errorMsg = '';
      this.enableNextStep();
    },
    async removeSelectedVenue() {
      if (this.hasDraftProducts) {
        const { close, canceled } = await confirmation.alert({
          title: this.$t('orders.removeVenueOnAddNewOrderAlertTitle'),
          message: this.$t('orders.removeVenueOnAddNewOrderAlertMessage'),
        });
        if (canceled) return;
        close();
        this.CLEAR_DRAFT();
      }
      this.disableNextStep();
      this.CLEAR_DRAFT_VENUE();
      this.CLEAR_PRODUCTS();
      this.CLEAR_SELECTED_PRODUCTS();
      this.CLEAR_OUTLET_REFERENCE_NUMBER();
    },
    onRefNumChange(val) {
      this.outletReferenceNumVal = val;
    },
    async onNextStep() {
      let newVenue = {};
      if (this.isNewCustomer) {
        try {
          const { data } = await this.distributorCreateTargetVenue({
            data: {
              name: this.selectedVenue.name,
              distributorAccountOwnerId: this.selectedVenue.accountRepId,
            },
          });
          newVenue = data.data;
        } catch (e) {
          this.errorMsg = formatErrorMessageFromRes(e.response);
          this.CLEAR_DRAFT_VENUE();
          return;
        }
      }

      this.SET_DRAFT_VENUE({ ...this.selectedVenue, ...newVenue });
      this.SET_OUTLET_REFERENCE_NUMBER(this.outletReferenceNumVal);
      this.$emit('stepCompleted');
    },
    onAccountOwnerUpdate(value) {
      this.SET_DRAFT_VENUE({ ...this.selectedVenue, accountRepId: value.id });
    },
  },
  async mounted() {
    this.outletReferenceNumVal = this.outletReferenceNumber;
    const [{ data }] = await Promise.all([
      this.fetchAccountRepsAssignable(),
      this.fetchDistributor(this.distributorId),
      this.fetchDefaultImages(),
    ]);
    this.accountRepsOptions = data.data;
    if (this.hasSelectedVenue) {
      this.enableNextStep();
    }
  },
};
</script>

<style lang="scss" scoped></style>
