<script>
import uuid from 'uuid/v4';
import { mapGetters } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { LOADING_KEY } from '@/util/constants';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzForm from '@/components/ui/Form';
import EzTabs from '@/components/ui/Tabs/EzTabs.vue';
import EzTab from '@/components/ui/Tabs/EzTab.vue';
import EzInput from '@/components/ui/Input';
import EzOnOff from '@/components/ui/OnOff';
import EzButton from '@/components/ui/Button';

/**
 * CreateWarehouseModal
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'CreateWarehouseModal',
  props: {},
  components: { EzFormModal, EzForm, EzTabs, EzTab, EzInput, EzOnOff, EzButton },
  data() {
    return {
      supplierCy,
      formValid: false,
      primaryToggleDisabled: false,
      values: {
        name: null,
        street: null,
        city: null,
        zipCode: null,
        country: null,
        state: null,
        primary: false,
        includedForCustomer: false,
        isProvino: false,
        locations: [],
        contactName: null,
      },
      requiredValues: {
        name: null,
        street: null,
        city: null,
        country: null,
      },
      formKey: 'create-warehouse-form',
      keyId: null,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    hasInvalidLocations() {
      return this.values.locations?.some(l => !l.code);
    },
    isLoading() {
      return this.getLoading(this.loadingKey);
    },
    loadingKey() {
      return LOADING_KEY.DISTRIBUTOR_CREATE_WAREHOUSE;
    },
  },
  methods: {
    open(first = false) {
      if (first) {
        this.primaryToggleDisabled = true;
        this.values.primary = true;
      }

      this.values.locations.push({ code: 'Default' });

      this.changeTab('mainInfo');
      this.keyId = uuid();
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    changeTab(tab) {
      this.$refs.tabs.selectTab(tab);
    },
    submitForm() {
      this.$refs.form.onSubmit();
    },
    onCloseModal() {
      this.resetValues();
    },
    resetValues() {
      Object.keys(this.values).forEach((key) => {
        if (typeof this.values[key] === 'boolean') this.values[key] = false;
        else if (Array.isArray(this.values[key])) this.values[key] = [];
        else this.values[key] = null;
      });
      Object.keys(this.requiredValues).forEach((key) => {
        this.requiredValues[key] = null;
      });
      this.formValid = false;
      this.primaryToggleDisabled = false;
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.requiredValues).forEach((key) => {
        if (!this.requiredValues[key]) this.formValid = false;
      });

      if (this.hasInvalidLocations) this.formValid = false;
    },
    async updateFormState(name, value) {
      const isToggleInput = ['primary', 'includedForCustomer', 'isProvino'].includes(name);
      this.values[name] = isToggleInput ? value : value.trim();

      const isRequired = Object.prototype.hasOwnProperty.call(this.requiredValues, name);

      if (!isToggleInput && isRequired) {
        this.requiredValues[name] = await this.$validator.validate(name, value);
      }
      this.validateForm();
    },
    addLocation() {
      this.values.locations = [...this.values.locations, { code: '' }];
      this.validateForm();
    },
    updateLocation(idx, value) {
      this.values.locations[idx].code = value.trim();
      this.validateForm();
    },
    removeLocation(idx) {
      this.values.locations = this.values.locations.filter((_, i) => i !== idx);
      this.validateForm();
    },
    transformer(data) {
      const trimmed = Object.entries(data).reduce(
        (acc, [key, val]) => ({
          ...acc,
          ...(typeof val === 'string' ? { [key]: val.trim() } : {}),
        }),
        {},
      );
      const { street, city, zipCode, country, state, ...rest } = trimmed;

      return {
        ...rest,
        address: { street, city, zipCode, country, state },
        locations: [...this.values.locations],
        primary: this.values.primary,
        includedForCustomer: this.values.includedForCustomer,
        isProvino: this.values.isProvino,
      };
    },
    hasError(idx, code) {
      if (!code) return '';

      const foundIdx = this.values.locations.findIndex((l, i) => l.code === code && i !== idx);

      if (foundIdx === -1) return '';
      if (foundIdx > idx) return '';

      this.formValid = false;
      return 'Location with this name already exists';
    },
    onSuccess() {
      this.$emit('success');
      this.close();
    },
    onError() {
      this.$emit('error');
    },
  },
};
</script>

<template>
  <ez-form-modal ref="modal" @close="onCloseModal">
    <template #title>Add New Warehouse</template>
    <template #content>
      <ez-tabs ref="tabs">
        <ez-tab title="Main Info" hash="mainInfo">
          <ez-form
            ref="form"
            action="/distributor/warehouses"
            :key="keyId"
            :formKey="formKey"
            :loadingKey="loadingKey"
            :transformer="transformer"
            @success="onSuccess"
            @error="onError"
          >
            <ez-input
              :key="`${keyId}-name`"
              name="name"
              :formKey="formKey"
              label="Warehouse Name*"
              placeholder="Enter Name"
              validators="required"
              :value="values.name"
              @onChange="updateFormState('name', $event)"
              class="mt-16"
              :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.INPUT__NAME"
            />
            <ez-input
              :key="`${keyId}-street`"
              name="street"
              :formKey="formKey"
              label="Street Address*"
              placeholder="Enter Street Address"
              validators="required"
              :value="values.street"
              @onChange="updateFormState('street', $event)"
              class="mt-12"
              :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.INPUT__STREET"
            />
            <div class="mt-12 u-flex-center two-column-inputs">
              <ez-input
                :key="`${keyId}-city`"
                name="city"
                :formKey="formKey"
                label="City*"
                placeholder="Enter City"
                validators="required"
                :value="values.city"
                @onChange="updateFormState('city', $event)"
                :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.INPUT__CITY"
              />
              <ez-input
                :key="`${keyId}-zipCode`"
                name="zipCode"
                :formKey="formKey"
                label="Zip Code"
                placeholder="Enter Zip Code"
                :value="values.zipCode"
                @onChange="updateFormState('zipCode', $event)"
                :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.INPUT__ZIP_CODE"
              />
            </div>
            <div class="mt-12 u-flex-center two-column-inputs">
              <ez-input
                :key="`${keyId}-country`"
                name="country"
                :formKey="formKey"
                label="Country*"
                placeholder="Enter Country"
                validators="required"
                :value="values.country"
                @onChange="updateFormState('country', $event)"
                :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.INPUT__COUNTRY"
              />
              <ez-input
                :key="`${keyId}-state`"
                name="state"
                :formKey="formKey"
                label="State"
                placeholder="Enter State"
                :value="values.state"
                @onChange="updateFormState('state', $event)"
                :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.INPUT__STATE"
              />
            </div>
            <hr />
            <ez-input
              :key="`${keyId}-contactName`"
              name="contactName"
              :formKey="formKey"
              label="Contact Name"
              placeholder="Enter Contact Name"
              :value="values.contactName"
              @onChange="updateFormState('contactName', $event)"
              class="mt-12"
              :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.INPUT__CONTACT_NAME"
            />
            <ez-input
              :key="`${keyId}-email`"
              name="email"
              :formKey="formKey"
              label="Email Address"
              placeholder="Enter Email Address"
              :value="values.email"
              @onChange="updateFormState('email', $event)"
              class="mt-12"
              :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.INPUT__EMAIL"
            />
            <ez-input
              :key="`${keyId}-phone`"
              name="phone"
              :formKey="formKey"
              label="Phone Number"
              placeholder="Enter Phone Number"
              :value="values.phone"
              @onChange="updateFormState('phone', $event)"
              class="mt-12"
              :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.INPUT__PHONE"
            />
            <hr />
            <div class="switch-option">
              <div class="help-block">
                <h4 class="help-block__title">Primary Warehouse</h4>
                <p class="help-block__text">
                  The warehouse where all orders are fulfilled from. <br />
                  This can be changed during order acceptance
                </p>
              </div>
              <ez-on-off
                :formKey="formKey"
                :key="`${keyId}-primary`"
                name="primary"
                :disabled="primaryToggleDisabled"
                :checked="values.primary"
                @change="updateFormState('primary', $event)"
                :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.BUTTON__IS_PRIMARY"
              />
            </div>
            <div class="switch-option">
              <div class="help-block">
                <h4 class="help-block__title">Include Inventory Levels</h4>
                <p class="help-block__text">
                  When enabled, stock from this warehouse will be included <br />
                  in the stock numbers available to customers.
                </p>
              </div>
              <ez-on-off
                :formKey="formKey"
                :key="`${keyId}-includedForCustomer`"
                name="includedForCustomer"
                :checked="values.includedForCustomer"
                @change="updateFormState('includedForCustomer', $event)"
              />
            </div>
            <div class="switch-option">
              <div class="help-block">
                <h4 class="help-block__title">Fulfill from Provino</h4>
              </div>
              <ez-on-off
                :formKey="formKey"
                :key="`${keyId}-isProvino`"
                name="isProvino"
                :checked="values.isProvino"
                @change="updateFormState('isProvino', $event)"
              />
            </div>
          </ez-form>
        </ez-tab>
        <ez-tab title="Locations" hash="locations">
          <div class="single-location mt-4" v-for="(location, idx) in values.locations" :key="idx">
            <ez-button
              v-if="idx > 0"
              class="remove-location-btn"
              type="link"
              @click="removeLocation(idx)"
              :data-cy="`${supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.BUTTON__REMOVE_LOCATION}-${idx}`"
            >
              <font-awesome-icon icon="times" /> Remove
            </ez-button>
            <ez-input
              :name="`location-${idx}`"
              formKey=""
              label="Location"
              :value="location.code"
              :errorMsg="hasError(idx, location.code)"
              @onChange="updateLocation(idx, $event)"
              class="mt-12"
              :data-cy="`${supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.INPUT__LOCATION_NAME}-${idx}`"
            />
          </div>
          <ez-button
            class="mt-16"
            type="secondary"
            :disabled="hasInvalidLocations"
            @click="addLocation"
            isFullWidth
            :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.BUTTON__ADD_LOCATION"
          >
            <font-awesome-icon icon="plus" /> Add Location
          </ez-button>
        </ez-tab>
      </ez-tabs>
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close"
        :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        formType="button"
        @click="submitForm"
        :disabled="!formValid"
        :isLoading="isLoading"
        :data-cy="supplierCy.SETTINGS.WAREHOUSES.CREATE_WAREHOUSE.BUTTON__CREATE"
      >
        Add Warehouse
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
:deep() .ez-tabs {
  ul li:first-of-type button {
    margin-left: 0;
  }
}

.two-column-inputs {
  & > *:first-of-type {
    flex: 1 0 auto;
  }

  & > *:last-of-type {
    flex-basis: 144px;
    padding-left: 12px;
  }
}

.switch-option {
  display: flex;
  justify-content: space-between;

  .help-block {
    &__text {
      @include font-size(14px, 20px);
    }
  }
}

.single-location {
  position: relative;

  .remove-location-btn {
    @include font-size(12px, 18px);
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    padding: 0;
  }
}
</style>
