<script>
/**
 * Overview
 * @version 1.0.0
 * @since 2.3.0
 */
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';

import { wizardListenerMixin } from '@/mixins/wizard';
import { LOADING_KEY, UNIT_TYPE_FRACTIONAL, DEFAULT_FRACTION_DIGITS } from '@/util/constants';
import { getTotalAmountPriceCustom } from '@/util/utilsFinCalculator';
import { debounce, falsy, pluralize } from '@/util/utils';

import EzEntityInfo from '@/components/ui/EntityInfo';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
import EzButton from '@/components/ui/Button';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzTable from '@/components/ui/Table';
import EzOnOff from '@/components/ui/OnOff/EzOnOff.vue';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import EzLiteBadge from '@/components/ui/LiteBadge';

import VMarketPriceInput from '@/components/v3/patterns/VMarketPriceInput';
import VEntityInfo from '@/components/v3/elements/VEntityInfo/index';
import VPrice from '@/components/v3/elements/VPrice';
import Paper from '@/components/layout/WizardOverviewPaper.vue';

import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';
import OverviewSidebar from './OverviewSidebar.vue';
import {
  DISCOUNT_TYPE,
  getDiscountedPrice,
  getDiscountValue,
  getPrice,
  ORDER_TYPE_SALES_ORDER,
  ORDER_TYPE_BACKORDER,
  ORDER_TYPE_DRAFT,
} from './utils';
import { NEW_ORDER_STEP_1, NEW_ORDER_STEP_2 } from '../steps';

const Event = {
  setPercentType: 'setPercentType',
  setAmountType: 'setAmountType',
};

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzMaskInputSimple,
    EzEntityInfo,
    EzTextarea,
    EzButton,
    EzLoader,
    EzTable,
    EzOnOff,
    EzPremiumBadge,
    EzLiteBadge,
    VMarketPriceInput,
    VEntityInfo,
    VPrice,
    Paper,
    OverviewSidebar,
  },
  data() {
    return {
      note: {
        message: '',
        deliveryOrder: false,
        invoice: false,
      },
      productsListExpanded: false,
      discountsInvalid: {
        product: false,
        order: false,
      },
      supplierCy,
      Event,
      getPrice,
      DISCOUNT_TYPE,
    };
  },
  computed: {
    ...mapState('entities/orders', ['draft', 'outletReferenceNumber']),
    ...mapGetters('entities/users', ['getDistributor']),
    ...mapGetters('loading', ['isSomeLoading']),
    ...mapState('entities/users', ['loggedUser']),
    distributor() {
      return this.loggedUser.distributor;
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_CREATE_ORDER,
        LOADING_KEY.DISTRIBUTOR_ADD_INVOICE_TO_ORDER,
      ]);
    },
    venue() {
      return this.draft.venue;
    },
    products() {
      return this.draft.products;
    },
    isOrder() {
      return (
        this.draft.distributorType === ORDER_TYPE_SALES_ORDER ||
        this.draft.distributorType === ORDER_TYPE_BACKORDER ||
        this.draft.distributorType === ORDER_TYPE_DRAFT
      );
    },
    invoices() {
      return this.draft?.invoices || [];
    },
    totalAmount() {
      return getTotalAmountPriceCustom({ products: this.products });
    },
    listProducts() {
      if (!this.productsListExpanded) return this.products.slice(0, 8);
      return this.products;
    },
    expandButtonCopy() {
      return this.productsListExpanded ? 'Collapse List' : 'Expand List';
    },
    expandButtonIcon() {
      return this.productsListExpanded ? 'angle-up' : 'angle-down';
    },
    isSomeDiscountInvalid() {
      const productDiscountInvalid = this.products.some(p => p.discountInvalid);
      const additionalDiscountInvalid = !!Object.values(this.discountsInvalid).filter(Boolean)
        .length;
      return productDiscountInvalid || additionalDiscountInvalid;
    },
  },
  methods: {
    ...mapMutations('entities/orders', [
      'SET_DRAFT_MESSAGE',
      'UPDATE_DRAFT',
      'UPDATE_DRAFT_PRODUCT',
      'CALCULATE_NEW_DRAFT_ORDER_AMOUNT',
      'UPDATE_DELIVERY_FEE_TO_NEW_ORDER',
    ]),
    ...mapActions('entities/orders', ['distributorCreateOrder', 'distributorAddInvoiceToOrder']),
    ...mapActions('entities/products', ['distributorNewOrderFetchProductPrice']),
    getRowClass(row) {
      return row.isBonus ? 'item-bonus' : null;
    },
    tradeDealTooltipContent(row) {
      return `Buy ${row.deals[0].buy} ${pluralize(row.orderingUnit.label, row.deals[0].buy)}, 
                    get ${row.deals[0].get} ${pluralize(
        row.orderingUnit.label,
        row.deals[0].get,
      )} for ${row.deals[0].price}${row.currency.symbol}`;
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    async onNextStep() {
      this.SET_DRAFT_MESSAGE(this.note.message);
      const { data } = await this.distributorCreateOrder();

      if (!falsy(this.invoices)) {
        await this.distributorAddInvoiceToOrder({ orderId: data.data.id, invoices: this.invoices });
      }

      await this.$router.push({
        name: 'distributor-home',
        params: {
          flash: {
            title: 'Order created!',
            type: 'success',
          },
        },
      });
    },
    editVenue() {
      this.UPDATE_DRAFT({ oldAmount: this.totalAmount });
      this.$emit('stepBack', NEW_ORDER_STEP_1);
    },
    editProducts() {
      this.UPDATE_DRAFT({ oldAmount: this.totalAmount });
      this.$emit('stepBack', NEW_ORDER_STEP_2);
    },
    isFractional(product) {
      return product.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    isInvalid(product) {
      return product.quantityInvalid;
    },
    onQuantityInvalid(val, product) {
      this.UPDATE_DRAFT_PRODUCT({ ...product, quantityInvalid: val });
    },
    onQuantityChange: debounce(async function deb(quantity, product) {
      if (quantity === product.quantity) return;

      const { minimumQuantity, quantity: selectedQuantity } = product;
      quantity = quantity + selectedQuantity < minimumQuantity ? minimumQuantity : quantity;

      let productClone = { ...product };

      if (!productClone.priceModified) {
        const { data } = await this.distributorNewOrderFetchProductPrice({
          productId: productClone.id,
          venueId: this.venue.id,
          quantity,
        });
        productClone = {
          ...productClone,
          price: data.data.pricePerUnit,
          marketPrice: data.data.marketPrice,
          defaultPrice: data.data.defaultPrice,
          discountType: data.data.discountType,
          discountValue: data.data.discountValue,
        };
      }

      const quantityInvalid = quantity < minimumQuantity;
      this.UPDATE_DRAFT_PRODUCT({ ...productClone, quantityInvalid, quantity });
      this.handleDeliveryFee();
    }, 300),
    handleDeliveryFee() {
      this.UPDATE_DRAFT({ oldAmount: this.draft.amount });
      this.CALCULATE_NEW_DRAFT_ORDER_AMOUNT();
      this.UPDATE_DELIVERY_FEE_TO_NEW_ORDER({ distributor: this.getDistributor });
    },
    isToBeDetermined(product) {
      const { priceUnit, marketPrice, price } = product || {};
      return (!!priceUnit && price > 0) || marketPrice;
    },
    customPrefix(row) {
      if (row.discountType === DISCOUNT_TYPE.amount) return row.currency?.symbol;
      return '%';
    },
    unitLabel(product) {
      return product.priceUnit ? product.priceUnit.label : '';
    },
    precision(row) {
      if (row.discountType === DISCOUNT_TYPE.amount) return row.currency?.fractionDigits;
      return DEFAULT_FRACTION_DIGITS;
    },
    maxValue(row) {
      return row.discountType === DISCOUNT_TYPE.amount ? row.defaultPrice : 100;
    },
    getDiscount(row) {
      return row.defaultMarketPrice ? 0 : row.discountValue;
    },
    onDiscountInput(val, product) {
      const updatedProduct = {
        ...product,
        discountValue: val,
        discountType: product.discountType ?? DISCOUNT_TYPE.percent,
      };
      updatedProduct.price = getDiscountedPrice(updatedProduct);
      updatedProduct.discountAmount = getDiscountValue(updatedProduct);
      this.UPDATE_DRAFT_PRODUCT({ ...updatedProduct });
      this.handleDeliveryFee();
    },
    onDiscountInvalid(val, product) {
      this.UPDATE_DRAFT_PRODUCT({ ...product, discountInvalid: val });
    },
    onAdditionalDiscountInvalid(val) {
      this.discountsInvalid = { ...this.discountsInvalid, ...val };
    },
    updateDiscountType(type, product) {
      const updatedProduct = {
        ...product,
        discountType: type === Event.setPercentType ? DISCOUNT_TYPE.percent : DISCOUNT_TYPE.amount,
        discountValue: 0,
      };
      updatedProduct.price = getDiscountedPrice(updatedProduct);
      updatedProduct.discountAmount = getDiscountValue(updatedProduct);
      this.UPDATE_DRAFT_PRODUCT({ ...updatedProduct });
      this.handleDeliveryFee();
    },
    totalPrice(product) {
      return getDiscountedPrice(product) * product.quantity;
    },
    toggleListExpanded() {
      this.productsListExpanded = !this.productsListExpanded;
    },
    onNoteUpdate(type, val) {
      this.note = {
        ...this.note,
        [type]: val,
      };
      this.UPDATE_DRAFT({ note: this.note });
    },
  },
  created() {
    this.note.deliveryOrder = this.distributor.config.showOrderNoteOnDeliveryOrder ?? false;
    this.note.invoice = this.distributor.config.showOrderNoteOnInvoice ?? false;
    this.UPDATE_DRAFT({ note: this.note });
    this.enableNextStep();
  },
  watch: {
    isSomeDiscountInvalid(val) {
      if (val) this.disableNextStep();
      else this.enableNextStep();
    },
  },
};
</script>

<template>
  <div class="create-order">
    <div class="create-order__main-content">
      <paper fullWidth class="venue-section">
        <template #actions>
          <ez-button
            type="link"
            formType="button"
            @click="editVenue"
            :data-cy="supplierCy.ORDERS.NEW_ORDER.OVERVIEW.BUTTON__EDIT_OUTLET"
          >
            <font-awesome-icon icon="pen" />
            <span>Edit</span>
          </ez-button>
        </template>
        <v-entity-info
          :imageUrl="venue.logo"
          imageSize="64px"
          imageBorderRadius="50%"
          imageHasBorder
        >
          <template>
            <span
              :data-cy="supplierCy.ORDERS.CREDIT_NOTES.NEW_CREDIT_NOTE.OVERVIEW.TEXT__CUSTOMER_NAME"
            >
              {{ venue.name }}
            </span>
          </template>
          <template #suffix>
            <ez-premium-badge v-if="$helpers.isPremium(venue.accountType)" />
            <ez-lite-badge v-if="$helpers.isLite(venue.accountType)" />
          </template>
        </v-entity-info>
        <template #footer v-if="outletReferenceNumber">
          <div class="ref-number">
            <hr class="mt-16 mb-16" />
            <p class="ref-number__label m-0">Reference Number</p>
            <p class="ref-number__value m-0">
              {{ outletReferenceNumber }}
            </p>
          </div>
        </template>
      </paper>

      <paper fullWidth>
        <template #title>Products ({{ products.length }})</template>
        <template #actions>
          <ez-button
            type="link"
            formType="button"
            @click="editProducts"
            :data-cy="supplierCy.ORDERS.NEW_ORDER.OVERVIEW.BUTTON__EDIT_PRODUCTS"
          >
            <font-awesome-icon icon="pen" />
            <span>Edit</span>
          </ez-button>
        </template>
        <div class="products-table">
          <ez-table
            :data="listProducts"
            :columns="['product', 'quantity', 'price', 'discount', 'total']"
            :columnProps="{
              product: { class: 'product-cell' },
              quantity: { class: 'quantity-cell' },
              price: { class: 'pricePerUnit-cell' },
              discount: { class: 'discount-cell' },
              total: { class: 'total-cell no-padding-cell' },
            }"
            :headers="{
              product: () => 'Product',
              price: () => 'Price per Unit',
              discount: () => 'Unit Discount',
              total: () => 'Total Price',
            }"
            :rowCls="getRowClass"
          >
            <template #cell-product="{ row }">
              <ez-entity-info imgWidth="28px" imgHeight="28px" :imgUrl="row.image">
                <div class="product-info" :title="row.name">
                  <span
                    :data-cy="`${supplierCy.ORDERS.NEW_ORDER.OVERVIEW.TEXT__PRODUCT_NAME}-${row.id}`"
                  >
                    {{ row.name }}
                  </span>
                  <span class="product-info-secondary">
                    <span class="product-info__unit">{{ row.orderingUnit.label }}</span>
                    <template v-if="row.sku">
                      &#8226;
                      <span class="product-info__sku">{{ row.sku }}</span>
                    </template>
                  </span>
                </div>
              </ez-entity-info>
            </template>

            <template #cell-quantity="{ row }">
              <ez-mask-input-simple
                name="quantity"
                formKey=""
                :value="row.quantity"
                :minValue="row.minimumQuantity ?? 0"
                :disabled="row.isBonus"
                :precision="isFractional(row) ? 2 : 0"
                :isInvalid="isInvalid(row)"
                @input="onQuantityChange($event, row)"
                @invalid="onQuantityInvalid($event, row)"
                class="quantity-input"
                :has-currency="false"
                :data-cy="`${supplierCy.ORDERS.NEW_ORDER.OVERVIEW.TEXT__PRODUCT_QUANTITY}-${row.id}`"
              />
            </template>

            <template #cell-price="{ row }">
              <v-price
                :price="getPrice(row)"
                :unit="unitLabel(row)"
                :currency="row.currency"
                :showMarketPriceInfo="false"
                :isMarketPrice="row.marketPrice"
                flexRow
                :data-cy="`${supplierCy.ORDERS.NEW_ORDER.OVERVIEW.TEXT__PRODUCT_PRICE}-${row.id}`"
              />
            </template>

            <template #cell-discount="{ row }">
              <v-market-price-input
                :value="getDiscount(row)"
                :disabled="row.marketPrice || row.defaultMarketPrice || row.isBonus"
                :customPrefix="customPrefix(row)"
                :precision="precision(row)"
                :maxValue="maxValue(row)"
                :is-invalid="!!row.discountInvalid"
                @input="onDiscountInput($event, row)"
                @invalid="onDiscountInvalid($event, row)"
                @setPercentType="updateDiscountType(Event.setPercentType, row)"
                @setAmountType="updateDiscountType(Event.setAmountType, row)"
                :actions="[
                  { type: Event.setPercentType, label: '%' },
                  { type: Event.setAmountType, label: row.currency?.symbol },
                ]"
              />
            </template>

            <template #cell-total="{ row }">
              <v-price
                :price="totalPrice(row)"
                :currency="row.currency"
                :showMarketPriceInfo="false"
                :isToBeDetermined="isToBeDetermined(row)"
                :data-cy="`${supplierCy.ORDERS.NEW_ORDER.OVERVIEW.TEXT__PRODUCT_PRICE}-${row.id}`"
              />
              <span
                v-if="row.isBonus"
                class="additional-info additional-info--bonus"
                v-tooltip="{
                  content: tradeDealTooltipContent(row),
                  classes: ['tooltip--reset-margin'],
                }"
                >Bonus</span
              >
            </template>
          </ez-table>
        </div>
        <template #footer>
          <div v-if="products.length > 8" class="u-flex-h-center mt-16">
            <ez-button @click="toggleListExpanded" type="link" formType="button">
              <span>{{ expandButtonCopy }}</span>
              <font-awesome-icon class="ml-8" :icon="expandButtonIcon" />
            </ez-button>
          </div>
        </template>
      </paper>

      <paper fullWidth>
        <template #title>
          Note
          <font-awesome-icon
            v-if="isOrder"
            icon="info-circle"
            v-tooltip="{
              content: 'This note will be displayed on the selected documents.',
              classes: ['tooltip--reset-margin', 'tooltip--lift-up'],
            }"
            class="info-icon ml-8"
          />
        </template>

        <template>
          <ez-textarea
            formKey=""
            :placeholder="
              isOrder ? 'Add a note for this order.' : 'Add a note to be displayed on this quote.'
            "
            name="note"
            class="mt-16"
            @onChange="onNoteUpdate('message', $event)"
            :data-cy="supplierCy.ORDERS.NEW_ORDER.OVERVIEW.TEXTAREA__NOTE"
          />

          <div v-if="isOrder" class="note-options u-flex-center mt-16">
            <div class="note-option u-flex-center">
              <span class="note-option__label">Delivery Order</span>
              <ez-on-off
                formKey=""
                name="deliveryOrder"
                :value="note.deliveryOrder.toString()"
                :checked="note.deliveryOrder"
                @change="onNoteUpdate('deliveryOrder', $event)"
                class="ml-12"
              />
            </div>
            <div class="note-option u-flex-center ml-24">
              <span class="note-option__label">Invoice</span>
              <ez-on-off
                formKey=""
                name="invoice"
                :value="note.invoice.toString()"
                :checked="note.invoice"
                @change="onNoteUpdate('invoice', $event)"
                class="ml-12"
              />
            </div>
          </div>
        </template>
      </paper>
    </div>

    <overview-sidebar :discountsInvalid="discountsInvalid" @invalid="onAdditionalDiscountInvalid" />

    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<style scoped lang="scss">
.create-order {
  width: 100%;
  display: flex;
}

.venue-section :deep() {
  .overview-paper__content {
    display: flex;
    justify-content: center;
  }

  .entity-info {
    margin-top: -36px;
    flex-direction: column;
    align-items: center;

    &__info {
      justify-content: center;
      margin-left: 0;
      margin-top: 8px;
      @include font-size(16px, 22px);
      font-weight: 600;
      color: $color-gray-25;
    }
  }

  .ref-number {
    &__label {
      @include font-size(12px, 18px);
      color: $color-gray-6C;
    }

    &__value {
      @include font-size(14px, 20px);
      color: $color-gray-25;
    }
  }
}

.products-table {
  .quantity-cell {
    width: 64px + $spacing-32;

    .quantity-input {
      :deep() .input-group {
        input {
          color: $color-primary-blue;
          font-weight: bold;
        }

        button {
          display: none;
        }
      }
    }
  }

  .pricePerUnit-cell {
    width: 90px + $spacing-32;

    :deep() .v-price__price {
      font-weight: bold;

      &:not(.v-price__market-price--row, .v-price__unit--row) {
        color: $color-gray-25;
      }
    }
  }

  .discount-cell {
    width: 95px + $spacing-32;
    overflow: visible;

    :deep() {
      .mask-input {
        &__input {
          @include font-size(16px, 20px);
        }

        &__prefix {
          color: $color-gray-6C;
          font-weight: bold;
        }
      }

      .mark-input__error {
        display: none;
      }
    }
  }

  .total-cell {
    width: 125px;
    overflow: visible;

    :deep() span {
      font-weight: bold;
      color: $color-gray-25;
    }
  }

  .product-cell {
    .product-info {
      display: flex;
      flex-direction: column;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      &__unit {
        color: $color-gray-25;
      }
    }
  }
  tbody tr.item-bonus {
    background-color: $color-pastel-blue;
    td:first-child {
      padding-left: 8px;
    }
    td:last-child {
      padding-right: 8px;
    }
    :deep() .has-border {
      border: 1px solid $color-primary-blue;
    }
    .additional-info--bonus {
      color: $color-gray-6C;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      border-bottom: 2px dotted #a3bbff;
      color: $color-primary-blue;
    }
  }
}

.note-options {
  .note-option {
    &__label {
      @include font-size(12px, 22px);
      font-weight: 600;
      color: $color-gray-25;
    }
  }
}

.info-icon {
  width: $spacing-16;
  height: $spacing-16;
}

:deep() .loader {
  @extend %whole-screen;
}

:deep() .mask-input-wrapper {
  .mask-input__input {
    text-align: center;
  }
}
</style>
