<template>
  <div class="ez-tiers">
    <div v-if="tier.tiers && tier.tiers.length" class="ez-tiers__discount-type">
      <ez-radio-button
        value="price"
        label="Price"
        :data="tier.discountType"
        :formKey="formKey"
        :name="`${name}[discountType]`"
        @change="onDiscountTypeChange"></ez-radio-button>
      <ez-radio-button
        value="percentage"
        label="Percentage"
        :data="tier.discountType"
        :formKey="formKey"
        :name="`${name}[discountType]`"
        @change="onDiscountTypeChange"></ez-radio-button>
    </div>
    <ul v-if="tier.tiers && tier.tiers.length">
      <li v-for="(t, ind) in tier.tiers" :key="t.id">
        <div class="ez-tiers__inputs">
          <ez-input
            label="From number of products"
            placeholder="Enter Quantity"
            :formKey="formKey"
            :name="`${name}[tiers][${ind}][minQuantity]`"
            :value="t.minQuantity"
            @onChange="onTierChange(ind, { minQuantity: $event })"></ez-input>
          <ez-input
            :label="labels[tier.discountType]"
            :formKey="formKey"
            :name="`${name}[tiers][${ind}][value]`"
            :value="t.value"
            @onChange="onTierChange(ind, { value: $event })">
              <template #prefix>{{ prefix[tier.discountType] }}</template>
            </ez-input>
        </div>
        <ez-button
          type="link"
          formType="button"
          customClass="ez-tiers__remove"
          @click="onRemoveTierClick(ind)">
          <font-awesome-icon icon="times"/>
        </ez-button>
      </li>
    </ul>
    <ez-button
      type="secondary"
      formType="button"
      customClass="ez-tiers__add-new"
      @click="onAddTierClick">
      <font-awesome-icon icon="plus"/>Add New Tier
    </ez-button>
  </div>
</template>

<script>
import EzButton from '@/components/ui/Button';
import EzInput from '@/components/ui/Input';
import EzRadioButton from '@/components/ui/RadioButton';

export default {
  components: {
    EzButton,
    EzInput,
    EzRadioButton,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    tier: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      labels: {
        price: 'Price',
        percentage: 'Percentage',
      },
      prefix: {
        price: '$',
        percentage: '%',
      },
    };
  },
  methods: {
    onAddTierClick() {
      this.$emit('addTier');
    },
    onRemoveTierClick(tierIndex) {
      this.$emit('removeTier', this.tier.tiers[tierIndex]);
    },
    onTierChange(tierIndex, payload) {
      this.$emit('tierChange', {
        ...this.tier,
        tiers: this.tier.tiers
          .map((tier, ind) => (ind === tierIndex ? { ...tier, ...payload } : tier)),
      });
    },
    onDiscountTypeChange(discountType) {
      this.$emit('tierChange', {
        ...this.tier,
        discountType,
        tiers: this.tier.tiers.map(tier => ({ ...tier, discountType })),
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ez-tiers {
  ul {
    @extend %ul-reset;
    li {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      + li {
        margin-top: 1em;
      }
    }
  }

  &__discount-type {
    margin-bottom: 2em;
    display: flex;
    .input-group {
      + .input-group {
        margin-left: 1em;
      }
    }
  }

  &__inputs {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1 1 auto;
    margin-right: 2em;
    .input-group {
      flex: 1 1 auto;
      max-width: 13em;
    }
  }
  :deep() &__add-new {
    @extend %button-add;
    margin-top: 1.2em;
    max-width: 11.1em;
    width: 100%;
  }
  :deep() &__remove {
    position: absolute;
    background: none;
    top: 2.6em;
    right: 0;
    padding: 0;
    box-shadow: none;
    &:hover {
      box-shadow: none;
      color: $primary-color;
    }
  }
}
</style>
