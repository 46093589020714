<script>
/**
 * Merge products table used for Xero integration.
 *
 * @version 1.0.0
 * @since
 */

import { mapMutations, mapState } from 'vuex';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import EzCheckbox from '@/components/ui/Checkbox';
import EzBadge from '@/components/ui/Badge/EzBadge';

export default {
  components: {
    VProductEntityInfo,
    EzCheckbox,
    EzBadge,
  },
  props: {
    products: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    ...mapState('xero', [
      'selectedProducts',
    ]),
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    numberOfSelectedProducts() {
      return Object.values(this.selectedProducts).reduce((a, b) => a + b, 0);
    },

    isAnySelected() {
      return this.numberOfSelectedProducts > 0;
    },
  },
  watch: {
    isAnySelected(value) {
      this.$emit('selected', value);
    },
  },
  methods: {
    ...mapMutations('xero', ['UPDATE_SELECTED_PRODUCTS', 'TOGGLE_SELECTED_PRODUCTS']),

    hasPriceDiff(item) {
      return item.product && item.product.price !== item.price;
    },

    selectOrDeselectAll(val) {
      this.TOGGLE_SELECTED_PRODUCTS(val);
    },

    selectOrDeselectProduct(val, code) {
      this.UPDATE_SELECTED_PRODUCTS({ [code]: val });
    },
  },
  mounted() {
    if (this.isAnySelected) this.$emit('selected', true);
  },
};
</script>
<template>
  <div>
    <div class="floating-bar">
      <div class="floating-bar--segment">
        <ez-checkbox
          :indeterminate="numberOfSelectedProducts > 0
          && numberOfSelectedProducts < products.length"
          @change="(val) => selectOrDeselectAll(val)"
          :checked="numberOfSelectedProducts === products.length"
          :label="$t('xero.wizzard.step2.selectAllLabel')"/>
      </div>
      <div class="floating-bar--segment products-selected">
        <span v-if="numberOfSelectedProducts === 0">
          {{ $t('xero.wizzard.step2.noSelectedProductsLabel') }}
        </span>
        <span v-else>
          {{ $t('xero.wizzard.step2.productsSelectedLabel') }}
          <ez-badge class="products-selected--counter" type="blue"
                    :count="numberOfSelectedProducts"/>
        </span>
      </div>
      <div class="floating-bar--segment"></div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Product</th>
          <th class="medium-cell">SKU</th>
          <th class="medium-cell">Price Per Unit</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="product in products">
          <tr :key="`${product.itemId}-row1`" :class="[{'fill': selectedProducts[product.code]}]">
            <td>
              <span class="xero-item">
                <ez-checkbox
                  :checked="selectedProducts[product.code]"
                  @change="(val) => selectOrDeselectProduct(val, product.code)"
                  class="mr-12"/>
                <img src="@/assets/logo-xero.png" width="32" height="32" alt="Xero Logo"/>
                <span class="xero-item__name ml-8">{{ product.name }}</span>
              </span>
            </td>
            <td class="medium-cell">{{ product.code }}</td>
            <td :class="['price-cell', {'price-diff': hasPriceDiff(product)}]">
              {{ product.price | price }}
            </td>
          </tr>
          <tr :key="`${product.itemId}-row2`" :class="[{'fill': selectedProducts[product.code]}]">
            <td class="imaginary-checkbox">
              <v-product-entity-info v-if="product.product" :product="product.product" />
              <span :class="['notice', {'notice__checked': selectedProducts[product.code]}]"
                    v-else>{{ $t('xero.wizzard.step2.noMatchingProducts', { platform: platformName }) }}</span>
            </td>
            <td class="medium-cell">{{ (product.product || {}).sku }}</td>
            <td class="price-cell medium-cell">
              <template v-if="product.product">{{ product.product.price | price }}</template>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<style lang="scss" scoped>
$border-color: #E9EBF2;
$cell-vertical-padding: 12px;
$cell-horizontal-padding: 16px;
$header-cell-padding: .5rem 1rem;
$selected-item-background: #E2EAFF;

.table {
  @include font-size(14px);
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;

  .fill {
    td {
      background-color: $selected-item-background;
    }
  }

  th {
    @include font-size(12px);
    font-weight: 500;
    border-bottom: 1px solid $border-color;
    color: $color-gray-6C;
    text-transform: capitalize;
    text-align: left;
    white-space: nowrap;
    padding: $header-cell-padding;
    overflow-x: hidden;
    text-overflow: ellipsis;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      text-align: right;
      padding-right: 0;
    }
  }

  td {
    color: $color-gray-6C;
    font-weight: 400;
    padding: $cell-vertical-padding $cell-horizontal-padding calc($cell-vertical-padding / 2);
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      text-align: right;
      padding-right: 0;
    }
    &:last-child:first-child {
      text-align: left;
    }
    &.price-cell {
      @include font-size(16px);
      font-weight: bold;
      color: $color-gray-25;
    }
  }

  tr:nth-child(2n) {
    border-bottom: 1px solid $border-color;

    td {
      padding-top: calc($cell-vertical-padding / 2);
    }
  }
}

.xero-item {
  display: inline-flex;
  align-items: center;

  img {
    border-radius: 50%;
  }

  &__name {
    color: $global-text-color;
  }
}

.medium-cell {
  width: 125px;
}

.imaginary-checkbox {
  padding-left: 26px !important;
}

.price-diff {
  color: $color-primary-dark-blue !important;
}

.notice {
  display: inline-block;
  padding: 10px 16px;
  border-radius: 3px;
  background-color: #F5F6FA;
  color: $global-text-color;

  &__checked {
    background-color: $selected-item-background;
  }
}

.floating-bar {
  @include z-index('wizzard-floating-bar');
  @extend %flex-space;
  margin-bottom: $spacing-32;
  padding: 20px 0;
  background-color: #fff;
  position: sticky;
  top: 64px;
  border-bottom: 1px solid #E9EBF2;

  .products-selected {
    @extend %flex-center;
    justify-content: center;

    &--counter {
      margin: 0 0 0 6px;
    }
  }

  &--segment {
    flex: 1 1 0px;
  }
}
</style>
