<script>
import { mapActions } from 'vuex';
import { clone } from '@/util/utils';

import EzButton from '@/components/ui/Button';
import EzForm from '@/components/ui/Form/EzForm';
import flash from '@/components/ui/FlashMessage';
import VVenueInventorySettings from '@/components/v3/patterns/VVenueInventorySettings';

/**
 * InventorySettings
 * @version 1.0.0
 * @since 3.25.1
 */

export default {
  name: 'InventorySettings',
  components: {
    EzButton,
    EzForm,
    VVenueInventorySettings,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      venue: null,
      formKey: 'distributor-outlet-inventory-settings',
      values: {
        displayStockNumbers: false,
        lowInventoryThreshold: 10,
        orderMoreThanAvailable: true,
        excludeExpired: false,
      },
      initialValues: {},
    };
  },
  computed: {
    hasMadeChanges() {
      const initial = JSON.stringify(this.initialValues);
      const current = JSON.stringify(this.values);
      return initial !== current;
    },
  },
  methods: {
    ...mapActions('reports', ['fetchVenue']),
    async initValues() {
      const { data } = await this.fetchVenue({ venueId: this.venueId });
      this.venue = clone(data.data);

      await this.$nextTick();
      const values = { ...this.values, ...this.venue?.connection.customerInventorySettings };
      this.values = clone(values);
      this.initialValues = clone(values);
    },
    updateState({ key, value }) {
      this.values[key] = value;
    },
    onSuccess() {
      flash.success({ title: `${this.$t('global.venue')} successfully updated!` });
      this.initValues();
    },
    onError() {
      flash.error({ title: 'Something went wrong.' });
    },
    transformer() {
      return { customerInventorySettings: this.values };
    },
  },
  created() {
    this.initValues();
  },
};
</script>

<template>
  <div class="container--small">
    <ez-form
      method="put"
      :form-key="formKey"
      :action="`/distributor/venues/${this.venueId}/connection-options`"
      :transformer="transformer"
      @success="onSuccess"
      @error="onError"
    >
      <v-venue-inventory-settings :values="values" @onChange="updateState" />

      <template #buttons>
        <ez-button class="mt-24" form-type="submit" :disabled="!hasMadeChanges">
          Save Changes
        </ez-button>
      </template>
    </ez-form>
  </div>
</template>

<style lang="scss" scoped></style>
