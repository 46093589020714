<template>
  <ez-user-list
    role="distributor"
    :users="users"
    :columns="[ 'name', 'email', 'role' ]"
    :columnProps="{
      role: { class: 'role-cell'}
    }"
    @userClick="onUserClick"
    @addUser="onAddUser"
    @removeUser="onRemoveUser"
  >
    <ez-distributor-user-modal
      :roles="roles"
      ref="userModal"
      :distributorId="loggedUser.distributor.id"
      :userId="selectedUserId"
    />
  </ez-user-list>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import EzUserList, { EzDistributorUserModal } from '@/views/common/users';
import User from '@/models/User';

export default {
  components: {
    EzUserList,
    EzDistributorUserModal,
  },
  data() {
    return {
      selectedUserId: null,
      roles: [],
    };
  },
  computed: {
    users() {
      return User.all();
    },
    ...mapState('entities/users', [
      'loggedUser',
    ]),
  },
  methods: {
    openModal() {
      this.$refs.userModal.open();
    },
    async onAddUser() {
      this.selectedUserId = -1;
      this.openModal();
    },

    async onUserClick({ id }) {
      await this.fetchDistributorUser({ id, groupId: this.groupId });
      this.selectedUserId = id;
      this.openModal();
    },
    async onRemoveUser(user, replacementOwnerId, replacementPrimaryUserId) {
      await this.deleteDistributorUser({
        distributorId: user.id,
        replacementOwnerId,
        replacementPrimaryUserId,
      });
      await User.delete(user.id);
    },
    ...mapActions('entities/users', [
      'fetchDistributorUser',
      'fetchDistributorUsers',
      'deleteDistributorUser',
      'fetchAccountOwners',
    ]),
    ...mapActions('roles', [
      'getDistributorRoles',
    ]),
  },
  async created() {
    await User.deleteAll();
    [
      this.roles,
      // Distributor Users
    ] = await Promise.all([
      this.getDistributorRoles(),
      this.fetchDistributorUsers(),
    ]);
  },
};
</script>

<style scoped lang="scss">
  :deep() .table {
    .role-cell {
      text-align: right;
    }
  }
</style>
