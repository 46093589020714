<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { LOADING_KEY, UNIT_TYPE_PACK } from '@/util/constants';

import SinglePage from '@/views/admin/SinglePage.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table';
import VAccountOwnerEntityInfo from '@/components/v3/patterns/VAccountOwnerEntityInfo';
import VWarehouseLocation from '@/components/v3/elements/VWarehouseLocation';
import downloadAttachment from '@/util/downloadAttachment';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';

/**
 * Stocktake
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'Stocktake',
  props: {
    stocktakeId: {
      type: Number,
      required: true,
    },
  },
  components: {
    VDataWithInfo,
    SinglePage,
    EzEntityInfo,
    EzLoadMore,
    EzSpinner,
    EzButton,
    EzTable,
    VAccountOwnerEntityInfo,
    VWarehouseLocation,
  },
  data() {
    return {
      stocktake: {},
      items: [],
      meta: { page: 0 },
      dayjs,
      packUnitType: UNIT_TYPE_PACK,
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_SINGLE_STOCKTAKE,
        LOADING_KEY.DISTRIBUTOR_FETCH_SINGLE_STOCKTAKE_ITEMS,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_SINGLE_STOCKTAKE_ITEMS);
    },
    isLoadingExport() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_EXPORT_STOCKTAKE);
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'distributorFetchSingleStocktake',
      'distributorFetchSingleStocktakeItems',
      'distributorExportSingleStocktake',
    ]),
    rowDataCy(row) {
      return `${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.ROW__STOCKTAKE_ITEM}-${row.product.id}`;
    },
    async fetchStocktake() {
      const { data } = await this.distributorFetchSingleStocktake({ id: this.stocktakeId });
      this.stocktake = data.data;
    },
    async fetchItems(loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_SINGLE_STOCKTAKE_ITEMS) {
      const { data } = await this.distributorFetchSingleStocktakeItems({
        id: this.stocktakeId,
        loadingKey,
        query: { page: this.meta.page + 1 },
      });
      this.items = [...this.items, ...data.data];
      this.meta = data.meta;
    },
    async exportStocktake() {
      const { data, headers } = await this.distributorExportSingleStocktake({
        id: this.stocktakeId,
      });
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'template.xlsx';
      downloadAttachment(data, name);
    },
    async loadMore() {
      await this.fetchItems(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_SINGLE_STOCKTAKE_ITEMS);
    },
  },
  async created() {
    await Promise.all([this.fetchStocktake(), this.fetchItems()]);
  },
};
</script>

<template>
  <div>
    <single-page v-if="!isLoading">
      <template #breadcrumbs>
        <template>
          <router-link :to="{ name: 'distributor-products-stocktakes' }">
            <font-awesome-icon icon="arrow-left" /> Back to Stocktake
          </router-link>
        </template>
      </template>
      <template #title>
        <h1
          v-if="stocktake.warehouse"
          class="header__title"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__TITLE"
        >
          Stocktake for {{ stocktake.warehouse.name }}
        </h1>
        <p
          class="header__createdAt"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__DATE"
        >
          {{ stocktake.createdAt | dateTime }}
        </p>
      </template>
      <template #actions>
        <ez-button
          type="secondary"
          :isLoading="isLoadingExport"
          @click="exportStocktake"
          :data-cy="supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.BUTTON__EXPORT"
        >
          Export CSV
        </ez-button>
      </template>

      <template>
        <div class="subheader">
          <div class="subheader__section">
            <span class="subheader__label">Note</span>
            <p
              class="subheader__text"
              :data-cy="supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__NOTE"
            >
              {{ stocktake.note || '-' }}
            </p>
          </div>
          <div class="subheader__section">
            <span class="subheader__label">Users</span>
            <div class="subheader__users">
              <v-account-owner-entity-info
                v-for="(user, idx) in stocktake.users"
                :key="idx"
                class="subheader__users__user"
                :account-owner="user"
                :to-show-name="false"
                :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__USER}-${idx}`"
              />
            </div>
          </div>
        </div>

        <ez-table
          :data="items"
          :loading="isLoading"
          :columns="[
            'name',
            'location',
            'batchCode',
            'expiryDate',
            'priorInventory',
            'actualInventory',
            'unit',
            'cogs',
            'difference',
            'note',
          ]"
          :headers="{
            name: () => 'Product',
            batchCode: () => 'Batch ID',
            priorInventory: () => 'Prior Stock',
            actualInventory: () => 'Actual Stock',
            cogs: () => 'COGS',
          }"
          :columnProps="{
            name: { class: 'name-cell' },
            location: { class: 'medium-cell' },
            batchCode: { class: 'medium-cell' },
            expiryDate: { class: 'medium-cell' },
            priorInventory: { class: 'medium-cell' },
            actualInventory: { class: 'medium-cell' },
            cogs: { class: 'medium-cell u-text-right' },
            difference: { class: 'medium-cell u-text-right difference-cell' },
            note: { class: 'large-cell note-cell' },
            unit: { class: 'medium-cell unit-cell' },
          }"
          disableHover
          :rowDataCy="rowDataCy"
        >
          <template #cell-name="{ row: { product } }">
            <ez-entity-info :imgUrl="product.image">
              <div class="product-info" :title="product.name">
                <span
                  class="product-info__name"
                  :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__PRODUCT_NAME}-${product.id}`"
                  >{{ product.name }}</span
                >
                <span
                  class="product-info__sku"
                  :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__PRODUCT_SKU}-${product.id}`"
                  >{{ product.sku }}</span
                >
              </div>
            </ez-entity-info>
          </template>
          <template #cell-location="{ row: { product, location } }">
            <v-warehouse-location
              :data="location ? [location.code] : []"
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__LOCATION}-${product.id}`"
            />
          </template>
          <template #cell-batchCode="{ row: { product, batchCode } }">
            <span
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__BATCH_CODE}-${product.id}`"
              >{{ batchCode || '-' }}</span
            >
          </template>
          <template #cell-expiryDate="{ row: { product, expiryDate } }">
            <span
              v-if="expiryDate"
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__EXPIRY_DATE}-${product.id}`"
              >{{ dayjs(expiryDate) | date }}</span
            >
            <span
              v-else
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__EXPIRY_DATE}-${product.id}`"
              >-</span
            >
          </template>
          <template #cell-actualInventory="{ row: { product, actualInventory } }">
            <span
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__ACTUAL_STOCK}-${product.id}`"
              >{{ actualInventory }}</span
            >
          </template>
          <template #cell-priorInventory="{ row: { product, priorInventory } }">
            <span
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__PRIOR_STOCK}-${product.id}`"
              >{{ priorInventory }}</span
            >
          </template>
          <template #cell-unit="{ row: { product } }">
            <template v-if="product.orderingUnit">
              <v-data-with-info
                :info="
                  product.orderingUnit.type === packUnitType
                    ? 'The quantity of pack unit products is being managed per single unit.'
                    : ''
                "
                :show-underline="product.orderingUnit.type === packUnitType"
                :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__ORDERING_UNIT}-${product.id}`"
                >{{ product.orderingUnit.label }}</v-data-with-info
              >
            </template>
          </template>
          <template #cell-cogs="{ row: { product, cogs } }">
            <span
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__COGS}-${product.id}`"
              >{{ (cogs || 0) | price }}</span
            >
          </template>
          <template #cell-difference="{ row: { product, difference } }">
            <span
              :class="[
                'bold-text',
                'difference',
                { 'difference--green': difference > 0 },
                { 'difference--red': difference < 0 },
              ]"
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__DIFFERENCE}-${product.id}`"
              >{{ difference > 0 ? '+' : '' }}{{ difference }}</span
            >
          </template>
          <template #cell-note="{ row: { product, note } }">
            <span
              v-if="note"
              class="dashed-underline"
              v-tooltip="{
                placement: 'top-end',
                content: note,
                classes: ['tooltip--reset-margin', 'mb-8'],
              }"
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__NOTE}-${product.id}`"
              >{{ note }}</span
            >
            <span
              v-else
              :data-cy="`${supplierCy.PRODUCTS.STOCKTAKE.SINGLE_STOCKTAKE.TEXT__NOTE}-${product.id}`"
              >-</span
            >
          </template>
        </ez-table>

        <div v-if="isLoadingMore" class="u-text-center mt-12">
          <ez-spinner />
        </div>

        <ez-load-more v-if="!isLoadingMore && meta.lastPage > meta.page" @loadMore="loadMore" />
      </template>
    </single-page>
  </div>
</template>

<style lang="scss" scoped>
.header {
  margin-bottom: 12px;

  &__title {
    @include font-size(24px, 32px);
    font-weight: 500;
    margin: 0;
  }

  &__createdAt {
    margin: 4px 0 0;
    color: $color-gray-6C;
  }
}

.subheader {
  display: flex;
  align-items: flex-start;
  margin-bottom: 48px;

  &__section {
    &:nth-child(2) {
      margin-left: 40px;
    }
  }

  &__total {
    display: flex;
    align-items: center;
    margin-left: auto;

    &__amount {
      @include font-size(20px, 28px);
      color: $color-gray-25;
      font-weight: 600;
      margin-right: 12px;
    }

    &__icon {
      @extend %flex-center;
      justify-content: center;
      color: $color-white;
      background-color: $color-pastel-orange;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;

      &__inner {
        @include font-size(11px);
        @extend %flex-center;
        justify-content: center;
        background-color: $color-primary-orange;
        border-radius: 50%;
        width: 1.875rem;
        height: 1.875rem;
      }
    }
  }

  &__label {
    @include font-size(12px, 18px);
    color: $color-gray-6C;
    font-weight: 600;
    margin-bottom: 4px;
  }

  &__text {
    margin: 0;
    width: 400px;
  }

  &__users {
    display: flex;
    align-items: center;

    &__user {
      border: 2px solid white;
      border-radius: 50%;

      &:not(:first-child) {
        margin-left: -8px;
      }
    }
  }
}

.product-info {
  display: flex;
  flex-direction: column;

  &__name {
    color: $color-gray-25;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__sku {
    @include font-size(12px, 14px);
    color: $color-gray-6C;
    font-weight: 500;
  }
}
.unit-cell {
  overflow: hidden;
}

.difference-cell {
  .bold-text {
    font-weight: 600;
    color: $color-gray-25;
  }

  .difference {
    &--green {
      color: $color-primary-green;
    }

    &--red {
      color: $color-primary-red;
    }
  }
}

:deep() .table {
  tbody tr td.note-cell {
    overflow: visible;

    .dashed-underline {
      @include font-size(14px);
      font-weight: 400;
      border-bottom: 2px dotted rgba($color-gray-6C, 0.64);
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
