<script>
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzButton from '@/components/ui/Button/EzButton';

/**
 *
 * @version 1.0.0
 * @since 3.26.0
 */
export default {
  name: 'index',
  components: {
    VSelectSearch,
    EzButton,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    isFullWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedId: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    close() {
      this.$refs.customerCategory.close();
    },
    addNew() {
      this.$emit('onAddNew');
    },
    edit(item) {
      this.$emit('onEdit', item);
    },
    remove(item) {
      this.$emit('onRemove', item);
    },
    onSelect(item) {
      this.$emit('onSelect', item);
    },
    reset() {
      this.$refs.customerCategory.reset();
    },
  },
};
</script>

<template>
  <v-select-search
    ref="customerCategory"
    :data="data"
    :name="name"
    :label="label"
    :selected="selectedId"
    :placeholder="placeholder"
    :is-full-width="isFullWidth"
    :has-clear="false"
    :disable="disabled"
    :class="['customer-category', { 'customer-category--first-action': hasActions }]"
    :data-cy="dataCy"
    scroll-into-view
    @selected="onSelect"
  >
    <template #firstResult v-if="hasActions">
      <ez-button
        @click.stop="addNew"
        class="add-category"
        type="blue-link"
        :data-cy="`${dataCy}-addNewCategory`"
      >
        <font-awesome-icon icon="plus" />Add New Category
      </ez-button>
    </template>
    <template #result="{ result }">
      <div class="result">
        <div class="result__item">
          {{ result.name }}
          <div class="result__item__actions">
            <ez-button
              v-if="hasActions && result.owned"
              type="link"
              class="action p-0"
              @click.stop="edit(result)"
              :data-cy="`${dataCy}-editItem`"
            >
              <font-awesome-icon icon="pen" />
            </ez-button>

            <ez-button
              v-if="hasActions && result.canBeDeleted"
              type="link"
              class="action p-0"
              @click.stop="remove(result)"
              :data-cy="`${dataCy}-deleteItem`"
            >
              <font-awesome-icon icon="trash" />
            </ez-button>
          </div>
        </div>
      </div>
    </template>
  </v-select-search>
</template>

<style lang="scss" scoped>
.customer-category {
  .add-category {
    padding: 0;
    width: 100%;
    text-align: left;
  }

  :deep() .select-search {
    &__trigger {
      span:not(.select-search__value) {
        color: $color-gray-6C;
      }
    }

    &__search-wrapper {
      padding: 8px 8px 0 8px;
      border: none;
      margin-bottom: 8px;

      .input-wrapper {
        border: 1px solid $input-border-color;
        border-radius: $input-border-radius;
        padding: 0 10px;

        svg {
          color: $color-gray-6C;
        }
      }
    }

    &__list-wrapper li {
      padding: 0;
    }
  }

  &--first-action {
    :deep() .select-search__list-wrapper {
      li:first-child {
        padding: 0 12px;
      }
    }
  }

  .result {
    height: 36px;
    width: 100%;
    padding: 6px 12px;

    &__item {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__actions {
        .action {
          display: none;

          & + .action {
            margin-left: 19px;
          }
        }
      }
    }

    &:hover {
      .action {
        display: initial;
      }
    }
  }
}
</style>
