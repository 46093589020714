<script>
import { wizardEmitterMixin } from '@/mixins/wizard';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzButton from '@/components/ui/Button/EzButton';
import EzWizard from '@/components/layout/Wizard';
import EzAlert from '@/components/ui/Alert/EzAlert';
import steps, {
  VENUE_STEP_1,
  VENUE_STEP_2,
  VENUE_STEP_3,
} from '@/views/platform/distributor/outlets/send-onboarding-link/steps';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { LOADING_KEY } from '@/util/constants';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'SendOnboardingLink',
  mixins: [wizardEmitterMixin],
  components: {
    EzLoader,
    EzButton,
    EzWizard,
    EzAlert,
  },
  props: {
    prefilledName: {
      type: String,
    },
  },
  data() {
    return {
      currentStep: 0,
      VENUE_STEP_1,
      VENUE_STEP_2,
      VENUE_STEP_3,
      nextDisabled: true,
      loadingMsg: 'Loading...',
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    ...mapState('entities/venues', ['sendOnboardingLinkData']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_TIME_ZONES,
        LOADING_KEY.FETCH_DISTRIBUTOR_VENUE,
        LOADING_KEY.GET_USERS,
        LOADING_KEY.CONNECT_DISTRIBUTOR_TO_VENUE,
      ]);
    },
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === VENUE_STEP_1;
    },
    isLastStep() {
      return this.currentStep === VENUE_STEP_3;
    },
    disableNext() {
      return false;
    },
  },
  methods: {
    ...mapMutations('entities/venues', [
      'CLEAR_SEND_ONBOARDING_LINK_DATA',
      'CLEAR_DRAFT',
    ]),
    onWizardExit() {
      this.$router.push({
        name: this.sendOnboardingLinkData.route,
        ...(this.sendOnboardingLinkData.params ? { params: this.sendOnboardingLinkData.params } : {}),
      });
      this.CLEAR_SEND_ONBOARDING_LINK_DATA();
      this.CLEAR_DRAFT();
    },
    onStepBack(step) {
      if (step !== undefined && step >= VENUE_STEP_1 && step <= VENUE_STEP_3) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= VENUE_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= VENUE_STEP_3) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
  created() {
  },
};
</script>

<template>
  <div>
    <ez-loader :show="isLoading">
      {{loadingMsg}}
    </ez-loader>
    <ez-wizard
      :fullWidth="currentStep === VENUE_STEP_2 || currentStep === VENUE_STEP_2"
      @exit="onWizardExit"
      @back="goToPreviousStep">
      <template #title>Send Onboarding Link</template>
      <template #nav>
        <ul>
          <li :class="{
            'ez-step-active': currentStep === VENUE_STEP_1,
            'ez-step-completed': currentStep > VENUE_STEP_1,
          }">
            1. {{ $t('global.venue') }} Info
          </li>
          <li :class="{
            'ez-step-active': currentStep === VENUE_STEP_2,
            'ez-step-completed': currentStep > VENUE_STEP_2,
          }">
            2. Products
          </li>
        </ul>
      </template>
      <template #actions>
        <ez-button
          :disabled="nextDisabled"
          @click="goToNextStep">{{ nextCta }}</ez-button>
      </template>
      <template #prevStep>
        <ez-button
          v-if="currentStep > VENUE_STEP_1 && currentStep < VENUE_STEP_3"
          type="link"
          formType="button"
          @click="goToPreviousStep">
          <font-awesome-icon icon="arrow-left"/>
          <span>Back</span>
        </ez-button>
      </template>
      <template #pageTitle>{{ currentStepPageTitle }}</template>
      <template #pageInfo>{{ currentStepPageInfo }}</template>
      <component
        :prefilledName="prefilledName"
        :is="currentStepComponent"
        @stepCompleted="onStepCompleted"
        @stepBack="onStepBack"></component>
    </ez-wizard>
  </div>
</template>

<style lang="scss" scoped>

</style>
